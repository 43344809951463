<template>
    <div class="fondocliente">
        <div class="container-fluid h-100">
            <div class="row flex-row h-100 bg-white">
                <div class="col-xl-12 col-lg-12 col-md-12 my-auto fondocliente">
                    <div class="d-flex mt-4 iconosbienvenidacliente justify-content-end">
                        <nav class="navbar-expand-xl">
                            <div class="container-fluid">
                                <ul class="navbar-nav">
                                    <li class="nav-item">
                                        <!-- <router-link
								        class="nav-link"
								        :to="{ name: 'propiedades', params: {} }"
							            >
                                            <i style="font-size:38px;" class="fa fa-file-certificate"></i>
                                            Administración de Contratos
							            </router-link> -->
                                        <router-link
								        class="nav-link"
								        :to="{ name: 'propiedades', params: {} }"
							            >
                                            <i style="font-size:38px;" class="fa fa-file-certificate"></i>
                                            Administración de Contratos
							            </router-link>
                                    </li>
                                    <!-- <li v-if="esClienteMatriz" class="nav-item">
                                        <router-link
								        class="nav-link"
								        :to="{ name: 'mon_sucursal', params: {} }"
							            >
                                            <i style="font-size:38px;" class="fa fa-money-check-edit"></i>
                                            Control de Activos
							            </router-link>
                                    </li>
                                    <li v-else class="nav-item">
                                        <router-link
								        class="nav-link"
								        :to="{ name: 'mon_equipo', params: {obj:UsuarioPropiedad,objCliente:UsuarioPropiedad,return:false} }"
							            >
                                            <i style="font-size:38px;" class="fa fa-money-check-edit"></i>
                                            Control de Activos
							            </router-link>
                                    </li> -->
                                    <li class="nav-item">
                                        <router-link
								        class="nav-link"
								        :to="{ name: 'mon_sucursal', params: {} }"
							            >
                                            <i style="font-size:38px;" class="fa fa-money-check-edit"></i>
                                            Control de Activos
							            </router-link>
                                    </li>
                                    <li class="nav-item">
                                        <router-link
								        class="nav-link"
								        :to="{ name: 'mon_calendario', params: {obj:UsuarioPropiedad,objCliente:UsuarioPropiedad,return:false} }"
							            >
                                            <i style="font-size:38px;" class="fa fa-calendar-check"></i>
                                            Calendario
							            </router-link>
                                        
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                    <div class="row justify-content-center align-items-center h-75">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                            <div class="mx-auto">
                                <h1 class="text-center text-white textobienvenidacliente">{{ Empresa.Nombre }}</h1>
                                <h1 class="text-center textolemacliente">"Trabajando Para Controlar Tus Activos"</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:"BienvenidaCliente",
    data() {
        return {
            esClienteMatriz:false,
            UsuarioPropiedad:{},
            Empresa:{}
        }
    },
    methods: {
        
    },
    created() {
        this.Empresa=JSON.parse(sessionStorage.getItem("empresa"));
        this.esClienteMatriz = sessionStorage.getItem("contacto")==undefined ? true : false;
        if (sessionStorage.getItem("propiedad")!=undefined) {
            this.UsuarioPropiedad=JSON.parse(sessionStorage.getItem("propiedad"));
        }
    },
}
</script>

<style>
    .disabledoption{
        color: #a2a2a2 !important; 
        cursor:default !important;
    }
    .disabledoption:hover{
        color: #a2a2a2 !important; 
    }
    .disabledoption:hover>i{
        color: #a2a2a2 !important; 
    }
</style>