<template>
    <div>
        <CHeader :pHeaderProps="HeaderProps">
            <template slot="Contenido">
                <div class="row p-2">
                    <div class="col-12 col-sm-12 mb-2 mt-3 d-flex justify-content-center">
                        <img :src=Equipo.ImagenEquipo alt="" style="width:70vw;">
                        <div class="border-bottom mt-3"></div>
                    </div>
                    <div class="col-12 col-sm-12 mb-2">
                        <p><b>Equipo:</b></p>
                        <p>{{ Equipo.Nequipo }}</p>
                        <div class="border-bottom"></div>
                    </div>
                    <div class="col-12 col-sm-12 mb-2">
                        <p><b>Serie:</b></p>
                        <p>{{ Equipo.Serie }}</p>
                        <div class="border-bottom"></div>
                    </div>
                    <div class="col-12 col-sm-12 mb-2">
                        <p><b>Marca:</b></p>
                        <p>{{ Equipo.Marca }}</p>
                        <div class="border-bottom"></div>
                    </div>
                    <div class="col-12 col-sm-12 mb-2">
                        <p><b>Modelo:</b></p>
                        <p>{{ Equipo.Modelo }}</p>
                        <div class="border-bottom"></div>
                    </div>
                    <div class="col-12 col-sm-12 mb-2">
                        <p><b>Año:</b></p>
                        <p>{{ Equipo.Ano }}</p>
                        <div class="border-bottom"></div>
                    </div>
                    <div class="col-12 col-sm-12 mb-2">
                        <p><b>Ubicacion:</b></p>
                        <p>{{ Equipo.Ubicacion }}</p>
                        <div class="border-bottom"></div>
                    </div>
                </div>
            </template>
        </CHeader>
    </div>
</template>

<script>
import CHeader from "@/views/webapp/components/HeaderWebApp.vue";
export default {
    components:{CHeader},
    props:['pEquipo'],
    data() {
        return {
            HeaderProps:{
                ShowSubHeader:false,
                ShowReturn:true,
                TextoHeader:'Datos del Equipo',
                ShowLogo:false
            },
            Equipo:{}
        }
    },
    methods: {
        Regresar(){
            this.$router.push({name:'EquiposServicioWebApp'});
        }
    },
    created() {
        this.Equipo=this.pEquipo;
        this.bus.$off('EmitReturn');
        this.bus.$on('EmitReturn',()=>{
            this.Regresar();
        });
    },
}
</script>

<style>

</style>