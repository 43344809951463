<template>
    <div>  
        <Clist :pConfigLoad="ConfigLoad" :isCrm="true" :pShowBtnAdd="false" @FiltrarC="Lista" :Filtro="Filtro" :regresar="true" :Nombre="NameList" :Pag="Pag" :Total="TotalPagina" :isModal="EsModal" :isContactos="true">
            <template slot="Botonnuevo">
                <div class="col-md-12 col-lg-6 text-right mt-2">
                    <button type="button" data-toggle="modal" :data-target="'#'+NombreSeccion"  data-backdrop="static" data-keyboard="false" class="btn btn-pink mr-2" @click="Nuevo()"><i class="far fa-plus-circle"></i> Sucursal</button>   
                </div>
            </template>
            <template slot="cards">
                <div class="row row-cols-md-4 row-cols-lg-4 row-cols-xl-2 mt-3">
                    <div class="col-md-4 col-lg-4 col-xl-2 card-group">
                        <div class="card mb-3" style="border:2px solid #000">
                            <!-- <div class="card-body" style="min-height:175px"> -->
                            <img v-if="oClienteP.LogoCliente!=null && oClienteP.LogoCliente!=''" height="150px" class="card-image" :src="oClienteP.Ruta+oClienteP.LogoCliente"/>
                            <img v-else height="150px" class="card-image" src="" style="background:#b4b4b4;width:100%"/>  
                            <div class="card-body pt-2">
                                <!-- <div class="row">
                                    <div class="col-sm-12 col-md-12 col-lg-5 col-xl-5 p-0 mt-3">
                                        <div v-if="oClienteP.LogoCliente!=null && oClienteP.LogoCliente!=''" :style="'background-image:url('+(oClienteP.Ruta+oClienteP.LogoCliente)+');background-size:contain;width:90%;height:100px;background-repeat:no-repeat;background-position:center'"></div>
                                        <div v-else style="background:#b4b4b4;width:90%;height:100px"></div>
                                        
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-7 col-xl-7 p-0">
                                        <h6 class="m-0">{{ $limitCharacters(oClienteP.Nombre,22) }}</h6>
                                        {{ $limitCharacters(oClienteP.Ciudad,22) }}
        
                                        <div class="mt-1 mb-0 mr-0 ml-0">
                                            <b>Sucursales: </b>{{ oClienteP.Sucursales }}
                                            <br>
                                            <b>Oportunidades: </b>{{ oClienteP.Oportunidades }}
                                            <br>
                                            <b>Contratos: </b>{{ oClienteP.Contratos }}
                                        </div>
                                    </div>
                                </div> -->
                                <!-- <div class="row">
                                    <div v-if="oClienteP.LogoCliente!=null && oClienteP.LogoCliente!=''" :style="'background-image:url('+(oClienteP.Ruta+oClienteP.LogoCliente)+');background-size:contain;width:100%;height:100px;background-repeat:no-repeat;background-position:center'"></div>
                                    <div v-else style="background:#b4b4b4;width:100%;height:100px"></div>
                                </div> -->
                                <div class="row mt-1">
                                    <div class="col-lg-12">
                                        <h6 class="m-0">{{ oClienteP.Nombre.toUpperCase() }}
                                            <!-- <button v-b-tooltip.hover.rightbotton :title="oClienteP.Nombre" class="btn-circle boton-info"><i class="fa fa-info"></i></button> -->
                                        </h6>
                                        {{ oClienteP.Ciudad }}
        
                                        <div class="mt-1 mb-0 mr-0 ml-0">
                                            <b>Sucursales: </b>{{ oClienteP.Sucursales }}
                                            <br>
                                            <b>Oportunidades: </b>{{ oClienteP.Oportunidades }}
                                            <br>
                                            <b>Contratos: </b>{{ oClienteP.Contratos }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- LISTA DE SUCURSALES -->
                    <div v-for="(lista,key,index) in ListaClientes" :key="index" class="col-md-4 col-lg-4 col-xl-2 card-group">
                        <div class="card mb-3" style="border:2px solid #787474">
                            <!-- <div class="card-body" style="min-height:175px"> -->
                            <img height="150px" v-if="lista.LogoSucursal!=null && lista.LogoSucursal!=''" :src="RutaLogoSucursal+lista.LogoSucursal" alt="/">
                            <img height="150px" v-else-if="oClienteP.LogoCliente==null || oClienteP.LogoCliente==''" style="background:#b4b4b4;width:100%"/>
                            <img height="150px" v-else class="card-image" :src="oClienteP.Ruta+oClienteP.LogoCliente"/>
                            <div class="card-body pt-2">
                                <!-- <div class="row">
                                    <div class="col-sm-12 col-md-12 col-lg-5 col-xl-5 p-0 mt-3">
                                        <div v-if="lista.LogoSucursal!=null && lista.LogoSucursal!=''" :style="'background-image:url('+(RutaLogoSucursal+lista.LogoSucursal)+');background-size:contain;width:90%;height:100px;background-repeat:no-repeat;background-position:center'"></div>
                                        <div v-else-if="oClienteP.LogoCliente==null || oClienteP.LogoCliente==''" style="background:#b4b4b4;width:90%;height:100px"></div>
                                        <div v-else :style="'background-image:url('+(oClienteP.Ruta+oClienteP.LogoCliente)+');background-size:contain;width:90%;height:100px;background-repeat:no-repeat;background-position:center'"></div>

                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-7 col-xl-7 p-0" v-b-tooltip.hover.rightbotton :title="lista.Nombre">
                                        <h6 @mouseover="MostrarTodo()" class="m-0">{{ $limitCharacters(lista.Nombre,22) }}</h6>
                                        {{ $limitCharacters(lista.ContactoS,22) }}
                                        <br>
                                        {{ $limitCharacters(lista.Ciudad,22) }}
                                        <div class="mt-1 mb-0 mr-0 ml-0">
                                            <b>Oportunidades: </b>{{ lista.Oportunidades }}
                                            <br>
                                            <b>Contratos: </b>{{ lista.Contratos }}
                                        </div>
                                    </div>
                                </div> -->
                                <!-- <div class="row">
                                    <div class="col-lg-12">
                                        <div v-if="lista.LogoSucursal!=null && lista.LogoSucursal!=''" :style="'background-image:url('+(RutaLogoSucursal+lista.LogoSucursal)+');background-size:contain;width:100%;height:100px;background-repeat:no-repeat;background-position:center'"></div>
                                        <div v-else-if="oClienteP.LogoCliente==null || oClienteP.LogoCliente==''" style="background:#b4b4b4;width:100%;height:100px"></div>
                                        <div v-else :style="'background-image:url('+(oClienteP.Ruta+oClienteP.LogoCliente)+');background-size:contain;width:100%;height:100px;background-repeat:no-repeat;background-position:center'"></div>
                                    </div>
                                </div> -->
                                <div class="row mt-1">
                                    <div class="col-lg-12">
                                        <h6 class="m-0">{{ lista.Nombre.toUpperCase() }}
                                            <!-- <button v-b-tooltip.hover.rightbotton :title="lista.Nombre" class="btn-circle boton-info"><i class="fa fa-info"></i></button> -->
                                        </h6>
                                        {{ lista.ContactoS }}
                                        <br>
                                        {{ lista.Ciudad }}
                                        <div class="mt-1 mb-0 mr-0 ml-0">
                                            <b>Oportunidades: </b>{{ lista.Oportunidades }}
                                            <br>
                                            <b>Contratos: </b>{{ lista.Contratos }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="editar_contactos dropright">
                            <button type="button" style="background:rgba(0,0,0,0); border:none;"
                                title="Menu"
                                id="dropdownMenuButton"
								data-toggle="dropdown"
								aria-haspopup="true"
								aria-expanded="false"
                            >
                                <i class="fa fa-ellipsis-v"></i>
                            </button>
                            <div class="dropdown-menu dropdown-menu-user dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                <div class="dropdown-menu-header">
                                    <h4 style="opacity:1" class="widget-nombre mt-1 text-center">Seleccione una opción</h4>
                                </div>
                                <a
									@click="Editar(lista.IdClienteS)"
									data-toggle="modal"
									:data-target="'#'+NombreSeccion"
									data-backdrop="static"
									class="dropdown-item"
									>
									<i class="fa fa-pencil fa-fw-m"></i> Editar
								</a>

                                <a
									@click="EditarContratos(lista)"
									data-backdrop="static"
									class="dropdown-item"
									>
									<i class="fa fa-file-contract fa-fw-m"></i> Contratos
								</a>

                                <a
									@click="EditarOportunidades(lista)"
									data-backdrop="static"
									class="dropdown-item"
									>
									<i class="fa fa-lightbulb-on fa-fw-m"></i> Oportunidades
								</a>

                                <a v-if="RolUsuario=='admin'"
                                    @click="Eliminar(lista.IdClienteS)"
                                    data-backdrop="static"
									class="dropdown-item"
                                    >
                                    <i class="fa fa-trash fa-fw-m"></i> Eliminar
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
          
        </Clist>
        
        <!-- PARTE DE MODAL -->
        <Modal :pEmitSeccion="EmitSeccion" :NameModal="NombreSeccion"  :size="size" :Nombre="'Sucursal'" :poBtnSave="oBtnSave">
            <template slot="Form">
                <Form :NameList="NameForm" :ocliente="ocliente" @titulomodal="Change" :poBtnSave="oBtnSave" :pRutaIconoEmp="RutaIconoEmp">
                </Form>
            </template>
        </Modal>

</div>
</template>
<script>
import Modal from '@/components/Cmodal.vue';
import Clist from '@/components/Clist.vue';
import Cbtnaccion from '@/components/Cbtnaccion.vue';

import Form from '../contactosucursal/Form.vue'

export default {
    name :'list',
    props:['ocliente','tipolistp'],
    components :{
        Modal,
        Clist,
        Cbtnaccion,
        Form,
        // FormContratos
        
    },
    data() {
        return {
            NombreSeccion:'ContactoSucursal',
            FormName:'clientesForm',//Por si no es modal y queremos ir a una vista declarada en el router
            EsModal:true,//indica si es modal o no
            size :"modal-lg",
            NameList:"",
            urlApi:"crmsucursal/list",
            ListaClientes:[],
            ListaHeader:[],
            TotalPagina:2,
            Pag:0,
            oClienteP:{},
            NameForm:"Sucursal del contacto : ",
            TipoList:'',
            ShowBtns:true,
            Filtro:{
                Nombre:'',
                Placeholder:'Nombre..',
                TotalItem:0,
                Pagina:1,
                Entrada: 30
            },  
            oBtnSave:{//valores  isModal(true),nombreModal('ModalForm'),tipoModal=1,regresarA(''),disableBtn(false),txtSave('Guardar'),txtCancel('Cerrar');
                isModal:true,
                disableBtn:false,
                toast:0,
                nombreModal:"ContactoSucursal",
                EmitSeccion:"ContactoSucursal"
            },
            ConfigLoad:{
				ShowLoader:true,
				ClassLoad:true,
			},
            RutaIconoEmp:"",
            RutaLogoSucursal:"",
            EmitSeccion:"ContactoSucursal",

            NombreSeccionContratos:"Contratos",
            EmitSeccionContratos:"Contratos",
            oBtnSaveContratos:{//valores  isModal(true),nombreModal('ModalForm'),tipoModal=1,regresarA(''),disableBtn(false),txtSave('Guardar'),txtCancel('Cerrar');
                isModal:true,
                disableBtn:false,
                toast:0,
                nombreModal:"Contratos",
                EmitSeccion:"Contratos"
            },
            RolUsuario:''
        }
    },
    methods: {
        Nuevo(){
            if (this.oBtnSave.isModal==true)
            { 
                this.bus.$emit('NewModal_'+this.EmitSeccion,true);
            }
            else{
                this.bus.$emit('NewModal_'+this.EmitSeccion);
            }
        },
        Eliminar(Id)
        {
            this.$swal({
                title: 'Esta seguro que desea eliminar este dato?',
                text: 'No se podra revertir esta acción',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si',
                cancelButtonText: 'No, mantener',
                showCloseButton: true,
                showLoaderOnConfirm: true
            }).then((result) => {
                if(result.value) {
                    
                    this.$toast.success('Información eliminada');
                    this.$http.delete(
                        'clientesucursal/' + Id
                    ).then( (res) => {
                            this.Lista();
                    });
                } 
            });
        },
        async Lista()
        {
            this.ConfigLoad.ShowLoader=true;
            await this.$http.get(
                this.urlApi,
                {
                    params:{Nombre:this.Filtro.Nombre,IdSucursa:this.oClienteP.IdSucursal,IdCliente:this.oClienteP.IdCliente,Entrada:this.Filtro.Entrada,pag:this.Filtro.Pagina, RegEstatus:'A'}
                }
            ).then( (res) => {
     
                this.ListaClientes =res.data.data.clientesucursal;
                this.ListaHeader=res.data.headers;
                this.Filtro.Entrada=res.data.data.pagination.PageSize;
                this.Filtro.TotalItem=res.data.data.pagination.TotalItems;
                this.RutaIconoEmp=res.data.RutaIcoEmp;
                this.RutaLogoSucursal=res.data.RutaLogoSucursal;
                this.ConfigLoad.ShowLoader=false;
            });
        },
        Regresar(){
            this.$router.push({name:'crmcontactos', params: { tipolistp:this.TipoList}});
        },
        Change(titulo)
        {
            var bdn=true;
            if(titulo=='Selecciona la imagen'){
                bdn=false;
            }else{
                titulo=titulo+' : '+this.oClienteP.Nombre;
            }
            this.NameForm=titulo;
           
            this.bus.$emit('cambiar_CloseModal',bdn);
        },
        go_to_equipo_sucursal(objsucursal){
            this.$router.push({name:'equipos', params:{obj:objsucursal,objCliente:this.oClienteP}})
        },
        Editar(Id){
            this.bus.$emit('NewModal_'+this.EmitSeccion,false,Id);
        },
        EditarContratos(item){
            this.$router.push({
                name:"contratoclientesucursal",
                params:{
                    ocliente:item,
                    idBranchCustomer:item.IdClienteS
                }
            });
        },
        EditarOportunidades(item){
            this.$router.push({
                name:"crmoportunidad",
                params:{
                    oclientesorigen:item,
                }
            });
        },
        MostrarTodo(){
            // alert("hey");
        }
    },
    created()
    {
        this.RolUsuario=JSON.parse(sessionStorage.getItem("user")).Perfil.toLowerCase();
        if (this.tipolistp!=undefined)
        {
            sessionStorage.setItem('TipoList',JSON.stringify(this.tipolistp));
        }

        this.TipoList=JSON.parse( sessionStorage.getItem('TipoList'));
        
        if(this.TipoList=='Scanning'){
            this.ShowBtns=false;
        }

        //recibiendo objetos
        if (this.ocliente!=undefined)
        {
            sessionStorage.setItem('IdSaved',JSON.stringify(this.ocliente));
        }

        this.oClienteP=JSON.parse( sessionStorage.getItem('IdSaved'));
        this.NameList=this.NameList+" "+this.oClienteP.Nombre;
        this.NameForm="Equipos de la sucursal: "+this.oClienteP.Nombre;
        
        this.bus.$off('Delete');
        this.bus.$off('List');
        this.bus.$off('Regresar');
        this.Lista();
        this.bus.$on('Delete',(Id)=> 
        {
            this.Eliminar(Id);
            
        });

         this.bus.$on('List',()=> 
        {
            this.Lista();
            
        });
        
        this.bus.$on('Regresar',()=> 
        {
            this.Regresar();
            
        });

    }
}
</script>