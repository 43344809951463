<template>
    <div>
        <div class="row">
            <div class="col-lg-6">
                <Cloader :pConfigLoad="ConfigLoad">
                    <template slot="BodyFormLoad">
                        <draggable id="div1" :list=Proyecto.TrabajadoresDisponibles class="droppable bg-feed" group="people">
                            <div v-for="(element, index) in Proyecto.TrabajadoresDisponibles" :key="index">
                                {{ element.Nombre }}
                            </div>
                        </draggable> 
                    </template>
                </Cloader>
            </div>
            <div class="col-lg-6">
                <draggable id="div2" :list=Proyecto.TrabajadoresOcupados class="droppable" group="people">
                    <div v-for="(element, index) in Proyecto.TrabajadoresOcupados" :key="index">
                        {{ element.Nombre }}
                    </div>
                </draggable>
                <!-- <Cvalidation v-if="this.errorvalidacion.EquiposReasignados" :Mensaje="errorvalidacion.EquiposReasignados[0]"/> -->
            </div>
        </div>
    </div>
</template>

<script>
import Cloader from '@/components/CLoader.vue';
import Cvalidation from '@/components/Cvalidation.vue';
export default {
    components:{Cloader},
    name:'Form',
    props:['Id','poBtnSave'],
    data() {
        return {
            Modal:true,
            FormName:'Trabajador',
            EmitSeccion:this.poBtnSave.EmitSeccion,
            Proyecto:{
                IdProyecto:0,
                TrabajadoresDisponibles:[],
                TrabajadoresOcupados:[]
            },
            ConfigLoad:{
                ShowLoader: false,
				ClassLoad:  true,
            },
        }
    },
    methods: {
        async Guardar(){
            this.poBtnSave.DisableBtn=true;
            let formData=new FormData();
            formData.set('IdProyecto',this.Proyecto.IdProyecto);
            formData.set('Trabajadores',JSON.stringify(this.Proyecto.TrabajadoresOcupados));
            await this.$http.post('proyectostrabajador/post',formData).then((res)=>{
                this.poBtnSave.DisableBtn=false;
                $("#AgregarTrabajador").modal("hide");
                this.bus.$emit('List');
            }).catch((err)=>{
                this.poBtnSave.DisableBtn=false;
            })
        },
        async Recuperar(){
            await this.$http.get('proyectostrabajador/get',{
                params:{
                    IdProyecto:this.Proyecto.IdProyecto
                }
            }).then((res)=>{
                this.Proyecto.TrabajadoresDisponibles=res.data.data.disponibles;
                this.Proyecto.TrabajadoresOcupados=res.data.data.ocupados;
            })
        },
        Limpiar(){
            this.Proyecto={
                IdProyecto:0,
                TrabajadoresDisponibles:[],
                TrabajadoresOcupados:[]
            }
        }
    },
    created() {
        this.bus.$off('NewModal_'+this.EmitSeccion);
        this.bus.$on('NewModal_'+this.EmitSeccion,(data,Id)=>{
            this.poBtnSave.disableBtn=false; 
            this.bus.$off('Save_'+this.EmitSeccion);
            this.bus.$on('Save_'+this.EmitSeccion,()=>
            {
                this.Guardar();
            });
            this.Limpiar();
            this.Proyecto.IdProyecto=Id;
            this.Recuperar();
        });
    },
}
</script>

<style>

</style>