<template>
	<div>
		<section class="container-fluid">
			<div class="row mt-3">
				<div class="col-12 col-sm-12 col-md-12 col-lg-12">
					<nav class="navbar navbar-breadcrumb navbar-expand-md bg-breadcrumb breadcrumb-borde">
						<div class="mr-auto">
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb clearfix pt-3">
                                    <li class="breadcrumb-item"><a href="#" @click="Menu">Menú</a></li>
                                    <li class="breadcrumb-item active">Generar Spend Plan</li>
                                </ol>
                            </nav>
                        </div>
						<div class="form-inline">
                            <div class="form-group mt-n1">
                                <!-- <button :disabled="BlockImprimir" @click="Descargar" type="button" class="btn btn-01 print mr-2"><i v-show="BlockImprimir" class="fa fa-spinner fa-pulse fa-1x fa-fw"></i>Imprimir</button> -->
                                <button :disabled="Disabled" @click="Guardar" type="button" class="btn btn-01 save mr-2"><i v-show="LoaderSpiral" class="fa fa-spinner fa-pulse fa-1x fa-fw"></i> Guardar</button>
                            </div>
                        </div>
					</nav>
				</div>
			</div>
			<div class="row justify-content-start mt-3">
				<div class="col-12 col-sm-12 col-md-12 col-lg-12">
					<div class="card card-01">
						<div class="row">
							<div class="col-md-12 col-lg-12">
								<div class="form-group row">
									<div class="col-lg-2 pr-0">
										<label>Cliente</label>
										<treeselect
											@input="GetListaSucursales();GetTabla()"
											:options="Clientes"
											class="d-inline-block mt-0 pt-0 mr-2"
											placeholder="Todos"
											v-model="IdCliente"
										/>
									</div>
									<div class="col-lg-2 pl-1">
										<label>Propiedad</label>
										<treeselect
											@input="GetNumContrato();GetTabla()"
											:options="Propiedades"
											class="d-inline-block mt-0 pt-0 mr-2"
											placeholder="Todas"
											v-model="IdClienteS"
										/>
									</div>
									<div class="col-lg-1 pl-0">
										<label class="mr-1">Servicio</label>
                                    	<select @change="GetSubTipo();" v-model="IdConfigS" class="form-control mr-2">
                                    	    <option v-for="(item, index) in Servicios" :key="index" :value="item.IdConfigS">{{item.Nombre}}</option>
                                    	    <option :value="6">Todos</option>
                                    	</select>
									</div>
									<div class="col-lg-1 p-0">
										<label class="mr-1">Tipo Servicio</label>
                                    	<select  @change="GetTabla();" v-model="IdTipoSer" class="form-control mr-2">
                                    	    <option :value="''">Todos</option>
                                    	    <option v-for="(item, index) in TiposServicio" :key="index" :value="item.IdTipoSer">{{item.Concepto}}</option>
                                    	</select>
                                    	<Cvalidation v-if="this.errorvalidacion.IdTipoServ" :Mensaje="'Seleccione el Servicio'"></Cvalidation>
									</div>
									<div class="col-lg-2 pr-0">
										<label class="mr-1">No. De Contrato</label>
                                    	<treeselect
											@input="GetTabla()"
											:options="Contratos"
											class="d-inline-block mt-0 pt-0 mr-2"
											placeholder="Todos"
											v-model="IdContrato"
										/>
										<!-- <select @change="GetTabla" v-model="IdContrato" name="" id=""  class="form-control form-control-sm mr-2">
                                    	    <option :value="''">Seleccione Un Numero de Contrato</option>
                                    	    <option v-for="(item,index) in Contratos" :key="index" :value="item.IdContrato">
                                    	    	{{item.NumeroC}}
                                    	    </option>
                                    	</select> -->
									</div>
									<div class="col-lg-1 pl-1">
										<label class="mr-1">Año</label>
                                    	<select @change="CambiarAnio" v-model="Anio" class="form-control mr-2">
                                    	    <option v-for="(item,index) in Anios" :key="index" :value="item">{{item}}</option>
                                    	</select>
									</div>
									<div class="col-lg-1 pl-1" v-if="IdContrato!=null">
										<label for="">Valor Total Contrato</label>
										<Cmoneda :activo="true" :minus="true" :clase="'form-control form-finanza form-control-sm text-center bold spend-plan-numeros'"  currency="$" separator="," :precision="2" v-model="Contrato.Valor" />
									</div>
									<div class="col-lg-1 pl-1" v-if="IdContrato!=null">
										<label for="">Gross Profit</label>
										<Cporcentaje :activo="true" :minus="true" :clase="'form-control form-finanza form-control-sm color-01 bold text-center'"  currency="%" separator="," :precision="2" v-model="Contrato.GrossProfit"/>
									</div>
								</div>
								<div class="form-group row">
									<div class="col-lg-12">
										<ul>
											<li v-for="(item,index) in errorvalidacion" :key="index">
												<Cvalidation :Mensaje="item"></Cvalidation>
											</li>
										</ul>
									</div>
									<!-- <label for=""><Cvalidation :Mensaje="item"></Cvalidation></label> -->
								</div>
							</div>
						</div>
						<hr>
						<div class="row mt-2">
							<div class="col-12 col-sm-12 col-md-12 col-lg-12">
								<div class="table-finanzas">
									<CLoader :pConfigLoad="ConfigLoad">
										<template slot="BodyFormLoad">
											<table class="table-fin-06">
												<thead>
													<tr>
														<th class="sticky mediana marca">Descripcion</th>
														<th class="blue-02">Enero</th>
														<th class="blue-02">Febrero</th>
														<th class="blue-02">Marzo</th>
														<th class="blue-02">Abril</th>
														<th class="blue-02">Mayo</th>
														<th class="blue-02">Junio</th>
														<th class="blue-02">Julio</th>
														<th class="blue-02">Agosto</th>
														<th class="blue-02">Septiembre</th>
														<th class="blue-02">Octubre</th>
														<th class="blue-02">Noviembre</th>
														<th class="blue-02">Diciembre</th>
													</tr>
												</thead>
												<tbody v-if="!MultiAnual">
													<tr>
														<td class="sticky"><b>Facturación</b></td>
														<td v-for="(item,index) in ListaPlan.TotalFacturacion" :key="index" class="sticky borde_bajo">
															<Cmoneda @input="CambiarTotales(index)" :activo="MesesDesactivados[index]" :minus="true" :clase="'form-control form-finanza form-control-sm text-center bold spend-plan-numeros'"  currency="$" separator="," :precision="2" v-model="item.Total" />  
														</td>
													</tr>
													<tr>
														<td class="sticky"><b>Equipos</b></td>
														<td v-for="(item,index) in ListaPlan.TotalEquipos" :key="index" class="sticky borde_bajo">
															<Cmoneda @input="CambiarTotales(index)" :activo="MesesDesactivados[index]" :minus="true" :clase="'form-control form-finanza form-control-sm text-center bold'"  currency="$" separator="," :precision="2" v-model="item.Total" />  
														</td>
													</tr>
													<tr>
														<td class="sticky"><b>Materiales</b></td>
														<td v-for="(item,index) in ListaPlan.TotalMateriales" :key="index" class="sticky borde_bajo">
															<Cmoneda @input="CambiarTotales(index)" :activo="MesesDesactivados[index]" :minus="true" :clase="'form-control form-finanza form-control-sm text-center bold'"  currency="$" separator="," :precision="2" v-model="item.Total" />  
														</td>
													</tr>
													<tr>
														<td class="sticky"><b>Mano de Obra</b></td>
														<td v-for="(item,index) in ListaPlan.TotalManoObra" :key="index" class="sticky borde_bajo">
															<Cmoneda @input="CambiarTotales(index)" :activo="MesesDesactivados[index]" :minus="true" :clase="'form-control form-finanza form-control-sm text-center bold'"  currency="$" separator="," :precision="2" v-model="item.Total" />  
														</td>
													</tr>
													<tr>
														<td class="sticky"><b>Vehículos</b></td>
														<td v-for="(item,index) in ListaPlan.TotalVehiculos" :key="index" class="sticky borde_bajo">
															<Cmoneda @input="CambiarTotales(index)" :activo="MesesDesactivados[index]" :minus="true" :clase="'form-control form-finanza form-control-sm text-center bold'"  currency="$" separator="," :precision="2" v-model="item.Total" />  
														</td>
													</tr>
													<tr>
														<td class="sticky"><b>Viáticos</b></td>
														<td v-for="(item,index) in ListaPlan.TotalViaticos" :key="index" class="sticky borde_bajo">
															<Cmoneda @input="CambiarTotales(index)" :activo="MesesDesactivados[index]" :minus="true" :clase="'form-control form-finanza form-control-sm text-center bold'"  currency="$" separator="," :precision="2" v-model="item.Total" />  
														</td>
													</tr>
													<tr>
														<td class="sticky"><b>Burden</b></td>
														<td v-for="(item,index) in ListaPlan.TotalBurden" :key="index" class="sticky borde_bajo">
															<Cmoneda @input="CambiarTotales(index)" :activo="MesesDesactivados[index]" :minus="true" :clase="'form-control form-finanza form-control-sm text-center bold'"  currency="$" separator="," :precision="2" v-model="item.Total" />  
														</td>
													</tr>
													<tr>
														<td class="sticky"><b>Contratista</b></td>
														<td v-for="(item,index) in ListaPlan.TotalContratistas" :key="index" class="sticky borde_bajo">
															<Cmoneda @input="CambiarTotales(index)" :activo="MesesDesactivados[index]" :minus="true" :clase="'form-control form-finanza form-control-sm text-center bold'"  currency="$" separator="," :precision="2" v-model="item.Total" />  
														</td>
													</tr>
												</tbody>
												<tbody v-else>
													<tr>
														<td class="sticky"><b>Facturación</b></td>
														<td v-for="(item,index) in ListaPlan[Anio].TotalFacturacion" :key="index" class="sticky borde_bajo">
															<Cmoneda @input="CambiarTotales(index)" :activo="MesesDesactivados[Anio][index]" :minus="true" :clase="'form-control form-finanza form-control-sm text-center bold spend-plan-numeros'"  currency="$" separator="," :precision="2" v-model="item.Total" />  
														</td>
													</tr>
													<tr>
														<td class="sticky"><b>Equipos</b></td>
														<td v-for="(item,index) in ListaPlan[Anio].TotalEquipos" :key="index" class="sticky borde_bajo">
															<Cmoneda @input="CambiarTotales(index)" :activo="MesesDesactivados[Anio][index]" :minus="true" :clase="'form-control form-finanza form-control-sm text-center bold'"  currency="$" separator="," :precision="2" v-model="item.Total" />  
														</td>
													</tr>
													<tr>
														<td class="sticky"><b>Materiales</b></td>
														<td v-for="(item,index) in ListaPlan[Anio].TotalMateriales" :key="index" class="sticky borde_bajo">
															<Cmoneda @input="CambiarTotales(index)" :activo="MesesDesactivados[Anio][index]" :minus="true" :clase="'form-control form-finanza form-control-sm text-center bold'"  currency="$" separator="," :precision="2" v-model="item.Total" />  
														</td>
													</tr>
													<tr>
														<td class="sticky"><b>Mano de Obra</b></td>
														<td v-for="(item,index) in ListaPlan[Anio].TotalManoObra" :key="index" class="sticky borde_bajo">
															<Cmoneda @input="CambiarTotales(index)" :activo="MesesDesactivados[Anio][index]" :minus="true" :clase="'form-control form-finanza form-control-sm text-center bold'"  currency="$" separator="," :precision="2" v-model="item.Total" />  
														</td>
													</tr>
													<tr>
														<td class="sticky"><b>Vehículos</b></td>
														<td v-for="(item,index) in ListaPlan[Anio].TotalVehiculos" :key="index" class="sticky borde_bajo">
															<Cmoneda @input="CambiarTotales(index)" :activo="MesesDesactivados[Anio][index]" :minus="true" :clase="'form-control form-finanza form-control-sm text-center bold'"  currency="$" separator="," :precision="2" v-model="item.Total" />  
														</td>
													</tr>
													<tr>
														<td class="sticky"><b>Viáticos</b></td>
														<td v-for="(item,index) in ListaPlan[Anio].TotalViaticos" :key="index" class="sticky borde_bajo">
															<Cmoneda @input="CambiarTotales(index)" :activo="MesesDesactivados[Anio][index]" :minus="true" :clase="'form-control form-finanza form-control-sm text-center bold'"  currency="$" separator="," :precision="2" v-model="item.Total" />  
														</td>
													</tr>
													<tr>
														<td class="sticky"><b>Burden</b></td>
														<td v-for="(item,index) in ListaPlan[Anio].TotalBurden" :key="index" class="sticky borde_bajo">
															<Cmoneda @input="CambiarTotales(index)" :activo="MesesDesactivados[Anio][index]" :minus="true" :clase="'form-control form-finanza form-control-sm text-center bold'"  currency="$" separator="," :precision="2" v-model="item.Total" />  
														</td>
													</tr>
													<tr>
														<td class="sticky"><b>Contratista</b></td>
														<td v-for="(item,index) in ListaPlan[Anio].TotalContratistas" :key="index" class="sticky borde_bajo">
															<Cmoneda @input="CambiarTotales(index)" :activo="MesesDesactivados[Anio][index]" :minus="true" :clase="'form-control form-finanza form-control-sm text-center bold'"  currency="$" separator="," :precision="2" v-model="item.Total" />  
														</td>
													</tr>
												</tbody>

												<tfoot v-if="!MultiAnual">
													<tr>
														<td class="color-01 sticky marca"><b>Costo Operacional</b></td>
														<td v-for="(item,index) in ListaPlan.TotalCostoOperacional" :key="index" class="sticky borde_bajo">
															<Cmoneda :activo="true" :minus="true" :clase="'form-control form-finanza form-control-sm color-01 bold text-center'"  currency="$" separator="," :precision="2" v-model="item.Total" />  
														</td>
													</tr>
													<tr>
														<td class="color-01 sticky marca"><b>Gross Profit</b></td>
														<td v-for="(item,index) in ListaPlan.TotalGrossProfit" :key="index" class="sticky borde_bajo">
															<Cmoneda :activo="true" :minus="true" :clase="'form-control form-finanza form-control-sm color-01 bold text-center'"  currency="$" separator="," :precision="2" v-model="item.Total" />  
														</td>
													</tr>
													<tr>
														<td class="color-01 sticky marca"><b>Utilidad Aproximada</b></td>
														<td v-for="(item,index) in ListaPlan.TotalUtilidadAproximada" :key="index" class="sticky borde_bajo">
															<Cmoneda :activo="true" :minus="true" :clase="'form-control form-finanza form-control-sm color-01 bold text-center'"  currency="$" separator="," :precision="2" v-model="item.Total" />  
														</td>
													</tr>
												</tfoot>

												<tfoot v-else>
													<tr>
														<td class="color-01 sticky marca"><b>Costo Operacional</b></td>
														<td v-for="(item,index) in ListaPlan[Anio].TotalCostoOperacional" :key="index" class="sticky borde_bajo">
															<Cmoneda :activo="true" :minus="true" :clase="'form-control form-finanza form-control-sm color-01 bold text-center'"  currency="$" separator="," :precision="2" v-model="item.Total" />  
														</td>
													</tr>
													<tr>
														<td class="color-01 sticky marca"><b>Gross Profit</b></td>
														<td v-for="(item,index) in ListaPlan[Anio].TotalGrossProfit" :key="index" class="sticky borde_bajo">
															<Cmoneda :activo="true" :minus="true" :clase="'form-control form-finanza form-control-sm color-01 bold text-center'"  currency="$" separator="," :precision="2" v-model="item.Total" />  
														</td>
													</tr>
													<tr>
														<td class="color-01 sticky marca"><b>Utilidad Aproximada</b></td>
														<td v-for="(item,index) in ListaPlan[Anio].TotalUtilidadAproximada" :key="index" class="sticky borde_bajo">
															<Cmoneda :activo="true" :minus="true" :clase="'form-control form-finanza form-control-sm color-01 bold text-center'"  currency="$" separator="," :precision="2" v-model="item.Total" />  
														</td>
													</tr>
												</tfoot>
											</table>
										</template>
									</CLoader>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import Cvalidation from '@/components/Cvalidation.vue';
export default {
	components:{Cvalidation},
    data() {
        return {
            Head:{
				Title:'GenerarSpendPlan',
				BtnNewShow:false,
				BtnNewName:'Nuevo',
				isreturn:true,
				isModal:false,
				isEmit:false,
				Url:'MenusPM',
				ObjReturn:'',
			},
            errorvalidacion :[],
			ConfigLoad:{
				ShowLoader:true,
				ClassLoad:true,
			},
			Anios:[],
			Clientes:[],
			Propiedades:[],
			Contratos:[],
			Servicios:[],
			TiposServicio:[],
			IdCliente: null,
			IdClienteS: null,
			IdContrato:null,
			IdConfigS:'',
			IdTipoSer:'',
			Anio:new Date('Y'),
			ListaPlan:[],
			EnActivo:false,
			Disabled:true,
			LoaderSpiral:true,
			Contrato:{
				IdContrato:'',
				NumeroC:'',
				Valor:0,
				GrossProfit:0
			},
			MesesDesactivados:[],
			MultiAnual:false,
			AniosContrato:[]
        }
    },
    methods: {
        async GetTabla(){
			this.errorvalidacion=[];
			this.LoaderSpiral=true;
			this.ConfigLoad.ShowLoader=true;
			this.Disabled=true;
			this.AniosContrato=[];
			await this.$http.get('spendplancontrato/get',{
				params:{
					IdConfigS:this.IdConfigS,
					IdTipoSer:this.IdTipoSer,
					IdCliente:this.IdCliente,
					IdClienteS:this.IdClienteS,
					IdContrato:this.IdContrato,
					Anio:this.Anio
				}
			}).then((res)=>{
				if (this.IdCliente==null || this.IdClienteS==null || this.IdConfigS=='6' || this.IdTipoSer=='' || this.IdContrato==null) {
					this.EnActivo=true;
					this.Disabled=true;
				}
				else{
					this.EnActivo=false;
					this.Disabled=false;
				}
				if (this.IdContrato!=null) {
					this.Contrato.IdContrato=res.data.data.Contrato.IdContrato;
					this.Contrato.NumeroC=res.data.data.Contrato.NumeroC;
					this.Contrato.Valor=res.data.data.Contrato.Valor;
					this.Contrato.GrossProfit=res.data.data.Contrato.GrossProfit;
				}
				// this.EnActivo=this.IdContrato!='' ? false : true;
				this.ListaPlan=res.data.data.Totales;
				this.MultiAnual=res.data.data.MultiAnual;
				if (this.MultiAnual) {
					this.AniosContrato=res.data.data.Anios;
					res.data.data.Anios.forEach((Anio)=>{
						for (let index = 0; index < res.data.data.MesesDesactivados[parseInt(Anio)].length; index++) {
							if (this.IdTipoSer=='' || this.IdConfigS==6){
								res.data.data.MesesDesactivados[parseInt(Anio)][index]=true;
							}							
						}
						// res.data.data.MesesDesactivados[parseInt(Anio)].forEach((Mes) => {
						// 	if (this.IdTipoSer=='' || this.IdConfigS==6) {
						// 		Mes=true;
						// 	}
						// });
					})
				}
				else{
					for (let index = 0; index < res.data.data.MesesDesactivados.length; index++) {
						if (this.IdTipoSer=='' || this.IdConfigS==6){
							res.data.data.MesesDesactivados[index]=true;
						}							
					}
				}
				this.MesesDesactivados=res.data.data.MesesDesactivados;
			}).finally(()=>{
				this.ConfigLoad.ShowLoader=false;
				this.LoaderSpiral=false;
			})
		},
		Guardar(){
			this.Disabled=true;
			this.LoaderSpiral=true;
			this.errorvalidacion=[];
			let formData=new FormData();
			formData.set('IdConfigS',this.IdConfigS);
			formData.set('IdTipoSer',this.IdTipoSer);
			formData.set('IdCliente',this.IdCliente);
			formData.set('IdClienteS',this.IdClienteS);
			formData.set('IdContrato',this.IdContrato);
			formData.set('Anio',this.Anio);
			formData.set('GrossProfit',this.Contrato.GrossProfit);
			formData.set('Valor',this.Contrato.Valor);
			formData.set('MultiAnual',this.MultiAnual);
			formData.append('SpendPlan',JSON.stringify(this.ListaPlan));
			this.$http.post('spendplancontrato/post',formData
			).then((res)=>{
				this.Disabled=false;
				this.LoaderSpiral=false;
				this.$toast.success('Información Guardada');
			}).catch((err)=>{
				this.errorvalidacion=err.response.data.errors;
				this.LoaderSpiral=false;
				this.Disabled=false;
			});
		},
		Menu(){
			this.$router.push({name:'menuspm'});
		},
		CambiarTotales(Index){
			if (this.MultiAnual) {
				let TotalFacturacion=isNaN(this.ListaPlan[this.Anio].TotalFacturacion[Index].Total) || this.ListaPlan[this.Anio].TotalFacturacion[Index].Total=='' ? 0 : this.ListaPlan[this.Anio].TotalFacturacion[Index].Total;
				let TotalEquipos=isNaN(this.ListaPlan[this.Anio].TotalEquipos[Index].Total) || this.ListaPlan[this.Anio].TotalEquipos[Index].Total=='' ? 0 : this.ListaPlan[this.Anio].TotalEquipos[Index].Total;
				let TotalMateriales=isNaN(this.ListaPlan[this.Anio].TotalMateriales[Index].Total) || this.ListaPlan[this.Anio].TotalMateriales[Index].Total=='' ? 0 : this.ListaPlan[this.Anio].TotalMateriales[Index].Total;
				let TotalManoObra=isNaN(this.ListaPlan[this.Anio].TotalManoObra[Index].Total) || this.ListaPlan[this.Anio].TotalManoObra[Index].Total=='' ? 0 : this.ListaPlan[this.Anio].TotalManoObra[Index].Total;
				let TotalVehiculos=isNaN(this.ListaPlan[this.Anio].TotalVehiculos[Index].Total) || this.ListaPlan[this.Anio].TotalVehiculos[Index].Total=='' ? 0 : this.ListaPlan[this.Anio].TotalVehiculos[Index].Total;
				let TotalViaticos=isNaN(this.ListaPlan[this.Anio].TotalViaticos[Index].Total) || this.ListaPlan[this.Anio].TotalViaticos[Index].Total=='' ? 0 : this.ListaPlan[this.Anio].TotalViaticos[Index].Total;
				let TotalBurden=isNaN(this.ListaPlan[this.Anio].TotalBurden[Index].Total) || this.ListaPlan[this.Anio].TotalBurden[Index].Total=='' ? 0 : this.ListaPlan[this.Anio].TotalBurden[Index].Total;
				let TotalContratistas=isNaN(this.ListaPlan[this.Anio].TotalContratistas[Index].Total) || this.ListaPlan[this.Anio].TotalContratistas[Index].Total=='' ? 0 : this.ListaPlan[this.Anio].TotalContratistas[Index].Total;
				this.ListaPlan[this.Anio].TotalCostoOperacional[Index].Total=TotalEquipos+TotalMateriales+TotalManoObra+TotalVehiculos+TotalViaticos+TotalBurden+TotalContratistas;
				this.ListaPlan[this.Anio].TotalGrossProfit[Index].Total=TotalFacturacion-this.ListaPlan[this.Anio].TotalCostoOperacional[Index].Total;
				if (this.Contrato.IdContrato!=null) {
					let PorcentajeGP=Number(this.Contrato.GrossProfit)/100;
					let PorcentajeUtilidad=(Number(this.Contrato.GrossProfit)-25)/100;
					let PorcentajeStr=PorcentajeUtilidad.toString().slice(2);
					let ValorGP=(this.ListaPlan[this.Anio].TotalGrossProfit[Index].Total*parseFloat('1.'+PorcentajeGP)).toFixed(0);
					let ValorUtilidad=(this.ListaPlan[this.Anio].TotalGrossProfit[Index].Total*parseFloat('1.'+PorcentajeStr)).toFixed(0);
					this.ListaPlan[this.Anio].TotalUtilidadAproximada[Index].Total=ValorUtilidad;
				}
			}
			else{
				let TotalFacturacion=isNaN(this.ListaPlan.TotalFacturacion[Index].Total) || this.ListaPlan.TotalFacturacion[Index].Total=='' ? 0 : this.ListaPlan.TotalFacturacion[Index].Total;
				let TotalEquipos=isNaN(this.ListaPlan.TotalEquipos[Index].Total) || this.ListaPlan.TotalEquipos[Index].Total=='' ? 0 : this.ListaPlan.TotalEquipos[Index].Total;
				let TotalMateriales=isNaN(this.ListaPlan.TotalMateriales[Index].Total) || this.ListaPlan.TotalMateriales[Index].Total=='' ? 0 : this.ListaPlan.TotalMateriales[Index].Total;
				let TotalManoObra=isNaN(this.ListaPlan.TotalManoObra[Index].Total) || this.ListaPlan.TotalManoObra[Index].Total=='' ? 0 : this.ListaPlan.TotalManoObra[Index].Total;
				let TotalVehiculos=isNaN(this.ListaPlan.TotalVehiculos[Index].Total) || this.ListaPlan.TotalVehiculos[Index].Total=='' ? 0 : this.ListaPlan.TotalVehiculos[Index].Total;
				let TotalViaticos=isNaN(this.ListaPlan.TotalViaticos[Index].Total) || this.ListaPlan.TotalViaticos[Index].Total=='' ? 0 : this.ListaPlan.TotalViaticos[Index].Total;
				let TotalBurden=isNaN(this.ListaPlan.TotalBurden[Index].Total) || this.ListaPlan.TotalBurden[Index].Total=='' ? 0 : this.ListaPlan.TotalBurden[Index].Total;
				let TotalContratistas=isNaN(this.ListaPlan.TotalContratistas[Index].Total) || this.ListaPlan.TotalContratistas[Index].Total=='' ? 0 : this.ListaPlan.TotalContratistas[Index].Total;
				this.ListaPlan.TotalCostoOperacional[Index].Total=TotalEquipos+TotalMateriales+TotalManoObra+TotalVehiculos+TotalViaticos+TotalBurden+TotalContratistas;
				this.ListaPlan.TotalGrossProfit[Index].Total=TotalFacturacion-this.ListaPlan.TotalCostoOperacional[Index].Total;
				if (this.Contrato.IdContrato!=null) {
					let PorcentajeGP=Number(this.Contrato.GrossProfit)/100;
					let PorcentajeUtilidad=(Number(this.Contrato.GrossProfit)-25)/100;
					let ValorGP=(this.ListaPlan.TotalGrossProfit[Index].Total*parseFloat('1.'+PorcentajeGP)).toFixed(0);
					let PorcentajeStr=PorcentajeUtilidad.toString().slice(2);
					// console.log(PorcentajeStr);
					// console.log(parseFloat('1.'+PorcentajeStr));
					let ValorUtilidad=this.ListaPlan.TotalGrossProfit[Index].Total*parseFloat('1.'+PorcentajeStr);
					this.ListaPlan.TotalUtilidadAproximada[Index].Total=ValorUtilidad.toFixed(0);
				}
			}
		},
		async GetAnios(){
			// this.Disabled=true;
			await this.$http.get(
				'funciones/getanios',
				{
					params:{}
				}
			).then( (res) => {
				this.Anios=res.data.ListaAnios;
				this.Anio=res.data.AnioActual;
				this.GetTipoServicio();
			});
		},
		async GetListaClientes(){
			await this.$http.get("clientes/get"
			).then((res)=>{
				this.Clientes=res.data.data.clientes.map(function(obj){
					return {id:obj.IdCliente,label:obj.Nombre}
				})
			});
		},
		async GetListaSucursales(){
			this.IdClienteS=null;
			this.IdContrato=null;
			await this.$http.get("clientesucursal/get",{
				params:{
					Todos:true,
					RegEstatus:'A',
					IdCliente:this.IdCliente
				}
			}
			).then((res)=>{
				this.Propiedades=res.data.data.sucursal.map(function(obj){
					return {id:obj.IdClienteS,label:obj.Nombre}
				})
			});
		},
		async GetNumContrato(){
			this.IdContrato=null;
			if (this.IdClienteS!=null) {
				await this.$http.get(
				'numcontrato/get',
				{
					params:{IdClienteS:this.IdClienteS}
				}
				).then( (res) => {
					this.Contratos=res.data.data.contractlist.map(function(obj){
						return {id:obj.IdContrato,label:obj.NumeroC}
					});
					// this.Contratos =res.data.data.contractlist;

				});
			}
		},
		async GetTipoServicio(){
			this.$http.get('baseactual/get',{
				params:{
					RegEstatus:'A',
					Entrada:''
				}
			}).then( (res) => {
				this.Servicios	= res.data.data.lista;
				this.IdConfigS		= this.Servicios[0].IdConfigS;
				this.GetSubTipo();
			});
		},
		async GetSubTipo(){
			this.IdTipoSer='';
			if (this.IdConfigS>0)
			{
				await this.$http.get('tiposervicio/get',{
					params:{
						RegEstatus:'A',
						IdConfigS:this.IdConfigS,
						// IdTipoSer:this.IdTipoSer,
						Entrada:''
					}
				}).then( (res) => {
					this.TiposServicio =res.data.data.tiposervicio;
					// this.IdTipoSer="";
					this.GetTabla();
				});
			}
		},
		CambiarAnio(){
			if (!this.MultiAnual || !this.AniosContrato.includes(this.Anio)) {
				this.MultiAnual=false;
				this.ListaPlan=[];
				this.GetTabla();
			}
		}
    },
	created() {
		this.GetAnios();
		this.GetListaClientes();
	},
	mounted() {
		// this.GetTabla();
	},
}
</script>

<style>

</style>