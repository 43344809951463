<template>
	<div>
		<!-- <div class="form-group form-row">
			<div class="col-lg-7 ">
				<label>Actividad</label>
				<input :disabled="CerradoEstatus" type="text" v-model="objproceso.Actividad" class="form-control form-control-sm" placeholder="Actividad" id="Actividad" name="Actividad"/>
				<Cvalidation v-if="this.errorvalidacion.Actividad" :Mensaje="errorvalidacion.Actividad[0]"/>
			</div>

			<div class="col-lg-5">
				<label>Vendedor</label>
				<select @change="LimpiarOportunidad" :disabled="CerradoEstatus" v-model="objproceso.IdTrabajador" class="form-control form-control-sm" >
					<option :value="''">Seleccione un vendedor</option>
					<option v-for="(item, index) in Listavendedor" :key="index" :value="item.IdUsuario" >
						{{ item.Nombre }}
					</option>
				</select>
				<Cvalidation v-if="this.errorvalidacion.Vendedor" :Mensaje="errorvalidacion.Vendedor[0]"/>
			</div>
		</div> -->
		<CLoader :pConfigLoad="ConfigLoad">
			<template slot="BodyFormLoad">
				<div class="form-group form-row">
					<div class="col-lg-12 mb-2">
						<label for="">Cliente</label>
						<input type="text" readonly="readonly" v-model="objproceso.NomCliente" class="form-control form-control-sm" placeholder="Cliente" />
					</div>
					<div class="col-lg-12 mb-2" v-if="RolUsuario=='Admin' || IdUsuario!=objproceso.IdTrabajador">
						<label for="">Responsable *</label>
						<!-- <select @change="LimpiarOportunidad" :disabled="issetOportunidad" v-model="objproceso.IdTrabajador" class="form-control form-control-sm" > -->
							<select :disabled="RolUsuario != 'Admin' && IdUsuario!=objproceso.IdTrabajador" @change="ListarRespSecundarios" v-model="objproceso.IdTrabajador" class="form-control form-control-sm" >
							<option :value="''">Seleccione un usuario</option>
							<option v-for="(item, index) in Listavendedor" :key="index" :value="item.IdUsuario" >
								{{ item.Nombre }}
							</option>
						</select>
						<Cvalidation v-if="this.errorvalidacion.Vendedor" :Mensaje="errorvalidacion.Vendedor[0]"/>
					</div>
					<div class="col-lg-12 mb-2">
						<label for="">Responsables Secundarios <small>(Max. 4 Responsables)</small></label>
						<treeselect
							:disabled="RolUsuario != 'Admin' && IdUsuario!=objproceso.IdTrabajador" 
							:multiple="true" 
							placeholder="Responsables Secundarios" 
							:options="ListaResponsablesSecundarios"
							v-model="objproceso.RespSecundarios"
							>
						</treeselect>
					</div>
					<!-- <div v-if="objproceso.IdTrabajador>0" :class="buscarOportunidad ? 'col-lg-9 mb-2' : 'col-lg-12 mb-2'">
						<label>Oportunidad </label>
						<input type="text" readonly="readonly" v-model="objproceso.Oportunidad" class="form-control form-control-sm" placeholder="Oportunidad" id="Oportunidad" name="Oportunidad" />
						<Cvalidation v-if="this.errorvalidacion.Oportunidad" :Mensaje="errorvalidacion.Oportunidad[0]"/>
					</div> -->
		
					<!-- <div class="col-lg-3 mb-2" v-if="objproceso.IdTrabajador>0 && buscarOportunidad">
						<div class="form-inline">
							<button :disabled="CerradoEstatus" @click="ListarOportunidad(objproceso.IdTrabajador)" data-toggle="modal" data-target="#ModalForm3" data-backdrop="static" type="button" class="btn btn-01 search mt-3b" >
								Buscar
							</button>
						</div>
					</div> -->
				</div>
		
				<div v-if="Origen!='activas'" class="form-group form-row">
					<div class="col-lg-12 mb-2" v-if="objproceso.IdTrabajador>0">
						<label>Contacto </label>
						<input type="text" readonly="readonly" v-model="objproceso.NomCliente" class="form-control form-control-sm" placeholder="Cliente" />
						<label id="lblmsuser" style="Cliente:red" v-if="this.errorvalidacion.Cliente"><Cvalidation :Mensaje="errorvalidacion.Cliente[0]" ></Cvalidation ></label>
					</div>
				</div>
				<div v-if="Origen!='activas'" class="form-group form-row">
					<div class="col-lg-12 mb-2" v-if="objproceso.IdTrabajador>0">
						<label>Sucursal</label>
						<input type="text" readonly="readonly" v-model="objproceso.Cliente" class="form-control form-control-sm" placeholder="Cliente" />
						<label id="lblmsuser" style="Cliente:red" v-if="this.errorvalidacion.Cliente"><Cvalidation :Mensaje="errorvalidacion.Cliente[0]" ></Cvalidation ></label>
					</div>
				</div>
		
				<div class="form-group form-row mb-2">
					<div class="col-lg-3 mb-2">
						<label>Fecha *</label>
						<v-date-picker v-model="objproceso.Fecha" :mode="typeCalendar" :min-date="MinimoFecha()"
							:popover="{
								placement: 'bottom',
								visibility: 'click'
							}"
							:input-props="{
								class: 'form-control form-control-sm cal-black',
								style: 'cursor:pointer;'
							}"
							:disabled="RolUsuario != 'Admin' && IdUsuario!=objproceso.IdTrabajador"
						/>
						<label id="lblmsuser" style="TipoProceso:red" v-if="this.errorvalidacion.Fecha" ><Cvalidation :Mensaje="errorvalidacion.Fecha[0]" ></Cvalidation ></label>
					</div>
					<div class="col-lg-2 d-flex align-items-center mt-3">
						<div>
							<label class="check_box">
								<input type="checkbox" v-model="isAlarmaChecked" @input="EliminarFechaAlarma">
								<span class="checkmark" style="border-color: #BDBDBD;"></span>
							</label>
							<span class="pl-4 ml-1">Alarma</span>
						</div>
					</div>
					<div v-show="isAlarmaChecked" class="col-lg-4">
						<label>Fecha Alarma</label>
						<v-date-picker v-model="objproceso.FechaAlarma" :mode="typeCalendar"
							:popover="{
								placement: 'bottom',
								visibility: 'click',
							}"
							:input-props="{
								class: 'form-control form-control-sm cal-black',
								style: 'cursor:pointer;',
							}"
						/>
						<label id="lblmsuser" style="TipoProceso:red" v-if="this.errorvalidacion.FechaAlarma">
							<Cvalidation :Mensaje="errorvalidacion.FechaAlarma[0]"/>
						</label>
					</div>
				</div>
		
				<div class="row mb-3">
					<div class="col-lg-12">
						<label>Actividad *</label>
						<textarea :disabled="RolUsuario != 'Admin' && IdUsuario!=objproceso.IdTrabajador" class="form-control form-control-sm" name="" v-model="objproceso.Actividad" id="" cols="30" rows="3" ></textarea>
						<Cvalidation v-if="this.errorvalidacion.Actividad" :Mensaje="errorvalidacion.Actividad[0]" ></Cvalidation>
					</div>
				</div>
				<div class="form-group form-row">
		
					<!-- <div class="col-lg-6 mb-2" v-if="objproceso.IdOportunidad>0">
						<label>Estatus Oportunidad </label>
						<input type="text" readonly v-model="objproceso.Estatus" class="form-control">
						<label id="lblmsuser" style="Estatus:red" v-if="this.errorvalidacion.Estatus"><Cvalidation  :Mensaje="errorvalidacion.Estatus[0]" ></Cvalidation ></label>
					</div> -->
					<div class="col-lg-12">
						<label for="">Tipo *</label>
						<select :disabled="RolUsuario != 'Admin' && IdUsuario!=objproceso.IdTrabajador" name="" id="" class="form-control" v-model="objproceso.TipoActividad">
							<option value="">Seleccione el tipo de actividad</option>
							<option value="2">Ventas</option>
							<option value="1">Operaciones</option>
							<option value="3">Administración</option>
							<option value="4">Logística</option>
						</select>
						<label id="lblmsuser" style="Estatus:red"><Cvalidation v-if="this.errorvalidacion.Tipo" :Mensaje="errorvalidacion.Tipo[0]"></Cvalidation></label>
					</div>
				</div>
		
				<div class="form-group form-row" v-show="objproceso.IdOportunidad>0">
					<!-- MONTO PROPUESTA NUEVO CAMPO -->
					<!-- <div class="col-lg-4" v-show="MostrarArchivo == true">
						<label>Monto propuesta</label>
						<input :disabled="VendidoEstatus" type="text" v-model="objproceso.MontoPropuesta" class="form-control form-control-sm" placeholder="Monto propuesta" />
						<label id="lblmsuser" style="Proceso:red" v-if="this.errorvalidacion.MontoPropuesta" ><Cvalidation :Mensaje="errorvalidacion.MontoPropuesta[0]" ></Cvalidation></label>
					</div> -->
		
					<div class="col-lg-5 mb-2">
						<label>Documento 1</label>
						<div class="custom-file-input-image">
							<input :disabled="EvaluarRespSecundario()" @change="uploadImage(1)" type="file" ref="file" name="myfile" accept="application/pdf" class="custom-file-input" id="validatedCustomFile" required/>
							<input :disabled="EvaluarRespSecundario()" type="text" v-model="NameFile" class="form-control form-control-sm" />
							<button type="button" class="" style="height:31px !important;line-height:31px !important">
								<i class="fas fa-paperclip"></i>
							</button>
						</div>
					</div>
		
					<div class="col-lg-1" v-show="MostrarArchivo1 == true || this.NameFile!='Elegir archivo (5 MB)'">
						<div v-if="(objproceso.Archivo != '' && objproceso.Archivo!=null) || this.NameFile!='Elegir archivo (5 MB)'">
							<button title="Archivo" @click="open_file(1)" type="button" class="btn-icon mr-4 mt-6" >
								<i class="fas fa-file-pdf"></i>
							</button>
						</div>
					</div>
		
					<div class="col-lg-5">
						<label>Documento 2</label>
						<div class="custom-file-input-image">
							<input :disabled="EvaluarRespSecundario()" @change="uploadImage(2)" type="file" ref="file2" name="myfile2" accept="application/pdf" class="custom-file-input" id="validatedCustomFile" required/>
							<input :disabled="EvaluarRespSecundario()" type="text" v-model="NameFile2" class="form-control form-control-sm" />
							<button type="button" class="" style="height:31px !important;line-height:31px !important">
								<i class="fas fa-paperclip"></i>
							</button>
						</div>
					</div>
		
					<div class="col-lg-1" v-show="MostrarArchivo2 == true || this.NameFile2!='Elegir archivo (5 MB)'">
						<div v-if="(objproceso.Archivo2 != '' && objproceso.Archivo2!=null) || this.NameFile2!='Elegir archivo (5 MB)'">
							<button title="Archivo" @click="open_file(2)" type="button" class="btn-icon mr-4 mt-6" >
								<i class="fas fa-file-pdf"></i>
							</button>
						</div>
					</div>
				</div>
		
				<div class="form-group form-row">
		
					<template>
						<div class="col-lg-4" v-if="objproceso.Estatus == 'Vendido' || this.SinServicio == true">
							<label>Servicio </label>
							<select :disabled="SeteaIdConfig" v-model="objproceso.IdConfigS" class="form-control form-control-sm" >
								<option :value="''">Seleccionar un tipo</option>
								<option value="1">Mantenimiento</option>
								<option value="2">Servicio</option>
								<option value="3">Proyecto</option>
							</select>
							<label id="lblmsuser" style="Estatus:red" ><Cvalidation v-if="this.errorvalidacion.Servicio" :Mensaje="errorvalidacion.Servicio[0]" ></Cvalidation ></label>
						</div>
		
						<div class="col-lg-4" v-if="objproceso.Estatus == 'Vendido'">
							<label>Monto venta</label>
							<input :disabled="VendidoEstatus" type="text" v-model="objproceso.MontoP" class="form-control form-control-sm" placeholder="Monto venta" />
							<label id="lblmsuser" style="Proceso:red"> <Cvalidation v-if="this.errorvalidacion.Monto" :Mensaje="errorvalidacion.Monto[0]" ></Cvalidation ></label>
						</div>
					</template>
		
					<!--fin col-6-->
				</div>
		
		
				<div class="f1-buttons mt-4">
					<button v-if="oOportunidad==undefined" @click="IrAActivas" type="button" class="btn btn-01 mr-2">
						<i class="fa fa-check-circle"></i> Ver Oportunidades
					</button>
					<button @click="Guardar" type="button" class="btn btn-01">
						<i v-show="Disablebtn" class="fa fa-spinner fa-pulse fa-1x fa-fw"></i>
						<i class="fa fa-plus-circle"></i>
						{{ txtSave }}
					</button>
				</div>
			</template>
		</CLoader>

		<Oportunidad :TipoModal="2"></Oportunidad>
	</div>
</template>
<script>
import moment from "moment"

import Modal from "@/components/Cmodal.vue";
import HoraDia from "../calendario/components/Hora";
import Oportunidad from "../calendario/components/Buscaroportunidad";

export default {
	name: "Form",
	props: ["ocliente", "poBtnSave","oOportunidad","pOrigen","pIdUsuario"],
	components: {
		Modal,
		HoraDia,
		Oportunidad
	},
	data() {
		return {
			ConfigLoad:{
				ShowLoader:false,
				ClassLoad:true
			},
			size: "none",
			NameList: "Asignar proceso",
			oBtnSave: {
				//valores  isModal(true),nombreModal('ModalForm'),tipoModal=1,regresarA(''),disableBtn(false),txtSave('Guardar'),txtCancel('Cerrar');
				isModal: true,
				disableBtn: false,
				toast: 0
			},
			EmitSeccion:this.poBtnSave.EmitSeccion,
			Modal: true, //Sirve pra los botones de guardado
			FormName: "cliente", //Sirve para donde va regresar,
			RolUsuario:'',
			IdUsuario:'',
			issetOportunidad:false,
			objproceso: {
				Estatus: "",
				Cliente: "",
				Oportunidad: "",
				IdTrabajador: "",
				IdClienteSucursal: "",
				IdProceso: "",
				Actividad: "",
				MontoP: "",
				MontoPropuesta: "",
				IdConfigS: "",
				IdOportunidad: "",
				HoraInicio: "",
				HoraFin: "",
				Fecha: "",
				IdTipoProceso: "",
				Comentarios: "",
				IdSeguimientoCliente: "",
				Archivo: "",
				FechaAlarma:"",
				NomCliente:"",
				TipoActividad:"",
				Archivo2:"",
				Recordatorio:false,
				RespSecundarios:[]
			},
			urlApi: "crmseguimiento/recovery",
			urlApivendedor: "trabajador/ListTrabRolQuery",
			urlApiVendedorNuevo:"vendedores/get",
			errorvalidacion: [],
			Listavendedor: [],
			Listaprocesos: [],
			Listaservicios: [],
			Disablebtn: false,
			MostrarArchivo: false,
			MostrarArchivo1: false,
			MostrarArchivo2: false,
			txtSave: "Guardar",
			NombreModal: "",
			HoraActividad: false,
			Concluido: false,
			SinServicio: false,
			SeteaIdConfig: true,
			CerradoEstatus: false,
			VendidoEstatus: false,
			VendidoValue: false,
			ListaEstatus: [
				{ id: "1", label: "Abierta" },
				{ id: "2", label: "Cerrada" }
			],
			Rol: ["Vendedor", "Gerente de ventas"],
			Ruta: "",
			NameFile: "Elegir archivo (5 MB)",
			NameFile2: "Elegir archivo (5 MB)",
			ListaHoras: [],
			ListaHrs:[],
			ListaMin:[],
			objHoraInicial: {
				Hora: '00',
				Minuto: '00',
				Tiempo: 'AM'
			},
			objHoraFinal: {
				Hora: '00',
				Minuto: '00',
				Tiempo: 'AM'
			},
			isAlarmaChecked:false,
			buscarOportunidad:true,
			FechaAnterior:'',
			FechaAnteriorAlarma:'',
			Origen:'',
			ListaResponsablesSecundarios:[]
		};
	},
	methods: {
		IrAActivas(){
			$("#ModalForm").modal("hide");
			this.$router.push({name:'activas',params:{pIdActivaRedireccion:this.objproceso.IdOportunidad}});
		},
		LimpiarOportunidad(){
			this.objproceso.Oportunidad="";
			this.objproceso.Cliente="";
			this.objproceso.Actividad="";
			this.objproceso.Comentarios="";
			this.objproceso.IdTipoProceso="";
			this.objproceso.HoraInicio="";
			this.objproceso.HoraFin="";
			this.objproceso.Estatus="";
			this.objproceso.IdProceso="";
			this.objproceso.TipoProceso="";
		},
		Limpiar() {
			this.errorvalidacion = [];

			this.objproceso = {
				Estatus: "",
				Cliente: "",
				Oportunidad: "",
				IdTrabajador: "",
				IdClienteSucursal: "",
				IdProceso: "",
				Actividad: "",
				MontoP: "",
				MontoPropuesta: "",
				IdConfigS: "",
				IdOportunidad: "",
				HoraInicio: "",
				HoraFin: "",
				Fecha: "",
				IdTipoProceso: "",
				Comentarios: "",
				IdSeguimientoCliente: "",
				Archivo: "",
				Archivo2:"",
				FechaAlarma:"",
				NomCliente:"",
				TipoActividad:"",
				Recordatorio:false,
				RespSecundarios:[]
			};
			this.SinServicio = false;
			this.SeteaIdConfig = true;
			this.VendidoValue = false;
			this.MostrarArchivo = false;
			this.MostrarArchivo1 = false;
			this.MostrarArchivo2 =false;
			this.isAlarmaChecked=false;
			this.buscarOportunidad=true;
			this.Listaprocesos = [];
			this.CerradoEstatus = false;
			this.HoraActividad = false;
			this.VendidoEstatus = false;
			this.Concluido = false;
			this.NameFile = "Elegir archivo (5 MB)";
			this.NameFile2 = "Elegir archivo (5 MB)";
			this.ListaResponsablesSecundarios=[];

			if (localStorage.getItem("fechacalendario")) {
				let NuevaFecha = localStorage.getItem("fechacalendario");
				let formatedDate = NuevaFecha.replace(/-/g, "\/");
				this.objproceso.Fecha = new Date(formatedDate);
			}

			this.objHoraInicial = {
				Hora: '00',
				Minuto: '00',
				Tiempo: 'AM'
			},
			this.objHoraFinal = {
				Hora: '00',
				Minuto: '00',
				Tiempo: 'AM'
			},
			this.FechaAnterior='',
			this.FechaAnteriorAlarma=''
		},
		/*async ListaVendedores() {
			await this.$http
				.get(this.urlApivendedor, {
					params: { Rol: JSON.stringify(this.Rol) }
				})
				.then(res => {
					this.Listavendedor = res.data.data.lista;
					this.Limpiar();
				});
		},*/
		async ListaVendedores() {
			await this.$http
				.get(this.urlApiVendedorNuevo, {
					params: {
						RegEstatus: 'A'
					}
				})
				.then(res => {
					// this.Listavendedor = res.data.data.Vendedores;
					let Mapeado=res.data.data.Vendedores.map((Element)=>{
                    if (Element.IdUsuario==null) {
                        Element.IdUsuario=Element.TrabajadorUsuario,
                        Element.Nombre=Element.NombreAdmin
                        return Element;
                    }
                    else{
                        return Element;
                    }
                });
                this.Listavendedor=Mapeado;
					// let UsuarioLogueado = JSON.parse(sessionStorage.getItem("user"));
					// let Index=this.Listavendedor.findIndex((Element)=>UsuarioLogueado.IdUsuario==Element.IdUsuario);
              		// if (Index==-1) {
              		//   let Obj={
              		//       IdUsuario:UsuarioLogueado.IdUsuario,
              		//       Nombre:UsuarioLogueado.Nombre+ ' '+UsuarioLogueado.Apellido
              		//   }
              		//   this.Listavendedor.unshift(Obj);
              		// }
					this.Limpiar();
				});
		},
		ListarRespSecundarios(){
			let ResponsablesBase=this.Listavendedor.filter((element)=>{
				if(this.oOportunidad!=undefined){
					return this.oOportunidad.RespSecundarios.includes(element.IdUsuario);
				}
				else{
					return this.objproceso.RespSecundarios.includes(element.IdUsuario);
				}
			});
			let Responsables=ResponsablesBase.filter((element)=>{
				return this.objproceso.IdTrabajador!=element.IdUsuario;
			});
			this.ListaResponsablesSecundarios=Responsables.map((element)=>{
				return {
					id:element.IdUsuario,
					label:element.Nombre,
					isDisabled:false
				}
			});
			//Anexar responsable de oportunidad si no está en listado
			if (this.oOportunidad!=undefined) {
				console.log(this.IdUsuario);
				console.log(this.oOportunidad.IdVendedor);
				let index=this.Listavendedor.findIndex((Element)=>Element.IdUsuario==this.oOportunidad.IdVendedor);
				if (this.oOportunidad.IdVendedor!=this.IdUsuario) {
					let Id=this.Listavendedor[index].IdUsuario;
					let Nombre=this.Listavendedor[index].Nombre;
					this.ListaResponsablesSecundarios.push({id:Id,label:Nombre,isDisabled:false});
				}
			}
			else{
				if (this.objproceso.IdTrabajador!=this.objproceso.Vendedor) {
					this.ListaResponsablesSecundarios.push({id:this.objproceso.Vendedor,label:this.objproceso.NomTrabajador,isDisabled:false});
				}
			}
		},
		ListarOportunidad(Id) {
			//this.NombreModal = 'Oportunidad';//Listoportunida
			this.bus.$emit("Listoportunida", Id);
		},
		get_revovy() {
			this.ConfigLoad.ShowLoader=true;
			this.$http
				.get("crmseguimiento/recovery", {
					params: { IdSeguimientoCliente: this.objproceso.IdSeguimientoCliente }
				})
				.then(res => {
					// this.procesosget(res.data.data.seguimiento.IdTipoProceso);
					this.objproceso.RespSecundarios=res.data.data.responsables;
					const objes = res.data.data.seguimiento;

					let HIni = objes.HoraInicio;
					HIni = moment('2022-12-01 '+HIni).format('hh:mm A');
					let Separa1 = this.splitHora(HIni);

					if(Separa1.length>0)
					{
						this.objHoraInicial.Hora = Separa1[0];
						this.objHoraInicial.Minuto = Separa1[1];
						this.objHoraInicial.Tiempo = Separa1[2];
					}

					let HFin = objes.HoraFin;
					HFin = moment('2022-12-01 '+HFin).format('hh:mm A');
					let Separa2 = this.splitHora(HFin);

					if(Separa2.length>0)
					{
						this.objHoraFinal.Hora = Separa2[0];
						this.objHoraFinal.Minuto = Separa2[1];
						this.objHoraFinal.Tiempo = Separa2[2];
					}


					//this.objproceso =res.data.data.seguimiento;
					// this.objproceso.IdProceso 			= objes.IdProceso;
					this.objproceso.IdTrabajador 		= objes.IdTrabajador;
					this.objproceso.IdClienteSucursal 	= objes.IdClienteSucursal;
					this.objproceso.Cliente 			= objes.ClienteSucursal;
					this.objproceso.Actividad			= objes.Actividad;
					this.objproceso.MontoP 				= objes.MontoP;
					this.objproceso.MontoPropuesta 		= objes.MontoPropuesta;
					this.objproceso.IdConfigS 			= objes.IdConfigS;
					this.objproceso.IdOportunidad 		= objes.IdOportunidad;
					// this.objproceso.HoraInicio 			= HIni;
					// this.objproceso.HoraFin 			= HFin;
					// this.objproceso.IdTipoProceso 		= objes.IdTipoProceso;
					this.objproceso.IdSeguimientoCliente= objes.IdSeguimientoCliente;
					this.objproceso.Oportunidad 		= objes.Oportunidad;
					this.objproceso.Estatus 			= objes.Estado;
					this.objproceso.NomCliente			=objes.Cliente;
					this.objproceso.TipoActividad		= this.RecuperarTipoActividad(objes.NombreTipo);
					this.objproceso.Vendedor=objes.Vendedor;
					this.objproceso.NomTrabajador=objes.NomTrabajador;
					this.ListarRespSecundarios();
					// this.objproceso.Comentarios 		= objes.Comentarios;

					// ARCHIVO 1 y 2
					this.Ruta = res.data.ruta;

					if (objes.Archivo != "undefined" && objes.Archivo != "") {
						this.MostrarArchivo1 = true;
						this.NameFile = objes.Archivo;
						this.objproceso.Archivo = objes.Archivo;
					} else {
						this.MostrarArchivo1 = false;
					}

					if (objes.Archivo2 != "undefined" && objes.Archivo2 != "") {
						this.MostrarArchivo2 = true;
						this.NameFile2 = objes.Archivo2;
						this.objproceso.Archivo2 = objes.Archivo2;
					} else {
						this.MostrarArchivo2 = false;
					}

					if (objes.Estatus == "Cerrada") {
						this.CerradoEstatus = true;
						this.Concluido = true;
						this.VendidoEstatus = true;
					} else {
						if (objes.Estatus == "Abierta" || objes.Estatus == "") {
							this.CerradoEstatus = false;
						}
					}
					if (objes.Estatus == "Vendido") {
						this.VendidoEstatus = true;
						this.CerradoEstatus = true;
						this.Concluido = true;
					}

					//añade zeros (necesarios para comparar la hora)
					function addZero(i) {
						if (i < 10) {
							i = "0" + i;
						}
						return i;
					}
					// SEPARAR TODO EL PROCESO DE LAS FECHAS EN UNA FUNCION PARA AHORRAR LINEAS
					//obtiene fecha, hora, minuto y segundo actual
					const current = new Date();
					let day = String(current.getDate()).padStart(2, "0");
					let month = String(current.getMonth() + 1).padStart(2, "0");
					let year = current.getFullYear();
					let hour = addZero(current.getHours());
					let min = addZero(current.getMinutes());
					let sec = addZero(current.getSeconds());
					let dateActual = year + "-" + month + "-" + day;
					let timeActual = hour + ":" + min + ":" + sec;
					/*si la horaFin es menor que la hora actual bloquea controles
					si la fecha de la actividad es diferente a la actual bloquea controles*/
					if (objes.Fecha <= dateActual && objes.HoraFin <= timeActual) {
						this.CerradoEstatus = true;
					}
					let formatedDate = objes.Fecha.replace(/-/g, "\/");
					this.objproceso.Fecha = new Date(formatedDate);

					// ESTA ES LA FECHA DE ALARMA
					if(objes.FechaAlarma!=null && objes.FechaAlarma!="0000-00-00"){
						formatedDate=objes.FechaAlarma.replace(/-/g, "\/");
						this.objproceso.FechaAlarma=new Date(formatedDate);
						this.isAlarmaChecked=true;
						this.FechaAnteriorAlarma=objes.FechaAlarma;
					}
					
					this.FechaAnterior=objes.Fecha;

					// Marcar recordatorio
					if(objes.Recordatorio!="" && objes.Recordatorio=="s"){
						this.objproceso.Recordatorio=true;
					}
				}).finally(()=>{
					this.ConfigLoad.ShowLoader=false;
				});
		},
		RecuperarTipoActividad(Actividad){
			let Tipo="";
			switch (Actividad) {
				case "operaciones":
					Tipo="1"
					break;
				case "ventas":
					Tipo="2"
					break;
				case "administracion":
					Tipo="3"
					break;
				case "logistica":
					Tipo="4"
					break;
			}
			return Tipo;
		},
		Validar() {
			let bandera = true;
			// if (this.objproceso.Actividad=='')
			// {
			//     this.$toast.info('La actividad se encuentra vacio');
			//     bandera= false;
			// }

			// if (this.objproceso.IdOportunidad==0 ||this.objproceso.IdOportunidad=='')
			// {
			//     this.$toast.info('Debe seleccionar una oportunidad');
			//     bandera= false;
			// }
			// if (this.objproceso.IdProceso==0 ||this.objproceso.IdProceso=='')
			// {
			//     this.$toast.info('Debe seleccionar un proceso');
			//     bandera= false;
			// }

			return bandera;
		},
		async Guardar() {
			//alert('en proceso');

			let validacion = this.Validar();

			if (validacion == false) {
				return false;
			} else {
				let Fecha = "0000-00-00";
				let FechaAlarma="0000-00-00";
				if (this.objproceso.Fecha != "") {
					Fecha=this.CrearFecha(Fecha,this.objproceso.Fecha);
				}
				if (this.objproceso.FechaAlarma!=""){
					FechaAlarma=this.CrearFecha(FechaAlarma,this.objproceso.FechaAlarma);
				}
				else{
					FechaAlarma="";
				}
				let TipoActividad=this.ObtenerDatosTipo(1);
				let ColorActividad=this.ObtenerDatosTipo(2);
				// COMENTAR TODOS LOS DATOS INUTILES DEL FORMULARIO
				let formData = new FormData();
				formData.set(
					"IdSeguimientoCliente",
					this.objproceso.IdSeguimientoCliente
				);
				formData.set("IdTrabajador", this.objproceso.IdTrabajador);
				formData.set("IdClienteSucursal", this.objproceso.IdClienteSucursal);
				formData.set("HoraInicio", this.objproceso.HoraInicio);
				formData.set("HoraFin", this.objproceso.HoraFin);
				// formData.set("IdProceso", this.objproceso.IdProceso);
				formData.set("Actividad", this.objproceso.Actividad);
				formData.set("MontoP", 0);
				formData.set("MontoPropuesta", this.objproceso.MontoPropuesta);
				formData.set("IdConfigS", this.objproceso.IdConfigS);
				formData.set("IdOportunidad", this.objproceso.IdOportunidad);
				formData.set("Estatus", this.objproceso.Estatus);
				formData.set("IdTipoProceso", this.objproceso.IdTipoProceso);
				formData.set("Comentarios", this.objproceso.Comentarios);
				formData.set("Fecha", Fecha);
				formData.set("FechaAlarma", FechaAlarma);
				formData.set("NombrePDF", this.objproceso.Archivo);
				formData.set("NombrePDF2", this.objproceso.Archivo2);

				formData.set("FechaIniH", this.objHoraInicial.Hora);
				formData.set("FechaIniM", this.objHoraInicial.Minuto);
				formData.set("FechaIniT", this.objHoraInicial.Tiempo);

				formData.set("FechaFinH", this.objHoraFinal.Hora);
				formData.set("FechaFinM", this.objHoraFinal.Minuto);
				formData.set("FechaFinT", this.objHoraFinal.Tiempo);
				formData.set("Tipo",this.objproceso.TipoActividad);
				formData.set("NombreTipo", TipoActividad);
				formData.set("ColorTipo", ColorActividad);
				formData.set("isAlarma", this.isAlarmaChecked);
				formData.set("RespSecundarios",JSON.stringify(this.objproceso.RespSecundarios));

				// PARA LOS RECORDATORIOS
				if (this.objproceso.Recordatorio) {
					formData.set("Recordatorio","s");
				}
				else{
					formData.set("Recordatorio","n");
				}

				// PARA AMBOS ARCHIVOS PDF
				if (this.objproceso.Archivo == undefined) {
					formData.set("NombrePDF", "");
				}

				let file = this.$refs.file.files[0];
				formData.append("File", file);
				
				if (this.objproceso.Archivo2 == undefined) {
					formData.set("NombrePDF2", "");
				}

				let file2 = this.$refs.file2.files[0];
				formData.append("File2", file2);


				this.Disablebtn = true;
				this.txtSave = " Espere...";

				this.$http
					.post("crmseguimiento/post", formData)
					.then(res => {
						this.Disablebtn = false;
						this.txtSave = " Guardar";
						if(this.oOportunidad!=undefined){
							$("#Seguimiento").modal("hide");
							this.bus.$emit('ListaOportunidadxActividad');
						}
						else{
							$("#ModalForm").modal("hide");
						}
						if (this.FechaAnterior=='') {
							var fecha={
								dateStr:Fecha
							}	
						}
						else{
							var fecha={
								dateStr:this.FechaAnterior
							}
						}
						this.bus.$emit("ListAgendaS",fecha);
						this.$toast.success("Información guardada");
					})
					.catch(err => {
						this.Disablebtn = false;
						this.txtSave = " Guardar";
						this.errorvalidacion = err.response.data.message.errores;
						this.$toast.info("Complete la Información");
					});
			}
		},
		// RETORNAR UNA FECHA PARA LA ALARMA
		CrearFecha(Fecha,obj){
			let day = obj.getDate();
			let month = obj.getMonth() + 1;
			let year = obj.getFullYear();
			Fecha = year + "-" + month + "-" + day;
			return Fecha;
		},
		// SE BUSCA OBTENER EL NOMBRE Y EL COLOR DEL TIPO ELEGIDO, POR ESO LA FUNCIÓN TOMA 2 CAMINOS SEGÚN UN PARÁMETRO
		ObtenerDatosTipo(opcion){
			if (opcion==1) {
				let NombreProceso;
				switch (this.objproceso.TipoActividad) {
					case "1":
						NombreProceso="operaciones"
						break;
					case "2":
						NombreProceso="ventas"
						break;
					case "3":
						NombreProceso="administracion"
						break;
					case "4":
						NombreProceso="logistica"
						break;
				}
				return NombreProceso;
			}
			// AZUL=#0F3F87, MAGENTA=#731F58, VERDE=#1fad15, AQUA=#17d6d9
			else{
				let Color;
				switch (this.objproceso.TipoActividad) {
					case "1":
						Color="#0F3F87"
						break;
					case "2":
						Color="#731F58"
						break;
					case "3":
						Color="#1fad15"
						break;
					case "4":
						Color="#17d6d9"
						break;
				}
				return Color;
			}
		},
		ObtenerOportunidad(obj) {
			//alert(JSON.stringify(obj));
			this.objproceso.Oportunidad = obj.Nombre;
			this.objproceso.Cliente = obj.Sucursal;
			this.objproceso.IdTipoProceso = obj.IdTipoP;
			this.objproceso.IdClienteSucursal = obj.IdClienteS;
			this.objproceso.IdOportunidad = obj.IdOportunidad;
			this.objproceso.NomCliente=obj.NomCliente;
			this.objproceso.Estatus=obj.Estado;
			this.procesosget(obj.IdTipoP);
		},
		procesosget(Id) {
			this.$http
				.get("crmtipoandproceso/list", {
					params: {
						IdTipoProceso: Id,
						IdOportunidad: this.objproceso.IdOportunidad,
						IdTrabajador: this.objproceso.IdTrabajador
					}
				})
				.then(res => {
					const objProcessT = res.data.data.tipoproceso;
					const objActivity = res.data.data.actividad;

					if (objActivity.IdSeguimientoCliente > 0) {
						//Valores recuperados de una oportunidad si esta contiene información previa
						this.Listaprocesos = res.data.data.procesos;
						this.objproceso.TipoProceso = objProcessT.Nombre;
						this.objproceso.IdConfigS = objProcessT.IdConfigS;
						this.objproceso.IdProceso = objActivity.IdProceso;
						this.objproceso.Estatus = objActivity.Estatus;
						this.objproceso.MontoPropuesta = objActivity.MontoPropuesta;

						if (
							objActivity.Archivo != "undefined" &&
							objActivity.Archivo != ""
						) {
							this.MostrarArchivo = true;
							this.NameFile = objActivity.Archivo;
							this.objproceso.Archivo = objActivity.Archivo;
						}
					} else {
						//Valores de editar (clic en la lista de actividades del calendario)

						this.objproceso.TipoProceso = objProcessT.Nombre;
						this.Listaprocesos = res.data.data.procesos;
						this.objproceso.IdConfigS = objProcessT.IdConfigS;
					}

					if (this.objproceso.IdConfigS <= 0) {
						this.SeteaIdConfig = false;
						this.SinServicio = true;
					} else {
						this.SeteaIdConfig = true;
						this.SinServicio = false;
					}
					var buscar = this.Listaprocesos.filter(element => {
						if (this.objproceso.IdProceso == element.IdCrmProceso)
							return element;
					});
					if (buscar.length > 0) {
						if (buscar[0].Nombre == "Propuestas") {
							this.MostrarArchivo = true;
						} else {
							this.MostrarArchivo = false;
						}
					}
					if (buscar.length > 0) {
						if (buscar[0].Nombre == "Cierre") {
							this.VendidoValue = true;
						} else {
							this.VendidoValue = false;
						}
					}
				});
		},
		ConfigServicio() {
			this.$http
				.get("configservicio/get", {
					params: { pag: 1, Entrada: 100, Facturable: "S" }
				})
				.then(res => {
					this.Listaservicios = res.data.data.configservicio;
				});
		},
		GetProceso() {
			var busqueda = this.Listaprocesos.filter(element => {
				if (this.objproceso.IdProceso == element.IdCrmProceso) return element;
			});
			//Muestra MontoPropuesta, Archivo
			if (busqueda.length > 0) {
				if (busqueda[0].Nombre == "Propuestas") {
					this.MostrarArchivo = true;
				} else {
					this.MostrarArchivo = false;
				}
			}
			//Muestra Estatus vendido
			if (busqueda.length > 0) {
				if (busqueda[0].Nombre != "Cierre") {
					this.VendidoValue = false;
				} else {
					this.VendidoValue = true;
				}
			}
			////Oculta MontoPropuesta, Archivo if seleccionar
			if (this.objproceso.IdProceso == "") {
				this.MostrarArchivo = false;
			}
		},
		GetEstatus() {
			//Deshabilita los controles cuando el estatus es cerrado
			//No deshabilita monto propuesta y evidencia
			if (this.objproceso.Estatus == "Cerrada") {
				this.CerradoEstatus = true;
			} else {
				if (
					this.objproceso.Estatus == "Abierta" ||
					this.objproceso.Estatus == ""
				) {
					this.CerradoEstatus = false;
				}
			}
			/*Deshabilita monto propuesta y evidencia para que ingrese el
			monto venta que se verá reflejado en su gráfica*/
			if (this.objproceso.Estatus == "Vendido") {
				this.VendidoEstatus = false;
				this.CerradoEstatus = true;
			}
		},
		open_file(NumArchivo) {
			let Archivo="";
			if (NumArchivo==1) {
				if (this.$refs.file.files[0]==undefined) {
					Archivo=this.Ruta+this.objproceso.Archivo;
				}
				else{
					Archivo=URL.createObjectURL(this.$refs.file.files[0]);
				}
			}
			else{
				if (this.$refs.file2.files[0]==undefined) {
					Archivo=this.Ruta+this.objproceso.Archivo2;
				}
				else{
					Archivo=URL.createObjectURL(this.$refs.file2.files[0]);
				}
			}
			window.open(
				Archivo,
				"_blank",
				"toolbar=1, scrollbars=1, resizable=1, width=" +
					1015 +
					", height=" +
					800
			);
			// let pdfWindow = window.open(Archivo);
			// pdfWindow.document.write(
			// 	"<iframe width='100%' height='100%' src='" +
			// 		Archivo +
			// 		"'></iframe>"
			// );
			// let Archivo="";
			// if (NumArchivo==1) {
			// 	Archivo=this.objproceso.Archivo;
			// }
			// else{
			// 	Archivo=this.objproceso.Archivo2;
			// }
			// let pdfWindow = window.open(this.Ruta + Archivo);
			// pdfWindow.document.write(
			// 	"<iframe width='100%' height='100%' src='" +
			// 		this.Ruta +
			// 		Archivo +
			// 		"'></iframe>"
			// );
		},
		uploadImage(NumArchivo) {
			let image;
			let input;
			if (NumArchivo==1) {
				image = this.$refs.file.files[0];
				input = this.$refs.file;
			}
			else{
				image = this.$refs.file2.files[0];
				input = this.$refs.file2;
			}
			// const image = this.$refs.file.files[0];

			var FileSize = image.size / 1024 / 1024; // in MB
			if (FileSize > 5) {
				this.$toast.info("Solo se puede subir archivos menores a 5 MB");
				// const input = this.$refs.file;
				input.type = "text";
				input.type = "file";
				return false;
			}

			var allowedExtensions = /(\.pdf|\.PDF)$/i;
			if (!allowedExtensions.exec(image.name)) {
				this.$toast.info("Extenciones permitidas .pdf");
				// const input = this.$refs.file;
				input.type = "text";
				input.type = "file";
				nombre = "Elegir archivo (5 MB)";
				return false;
			}
			if (NumArchivo==1) {
				this.NameFile=image.name;
			}
			else{
				this.NameFile2 = image.name;
			}
			// nombre = image.name;
			/*
            const reader = new FileReader();
            var img="";
            reader.readAsDataURL(image);
            reader.onload= e =>{
                this.Img = e.target.result;
            }*/
		},
		async get_horas()
        {
            await this.$http.get(
                'horaslaborales/horaslaborales',
                {
                    params:{}
                }
            ).then( (res) => {
                this.ListaHoras = res.data.data.horaslaborales;
            });
        },
		setHorasLab()
		{
			let objHrs = [
				{Hora:'01'},{Hora:'02'},{Hora:'03'},{Hora:'04'},
				{Hora:'05'},{Hora:'06'},{Hora:'07'},{Hora:'08'},
				{Hora:'09'},{Hora:'10'},{Hora:'11'},{Hora:'12'}
			];

			let objMin = [{Min:'00'},{Min:'15'},{Min:'30'},{Min:'45'}];

			this.ListaHrs = objHrs;
			this.ListaMin = objMin;
		},
		splitHora(Hora)
		{
			if(Hora!=''){
				let sep1 = Hora.split(':');
				let sep2 = sep1[1].split(' ');

				return [sep1[0],sep2[0],sep2[1]];
			}
			else
			{
				return [];
			}
		},
		// PARA LIMPIAR LA FECHA DE ALARMA CUANDO SE DESELECCIONE
		EliminarFechaAlarma(){
			if (this.isAlarmaChecked==true) {
				this.objproceso.FechaAlarma="";
				this.objproceso.Recordatorio=false;
			}
		},
		// RECUPERAR TODOS LOS DATOS DE LA ACTIVIDAD DESDE LA OPORTUNIDAD
		RecuperarActividadDeOportunidad(){
			if (this.oOportunidad!=undefined) {
				this.buscarOportunidad=false;
				this.objproceso.IdOportunidad=this.oOportunidad.IdOportunidad;
				this.objproceso.Oportunidad=this.oOportunidad.Nombre;
				this.objproceso.IdClienteSucursal=this.oOportunidad.IdClienteS;
				this.objproceso.NomCliente=this.oOportunidad.Cliente;
				this.objproceso.Cliente=this.oOportunidad.Sucursal;
				switch (this.oOportunidad.Estatus) {
					case 1:
						this.objproceso.Estatus="Activa";
						break;
					case 2:
						this.objproceso.Estatus="Ganada";
						break;
					case 3:
						this.objproceso.Estatus="Perdida";
						break;
					case 4:
						this.objproceso.Estatus="Enviada";
						break;
				}
			}
		},
		MinimoFecha(){
			let fecha='';
			if(this.FechaAnterior!=""){
				var FechaAnterior=new Date(this.FechaAnterior);
				var Actual=new Date();
				if (FechaAnterior.getTime()>=Actual.getTime()) {
					fecha=new Date();
				}
				else{
					fecha=moment(this.objproceso.Fecha).add(1,'day').format('YYYY-MM-DD');
					fecha=new Date(fecha);
				}
				// fecha=moment(this.objproceso.Fecha).add(1,'day').format('YYYY-MM-DD');
				// fecha=new Date(fecha);
				
			}
			else{
				fecha=new Date();
			}
			return fecha;
		},
		MaximoFechaAlarma(){
			let fecha='';
			fecha=moment(this.objproceso.Fecha).add(1,'day').format('YYYY-MM-DD');
			fecha=new Date(fecha);
			return fecha;
		},
		BloquearRespSecundarios(){

		},
		EvaluarRespSecundario(){
			if (this.oOportunidad!=undefined) {
				let Index=this.ListaResponsablesSecundarios.findIndex((Element)=>Element.id==this.IdUsuario);
				if (Index!=-1) {
					return true;
				}
				else{
					return false;
				}
			}
			else{
				return true;
			}
			// let IdUsuario=JSON.parse(sessionStorage.getItem("user")).IdUsuario;
			// if (this.oOportunidad!=undefined) {
			// 	if (this.oOportunidad.RespSecundarios.length>0) {
			// 		let Index=this.oOportunidad.RespSecundarios.findIndex((Element)=>Element.Id==this.IdUsuario);
			// 		if (Index!=-1) {
			// 			return true;
			// 		}
			// 		else{
			// 			return false;
			// 		}
			// 	}
			// 	else{
			// 		return false
			// 	}
			// }
			// else{
			// 	if(this.Origen=='activas'){
			// 		if (this.ListaResponsablesSecundarios.length>0) {
			// 			let Index=this.ListaResponsablesSecundarios.findIndex((Element)=>Element.Id==this.IdUsuario);
			// 			if (Index!=-1) {
			// 				return true;
			// 			}
			// 			else{
			// 				return false;
			// 			}
			// 		}
			// 		else{
			// 			return false;
			// 		}
			// 	}
			// 	else{
			// 		return true;
			// 	}
			// }
		}
	},
	created() {
		//this.Limpiar();
		//this.get_horas();
		this.setHorasLab();
		this.ListaVendedores();
		this.ConfigServicio();
		if (this.pOrigen!==undefined) {
			this.Origen=this.pOrigen;
		}
		this.RolUsuario=JSON.parse(sessionStorage.getItem("user")).Perfil;
		this.bus.$off("SeleccionarOportunidad");
		this.bus.$on("SeleccionarOportunidad", oOportunidad => {
			//alert(JSON.stringify(oOportunidad));
			this.ObtenerOportunidad(oOportunidad);
		});
		// console.log(sessionStorage.setItem('FechaSeleccionada'));
		// if(sessionStorage.setItem('FechaSeleccionada') != '')
		// {
		//    this.objproceso.Fecha = sessionStorage.setItem('FechaSeleccionada');
		// }

		this.bus.$off("Regresar");
		this.bus.$off("NewModal_"+this.EmitSeccion);
		this.bus.$off("SeleccionarCliente");
		//this.ListaServ();

		this.bus.$on("SeleccionarCliente", oSucursal => {
			this.SeleccionarCliente(oSucursal);
		});

		this.bus.$on("Regresar", () => {
			//this.ListaCliente();
		});

		this.bus.$on("NewModal_"+this.EmitSeccion, (data, Id) => {
			this.IdUsuario=this.pIdUsuario;
			this.Limpiar();
			if (Id == undefined) {
				Id = 0;
			}
			this.ShowComponent = false;

			this.objproceso.IdSeguimientoCliente = Id;
			// console.log(Id);
			if (Id > 0) {
				//console.log('2');
				this.get_revovy();
			} else {
				//console.log('3');
				this.Limpiar();
				if (this.RolUsuario!="Admin") {
					this.objproceso.IdTrabajador=JSON.parse(sessionStorage.getItem("user")).IdUsuario;
				}
				if (this.oOportunidad!=undefined) {
					// this.objproceso.IdTrabajador=this.oOportunidad.IdVendedor;
					this.objproceso.IdTrabajador=JSON.parse(sessionStorage.getItem("user")).IdUsuario;
					this.issetOportunidad=true;
				}
				this.ShowComponent = true;
				this.buscarOportunidad=true;
				// console.log("a");
			}
			this.RecuperarActividadDeOportunidad();
			const input = this.$refs.file;
			input.type = "text";
			input.type = "file";
			this.ListarRespSecundarios();
		});
	},
	computed: {
		isEstatus() {
			if (this.objproceso.Estatus == 3) {
				return true;
			}

			return false;
		},
		typeCalendar: function() {
			// if(this.tipo == 'guardia')
			//     return 'range';

			return "single";
		},
		// attributes(){
		// 	return[{
		// 		contentStyle:{
		// 			z-index:1000
		// 		}
		// 	}]
		// }
	}
};
</script>
