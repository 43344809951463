<template>
    <div class="col-md-6 col-lg-6">
        <b-overlay :show="isOverlay" rounded="sm" spinner-variant="primary" class="h-100">
            <div class="row">
                <div class="col-12">
                    <div class="card card-grafica h-100 mb-3">
                        <div class="card-body">
                            <div class="filtro-grafic" id="grafica_003" v-if="isVisible">
                                <div class="row justify-content-start">
                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                        <form class="form-inline">
                                            <label class="title-grafic side-l mr-3">Días Activos</label>
                                            <treeselect
                                                class="d-inline-block w-25 mt-0 pt-0 ml-2"
                                                style="top: -4px;"
                                                :maxHeight="180"
                                                :options="ListaComboTrabajadores"
                                                placeholder="Trabajador..."
                                                v-model="IdTrabajador"
                                                @input="get_dataSource()"
                                            />
                                            <v-date-picker
										        mode='range'
										        v-model='rangeDate'
										        @input="get_dataSource()"
										        :input-props='{
										        	class: "form-control form-control mb-2 mr-1 calendar",
										        	placeholder: "Selecciona un rango de fecha para buscar",
										        	readonly: true
										        }'
										    />
                                        </form>
                                        <button type="button" class="btn close abs_01" @click="Ocultar()"><i class="fal fa-times"></i></button>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-10 col-sm-10 col-md-8 col-lg-8">
									<h6 class="title-grafic side-l">Días Activos</h6>
								</div>
                                <div class="col-2 col-sm-2 col-md-4 col-lg-4 text-right">
									<button
										type="button"
										class="btn-fil-002"
										title="Filtros"
										@click="mostrarfiltro()"
									>
										<i class="fas fa-filter"></i>
									</button>
								</div>
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
									<hr />
								</div>
                            </div>
                            <div style="height:281px">
                                <jscharting :mutable="false" ref="chart3" :options="chartOptions" class="ml-0 pl-0 columnChart3"></jscharting>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </b-overlay>
    </div>
</template>

<script>
export default {
    name:'CheckInContadorBarra',
    data() {
        return {
            isVisible: false,
			isOverlay: false,
            IdTrabajador:null,
            rangeDate:{
                start:null,
                end:null
            },
            ListaTrabajadores:[],
            ListaComboTrabajadores:[],
            chartOptions:{
                debug:false,
                type:'horizontal column solid',
				legend_visible:false,
            	yAxis:{
            	    scale_range:{min:0, padding:0.1},
            	    orientation:'opposite',
                    formatString: 'n0',
            	},
            	xAxis:{
					overflow:'scroll',
            	    defaultTick_label_width: 150,
					caOffset:5, 
            	    crosshair: { 
            	        enabled: true, 
            	        gridLine_visible: false, 
            	        outline_width: 0, 
            	        label_style_fontWeight: 'bold'
            	    } 
            	},
            	defaultSeries_palette: ['#CFD8DC'],
            	defaultPoint: { 
            	    outline_width: 0, 
            	    tooltip: '<b>%yValue</b>',
            	},
            	defaultTooltip_label_text: '%points',
            	series:[]
            }
        }
    },
    methods: {
        mostrarfiltro() {
			this.isVisible = true;
		},
		Ocultar() {
			this.isVisible = false;
		},
        verificarnumero(value) {
			let val = value;
			if (value < 10) {
				val = "0" + value;
			}
			return val;
		},
        async get_dataSource() {
            this.isOverlay = true;
            await this.$http
				.get(
					"checkincontadorbarra",
					{
						params: {
							Fecha_I: this.rangeDate.start,
							Fecha_F: this.rangeDate.end,
							IdTrabajador:this.IdTrabajador,
						}
					}
				).then((res)=>{
                    let DatosOrdenados=[];
					// for (let index = 0; index < res.data.datacategory.length; index++) {
					// 	const label = res.data.datacategory[index];
					// 	const value = res.data.dataseries[index];
					// 	DatosOrdenados.push({label:label,value:value});
					// }
                    DatosOrdenados.push({label:'Trabajados',value:res.data.data.Trabajados});
                    DatosOrdenados.push({label:'Ausencias',value:res.data.data.Ausencias});
                    DatosOrdenados.push({label:'Vacaciones',value:res.data.data.Vacaciones});
                    DatosOrdenados.push({label:'Permisos',value:res.data.data.Permisos});

					this.isOverlay = false;
					DatosOrdenados.sort((a,b)=>b.value-a.value);

                    let points=DatosOrdenados.map((datos)=>{
                        return {
                            x:datos.label,
                            y:datos.value,
                            color:"#0F3F87",
                            xAxisTick_label_text: this.$limitCharacters(datos.label,18),
							xAxisTick_label_color: '#373D3F'
                        }
                    });
                    this.chartOptions.series=
					[
						{
							points:points
						}
					];
                })
        },
        async GetListaComboTrabajadores(){
            await this.$http.get('trabajador/checkin',{
            }).then((res)=>{
                this.ListaComboTrabajadores=res.data.data.trabajadores.map(function(obj){
                    return {id:obj.IdTrabajador,label:obj.Nombre}
                });
                this.IdTrabajador=this.ListaComboTrabajadores[0].id;
                this.ListaComboTrabajadores.push({id:null,label:'Todos'});
                this.get_dataSource();
            })
        },
    },
    created() {
		var date = new Date(),
			y = date.getFullYear(),
			m = date.getMonth();
		var firstDay = new Date(y, m, 1);
		var lastDay = new Date(y, m + 1, 0);
		this.rangeDate = {
			start: firstDay,
			end: lastDay
		};
	},
    mounted() {
        this.GetListaComboTrabajadores();
    },
}
</script>

<style>
    .columnChart3{
        height: 310px;
    }
</style>