<template>
    <div>
        <div class="row">
            <div class="col-lg-4">
                <label for="">Descripción</label>
            </div>
            <div class="pt-1 col-lg-2 FondoDetallesTitulos">
                <label class="text-white" for="">Trimestre 1</label>
            </div>
            <div class="pt-1 col-lg-2 FondoDetallesTitulos">
                <label class="text-white" for="">Trimestre 2</label>
            </div>
            <div class="pt-1 col-lg-2 FondoDetallesTitulos">
                <label class="text-white" for="">Trimestre 3</label>
            </div>
            <div class="pt-1 col-lg-2 FondoDetallesTitulos">
                <label class="text-white" for="">Trimestre 4</label>
            </div>
        </div>
        <div :class="index%2==0 ? 'row pb-2 pt-1 fondoformfinanciera' : 'row pb-2 pt-1 fondoformfinanciera2'" v-for="(item,index) in ListaDetallesCuenta" :key="index">
            <div class="col-lg-4">
                <input v-model="item.Descripcion" type="text" class="form-control">
            </div>
            <div :class="index%2==0 ? 'col-lg-2 m-auto FondoDetallesValor' : 'col-lg-2 m-auto FondoDetallesValor2'">
                <!-- <input v-model="item.Trimestre1Ind" type="text" class="form-control"> -->
                <vue-numeric @input="GetTotalTrimestre" placeholder="$ 0" class="form-control form-finanza form-control-sm text-center" currency="$" separator="," :precision="Decimal" v-model="item.Trimestre1Ind" ></vue-numeric>
            </div>
            <div :class="index%2==0 ? 'm-auto col-lg-2 FondoDetallesValor' : 'm-auto col-lg-2 FondoDetallesValor2'">
                <!-- <input v-model="item.Trimestre2Ind" type="text" class="form-control"> -->
                <vue-numeric @input="GetTotalTrimestre" placeholder="$ 0" class="form-control form-finanza form-control-sm text-center" currency="$" separator="," :precision="Decimal" v-model="item.Trimestre2Ind" ></vue-numeric>
            </div>
            <div :class="index%2==0 ? 'm-auto col-lg-2 FondoDetallesValor' : 'm-auto col-lg-2 FondoDetallesValor2'">
                <vue-numeric @input="GetTotalTrimestre" placeholder="$ 0" class="form-control form-finanza form-control-sm text-center" currency="$" separator="," :precision="Decimal" v-model="item.Trimestre3Ind" ></vue-numeric>
            </div>
            <div :class="index%2==0 ? 'm-auto col-lg-2 FondoDetallesValor' : 'm-auto col-lg-2 FondoDetallesValor2'">
                <vue-numeric @input="GetTotalTrimestre" placeholder="$ 0" class="form-control form-finanza form-control-sm text-center" currency="$" separator="," :precision="Decimal" v-model="item.Trimestre4Ind" ></vue-numeric>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4">
                <label for="" class="color-01 bold sticky">TOTALES</label>
            </div>
            <div class="col-lg-2">
                <vue-numeric disabled placeholder="$ 0" class="form-control form-finanza form-control-sm text-center color-01 bold" currency="$" separator="," :precision="Decimal" v-model="TotalTrimestre1" ></vue-numeric>
            </div>
            <div class="col-lg-2">
                <vue-numeric disabled placeholder="$ 0" class="form-control form-finanza form-control-sm text-center color-01 bold" currency="$" separator="," :precision="Decimal" v-model="TotalTrimestre2" ></vue-numeric>
            </div>
            <div class="col-lg-2">
                <vue-numeric disabled placeholder="$ 0" class="form-control form-finanza form-control-sm text-center color-01 bold" currency="$" separator="," :precision="Decimal" v-model="TotalTrimestre3" ></vue-numeric>
            </div>
            <div class="col-lg-2">
                <vue-numeric disabled placeholder="$ 0" class="form-control form-finanza form-control-sm text-center color-01 bold" currency="$" separator="," :precision="Decimal" v-model="TotalTrimestre4" ></vue-numeric>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:'FormDetalles',
    props:['pEmitSeccion'],
    data() {
        return {
            IdCuenta:0,
            ListaDetallesCuenta:[],
            TotalTrimestre1:0,
            TotalTrimestre2:0,
            TotalTrimestre3:0,
            TotalTrimestre4:0,
            Decimal:0,
            NumTabla:0
        }
    },
    methods: {
        GetDetallesCuenta(IdCuenta,pListaDetallesCuenta,PrimerT,SegundoT,TercerT,CuartoT,NumTabla){
            if (NumTabla!=undefined) {
                this.NumTabla=NumTabla;
            }
            this.IdCuenta=IdCuenta;
            this.ListaDetallesCuenta=pListaDetallesCuenta;
            this.TotalTrimestre1=parseFloat(PrimerT);
            this.TotalTrimestre2=parseFloat(SegundoT);
            this.TotalTrimestre3=parseFloat(TercerT);
            this.TotalTrimestre4=parseFloat(CuartoT);
        },
        GetTotalTrimestre(){
            this.TotalTrimestre1=0,
            this.TotalTrimestre2=0,
            this.TotalTrimestre3=0,
            this.TotalTrimestre4=0,
            this.ListaDetallesCuenta.forEach(element => {
                this.TotalTrimestre1+=parseFloat(element.Trimestre1Ind);
                this.TotalTrimestre2+=parseFloat(element.Trimestre2Ind);
                this.TotalTrimestre3+=parseFloat(element.Trimestre3Ind);
                this.TotalTrimestre4+=parseFloat(element.Trimestre4Ind);
            });
        },
        EnviarTotalTrimestres(){
            if (this.NumTabla==0) {
                this.bus.$emit('IgualarTrimestres',this.IdCuenta,this.TotalTrimestre1,this.TotalTrimestre2,this.TotalTrimestre3,this.TotalTrimestre4);
            }
            else{
                this.bus.$emit('IgualarTrimestres_'+this.NumTabla,this.IdCuenta,this.TotalTrimestre1,this.TotalTrimestre2,this.TotalTrimestre3,this.TotalTrimestre4);
            }
        }
    },
    created() {
        this.bus.$off('PassDetallesCuenta');
        this.bus.$on('PassDetallesCuenta',(IdCuenta,DetallesCuenta,PrimerT,SegundoT,TercerT,CuartoT,NumTabla)=>{
            this.GetDetallesCuenta(IdCuenta,DetallesCuenta,PrimerT,SegundoT,TercerT,CuartoT,NumTabla);
        });
        this.bus.$off('Save_'+this.pEmitSeccion);
        this.bus.$on('Save_'+this.pEmitSeccion,()=>{
            this.EnviarTotalTrimestres();
        });
    },
}
</script>

<style>
    .fondoformfinanciera{
        background: linear-gradient(to right,#FFF 33.3%, #D3F1EC 33.3%);
    }
    .FondoDetallesTitulos{
        background-color: #21BBA0;
        color: #FFF !important;
        text-align: center;
    }
    .FondoDetallesValor{
        background-color: #D3F1EC;
    }
    .fondoformfinanciera2{
        background: linear-gradient(to right,#FFF 33.3%, #C8E7E2 33.3%);
    }
    .FondoDetallesValor2{
        background-color: #C8E7E2;
    }
</style>