<template>
    <div>
        <CHead :oHead="Head"></CHead>

        <div class="row justify-content-start mt-3">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                <div class="card card-01">

                    <div v-if="NocountV" class="row">
                        <div class="col-md-12 col-lg-12">
							<h5 class="mb-3 text-center" style="color:red">Se requiere un vendedor para poder crear el plan de venta</h5>
							<hr>
						</div>
                    </div>

                    <div class="row">
                        <div class="col-md-12 col-lg-12">
                            <form class="form-inline justify-content-center">
                                <label class="mr-1">Vendedor </label>
								<!--<treeselect
										@input="get_listdata"
										:options="ListaVendedores"
										placeholder="Seleccione un Vendedor..."
										:searchable="false"
										v-model="IdVendedor"
										:disabled="bockBtn"
										class="col-1"
								/>-->

								<select :disabled="bockBtn" @change="get_listdata(0)" v-model="IdVendedor"  class="form-control mr-2">
									<option v-for="(item, index) in ListaVendedores" :key="index" :value="item.IdUsuario"
											:style="item.RegEstatus === 'B' ? 'background-color:#DDD;' : ''">{{item.Nombre}}</option>
								</select>

                                <label class="mr-1">Año </label>

                                    <select :disabled="bockBtn" @change="get_listdata(1)"  v-model="Anio" class="form-control mr-2">
                                    <option v-for="(item,index) in ListaAnios" :key="index" :value="item">{{item}}</option>
                                </select>
                                 <button :disabled="bockBtn" @click="Guardar" type="button"  class="btn btn-01">
                                    <i v-show="loading" class="fa fa-spinner fa-pulse fa-1x fa-fw"></i>
                                    <i class="fa fa-plus-circle"></i>
                                    {{txtSave}}
                                </button>
                            </form>
                            <hr>
                        </div>
                    </div>
                    <!--tablas-->
					<CLoader :pConfigLoad="ConfigLoad">
						<template slot="BodyFormLoad">
							<div class="row mt-2">
								<div class="col-12 col-sm-12 col-md-12 col-lg-12">
									<div class="table-venta">
										<Plan :ListaDetalle="ListaDetalle" :pBloker="blockAll"></Plan>
									</div>
								</div>
							</div>
						</template>
					</CLoader>

                </div>
            </div>
        </div>
        <!--siguientes tablas-->
		<CLoader :pConfigLoad="ConfigLoad">
			<template slot="BodyFormLoad">
				<Porcentaje :ListaDetalle="ListaDetalle" :pBloker="blockAll"></Porcentaje>
			</template>
		</CLoader>
		<CLoader :pConfigLoad="ConfigLoad">
			<template slot="BodyFormLoad">
				<PromedioC :ListaDetalle="ListaDetalle" :pBloker="blockAll"></PromedioC>
			</template>
		</CLoader>
        

    </div>
</template>
<script>


import Plan from './Plan.vue'
import Porcentaje from './PorcentajeSubtipos.vue'
import PromedioC from './PromedioContrato.vue'
import CHead from "../../../../components/CHead";



export default {
    props:['Id'],
    components:{
        Plan,
		Porcentaje,
		PromedioC,
		CHead

    },
    data() {
        return {
            planventas:{},
			//ListaTrabajadores:[],
			ListaVendedores:[],
			IdVendedor:0,
			ListaDetalle:[],
			ListaRol:["Vendedor","Gerente de ventas"],

            Head:{
				Title:'Plan de ventas',
                BtnNewShow:false,
                BtnNewName:'Nuevo',
                isreturn:true,
                isModal:false,
                isEmit:false,
                Url:'SubMenusFinanzas',
                ObjReturn:'',
             },
			 ConfigLoad:{
				ShowLoader:true,
				ClassLoad:false
			 },
			Trimestre1:0,
			Anio:'2020',
			loading:false,
			txtSave:'Guardar',
            ListaAnios:[],
            NocountV:false,
			bockBtn: false,
			blockAll: false,
		}
    },
    methods: {
		get_anios(){
			this.loading = true;
			this.bockBtn = true;
			this.$http.get(
                'funciones/getanios',
                {
                    params:{}
                }
            ).then( (res) => {
              this.ListaAnios 	= res.data.ListaAnios;
              this.Anio 		= res.data.AnioActual;
			  this.get_listVendedor(0);
            });
		},

		async get_listVendedor(op){
			// No se Envia RegEstatus: 'A', debido a que es posible ver el plan de ventas de los vendedores
			await this.$http.get('vendedores/get', {
				params:{
					orderEstatus: 1,
					yearDeleted: this.Anio,
				}

			}).then( (res) => {
				this.ListaVendedores = res.data.data.Vendedores;

				let isEmpty = false;
				if(op > 0) {
					let ursTep = this.ListaVendedores.find(item => item.IdUsuario === this.IdVendedor);
					if(ursTep !== undefined) {
						isEmpty = true;
					}

				}

				if (this.ListaVendedores.length>0) {
					if(!isEmpty) {
						this.IdVendedor = this.ListaVendedores[0].IdUsuario;
						this.NocountV = false;
						this.bockBtn = false;
						this.get_listdata();
					}

				}else{
					this.ListaVendedores.push({
						IdUsuario:0,
						Nombre:'-- No Existen Vendedores --'
					});

					this.loading 	= false;
					this.NocountV	= true;
				}

			});
		},

		async get_listdata(op){
			this.ConfigLoad.ShowLoader=true;
			this.ConfigLoad.ClassLoad=false;
			if(op > 0){
				await this.get_listVendedor(1);
			}

			if (this.IdVendedor !== '' && this.IdVendedor >0) {
				let ursTep = this.ListaVendedores.find(item => item.IdUsuario === this.IdVendedor);
				this.blockAll = ursTep.RegEstatus === 'B' ? true : false;

				this.$http.get('Cplanventas/get', {
					params:{
						IdVendedor:this.IdVendedor,
						Anio:this.Anio
					}

				}).then( (res) => {
					this.ListaDetalle	= res.data.data.detalle;
					this.loading 		= false;
				}).finally(()=>{
					this.ConfigLoad.ShowLoader=false;
					this.ConfigLoad.ClassLoad=true;
				});
			}
		},

		Guardar() {
			if (this.ListaDetalle.length>0) {
				this.loading=true;

				this.$http.post('planventas/post', {
					Detalle:this.ListaDetalle,
					Anio:this.Anio,
					IdVendedor:this.IdVendedor

				}).then( (res) => {
					this.$toast.success('Información Guardada');
					this.get_listdata();
					this.loading=false;

				}).catch( err => {
					this.loading=false;
					this.$toast.error('Ocurrio un error al agregar los datos');
				});
			}
		}

    },
    created() {
		this.get_anios();
		this.bus.$off('Regresar');
		this.bus.$on('Regresar',()=> {
			this.$router.push({name:'SubMenusFinanzas'});
		});
    },
    mounted() {

    },
    computed: {

    },
}
</script>
