<template>
    <CHeader :pHeaderProps="HeaderProps">
        <template slot="Contenido">
            <CLoader :pConfigLoad="ConfigLoad">
                <template slot="BodyFormLoad">
                    <div v-show="ListObservaciones.length > 0" class="row mt-3" v-for="(item,index) in ListObservaciones" :key="index">
                        <div class="col-sm-12">
                            <div class="card card-01 shadow p-0">
                                <div @click="Reducir(index)" :id="index+'labeled'" class="card-header card-header-01" role="button" data-toggle="collapse" :data-target="'#'+index" aria-expanded="false" :aria-controls="index">
                                    <div class="row">
                                        <div class="col-6 d-flex align-items-center">
                                            <h6>{{ $getCleanDate(item.FechaReg,false) }}</h6>
                                        </div>
                                        <div class="col-6 d-flex justify-content-end">
                                            <i class="fa fa-chevron-down icons-equipo icon-color-app" :index="index+'collapse'"></i>
                                        </div>
                                    </div>
                                </div>
                                <div :id="index" class="collapse" :aria-labelledby="index+'labeled'">
                                    <div class="card-body mt-2 mb-2">
                                        <span style="white-space:break-spaces;">{{ item.Observacion }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-show="ListObservaciones.length == 0"  class="row mt-3">
                        <div class="col-12 col-sm-12 text-center mt-3">
                            <h2>Sin Observaciones Críticas.</h2>
                        </div>
                    </div>
                </template>
            </CLoader>
        </template>
    </CHeader>
</template>

<script>
import CHeader from "@/views/webapp/components/HeaderWebApp.vue";
export default {
    props:['pEquipo'],
    components:{CHeader},
    data() {
        return {
            HeaderProps:{
                ShowSubHeader:true,
                ShowTecnico:false,
                ShowFechaHoy:false,
                ShowMenuDropdown:false,
                ShowFotoPerfil:false,
                ShowReturn:true,
                TextoHeader:'Observaciones Críticas',
                ShowLogo:false,
                TextoCentroSubHeader:''
            },
            Filtro:{
                TotalItem:0,
                Pagina:1,
                Entrada:20
            },
            ConfigLoad:{
				ShowLoader:true,
				ClassLoad:false,
			},
            ListObservaciones:[],
            Equipo:{}
        }
    },
    methods: {
        async Lista(){
            this.ConfigLoad.ShowLoader=true;
            this.ConfigLoad.ClassLoad=false;
            await this.$http.get('observacionequipo/get',{
                params:{
                    IdEquipo:this.Equipo.IdEquipo,
                    Entrada:this.Filtro.Entrada,
                    RegEstatus:'A'
                }
            }).then((res)=>{
                res.data.data.observaciones.forEach(element => {
                    element.collapse=false;
                });
                this.ListObservaciones=res.data.data.observaciones;
            }).finally(()=>{
                this.ConfigLoad.ShowLoader=false;
                this.ConfigLoad.ClassLoad=true;
            })
        },
        Regresar(){
            this.$router.push({name:'EquiposServicioWebApp'});
        },
        Reducir(index){
            if (this.ListObservaciones[index].collapse==false) {
                $('#'+index).collapse('show');
                this.ListObservaciones[index].collapse=true;
            }
            else{
                $('#'+index).collapse('hide');
                this.ListObservaciones[index].collapse=false;
            }
        }
    },
    created() {
        this.bus.$off('EmitReturn');
        this.bus.$on('EmitReturn',()=>{
            this.Regresar();
        });
    },
    mounted() {
        this.Equipo=this.pEquipo;
        this.HeaderProps.TextoCentroSubHeader=this.Equipo.Nequipo;
        this.Lista();
    },
}
</script>

<style>

</style>