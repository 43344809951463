<template>
    <div>
        <b-overlay :show="this.isOverlay" spinner-variant="primary" >
            <CHead :oHead="oHead">
            </CHead>
            <div class="row justify-content-start mt-3">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                    <div class="card card-01">
                        <div  v-if="ShowComponents" class="row justify-content-end mt-2">
                            <div class="col-12 col-sm-12 col-md-12 col-lg-9">
                                <div class="row">
                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                        <div class="form-group form-row">
                                            <div class="col-12 col-sm-12 col-md-6 col-lg-5">
                                                <label>Cliente</label>
                                                <input placeholder="Cliente" type="text" class="form-control" v-model="Ocliente.Nombre"  readonly="true">
                                                <label id="lblmsuser" style="color:red"><Cvalidation v-if="this.errorvalidacion.Cliente" :Mensaje="errorvalidacion.Cliente[0]"></Cvalidation></label>
                                            </div>

                                            <div class="col-12 col-sm-12 col-md-2 col-lg-2">
                                                <button type="button" :disabled="BtnBuscar" class="btn btn-01 search mx-sm-3 mt-6" @click="ListaCliente" data-toggle="modal" data-target="#ModalForm3"  data-backdrop="static">Buscar</button>
                                            </div>
                                        </div>
                                        <div class="form-group form-row">
                                            <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                                                <label>Teléfono</label>
                                                <input placeholder="Teléfono"  v-model="Ocliente.Telefono"  type="text" class="form-control" readonly="true">
                                            </div>
                                            <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                                                <label>Correo</label>
                                                <input placeholder="Correo" v-model="Ocliente.Correo"  type="text" class="form-control" readonly="true">
                                            </div>
                                            <div class="col-12 col-sm-12 col-md-8 col-lg-4">
                                                <label>Dirección</label>
                                                <input placeholder="Dirección" v-model="Ocliente.Direccion"  type="text" class="form-control" readonly="true">
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div class="row mt-2">
                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                        <nav>
                                            <div class="nav nav-tabs nav-tabs-table" id="nav-tab" role="tablist">
                                                <a class="nav-item nav-link active" id="dato-tab" data-toggle="tab" href="#nav-dato" role="tab" aria-controls="nav-dato" aria-selected="true">Datos</a>
                                                <a class="nav-item nav-link" id="obra-tab" data-toggle="tab" href="#nav-obra" role="tab" aria-controls="nav-obra" aria-selected="false">Mano de Obra</a>
                                                <a class="nav-item nav-link" id="material-tab" data-toggle="tab" href="#nav-material" role="tab" aria-controls="nav-material" aria-selected="false">Materiales</a>
                                                <a class="nav-item nav-link" id="equipo-tab" data-toggle="tab" href="#nav-equipo" role="tab" aria-controls="nav-equipo" aria-selected="false">Equipos</a>
                                                <a class="nav-item nav-link" id="contratista-tab" data-toggle="tab" href="#nav-contratista" role="tab" aria-controls="nav-contratista" aria-selected="false">Contratistas</a>
                                                <a class="nav-item nav-link" id="viatico-tab" data-toggle="tab" href="#nav-viatico" role="tab" aria-controls="nav-viatico" aria-selected="false">Viaticos</a>
                                                <!-- <a class="nav-item nav-link" id="miscelaneos-tab" data-toggle="tab" href="#nav-miscelaneos" role="tab" aria-controls="nav-miscelaneos" aria-selected="false">Misceláneos</a> -->
                                            </div>
                                        </nav>
                                        <div class="tab-content tab-content-table">
                                            <div class="tab-pane fade show active" id="nav-dato" role="tabpanel" aria-labelledby="dato-tab">
                                                <div class="row mt-4">
                                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                                        <h4>Datos de Sitio</h4>
                                                        <hr>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12 col-sm-12 col-md-3 col-lg-3">
                                                        <label>Distancia KM</label>
                                                        <vue-numeric @input="CalcularCostoKM" placeholder="0 Km"  :minus="false" class="form-control w-03"  currency="" separator="," :precision="1" v-model="cotizacion_servicio.Distancia"></vue-numeric>
                                                        <label id="lblmsuser" style="color:red"><Cvalidation v-if="this.errorvalidacion.Distancia" :Mensaje="errorvalidacion.Distancia[0]"></Cvalidation></label>
                                                    </div>
                                                    <div class="col-12 col-sm-12 col-md-3 col-lg-3">
                                                        <label>Velocidad Prom. KM/H</label>
                                                        <vue-numeric @input="CalcularTotalMODesplazamiento" placeholder="0 Km/h"  :minus="false" class="form-control w-03"  currency="" separator="," :precision="0" v-model="cotizacion_servicio.Velocidad"></vue-numeric>
                                                        <label id="lblmsuser" style="color:red"><Cvalidation v-if="this.errorvalidacion.Velocidad" :Mensaje="errorvalidacion.Velocidad[0]"></Cvalidation></label>
                                                    </div>
                                                    <div class="col-12 col-sm-12 col-md-3 col-lg-3">
                                                        <label>Costo KM</label>
                                                        <vue-numeric placeholder="$ 0.00"  disabled :minus="false" class="form-control w-03"  currency="$" separator="," :precision="0" v-model="cotizacion_servicio.CostoKm"></vue-numeric>
                                                        <label id="lblmsuser" style="color:red"><Cvalidation v-if="this.errorvalidacion.CostoKm" :Mensaje="errorvalidacion.CostoKm[0]"></Cvalidation></label>
                                                    </div>
                                                    <div class="col-12 col-sm-12 col-md-3 col-lg-3">
                                                        <label>Garantía %</label>
                                                        <vue-numeric @input="CalcularTotalGarantia" placeholder="0 %"  :minus="false" class="form-control w-03"  currency="" separator="," :precision="0" v-model="cotizacion_servicio.Garantia"></vue-numeric>
                                                        <label id="lblmsuser" style="color:red"><Cvalidation v-if="this.errorvalidacion.Garantia" :Mensaje="errorvalidacion.Garantia[0]"></Cvalidation></label>
                                                    </div>
                                                </div>
                                                <div class="row mt-3">
                                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                                        <h4>Datos Adicionales</h4>
                                                        <hr>
                                                    </div>
                                                </div>
                                                <div class="row mb-3">
                                                    <div class="col-12 col-sm-12 col-md-3 col-lg-3">
                                                        <label>Gross Profit %</label>
                                                        <vue-numeric placeholder="0 %"  :minus="false" @input="ValidarGross();CalcularGastoOperaciones();CalcularUtilidadAprox();" class="form-control w-03"  currency="" separator="," :precision="0" v-model="cotizacion_servicio.GrossProfit"></vue-numeric>
                                                        <label id="lblmsuser" style="color:red"><Cvalidation v-if="this.errorvalidacion.GrossProfit" :Mensaje="errorvalidacion.GrossProfit[0]"></Cvalidation></label>
                                                    </div>
                                                    <div class="col-12 col-sm-12 col-md-3 col-lg-3">
                                                        <label>Factor Hora Extra %</label>
                                                        <vue-numeric placeholder="0 %"  :minus="false" @blur="ValidarGross" class="form-control w-03"  currency="" separator="," :precision="0" v-model="cotizacion_servicio.FactorHoraExtra"></vue-numeric>
                                                        <label id="lblmsuser" style="color:red"><Cvalidation v-if="this.errorvalidacion.FactorHoraExtra" :Mensaje="errorvalidacion.FactorHoraExtra[0]"></Cvalidation></label>
                                                    </div>
                                                    <div class="col-12 col-sm-12 col-md-3 col-lg-3">
                                                        <label>Utilidad Aprox. %</label>
                                                        <vue-numeric placeholder="0 %" disabled :minus="false" @input="ValidarGross" class="form-control w-03"  currency="" separator="," :precision="0" v-model="cotizacion_servicio.Utilidad"></vue-numeric>
                                                        <label id="lblmsuser" style="color:red"><Cvalidation v-if="this.errorvalidacion.Utilidad" :Mensaje="errorvalidacion.Utilidad[0]"></Cvalidation></label>
                                                    </div>
                                                    <div class="col-12 col-sm-12 col-md-3 col-lg-3">
                                                        <label>Gasto Operaciones %</label>
                                                        <vue-numeric placeholder="0 %" disabled :minus="false" @input="ValidarGross" class="form-control w-03"  currency="" separator="," :precision="0" v-model="cotizacion_servicio.GastoOperaciones"></vue-numeric>
                                                        <label id="lblmsuser" style="color:red"><Cvalidation v-if="this.errorvalidacion.GastoOperaciones" :Mensaje="errorvalidacion.GastoOperaciones[0]"></Cvalidation></label>
                                                    </div>
                                                </div>
                                                <div class="row mb-3">
                                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                                        <label>Observaciones</label>
                                                        <textarea placeholder="Observaciones"  class="form-control" v-model="cotizacion_servicio.Observaciones" cols="10" rows="3"></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tab-pane fade" id="nav-obra" role="tabpanel" aria-labelledby="obra-tab">
                                                <table class="table table-sm table-02 mt-4">
                                                    <thead>
                                                        <tr>
                                                            <th class="text-center colum01">Categoría</th>
                                                            <th class="text-center colum01">Costo M.O</th>
                                                            <th class="text-center colum01">Hr. Normal</th>
                                                            <th class="text-center colum01">Hr. Extra</th>
                                                            <th class="text-center colum01">Total</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(item, index) in cotizacion_servicio.ListaManoObra" :key="index">
                                                            <td>
                                                                {{item.Categoria}}
                                                            </td>
                                                            <td>
                                                                <p v-if="item.Input" >
                                                                    <vue-numeric  @input="cal_costoMO(index)" placeholder="$ 0.00" :minus="false"  class="form-control form-control-sm"  currency="$" separator="," :precision="2" v-model="item.CostoMO"></vue-numeric>
                                                                    <!-- <Cmoneda  @blur="cal_costoMO(index)"  :activo="false" :clase="'form-control form-finanza form-control-sm text-left'"  :readonly="false"     :minus="true"        currency="$" separator="," :precision="Decimal" v-model="item.costoMO"></Cmoneda> -->
                                                                </p>
                                                                <p v-else>
                                                                    $ {{item.CostoMO}}
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <vue-numeric  @input="cal_costoMO(index)" placeholder="0.00" :minus="false"  class="form-control form-control-sm  w-04"  currency="" separator="," :precision="2" v-model="item.HoraNormal"></vue-numeric>
                                                                <!-- <Cmoneda  @input="cal_costoMO(index)"  :activo="false" :clase="'form-control form-finanza form-control-sm text-left'"  :readonly="false"     :minus="true"        currency="$" separator="," :precision="Decimal" v-model="item.horaNormal"></Cmoneda> -->
                                                            </td>
                                                            <td>
                                                                <vue-numeric  @input="cal_costoMO(index)" placeholder="0.00" :minus="false"  class="form-control form-control-sm  w-04"  currency="" separator="," :precision="2" v-model="item.HoraExtra"></vue-numeric>
                                                                <!-- <Cmoneda  @input="cal_costoMO(index)"  :activo="false" :clase="'form-control form-finanza form-control-sm text-left'"  :readonly="false"     :minus="true"        currency="$" separator="," :precision="Decimal" v-model="item.horaExtra"></Cmoneda> -->
                                                            </td>
                                                            <td>
                                                                <vue-numeric  disabled  :minus="false" placeholder="$ 0.00" class="form-control form-control-sm"  currency="$" separator="," :precision="2" v-model="item.TotalIndividual"></vue-numeric>
                                                                <!-- <Cmoneda  @input="cal_costoMO(index)"  :activo="true" :clase="'form-control form-finanza form-control-sm text-left'"  :readonly="true"     :minus="true"        currency="$" separator="," :precision="Decimal" v-model="item.totalIndividual"></Cmoneda> -->
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="4" class="text-right">
                                                                Total Con Traslado
                                                            </td>
                                                            <td colspan="1">
                                                                <vue-numeric  disabled placeholder="$ 0.0"  :minus="false" class="form-control form-control-sm"  currency="$" separator="," :precision="2" v-model="cotizacion_servicio.TotalManoDeObra"></vue-numeric>
                                                                <!-- <Cmoneda  :activo="true" :clase="'form-control form-finanza form-control-sm text-left'"  :readonly="true"     :minus="true"        currency="$" separator="," :precision="Decimal" v-model="cotizacion_servicio.TotalManoDeObra"></Cmoneda> -->
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div class="tab-pane fade" id="nav-material" role="tabpanel" aria-labelledby="material-tab">
                                                <div class="row">
                                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 text-right">
                                                        <form class="form-inline float-right">
                                                            <button @click="add_Materiales(undefined)" type="button" class="btn btn-01 add mx-sm-2">
                                                                Agregar Fila
                                                            </button>
                                                            <button  data-toggle="modal" data-target="#ModalForm" type="button" class="btn btn-01 search">Producto</button>
                                                        </form>
                                                    </div>
                                                </div>

                                                <table class="table table-sm table-03 mt-3">
                                                    <thead>
                                                        <tr>
                                                            <th style="width:15% !important;" class="text-center colum02">Código</th>
                                                            <th style="width:50% !important;" class="text-center colum02">Descripción</th>
                                                            <th class="text-center colum02">Cantidad</th>
                                                            <th class="text-center colum02">Costo Unit</th>
                                                            <th class="text-center colum02">Total</th>
                                                            <th class="text-center colum02"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(item, index) in cotizacion_servicio.ListaMaterialCot" :key="index">
                                                            <td>
                                                                <input placeholder="Código"  v-model="item.Codigo" type="text" class="form-control form-control-sm" :disabled="item.blocked">
                                                            </td>
                                                            <td>
                                                                <input placeholder="Descripción"  v-model="item.NombreMat" type="text" class="form-control form-control-sm" :disabled="item.blocked">
                                                            </td>
                                                            <td>
                                                                <vue-numeric placeholder="0" @input="calcula_importe_material(item)" :minus="false" class="form-control form-control-sm"  currency="" separator="," :precision="2" v-model="item.Cantidad"></vue-numeric>
                                                            </td>
                                                            <td>
                                                                <vue-numeric placeholder="$ 0.00" @input="calcula_importe_material(item)" :minus="false" class="form-control form-control-sm"  currency="$" separator="," :precision="2" v-model="item.CostoUnitario"></vue-numeric>
                                                            </td>
                                                            <td>
                                                                <vue-numeric placeholder="$ 0.00" disabled :minus="false" class="form-control form-control-sm"  currency="$" separator="," :precision="2" v-model="item.TotalIndividual"></vue-numeric>
                                                            </td>
                                                            <td>
                                                                <button @click="delete_mat(index);" type="button" href="#" class="btn-icon-02">
                                                                    <i class="fas fa-times"></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="4" class="text-right">Total</td>
                                                            <td colspan="1" class="text-right">
                                                                <vue-numeric disabled placeholder="$ 0.00" :minus="true" class="form-control form-control-sm"  currency="$" separator="," :precision="2" v-model="cotizacion_servicio.TotalMateriales"></vue-numeric>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div class="tab-pane fade" id="nav-equipo" role="tabpanel" aria-labelledby="equipo-tab">
                                                <div class="row">
                                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 text-right">
                                                        <form class="form-inline float-right">
                                                            <button @click="add_Equipos(undefined)" type="button" class="btn btn-01 add mx-sm-2">
                                                                Agregar Fila
                                                            </button>
                                                            <button  data-toggle="modal" data-target="#ModalForm2" type="button" class="btn btn-01 search">Equipo</button>
                                                        </form>
                                                    </div>
                                                </div>
                                                <table class="table table-sm table-03 mt-3">
                                                    <thead>
                                                        <tr>
                                                            <th style="width:15% !important;" class="text-center colum02">Código</th>
                                                            <th style="width:50% !important;" class="text-center colum02">Descripción</th>
                                                            <th class="text-center colum02">Cantidad</th>
                                                            <th class="text-center colum02">Costo Unit</th>
                                                            <th class="text-center colum02">Total</th>
                                                            <th class="text-center colum02"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(item, index) in cotizacion_servicio.ListaEquipoCot" :key="index">
                                                            <td>
                                                                <input placeholder="Código"  v-model="item.Codigo" type="text" class="form-control form-control-sm" :disabled="item.blocked">
                                                            </td>
                                                            <td>
                                                                <input placeholder="Descripción"  v-model="item.Nombre" type="text" class="form-control form-control-sm" :disabled="item.blocked">
                                                            </td>
                                                            <td>
                                                                <vue-numeric placeholder="0" @input="calcula_importe_equipo(item)" :minus="false" class="form-control form-control-sm"  currency="" separator="," :precision="2" v-model="item.cantidad"></vue-numeric>
                                                            </td>
                                                            <td>
                                                                <vue-numeric placeholder="$ 0.00" @input="calcula_importe_equipo(item)" :minus="false" class="form-control form-control-sm"  currency="$" separator="," :precision="2" v-model="item.costoUnitario"></vue-numeric>
                                                            </td>
                                                            <td>
                                                                <vue-numeric placeholder="$ 0.00" disabled :minus="false" class="form-control form-control-sm"  currency="$" separator="," :precision="2" v-model="item.totalIndividual"></vue-numeric>
                                                            </td>
                                                            <td>
                                                                <button @click="delete_equipo(index);" type="button" href="#" class="btn-icon-02">
                                                                    <i class="fas fa-times"></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="4" class="text-right">Total</td>
                                                            <td colspan="1" class="text-right">
                                                                <vue-numeric disabled placeholder="$ 0.00" :minus="true" class="form-control form-control-sm"  currency="$" separator="," :precision="2" v-model="cotizacion_servicio.TotalEquipos"></vue-numeric>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div class="tab-pane fade" id="nav-contratista" role="tabpanel" aria-labelledby="contratista-tab">
                                                <div class="row">
                                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 text-right">
                                                        <form class="form-inline float-right">
                                                            <button @click="add_Contratistas(undefined)" type="button" class="btn btn-01 add mx-sm-2">
                                                                Agregar Fila
                                                            </button>
                                                        </form>
                                                    </div>
                                                </div>
                                                <table class="table table-sm table-03 mt-3">
                                                    <thead>
                                                        <tr>
                                                            <th class="text-center colum02">Descripción</th>
                                                            <th class="text-center colum02">Cantidad</th>
                                                            <th class="text-center colum02">Costo Unit</th>
                                                            <th class="text-center colum02">Total</th>
                                                            <th class="text-center colum02">Burden</th>
                                                            <th class="text-center colum02"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(item, index) in cotizacion_servicio.ListaContratista" :key="index">
                                                            <td>
                                                                <input placeholder="Descripción"  v-model="item.Nombre" type="text" class="form-control form-control-sm" :disabled="item.blocked">
                                                            </td>
                                                            <td>
                                                                <vue-numeric placeholder="0" @input="calcula_importe_contratista(item)" :minus="false" class="form-control form-control-sm"  currency="" separator="," :precision="0" v-model="item.Cantidad"></vue-numeric>
                                                            </td>
                                                            <td>
                                                                <vue-numeric placeholder="$ 0.00" @input="calcula_importe_contratista(item)" :minus="false" class="form-control form-control-sm"  currency="$" separator="," :precision="2" v-model="item.CostoUnitario"></vue-numeric>
                                                            </td>
                                                            <td>
                                                                <vue-numeric placeholder="$ 0.00" disabled :minus="false" class="form-control form-control-sm"  currency="$" separator="," :precision="2" v-model="item.TotalIndividual"></vue-numeric>
                                                            </td>
                                                            <td>
                                                                <vue-numeric placeholder="$ 0.00" disabled :minus="false" class="form-control form-control-sm"  currency="$" separator="," :precision="2" v-model="item.Burden"></vue-numeric>
                                                            </td>
                                                            <td>
                                                                <button @click="delete_contratista(index);" type="button" href="#" class="btn-icon-02">
                                                                    <i class="fas fa-times"></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="3" class="text-right">Total</td>
                                                            <td colspan="1" class="text-right">
                                                                <vue-numeric disabled placeholder="$ 0.00" :minus="true" class="form-control form-control-sm"  currency="$" separator="," :precision="2" v-model="cotizacion_servicio.TotalContratistas"></vue-numeric>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div class="tab-pane fade" id="nav-viatico" role="tabpanel" aria-labelledby="viatico-tab">
                                                <div class="row">
                                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 text-right">
                                                        <form class="form-inline float-right">
                                                            <button @click="add_Viaticos(undefined)" type="button" class="btn btn-01 add mx-sm-2">
                                                                Agregar Fila
                                                            </button>
                                                        </form>
                                                    </div>
                                                </div>
                                                <table class="table table-sm table-03 mt-3">
                                                    <thead>
                                                        <tr>
                                                            <th class="text-center colum02">Descripción</th>
                                                            <th class="text-center colum02">Cantidad</th>
                                                            <th class="text-center colum02">Costo Unit</th>
                                                            <th class="text-center colum02">Total</th>
                                                            <th class="text-center colum02"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(item, index) in cotizacion_servicio.ListaViatico" :key="index">
                                                            <td>
                                                                <input placeholder="Descripción"  v-model="item.Nombre" type="text" class="form-control form-control-sm" :disabled="item.blocked">
                                                            </td>
                                                            <td>
                                                                <vue-numeric placeholder="0" @input="calcula_importe_viatico(item)" :minus="false" class="form-control form-control-sm"  currency="" separator="," :precision="0" v-model="item.Cantidad"></vue-numeric>
                                                            </td>
                                                            <td>
                                                                <vue-numeric placeholder="$ 0.00" @input="calcula_importe_viatico(item)" :minus="false" class="form-control form-control-sm"  currency="$" separator="," :precision="2" v-model="item.CostoUnitario"></vue-numeric>
                                                            </td>
                                                            <td>
                                                                <vue-numeric placeholder="$ 0.00" disabled :minus="false" class="form-control form-control-sm"  currency="$" separator="," :precision="2" v-model="item.TotalIndividual"></vue-numeric>
                                                            </td>
                                                            <td>
                                                                <button @click="delete_viatico(index);" type="button" href="#" class="btn-icon-02">
                                                                    <i class="fas fa-times"></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="3" class="text-right">Total</td>
                                                            <td colspan="1" class="text-right">
                                                                <vue-numeric disabled placeholder="$ 0.00" :minus="true" class="form-control form-control-sm"  currency="$" separator="," :precision="2" v-model="cotizacion_servicio.TotalViaticos"></vue-numeric>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-12 col-md-5 col-lg-3 mb-01">
                                <div class="row">
                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                        <div class="card card-03">
                                            <div class="card-header">
                                                <h4 class="text-white">Folio: {{cotizacion_servicio.Folio}} | Resumen</h4>
                                            </div>
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                                        <label>Vehículos</label>
                                                        <vue-numeric disabled placeholder="$ 0.00"  :minus="true" class="form-control form-control-sm "  currency="$" separator="," :precision="2" v-model="cotizacion_servicio.TotalCostoKm"></vue-numeric>
                                                        <label id="lblmsuser" style="color:red"><Cvalidation v-if="this.errorvalidacion.TotalCostoKm" :Mensaje="errorvalidacion.TotalCostoKm[0]"></Cvalidation></label>
                                                    </div>
                                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                                        <label>Mano de Obra</label>
                                                        <vue-numeric disabled placeholder="$ 0.00"  :minus="true" class="form-control form-control-sm "  currency="$" separator="," :precision="2" v-model="cotizacion_servicio.TotalManoDeObra"></vue-numeric>
                                                        <label id="lblmsuser" style="color:red"><Cvalidation v-if="this.errorvalidacion.TotalManoDeObra" :Mensaje="errorvalidacion.TotalManoDeObra[0]"></Cvalidation></label>
                                                    </div>
                                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                                        <label>Costo Burden</label>
                                                        <vue-numeric disabled placeholder="$ 0.00"  :minus="true" class="form-control form-control-sm "  currency="$" separator="," :precision="2" v-model="cotizacion_servicio.CostoBurden"></vue-numeric>
                                                        <label id="lblmsuser" style="color:red"><Cvalidation v-if="this.errorvalidacion.CostoBurden" :Mensaje="errorvalidacion.CostoBurden[0]"></Cvalidation></label>
                                                    </div>
                                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                                        <label>Materiales</label>
                                                        <vue-numeric disabled placeholder="$ 0.00"  :minus="true" class="form-control form-control-sm "  currency="$" separator="," :precision="2" v-model="cotizacion_servicio.TotalMateriales"></vue-numeric>
                                                        <label id="lblmsuser" style="color:red"><Cvalidation v-if="this.errorvalidacion.TotalMateriales" :Mensaje="errorvalidacion.TotalMateriales[0]"></Cvalidation></label>
                                                    </div>
                                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                                        <label>Equipos</label>
                                                        <vue-numeric disabled placeholder="$ 0.00"  :minus="true" class="form-control form-control-sm "  currency="$" separator="," :precision="2" v-model="cotizacion_servicio.TotalEquipos"></vue-numeric>
                                                        <label id="lblmsuser" style="color:red"><Cvalidation v-if="this.errorvalidacion.TotalEquipos" :Mensaje="errorvalidacion.TotalEquipos[0]"></Cvalidation></label>
                                                    </div>
                                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                                        <label>Contratistas</label>
                                                        <vue-numeric disabled placeholder="$ 0.00"  :minus="true" class="form-control form-control-sm "  currency="$" separator="," :precision="2" v-model="cotizacion_servicio.TotalContratistas"></vue-numeric>
                                                        <label id="lblmsuser" style="color:red"><Cvalidation v-if="this.errorvalidacion.TotalContratistas" :Mensaje="errorvalidacion.TotalContratistas[0]"></Cvalidation></label>
                                                    </div>
                                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                                        <label>Viaticos</label>
                                                        <vue-numeric disabled placeholder="$ 0.00"  :minus="true" class="form-control form-control-sm "  currency="$" separator="," :precision="2" v-model="cotizacion_servicio.TotalViaticos"></vue-numeric>
                                                        <label id="lblmsuser" style="color:red"><Cvalidation v-if="this.errorvalidacion.TotalViaticos" :Mensaje="errorvalidacion.TotalViaticos[0]"></Cvalidation></label>
                                                    </div>
                                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                                        <label>Garantía</label>
                                                        <vue-numeric disabled placeholder="$ 0.00"  :minus="true" class="form-control form-control-sm "  currency="$" separator="," :precision="2" v-model="TotalGarantia"></vue-numeric>
                                                    </div>
                                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                                        <hr class="total">
                                                    </div>
                                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                                        <label class="lab-01">Costo Total</label>
                                                        
                                                        <vue-numeric disabled placeholder="$ 0.00"  :minus="true" class="form-control form-control-sm "  currency="$" separator="," :precision="2" v-model="cotizacion_servicio.TotalGlobal"></vue-numeric>
                                                        <label id="lblmsuser" style="color:red"><Cvalidation v-if="this.errorvalidacion.TotalGlobal" :Mensaje="errorvalidacion.TotalGlobal[0]"></Cvalidation></label>
                                                    </div>
                                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                                        <label class="lab-01">Valor Venta</label>
                                                        <vue-numeric disabled placeholder="$ 0.00"  :minus="true" class="form-control form-control-sm numer border-yellow"  currency="$" separator="," :precision="2" v-model="cotizacion_servicio.ValorVenta"></vue-numeric>
                                                        <label id="lblmsuser" style="color:red"><Cvalidation v-if="this.errorvalidacion.ValorVenta" :Mensaje="errorvalidacion.ValorVenta[0]"></Cvalidation></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 text-right">
									<Cbtnsave :IsModal="false" :RegresarA="oHead.Url"></Cbtnsave>
							</div>
                        </div>
                    </div>
                </div>
            </div>
        </b-overlay>

        <Modal  :size="size">
            <template slot="Form" >
                <Cmateriales :pTipo="2"></Cmateriales>
            </template>
        </Modal>

        <Modal  :size="size" :NameModal="'ModalForm2'">
            <template slot="Form" >
                <Cequipos :pTipo="2"></Cequipos>
            </template>
        </Modal>

        <Ccliente :TipoModal='1'></Ccliente>
    </div>
</template>

<script>
import Modal from '@/components/Cmodal.vue';
import Cmateriales from '@/views/catalogos/cotizacion/material/List.vue';
import Cequipos from '@/views/catalogos/cotizacion/equipo/List.vue';
import Ccliente from '@/components/Ccliente.vue';
export default {
    name:'FormCotizacion',
    props:['NameList','Id','objsucursal'],
    components:{
        Modal,Ccliente,Cmateriales,Cequipos
    },
    data() {
        return {
            BtnBuscar:false,
            Modal:true,//Sirve pra los botones de guardado
            FormName:'Cotizacion',//Sirve para donde va regresar
            size :"modal-xl",
            ListaClientes:[],
            ListaSucursal:[],
            Mostrar:true,
            Regresar:false,
            TituloLista:'Lista de Clientes',
            Ocliente:{IdClienteS:0,IdCliente:0,Nombre:'',Direccion:'',Telefono:'',Correo:''},
            urlApiCostoKm:"costoskm/get",
            Listacostoskm:[],
            ValorKMData:0,
            urlApiMo:"cotizacion_servicio/listmo",
            ListManoObraCot:[],
            InicioCalculo:0,
            Burden:0,
            TotalGarantia:0,
            cotizacion_servicio:{
                IdServicio:0,
                IdCotizacionServicio:0,
                IdCliente:0,
                GrossProfit:0,
                Utilidad:0,
                GastoOperaciones:0,
                FactorHoraExtra:0,
                TotalMateriales:0,
                TotalEquipos:0,
                TotalContratistas:0,
                TotalViaticos:0,
                TotalManoDeObra:0,
                TotalMiscelaneos:0,
                CostoKm:0,
                TotalGlobal:0,
                Distancia:0,
                Velocidad:0,
                TotalCostoKm:0,
                Garantia:0,
                CostoBurden:0,
                CostoDesplazamiento:0,
                CostoManoObraD:0,
                ValorVenta:0,
                ListaManoObra:[],
                ListaMaterialCot:[],
                ListaEquipoCot:[],
                ListaMiscelaneCot:[],
                ListaContratista:[],
                ListaViatico:[]
            },
            ConfigLoad:{
                ShowLoader:false,
                ClassLoad:true,
            },
            ShowComponents:false,
            errorvalidacion:[],
            oHead:{//Encabezado
                Title:'Cotizacion de servicio',
                BtnNewShow:false,
                BtnNewName:'Nuevo',
                isreturn:true,
                isModal:false,
                isEmit:false,
                Url:'cotizacion_list',
                ObjReturn:'',
             },

            Listamarkup:[],
            isOverlay: true
        }
    },
    methods: {
        async get_ListaMarkup()
		{
			await this.$http.get(
				'markup/get',
				{
					params:{
						Nombre:'',
						Entrada:500,
						pag:0,
						RegEstatus:'A'
					}
				}
			).then( (res) => {
				this.Listamarkup =res.data.data.row;
			});
		},
        async Get_Recovery(){
            this.ConfigLoad.ShowLoader=true;
            // this.ConfigLoad.ClassLoad=false;
			await this.$http.get('cotizacion_servicio/recovery', {
				params:{
					IdCotizacionServicio: this.cotizacion_servicio.IdCotizacionServicio
				}
			}).then((res) => {
                if (res.data.data.cotizacion_servicio.TotalEquipos=='' || res.data.data.cotizacion_servicio.TotalEquipos==null) {
                    res.data.data.cotizacion_servicio.TotalEquipos=0;
                }
                if (res.data.data.cotizacion_servicio.TotalContratistas=='' || res.data.data.cotizacion_servicio.TotalContratistas==null) {
                    res.data.data.cotizacion_servicio.TotalContratistas=0;
                }
                if (res.data.data.cotizacion_servicio.TotalViaticos=='' || res.data.data.cotizacion_servicio.TotalViaticos==null) {
                    res.data.data.cotizacion_servicio.TotalViaticos=0;
                }
				// this.cotizacion_servicio	= res.data.data.cotizacion_servicio;
                this.cotizacion_servicio.IdCotizacionServicio	= res.data.data.cotizacion_servicio.IdCotizacionServicio;
                this.cotizacion_servicio.IdCliente	= res.data.data.cotizacion_servicio.IdCliente;
                this.cotizacion_servicio.IdUsuario	= res.data.data.cotizacion_servicio.IdUsuario;
                this.cotizacion_servicio.IdSucursal	= res.data.data.cotizacion_servicio.IdSucursal;
                this.cotizacion_servicio.GrossProfit	= res.data.data.cotizacion_servicio.GrossProfit;
                this.cotizacion_servicio.Utilidad	= res.data.data.cotizacion_servicio.Utilidad;
                this.cotizacion_servicio.GastoOperaciones	= res.data.data.cotizacion_servicio.GastoOperaciones;
                this.cotizacion_servicio.FactorHoraExtra	= res.data.data.cotizacion_servicio.FactorHoraExtra;
                this.cotizacion_servicio.TotalMateriales	= res.data.data.cotizacion_servicio.TotalMateriales;
                this.cotizacion_servicio.TotalEquipos	= res.data.data.cotizacion_servicio.TotalEquipos;
                this.cotizacion_servicio.TotalContratistas	= res.data.data.cotizacion_servicio.TotalContratistas;
                this.cotizacion_servicio.TotalViaticos	= res.data.data.cotizacion_servicio.TotalViaticos;
                this.cotizacion_servicio.TotalManoDeObra	= res.data.data.cotizacion_servicio.TotalManoDeObra;
                this.cotizacion_servicio.Miscelaneos	= res.data.data.cotizacion_servicio.Miscelaneos;
                this.cotizacion_servicio.CostoKm	= res.data.data.cotizacion_servicio.CostoKm;
                this.cotizacion_servicio.FechaCotiServicio	= res.data.data.cotizacion_servicio.FechaCotiServicio;
                this.cotizacion_servicio.TotalGlobal	= res.data.data.cotizacion_servicio.TotalGlobal;
                this.cotizacion_servicio.Distancia	= res.data.data.cotizacion_servicio.Distancia;
                this.cotizacion_servicio.Velocidad	= res.data.data.cotizacion_servicio.Velocidad;
                this.cotizacion_servicio.RegEstatus	= res.data.data.cotizacion_servicio.RegEstatus;
                this.cotizacion_servicio.Folio	= res.data.data.cotizacion_servicio.Folio;
                this.cotizacion_servicio.TotalCostoKm	= res.data.data.cotizacion_servicio.TotalCostoKm;
                this.cotizacion_servicio.Garantia	= res.data.data.cotizacion_servicio.Garantia;
                this.cotizacion_servicio.Comentario	= res.data.data.cotizacion_servicio.Comentario;
                this.cotizacion_servicio.CostoBurden	= res.data.data.cotizacion_servicio.CostoBurden;
                this.cotizacion_servicio.CostoDesplazamiento	= res.data.data.cotizacion_servicio.CostoDesplazamiento;
                this.cotizacion_servicio.CostoManoObraD	= res.data.data.cotizacion_servicio.CostoManoObraD;
                this.cotizacion_servicio.ValorVenta	= res.data.data.cotizacion_servicio.ValorVenta;
                this.cotizacion_servicio.FechaMod	= res.data.data.cotizacion_servicio.FechaMod;
                this.cotizacion_servicio.Observaciones	= res.data.data.cotizacion_servicio.Observaciones;
                this.cotizacion_servicio.IdServicio	= res.data.data.cotizacion_servicio.IdServicio;
                this.cotizacion_servicio.Estatus	= res.data.data.cotizacion_servicio.Estatus;

				/// MAPPING DATA - Anexa atributo de bloqueo para deshabilitar la modificaion del nombre del material.
				let tmpMaterial = [];
				tmpMaterial = res.data.data.cotizacion_servicio.ListaMaterialCot.map( item => ({
					IdCotizacionServicio : item.IdCotizacionServicio,
					IdMaterial: 	item.IdMaterial,
					NombreMat: 		item.NombreMat,
					CostoUnitario: 	item.CostoUnitario,
					Cantidad: 		item.Cantidad,
                    Codigo: item.Codigo,
					TotalIndividual: item.TotalIndividual,
					blocked: false
				}));

                let tmpEquipoCot=[];
                tmpEquipoCot=res.data.data.cotizacion_servicio.ListaEquipoCot.map(item=>({
                    IdCotizacionServicio:item.IdCotizacionServicio,
                    IdEquipoCot:item.IdEquipoCot,
                    Nombre:item.Nombre,
                    costoUnitario:item.CostoUnitario,
                    Codigo: item.Codigo,
                    cantidad:item.Cantidad,
                    totalIndividual:item.TotalIndividual,
                    blocked:false
                }));

				this.cotizacion_servicio.ListaMaterialCot = [];
				this.cotizacion_servicio.ListaMaterialCot = tmpMaterial;

                this.cotizacion_servicio.ListaEquipoCot=[];
                this.cotizacion_servicio.ListaEquipoCot=tmpEquipoCot;

                let tmpContratista=[];
                tmpContratista=res.data.data.cotizacion_servicio.ListaContratista.map(item=>({
                    IdCotizacionServicio:item.IdCotizacionServicio,
                    IdContratista:item.IdContratista,
                    Nombre:item.Nombre,
                    CostoUnitario:item.CostoUnitario,
                    Cantidad:item.Cantidad,
                    TotalIndividual:item.Valor,
                    Burden:item.Burden,
                    blocked:false
                }));
                this.cotizacion_servicio.ListaContratista=tmpContratista;
                
                let tmpViatico=[];
                tmpViatico=res.data.data.cotizacion_servicio.ListaViatico.map(item=>({
                    IdCotizacionServicio:item.IdCotizacionServicio,
                    Nombre:item.Descripcion,
                    CostoUnitario:item.CostoUnitario,
                    Cantidad:item.Cantidad,
                    TotalIndividual:item.Valor,
                    blocked:false
                }))
                this.cotizacion_servicio.ListaViatico=tmpViatico;

				this.Ocliente				= res.data.data.Ocliente;
				this.ShowComponents			= true;

			}).catch((e) => {
				// this.isOverlay = false;
			}).finally(()=>{
				this.isOverlay = false;
                setTimeout(() => {
                    this.ConfigLoad.ShowLoader=false;
                    // this.ConfigLoad.ClassLoad=true;
                }, 1300);
			});
		},
        async Guardar()
        {
            this.bus.$emit('BloquearBtn',0);
            await this.$http.post(
                'cotizacion_servicio/post',
                this.cotizacion_servicio
                ,
            ).then( (res) => {
                this.bus.$emit('BloquearBtn',1);
                this.$router.push({name:'cotizacion_list'});

            }).catch( err => {

                    if(err.response.data.type==2){
                        this.$toast.error(err.response.data.message);
                    }else{
                        this.errorvalidacion=err.response.data.message.errores;
                    }

                this.bus.$emit('BloquearBtn',2);
                this.bus.$emit('Desbloqueo',false);
            });
        },
        ListaCliente(){
            this.bus.$emit('ListCcliente');
        },
        add_datos(oSucursal){
            this.Ocliente=oSucursal;
            this.cotizacion_servicio.IdCliente=this.Ocliente.IdClienteS;

            if(oSucursal.DistanciaAprox > 0){
                this.cotizacion_servicio.Distancia = oSucursal.DistanciaAprox;
            }
        },
        add_datos2(oSucursal){
            this.oHead.Url = 'menulevantamiento';
            this.BtnBuscar = true;
            this.Ocliente=oSucursal;
            this.cotizacion_servicio.IdCliente=this.Ocliente.IdClienteS;
            this.cotizacion_servicio.IdServicio=this.Ocliente.IdServicio;
        },
        // ========================================= DATO =========================================

        async ListarCostoKm()
        {
            await this.$http.get(
                this.urlApiCostoKm,
                {
                    params:{Nombre:'',Entrada:50,pag:0, RegEstatus:'A'}
                }
            ).then( (res) => {
                this.Listacostoskm =res.data.data.row;
            });
        },
        ValidarGross()
        {
            if (this.cotizacion_servicio.GrossProfit !='' && this.cotizacion_servicio.GrossProfit != null)
            {  
                if (parseFloat (this.cotizacion_servicio.GrossProfit)>100)
                {
                this.cotizacion_servicio.GrossProfit =100;  
                }
            }
            this.AgregarFactorExtra();
        },
        CalcularCostoKM(){
            var Distancia= this.cotizacion_servicio.Distancia;
            var valObj = this.Listacostoskm.filter(function(elem){
                if(parseFloat(elem.KMinicial)<=parseFloat(Distancia) && parseFloat(elem.KMfinal)>=parseFloat(Distancia)){
                        return elem;
                }
            });

            var Costo=0;
            if(valObj.length>0){
                Costo=valObj[0].CostoKM;
            }
            this.cotizacion_servicio.CostoKm=parseFloat(Costo);
            this.calcularTotalMO();
            this.CalcularTotalCostoDesplazamiento();
        },
        CalcularGastoOperaciones(){
            var Valor=0;
            if(this.cotizacion_servicio.GrossProfit<=100 && this.cotizacion_servicio.GrossProfit!=""){
                Valor= 100- parseFloat(this.cotizacion_servicio.GrossProfit);
                this.cotizacion_servicio.GastoOperaciones=parseFloat(Valor.toFixed(0));
            }
        },
        CalcularUtilidadAprox(){
            var Valor=0;
            if(this.cotizacion_servicio.GrossProfit>0 && this.cotizacion_servicio.GrossProfit!=""){
                Valor=  parseFloat(this.cotizacion_servicio.GrossProfit)-25;
                this.cotizacion_servicio.Utilidad=parseFloat(Valor.toFixed(0));
            }
            this.CalcularTotalValorVenta();
        },
        // ========================================= MANO DE OBRA =========================================
        async get_list_mo()
        {
            this.isOverlay=true;
            await this.$http.get(
                this.urlApiMo,
                {
                    params:{IdCotizacionServicio:this.cotizacion_servicio.IdCotizacionServicio,Entrada:50,pag:0, RegEstatus:'A'}
                }
            ).then( (res) => {
                this.cotizacion_servicio.ListaManoObra=res.data.data.row;
                this.Burden=res.data.data.Burden;
            }).finally(()=>{
                if (this.cotizacion_servicio.IdCotizacionServicio>0) {
                    this.Get_Recovery();
                }
            });
        },
        cal_costoMO(index){
            var costMO=this.cotizacion_servicio.ListaManoObra[index].CostoMO;
            var burden=this.cotizacion_servicio.ListaManoObra[index].Burden;
            var normal=this.cotizacion_servicio.ListaManoObra[index].HoraNormal;
            var factor=this.cotizacion_servicio.FactorHoraExtra;
            var extra=this.cotizacion_servicio.ListaManoObra[index].HoraExtra;

            if(costMO==''){costMO=0;}
            if(burden==''){burden=0;}
            if(normal==''){normal=0;}
            if(factor==''){factor=0;}
            if(extra==''){extra=0;}
            if (index<2)
            {
                //var resultado = eval (  (parseFloat(costMO) + parseFloat(burden) ) * (parseFloat(normal)) +(  parseFloat(costMO) +(parseFloat(costMO)*(factor/100) )) *  extra + parseFloat(burden) * extra ); 
                //PRIMERO // var resultado = eval (  (parseFloat(costMO) ) * (parseFloat(normal)) +(  parseFloat(costMO) +(parseFloat(costMO)*(factor/100) )) *  extra * extra ); 
                //PRIMERO // this.cotizacion_servicio.ListaManoObra[index].totalIndividual=resultado.toFixed(0);

                //Poner decimales
                var resultado = eval (  parseFloat(costMO ) * parseFloat(normal) + (parseFloat( costMO) +( parseFloat(costMO)*(parseFloat(factor)/100) )) *  parseFloat(extra) * parseFloat(extra) ); 
           
                this.cotizacion_servicio.ListaManoObra[index].TotalIndividual= resultado.toFixed(2);
            }
            else{
                //var resultado = eval (  (parseFloat(costMO) + (parseFloat(factor) /100) * (parseFloat(normal)) +(  parseFloat(extra) ) * (parseFloat(factor)/100 )));
                //PRIMERO // resultado=eval(Number((parseFloat(costMO) + parseFloat(factor/100))*(parseFloat(normal)+parseFloat((extra*(factor/100))))));
                //PRIMERO // this.cotizacion_servicio.ListaManoObra[index].totalIndividual=resultado.toFixed(0);

                //Poner decimales
                //resultado=eval(Number((costMO + factor/100)*(normal+(extra*(factor/100)))));
                resultado=(parseFloat(costMO) + parseFloat(factor)/100)*(parseFloat(normal)+(parseFloat(extra)*(parseFloat(factor)/100)));
                this.cotizacion_servicio.ListaManoObra[index].TotalIndividual=resultado.toFixed(2);
                
            }
            this.calcularTotalMO();
        },

        AgregarFactorExtra(){
            let Contador = 0;

            this.cotizacion_servicio.ListaManoObra.forEach(element => {
                this.cal_costoMO(Contador);
                Contador++;
            });
        },
        calcularTotalMO(){
            var Total=0;
            this.cotizacion_servicio.ListaManoObra.forEach(element => {
                if(element.TotalIndividual!=''){
                    Total+=parseFloat(element.TotalIndividual);
                }
            });
            // console.log(this.cotizacion_servicio.ListaManoObra);

            this.cotizacion_servicio.TotalManoDeObra= Total.toFixed(0);
            this.CalcularTotalMODesplazamiento();
            this.CalcularTotalCostoBurden();
        },

        // ========================================= MATERIAL =========================================

        add_Materiales(obj){
            let IdMaterial	= 0;
            let NomMaterial	= '';
            let Precio		= 0;
			let blocker = false;
            let Codigo = '';

            if(obj !== undefined) {
               IdMaterial 	= parseInt(obj.IdMaterial);
               NomMaterial 	= obj.NomMaterial.trim();
               Precio 		= parseFloat(obj.Precio);
               Codigo 	= obj.Codigo!=null ? obj.Codigo.trim() : '';

			   // EVALUA SI EL MATERIAL NO YA HA SIDO AGREGADO, EVITA QUE SE REPITA DICHO ELEMENTO EN LA LISTA
			   let valObj = this.cotizacion_servicio.ListaMaterialCot.filter(function(elem) {
                    if(elem.IdMaterial === obj.IdMaterial) {
                        return elem.IdMaterial;
                    }
                });

			   if(valObj.length > 0){
				   return false;
			   }

				blocker = true;

            }

			let lista = {
				IdMaterial: IdMaterial,
				NombreMat: NomMaterial,
				CostoUnitario: Precio,
				Cantidad: 1,
				TotalIndividual: Precio,
				blocked: blocker,
                Codigo: Codigo
			};

			this.cotizacion_servicio.ListaMaterialCot.push(lista);


        },

        calcula_importe_material(item) {
            let Precio	 	= parseFloat(item.CostoUnitario);
            let Cantidad 	= parseFloat(item.Cantidad);
            let Resultado  	= (Precio * Cantidad);

            // MARKUP - SE UBICA CON IMPORTE DEL MATERIAL EL RANGO EN EL QUE CALIFICA PARA TENER EL VALOR DE MARKUP
            let valObj = this.Listamarkup.filter(function(elem) {
                if(parseFloat(elem.Monto_I) <= Resultado && parseFloat(elem.Monto_F) >= Resultado) {
					return elem;
                }
            });

            let CostoMarkup = 0;
            if(valObj.length > 0){
				CostoMarkup = parseFloat(valObj[0].CostoM);
            }


			if(CostoMarkup === 0){CostoMarkup=0}
            if(Precio === 0){Precio=0}
            if(Cantidad === 0){Cantidad=0}
            if(Resultado === 0){Resultado=0}

            let subTotal = isNaN( (Resultado * CostoMarkup) ) ? 0 : (Resultado * CostoMarkup);

			item.TotalIndividual = subTotal.toFixed(2);


           this.calcula_total_material();
        },

		calcula_total_material(){
			let Total = 0;
			this.cotizacion_servicio.ListaMaterialCot.forEach(element => {
				if(parseFloat(element.TotalIndividual) !== 0){
					Total+=parseFloat(element.TotalIndividual);
				}
			});

			let subTotal = isNaN(Total) ? 0 : Total;
			this.cotizacion_servicio.TotalMateriales = subTotal.toFixed(2);
            this.CalcularTotalGarantia();
		},

        delete_mat(index) {
            this.cotizacion_servicio.ListaMaterialCot.splice(index, 1);
			this.calcula_total_material();
        },

        // ========================================= EQUIPO =========================================

        add_Equipos(obj){
            let IdEquipoCot	= 0;
            let Nombre	= '';
            let Precio		= 0;
			let blocker = false;
            let Codigo = '';

            if(obj !== undefined) {
               IdEquipoCot 	= parseInt(obj.IdEquipoCot);
               Nombre 	= obj.Nombre.trim();
               Precio 		= parseFloat(obj.Precio);
               Codigo 	= obj.Codigo.trim();

			   // EVALUA SI EL MATERIAL NO YA HA SIDO AGREGADO, EVITA QUE SE REPITA DICHO ELEMENTO EN LA LISTA
			   let valObj = this.cotizacion_servicio.ListaEquipoCot.filter(function(elem) {
                    if(elem.IdEquipoCot === obj.IdEquipoCot) {
                        return elem.IdEquipoCot;
                    }
                });

			   if(valObj.length > 0){
				   return false;
			   }

				blocker = true;

            }

			let lista = {
				IdEquipoCot: IdEquipoCot,
				Nombre: Nombre,
				costoUnitario: Precio,
				cantidad: 1,
				totalIndividual: Precio,
				blocked: blocker,
                Codigo: Codigo
			};

			this.cotizacion_servicio.ListaEquipoCot.push(lista);
        },
        calcula_importe_equipo(item) {
            let Precio	 	= parseFloat(item.costoUnitario);
            let Cantidad 	= parseFloat(item.cantidad);
            let Resultado  	= (Precio * Cantidad);

            // MARKUP - SE UBICA CON IMPORTE DEL MATERIAL EL RANGO EN EL QUE CALIFICA PARA TENER EL VALOR DE MARKUP
            let valObj = this.Listamarkup.filter(function(elem) {
                if(parseFloat(elem.Monto_I) <= Resultado && parseFloat(elem.Monto_F) >= Resultado) {
					return elem;
                }
            });

            let CostoMarkup = 0;
            if(valObj.length > 0){
				CostoMarkup = parseFloat(valObj[0].CostoM);
            }


			if(CostoMarkup === 0){CostoMarkup=0}
            if(Precio === 0){Precio=0}
            if(Cantidad === 0){Cantidad=0}
            if(Resultado === 0){Resultado=0}

            let subTotal = isNaN( (Resultado * CostoMarkup) ) ? 0 : (Resultado * CostoMarkup);

			item.totalIndividual = subTotal.toFixed(2);


           this.calcula_total_equipo();
        },
        calcula_total_equipo(){
			let Total = 0;
			this.cotizacion_servicio.ListaEquipoCot.forEach(element => {
				if(parseFloat(element.totalIndividual) !== 0){
					Total+=parseFloat(element.totalIndividual);
				}
			});

			let subTotal = isNaN(Total) ? 0 : Total;
			this.cotizacion_servicio.TotalEquipos = subTotal.toFixed(2);
            this.CalcularTotalGarantia();
		},
        delete_equipo(index) {
            this.cotizacion_servicio.ListaEquipoCot.splice(index, 1);
			this.calcula_total_equipo();
        },

        // ========================================= CONTRATISTA =========================================

        add_Contratistas(obj){
            let IdContratista=0;
            let Nombre	= '';
            let Precio		= 0;
            let Burden=0;
			let blocker = false;

            if (obj!==undefined) {
                IdContratista=parseInt(obj.IdContratista);
                Nombre=obj.Nombre;
                Precio=parseFloat(obj.Precio);
                
                let valObj = this.cotizacion_servicio.ListaContratista.filter(function(elem) {
                    if(elem.IdContratista === obj.IdContratista) {
                        return elem.IdContratista;
                    }
                });

			   if(valObj.length > 0){
				   return false;
			   }

				blocker = true;
            }

            let lista = {
				IdContratista: IdContratista,
				Nombre: Nombre,
				CostoUnitario: Precio,
				Cantidad: 1,
				TotalIndividual: Precio,
                Burden:Burden,
				blocked: blocker,
			};

			this.cotizacion_servicio.ListaContratista.push(lista);
        },
        calcula_importe_contratista(item) {
            let Precio	 	= parseFloat(item.CostoUnitario);
            let Cantidad 	= parseFloat(item.Cantidad);
            let Resultado  	= (Precio * Cantidad);

            item.TotalIndividual = Resultado.toFixed(2);
            if (this.InicioCalculo==0) {
                setTimeout(() => {
                    item.Burden=(((item.TotalIndividual/this.cotizacion_servicio.ListaManoObra[0].CostoMO)/4)*this.cotizacion_servicio.ListaManoObra[0].Burden).toFixed(2);
                    this.InicioCalculo++;
                }, 750);
            }
            else{
                item.Burden=(((item.TotalIndividual/this.cotizacion_servicio.ListaManoObra[0].CostoMO)/4)*this.cotizacion_servicio.ListaManoObra[0].Burden).toFixed(2);
            }

           this.calcula_total_contratista();
        },
        calcula_total_contratista(){
            let Total = 0;
			this.cotizacion_servicio.ListaContratista.forEach(element => {
				if(parseFloat(element.TotalIndividual) !== 0){
					Total+=parseFloat(element.TotalIndividual);
				}
			});

			let subTotal = isNaN(Total) ? 0 : Total;
			this.cotizacion_servicio.TotalContratistas = subTotal.toFixed(2);
            this.CalcularTotalCostoBurden();
            // this.CalcularTotalGarantia();
        },
        delete_contratista(index) {
            this.cotizacion_servicio.ListaContratista.splice(index, 1);
			this.calcula_total_contratista();
        },

        // ========================================= VIATICO =========================================

        add_Viaticos(obj){
            let IdViatico=0;
            let Nombre	= '';
            let Precio		= 0;
			let blocker = false;

            if (obj!==undefined) {
                IdViatico=parseInt(obj.IdViatico);
                Nombre=obj.Nombre;
                Precio=parseFloat(obj.Precio);
                
                let valObj = this.cotizacion_servicio.ListaViatico.filter(function(elem) {
                    if(elem.IdViatico === obj.IdViatico) {
                        return elem.IdViatico;
                    }
                });

			   if(valObj.length > 0){
				   return false;
			   }

				blocker = true;
            }

            let lista = {
				IdViatico: IdViatico,
				Nombre: Nombre,
				CostoUnitario: Precio,
				Cantidad: 1,
				TotalIndividual: Precio,
				blocked: blocker,
			};

			this.cotizacion_servicio.ListaViatico.push(lista);
        },
        calcula_importe_viatico(item) {
            let Precio	 	= parseFloat(item.CostoUnitario);
            let Cantidad 	= parseFloat(item.Cantidad);
            let Resultado  	= (Precio * Cantidad);

			item.TotalIndividual = Resultado.toFixed(2);


           this.calcula_total_viatico();
        },
        calcula_total_viatico(){
            let Total = 0;
			this.cotizacion_servicio.ListaViatico.forEach(element => {
				if(parseFloat(element.TotalIndividual) !== 0){
					Total+=parseFloat(element.TotalIndividual);
				}
			});

			let subTotal = isNaN(Total) ? 0 : Total;
			this.cotizacion_servicio.TotalViaticos = subTotal.toFixed(2);
            this.CalcularTotalGarantia();
        },
        delete_viatico(index) {
            this.cotizacion_servicio.ListaViatico.splice(index, 1);
			this.calcula_total_viatico();
        },

        // ========================================= RESULTADO =========================================
        CalcularTotalCostoDesplazamiento(){
            // if (this.ContLoader==0) {
            //     this.pConfigLoad.ShowLoader=true;
            // } 
            var Distancia=this.cotizacion_servicio.Distancia;
            var CostoKM=this.cotizacion_servicio.CostoKm; 
            var Valor=0;
            if(Distancia==''){Distancia=0;}
            if(CostoKM==''){CostoKM=0;}
          
            Valor = (parseFloat(Distancia)*2)*parseFloat(CostoKM);
            this.cotizacion_servicio.TotalCostoKm=parseFloat(Valor.toFixed(0));
            this.CalcularTotalGarantia();

        },
        CalcularTotalMODesplazamiento(){
            let Total=0;
            this.cotizacion_servicio.ListaManoObra.forEach(element => {
                if(element.TotalIndividual!=''){
                    Total+=parseFloat(element.TotalIndividual);
                }
            });

            this.cotizacion_servicio.TotalManoDeObra= Total.toFixed(2); 
            let Distancia=parseFloat(this.cotizacion_servicio.Distancia);
            let Velocidad=parseFloat(this.cotizacion_servicio.Velocidad);
            let total=0;
            this.cotizacion_servicio.ListaManoObra.forEach(element => {
                if(element.HoraNormal!='' && element.HoraNormal>0){
                    let TotalInd=((Distancia*2)/Velocidad)*parseFloat(element.CostoMO);
                    if (isNaN(TotalInd) || TotalInd==Infinity) {
                        TotalInd=0;
                    }
                    total+= TotalInd;
                }
            });

            this.cotizacion_servicio.CostoManoObraD=parseFloat(total.toFixed(0));
            this.cotizacion_servicio.TotalManoDeObra=parseFloat(this.cotizacion_servicio.TotalManoDeObra)+this.cotizacion_servicio.CostoManoObraD;
            this.CalcularTotalGarantia();
        },
        CalcularTotalCostoBurden(){
            var total=0;
            var cont=0;
            this.cotizacion_servicio.ListaManoObra.forEach(element => {
                if (cont<2)
                {
                    var HoraNorma=0;
                    var HoraExtra=0;
                    var Burden=0;
                    if(element.HoraNormal!='' && element.HoraNormal>0){
                        HoraNorma=element.HoraNormal;
                    }
                    if(element.HoraExtra!='' && element.HoraExtra>0){
                        HoraExtra=element.HoraExtra;
                    }

                    if(element.Burden!='' && element.Burden>0){
                        Burden=element.Burden;
                    }
                    total+=parseFloat(HoraNorma + HoraExtra) * parseFloat(Burden);
                    cont ++; 
                }
            });
            this.cotizacion_servicio.ListaContratista.forEach((element)=>{
                var Burden=0;
                if (element.Burden!='' && element.Burden>0) {
                    Burden=element.Burden;
                }
                total+=parseFloat(Burden);
            })
           this.cotizacion_servicio.CostoBurden=parseFloat(total.toFixed(0));
           this.CalcularTotalGarantia();
        },
        CalcularTotalGarantia(){
            //Garantía = (Materiales + mano de obra + costo desplazamiento + costo mano de obra desplazamiento
            // + micelaneos + costo burden )*  (garantía/100) 
            var Materiales=this.cotizacion_servicio.TotalMateriales;
            var Equipos=this.cotizacion_servicio.TotalEquipos;
            var Contratistas=this.cotizacion_servicio.TotalContratistas;
            var Viaticos=this.cotizacion_servicio.TotalViaticos;
            var TotalManoDeObra=this.cotizacion_servicio.TotalManoDeObra;
            var CostoDesplazamiento=this.cotizacion_servicio.TotalCostoKm;
            // var CostoManoObraD=this.cotizacion_servicio.CostoManoObraD;
            // var TotalMiscelaneo=this.cotizacion_servicio.totalMiscelaneos;
            var CostoBurden=this.cotizacion_servicio.CostoBurden;
            var Garantia=this.cotizacion_servicio.Garantia;

            if(Materiales==''){Materiales=0;}
            if(Equipos==''){Equipos=0;}
            if(Contratistas==''){Contratistas=0;}
            if(Viaticos==''){Viaticos=0;}
            if(TotalManoDeObra==''){TotalManoDeObra=0;}
            if(CostoDesplazamiento==''){CostoDesplazamiento=0;}
            // if(CostoManoObraD==''){CostoManoObraD=0;}
            // if(TotalMiscelaneo==''){TotalMiscelaneo=0;}
            if(CostoBurden==''){CostoBurden=0;}
            if(Garantia==''){Garantia=0;}
            
            let Total = (parseFloat(Materiales) + parseFloat(Equipos) + parseFloat(Contratistas) + parseFloat(Viaticos) + parseFloat(TotalManoDeObra) + parseFloat(CostoDesplazamiento) + parseFloat(CostoBurden) )*  (parseFloat(Garantia)/100);
            // parseFloat(TotalMiscelaneo) + parseFloat(CostoManoObraD) +
            this.TotalGarantia=Total.toFixed(0);
            //return Total.toFixed(0);
            this.CalcularCostoTotal();
        },
        CalcularCostoTotal(){
            //Costo total = (Materiales + mano de obra + costo desplazamiento + costo mano de obra desplazamiento + micelaneos + costo burden + Garantía)
            var Materiales=this.cotizacion_servicio.TotalMateriales;
            var Equipos=this.cotizacion_servicio.TotalEquipos;
            var Contratistas=this.cotizacion_servicio.TotalContratistas;
            var Viaticos=this.cotizacion_servicio.TotalViaticos;
            var TotalManoDeObra=this.cotizacion_servicio.TotalManoDeObra;
            var CostoDesplazamiento=this.cotizacion_servicio.TotalCostoKm;
            // var CostoManoObraD=this.cotizacion_servicio.CostoManoObraD;
            // var TotalMiscelaneo=this.cotizacion_servicio.totalMiscelaneos;
            var CostoBurden=this.cotizacion_servicio.CostoBurden;
            var Garantia=this.cotizacion_servicio.Garantia;

            if(Materiales==''){Materiales=0;}
            if(Equipos==''){Equipos=0;}
            if(Contratistas==''){Contratistas=0;}
            if(Viaticos==''){Viaticos=0;}
            if(TotalManoDeObra==''){TotalManoDeObra=0;}
            if(CostoDesplazamiento==''){CostoDesplazamiento=0;}
            // if(CostoManoObraD==''){CostoManoObraD=0;}
            // if(TotalMiscelaneo==''){TotalMiscelaneo=0;}
            if(CostoBurden==''){CostoBurden=0;}
            if(Garantia==''){Garantia=0;}
            
            let Total=(parseFloat(Materiales)+parseFloat(Equipos)+parseFloat(Contratistas)+parseFloat(Viaticos)+parseFloat(TotalManoDeObra)+parseFloat(CostoDesplazamiento)+parseFloat(CostoBurden)+parseFloat(this.TotalGarantia));
            // +parseFloat(TotalMiscelaneo)+parseFloat(CostoManoObraD)+

            this.cotizacion_servicio.TotalGlobal=parseFloat(Total.toFixed(0));
            this.CalcularTotalValorVenta();
           // return Total.toFixed(0);
        },
        CalcularTotalValorVenta(){
            //COSTOTOTA*100 /GASTOS OPERACIONES;
            let CotosTotal=this.cotizacion_servicio.TotalGlobal;
            let gastoOperaciones=this.cotizacion_servicio.GastoOperaciones;
            if(CotosTotal==''){CotosTotal=0;}
            if(gastoOperaciones==''){gastoOperaciones=0;}
            let Total=parseFloat(CotosTotal)*100/parseFloat(gastoOperaciones);

            if(isNaN(Total) || Total==Infinity){
                Total=0;
            }

            this.cotizacion_servicio.ValorVenta=parseFloat(Total.toFixed(0));
            // if (this.ContLoader==0) {
            //     this.pConfigLoad.ShowLoader=false;
            //     this.ContLoader++;
            // }
            //return Total.toFixed(0);
        }

    },
    created() {
        this.get_ListaMarkup();
        this.ListarCostoKm();
        this.bus.$off('Save');
        this.bus.$off('Nuevo');
        this.bus.$off('SeleccionarCliente');

        this.bus.$on('Save',()=> {
           this.Guardar();
        });

        let id=0;
        if(this.Id!=undefined){
            sessionStorage.setItem('IdSaved',JSON.stringify(this.Id));
        }
        id=JSON.parse( sessionStorage.getItem('IdSaved'));

        this.cotizacion_servicio.IdCotizacionServicio=id;
        this.get_list_mo();
        if(id>0){
            this.cotizacion_servicio.IdCotizacionServicio=id;
            this.ConfigLoad.ShowLoader=true;
            // this.Get_Recovery();
            this.isOverlay = true;

        }else{
            this.ShowComponents=true;
            this.isOverlay = false;
        }

        this.bus.$on('SeleccionarCliente',(oSucursal)=>
        {
           this.add_datos(oSucursal);
           this.CalcularCostoKM();
        });
        
        this.bus.$off('Add_Materiales');
        this.bus.$on('Add_Materiales',(obj)=> {
            this.add_Materiales(obj);
        });

        this.bus.$off('Add_Equipos');
        this.bus.$on('Add_Equipos',(obj)=> {
            this.add_Equipos(obj);
        });



        if(this.objsucursal!=undefined){
            this.add_datos2(this.objsucursal);
        }
    },
}
</script>

<style>

</style>