<template>
    <div>
        <CLoader :pConfigLoad="ConfigLoad">
            <template slot="BodyFormLoad">
                <div class="form-group row">
                    <div class="col-lg-12">
                        <label for="">Cantidad</label>
                        <input type="number" class="form-control" v-model="PaqueteCheckIn.Cantidad">
                        <label id="lblmsuser" style="color:red"><Cvalidation v-if="errorvalidacion.Cantidad" :Mensaje="errorvalidacion.Cantidad[0]"></Cvalidation></label>
                    </div>
                </div>
            </template>
        </CLoader>
    </div>
</template>

<script>
import Cbtnsave from '@/components/Cbtnsave.vue'
import Cvalidation from '@/components/Cvalidation.vue'
export default {
    name:'Form',
    props:['poBtnSave'],
    data() {
        return {
            Modal:true,
            Name:'CheckInForm',
            ConfigLoad:{
                ShowLoader:true,
                ClassLoad:true
            },
            PaqueteCheckIn:{
                IdPaqueteCheckIn:0,
                Cantidad:0
            },
            errorvalidacion:[]
        }
    },
    methods: {
        async Guardar(){
            this.poBtnSave.disableBtn=true;
            let formData=new FormData();
            formData.set('IdPaqueteCheckIn',this.PaqueteCheckIn.IdPaqueteCheckIn);
            formData.set('Cantidad',this.PaqueteCheckIn.Cantidad);
            await this.$http.post('paquetecheckin/post',formData
            ).then((res)=>{
                this.poBtnSave.disableBtn=false;  
                this.poBtnSave.toast=1;
                $('#ModalForm').modal('hide');
                this.bus.$emit('List');
            }).catch((err)=>{
                this.errorvalidacion=err.response.data.message.errores;
                this.poBtnSave.disableBtn=false;
                this.poBtnSave.toast=2;
            })
        },
        Limpiar(){
            this.PaqueteCheckIn={
                IdPaqueteCheckIn:0,
                Cantidad:0
            },
            this.errorvalidacion=[];
        },
        get_one(){
            this.$http.get('paquetecheckin/recovery',{
                params:{
                    IdPaqueteCheckIn:this.PaqueteCheckIn.IdPaqueteCheckIn
                }
            }).then((res)=>{
                this.PaqueteCheckIn.IdPaqueteCheckIn=res.data.data.PaqueteCheckIn.IdPaqueteCheckIn;
                this.PaqueteCheckIn.Cantidad=res.data.data.PaqueteCheckIn.Cantidad;
            }).finally(()=>{
                this.ConfigLoad.ShowLoader=false;
            })
        }
    },
    created() {
        this.bus.$off('Nuevo');
        this.bus.$on('Nuevo',(data,Id)=> 
        {
            this.ConfigLoad.ShowLoader=true;
            this.poBtnSave.toast=0;
            this.poBtnSave.disableBtn=false; 
            this.bus.$off('Save');

            this.bus.$on('Save',()=>
            {
                this.Guardar();
            });

            this.Limpiar();
            if (Id>0) 
            {
                this.PaqueteCheckIn.IdPaqueteCheckIn=Id;
                this.get_one();
            }
            else{
                this.ConfigLoad.ShowLoader=false;
            }
        });
        if (this.Id!=undefined)
        {
            this.PaqueteCheckIn.IdPaqueteCheckIn=this.Id;
            this.get_one();
        }
    }
}
</script>

<style>

</style>