<template>
    <div>

        <CHead :oHead="oHead">
            <template slot="component">
                <!-- <button data-toggle="modal" data-target="#ModalForm" data-backdrop="static" data-keyboard="false" type="button" class="btn btn-03 mb-2" v-if="ShowBtnSolicitud">Solicitar servicio</button> -->
                <!-- <button data-toggle="modal" data-target="#ModalForm" data-backdrop="static" data-keyboard="false" type="button" class="btn btn-pink mb-2"><i class="fa fa-plus-circle"></i> Agregar Equipo</button> -->
            </template>
        </CHead>

        <div class="row mt-2" >
            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12 col-lg-12">
                                <form class="form-inline">
                                    <div class="form-group mr-2">
                                        <input v-model="Filtro.Nombre"  type="text" class="form-control lup"  placeholder="Nombre/Marca/Modelo ...">
                                    </div>
                                    <!--<div class="form-group mr-2">
                                        <label>Filas &nbsp;</label>
                                        <select @change="Lista" v-model="Filtro.Entrada" class="form-control">
                                            <option :value="50">50</option>
                                            <option :value="100">100</option>
                                            <option :value="150">150</option>
                                        </select>
                                    </div>-->
                                    <div class="form-group  mr-2">
                                        <button  :disabled="loading" @click="Lista()" type="button" class="btn btn-primary mr-1" ><i v-show="loading" class="fa fa-spinner fa-pulse fa-1x fa-fw"></i>Filtrar</button>
                                    </div>
                                </form>
                            </div>
                            <div class="col-md-12 col-lg-12">
                                <hr>
                            </div>
                        </div>

                        <CLoader :pConfigLoad="ConfigLoad">
                            <template slot="BodyFormLoad">
                                <div class="row" v-if="!loading">

                                    <div v-for="(lista,index) in ListaEquipos" :key="index"  class="col col-md-3 col-lg-2" style="flex-basis:12.5%;max-width:60rem">
                                        <div :id="'button'+lista.IdEquipo" :class="lista.Status =='Fuera de Servicio' ?'circular_shadow borde-rojo2' :lista.Status=='En Servicio' ?'circular_shadow borde-morado' :lista.Status=='En Observacion' ?'circular_shadow borde-amarillo':lista.Status=='Operando'?'circular_shadow borde-verde ': 'circular_shadow borde-gris'"
                                             @click="go_to_History(lista)"
                                             aria-haspopup="true"
                                             aria-expanded="false">
                                            <svg-injector v-show="loading == false && lista.ImgSvg != ''"  :class-name="lista.Status =='Fuera de Servicio' ?'svg-inject iconic-signal-weak' :lista.Status=='En Servicio' ?'svg-inject iconic-signal-current' :lista.Status=='En Observacion' ?'svg-inject iconic-signal-medium':lista.Status=='Operando'?'svg-inject iconic-signal-strong ':'svg-inject iconic-signal-none'" :src="lista.ImgSvg"></svg-injector>
                                            <b-tooltip custom-class="tooltip-des"  :target="'button'+lista.IdEquipo" placement="right" style="position:fixed;">
                                            <span class="tiptext">
                                                <b>Nombre:</b>{{lista.Nequipo}}<br>
                                                <b>Marca:</b> {{lista.Marca}}<br>
                                                <b>Modelo:</b> {{lista.Modelo}}<br>
                                                <b>Unidad:</b> {{lista.Unidad}}<br>
                                                <b>Ubicación:</b>  {{lista.Ubicacion}}<br>
                                            </span>
                                            </b-tooltip>
                                        </div>
                                        <h3 class="text-center equipo">{{lista.Nequipo}}</h3>
                                        <div class="dropdown-menu dropdown-menu-user" aria-labelledby="dropdownMenuButton" style="position:absolute;z-index:1151">
                                            <div class="dropdown-menu-header">
                                                <h4 style="opacity:1" class="widget-nombre mt-1 text-center">Seleccione una opción</h4>
                                            </div>
    
                                            <a
                                                    @click="go_to_History(lista)"
                                                    data-toggle="modal"
                                                    data-backdrop="static"
                                                    class="dropdown-item"
                                            >
                                                <i class="fa fa-info-circle"></i> Ver Información del Equipo
                                            </a>
    
                                            <a
                                                    class="dropdown-item"
                                                    @click="DescargarQR(lista)"
                                            >
                                                <i :id="'etiquetaEquipo_' + lista.IdEquipo" class="fa fa-qrcode"></i> Descargar QR
                                            </a>
    
                                            <!-- <a
                                            @click="CopiarEquipo(lista)"
                                            class="dropdown-item"
                                            >
                                            <i class="fa fa-copy"></i> Copiar Equipo
                                            </a> -->
    
                                            <a
                                                    @click="Editar(lista.IdEquipo)"
                                                    data-toggle="modal"
                                                    data-backdrop="static"
                                                    class="dropdown-item"
                                                    data-target="#ModalForm"
                                            >
                                                <i class="fa fa-pencil fa-fw-m"></i> Editar
                                            </a>
    
                                            <a
                                                    @click="Eliminar(lista.IdEquipo)"
                                                    data-toggle="modal"
                                                    data-backdrop="static"
                                                    class="dropdown-item"
                                            >
                                                <i class="fa fa-trash fa-fw-m"></i> Eliminar
                                            </a>
    
                                        </div>
    
                                    </div>
                                    <CSinRegistros :pContIF="ListaEquipos.length" :pColspan="7" ></CSinRegistros>
                                </div>
                            </template>
                        </CLoader>
						

                        <div class="row">
                            <div class="col-md-12 col-lg-12">
                               <Pagina :Filtro="Filtro" @Pagina="Lista" :Entrada="Filtro.Entrada" ></Pagina>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <!-- <Modal :poBtnSave="oBtnSave"  :size="size" :Nombre="NameList" >
            <template slot="Form" >
                <Form :poBtnSave="oBtnSave" :osucursal="oClienteSucursal">
                </Form>
            </template>
        </Modal> -->
        <Modal :poBtnSave="oBtnSave"  :size="size" :Nombre="NameList" >
            <template slot="Form" >
                <Form :poBtnSave="oBtnSave" :oClienteSucursalP="oClienteSucursal">
                </Form>
            </template>
        </Modal>
    </div>
    </template>
    <script>
    import $$ from "jquery"
    import Modal from '@/components/Cmodal.vue';
    import Cbtnsave2 from '@/components/Cbtnsave2.vue';
    // import Form from '@/views/modulos/monitoreo/solicitudes/Form.vue'
    import Form from '@/views/catalogos/equipos/Form.vue';
    import SvgFiller from '@/vue-svg-filler'
    import Pagina from '@/components/Cpagina.vue'
    import CSinRegistros from "../../../components/CSinRegistros";
    export default {
        name :'list',
        props:['obj','objCliente','rutaIcoEmp','return'],
        components :{Modal,Form,SvgFiller,Pagina,CSinRegistros,Cbtnsave2},
        data() {
            return {
                Disablebtn:false,
                loading:false,
                FormName:'TipoUnidadForm',//Por si no es modal y queremos ir a una vista declarada en el router
                EsModal:true,//indica si es modal o no
                size :"modal-lg",
                NameList:"Agregar Equipo",
                urlApi:"equipos/get",
                ListaEquipos:[],
                ListaHeader:[],
                TotalPagina:2,
                Pag:0,
                oClienteSucursal:{},
                oCliente:{},
                Equipo:{},
                BndCopiar:true,
                RutaEquipo:'',
                oHead:{
                    isreturn:true,
                    Title:'Sucursales',
                    Url:'monitoreo_cli',
                    isEmit:true,
                    BtnNewShow:true,
                    BtnNewName:'Agregar Equipo',
                    isModal:true
                },
                ShowBtnSolicitud:false,
                Filtro:{
                    Nombre:'',
                    Placeholder:'Nombre/Marca/Modelo..',
                     TotalItem:0,
                    Pagina:1,
                    Entrada:56
                },
                ConfigLoad:{
                    ShowLoader:true,
                    ClassLoad:true
                },
                oBtnSave:{//valores  isModal(true),nombreModal('ModalForm'),tipoModal=1,regresarA(''),disableBtn(false),txtSave('Guardar'),txtCancel('Cerrar');
                    isModal:true,
                    disableBtn:false,
                    toast:0,
                },
                // BndCopiar:true
            }
        },
        methods: {
           async Lista()
            {
                this.ConfigLoad.ShowLoader=true;
                this.loading = true;
                this.ListaEquipos = [];

                await this.$http.get(
                    this.urlApi,
                    {
                        params:{ 
                            RegEstatus:'A',
                            IdClienteS:this.oClienteSucursal.IdClienteS,
                            Entrada:this.Filtro.Entrada,
                            pag:this.Filtro.Pagina,
                            Nombre:this.Filtro.Nombre
                        }
                    }
                ).then( (res) => {
                    this.ListaEquipos = res.data.data.equipos;
                    this.RutaEquipo = res.data.RutaEquipo;
                    this.Filtro.Entrada=res.data.data.pagination.PageSize;
                    this.Filtro.TotalItem=res.data.data.pagination.TotalItems;
                    this.loading = false;
                }).finally(()=>{
                    this.ConfigLoad.ShowLoader=false;
                });

            },
            Regresar(){
                this.$router.push({name:'mon_sucursal', params:{ocliente:this.oCliente}})
            },
            go_to_History(item){
                item.RutaEquipo=this.RutaEquipo;
                this.$router.push({name:'mon_histequipo', params:{pOrigen:"Monitoreo",ocliente:this.oCliente,oClienteSucursal:this.oClienteSucursal,oEquipo:item,rutaIcoEmp:this.rutaIcoEmp}})
            },
            Editar(Id){
                this.bus.$emit('Nuevo',false,Id);
            },
            Eliminar(Id)
            {
                this.$swal({
                    title: 'Esta seguro que desea eliminar este dato?',
                    text: 'No se podra revertir esta acción',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Si',
                    cancelButtonText: 'No, mantener',
                    showCloseButton: true,
                    showLoaderOnConfirm: true
                    }).then((result) => {
                    if(result.value) {
                         this.$http.delete(
                            'equipos/' + Id
                        ).then( (res) => {
                            this.$toast.success('Información eliminada');
                            this.Lista();
                        });
                    }
                });
            },
            DescargarQR(Equipo){
                this.$http.get('reporte/equipoetiquetascanning', {
                    responseType: 'blob',
                    params: {
                        IdEquipo:Equipo.IdEquipo,
                    }
                }).then( (response) => {

                    let pdfContent = response.data;
                    let file = new Blob([pdfContent], { type: 'application/pdf' });
                    let fileUrl = URL.createObjectURL(file);

                    window.open(fileUrl);

                });
            },
            CopiarEquipo(Equipo){
                if(this.BndCopiar){//si es false es por que sigue ejecutando la accion de copiar
                    this.$swal({
                        title: 'Desea copiar esta informacion',
                        text: 'La información se duplicará.',
                        type: 'info',
                        showCancelButton: true,
                        confirmButtonText: 'Si',
                        cancelButtonText: 'No, mantener',
                        showCloseButton: true,
                        showLoaderOnConfirm: true
                    }).then((result) => {
                    if(result.value) {
                            this.BndCopiar=false;
                            this.Equipo=Equipo;
                            this.Equipo.IdEquipo=0;
                            this.Equipo.Nequipo=Equipo.Nequipo+" - Copy";

                             this.$http.post(
                                'equipos/post',
                                this.Equipo,
                            ).then( (res) => {
                                this.$toast.success('Información copiada');
                                this.BndCopiar=true;
                                this.Lista();
                            }).catch( err => {
                                this.BndCopiar=true;
                            });
                        }
                    });
                }
            }
        },
        created()
        {
            // if(this.return!=undefined || sessionStorage.getItem("propiedad")!=undefined){
            //     this.oHead.isreturn=false;
            // }
            if (this.obj!=undefined)
            {
                sessionStorage.setItem('IdSaved',JSON.stringify(this.obj));
                sessionStorage.setItem('ssobjcliente',JSON.stringify(this.objCliente));
            }
            this.oClienteSucursal=JSON.parse(sessionStorage.getItem('IdSaved'));
            this.oCliente=JSON.parse( sessionStorage.getItem('ssobjcliente'));
            this.oHead.Title=this.oClienteSucursal.Nombre + ' | Equipos';

            var osucursalSession=JSON.parse( sessionStorage.getItem('clientelog'));
            if(osucursalSession!=null){//Datos desde login
                this.ShowBtnSolicitud=true;
            }

            this.bus.$off('Regresar');
            this.bus.$on('Regresar',()=>
            {
                this.Regresar();
            });
            this.bus.$off('List');
            this.bus.$on('List',()=>{
                this.Lista();
            })
            let usuario=JSON.parse(this.RolUsuario=sessionStorage.getItem("user"));
		    let RolUsuario=usuario.Perfil;
		    let IdPerfil=usuario.IdPerfil;
            if (RolUsuario!='Monitoreo' && IdPerfil!=0) {
                this.oHead.BtnNewShow=true;
            }
            else{
                this.oHead.BtnNewShow=false;
            }
        },
        mounted() {
            this.Lista();
        },
        destroyed() {
            sessionStorage.removeItem('IdSaved');
            sessionStorage.removeItem('ssobjcliente');
        },
    }

    //$$('.svg-inject').svgInject();
    </script>
