<template>
    <div>
        <Clist :ShowFilas="false" :pShowBtnAdd="false" :regresar="false" :Nombre="NameList" :Pag="Pag" :Total="TotalPagina" @FiltrarC="Lista" :Filtro="Filtro" :pConfigLoad="ConfigLoad" :ShowBusqueda="false">
            <template slot="botonextra">
                <button @click="Imprimir" type="button" class="btn btn-pink mb-2 mr-1">
					<i class="fa fa-print"></i> Imprimir
				</button>
            </template>
            <template slot="Filtros">
                <div class="form-group mr-2">
                    <select style="width: 150px;" @change="Lista"  v-model="Filtro.IdTrabajador"  class="form-control">
                        <option :value="''">--Personal--</option>
                        <option v-for="(item, index) in ListaTrabajadores" :key="index" :value="item.IdTrabajador">{{item.Nombre}}</option>
                    </select>
                </div>
                <div class="form-group mr-2">
                    <select style="width: 150px;" @change="Lista"  v-model="Filtro.Estatus"  class="form-control">
                        <option value="">--Estatus--</option>
                        <option value="Retardo">Retardo</option>
                        <option value="Falta">Falta</option>
                        <option value="HorasExtra">Horas Extra</option>
                    </select>
                </div>
                <div class="form-group mr-2">
                    <v-date-picker
                    mode='range'
                    v-model='rangeDate'
                    @input="Lista"
                    :input-props='{
                    class: "form-control   calendar",
                    placeholder: "Selecciona un rango de fecha para buscar",
                    readonly: true
                    }'/>
                </div>
                <div class="form-group">
                    <div class="dropdown mr-2">
                        <button class="btn btn-primary btn-07 dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Localización <span class="ml-1"></span>
                        </button>
                        <div class="dropdown-menu dropdown-menu-button dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                            <a class="dropdown-item" @click="Open_Modal(1)" data-toggle="modal" data-target="#ModalForm2" data-backdrop="static" data-keyboard="false" type="button">
                                Localización &nbsp;<i class="fas fa-map-marker-alt"></i>
                            </a>
                            <a class="dropdown-item" @click="OpenOterView()">
                                Localización Amplia <i class="fas fa-map-marker-alt"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </template>
            <template slot="header">
                <tr>
                    <th></th>
					<th class="tw-1"></th>
                    <th></th>
					<th class="text-center" colspan="2">Check</th>
                    <th class="text-center" colspan="2">Comida</th>
                    <th class="text-center" colspan="3">Evento 1</th>
                    <th class="text-center" colspan="3">Evento 2</th>
                    <th class="text-center" colspan="3">Evento 3</th>
					<th></th>
					<th></th>
                </tr>
                <tr>
                    <th>Fecha</th>
                    <th class="tw-1"></th>
					<th>Nombre del Personal</th>
					<th class="text-center">In</th>
                    <th class="text-center">Out</th>
                    <th class="text-center">In</th>
                    <th class="text-center">Out</th>
                    <th class="text-center">In</th>
                    <th class="text-center">Site</th>
                    <th class="text-center">Out</th>
                    <th class="text-center">In</th>
                    <th class="text-center">Site</th>
                    <th class="text-center">Out</th>
                    <th class="text-center">In</th>
                    <th class="text-center">Site</th>
                    <th class="text-center">Out</th>
					<th class="text-center">Estatus</th>
					<th>Acciones</th>
                </tr>
            </template>
            <template slot="body">
                <tr v-for="(item, index) in ListaCheckIn" :key="index">
                    <td class="tw-1">{{ $getCleanDate(item.Fecha,false) }}</td>
                    <td class="tw-1">
                        <div class="d-flex justify-content-center">
                            <div :style="'width:40px;height:40px;border-radius:50%;background-image:url('+item.Foto+');background-size:cover;background-repeat:no-repeat;background-position:center'">
                            </div>
                        </div>
                    </td>
                    <td class="tw-1">{{ item.Trabajador }}</td>
                    <td class="text-center">{{ FormatearFecha(item.checkin) }}</td>
                    <td class="text-center">{{ FormatearFecha(item.checkout) }}</td>
                    <td class="text-center">{{ FormatearFecha(item.comidacheckin) }}</td>
                    <td class="text-center">{{ FormatearFecha(item.comidacheckout) }}</td>
                    <td class="text-center">{{ FormatearFecha(item.evento1checkin) }}</td>
                    <td class="text-center">{{ FormatearFecha(item.evento1checksite) }}</td>
                    <td class="text-center">{{ FormatearFecha(item.evento1checkout) }}</td>
                    <td class="text-center">{{ FormatearFecha(item.evento2checkin) }}</td>
                    <td class="text-center">{{ FormatearFecha(item.evento2checksite) }}</td>
                    <td class="text-center">{{ FormatearFecha(item.evento2checkout) }}</td>
                    <td class="text-center">{{ FormatearFecha(item.evento3checkin) }}</td>
                    <td class="text-center">{{ FormatearFecha(item.evento3checksite) }}</td>
                    <td class="text-center">{{ FormatearFecha(item.evento3checkout) }}</td>
                    <td>
                        <div class="d-flex justify-content-center">
                            <div v-b-tooltip.hover :title="item.estatus" :class="DefinirClaseCheck(item.estatus) +' m-0'"></div>
                        </div>
                    </td>
                    <td>
                        <Cbtnaccion :isModal="false" :Id="item.IdTrabajador" :IrA="'checkindetalle'" :pShowButtonDelete="false" :pShowButtonEdit="false">
                            <template slot="btnaccion">
                                <button v-b-tooltip.hover.top class="btn-icon mr-1" title="Ver Más" @click="IrADetalle(item.IdTrabajador)">
                                    <i class="fa fa-eye"></i>
                                </button>
                                <button v-show="item.checkin!='-'" v-b-tooltip.hover.top title="Ver Comentario" @click="VerComentario(item,'In')" class="btn-icon mr-1" data-target="#Comentario" data-toggle="modal">
                                    <i class="fa fa-comment"></i>
                                </button>
                                <button v-show="item.checkin!='-'" v-b-tooltip.hover.top title="Ver Ubicación Check-In" @click="VerUbicacion(item,'In')" class="btn-icon mr-1" data-target="#Ubicacion" data-toggle="modal">
                                    <i class="far fa-map-marked-alt"></i>
                                </button>
                                <button v-show="item.checkout!='-'" v-b-tooltip.hover.top title="Ver Comentario" @click="VerComentario(item,'Out')" class="btn-icon mr-1" data-target="#Comentario" data-toggle="modal">
                                    <i class="fa fa-comment"></i>
                                </button>
                                <button v-show="item.checkout!='-'" v-b-tooltip.hover.top title="Ver Ubicación Check-Out" @click="VerUbicacion(item,'Out')" class="btn-icon" data-target="#Ubicacion" data-toggle="modal">
                                    <i class="far fa-map-marked-alt"></i>
                                </button>
                            </template>
                        </Cbtnaccion>
                    </td>
                </tr>
            </template>
        </Clist>
        
        <Modal :NameModal="'ModalForm2'" :Showbutton="false" :size="'modal-lg'" :Nombre="'Ubicación'">
			<template slot="Form">
				<Cmapa :Arreglo="Markers" :rutatrab="RutaTrab" :rutaclientes="RutaClientes" :rutaempresa="RutaEmpresa"></Cmapa>
			</template>
		</Modal>

        <Modal :pEmitSeccion="ConfigModalComentario.EmitSeccion" :NameModal="ConfigModalComentario.NombreSeccion"  :size="ConfigModalComentario.Size" :Nombre="ConfigModalComentario.Nombre" :poBtnSave="oBtnSaveComentario">
            <template slot="Form">
                <Comentario :NameList="ConfigModalComentario.NameForm" :poBtnSave="oBtnSaveComentario">
                </Comentario>
            </template>
        </Modal>
        
        <Modal :pEmitSeccion="ConfigModalUbicacion.EmitSeccion" :NameModal="ConfigModalUbicacion.NombreSeccion"  :size="ConfigModalUbicacion.Size" :Nombre="ConfigModalUbicacion.Nombre" :poBtnSave="oBtnSaveUbicacion">
            <template slot="Form">
                <Ubicacion :NameList="ConfigModalUbicacion.NameForm" :poBtnSave="oBtnSaveUbicacion">
                </Ubicacion>
            </template>
        </Modal>

    </div>
</template>

<script>
import Clist from "@/components/Clist.vue";
import Cbtnaccion from "@/components/Cbtnaccion.vue";
import Modal from "@/components/Cmodal.vue";
import Cmapa from "@/components/Cmapa.vue";
import Comentario from "./forms/Comentario.vue";
import Ubicacion from "./forms/Ubicacion.vue";
export default {
    name:"checkin",
    components:{Clist,Cbtnaccion,Modal,Cmapa,Comentario,Ubicacion},
    data() {
        return {
            NameList: "Administración de Personal",
            TotalPagina: 2,
			Pag: 0,
            Filtro:{
                Entrada: 20,
				Nombre: "",
				Placeholder: "Nombre..",
				TotalItem: 0,
				Pagina: 1,
                IdTrabajador:"",
                Estatus:""
            },
            ConfigLoad:{
				ShowLoader:true,
				ClassLoad:true,
			},
            ConfigModalComentario:{
                NombreSeccion:"Comentario",
                EmitSeccion:"Comentario",
                Size:"modal-md",
                Nombre:"Comentario",
                NameForm:"Comentario"
            },
            oBtnSaveComentario:{
                isModal:true,
                disableBtn:false,
                toast:0,
                nombreModal:"Comentario",
                EmitSeccion:"Comentario",
                ShowSave:false
            },
            ConfigModalUbicacion:{
                NombreSeccion:"Ubicacion",
                EmitSeccion:"Ubicacion",
                Size:"modal-lg",
                Nombre:"Ubicacion",
                NameForm:"Ubicacion"
            },
            oBtnSaveUbicacion:{
                isModal:true,
                disableBtn:false,
                toast:0,
                nombreModal:"Ubicacion",
                EmitSeccion:"Ubicacion",
                ShowSave:false
            },
            ListaCheckIn:[],
            rangeDate:{},
            ListaTrabajadores:[],
            Markers:[],
            RutaTrab:'',
            RutaClientes:'',
            RutaEmpresa:'',
            Sucursal:{}
        }
    },
    methods: {
        async Lista(){
            this.ConfigLoad.ShowLoader=true;
            this.$http.get('checkin/get',{
                params:{
                    Entrada:this.Filtro.Entrada,
                    pag:this.Filtro.Pagina,
                    Fecha_I:this.rangeDate.start,
                    Fecha_F:this.rangeDate.end,
                    IdTrabajador:this.Filtro.IdTrabajador,
                    Estatus:this.Filtro.Estatus,
                }
            }).then((res)=>{
                this.ListaCheckIn=res.data.data.tabla;
                this.Filtro.Entrada=res.data.data.pagination.PageSize;
                this.Filtro.TotalItem=res.data.data.pagination.TotalItems;
                this.Sucursal=res.data.data.sucursal;
            }).finally(()=>{
				this.ConfigLoad.ShowLoader = false;
			});
        },
        GetListTrabajador(){
            this.$http.get(
            'trabajador/get',
            {
                params:{RegEstatus:'A'}
            }
            ).then( (res) => {
                this.ListaTrabajadores=res.data.data.trabajador;
            });
        },
        FormatearFecha(Fecha){
            if (Fecha!='-') {
                let FechaObj=new Date(Fecha);
                let Hora=FechaObj.getHours();
                let Minuto=FechaObj.getMinutes() < 10 ? '0'+FechaObj.getMinutes() : FechaObj.getMinutes();
                return Hora+':'+Minuto;
            }
            else{
                return '-';
            }
        },
        DefinirClaseCheck(Status){
            let Clase="";
            switch (Status) {
                case "Puntual":
                    Clase="circuloStatusOperando";
                    break;
                case "Retardo":
                    Clase="circuloStatusObservacion";
                    break;
                case "Falta":
                    Clase="circuloStatusFueraServicio";
                    break;
                case "Horas Extra":
                    Clase="circuloStatusAzul";
                    break;
                case "Hora Extra":
                    Clase="circuloStatusAzul";
                    break;
                case "Vacaciones":
                    Clase="circuloStatusServicio";
                    break;
                default:
                    Clase="circuloStatusDefault";
                    break;
            }
            return Clase;
        },
        Imprimir(){
            let formData=new FormData();
            formData.set("Tabla",JSON.stringify(this.ListaCheckIn));
            this.$http.post('checkinpdf',formData,{responseType: 'blob'}).then((res)=>{
                let pdfContent = res.data;
                let file = new Blob([pdfContent], { type: 'application/pdf' });
                let fileUrl = URL.createObjectURL(file);

                window.open(fileUrl);
            });
        },
        IrADetalle(Index){
            this.$router.push({name:"checkindetalle",params:{Id:Index}});
        },
        async GetUbicaciones(){
            await this.$http.get("ubicacionmapa2/get")
			.then(res => {
				this.RutaTrab = res.data.data.ruta;
				this.RutaClientes=res.data.data.rutaclientes;
				this.RutaEmpresa=res.data.data.rutaempresa;
				this.Markers = [];
				res.data.data.ubicaciones.forEach(element => {
					this.Markers.push({
						position: {
							lat: parseFloat(element.lat),
							lng: parseFloat(element.lng)
						},
						//Nuevo
						datos: {
							Tipo: element.Tipo,
							Tecnico: element.Nombre,
							Cliente: element.Cliente,
							Servicio: element.Folio,
							TipoServicio: element.Concepto,
							FechaI: element.Fecha_I,
							FechaF: element.Fecha_F,
							HoraI: element.HoraInicio,
							HoraF: element.HoraFin,
							Estatus: element.Estatus,
							Foto2: element.Foto2,
							IsAdmin:element.IsAdmin
						}
					});
				});
				sessionStorage.setItem("rutaMapa", this.RutaTrab);
			});
        },
        Open_Modal(tipo, Id = 0) {
			this.Tipo = tipo;

			if (this.Tipo == 1) {
				this.bus.$emit("OpenModal");
			} else {
				this.bus.$emit("Nuevo", null, Id);
			}
		},
        OpenOterView() {
			// window.open("http://localhost:8080/Geolocalizacion");
			window.open("http://localhost:8080/Geolocalizacion" , '_blank');

			//window.open("https://desprosoft.lugarcreativo.com.mx/Geolocalizacion");
			// window.open("https://desprosoft.online/Geolocalizacion", "_blank");
		},
        VerComentario(Item,Tipo){
            Item.TipoCheck='checkin';
            if (Tipo=='In') {
                this.ConfigModalComentario.Nombre='Check In';
                Item.Comentario=Item.comentariocheckin;
                Item.Imagen=Item.imagencheckin;
            }
            else{
                this.ConfigModalComentario.Nombre='Check Out';
                Item.ComentarioCheckOut=Item.comentariocheckout;
                Item.ImagenCheckOut=Item.imagencheckout;
            }
            this.bus.$emit('NewModal_'+this.ConfigModalComentario.EmitSeccion,Item,Tipo);
        },
        VerUbicacion(Item,Tipo){
            Item.Latitud=Item.latitudcheckin;
            Item.LatitudOut=Item.latitudcheckout;
            Item.Longitud=Item.longitudcheckin;
            Item.LongitudOut=Item.longitudcheckout;
            this.bus.$emit('NewModal_'+this.ConfigModalUbicacion.EmitSeccion,Item,Tipo,this.Sucursal);
        },
    },
    created() {
        var date = new Date(), y = date.getFullYear(), m = date.getMonth();
        var firstDay = new Date();
        var lastDay = new Date();
        this.rangeDate={
            start:firstDay,
            end:lastDay
        }
        this.bus.$off("ListarUbicacion");
        this.bus.$on("ListarUbicacion", () => {
			this.GetUbicaciones();
		});
    },
    mounted() {
        this.GetListTrabajador();
        this.Lista();
    },
}
</script>

<style>

</style>