<template>
    <div>
        <section class="container-fluid mt-2">
           <Menu :isInOportunidad="true" :pSitio="NombreSeccion" :pRegresar="false" :pSitioAtras="txtSitio">
            </Menu>
            <div class="row">
                <div class="col-md-12 col-lg-12 col-xl-12 mt-2">
                   <Clist :pConfigLoad="ConfigLoad" :isCrm="true" :regresar="true" :ShowHead="false" @FiltrarC="Lista" :Filtro="Filtro" :Nombre="NameList" :Pag="Pag" :Total="TotalPagina" :isModal="EsModal">
                    <template slot="Botonnuevo">
                        <div class="col-md-12 col-lg-6 text-right mt-2">
                            <button type="button" data-toggle="modal" data-target="#ModalForm"  data-backdrop="static" data-keyboard="false" class="btn btn-pink mr-2" @click="Nuevo()">Nueva</button>
                        </div>
                    </template>
                    <template slot="Filtros"> 
                        <label class="mr-2">Estatus</label>
                        <select @change="Lista" v-model="Estado" class="form-control form-control">
                            <option value="">Seleccione una opción</option>
                            <option :value="'Activa'">Activa</option>
                            <option :value="'Ganada'">Ganada</option>
                            <option :value="'Perdida'">Perdida</option>
                            <!-- <option :value="'Inactivo'">Inactivo</option> -->
                        </select>
                    </template>
                    <template slot="header" v-if="MostrarTodo">
                        <tr>
                            <th>Nombre</th>
                            <th>Contacto</th>
                            <th>Sucursal</th>
                            <th>Estatus</th>
                            <th v-if="RolUsuario=='Admin'">Usuario</th>
                            <th>Fecha</th>
                            <th>Acciones</th>
                        </tr>
                    </template>
                    <template slot="header" v-else>
                        <tr>
                            <th>Nombre</th>
                            <th v-if="RolUsuario=='Admin'">Usuario</th>
                            <th>Fecha</th>
                            <th>Estatus</th>
                            <th>Acciones</th>
                        </tr>
                    </template>

                    <template slot="body" v-if="MostrarTodo">
                        <tr v-for="(lista,key,index) in  ListaOportunidades" :key="index" >
                            <td>{{$limitCharacters(lista.Nombre,40)}}</td>
                            <td>{{ lista.NomCliente }}</td>
                            <td>{{lista.Sucursal}}</td>
                            <td>{{lista.Estado}}</td>
                            <td v-if="RolUsuario=='Admin'">{{$limitCharacters(lista.Vendedor,26)}}</td>
                            <td>{{$getCleanDate(lista.FechaReg,false)}}</td>
                            <td>
                                <Cbtnaccion  :isModal="EsModal" :Id="lista.IdOportunidad" :IrA="FormName" :pEmitSeccion="EmitSeccion">
                                    <template slot="btnaccion">

                                    </template>
                                </Cbtnaccion>
                            </td>
                        </tr>
                    </template>
                    <template slot="body" v-else>
                        <tr v-for="(lista,key,index) in  ListaOportunidades" :key="index" >
                            <td>{{$limitCharacters(lista.Nombre,40)}}</td>
                            <td v-if="RolUsuario=='Admin'">{{$limitCharacters(lista.Vendedor,26)}}</td>
                            <td>{{$getCleanDate(lista.FechaReg,false)}}</td>
                            <td>{{lista.Estado}}</td>
                            <td>
                                <Cbtnaccion  :isModal="EsModal" :Id="lista.IdOportunidad" :IrA="FormName" :pEmitSeccion="EmitSeccion">
                                    <template slot="btnaccion">

                                    </template>
                                </Cbtnaccion>
                            </td>
                        </tr>
                    </template>
                </Clist>

                <Modal :size="size" :Nombre="NameList" :poBtnSave="oBtnSave"  :pEmitSeccion="EmitSeccion">
                    <template slot="Form">
                        <Form :OClienteS="oClienteS" @Listar="ListaCliente" :poBtnSave="oBtnSave"></Form>
                    </template>
                </Modal>

                    <Ccliente :Origen="'Oportunidad'" :TipoModal='2'></Ccliente>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import Modal from '@/components/Cmodal.vue';
import Clist from '@/components/Clist.vue';
import Cbtnaccion from '@/components/Cbtnaccion.vue';
import Ccliente from '@/components/Ccliente.vue';
import Form from '../oportunidad/form.vue';
import Menu from "../indexMenu.vue";

export default {
    name :'list',
    props: ["oclientesorigen"],
    components :{
        Modal,
        Clist,
        Cbtnaccion,
        Form,
        Ccliente,
        Menu,
    },
    data() {
        return {
            NombreSeccion: 'Oportunidades',
            FormName:'TipoUnidadForm',//Por si no es modal y queremos ir a una vista declarada en el router
            EsModal:true,//indica si es modal o no
            size :"modal-lg",
            EmitSeccion:'Oportunidad',
            NameList:"Oportunidad",
            urlApi:"crmoportunidad/list",
            RolUsuario:'',
            IdUsuario:'',
            ListaOportunidades:[],
            ListaHeader:[],
            TotalPagina:2,
            Pag:0,
            Estado:"",
              Filtro:{
                Nombre:'',
                Placeholder:'Nombre..',
                TotalItem:0,
                Pagina:1,
                Entrada: 20
            },
            oBtnSave:{//valores  isModal(true),nombreModal('ModalForm'),tipoModal=1,regresarA(''),disableBtn(false),txtSave('Guardar'),txtCancel('Cerrar');
                isModal:true,
                disableBtn:false,
                toast:0,
                EmitSeccion:'Oportunidad'
            },
            oClienteS:{},
            txtSitio:"Calendario",
            MostrarTodo:true,
            ConfigLoad:{
                ShowLoader:true,
                ClassLoad:true
            }
        }
    },
    methods: {
        Nuevo()
        {
            if (this.oBtnSave.isModal==true)
            {
                this.bus.$emit('NewModal_'+this.EmitSeccion,true);
            }
            else{
                this.bus.$emit('NewModal_'+this.EmitSeccion);
            }
        },
        async ListaCliente()
        {
            this.bus.$emit('ListCcliente');
        },
        Eliminar(Id)
        {
            this.$swal({
                title: 'Esta seguro que desea eliminar este dato?',
                text: 'No se podra revertir esta acción',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si',
                cancelButtonText: 'No, mantener',
                showCloseButton: true,
                showLoaderOnConfirm: true
            }).then((result) => {
                if(result.value) {
                    this.$http.delete(
                        'crmoportunidad/' + Id
                    ).then( (res) => {
                        if (this.oclientesorigen!=undefined) {
                            let Obj=JSON.parse(sessionStorage.getItem("IdSaved"));
							Obj.Oportunidades--;
							sessionStorage.setItem("IdSaved",JSON.stringify(Obj));
                        }
                        this.$toast.success('Información eliminada');
                        this.Lista();
                    });
                }
            });
        },
        async Lista()
        {
            this.ConfigLoad.ShowLoader=true;
            let params={
                Nombre:this.Filtro.Nombre,
                RegEstatus:'A',
                Entrada:this.Filtro.Entrada,
                pag:this.Filtro.Pagina,
                Estado:this.Estado
            }
            if (this.RolUsuario!="Admin") {
                params.IdVendedor=this.IdUsuario;
            }
            if (this.oclientesorigen!=undefined) {
                params.IdClienteS=this.oClienteS.IdClienteS
            }
            await this.$http.get(
                this.urlApi,
                {
                    params:params
                }
            ).then( (res) => {
                this. ListaOportunidades =res.data.data.oportunidades;
                this.Filtro.Entrada=res.data.data.pagination.PageSize;
                this.Filtro.TotalItem=res.data.data.pagination.TotalItems;
                this.ConfigLoad.ShowLoader=false;
            });
        },
        SeleccionarCliente(objeto)
        {
            this.oclientesuc=objeto;
            if (this.oclientesuc.Correo!='')
            {
                this.servicios.Para.push({ "text": this.oclientesuc.Correo});
            }
            let distacia=0;
            if(objeto.DistanciaAprox !='')
            {
                distacia=objeto.DistanciaAprox;
            }
            this.servicios.IdCliente=objeto.IdCliente;
            this.servicios.IdClienteS=objeto.IdClienteS;
            this.servicios.Cliente=objeto.Nombre;
            this.servicios.Direccion=objeto.Direccion;
            this.servicios.Distancia=distacia;
            this.servicios.Velocidad=0;
            this.ListaNumContrato();
        },
    },
    created()
    {
        this.MostrarTodo=true;
        if (this.oclientesorigen!=undefined) {
            this.oClienteS=this.oclientesorigen;
            this.txtSitio="Volver";
            this.MostrarTodo=false;
        }
        this.RolUsuario=JSON.parse(sessionStorage.getItem('user')).Perfil;
        if (this.RolUsuario!="Admin") {
            this.IdUsuario=JSON.parse(sessionStorage.getItem('user')).IdUsuario;
        }
        this.bus.$off('SeleccionarCliente');

        this.bus.$on('SeleccionarCliente',(oSucursal)=>
        {
            //this.SeleccionarCliente(oSucursal);
            alert(JSON.stringify(oSucursal));
        });

        this.bus.$off('Delete');
        this.bus.$off('List');
        this.bus.$off('Regresar');
        this.Lista();

        this.bus.$on('Delete',(Id)=>
        {
            this.Eliminar(Id);
        });
        this.bus.$on('List',()=>
        {
            this.Lista();
        });
        this.bus.$on('RegresarCalendario',()=>
        {
            if (this.oclientesorigen!=undefined) {
                this.$router.push({
				    name: "crmcontactosucursal",
			    });   
            }
            else{
                this.$router.push({name:'submenucrm'});
            }
        });
    },
    mounted() {
        $(".card-01").css('width','80%');
        $(".card-01").css('margin-left','auto');
        $(".card-01").css('margin-right','auto');
    },
}
</script>
