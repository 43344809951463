<template>
	<div>
		<input type="hidden" :value="Validates">
		<div class="row mt-2">
			<div class="col-md-12 col-lg-12">
				<h4 class="titulo-04">OBSERVACIONES</h4>
			</div>
			<div class="col-md-12 col-lg-12">
				<hr>
			</div>
		</div>

		<div class="row mt-2">
			<div class="col-md-6 col-lg-6">
				<label>Observaciones del técnico</label>
				<textarea v-model="localService.Comentario" readonly class="form-control" rows="5"></textarea>
			</div>
			<div class="col-md-6 col-lg-6">
				<label>Observaciones finales</label>
				<textarea v-model="localService.ComentarioFin" class="form-control" rows="5"></textarea>
			</div>
		</div>
    <!--End Observaciones-->

    <!--Costos-->
		<div class="row mt-2">
			<div class="col-md-12 col-lg-12">
				<h4>COSTOS</h4>
				<hr>
			</div>
		</div>

		<div class="row mt-2">
			<div class="col-md-2 col-lg-2">
				<label>Equipos</label>
				<vue-numeric :minus="false" class="form-control form-control-sm"  currency="$" separator="," :precision="2" placeholder="$ 0.00" v-model="localService.EquiposD"></vue-numeric>
		  </div>

		  <div class="col-md-2 col-lg-2">
			  <label>Materiales</label>
			  <vue-numeric :minus="false" class="form-control form-control-sm"  currency="$" separator="," :precision="2" placeholder="$ 0.00" v-model="localService.MaterialesD"></vue-numeric>
		  </div>

		  <div class="col-md-2 col-lg-2">
			  <label>Viaticos</label>
			  <vue-numeric :minus="false" class="form-control form-control-sm"  currency="$" separator="," :precision="2" placeholder="$ 0.00" v-model="localService.ViaticosD"></vue-numeric>
		  </div>

		  <div class="col-md-2 col-lg-2">
			  <label>Contratista</label>
			  <vue-numeric   :minus="false" class="form-control form-control-sm"  currency="$" separator="," :precision="2" placeholder="$ 0.00" v-model="localService.ContratistasD"></vue-numeric>
		  </div>

		  <div class="col-md-2 col-lg-2">
			  <label>Costo Mano de Obra</label>
			  <vue-numeric  disabled :minus="true" class="form-control form-control-sm"  currency="$" separator="," :precision="2" placeholder="$ 0.00" v-model="localService.ManoObraT"></vue-numeric>
		  </div>

		  <div class="col-md-2 col-lg-2">
			  <label>Burden</label>
			  <vue-numeric  disabled  :minus="true" class="form-control form-control-sm"  currency="$" separator="," :precision="2" placeholder="$ 0.00" v-model="localService.BurdenTotal"></vue-numeric>
		  </div>

		  <div class="col-md-2 col-lg-2">
			  <label>Costo Vehiculos</label>
			  <vue-numeric  disabled  :minus="true" class="form-control form-control-sm"  currency="$" separator="," :precision="2" placeholder="$ 0.00" v-model="localService.CostoV"></vue-numeric>
		  </div>

		</div>

  </div>
</template>
<script>
export default {
    name:'Costos',
    props:['servicios'],
    data() {
        return {
            monto:0,
            errorvalidacion:[],
			localService:{
				Comentario: '',
				ComentarioFin: '',
				EquiposD: 0,
				MaterialesD: 0,
				ViaticosD: 0,
				ContratistasD: 0,
				ManoObraT: 0,
				BurdenTotal: 0,
				CostoV: 0,
			}
        }
    },methods: {


    },created() {

    },
	computed: {
		Validates() {

			if(this.servicios.Comentario !== undefined){
				this.localService.Comentario = this.servicios.Comentario;
			}
			if(this.servicios.ComentarioFin !== undefined){
				this.localService.ComentarioFin = this.servicios.ComentarioFin;
			}
			if(this.servicios.EquiposD !== undefined){
				this.localService.EquiposD = this.servicios.EquiposD;
			}
			if(this.servicios.MaterialesD !== undefined){
				this.localService.MaterialesD = this.servicios.MaterialesD;
			}
			if(this.servicios.ViaticosD !== undefined){
				this.localService.ViaticosD = this.servicios.ViaticosD;
			}
			if(this.servicios.ContratistasD !== undefined){
				this.localService.ContratistasD = this.servicios.ContratistasD;
			}
			if(this.servicios.ManoObraT !== undefined){
				this.localService.ManoObraT = this.servicios.ManoObraT;
			}
			if(this.servicios.BurdenTotal !== undefined){
				this.localService.BurdenTotal = this.servicios.BurdenTotal;
			}
			if(this.servicios.CostoV !== undefined){
				this.localService.CostoV = this.servicios.CostoV;
			}
		}
	}
}
</script>
