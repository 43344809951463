<template>
	<div>
		<CLoader :pConfigLoad="ConfigLoad">
			<template slot="BodyFormLoad">
				<div v-if="!BuscarIcono">
					<div class="form-group">
						<div class="form-check form-check-inline">
							<div class="checkbox">
								<label>
									<input
										type="checkbox"
										v-model="checked"
										@change="get_DatosCli"
										name="optionsCheckboxes"
									/><span class="checkbox-material-green"
										><span class="check"></span
									></span>
									Utilizar Datos Contacto
								</label>
							</div>
						</div>
						<div class="form-check form-check-inline">
							<div class="checkbox">
								<label>
									<input type="checkbox"
									:disabled="DesactivarUsuarioMonitoreo"
									v-model="checkedScanning"
									@change="ChangeScanning"
									:checked="checkedScanning"
									name="optionsCheckboxes"
									><span class="checkbox-material-green">
										<span class="check"></span>
									</span>
									Propiedad Monitoreo Interno
								</label>
							</div>
						</div>
						<div class="form-check form-check-inline">
							<div class="checkbox">
								<label>
									<input type="checkbox"
									:disabled="DesactivarMonCliente"
									:checked="checkedMonCliente"
									v-model="checkedMonCliente"
									@change="ChangeMonCliente"
									name="optionsCheckboxes"
									><span class="checkbox-material-green">
										<span class="check"></span>
									</span>
									Client View Propiedad
								</label>
							</div>
						</div>
						<hr />
					</div>
			
					<div class="row">
						<div class="col-lg-6 ">
							<label>Nombre de la Propiedad *</label>
							<input
								type="text"
								v-model="Clientes.Nombre"
								class="form-control form-control-sm"
								placeholder="Nombre"
								id="Nombre"
								name="Nombre"
							/>
							<Cvalidation
								v-if="this.errorvalidacion.Nombre"
								:Mensaje="errorvalidacion.Nombre[0]"
							></Cvalidation>
						</div>
			
						<div class="col-lg-6 ">
							<label>Categoría </label>
							<select v-model="Clientes.Tipo" class="form-control form-control-sm">
								<option value="">Seleccione una opción</option>
								<option :value="'Cliente'">Clientes</option>
								<option :value="'Prospecto'">Prospectos</option>
								<option :value="'Suspecto'">Suspecto</option>
								<option :value="'Inactivo'">Inactivo</option>
							</select>
							<label id="lblmsuser" style="color:red"
								><Cvalidation
									v-if="this.errorvalidacion.Tipo"
									:Mensaje="errorvalidacion.Tipo[0]"
								></Cvalidation
							></label>
						</div>
			
					</div>
			
					<div class="row">
						<div class="col-lg-9">
							<label>Dirección *</label>
							<input
								type="text"
								v-model="Clientes.Direccion"
								class="form-control form-control-sm"
								placeholder="Dirección"
							/>
							<label id="lblmsuser" style="color:red"
								><Cvalidation
									v-if="this.errorvalidacion.Direccion"
									:Mensaje="errorvalidacion.Direccion[0]"
								></Cvalidation
							></label>
						</div>
			
						<div class="col-lg-3">
							<label>Distancia Aproximada (KM)</label>
							<input
								type="text"
								v-model="Clientes.DistanciaAprox"
								class="form-control form-control-sm"
								placeholder="Distancia Aproximada"
							/>
							<label id="lblmsuser" style="color:red"
								><Cvalidation
									v-if="this.errorvalidacion.DistanciaAprox"
									:Mensaje="errorvalidacion.DistanciaAprox[0]"
								></Cvalidation
							></label>
						</div>
					</div>
					
					<div class="row">
						<div class="col-lg-6">
							<label>Estado </label>
							<input
								type="text"
								v-model="Clientes.Estado"
								class="form-control form-control-sm"
								placeholder="Estado"
							/>
							<label id="lblmsuser" style="color:red"
								><Cvalidation
									v-if="this.errorvalidacion.Estado"
									:Mensaje="errorvalidacion.Estado[0]"
								></Cvalidation
							></label>
						</div>
		
						<div class="col-lg-6 ">
							<label>Ciudad *</label>
							<input
								type="text"
								v-model="Clientes.Ciudad"
								class="form-control form-control-sm"
								placeholder="Ciudad"
							/>
							<label id="lblmsuser" style="color:red"
								><Cvalidation
									v-if="this.errorvalidacion.Ciudad"
									:Mensaje="errorvalidacion.Ciudad[0]"
								></Cvalidation
							></label>
						</div>
			
					</div>
		
					<div class="row">
						<div class="col-lg-6">
							<label>Teléfono *</label>
							<input
								type="text"
								v-model="Clientes.Telefono"
								class="form-control form-control-sm"
								placeholder="Teléfono"
							/>
							<label id="lblmsuser" style="color:red"
								><Cvalidation
									v-if="this.errorvalidacion.Telefono"
									:Mensaje="errorvalidacion.Telefono[0]"
								></Cvalidation
							></label>
						</div>
		
						<div class="col-lg-6">
							<label>Estatus *</label>
							<select v-model="Clientes.Estatus" class="form-control form-control-sm">
								<option value="">Selecciona un Estatus</option>
								<option value="Activo">Activo</option>
								<option value="Inactivo">Inactivo</option>
							</select>
							<label id="lblmsuser" style="color:red"
								><Cvalidation
									v-if="this.errorvalidacion.Estatus"
									:Mensaje="errorvalidacion.Estatus[0]"
								></Cvalidation
							></label>
						</div>
					</div>
			
					<!-- <div class="row">
						<div class="col-lg-6">
							<label>Nombre del Contacto</label>
							<input
								type="text"
								v-model="Clientes.Contacto"
								class="form-control form-control-sm"
								placeholder="Contacto"
							/>
							<label id="lblmsuser" style="color:red"
								><Cvalidation
									v-if="this.errorvalidacion.ContactoS"
									:Mensaje="errorvalidacion.ContactoS[0]"
								></Cvalidation
							></label>
						</div>
			
						<div class="col-lg-6 ">
							<label>Correo </label>
							<input
								v-model="Clientes.Correo"
								class="form-control form-control-sm"
								placeholder="Correo"
							/>
							<label id="lblmsuser" style="color:red"
								><Cvalidation
									v-if="this.errorvalidacion.Correo"
									:Mensaje="errorvalidacion.Correo[0]"
								></Cvalidation
							></label>
						</div>
					</div> -->
						
			
						<!-- ESPACIO PARA LAS IMAGENES -->
						<div class="row mb-3">
							<div class="col-md-6 col-lg-6 justify-content-center text-center mt-2">
									<div class="circular_shadow">
										<img :src="pRutaIconoEmp+Clientes.IdIconoEmp" alt="">
									</div>
									<button @click="FindIcoEmp" type="button" class="btn btn-03 search02 mt-2">Buscar Icono de Edificio: {{ Clientes.IdIconoEmp }}</button>	
							</div>
				
							<div class="col-lg-6 mt-4">
								<div class="row">
									<div class="col-lg-6">
										<div class="avatar-upload-logocliente">
											<div class="avatar-preview">
												<div id="ImagePreviewLogoSucursal" :style="'background-image:url('+Clientes.LogoSucursal+');background-size:contain'" :src="Clientes.LogoSucursal">
													<!-- <div class="d-flex h-100 align-items-center justify-content-center" v-if="AltLogoSucursal">
														<h4>Logo</h4>
													</div> -->
													<!-- PONER ESTO DENTRO DEL DIV -->
													<!-- :style="'background-image: url('+Equipo.ImagenEspecificaciones+');'" :src="Equipo.ImagenEspecificaciones" -->
												</div>
											</div>
										</div>
									</div>
									<div class="col-lg-6 pl-5">
										<label title="Seleccionar Imagen" for="logosucursal" style="cursor:pointer" class="btn-icon mb-2"><i class=" mt-2 fa fa-pencil"></i></label>
										<input @change="$uploadImagePreview($event,ValidElement,Array('Img','ImagePreviewLogoSucursal'))" class="d-none" type="file" id="logosucursal" ref="logosucursal" name="logosucursal" accept=".png, .jpg, .jpeg">
										<br>
										<button @click="EliminarImagen" class="btn-icon-02"><i class="fa fa-trash"></i></button>
									</div>
								</div>
							</div>
						</div>
						
						<div class="row">
							<div class="col-lg-12 ">
								<h5>Selecciona tu ubicación</h5>
								<div class="col-12 col-sm-12 col-md-12 col-lg-12">
									<Search v-if="boleano" :oLatLng="oLatLng"></Search>
								</div>
							</div>
						</div>
						
						<!--fin col-6-->
				</div>
				<div v-else>
					<div class="row">
						<div class="col-lg-1 mb-3">
							<h4 @click="VolverFormulario" class="breadcrumb breadcrumb-item active" style="cursor:pointer;">Volver</h4>
						</div>
						<div class="col-lg-11 text-center mb-3">
							<h2>Seleccione un ícono</h2>
						</div>
					</div>
					<div class="row">
						<div v-for="(item,index) in ListaIconosEmp" :key="index" class="col-md-4 col-lg-3 mb-3">
							<div class="circular_shadow" @click="ElegirIcoEmp(index)">
								<img :src="pRutaIconoEmp+item.Imagen" alt="">
							</div>
						</div>
					</div>
			
				</div>
			</template>
		</CLoader>
		<!-- MODAL DE LoS CONTRATOS -->
		<!-- <Cmodal :Nombre="'Contratos'" :pEmitSeccion="DatosTablaContratos.EmitSeccionTabla" :NameModal="DatosTablaContratos.NombreTabla" :poBtnSave="DatosTablaContratos.oBtnSave">
			<template slot="Form">
				<FormContratos :ocliente="Clientes" :poBtnSave="DatosTablaContratos.oBtnSave"></FormContratos>
			</template>
		</Cmodal> -->
	</div>
	
</template>
<script>
//El props Id es cuando no es modal y se mando con props
//El export de btnsave es por si no se usa el modal
import Cbtnsave from "@/components/Cbtnsave.vue";
import Cvalidation from "@/components/Cvalidation.vue";
import Config from "@/helpers/VarConfig.js";
import Clistform from '../../../../components/Clistform.vue';
// QUITAR ESTAS PARTES
import Cmodal from '@/components/Cmodal.vue';
import FormContratos from '../../../catalogos/contratos/Form.vue';

export default {
	name: "Form",
	props: ["ocliente", "poBtnSave", "pRutaIconoEmp","pContadorPropiedades","pRutaCliente","pContadorMonCliente"],
	data() {
		return {
			Modal: true, //Sirve pra los botones de guardado
			FormName: "cliente", //Sirve para donde va regresar
			ValidElement:Config.validImage2m,
			ConfigLoad:{
				ShowLoader:true,
				ClassLoad:true
			},
			Clientes: {
				IdClienteS: "",
				IdCliente: 0,
				Nombre: "",
				Telefono: "",
				Direccion: "",
				Correo: "",
				Ciudad: "",
				Pais: "",
				Estado: "",
				CP: "",
				IdSucursal: "",
				Contacto: "",
				Dfac: "",
				Tipo: "",
				Posicion: "",
				Latitud: 0,
				Longitud: 0,
				DistanciaAprox:0,
				IdIconoEmp:"",
				LogoSucursal:"",
				CheckCli:0,
				CheckMonCli:0,
				Estatus:""
			},
			checked: false,
			checkedScanning:false,
			checkedMonCliente:false,
			oClienteP: {},
			urlApi: "crmsucursal/recovery",
			errorvalidacion: [],
			oLatLng: {
				Lat: 21.021320029601277,
				Lng: -89.58358390674591
			},
			boleano: true,
			RutaIconoEmp:"",
			RutaLogoSucursal:"",
			ListIconoEmp:[],
			BuscarIcono:false,
			EmitSeccion:this.poBtnSave.EmitSeccion,
			DatosTablaContratos:{
				ShowButtonTop:"true",
				NombreTabla:"Contratos",
				EmitSeccionTabla:"Contratos",
				TituloBoton:"Contratos",
				oBtnSave:{
                	isModal:true,
                	disableBtn:false,
                	toast:0,
                	nombreModal:"Contratos",
                	EmitSeccion:"Contratos"
            	}
			},
			DatosTablaOportunidades:{},
			Contratos:[],
			ContratosAppear:false,
			AgregarNuevo:true,
			DesactivarUsuarioMonitoreo:false,
			DesactivarMonCliente:false
			// AltLogoSucursal:true
		};
	},
	components: {
		Cbtnsave,
		Cvalidation,
		Clistform,
		Cmodal,
		FormContratos
	},
	methods: {
		get_DatosCli() {
			if (this.checked) {
				this.Clientes.IdCliente = this.oClienteP.IdCliente;
				this.Clientes.Nombre = this.oClienteP.Nombre;
				this.Clientes.Direccion = this.oClienteP.Direccion;
				this.Clientes.Ciudad = this.oClienteP.Ciudad;
				this.Clientes.Contacto = this.oClienteP.Contacto;
				this.Clientes.Telefono = this.oClienteP.Telefono;
				this.Clientes.Correo = this.oClienteP.Correo;
				this.Clientes.Estado=this.oClienteP.Estado;
				// console.log(this.ImagenBase64(this.oClienteP.Ruta+this.oClienteP.LogoCliente));
				// this.Clientes.LogoSucursal=this.oClienteP.Ruta+this.oClienteP.LogoCliente;
				// this.$refs.logosucursal.files=this.getBase64FromUrl(this.Clientes.LogoSucursal);
			} else {
				this.Limpiar();
			}
		},
		async Guardar() {
			//deshabilita botones
			let Operacion="";
			if (this.Clientes.IdClienteS=="" ? Operacion="Crear" : Operacion="Editar");
			this.Clientes.Latitud = this.oLatLng.Lat;
			this.Clientes.Longitud = this.oLatLng.Lng;
			this.poBtnSave.toast = 0;
			this.poBtnSave.disableBtn = true;

			let formData = new FormData();

			formData.set("IdCliente", this.Clientes.IdCliente);
			formData.set("Nombre", this.Clientes.Nombre);
			formData.set("Telefono", this.Clientes.Telefono);
			formData.set("Direccion", this.Clientes.Direccion);
			formData.set("Correo", this.Clientes.Correo);
			formData.set("Ciudad", this.Clientes.Ciudad);
			formData.set("Pais", this.Clientes.Pais);
			formData.set("Estado", this.Clientes.Estado);
			formData.set("Estatus", this.Clientes.Estatus);
			formData.set("CP", this.Clientes.CP);
			formData.set("ContactoS", this.Clientes.Contacto);
			formData.set("Comentario", this.Clientes.Dfac);
			formData.set("Tipo", this.Clientes.Tipo);
			formData.set("IdClienteS", this.Clientes.IdClienteS);
			// formData.set("Posicion", this.Clientes.Posicion);
			formData.set("Latitud", this.oLatLng.Lat);
			formData.set("Longitud", this.oLatLng.Lng);
			formData.set("CheckCli", this.Clientes.CheckCli);
			formData.set("CheckMonCli", this.Clientes.CheckMonCli);

			formData.set("IdIconoEmp",this.Clientes.IdIconoEmp);
			formData.set("DistanciaAprox",this.Clientes.DistanciaAprox);
			// IMAGEN
			if (this.$refs.logosucursal.files[0]!=null) {
				let image=this.$refs.logosucursal.files[0];
				formData.append("LogoSucursal",image);
			}
			if (this.Clientes.LogoSucursal!="" && this.Clientes.LogoSucursal!=(this.pRutaCliente.Ruta+this.oClienteP.LogoCliente)) {
				let index=this.Clientes.LogoSucursal.lastIndexOf("/")+1;
			    let cadena=this.Clientes.LogoSucursal.substr(index);
                formData.set('LogoSucursalPrevio',"/"+cadena);   
			}

			await this.$http
				.post("crmsucursal/post", formData, {
					headers: {
						"Content-Type": "multipart/form-data"
					}
				})
				.then(res => {
					this.poBtnSave.disableBtn = false;
					this.poBtnSave.toast = 1;
					$("#ContactoSucursal").modal("hide");
					// if (this.AgregarNuevo) {
					// 	this.Limpiar();
					// }
					// else{
					// 	$("#Sucursal").modal("hide");
					// }
					this.bus.$emit("List",Operacion,"Propiedad",res.data.data.clientes);
				})
				.catch(err => {
					this.errorvalidacion = err.response.data.message.errores;
					this.poBtnSave.disableBtn = false;
					this.poBtnSave.toast = 2;
				});
		},
		Limpiar() {
			(this.Clientes.Nombre = ""),
				(this.Clientes.Telefono = ""),
				(this.Clientes.Direccion = ""),
				(this.Clientes.Correo = ""),
				(this.Clientes.Ciudad = "");
			(this.Clientes.Pais = ""),
				(this.Clientes.Estado = ""),
				(this.Clientes.CP = ""),
				(this.Clientes.Contacto = ""),
				(this.Clientes.Dfac = ""),
				// (this.Clientes.IdCliente = 0),
				(this.Clientes.IdSucursal = ""),
				(this.errorvalidacion = [""]);
			this.Clientes.IdClienteS = "";
			this.Clientes.Tipo = "";
			this.Clientes.Posicion = "";
			this.Clientes.Latitud = 0;
			this.Clientes.Longitud = 0;
			this.Clientes.DistanciaAprox=0;
			this.Clientes.IdIconoEmp="",
			this.Clientes.LogoSucursal="",
			this.Clientes.CheckCli=0,
			this.Clientes.CheckMonCli=0,
			(this.Clientes.Estatus = ""),
			this.oLatLng = {
				Lat: 19.43120339928868,
				Lng: -99.13450664719238
			};

			this.checked = false;
			this.checkedScanning=false;
			this.checkedMonCliente=false;
			this.BuscarIcono=false;
			this.ContratosAppear=false;

			// LIMPIAR LA IMAGEN
			// VERIFICAR SI ES NUEVO O SI ESTA MODIFICANDO
			$("#ImagePreviewLogoSucursal").css('background-image','url()');
			$("#ImagePreviewLogoSucursal").css('background-size','contain');
			if (this.AgregarNuevo) {
				this.Clientes.LogoSucursal=this.pRutaCliente+this.oClienteP.LogoCliente;
				$("#ImagePreviewLogoSucursal").css('background-image','url('+this.Clientes.LogoSucursal+')');
			}
			this.$refs.logosucursal.value="";
			// console.log(this.$refs.logosucursal.files[0]);
			// this.$refs.logosucursal.files[0]=undefined;
		},
		get_one() {
			this.$http
				.get(this.urlApi, {
					params: { IdClienteS: this.Clientes.IdCliente }
				})
				.then(res => {
					this.RutaLogoSucursal=res.data.ruta;
					this.Clientes.IdClienteS = res.data.data.Clientes.IdClienteS;
					this.Clientes.IdCliente = res.data.data.Clientes.IdCliente;
					this.Clientes.Nombre = res.data.data.Clientes.Nombre;
					this.Clientes.Telefono = res.data.data.Clientes.Telefono;
					this.Clientes.Direccion = res.data.data.Clientes.Direccion;
					this.Clientes.Correo = res.data.data.Clientes.Correo;
					this.Clientes.Ciudad = res.data.data.Clientes.Ciudad;
					this.Clientes.Pais = res.data.data.Clientes.Pais;
					this.Clientes.Estado = res.data.data.Clientes.Estado;
					this.Clientes.Estatus = res.data.data.Clientes.Estatus;
					this.Clientes.CP = res.data.data.Clientes.CP;
					this.Clientes.IdSucursal = res.data.data.Clientes.IdSucursal;
					this.Clientes.Contacto = res.data.data.Clientes.Contacto;
					this.Clientes.Dfac = res.data.data.Clientes.Comentario;
					this.Clientes.Contacto = res.data.data.Clientes.ContactoS;
					this.Clientes.Tipo = res.data.data.Clientes.Tipo;
					this.Clientes.Posicion = res.data.data.Clientes.Cargo;
					this.Clientes.Latitud = res.data.data.Clientes.Latitud;
					this.Clientes.Longitud = res.data.data.Clientes.Longitud;
					this.Clientes.DistanciaAprox=res.data.data.Clientes.DistanciaAprox;
					this.Clientes.IdIconoEmp=res.data.data.Clientes.IdIconoEmp;
					this.Clientes.LogoSucursal=this.RutaLogoSucursal+res.data.data.Clientes.LogoSucursal;
					$("#ImagePreviewLogoSucursal").css('background-image','url('+this.Clientes.LogoSucursal+')');
					this.oLatLng.Lat = this.Clientes.Latitud;
					this.oLatLng.Lng = this.Clientes.Longitud;
					this.Clientes.CheckCli=res.data.data.Clientes.CheckCli;
					this.Clientes.CheckMonCli=res.data.data.Clientes.CheckMonCli;
					if (this.Clientes.CheckCli==1) {
						this.checkedScanning=true;
						this.DesactivarUsuarioMonitoreo=false;
					}
					if (this.Clientes.CheckMonCli==1) {
						this.checkedMonCliente=true;
						this.DesactivarMonCliente=false;
						// this.DesactivarUsuarioMonitoreo=false;
					}
					// else{
					// 	if (this.pContadorPropiedades<=0) {
					// 		if (this.Clientes.CheckCli==0) {
					// 			this.DesactivarUsuarioMonitoreo=true;
					// 		}
					// 		else{
					// 			this.DesactivarUsuarioMonitoreo=false;
					// 		}
					// 	}
					// }

					this.bus.$emit("ActualC", "");
					// this.ListaContratos();
					if (res.data.data.Clientes.LogoSucursal==null || res.data.data.Clientes.LogoSucursal=="") {
						this.Clientes.LogoSucursal=this.pRutaCliente+this.oClienteP.LogoCliente;
						$("#ImagePreviewLogoSucursal").css('background-image','url('+this.Clientes.LogoSucursal+')');
					}
					//this.bus.$emit('actualCordenadas2');
				}).finally(()=>{
					this.ConfigLoad.ShowLoader=false;
				});
		},
		ListaContratos(){
			this.$http.get("numcontrato/get",{
				params:{
					IdClienteS: this.Clientes.IdClienteS,
					IdCliente: this.oClienteP.IdCliente,
					IdSucursa: this.oClienteP.IdSucursal,
					RegEstatus: "A"
				}
			}).then((res)=>{
				this.Contratos=res.data.data.contractlist
			})
		},
		VerificarMap() {
			if (this.Clientes.IdClienteS == 0) {
				this.bus.$emit("actualCordenadas2");
			}
		},
		ListaIconosEmp(){
			this.$http.get('iconosemp/get',{
				params:{IdClienteS:this.Clientes.IdClienteS},
			}).then((res)=>{
				this.ListaIconosEmp=res.data.data.iconosemp;
			})
		},
		ChangeScanning(){
			this.Clientes.CheckCli=0;
			if (this.checkedScanning) {
				this.Clientes.CheckCli=1;
			}
		},
		ChangeMonCliente(){
			this.Clientes.CheckMonCli=0;
			if (this.checkedMonCliente) {
				this.Clientes.CheckMonCli=1;
			}
		},
		FindIcoEmp(){
			this.BuscarIcono=true;
		},
		ElegirIcoEmp(index){
			this.Clientes.IdIconoEmp=this.ListaIconosEmp[index].Imagen;
			this.BuscarIcono=false;
		},
		VolverFormulario(){
			this.BuscarIcono=false;
		},
		EliminarImagen(){
            if (this.$refs.logosucursal.files[0]!=undefined || this.Clientes.LogoSucursal!="") {
                this.$swal({
                title: 'Esta seguro que desea eliminar esta imagen?',
                text: 'No se podra revertir esta acción',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si',
                cancelButtonText: 'No, mantener',
                showCloseButton: true,
                showLoaderOnConfirm: true
                }).then((result) => {
                    if(result.value) {
                        this.$toast.success('Información eliminada');
                        $("#ImagePreviewLogoSucursal").css('background-image','url()');
                        $("#ImagePreviewLogoSucursal").css('background-size','contain');
                        // console.log(this.$refs.logocliente.files);
                        this.$refs.logosucursal.value=null;
                        this.Clientes.LogoSucursal="";
                    } 
                });   
            }
            else{
                this.$toast.info('No hay una imagen para eliminar');
            }
        },
		// ImagenBase64(Imagen){
        //     var canvas = document.createElement("canvas");
        //     canvas.width = Imagen.width;
        //     canvas.height = Imagen.height;
        //     var ctx = canvas.getContext("2d");
        //     ctx.drawImage(Imagen, 0, 0);
        //     var dataURL = canvas.toDataURL("image/png");
        //     return dataURL.replace(/^data:image\/?[A-z]*;base64,/);
        // }
	},
	created() {
		this.ListaIconosEmp();
		this.bus.$off('NewModal_'+this.EmitSeccion);

		if (this.ocliente != undefined) {
			sessionStorage.setItem("IdSaved", JSON.stringify(this.ocliente));
		}

		this.oClienteP = JSON.parse(sessionStorage.getItem("IdSaved"));

		//Este es para modal
		this.bus.$on('NewModal_'+this.EmitSeccion, (data, Id) => {
			this.ConfigLoad.ShowLoader=true;
			this.DesactivarUsuarioMonitoreo=false;
			this.DesactivarMonCliente=false;
			this.AgregarNuevo=true;
			this.VerificarMap();
			//alert(this.ocliente.IdCliente);
			this.poBtnSave.disableBtn = false;
			if (this.pContadorPropiedades<=0) {
				this.DesactivarUsuarioMonitoreo=true;
			}
			if (this.pContadorMonCliente<=0) {
				this.DesactivarMonCliente=true;
			}
			this.bus.$off('Save_'+this.EmitSeccion);
			this.bus.$on('Save_'+this.EmitSeccion, () => {
				this.Guardar();
			});

			this.Limpiar();

			this.Clientes.IdCliente = this.oClienteP.IdCliente;
			this.Clientes.LogoSucursal=this.pRutaCliente+this.oClienteP.LogoCliente;
			if (Id > 0) {
				this.AgregarNuevo=false;
				this.Clientes.IdCliente = Id;
				this.get_one();
				this.Clientes.IdCliente = this.oClienteP.IdCliente;
				this.ContratosAppear=true;
			} else {
				this.ConfigLoad.ShowLoader=false;
				//this.bus.$emit("ActualC",'');
				this.bus.$emit("actualCordenadas2");
			}
			this.bus.$emit("Desbloqueo", false);
		});
		if (this.Id != undefined) {
			this.Clientes.IdCliente = this.Id;
			this.AgregarNuevo=false;
			this.get_one();
			this.ContratosAppear=true;
		}
		// if (this.pRutaIconoEmp!=undefined) {
		// 	this.RutaIconoEmp=this.pRutaIconoEmp;
		// }
	}
};
</script>
