<template>
  <div>
	  <div class="row mt-2">
		  <div class="col-12 col-sm-12 col-md-12 col-lg-12">
			  <h4 class="titulo-04">TAREAS Y MATERIALES</h4>
			  <hr>
		  </div>
	  </div>
	  <div class="row mt-2">
		  <div class="col-md-6 col-lg-6">
			  <label> Tareas</label>
			  <textarea v-model="servicios.Observaciones" class="form-control" rows="5"></textarea>
			  <Cvalidation v-if="errorvalidacion.Tareas" :Mensaje="errorvalidacion.Tareas[0]"/>
		  </div>

		  <div class="col-md-6 col-lg-6">
			  <label>Materiales</label>
			  <textarea v-model="servicios.Materiales" class="form-control" rows="5"></textarea>
		  </div>
	  </div>

    </div>
</template>

<script>
import Cvalidation from '@/components/Cvalidation.vue'
export default {
    name:'Comentarios',
    props:['servicios','errorvalidacion'],
    components:{
        Cvalidation
    },
    data() {
        return {

        }
    },
}
</script>
