<template>
    <div>
        <CHeader :pHeaderProps="HeaderProps">
            <template slot="Contenido">

                <CLoader :pConfigLoad="ConfigLoad">
				    <template slot="BodyFormLoad">


                    <div class="row p-2" v-show="!CancelServicio">
                        <div class="col-12 col-sm-12 mb-2">
                            <p><b>Tiempo Programado:</b></p>
                            <p>De {{ Servicio.Hora_I }} A {{ Servicio.Hora_F }}</p>
                            <div class="border-bottom"></div>
                        </div>
                        <div class="col-12 col-sm-12 mb-2">
                            <p><b>Contacto:</b></p>
                            <p>{{ Servicio.Contacto }}</p>
                            <div class="border-bottom"></div>
                        </div>
                        <div class="col-12 col-sm-12 mb-2">
                            <p><b>Teléfono:</b></p>
                            <p>{{ Servicio.TelCS }}</p>
                            <div class="border-bottom"></div>
                        </div>
                        <div class="col-12 col-sm-12 mb-2">
                            <p><b>Vehículo:</b></p>
                            <p>{{ Servicio.Vehiculo }}</p>
                            <div class="border-bottom"></div>
                        </div>
                        <div class="col-12 col-sm-12 mb-2">
                            <p><b>Cliente:</b></p>
                            <p>{{ Servicio.Client }}</p>
                            <div class="border-bottom"></div>
                        </div>
                        <div class="col-12 col-sm-12 mb-2">
                            <p><b>Propiedad</b></p>
                            <p>{{ Servicio.Sucursal }}</p>
                            <div class="border-bottom"></div>
                        </div>
                        <div class="col-12 col-sm-12 mb-2">
                            <p><b>Dirección:</b></p>
                            <p>{{ Servicio.DireccionCS }}</p>
                            <div class="border-bottom"></div>
                        </div>
                        <div class="col-12 col-sm-12 mb-2">
                            <p><b>Materiales:</b></p>
                            <span style="white-space:break-spaces;">{{ Servicio.Materiales }}</span>
                            <div class="border-bottom"></div>
                        </div>
                        <div class="col-12 col-sm-12  mb-2">
                            <p><b>Tareas:</b></p>
                            <span style="white-space:break-spaces;">{{ Servicio.Observaciones }}</span>
                            <div class="border-bottom"></div>
                        </div>
                        <div class="col-12 col-sm-12 sticky mb-3" v-show="Origen!='Agenda'">
                            <div class="row">
                                <div class="col-12">
                                    <button @click="IniciarServicio" type="button" class="btn btn-01 btn-block btn-grande" :disabled="btnRecargar.blockear || !Online">
                                        <i v-show="btnRecargar.blockear" class="fa fa-spinner fa-pulse fa-1x fa-fw"></i> 
                                        <span v-show="btnRecargar.blockear"> ESPERE...</span>
                                        <span v-show="!btnRecargar.blockear">{{ this.Servicio.EstadoS == 'ABIERTA' ? 'INICIAR' : 'CONTINUAR SERVICIO' }}</span>
                                        
                                        
                                        
                                    </button>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div class="row p-2" v-show="CancelServicio && Origen!='Agenda'">
                        <div class="col-12 col-sm-12">
                            <textarea class="form-control shadow" cols="30" rows="10" v-model="Comentario" placeholder="Escriba aquí el motivo"></textarea>
                        </div>
                    </div>
                    <div class="row mt-3" v-show="CancelServicio && Origen!='Agenda'">
                        <div class="col-12 col-sm-12 text-center">
                            <button @click="Regresar" type="button" class="btn btn-01"><i class="fa fa-times-circle"></i></button>
                        </div>
                        <div class="col-6 col-sm-6 text-center">
                            <button @click="CancelarServicio" type="button" class="btn btn-01"><i class="fa fa-check"></i></button>
                        </div>
                    </div>

                </template>
            </CLoader>
            </template>
        </CHeader>
    </div>
</template>

<script>
import CHeader from "@/views/webapp/components/HeaderWebApp.vue";
import Cvalidation from '@/components/Cvalidation.vue';
export default {
    components:{CHeader,Cvalidation},
    props:['pIdServicio'],
    data() {
        return {
            HeaderProps:{
                ShowLogo:false,
                TextoHeader:'',
                ShowFotoPerfil:false,
                ShowSubHeader:false,
                ShowTecnico:false,
                ShowFechaHoy:false,
                ShowReturn:true,
                ShowMenuDropdown:false,
            },
            errorvalidacion:[],
            IdServicio:'',
            IdTrabajador:'',
            Servicio:{},
            CancelServicio:false,
            Comentario:'',
            ConfigLoad:{
				ShowLoader:true,
				ClassLoad:true,
			},
            btnRecargar: {
                blockear: true,
                mod1 : 'btn btn-01 icon-circul-02',
                mod2 : 'btn btn-01-amarillo icon-circul-02'
            },
            Online:true,
            Origen:''
        }
    },
    methods: {
        Recuperar(){
            this.ConfigLoad.ShowLoader = true;
            this.btnRecargar.blockear = true;
            if (this.Online) {
                this.$http.get('webapp/getservicio',{
                    params:{
                        IdTrabajador:this.IdTrabajador,
                        IdServicio:this.IdServicio
                    }
                }).then((res)=>{
                    this.Servicio=res.data.Datos;
                    this.HeaderProps.TextoHeader=this.Servicio.Folio;
                    this.Servicio.Vehiculo=res.data.datav.Categoria;

                }).finally(()=>{
			    	this.ConfigLoad.ShowLoader = false;
                    this.btnRecargar.blockear = false;
			    });
            }
            else{
                let Servicios=JSON.parse(localStorage.getItem("serviciosoffline"));
                let Index=Servicios.findIndex((element)=>element.IdServicio==localStorage.getItem("idservicio"));
                this.Servicio=Servicios[Index];
                this.ConfigLoad.ShowLoader = false;
                this.btnRecargar.blockear = false;''
                this.HeaderProps.TextoHeader=this.Servicio.Folio;
            }
        },
        Regresar(){
            if (!this.CancelServicio) {
                this.$router.push({name:'ServiciosWebApp',params:{pOrigen:this.Origen,pFecha:sessionStorage.getItem("Fecha")}})
            }
            else{
                this.CancelServicio=false;
            }
        },
        // IniciarServicio(){
        //     if (this.Servicio.EstadoS=='ABIERTA') {
        //         let Hoy=new Date();
        //         let HoraArray=this.Servicio.HoraInicio.split(":");
        //         let Servicio=new Date(Hoy.getFullYear(),Hoy.getMonth(),Hoy.getDate(),HoraArray[0],HoraArray[1],HoraArray[2]);
        //         let diffMs = (Servicio - Hoy);
        //         var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000);
        //         if (diffMins<=10) {
        //             this.$swal({
        //                 title: 'Iniciar Servicio',
        //                 text: '¿Desea iniciar este servicio?',
        //                 type: 'warning',
        //                 showCancelButton: true,
        //                 confirmButtonText: 'Si',
        //                 cancelButtonText: 'No',
        //                 showCloseButton: true,
        //                 showLoaderOnConfirm: true
        //             }).then((result)=>{
        //                 if(result.value){
        //                     let formData=new FormData();
        //                     formData.set('IdTrabajador',this.IdTrabajador);
        //                     formData.set('IdServicio',this.IdServicio);
        //                     formData.set('EstadoS','PENDIENTE');
        //                     formData.set('Estatus','Ocupado');
        //                     this.$http.post(
        //                         'app/updateestatus',formData
        //                     ).then((res)=>{
        //                         this.$toast.success('Servicio iniciado');
        //                         this.$router.push({name:'EquiposServicioWebApp',params:{pServicio:this.Servicio}});
        //                     }).catch((err)=>{
        //                         this.$toast.warning(err.response.data.message);
        //                     });
        //                 }
        //             });
        //         }
        //         else{
        //             this.$toast.warning('El Servicio Debe Iniciarse 10 Minutos Antes');
        //         }
        //     }
        //     else{
        //         this.$router.push({name:'EquiposServicioWebApp',params:{pServicio:this.Servicio}});
        //     }
        // },
        IniciarServicio(){
            if (this.Servicio.EstadoS=='ABIERTA') {
                if (this.IdTrabajador!=this.Servicio.Personal) {
                    this.$toast.warning('Solo el Responsable Puede Iniciar Este Servicio');
                }
                else{
                    let Hoy=new Date();
                    let HoraArray=this.Servicio.HoraInicio.split(":");
                    let Servicio=new Date(Hoy.getFullYear(),Hoy.getMonth(),Hoy.getDate(),HoraArray[0],HoraArray[1],HoraArray[2]);
                    let diffMs = (Servicio - Hoy);
                    var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000);
                    if (diffMins<=10) {
                        this.$router.push({name:'TipoQrWebApp',params:{pServicio:this.Servicio}});
                    }
                    else{
                        this.$toast.warning('El Servicio Debe Iniciarse 10 Minutos Antes');
                    }
                }
            }
            else{
                this.$router.push({name:'EquiposServicioWebApp',params:{pServicio:this.Servicio}});
            }
        },
        IrACancelarServicio(){
            this.CancelServicio=true;
        },
        CancelarServicio(){
            this.$swal({
                title: 'Cancelar Servicio',
                text: '¿Desea cancelar este servicio?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si',
                cancelButtonText: 'No',
                showCloseButton: true,
                showLoaderOnConfirm: true
            }).then((result)=>{
                if(result.value){
                    let formData=new FormData();
                    formData.set('IdTrabajador',this.IdTrabajador);
                    formData.set('IdServicio',this.IdServicio);
                    formData.set('Estatus','Disponible');
                    formData.set('Comentario',this.Comentario);

                    this.$http.post(
                        'webapp/cancelarservicio',formData
                    ).then((res)=>{
                        this.$toast.success('Servicio cancelado');
                        this.$router.push({name:'ServiciosWebApp',params:{pOrigen:this.Origen}});
                    }).catch((err)=>{
                        this.$toast.warning(err.response.data.message);
                    });
                }
            });
        },
        async PingServer(){
            await this.$http.get('webapp/ping',{
                timeout:2000
            }
            ).then(()=>{
                this.Online=true;
            }).catch((err)=>{
                // console.log(err);
                this.Online=false;
                this.Recuperar();
            }).finally(()=>{
                this.Recuperar();
            })
        }
    },
    created() {
        this.bus.$off('EmitReturn');
        this.bus.$on('EmitReturn',()=>{
            this.Regresar();
        });
        if (this.pIdServicio!=undefined) {
            sessionStorage.setItem('IdServicio',this.pIdServicio);
        }
        this.Origen=sessionStorage.getItem('Origen');
        this.IdServicio=sessionStorage.getItem('IdServicio');
        this.IdTrabajador=JSON.parse(sessionStorage.getItem('user')).IdTrabajador;
    },
    mounted() {
        this.PingServer();
        // this.Recuperar();
    },
    destroyed() {
        sessionStorage.removeItem('Origen');
    },

}
</script>

<style>

</style>