<template>
    <div>
        <div class="row mb-3">
            <div class="col-lg-12">
                <label for="">Fecha  *</label>
                <v-date-picker v-model="Nomina.Fecha" :mode="typeCalendar"
					:popover="{
						placement: 'bottom',
						visibility: 'click'
					}"
					:input-props="{
						class: 'form-control cal-black',
						style: 'cursor:pointer;'
					}"
				/>
                <label id="lblmsuser" style="color:red"><Cvalidation v-if="this.errorvalidacion.Fecha" :Mensaje="this.errorvalidacion.Fecha[0]"></Cvalidation></label>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-lg-12">
                <label for="">Archivo</label>
                <div class="custom-file-input-image">
                    <input @change="SubirArchivo()" type="file" accept="application/pdf" ref="file" class="custom-file-input" id="validatedCustomFile" required>
                    <input type="text" v-model="Archivo" class="form-control">
                    <button type="button" class=""><i class="fas fa-paperclip"></i></button>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <label for="">Comentario</label>
                <textarea class="form-control" v-model="Nomina.Comentario" cols="10" rows="10"></textarea>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:"Nomina",
    props:["poBtnSave"],
    data() {
        return {
            Modal:true,
            FormName:'Nomina',
            EmitSeccion:this.poBtnSave.EmitSeccion,
            Nomina:{
                IdNomina:0,
                IdTrabajador:0,
                Fecha:'',
                Comentario:'',
                Archivo:'',
            },
            Archivo:'Elegir Archivo (5MB)',
            errorvalidacion:[]
        }
    },
    methods: {
        Guardar(){
            this.errorvalidacion=[];
            let Fecha='';
            if (this.Nomina.Fecha!='' && this.Nomina.Fecha!=undefined) {
                let day=this.Nomina.Fecha.getDate();
                let month=this.Nomina.Fecha.getMonth()+1;
                let year=this.Nomina.Fecha.getFullYear();
                Fecha=year+'-'+month+'-'+day;   
            }
            let file = this.$refs.file.files[0];
            let formData=new FormData();
            formData.set('IdNomina',this.Nomina.IdNomina);
            formData.set('IdTrabajador',this.Nomina.IdTrabajador);
            formData.set('Comentario',this.Nomina.Comentario);
            formData.set('Fecha',Fecha);
            formData.set('NombreArchivo',this.Nomina.Archivo);
            formData.append('NombreArchivoFile',file);
            this.poBtnSave.toast=0;
            this.poBtnSave.disableBtn=true;
            this.$http.post('nomina/post',formData,{
                headers:{
                    'Content-Type':'multipart/form-data'
                }
            }).then((res)=>{
                this.poBtnSave.disableBtn=false; 
                this.poBtnSave.toast=1;
                $("#Nomina").modal("hide");
                this.bus.$emit('ListaPrincipal');
            }).catch((err)=>{
                this.errorvalidacion=err.response.data.message.errores;
                this.poBtnSave.disableBtn=false;
                this.poBtnSave.toast=2;
            });
        },
        Limpiar(){
            const  input  = this.$refs.file;
            input .type = 'text'
            input .type = 'file';
            this.Nomina={
                IdNomina:0,
                IdTrabajador:0,
                Fecha:'',
                Comentario:'',
                Archivo:'',
            }
            if (localStorage.getItem("fechacalendario")) {
				let NuevaFecha = localStorage.getItem("fechacalendario");
				let formatedDate = NuevaFecha.replace(/-/g, "\/");
				this.Nomina.Fecha = new Date(formatedDate);
			}
            this.Archivo='Elegir Archivo (5MB)';
            this.errorvalidacion=[];
        },
        Recuperar(){
            this.$http.get('nomina/recovery',{
                params:{
                    IdNomina:this.Nomina.IdNomina
                }
            }).then((res)=>{
                const objres=res.data.data.nomina;
                this.Nomina.IdNomina=objres.IdNomina;
                this.Nomina.IdTrabajador=objres.IdTrabajador;
                let formatedDate = objres.FechaInicio.replace(/-/g, "\/");
                this.Nomina.Fecha=new Date(formatedDate);
                this.Nomina.Archivo=objres.Archivo;
                this.Nomina.Comentario=objres.Comentario;
                this.Archivo=objres.Archivo;
            })
        },
        SubirArchivo(){
            const image = this.$refs.file.files[0];

            var FileSize = image.size / 1024 / 1024; // in MB
            if (FileSize > 5) {
                this.$toast.info('Solo se puede subir archivos menores a 5 MB');
                const  input  = this.$refs.file;
                input .type = 'text'
                input .type = 'file';
                return false;
            }
                        
            var allowedExtensions = /(\.pdf|\.PDF)$/i;
            if(!allowedExtensions.exec(image.name)){
                this.$toast.info('Extensiones permitidas .pdf');
                const  input  = this.$refs.file;
                input.type = 'text'
                input.type = 'file';
                this.Archivo='Elegir archivo (5MB)';
                return false;
            }

            this.Archivo=image.name;
        }
    },
    created() {
        this.bus.$off("NewModal_"+this.EmitSeccion);
        this.bus.$on("NewModal_"+this.EmitSeccion,(data,Id,IdTrabajador)=>{
            this.poBtnSave.disableBtn=false;
            this.bus.$off("Save_"+this.EmitSeccion);
            this.bus.$on("Save_"+this.EmitSeccion, () => {
				this.Guardar();
			});
            this.Limpiar();
            if (IdTrabajador!==undefined) {
                this.Nomina.IdTrabajador=IdTrabajador;
            }
            if (Id > 0) {
				this.Nomina.IdVacacion = Id;
				this.Recuperar();
			}
        });
    },
    computed:{
        typeCalendar:function(){
            return 'single';
        }
    }
}
</script>

<style>

</style>