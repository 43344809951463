<template>
    <CHeader :pHeaderProps="HeaderProps">
        <template slot="Contenido">
            <div class="row" @click="ElegirQr(1)">
                <div class="col-12 col-sm-12 justify-content-center d-flex flex-column align-items-center">
                    <h2 class="mt-3 mb-3">Servicio con QR</h2>
                    <img class="img img-fluid img-qr" src="../../../images/tipoqr/QR_Code_example.png" alt="">
                </div>
            </div>
            <div class="row mt-4" @click="ElegirQr(2)">
                <div class="col-12 col-sm-12 justify-content-center d-flex flex-column align-items-center">
                    <h2 class="mt-3 mb-3">Servicio sin QR</h2>
                    <img class="img img-fluid img-qr" src="../../../images/tipoqr/QR-codes-no-qr.png" alt="">
                </div>
            </div>
        </template>
    </CHeader>
</template>

<script>
import CHeader from "@/views/webapp/components/HeaderWebApp.vue";
export default {
    props:["pServicio"],
    name:'TipoQR',
    components:{CHeader},
    data() {
        return {
            HeaderProps:{
                ShowSubHeader:false,
                ShowReturn:true,
                TextoHeader:'Selecciona Una Opción',
                ShowLogo:false,
            },
            Servicio:{},
            IdTrabajador:''
        }
    },
    methods: {
        ElegirQr(Tipo){
            let ParteCadena='';
            if (Tipo==1) {
                ParteCadena=' Con QR';
            }
            else{
                ParteCadena=' Sin QR';
            }
            this.$swal({
                title: 'Iniciar Servicio',
                text: '¿Desea iniciar este servicio'+ParteCadena+'?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si',
                cancelButtonText: 'No',
                showCloseButton: true,
                showLoaderOnConfirm: true
            }).then((result)=>{
                if(result.value){
                    let formData=new FormData();
                    formData.set('IdTrabajador',this.IdTrabajador);
                    formData.set('IdServicio',this.Servicio.IdServicio);
                    formData.set('EstadoS','PENDIENTE');
                    formData.set('TipoQr',Tipo);
                    formData.set('Estatus','Ocupado');
                    this.$http.post(
                        'webapp/setservicioestatus',formData
                    ).then((res)=>{
                        this.$toast.success('Servicio iniciado');
                        this.$router.push({name:'EquiposServicioWebApp',params:{pServicio:res.data.data.servicio}});
                    }).catch((err)=>{
                        this.$toast.warning(err.response.data.message);
                    });
                }
            });
        },
        Regresar(){
            this.$router.push({name:'ServicioDetalleWebApp'});
        }
    },
    created() {
        this.bus.$off('EmitReturn');
        this.bus.$on('EmitReturn',()=>{
            this.Regresar();
        });
        if (this.pServicio!=undefined) {
            sessionStorage.setItem('servicio',JSON.stringify(this.pServicio));
        }
        this.Servicio=JSON.parse(sessionStorage.getItem('servicio'));
        this.IdTrabajador=JSON.parse(sessionStorage.getItem('user')).IdTrabajador;
    },
    mounted() {
        
    },
}
</script>

<style>

</style>