<template>
    <div>
        <CLoader :pConfigLoad="ConfigLoadForm">
            <template slot="BodyFormLoad">
                <div class="row  justify-content-center">
                    <!-- <div :class="RolUsuario=='Admin' || IdUsuario!=objoportunidad.IdVendedor ? 'col-lg-4 mb-3' : 'col-lg-6 mb-3'"> -->
                    <div class="col-lg-4 mb-3">
                        <label>Nombre de Oportunidad *</label>
                        <input :disabled="RolUsuario!='Admin' && IdUsuario > 0 && IdUsuario!=objoportunidad.IdVendedor && this.Origen=='activa'" class="form-control" v-model=" objoportunidad.Nombre" placeholder="Nombre">
                        <Cvalidation v-if="this.errorvalidacion.Nombre" :Mensaje="errorvalidacion.Nombre[0]"></Cvalidation>
                    </div>
        
                    <!-- <div v-if="RolUsuario=='Admin' || objoportunidad.IdVendedor != IdUsuario" class="col-lg-4 mb-3"> -->
                    <div class="col-lg-4 mb-3">
                        <label>Responsable *</label>
                        <select :disabled="RolUsuario!='Admin' && IdUsuario > 0" @change="listartipo(objoportunidad.IdVendedor);ListarResponsablesSecundarios();"  v-model="objoportunidad.IdVendedor" class="form-control">
                            <option value="">Seleccione una opción</option>
                            <option v-for="(item, index) in ListaVendedores" :key="index" :value="item.IdUsuario">{{item.Nombre}}</option>
                        </select>
                        <Cvalidation v-if="this.errorvalidacion.Vendedor" :Mensaje="errorvalidacion.Vendedor[0]"></Cvalidation>
                    </div>

                    <!-- <div :class="RolUsuario=='Admin' || IdUsuario!=objoportunidad.IdVendedor ? 'col-lg-4 mb-3' : 'col-lg-6'"> -->
                    <div class="col-lg-4 mb-3">
                        <label>Responsables Secundarios <small> (max 4 responsables)</small></label>
                        <!-- <vue-tags-input placeholder="Responsables Secundarios"/> -->
                        <treeselect 
                            :multiple="true"
                            :disabled="RolUsuario!='Admin' && IdUsuario != objoportunidad.IdVendedor && this.Origen=='activa'"
                            placeholder="Responsables Secundarios" 
                            :options="ListaResponsablesSecundarios"
                            v-model="objoportunidad.RespSecundarios"
                            @input="BloquearRespSecundarios">
                        </treeselect>
                    </div>
            
                    <div :class="BuscarCliente ? 'col-lg-10 mb-3':'col-lg-4 mb-3'">
                        <label >Cliente *</label>
                        <input  class="form-control" readonly="readonly" v-model=" objoportunidad.Cliente" placeholder="Cliente">
                        <Cvalidation v-if="this.errorvalidacion.Cliente" :Mensaje="errorvalidacion.Cliente[0]"></Cvalidation>
                    </div>
            
                    <div v-if="BuscarCliente" class="col-lg-2 mb-3">
                        <div class="form-inline">
                            <button @click="ListarCli"  data-toggle="modal" data-target="#OporModalCliente"  data-backdrop="static" type="button" class="btn btn-01 search mt-3b">Buscar</button>
                        </div>
                    </div>
                    
                    <div :class="BuscarCliente ? 'col-lg-10 mb-3':'col-lg-4 mb-3'">
                        <label>Propiedad *</label>
                        <input  class="form-control" readonly="readonly" v-model=" objoportunidad.Sucursal" placeholder="Sucursal">
                        <Cvalidation v-if="this.errorvalidacion.Sucursal" :Mensaje="errorvalidacion.Sucursal[0]"></Cvalidation>
                    </div>
                    <div v-if="BuscarCliente" class="col-lg-2">

                    </div>
                    <div :class="BuscarCliente ? 'col-lg-6 mb-3':'col-lg-4 mb-3'">
                        <label>Contacto *</label>
                        <select :disabled="RolUsuario!='Admin' && IdUsuario > 0 && IdUsuario!=objoportunidad.IdVendedor" v-model="objoportunidad.IdContacto" class="form-control">
                            <option value="">Seleccione un Contacto</option>
                            <option v-for="(item,index) in ListaContactos" :key="index" :value="item.IdContacto">{{ item.Nombre }}</option>
                        </select>
                    </div>
        
                    <!-- <div v-if="RolUsuario=='Admin'" class="col-lg-6 mb-3"> -->
                        <div :class="BuscarCliente ? 'col-lg-6 mb-3' : 'col-lg-3 mb-3'">
                        <label>Técnico</label>
                        <select :disabled="RolUsuario!='Admin' && IdUsuario > 0 && IdUsuario!=objoportunidad.IdVendedor && this.Origen=='activa'" v-model="objoportunidad.IdTrabajador" class="form-control">
                            <option value="">Seleccione una opción</option>
                            <option v-for="(item, index) in ListaTecnicos" :key="index" :value="item.IdTrabajador">{{item.Nombre}}</option>
                        </select>
                        <!-- <Cvalidation v-if="this.errorvalidacion.Vendedor" :Mensaje="errorvalidacion.Vendedor[0]"></Cvalidation> -->
                    </div>
        
                    <div class="col-lg-3 mb-3">
                        <label for="">Folio de Servicio (Opcional)</label>
                        <input :disabled="RolUsuario!='Admin' && IdUsuario > 0 && IdUsuario!=objoportunidad.IdVendedor && this.Origen=='activa'" class="form-control" placeholder="Folio" type="text" v-model="objoportunidad.Folio">
                    </div>
                    <div :class="BuscarCliente ? 'col-lg-3 mb-3' : 'col-lg-2 mb-3'">
                        <label for="">Folio de Oportunidad</label>
                        <input readonly class="form-control" placeholder="Folio" type="text" v-model="objoportunidad.FolioOportunidad">
                    </div>
                    <div :class="BuscarCliente ? 'col-lg-3 mb-3' : 'col-lg-2 mb-3'">
                        <label for="">Valor Aprox. Oportunidad</label>
                        <vue-numeric
                            class="form-control "
                            currency="$"
                            separator=","
                            placeholder="$0"
                            :precision="0"
                            v-model="objoportunidad.MontoPropuesta"
                            :disabled="RolUsuario!='Admin' && IdUsuario > 0 && IdUsuario!=objoportunidad.IdVendedor && this.Origen=='activa'"
                        ></vue-numeric>
                    </div>
                    <div :class="BuscarCliente ? 'col-lg-3' : 'col-lg-2'">
                        <label>Estatus *</label>
                        <select :disabled="RolUsuario!='Admin' && IdUsuario > 0 && IdUsuario!=objoportunidad.IdVendedor && this.Origen=='activa'" id="" class="form-control" v-model="objoportunidad.Estatus">
                            <option :value="''">Seleccione un estado</option>
                            <option :value="'1'">Activa</option>
                            <option :value="'4'">Enviada</option>
                            <option :value="'2'">Ganada</option>
                            <option :value="'3'">Perdida</option>
                        </select>
                        <Cvalidation v-if="this.errorvalidacion.Estado" :Mensaje="errorvalidacion.Estado[0]"></Cvalidation>
                    </div>
        
                    <div class="col-lg-12">
                        <label for="">Descripción</label>
                        <textarea :disabled="RolUsuario!='Admin' && IdUsuario > 0 && IdUsuario!=objoportunidad.IdVendedor && this.Origen=='activa'" cols="30" rows="4" v-model="objoportunidad.Descripcion" class="form-control"></textarea>
                    </div>
        
                </div>
                <!-- <div class="form-row" v-if="objoportunidad.IdOportunidad!=0"> -->
                    <div class="form-row" v-if="MostrarSegundaTabla">
                    <table class="table-01 text-nowrap mt-2">
                        <thead>
                            <tr>
                                <th>Actividad</th>
                                <th>Fecha</th>
                                <th>Tipo</th>
                                <th class="text-right">
                                    <!-- <button
                                    v-show="RolUsuario=='Admin' || IdUsuario==objoportunidad.IdVendedor"
                                        v-b-tooltip.hover.left
                                        type="button"
                                        data-backdrop="static"
                                        data-toggle="modal"
                                        data-target="#Seguimiento"
                                        data-keyboard="false"
                                        class="mr-2 btn-obs"
                                        title="Agregar actividad"
                                        @click="NuevaActividad(0)"
                                        >
                                        <i
                                        class="fas fa-plus"></i>
                                        </button> -->
                                        <button
                                        v-b-tooltip.hover.left
                                        type="button"
                                        data-backdrop="static"
                                        data-toggle="modal"
                                        data-target="#Seguimiento"
                                        data-keyboard="false"
                                        class="mr-2 btn-obs"
                                        title="Agregar actividad"
                                        @click="NuevaActividad(0)"
                                        >
                                        <i
                                        class="fas fa-plus"></i>
                                        </button>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item,index) in ListaActividades" :key="index">
                                <td>{{ $limitCharacters(item.Actividad,40) }}</td>
                                <td> <i class="fa fa-calendar"></i> {{ $getCleanDate(item.Fecha,false) }}</td>
                                <td>{{ $capitalize(item.NombreTipo) }}</td>
                                <td class="text-right">
                                    <button
                                        v-b-tooltip.hover.left
                                        type="button"
                                        data-backdrop="static"
                                        data-toggle="modal"
                                        data-target="#Seguimiento"
                                        data-keyboard="false"
                                        class="mr-1 btn btn-table"
                                        title="Cambiar actividad"
                                        @click="NuevaActividad(item.IdSeguimientoCliente)"
                                        >
                                        <i
                                        class="fa fa-pencil"></i>
                                    </button>
                                        
                                    <button v-if="item.Archivo!=''"
                                        v-b-tooltip.hover.left
                                        type="button"
                                        data-backdrop="static"
                                        data-keyboard="false"
                                        class="btn btn-table mr-1"
                                        title="consultar evidencia 1"
                                        @click="open_file(item.Archivo)"
                                        >
                                        <i 
                                        class="fas fa-file-pdf"></i>
                                    </button>
        
                                    <button v-if="item.Archivo2!=''"
                                        v-b-tooltip.hover.left
                                        type="button"
                                        data-backdrop="static"
                                        data-keyboard="false"
                                        class="btn btn-table mr-1"
                                        title="consultar evidencia 2"
                                        @click="open_file(item.Archivo2)"
                                        >
                                        <i 
                                        class="fas fa-file-pdf"></i>
                                    </button>
        
                                    <button
                                        v-b-tooltip.hover.left
                                        type="button"
                                        data-backdrop="static"
                                        data-keyboard="false"
                                        class="btn btn-table-da mr-1"
                                        title="Borrar"
                                        @click="EliminarActividad(item.IdSeguimientoCliente)"
                                        >
                                        <i 
                                        class="fas fa-trash"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <Cpagina :Filtro="Filtro" :Entrada="Filtro.Entrada" @Pagina="Filtrar"></Cpagina>
                </div>
            </template>
        </CLoader>
        
        <Modal :NameModal="'Seguimiento'" :pEmitSeccion="'Seguimiento'" :Showbutton="false" :size="'modal-md'" :Nombre="NameList" :poBtnSave="poBtnSave2">
            <template slot="Form">
                <FormActividades :pIdUsuario="IdUsuario" :pOrigen="'activas'" :oOportunidad="objoportunidad" :poBtnSave="poBtnSave2"></FormActividades>
            </template>
        </Modal>
    </div>
    
</template>
<script>
//El props Id es cuando no es modal y se mando con props
//El export de btnsave es por si no se usa el modal
import Cbtnsave from '@/components/Cbtnsave.vue'
import Cvalidation from '@/components/Cvalidation.vue'
import Modal from '@/components/Cmodal.vue'
import FormActividades from '../oportunidades/calendario/form.vue'
import Cpagina from '@/components/Cpagina.vue';
import CLoader from "@/components/CLoader.vue";
// import Clist from '@/components/Clist.vue'

export default {
    name:'Form',
        // props:['IdOportunidad','poBtnSave','OClienteS'],
    props:['poBtnSave','pOrigen'],
    components:{
        CLoader,
        Cbtnsave,
        Cvalidation
    },
    data() {
        return {
            Modal:true,//Sirve pra los botones de guardado
            FormName:'objoportunidad',//Sirve para donde va regresar
            NameList:'Programar Actividad',
            poBtnSave2:{
                isModal:true,
                toast:0,
                disableBtn:false,
                nombreModal:"Seguimiento",
                EmitSeccion:"Seguimiento"
            },
            Pag:0,
            Filtro:{
                Nombre:'',
                TotalItem:0,
                Pagina:1,
                Entrada:8
            },
            EmitSeccion:this.poBtnSave.EmitSeccion,
            RolUsuario:'',
            IdUsuario:'',
            objoportunidad:{
                Nombre:"",
                IdVendedor:"",
                IdServicio:"",
                IdTrabajador:"",
                IdClienteS:"",
                IdTipoP:"",
                IdOportunidad:'',
                Sucursal:'',
                Cliente:'',
                Estatus:"",
                Folio:'',
                IdCliente:"",
                MontoPropuesta:"",
                Origen:1,
                Descripcion:"",
                IdContacto:"",
                RespSecundarios:[],
                FolioOportunidad:""
            },
            ConfigLoadForm:{
                ShowLoader: false,
				ClassLoad:  true,
            },
            Ruta:'',
            urlApi:"crmoportunidad/recovery",
            urlApiVendedor:"trabajador/ListTrabRolQuery",
            urlApiVendedorNuevo:"vendedores/get",
            errorvalidacion:[],
            ListaVendedores:[],
            ListaResponsablesSecundarios:[],
            ListaContactos:[],
            Listatipos:[],
            ListaActividades:[],
            ListaTecnicos:[],
            BndResponsablesCompletos:false,
            perfil :['Vendedor','Gerente de ventas'],
            BuscarCliente:true,
            oClienteS:{},
            MostrarSegundaTabla:false,
            Origen:'',
        }
    },
    components:{
        Cbtnsave,
        Cvalidation,
        FormActividades,
        Modal,
        Cpagina
    },
    methods :
    {
        NuevaActividad(Id){
            this.NameList=this.objoportunidad.Nombre;
            if (Id!=0) {
                this.bus.$emit('NewModal_Seguimiento',true,Id);
            }
            else{
                this.bus.$emit('NewModal_Seguimiento',true);
            }
            
        },
       async Guardar()
        {
        //     if (Object.keys(this.OClienteS)!=0) {
        //         if (this.objoportunidad.IdOportunidad==0) {
        //             let Obj=JSON.parse(sessionStorage.getItem("IdSaved"));
		// 	        Obj.Oportunidades++;
		// 	        sessionStorage.setItem("IdSaved",JSON.stringify(Obj));   
        //         }
        // }
            //deshabilita botones
            this.poBtnSave.toast=0;
            this.poBtnSave.disableBtn=true;
            let formData = new FormData();
            if (this.Origen=="oportunidad") {
                formData.set('IdOportunidad',0);    
            }
            else{
                formData.set('IdOportunidad',this.objoportunidad.IdOportunidad);
            }
            formData.set('Nombre',this.objoportunidad.Nombre);
            if (this.RolUsuario!="Admin") {
                formData.set('IdVendedor',JSON.parse(sessionStorage.getItem("user")).IdUsuario);
            }
            else{
                formData.set('IdVendedor',this.objoportunidad.IdVendedor);
            }
            formData.set('IdClienteS',this.objoportunidad.IdClienteS);
            formData.set('IdTipoP',this.objoportunidad.IdTipoP);
            formData.set('IdCliente',this.objoportunidad.IdCliente);
            formData.set('IdTrabajador',this.objoportunidad.IdTrabajador);
            formData.set('IdServicio',this.objoportunidad.IdServicio);
            formData.set('Folio',this.objoportunidad.Folio);
            formData.set('Estatus',this.objoportunidad.Estatus);
            formData.set('MontoPropuesta',this.objoportunidad.MontoPropuesta);
            formData.set('Origen',this.objoportunidad.Origen);
            formData.set('Descripcion',this.objoportunidad.Descripcion);
            formData.set('IdContacto',this.objoportunidad.IdContacto);
            formData.set('RespSecundarios',JSON.stringify(this.objoportunidad.RespSecundarios));

            await this.$http.post(
                'crmoportunidad/post',
                formData,
                {
                headers: {
                    'Content-Type': 'multipart/form-data'

                }
                },
            ).then( (res) => {
                // MODIFICAR LA VARIABLE DE SESION EN CASO DE ESTAR DESDE CONTACTO

                this.poBtnSave.disableBtn=false;
                this.poBtnSave.toast=1;
                $('#oportunidadservicio').modal('hide');
                this.bus.$emit('List');
                if(this.Origen == 'oportunidad'){
                    this.$router.push('activas')
                }
            }).catch( err => {
                this.errorvalidacion=err.response.data.message.errores;
                this.poBtnSave.disableBtn=false;
                this.poBtnSave.toast=2;
            });
        },
        Limpiar()
        {
            this.objoportunidad={
                Nombre:"",
                IdVendedor:"",
                IdServicio:"",
                IdTrabajador:"",
                IdClienteS:"",
                IdTipoP:"",
                IdOportunidad:'',
                Sucursal:'',
                Cliente:'',
                Estatus:"",
                Folio:'',
                IdCliente:"",
                MontoPropuesta:"",
                Origen:1,
                Descripcion:"",
                IdContacto:"",
                RespSecundarios:[],
                FolioOportunidad:''
            };
            this.errorvalidacion=[""],
            this.ListaActividades=[],
            this.Ruta='',
            this.BuscarCliente=true,
            this.BndResponsablesCompletos=false;
            this.ListaContactos=[];
            this.ListaResponsablesSecundarios=[];
        },
        get_one()
        {
            this.ConfigLoadForm.ShowLoader=true;
            if (this.Origen=="oportunidad") {
                this.$http.get('servicio/recovery',{
                    params:{
                        IdServicio:this.objoportunidad.IdOportunidad
                    }
                }).then((res)=>{
                    this.objoportunidad.Folio=res.data.data.servicio.Folio;
                    this.objoportunidad.IdCliente=res.data.data.servicio.IdCliente;
                    this.objoportunidad.IdClienteS=res.data.data.servicio.IdClienteS;
                    this.objoportunidad.Sucursal=res.data.data.servicio.Sucursal;
                    this.objoportunidad.Cliente=res.data.data.servicio.Client;
                    this.objoportunidad.IdServicio=res.data.data.servicio.IdServicio;
                    this.objoportunidad.Descripcion=res.data.data.servicio.DescripOportunidad;
                    this.ListaUsuariosApp();    
                    this.MostrarSegundaTabla=false;
                    this.BuscarCliente=false;
                    this.ListarContactos();
                }).finally(()=>{
                    this.ConfigLoadForm.ShowLoader=false;
                })
            }
            else{
                this.$http.get(
                    this.urlApi,
                    {
                        params:{IdOportunidad: this.objoportunidad.IdOportunidad}
                    }
                ).then( (res) => {
                    this.objoportunidad.IdOportunidad= res.data.data.oportunidad.IdOportunidad;
                    this.objoportunidad.IdTrabajador=res.data.data.oportunidad.IdTrabajador;
                    this.objoportunidad.IdServicio=res.data.data.oportunidad.IdServicio;
                    this.objoportunidad.Nombre=res.data.data.oportunidad.Nombre;
                    this.objoportunidad.IdTipoP=res.data.data.oportunidad.IdTipoP;
                    this.objoportunidad.IdVendedor=res.data.data.oportunidad.IdVendedor;
                    this.listartipo(this.objoportunidad.IdVendedor);
                    this.objoportunidad.IdClienteS=res.data.data.oportunidad.IdClienteS;
                    this.objoportunidad.Sucursal=res.data.data.oportunidad.Sucursal;
                    this.objoportunidad.Cliente=res.data.data.oportunidad.Cliente;
                    this.objoportunidad.IdCliente=res.data.data.oportunidad.IdCliente;
                    this.objoportunidad.Folio=res.data.data.oportunidad.Folio;
                    this.objoportunidad.MontoPropuesta=res.data.data.oportunidad.MontoPropuesta;
                    this.objoportunidad.Descripcion=res.data.data.oportunidad.Descripcion;
                    this.objoportunidad.IdContacto=res.data.data.oportunidad.IdContacto;
                    this.objoportunidad.FolioOportunidad=res.data.data.oportunidad.FolioOportunidad;
                    this.ListaContactos=res.data.data.contactos;
                    this.ListarResponsablesSecundarios();
                    this.objoportunidad.RespSecundarios=res.data.data.responsables;
                    // MARCAR EL ESTATUS DE LA OPORTUNIDAD
                    switch (res.data.data.oportunidad.Estado) {
                        case "Activa":
                            this.objoportunidad.Estatus=1;
                            break;
                        case "Ganada":
                            this.objoportunidad.Estatus=2;
                            break;
                        case "Perdida":
                            this.objoportunidad.Estatus=3;
                            break;
                        case "Enviada":
                            this.objoportunidad.Estatus=4;
                            break;
                    }
                    if (this.objoportunidad.IdServicio!=0) {
                        this.BuscarCliente=false;
                    }
                    
                }).finally(()=>{
                    this.ConfigLoadForm.ShowLoader=false;
                    this.ObtenerListaActividades();
                });
                this.MostrarSegundaTabla=true;
            }
            
        },
        async ListarContactos(){
            await this.$http.get('contactos/getSimple',{
                params:{
                    IdCliente:this.objoportunidad.IdCliente,
                    IdClienteS:this.objoportunidad.IdClienteS,
                }
            }).then((res)=>{
                this.ListaContactos=res.data.data.Contactos
            });
        },
        ListarCli()
        {
            this.$emit('Listar');
        },
        SeleccionarCliente(objeto,obj)
        {
            this.objoportunidad.IdClienteS=objeto.IdClienteS;
            this.objoportunidad.Sucursal=objeto.Nombre;
            this.objoportunidad.Cliente=obj.Nombre;
            this.objoportunidad.IdCliente=obj.IdCliente;
            this.ListarContactos();
        },
        ListaVendedor()
        {
            this.$http.get(
                this.urlApiVendedorNuevo,
                {
                    params:{
						RegEstatus: 'A'
					}
                }
            ).then( (res) => {
                let Mapeado=res.data.data.Vendedores.map((Element)=>{
                    if (Element.IdUsuario==null) {
                        Element.IdUsuario=Element.TrabajadorUsuario,
                        Element.Nombre=Element.NombreAdmin
                        return Element;
                    }
                    else{
                        return Element;
                    }
                });
                this.ListaVendedores=Mapeado;
            //   this. ListaVendedores = res.data.data.Vendedores;
            //   let UsuarioLogueado = JSON.parse(sessionStorage.getItem("user"));
            //   let Index=this.ListaVendedores.findIndex((Element)=>UsuarioLogueado.IdUsuario==Element.IdUsuario);
            //   if (Index==-1) {
            //     let Obj={
            //         IdUsuario:UsuarioLogueado.IdUsuario,
            //         Nombre:UsuarioLogueado.Nombre+ ' '+UsuarioLogueado.Apellido
            //     }
            //     this.ListaVendedores.unshift(Obj);
            //   }
            });
        },
        ListarResponsablesSecundarios(){
            this.ListaResponsablesSecundarios=this.ListaVendedores.filter((element)=>{
                return element.IdUsuario!=this.objoportunidad.IdVendedor;
            });

            this.ListaResponsablesSecundarios=this.ListaResponsablesSecundarios.map((element)=>{
                return {
                    id:element.IdUsuario,
                    label:element.Nombre,
                    isDisabled:false
                }
            })
        },
        BloquearRespSecundarios(){
            if (this.objoportunidad.RespSecundarios.length>=4) {
                this.ListaResponsablesSecundarios.forEach((element)=>{
                    let index=this.objoportunidad.RespSecundarios.findIndex((Resp)=>element.id==Resp);
                    if (index==-1) {
                        element.isDisabled=true;
                    }
                })
                this.BndResponsablesCompletos=true;
            }
            if (this.BndResponsablesCompletos && this.objoportunidad.RespSecundarios.length<4) {
                this.BndResponsablesCompletos=false;
                this.ListaResponsablesSecundarios.forEach((element)=>{
                    element.isDisabled=false;
                })
            }
        },
        ListaUsuariosApp(){
            let urlApi='';
            urlApi=this.objoportunidad.IdServicio==0 ? 'oportunidades/trabajadores/get': 'oportunidades/trabajadoresxservicio/get';
            this.$http.get(urlApi,{
                params:{
                    IdServicio:this.objoportunidad.IdServicio
                }
            }).then((res)=>{
                this.ListaTecnicos=res.data.data.Trabajadores;
            })
        },
        listartipo(Id)
        {   if (Id>0)
            {
                this.Listatipos = [];
                this.$http.get(
                    'crmprocesovendedor/listasig',
                    {
                        params:{IdTrabajador: Id}
                    }
                ).then( (res) => {
                    let Listatipos2= res.data.data.asignados;

                    Listatipos2.forEach(element => {
                        if(element.Estatus === 'true'){
                        this.Listatipos.push(element);
                        }
                    });
                });
            }
        },
        ObtenerListaActividades(){
            this.$http.get('crm/actividadoportunidad/get',{
                params:{
                    IdOportunidad:this.objoportunidad.IdOportunidad,
                    Entrada:this.Filtro.Entrada,
                    pag:this.Filtro.Pagina,
                    IdTrabajador:this.RolUsuario=='Admin' || this.IdUsuario==this.objoportunidad.IdVendedor ? '' : this.IdUsuario
                }
            }
            ).then((res)=>{
                this.ListaActividades=res.data.data.actividades;
                this.Filtro.Entrada=res.data.data.pagination.PageSize;
                this.Filtro.TotalItem=res.data.data.pagination.TotalItems;
                this.Ruta=res.data.ruta;
            });
        },
        open_file(Nombre) {
			let pdfWindow = window.open(this.Ruta + Nombre);
			pdfWindow.document.write(
				"<iframe width='100%' height='100%' src='" +
					this.Ruta +
					Nombre +
					"'></iframe>"
			);
		},
        Filtrar(){
            this.ObtenerListaActividades();
        },
        EliminarActividad(Id){
            this.$swal({
				title: "Esta seguro que desea eliminar este dato?",
				text: "No se podra revertir esta acción",
				type: "warning",
				showCancelButton: true,
				confirmButtonText: "Si",
				cancelButtonText: "No, mantener",
				showCloseButton: true,
				showLoaderOnConfirm: true
			}).then(result => {
				if (result.value) {
					this.$toast.success("Información eliminada");

					this.$http.delete("crmseguimiento/" + Id).then(res => {
						this.bus.$emit("ListaOportunidadxActividad");
					});
				}
			});
        }
    },
    created() {
        this.ListaVendedor();
        this.ConfigLoadForm.ShowLoader=true;
        this.RolUsuario=JSON.parse(sessionStorage.getItem("user")).Perfil;
        this.IdUsuario=JSON.parse(sessionStorage.getItem("user")).IdUsuario;
        this.Origen=this.pOrigen;
        // if (Object.keys(this.OClienteS)!=0) {
        //     this.oClienteS=this.OClienteS;
        //     this.BuscarCliente=false;
        // }
        this.bus.$off('SeleccionarClienteOportunidad');
        this.bus.$on('SeleccionarClienteOportunidad',(oSucursal,ocliente)=>
        {
            this.SeleccionarCliente(oSucursal,ocliente);
        });
        this.bus.$off('ListaOportunidadxActividad');
        this.bus.$on('ListaOportunidadxActividad',()=>{
            this.ObtenerListaActividades();
        })
        this.bus.$off('NewModal_'+this.EmitSeccion);

        //Este es para modal
        this.bus.$on('NewModal_'+this.EmitSeccion,(data,Id)=>
        {
            this.poBtnSave.disableBtn=false;
            this.bus.$off('Save_'+this.EmitSeccion);
            this.bus.$on('Save_'+this.EmitSeccion,()=>
            {
                this.Guardar();
            });

            this.Limpiar();
            if (!this.BuscarCliente) {
                this.objoportunidad.IdClienteS=this.oClienteS.IdClienteS;
                this.objoportunidad.Sucursal=this.oClienteS.Nombre;
                this.objoportunidad.Cliente=this.oClienteS.Empresa;
                this.objoportunidad.IdCliente=this.oClienteS.IdCliente;
                this.ListarContactos();
            }
            if (this.Origen=='oportunidad') {
                if (this.RolUsuario!='Admin') {
                    this.objoportunidad.IdVendedor=this.IdUsuario;
                    this.ListarResponsablesSecundarios();
                }
            }
            if (Id>0)
            {
                this.objoportunidad.IdOportunidad=Id;
                this.get_one();
            }
            else{
                this.ConfigLoadForm.ShowLoader=false;
            }
            this.bus.$emit('Desbloqueo',false);
            this.ListaUsuariosApp();
        });

        if (this.Id!=undefined)
        {
            this.objoportunidad.IdOportunidad=this.Id;
            this.get_one();
        }
        else{
            // this.ConfigLoadForm.ShowLoader=false;
        }
    }
}
</script>
