<template>
    <div>
        <CHeader :pHeaderProps="HeaderProps">
            <template slot="Contenido">
                <CLoader :pConfigLoad="ConfigLoad">
                    <template slot="BodyFormLoad">
                        <div v-show="ListDocumentos.length > 0" class="row mt-3" v-for="(item,index) in ListDocumentos" :key="index" @click="VerPDFDocumentos(item.NombreArchivo)">
                            <div class="col-12 col-sm-12">
                                <div class="card card-01 shadow">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-3 col-sm-3 position-relative">
                                                <div class="icon-circul">
                                                    <i class="fa fa-file-pdf fa-2x" style="padding-top:28%;"></i>
                                                </div>
                                            </div>
                                            <div class="col-9 col-sm-9">
                                                <p><b>{{ item.Titulo }}</b></p>
                                                <p><span style="white-space:break-spaces">{{ item.Descripcion }}</span></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-show="ListDocumentos.length == 0"  class="row mt-3">
                            <div class="col-12 col-sm-12 text-center mt-3">
                                <h2>Sin Documentos.</h2>
                            </div>
                        </div>
                    </template>
                </CLoader>
            </template>
        </CHeader>
    </div>
</template>

<script>
import CHeader from "@/views/webapp/components/HeaderWebApp.vue";
export default {
    props:['pEquipo'],
    components:{CHeader},
    data() {
        return {
            HeaderProps:{
                ShowSubHeader:true,
                ShowTecnico:false,
                ShowFechaHoy:false,
                ShowMenuDropdown:false,
                TextoCentroSubHeader:'',
                ShowFotoPerfil:false,
                ShowReturn:true,
                TextoHeader:'Manuales del Equipo',
                ShowLogo:false,
                TextoCentroSubHeader:''
            },
            ConfigLoad:{
				ShowLoader:true,
				ClassLoad:false,
			},
            ListDocumentos:[],
            Equipo:{}
        }
    },
    methods: {
        async Lista(){
            this.ConfigLoad.ShowLoader=true;
            this.ConfigLoad.ClassLoad=false;
            await this.$http.get('manuales/get',{
                params:{
                    IdActivo:this.Equipo.ActivoId,
                    Tipo:1
                }
            }).then((res)=>{
                this.ListDocumentos=res.data.data.documentos
            }).finally(()=>{
                this.ConfigLoad.ShowLoader=false;
                this.ConfigLoad.ClassLoad=true;
            });
        },
        Regresar(){
            this.$router.push({name:'EquiposServicioWebApp'});
        },
        VerPDFDocumentos(NombreArchivo){
			let archivo = NombreArchivo;
			window.open(
				archivo,
				"_blank",
				"toolbar=1, scrollbars=1, resizable=1, width=" +1015 +", height=" +800
			);
		},
    },
    created() {
        this.bus.$off('EmitReturn');
        this.bus.$on('EmitReturn',()=>{
            this.Regresar();
        })
    },
    mounted() {
        this.Equipo=this.pEquipo;
        this.HeaderProps.TextoCentroSubHeader=this.Equipo.Nequipo;
        this.Lista();
    },
}
</script>

<style>
.icon-servicio {
    width: 58px;
    height: 58px;
    padding: 0px;
    margin: auto;
 
}
</style>