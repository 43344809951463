<template>
    <div>
        <section class="container-fluid mt-2">
            <Menu :isInOportunidad="true" :pShowReturn="false" :isInContactos="true" :pSitio="NombreSeccion">
            </Menu>

            <div class="row">
                <div class="col-md-12 col-lg-12 col-xl-12 mt-2">
                    <Clist :isCrm="true" :pShowBtnAdd="ShowBtns" :ShowHead="false" @FiltrarC="Lista" :Filtro="Filtro" :regresar="false"  :Nombre="NameList"  :isModal="EsModal" :pConfigLoad="ConfigLoad" :isContactos="true">
                        <template slot="Botonnuevo">
                            <div class="col-md-12 col-lg-2 mt-2 text-right p-0">
                                <!-- <button v-if="disableHouseAccount" type="button" data-toggle="modal" data-target="#ModalNewUser"  data-backdrop="static" data-keyboard="false" class="btn btn-01 mr-1" @click="houseAccount()">House Account</button> -->
                                <button type="button" data-toggle="modal" :data-target="'#'+NombreSeccion"  data-backdrop="static" data-keyboard="false" class="btn btn-pink mr-3" @click="Nuevo()"><i class="far fa-plus-circle"></i> Nuevo</button>   
                            </div>
                        </template>
                        <template slot="Filtros">

                        </template>
                        <template slot="cards">
                            <div class="row row-cols-md-4 row-cols-lg-4 row-cols-xl-2 mt-3">
                                <div v-for="(lista,key,index) in ListaProyectos" :key="index" class="col-md-4 col-lg-4 col-xl-2 card-group">
                                    <div class="card mb-3 tarjetacliente" style="border:2px solid #0F3F87;cursor:pointer;">
                                        <img height="160px" v-if="lista.Imagen!=null && lista.Imagen!=''" class="card-img-top" :src="RutaImagenProyecto+lista.Imagen" alt=""/>
                                        <img height="160px" v-else-if="lista.Imagen=='' && lista.LogoSucursal!=''" class="card-img-top" :src="RutaImagenClienteSucursal+lista.IdClienteS+'/'+lista.LogoSucursal" alt=""/>
                                        <img height="160px" v-else-if="lista.Imagen=='' && lista.IdCliente!=0" class="card-img-top" :src="RutaImagenCliente+lista.LogoCliente" alt=""/>
                                        <img height="160px" v-else class="card-img-top" src="" alt="" style="background-color:#b4b4b4;padding:75px"/>
                                        <div class="card-body pt-2">
                                            <div class="row mt-1">
                                                <div class="col-lg-12">
                                                    <h6 class="m-0">
                                                        {{lista.Nombre.toUpperCase() }}
                                                    </h6>
                                                    {{ lista.Dirección }}
                                                    <div class="mb-0 mr-0 ml-0">
                                                        <b>Personal Asignado: </b> {{ lista.CantidadPersonal }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="editar_contactos dropright">
                                        <button type="button" style="background:rgba(0,0,0,0); border:none;"
                                            title="Menu"
                                            id="dropdownMenuButton"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                            >
                                                <i class="fa fa-ellipsis-v"></i>
                                        </button>
                                        <div class="dropdown-menu dropdown-menu-user" aria-labelledby="dropdownMenuButton">
                                            <div class="dropdown-menu-header">
                                                <h4 style="opacity:1" class="widget-nombre mt-1 text-center">Seleccione una opción</h4>
                                            </div>
                                            <a
                                                @click="AgregarTrabajador(lista.IdProyecto)"
                                                data-toggle="modal"
                                                :data-target="'#AgregarTrabajador'"
                                                data-backdrop="static"
                                                class="dropdown-item"
                                                >
                                                <i class="fa fa-user fa-fw-m"></i> Asignar Personal
                                            </a>

                                            <a
                                                @click="Editar(lista.IdProyecto)"
                                                data-toggle="modal"
                                                :data-target="'#'+NombreSeccion"
                                                data-backdrop="static"
                                                class="dropdown-item"
                                                >
                                                <i class="fa fa-pencil fa-fw-m"></i> Editar
                                            </a>
            
                                            <a
                                                @click="Eliminar(lista.IdProyecto)"
                                                data-backdrop="static"
                                                class="dropdown-item"
                                                >
                                                <i class="fa fa-trash fa-fw-m"></i> Eliminar
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </Clist>
                    <Modal :NameModal="NombreSeccion" :size="size" :Nombre="NameList" :poBtnSave="oBtnSave" :pEmitSeccion="EmitSeccion">
                        <template slot="Form">
                            <Form :poBtnSave="oBtnSave" :pRutaImagenCliente="RutaImagenCliente" :pRutaImagenPropiedad="RutaImagenClienteSucursal">
                            </Form>
                        </template>
                    </Modal> 
                    <Modal :NameModal="'AgregarTrabajador'" :size="'modal-lg'" :Nombre="'Asignar Personal'" :poBtnSave="oBtnSaveTrabajador" :pEmitSeccion="EmitSeccionTrabajador">
                        <template slot="Form">
                            <Trabajador :poBtnSave="oBtnSaveTrabajador" >
                            </Trabajador>
                        </template>
                    </Modal> 
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import Modal from '@/components/Cmodal.vue';
import Clist from '@/components/Clist.vue';
import Cbtnaccion from '@/components/Cbtnaccion.vue';
import Form from '@/views/modulos/proyecto/Form.vue';
import Trabajador from '@/views/modulos/proyecto/AgregarTrabajador.vue';
import AgregarTrabajador from './AgregarTrabajador.vue';
// import Menu from "../indexMenu.vue";

export default {
    name :'list',
    props:[],
    components :{
        Modal,
        Clist,
        Cbtnaccion,
        Form,
        Trabajador
        // Menu,
    },
    data() {
        return {
            NombreSeccion: 'Proyectos',
            FormName:'ProyectosForm',
            EsModal:true,
            size :"modal-lg",
            NameList:"Proyecto",
            EmitSeccion:"Proyecto",
            EmitSeccionTrabajador:"AgregarTrabajador",
            urlApi:"proyectos/list",
            ListaProyectos:[],
            ShowBtns:true,
            TipoList:'',
            Filtro:{
                Nombre:'',
                Placeholder:'Nombre..',
                TotalItem:0,
                Pagina:1,
                Entrada:30
            },
            oBtnSave:{
                isModal:true,
                disableBtn:false,
                toast:0,
                nombreModal:"Proyectos",
                EmitSeccion:'Proyecto'
            },
            oBtnSaveTrabajador:{
                isModal:true,
                disableBtn:false,
                toast:0,
                nombreModal:"Agregar Trabajador",
                EmitSeccion:'AgregarTrabajador'
            },
            ConfigLoad:{
				ShowLoader:true,
				ClassLoad:true,
			},
            RutaImagenProyecto:"",
            RutaImagenCliente:"",
            RutaImagenClienteSucursal:"",
        }
    },
    methods: {
        Nuevo()
        {
            if (this.oBtnSave.isModal==true)
            { 
                this.bus.$emit('NewModal_'+this.EmitSeccion,true);
            }
            else{
                this.bus.$emit('NewModal_'+this.EmitSeccion);
            }
        },
        Eliminar(Id)
        {
            this.$swal({
                title: 'Esta seguro que desea eliminar este dato?',
                text: 'No se podra revertir esta acción',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si',
                cancelButtonText: 'No, mantener',
                showCloseButton: true,
                showLoaderOnConfirm: true
            }).then((result) => {
                if(result.value) {
                    this.$http.delete(
                        'proyectos/' + Id
                    ).then( (res) => {
                        this.$toast.success('Información eliminada');
                        this.Lista();
                    });
                } 
            });
        },
        Editar(Id){
            this.bus.$emit('NewModal_'+this.EmitSeccion,false,Id);
        },
        AgregarTrabajador(Id){
            this.bus.$emit('NewModal_'+this.EmitSeccionTrabajador,false,Id)
        },
        async Lista(){
            this.ConfigLoad.ShowLoader = true;
            await this.$http.get(
                'proyectos/get',
                {
                    params:{
                        Nombre:this.Filtro.Nombre,
                        Entrada:this.Filtro.Entrada,
                        pag:this.Filtro.Pagina, 
                        RegEstatus:'A',
                    }
                }
            ).then((res)=>{
                this.ListaProyectos=res.data.data.proyectos;
                this.Filtro.Entrada=res.data.pagination.PageSize;
                this.Filtro.TotalItem=res.data.pagination.TotalItems; 
                this.RutaImagenProyecto=res.data.data.rutaImagenProyecto;
                this.RutaImagenCliente=res.data.data.rutaImagenCliente;
                this.RutaImagenClienteSucursal=res.data.data.rutaImagenClienteSucursal;
            }).finally(()=>{
				this.ConfigLoad.ShowLoader = false;
			});
        },
    },
    created() {
        this.bus.$off('Delete');
        this.bus.$off('List');
        this.Lista();
        this.bus.$on('Delete',(Id)=> 
        {
            this.Eliminar(Id);
        });
        this.bus.$on('List',()=> 
        {
            this.Lista();
        });
    },
}
</script>

<style>

</style>