<template>
    <div>
        <div class="row">
            <div class="col-lg-12">
                <label for="">Nombre del Documento *</label>
                <input type="text" v-model="Documento.Nombre" placeholder="Nombre" class="form-control">
                <label id="lblmsuser" style="color:red"><Cvalidation v-if="this.errorvalidacion.Nombre" :Mensaje="'Campo obligatorio'"></Cvalidation></label>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-lg-12">
                <label for="">Archivo</label>
                <div class="custom-file-input-image">
                    <input @change="SubirArchivo()" type="file" accept="application/pdf" ref="file" class="custom-file-input" id="validatedCustomFile" required>
                    <input type="text" v-model="Archivo" class="form-control">
                    <button type="button" class=""><i class="fas fa-paperclip"></i></button>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <label for="">Comentario</label>
                <textarea class="form-control" v-model="Documento.Comentario" cols="10" rows="10"></textarea>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:"Documento",
    props:["poBtnSave","pIdTrabajador"],
    data() {
        return {
            Modal:true,
            FormName:'Documento',
            EmitSeccion:this.poBtnSave.EmitSeccion,
            Documento:{
                IdDocT:0,
                IdTrabajador:0,
                Nombre:'',
                Comentario:'',
                Archivo:'',
            },
            Archivo:'Elegir Archivo (5MB)',
            errorvalidacion:[]
        }
    },
    methods: {
        Guardar(){
            let file = this.$refs.file.files[0];
            let formData=new FormData();
            formData.set('IdDocT',this.Documento.IdDocT);
            formData.set('IdTrabajador',this.Documento.IdTrabajador);
            formData.set('Nombre',this.Documento.Nombre);
            formData.set('Comentario',this.Documento.Comentario);
            formData.set('NombreArchivo',this.Documento.Archivo);
            formData.append('NombreArchivoFile',file);
            this.poBtnSave.toast=0;
            this.poBtnSave.disableBtn=true;
            this.$http.post('docstrabajador2/post',formData,{
                headers:{
                    'Content-Type':'multipart/form-data'
                }
            }).then((res)=>{
                this.poBtnSave.disableBtn=false; 
                this.poBtnSave.toast=1;
                $("#Documento").modal("hide");
                this.bus.$emit('ListaPrincipal');
            }).catch((err)=>{
                this.errorvalidacion=err.response.data.message.errores;
                this.poBtnSave.disableBtn=false;
                this.poBtnSave.toast=2;
            });
        },
        Limpiar(){
            const  input  = this.$refs.file;
            input .type = 'text'
            input .type = 'file';
            this.Documento={
                IdDocT:0,
                IdTrabajador:0,
                Nombre:'',
                Comentario:'',
                Archivo:'',
            }
            this.Archivo='Elegir Archivo (5MB)';
        },
        Recuperar(){
            this.$http.get('docstrabajador2/recovery',{
                params:{
                    IdDocT:this.Documento.IdDocT
                }
            }).then((res)=>{
                const objres=res.data.data.doctrabajador;
                this.Documento.IdDocT=objres.IdDocT;
                this.Documento.IdTrabajador=objres.IdTrabajador;
                this.Documento.Nombre=objres.Nombre;
                this.Documento.Comentario=objres.Comentario;
                this.Documento.Archivo=objres.Archivo;
                this.Archivo=objres.Archivo;
            })
        },
        SubirArchivo(){
            const image = this.$refs.file.files[0];

            var FileSize = image.size / 1024 / 1024; // in MB
            if (FileSize > 5) {
                this.$toast.info('Solo se puede subir archivos menores a 5 MB');
                const  input  = this.$refs.file;
                input .type = 'text'
                input .type = 'file';
                return false;
            }
                        
            var allowedExtensions = /(\.pdf|\.PDF)$/i;
            if(!allowedExtensions.exec(image.name)){
                this.$toast.info('Extensiones permitidas .pdf');
                const  input  = this.$refs.file;
                input.type = 'text'
                input.type = 'file';
                this.Archivo='Elegir archivo (5MB)';
                return false;
            }

            this.Archivo=image.name;
        }
    },
    created() {
        this.bus.$off("NewModal_"+this.EmitSeccion);
        this.bus.$on("NewModal_"+this.EmitSeccion,(data,Id,IdTrabajador)=>{
            this.poBtnSave.disableBtn=false;
            this.bus.$off("Save_"+this.EmitSeccion);
            this.bus.$on("Save_"+this.EmitSeccion, () => {
				this.Guardar();
			});
            this.Limpiar();
            if (IdTrabajador!==undefined) {
                this.Documento.IdTrabajador=IdTrabajador;
            }
            if (Id > 0) {
				this.Documento.IdDocT = Id;
				this.Recuperar();
			}
        });
    },
}
</script>

<style>

</style>