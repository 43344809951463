<template>
    <div>
        <b-overlay :show="this.isOverlay" spinner-variant="primary" >
            <CHead :oHead="oHead">
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                        <div class="card card-01">
                            <div class="form-group form-row">
                                <div class="col-md-3 col-lg-3">
                                    <label>Tipo de Servicio *</label>
                                    <select id="TipoServicio" class="form-control form-control-sm" v-model="servicios.Tipo_Serv" @change="GetServicio();">
                                        <option value="">Seleccione una Opcion</option>
                                        <option v-for="(item, index) in ListaTipoServicio" :key="index" :value="item.IdTipoSer" >{{item.Concepto}}</option>
                                    </select>
                                    <Cvalidation v-if="this.errorvalidacion.TipoServ" :Mensaje="errorvalidacion.TipoServ[0]"/>
                                </div>

                                <div class="col-md-1 col-lg-1">
                                    <div class="float_checkbox mt-3">
                                         <label class="check_box">
                                             <input v-model="servicios.Offline" type="checkbox">
                                             <span class="checkmark" style="border-color: #BDBDBD;"></span>
                                         </label>
                                         <span class="pl-4 ml-1 bold">Off Line</span>
                                     </div>
                                 </div>

                                 <div class="col-md-6 col-lg-6"></div>
                                 <div class="col-md-2 col-lg-2">
                                     <label>Folio</label>
                                     <input type="text" class="form-control form-control-sm bold color-02" v-model="servicios.Folio" readonly="">
                                 </div>

                            </div>

                            <Cliente v-if="ShowComponent" @Listar="ListaCliente" :Consultado="Consultado" :pServicios="servicios" :oclientesuc="oclientesuc" :errorvalidacion="errorvalidacion"/>
                            <Fechas v-if="ShowComponent" :Consultado="Consultado" :servicios="servicios" :errorvalidacion="errorvalidacion" :pBloker="BlockerDates"/>

                            <template v-if="ShowComponent">
                                <div class="row mt-2">
                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                        <h4 class="titulo-04">TAREAS Y MATERIALES</h4>
                                        <hr>
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-md-6 col-lg-6">
                                        <label> Tareas</label>
                                        <textarea v-model="servicios.Observaciones" class="form-control" rows="5"></textarea>
                                        <Cvalidation v-if="errorvalidacion.Tareas" :Mensaje="errorvalidacion.Tareas[0]"/>
                                    </div>
                    
                                    <div class="col-md-6 col-lg-6">
                                        <label>Materiales</label>
                                        <textarea v-model="servicios.Materiales" class="form-control" rows="5"></textarea>
                                    </div>
                                </div>
                            </template>
                            <!-- ------------------- TERMINA TAREAS Y MATERIALES ---------------------------- -->

		                    <!-- ------------------- OBSERVACIONES Y COSTOS ---------------------------- -->
		                    <template v-if="servicios.IdServicio > 0">
		                    	<!-- ------------------- OBSERVACIONES ---------------------------- -->
		                    	<div class="row mt-2">
		                    		<div class="col-md-12 col-lg-12">
		                    			<h4 class="titulo-04">OBSERVACIONES</h4>
		                    		</div>
		                    		<div class="col-md-12 col-lg-12">
		                    			<hr>
		                    		</div>
		                    	</div>
                            
		                    	<div class="row mt-2">
		                    		<div class="col-md-6 col-lg-6">
		                    			<label>Observaciones del técnico</label>
		                    			<textarea v-model="servicios.Comentario" readonly class="form-control" rows="5"></textarea>
		                    		</div>
		                    		<div class="col-md-6 col-lg-6">
		                    			<label>Observaciones finales</label>
		                    			<textarea v-model="servicios.ComentarioFin" class="form-control" rows="5"></textarea>
		                    		</div>
		                    	</div>
		                    	<!-- ------------------- TERMINA OBSERVACIONES ---------------------------- -->
                            
		                    	<!-- ------------------- COSTOS ---------------------------- -->
		                    	<div class="row mt-2">
		                    		<div class="col-md-12 col-lg-12">
		                    			<h4>COSTOS</h4>
		                    			<hr>
		                    		</div>
		                    	</div>
                            
		                    	<div class="row mt-2">
		                    		<div class="col-md-2 col-lg-2">
		                    			<label>Equipos</label>
		                    			<vue-numeric :minus="false" class="form-control form-control-sm"  currency="$" separator="," :precision="2" placeholder="$ 0.00" v-model="servicios.EquiposD"></vue-numeric>
		                    		</div>
                                
		                    		<div class="col-md-2 col-lg-2">
		                    			<label>Materiales</label>
		                    			<vue-numeric :minus="false" class="form-control form-control-sm"  currency="$" separator="," :precision="2" placeholder="$ 0.00" v-model="servicios.MaterialesD"></vue-numeric>
		                    		</div>

		                    		<div class="col-md-2 col-lg-2">
		                    			<label>Viaticos</label>
		                    			<vue-numeric :minus="false" class="form-control form-control-sm"  currency="$" separator="," :precision="2" placeholder="$ 0.00" v-model="servicios.ViaticosD"></vue-numeric>
		                    		</div>

		                    		<div class="col-md-2 col-lg-2">
		                    			<label>Contratista</label>
		                    			<vue-numeric   :minus="false" class="form-control form-control-sm"  currency="$" separator="," :precision="2" placeholder="$ 0.00" v-model="servicios.ContratistasD"></vue-numeric>
		                    		</div>

		                    		<div class="col-md-2 col-lg-2">
		                    			<label>Costo Mano de Obra</label>
		                    			<vue-numeric  disabled :minus="true" class="form-control form-control-sm"  currency="$" separator="," :precision="2" placeholder="$ 0.00" v-model="servicios.ManoObraT"></vue-numeric>
		                    		</div>

		                    		<div class="col-md-2 col-lg-2">
		                    			<label>Burden</label>
		                    			<vue-numeric  disabled  :minus="true" class="form-control form-control-sm"  currency="$" separator="," :precision="2" placeholder="$ 0.00" v-model="servicios.BurdenTotal"></vue-numeric>
		                    		</div>

		                    		<div class="col-md-2 col-lg-2">
		                    			<label>Costo Vehiculos</label>
		                    			<vue-numeric  disabled  :minus="true" class="form-control form-control-sm"  currency="$" separator="," :precision="2" placeholder="$ 0.00" v-model="servicios.CostoV"></vue-numeric>
		                    		</div>
		                    	</div>
		                    	<!-- ------------------- TERMINA COSTOS ---------------------------- -->
		                    </template>

                            <div class="row mt-2">
                                <div class="col-md-12 col-lg-12">
                                    <h4 class="mt-2">ANEXOS</h4>
                                    <hr>
                                </div>
                                <div class="col-md-12 col-lg-12 mt-2">
                                    <button type="button" @click="OpenReporte2" class="btn btn-01 mr-3" data-toggle="modal" data-target="#ModalReporte2"  data-backdrop="static" data-keyboard="false">Observaciones Equipos <span class="badge badge-light">{{contadores.Observaciones}}</span></button>
                                    <button type="button"  @click="OpenReporte1"  class="btn btn-01 mr-3"  data-toggle="modal" data-target="#ModalReporte1"  data-backdrop="static" data-keyboard="false">Evidencias Generales <span class="badge badge-light">{{contadores.Imagenes}}</span></button>
                                </div>
                            </div>

                            <div class="row mt-4">
                                <div class="col-md-12 col-lg-12">
                                    <h4>ESTATUS Y FACTURACIÓN</h4>
                                    <hr>
                                </div>
                            </div>

                            <div class="row mt-2">
                                <div v-show="servicios.IdServicio>0"  class="col-md-2 col-lg-2">
                                    <label>Estatus *</label>
                                    <select :disabled="Cancelado" v-model="servicios.EstadoS" class="form-control form-control-sm">
                                        <option :value="''">Seleccione una Opción</option>
                                        <option :value="'ABIERTA'">ABIERTA</option>
                                        <option v-if="servicios.EstadoS=='PENDIENTE'" :value="'PENDIENTE'">PENDIENTE</option>
                                        <option v-if="servicios.EstadoS=='REALIZADA'" :value="'REALIZADA'">PENDIENTE</option>
                                        <option :value="'CERRADA'">CERRADA</option>
                                        <option :value="'CERRADA Y OPORTUNIDAD'">CERRADA Y OPORTUNIDAD</option>
                                        <option v-if="BlockerDates.BlockFecha !== 'Si' " :value="'CANCELADA'">CANCELADA</option>
                                    </select>
                                    <label style="color:red"><Cvalidation v-if="this.errorvalidacion.Estado_servicio" :Mensaje="errorvalidacion.Estado_servicio[0]"></Cvalidation></label>
                                </div>
                                <div class="col-md-2 col-lg-2">
                                    <label>Factura *</label>
                                    <select v-model="servicios.Factura" class="form-control form-control-sm">
                                        <option  :value="'n'" >No Facturar</option>
                                        <option v-if="this.TipoServicioFact =='s'"  :value="'s'" >Facturar</option>
                                    </select>
                                </div>
                                <div v-if="servicios.EstadoS=='REALIZADA'" class="col-lg-2">
                                    <label for="">Calificación de Servicio</label>
                                    <star-rating :rating="parseInt(servicios.Rating)" :read-only="true" :increment="0.01" :max-rating="3"></star-rating>
                                </div>
                            </div>

                            <div v-if="(servicios.DescripOportunidad=='' || servicios.DescripOportunidad==' ' || servicios.DescripOportunidad==null) && (servicios.Oportunidad=='n' || servicios.Oportunidad==null) && servicios.EstadoS=='CERRADA Y OPORTUNIDAD'" class="f1-buttons mt-4">
                                <button data-toggle="modal" data-target="#Oportunidad" data-backdrop="static" :disabled="Disablebtn" @click="AbrirOportunidad" type="button" class="btn btn-01">
                                    <i v-show="Disablebtn" class="fa fa-spinner fa-pulse fa-1x fa-fw"></i><i class="fa fa-plus-circle"></i> Continuar
                                </button>
                            </div>

                            <div class="f1-buttons mt-4" v-else>
                                <button :disabled="Disablebtn" @click="Validacion"  type="button" class="btn btn-01">
                                    <i v-show="Disablebtn" class="fa fa-spinner fa-pulse fa-1x fa-fw"></i><i class="fa fa-plus-circle"></i> {{txtSave}}
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            </CHead>
        </b-overlay>
    </div>
</template>

<script>
import StarRating from 'vue-star-rating'
//El props Id es cuando no es modal y se mando con props
//El export de btnsave es por si no se usa el modal
import Cbtnsave from '@/components/Cbtnsave.vue'
import Cvalidation from '@/components/Cvalidation.vue'
import Modal from '@/components/Cmodal.vue';
import Clist from '@/components/Clist.vue';

import Cliente from "./componentes/Cliente";
import Fechas from './componentes/Fechas.vue';
import Personal from './componentes/Personal.vue';
import Comentarios from './componentes/Comentarios.vue';
import Costos from './componentes/Costos.vue';

import Reporte2 from '@/views/modulos/servicios/Reporteequipo.vue'
import Reporte1 from '@/views/modulos/servicios/Imagenes.vue'
import NewCliente from '@/views/modulos/servicios/NewCliente.vue'
import Levantamiento from '@/views/modulos/servicios/componentes/DatosLevantamiento.vue'
import Oportunidad from '@/views/modulos/servicios/Oportunidad.vue';
import moment from 'moment';

export default {
    name:'Form',
    props:['pOrigen'],
    components:{
		Clist,
		Cbtnsave,
		Cvalidation,
		Cliente,
		Fechas,
		Personal,
		Comentarios,
		Costos,
		Modal,
		Reporte2,
		Reporte1,
		NewCliente,
		Levantamiento,
        Oportunidad,
        StarRating
	},
    data() {
        return {
            oHead:{//Encabezado
                Title:'Servicio',
                BtnNewShow:false,
                BtnNewName:'Nuevo',
                isreturn:true,
                isModal:false,
                isEmit:false,
                Url:'despacho',
                ObjReturn:'',
             },
            Modal: false,//Sirve pra los botones de guardado
            FormName: 'vehiculo',//Sirve para donde va regresar
            oBtnOportunidad:{
                EmitSeccion:'Oportunidad',
                disableBtn:false,
                nombreModal:'Oportunidad'
            },
            PasoActual: 1,
            ListaCategoria: [],
            servicios: {
                IdServicio: 0,
                IdCliente: '',
                IdClienteS: '',
                Cliente: '',
                Client: '',
                ClienteSucursal: '',
                Direccion: '',
                Telefono: '',
                Correo: '',
                Distancia: 0,
                Velocidad: 0,
                Tipo_Serv: '',
                Trabajadores: [],
                Vehiculos: [],
                Personal: 0,
                Fecha_I: '',
                Fecha_F: '',
                Observaciones: '',
                EstadoS: 'ABIERTA',
                IdVehiculo: '',
                EquiposD: 0,
                MaterialesD: 0,
                ViaticosD: 0,
                ContratistasD: 0,
                ManoObraT: 0,
                BurdenTotal: 0,
                CostoV: 0,
                IdContrato: 0,
                NumContrato: '',
                Paso: 1,
                FechasHoras: [],
                Econtacto: '',
                Contacto: '',
                Para: [],
                Enviar: false,
                Factura: 'n',
                Sucursal:'',
                Oportunidad:'',
                DescripOportunidad:'',
                Offline:true,
                Rating:0
            },
            Consultado:{
                ListaTrabajadores:[],
                ListaVehiculos:[],
                ListaNumc:[]
            },
            ListaClientes:[],
            ListaSucursal:[],
            Mostrar:true,
            Regresar:false,
            TituloLista:'Lista de Clientes',
            errorvalidacion:[],
            urlApi:'servicio/recovery',
            ShowComponent:false,
            oclientesuc:{
                ContactoS: "",
                Correo: "",
                Direccion: "",
                DistanciaAprox: "",
                Empresa: "",
                IdCliente: "",
                IdClienteS: "",
                IdSucursal: "",
                Ncontrato: "",
                Nombre: "",
                Telefono: "",
            },
            ListaTipoServicio:[],
            ListaTipoServicio2:[],

            Disablebtn:false,
            txtSave:'Guardar',
            contadores: {
                Observaciones: 0,
                Imagenes: 0
            },
            loader: false,
            IdTipo:0,
            TipoServicioFact:'',
            HoraInicio: '',
            HoraActual:moment().format('HH:mm:ss'),
            FechaActual:moment().format('YYYY-MM-DD'),

            HoraValida:'',
            FechaValida:'',
            DisableHI:'',
            DisbaleT:'',
			BlockerDates:{
				BlockFecha:true,
				BlockHoraI:false,
				BlockHoraF:false,
                FechaActual:'',
                FechaAyer:'',
				isPast: false
			},
			OrigenView: '',
            Cancelado:false,
            Cliente:{}
        }
    },
    methods: {
        AbrirOportunidad(){
            this.bus.$emit('NewModal_'+this.oBtnOportunidad.EmitSeccion);
        },
        Validacion(){
            if (this.servicios.Factura=="n" && this.servicios.EstadoS =="CERRADA") {
                    this.$swal({
                    title: "¿Está seguro que desea guardar este servicio como NO facturado?",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Si",
                    cancelButtonText: "No, mantener",
                    showCloseButton: true,
                    showLoaderOnConfirm: true
                }).then(result => {
                    if (result.value) {
                        this.Guardar();

                    } else {

                    }
                });
            }else{
                this.Guardar();
            }

        },
        async ListaCliente() {
            this.bus.$emit('ListCcliente');
        },

        async ListaServ() {
            await this.$http.get(
                'tiposervicio/get',
                {
                    params:{Nombre:'',Entrada:50,pag:0, RegEstatus:'A'}
                }
            ).then( (res) => {
                this.ListaTipoServicio =res.data.data.tiposervicio;
            });
        },

        async ListaNumContrato() {
            await this.$http.get(
                'numcontrato/get',
                {
                    params:{
						IdClienteS:this.servicios.IdClienteS
					}
                }
            ).then( (res) => {
				//console.log(res.data.data);
                this.Consultado.ListaNumc =res.data.data.row;
            });
        },

        // OBJETO 1 = PROPIEDAD, OBJETO 2 = MATRIZ
        SeleccionarCliente(objeto,objeto2) {
            this.oclientesuc=objeto;
            this.Cliente=objeto2;
            if (this.Cliente.Correo!='')
            {   
                this.servicios.Para=[];
                this.servicios.Para.push({ "text": this.Cliente.Correo});
                // this.servicios.Para.push({ "text": this.oclientesuc.Correo});
            }

            let distacia=0;
            if (objeto.DistanciaAprox !='')
            {
                distacia=objeto.DistanciaAprox;
            }

            this.servicios.IdCliente    = objeto.IdCliente;
            this.servicios.IdClienteS   = objeto.IdClienteS;
            this.servicios.Client       = objeto.Empresa;
            this.servicios.Sucursal     = objeto.Nombre;
            this.servicios.Direccion    = objeto.Direccion;
            this.oclientesuc.ContactoS=objeto2.Contacto;
            this.oclientesuc.Telefono=objeto2.Telefono;
            this.servicios.Distancia    = distacia;
            this.servicios.Velocidad    = 0;
            this.ListaNumContrato();
        },

        Validar() {
            let bandera= true;
            if (this.servicios.FechasHoras.length === 0)
            {
                this.$toast.info('No hay horas seleccionadas');
                bandera= false;
            }

            for(var i=0;i<this.servicios.FechasHoras.length;i++)
            {
                if (this.servicios.FechasHoras[i].HoraI === '' || this.servicios.FechasHoras[i].HoraF === '')
                {
                    this.$toast.info('Seleccione todas las horas');
                    bandera= false;
                }
            }

            if (this.servicios.Trabajadores.length === 0)
            {
                this.$toast.info('Debe seleccionar un técnico');
                bandera= false;
            }

			if (this.servicios.Personal === 0)
			{
				this.$toast.info('Seleccione un responsable');
				bandera= false;
			}

            if (this.servicios.Vehiculos.length === 0)
            {
                this.$toast.info('Debe seleccionar un vehículo');
                bandera= false;
            }

            return bandera;
        },


        async Guardar() {

            let validacion= this.Validar();

            if (validacion === false) {
                return false;

            } else {
                this.oBtnOportunidad.disableBtn=true;
                this.servicios.Cliente = this.servicios.Client;
                if (this.servicios.IdServicio==0) {
                    this.servicios.Econtacto=this.Cliente.Correo;
                }
                this.servicios.Contacto=this.oclientesuc.ContactoS;
                this.servicios.EquiposD=parseInt(this.servicios.EquiposD);
                this.servicios.MaterialesD=parseInt(this.servicios.MaterialesD);
                this.servicios.ViaticosD        =parseInt(this.servicios.ViaticosD);
                this.servicios.ContratistasD    =parseInt(this.servicios.ContratistasD);
                this.servicios.ManoObraT=parseInt(this.servicios.ManoObraT);
                this.servicios.BurdenTotal=parseInt(this.servicios.BurdenTotal);
                this.servicios.CostoV=parseInt(this.servicios.CostoV);

                this.servicios.Velocidad=parseInt(this.servicios.Velocidad);
                this.servicios.Distancia=parseInt(this.servicios.Distancia);

                this.Disablebtn=true;
                this.txtSave=' Espere...';

                

                this.$http.post('servicio/post', this.servicios)
					.then( (res) => {
						this.Disablebtn=false;
                        this.oBtnOportunidad.disableBtn=false;
                    this.txtSave=' Guardar';
                    $('#ModalForm').modal('hide');
                    $('#Oportunidad').modal('hide');

					if( this.OrigenView === 'DESPACHO' ) {
						this.bus.$emit('ListDespacho');

					}else if(this.OrigenView === 'CALENDARIO' ) {
						this.bus.$emit('ListCalendarioDespacho');

					}
                    else if(this.OrigenView === 'SERVICIOS'){
                        this.bus.$emit('List');
                    }

					this.$toast.success('Información guardada');

					if(this.servicios.Enviar) {
						if (res.data.Mail == 'sended'){
							this.$toast.info('Correo Enviado','Éxito','');

						}else if(res.data.Mail == 'unSended') {
							this.$toast.error('No fue posible enviar el correo electrónico','Error de Envío','');
						}

					}


                }).catch( err => {
                    this.Disablebtn=false;
                    this.oBtnOportunidad.disableBtn=false;
                    this.txtSave=' Guardar';
                    this.errorvalidacion=err.response.data.message.errores;
                    this.$toast.info('Complete la Información');
                });
            }
        },

        Limpiar() {
            this.PasoActual=1;

			this.servicios = {
				IdServicio: 0,
				IdCliente: '',
				IdClienteS: '',
                
                Cliente: '',
				Client: '', // CLIENTE PRINCIPAL
                Sucursal: '', // SUCURSAL DEL CLIENTE

				Direccion: '',
				Telefono: '',
				Correo: '',
				Distancia: 0,
				Velocidad: 0,
				Tipo_Serv: '',
				Trabajadores: [],
				Vehiculos: [],
				Personal: 0,
				Fecha_I: '',
				Fecha_F: '',
				Observaciones: '',
				EstadoS: 'ABIERTA',
				IdVehiculo: '',
				EquiposD: 0,
				MaterialesD: 0,
				ViaticosD: 0,
				ContratistasD: 0,
				ManoObraT: 0,
				BurdenTotal: 0,
				CostoV: 0,
				IdContrato: 0,
				NumContrato: '',
				Paso: 1,
				FechasHoras: [],
				Econtacto: '',
				Contacto: '',
				Para: [],
				Enviar: false,
				Factura: 'n',
				Folio:'',
                DescripOportunidad:'',
                Oportunidad:'',
			};

			this.Consultado = {
				ListaTrabajadores:[],
				ListaVehiculos:[],
				ListaNumc:[]
			};


            this.ListaClientes=[];
            this.ListaSucursal=[];
            this.errorvalidacion=[];
            this.oclientesuc ={
                ContactoS: "",
                Correo: "",
                Direccion: "",
                DistanciaAprox: "",
                Empresa: "",
                IdCliente: "",
                IdClienteS: "",
                IdSucursal: "",
                Ncontrato: "",
                Nombre: "",
                Telefono: "",
            };

			this.BlockerDates = {
				BlockFecha: true,
				BlockHoraI: false,
				BlockHoraF: false,
				FechaActual:'',
				FechaAyer:'',
				isPast: false
			}

            this.Cancelado=false;
            this.Cliente={};

           this.bus.$emit('LimpiarCompoenets');
           this.bus.$emit('LimpiarCompoenetsL');


        },

        recovery() {
			this.loader = true;

            this.$http.get(this.urlApi, {
				params:{
					IdServicio: this.servicios.IdServicio
				}

			}).then((res) => {

                this.servicios 					= res.data.data.servicio;
                if (this.servicios.Offline==0) {
                    this.servicios.Offline=false;
                }
                else{
                    this.servicios.Offline=true;
                }

                console.log('FIni: '+this.servicios.ref_HoraInicioReal);
                console.log('FFin: '+this.servicios.ref_HoraFinReal);

                
                this.servicios.HoraInicioReal   = this.servicios.HoraInicioReal == null ? '': this.servicios.HoraInicioReal;
                this.servicios.HoraFinReal      = this.servicios.HoraFinReal == null ? '' : this.servicios.HoraFinReal;
                this.HoraInicio 				= this.servicios.HoraInicio;
				this.contadores.Observaciones 	= res.data.data.count_Observaciones;
				this.contadores.Imagenes 		= res.data.data.count_Imagenes;

				// OBTENEMOS LA FECHA ACTUAL
				let FechaActual = moment(new Date());
				FechaActual = FechaActual.format('YYYY-MM-DD');

				this.BlockerDates.FechaActual = FechaActual;
				//console.log(this.BlockerDates.FechaActual);

				// OBTENEMOS LA FECHA DE AYER BASADA EN LA FECHA ACTUAL
                let FechaA = moment(new Date());
                FechaA = FechaA.subtract(1,"days");
                FechaA = FechaA.format('YYYY-MM-DD');

               this.BlockerDates.FechaAyer=FechaA;
			   //console.log(this.BlockerDates.FechaAyer);

				// SI LA FECHA INICIAL DEL SERVICIO ESTA ENTRE EL RANGO DE FECHAS DE AYER Y HOY, NO SE BLOQUEA NADA
				if (this.servicios.Fecha_I >= this.BlockerDates.FechaAyer && this.servicios.Fecha_I <= this.BlockerDates.FechaActual ) {
					this.BlockerDates.BlockFecha = null;
					this.BlockerDates.BlockHoraI = false;
					this.BlockerDates.BlockHoraF = false;
					this.BlockerDates.isPast     = (this.servicios.Fecha_I < this.BlockerDates.FechaActual) ? true: false;

				}else{

					//
					if(this.servicios.Fecha_I > this.BlockerDates.FechaActual ) {
						this.BlockerDates.BlockFecha = false;
						this.BlockerDates.BlockHoraI = false;
						this.BlockerDates.BlockHoraF = false;

					}else {
						this.BlockerDates.BlockFecha = true;
						this.BlockerDates.BlockHoraI = true;
						this.BlockerDates.BlockHoraF = true;
					}

				}

               /* if (this.BlockerDates.FechaAyer===this.servicios.Fecha_I) {
                //    console.log(this.FechaAyer);
                        this.BlockerDates.BlockFecha = null;
					    this.BlockerDates.BlockHoraI = false;
					    this.BlockerDates.BlockHoraF = false;

                   // PASADO
                }else if (this.FechaActual > this.servicios.Fecha_I) {
                    this.BlockerDates.BlockFecha = true;
					this.BlockerDates.BlockHoraI = true;
					this.BlockerDates.BlockHoraF = true;

                     //PRESENTE
                }
                // else if(this.FechaActual===this.servicios.Fecha_I) {

                //     if (this.HoraActual >= this.servicios.HoraInicio) {

				// 		this.BlockerDates.BlockFecha = true;
				// 		this.BlockerDates.BlockHoraI = true;
                //         this.BlockerDates.BlockHoraF = this.HoraActual >= this.servicios.HoraFin;

                //     }else{
				// 		this.BlockerDates.BlockFecha = false;
				// 		this.BlockerDates.BlockHoraI = false;
				// 		this.BlockerDates.BlockHoraF = false;
                //     }

					//FUTURO
                else if(this.FechaActual < this.servicios.Fecha_I) {
					this.BlockerDates.BlockFecha = false;
					this.BlockerDates.BlockHoraI = false;
					this.BlockerDates.BlockHoraF = false;
                }*/

				//console.log(res.data.data.servicio.Fecha_I);
				//console.log(res.data.data.servicio.Fecha_F);

                var formatedDate = this.servicios.Fecha_I.replace(/-/g,'\/');
                this.servicios.Fecha_I = new Date(formatedDate);

                var formatedDate2 = this.servicios.Fecha_F.replace(/-/g,'\/');
                this.servicios.Fecha_F = new Date(formatedDate2);

				//console.log(formatedDate);
				//console.log(formatedDate2);

                if(this.servicios.Factura ===''){
                    this.servicios.Factura ='n';
                }

                if(this.servicios.Factura ==null){
                    this.servicios.Factura ='n';
                }

                this.servicios.Paso=1;

                if(this.servicios.EquiposD===0){
                    this.servicios.EquiposD=0;
                }

                if(this.servicios.MaterialesD===0){
                    this.servicios.MaterialesD=0;
                }

                if(this.servicios.ViaticosD===0){
                    this.servicios.ViaticosD=0;
                }

                if(this.servicios.ContratistasD===0){
                    this.servicios.ContratistasD=0;
                }

                if(this.servicios.ManoObraT===0){
                    this.servicios.ManoObraT=0;
                }

                if(this.servicios.BurdenTotal===0){
                    this.servicios.BurdenTotal=0;
                }

                if(this.servicios.CostoV===0){
                    this.servicios.CostoV=0;
                }

                if(this.servicios.EstadoS===''){
                    this.servicios.EstadoS='ABIERTA';
                }
                if(this.servicios.EstadoS=='CANCELADA'){
                    this.Cancelado=true;
                }

                if(this.servicios.Econtacto!=='' && this.servicios.Econtacto!=null)
                {
                    this.servicios.Para=[];
                    this.servicios.Para.push({ "text": this.servicios.Econtacto,"tiClasses": ["ti-valid"]});
                }
                if (this.servicios.Oportunidad=='s') {
                    this.servicios.EstadoS='CERRADA Y OPORTUNIDAD';
                }

                this.ShowComponent=true;
                this.oclientesuc=res.data.data.clientesuc;
                this.oclientesuc.ContactoS=this.servicios.Contacto;
                this.oclientesuc.Telefono=this.servicios.Tel;
                this.TipoServicioFact = this.servicios.Tipo_Serv;
                this.ListaNumContrato();
                this.ListaServ3();




            }).finally(() => {
				this.loader = false;
			});
        },

        OpenReporte2()
        {
            this.bus.$emit('OpenRepor2',this.servicios.IdServicio);
        },
        OpenReporte1()
        {
            this.bus.$emit('OpenRepor1',this.servicios.IdServicio);
        },
        // AbrirOportunidad(){
        //     this.bus.$emit('');
        // }

        /*async ContadoresImg()
        {
            await this.$http.get(
                'imageneservicio/totales',
                {
                    params:{IdServicio:this.servicios.IdServicio}
                }
            ).then( (res) => {
                this.contadores =res.data.data;
            });
        },*/

        habilitaa()
        {
            this.loader = false;
        },

        async ListaServ2()
        {
            await this.$http.get(
                'tiposervicio/get',
                {
                    params:{IdTipoSer:this.IdTipo,Entrada:50,pag:0, RegEstatus:'A'}
                }
            ).then( (res) => {
                this.ListaTipoServicio2 =res.data.data.tiposervicio;
                this.TipoServicioFact = this.ListaTipoServicio2[0].Ingresos;
            });
        },

        async ListaServ3()
        {
            await this.$http.get(
                'tiposervicio/get',
                {
                    params:{IdTipoSer:this.TipoServicioFact,Entrada:50,pag:0, RegEstatus:'A'}
                }
            ).then( (res) => {
                this.ListaTipoServicio2 =res.data.data.tiposervicio;
                this.TipoServicioFact = this.ListaTipoServicio2[0].Ingresos;
            });
        },

        GetServicio()
		{
			var cod = document.getElementById("TipoServicio").value;
			this.IdTipo=cod;
            this.ListaServ2();

		},

        showInNew(){
            this.BlockerDates.BlockFecha = false;
			this.servicios.IdServicio = 0;
			this.ShowComponent=true;
            this.loader = false;
        }
    },
    created() {
        this.Limpiar();

        this.bus.$off('Regresar');
        this.bus.$off('Nuevo');
        this.bus.$off('SeleccionarCliente');
        this.bus.$off('AcValores');
        this.bus.$off('GuardarConOportunidad');

        this.ListaServ();


        this.bus.$on('SeleccionarCliente',(oSucursal,clienteselect) => {
           this.SeleccionarCliente(oSucursal,clienteselect);
        });

        this.bus.$on('Regresar',() => {
            this.ListaCliente();
        });

		if(this.pOrigen !== undefined) {
			this.OrigenView = this.pOrigen;
		}


    },
    mounted() {
		this.bus.$on('Nuevo',(data,Id)=> {
			if (Id === undefined) {
				Id=0;
			}

			this.Limpiar();
			this.ShowComponent=false;

			if(Id>0) {

				this.servicios.IdServicio = Id;
				this.recovery();
				//this.ContadoresImg();

			} else {
                this.loader = true;
                setTimeout(() => this.showInNew(), 800);
				
			}


		});

		this.bus.$on('AcValores',(obj,Enviar) => {
			
			this.servicios.IdContrato	= obj.IdContrato
			this.servicios.Cliente 		= obj.Client; // CLIENTE PRINCIPAL
			this.servicios.Client 		= obj.Client; // CLIENTE PRINCIPAL
            this.servicios.Sucursal     = obj.Sucursal // SUCURSAL DEL CLIENTE
			this.servicios.Direccion 	= obj.Direccion;
			this.servicios.Distancia 	= obj.Distancia;
			this.servicios.Velocidad 	= obj.Velocidad;
			this.servicios.Para 		= obj.Para;
			this.servicios.Enviar 		= obj.Enviar;
			this.servicios.BurdenTotal 	= obj.BurdenTotal;
			this.servicios.ManoObraT 	= obj.ManoObraT;
			this.servicios.CostoV 		= obj.CostoV;
            
		});
        this.bus.$on('GuardarConOportunidad',(Oportunidad)=>{
            this.servicios.DescripOportunidad=Oportunidad;
            this.Validacion();
        })


    },
}
</script>

<style>

</style>