<template>
<div>
    <Clist :Cuentas="{isCuentas:true}" :EmitSeccion="EmitSeccion" :regresar="false" @FiltrarC="Lista" :Filtro="Filtro" :Nombre="NameList" :isModal="EsModal" :Total="TotalPagina" :pConfigLoad="ConfigLoad">
        <template slot="Botonnuevo">
            <div class="col-lg-6 d-flex justify-content-end mt-2 pr-5">
                <span class="ml-3 titulosmonitoreo">Total de Activos: </span> <span class="titulosmonitoreo text-primary"> {{ TotalActivos }}</span>
                <span class="ml-3 titulosmonitoreo">Total Valor Activos: </span> <span class="titulosmonitoreo text-primary">{{ $formatNumber(TotalMontoActivos,'$',false) }}</span>
                <br>
            </div>
        </template>
        <template slot="header">
            <tr>
                <th>Id Equipo</th>
                <th>Marca</th>
                <th>Modelo</th>
                <th>Tipo de Unidad</th>
                <th>Sub Tipo de Unidad</th>
                <th>Capacidad</th>
                <th>Total</th>
                <th>Acciones</th>
            </tr>
        </template>
        <template slot="body">
            <tr v-for="item,key,index in Activos" :key="index">
                <td>{{ item.CodigoActivo }}</td>
                <td>{{ item.Marca }}</td>
                <td>{{ item.Modelo }}</td>
                <td>{{ item.TipoUnidad }}</td>
                <td>{{ item.Voltaje }}</td>
                <td>{{ item.Capacidad }}</td>
                <td>{{ item.Total }}</td>
                <td>
                    <Cbtnaccion :isModal="EsModal" :IrA="FormName" :pEmitSeccion="EmitSeccion" :Id="item.IdActivo"></Cbtnaccion>
                </td>
            </tr>
        </template>
    </Clist>
    <Modal :Showbutton="false" :size="size" :Nombre="NameList" :poBtnSave="oBtnSave" :pEmitSeccion="EmitSeccion" :NameModal="'activo'">
        <template slot="Form">
            <Form :poBtnSave="oBtnSave"></Form>
            <Cbtnsave2 :poBtnSave="oBtnSave">
                <template slot="botonesform">
                    <button @click="Finalizar()" class="btn btn-pink">Finalizar</button>
                </template>
            </Cbtnsave2>
        </template>
    </Modal>
</div>
</template>

<script>
import Clist from "@/components/Clist.vue";
import Modal from "@/components/Cmodal.vue";
import Cbtnaccion from "@/components/Cbtnaccion.vue";
import CSinRegistros from "@/components/CSinRegistros.vue";
import Cbtnsave2 from "@/components/Cbtnsave2.vue";
import Form from "../activos/Form.vue";
export default {
    name:'activos',
    components:{
        Modal,
        Clist,
        Cbtnaccion,
        CSinRegistros,
        Form,
        Cbtnsave2
    },
    data(){
        return {
            EmitSeccion:'activo',
            EsModal:true,
            size:'modal-xl',
            NameList:"ID Equipos",
            FormName:'activo',
            Pag:0,
            TotalPagina:2,
            Filtro:{
                Nombre:"",
                Placeholder:"Id del Equipo/Marca/Modelo",
                TotalItem:0,
                Pagina:1,
                Entrada:20
            },
            oBtnSave:{
                isModal:true,
                EmitSeccion:'activo',
                nombreModal:'activo',
                disableBtn:false,
                toast:0,
                ShowSave:false,
                ShowCancel:false
            },
            ConfigLoad:{
	    		ShowLoader:true,
	    		ClassLoad:true,
	    	},
            Activos:[],
            TotalActivos:0,
            TotalMontoActivos:0
        }
    },
    methods: {
        // Nuevo()
        // {
        //     if (this.oBtnSave.isModal==true)
        //     {
        //         this.bus.$emit('NewModal_'+this.EmitSeccion,true);
        //     }
        //     else{
        //         this.bus.$emit('NewModal_'+this.EmitSeccion);
        //     }
        // },
        async Lista(){
            this.TotalActivos=0;
            this.TotalMontoActivos=0;
            this.ConfigLoad.ShowLoader=true;
            await this.$http.get('activos/get',{
                params:{
                    Nombre:this.Filtro.Nombre,
                    Entrada:this.Filtro.Entrada,
                    pag:this.Filtro.Pagina
                }
            }).then((res)=>{
                this.Activos=res.data.data.Activos;
                this.Filtro.Entrada=res.data.data.pagination.PageSize;
                this.Filtro.TotalItem=res.data.data.pagination.TotalItems;
                res.data.Todos.forEach(element => {
                    this.TotalActivos+=parseInt(element.Total);
                    this.TotalMontoActivos+=parseInt(element.Total)*parseFloat(element.Valor);
                });
            }).finally(()=>{
                this.ConfigLoad.ShowLoader=false;
            })
        },
        Eliminar(Id){
            this.$swal({
                title: 'Esta seguro que desea eliminar este dato?',
                text: 'No se podra revertir esta acción',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si',
                cancelButtonText: 'No, mantener',
                showCloseButton: true,
                showLoaderOnConfirm: true
            }).then((result)=>{
                if(result.value){
                    this.$http.delete(
                        'activos/'+Id
                    ).then((res)=>{
                        this.$toast.success('Información eliminada');
                        this.Lista();
                    })
                }
            })
        },
        Finalizar(){
            this.bus.$emit("Save_"+this.EmitSeccion,1);
        }
    },
    created(){
        this.bus.$off('Delete_'+this.EmitSeccion);
        this.bus.$off('List');
        // this.bus.$off('Regresar');
        this.Lista();

        this.bus.$on('Delete_'+this.EmitSeccion,(Id)=>
        {
            this.Eliminar(Id);
        });

        this.bus.$on('List',()=>
        {
            this.Lista();
        });
        // this.bus.$on('Regresar',()=>
        // {
        //    this.$router.push({name:'submenuadmon'});

        // });
    }
}
</script>