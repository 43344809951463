<template>
    <div class="container-fluid">
        <Clist
            :regresar="Regresar"
			:Nombre="Titulo"
			@FiltrarC="Lista"
			:Filtro="Filtro"
			:isModal="EsModal"
			:pShowBtnAdd="false"
			:pConfigLoad="ConfigLoad"
			:isHistorialEquipo="true"
			:ShowPager="ShowPager"
            :ShowFilas="false"
        >
        <template  slot="DatosEquipo">
            <div class="col-lg-12 mb-3">
                <div class="row pb-0 mb-0">
                    <div class="col-lg-2 m-0 pr-0">
                        <div class="avatar-upload-histequipo">
                            <div v-show="!EsCliente" class="avatar-edit">
                                <input id="file" @change="CambiarImagenLogo($event)" ref="file" type="file" name="file" accept=".png, .jpg, .jpeg">
                                <label for="file"></label>
                            </div>
                            <div class="avatar-edit2">
                                <button type="button" data-backdrop="static"
                                data-toggle="modal"
                                data-target="#imagen" @click="ZoomImagen(IdClienteS!=0 && Contacto.LogoSucursal!=null ? RutaImagen+IdClienteS+'/'+Contacto.LogoSucursal : RutaImagenLogoContacto+Contacto.LogoCliente,1)">
                                    <i class="fas fa-search"></i>
                                </button>
                            </div>
                            <div v-if="BndImagenLogo!=0" class="avatar-edit3">
                                <button type="button" @click="GuardarImagenLogo">
                                    <i class="fas fa-save"></i>
                                </button>
                            </div>
                            <div class="avatar-preview">
                                <div id="imagePreview" 
                                :style="IdClienteS!=0 && (Contacto.LogoSucursal!=null && Contacto.LogoSucursal!='null' && Contacto.LogoSucursal!='' && Contacto.ExisteImagen) ? 'background-image: url('+RutaImagen+IdClienteS+'/'+Contacto.LogoSucursal+');background-size:contain;background-repeat:no-repeat' : 'background-image: url('+RutaImagenLogoContacto+Contacto.LogoCliente+');background-size:contain;background-repeat:no-repeat'" 
                                :src="IdClienteS!=0 && (Contacto.LogoSucursal!=null && Contacto.LogoSucursal!='null' && Contacto.LogoSucursal!='' && Contacto.ExisteImagen) ? RutaImagen+IdClienteS+'/'+Contacto.LogoSucursal : RutaImagenLogoContacto+Contacto.LogoCliente">
                                </div>
                            </div>
                        </div>
                        <div v-if="BndImagenLogo!=0">
                            <p class="text-danger text-leyenda">Aún no sube la imagen, presione guardar para subirla</p>
                        </div>
                    </div>

                    <div class="col-lg-2 p-0 m-0" style="flex:0 0 14.666667%;max-width:14.666667%;">
                        <div class="avatar-upload-histequipo" id="exterior">
                            <div v-show="!EsCliente" class="avatar-edit">
                                <input id="file2" @change="CambiarImagenExterior($event)" ref="file2" type="file" name="file2" accept=".png, .jpg, .jpeg">
                                <label for="file2"></label>
                            </div>
                            <div class="avatar-edit2">
                                <button type="button" data-backdrop="static"
                                data-toggle="modal"
                                data-target="#imagen" @click="ZoomImagen(RutaImagenExterior+IdClienteS+'/'+Contacto.ImagenExterior,2)">
                                    <i class="fas fa-search"></i>
                                </button>
                            </div>
                            <div class="avatar-preview">
                                <div id="ImagePreviewExterior" :style="'background-image: url('+RutaImagenExterior+IdClienteS+'/'+Contacto.ImagenExterior+');background-size:cover;background-repeat:no-repeat'" :src="RutaImagenExterior+IdClienteS+'/'+Contacto.ImagenExterior">
                                </div>
                            </div>
                            <div v-if="BndImagenExterior!=0" class="avatar-edit3">
                                <button type="button" @click="GuardarImagenExterior">
                                    <i class="fas fa-save"></i>
                                </button>
                            </div>
                        </div>
                        <div class="position-relative" style="right:5px" v-if="BndImagenExterior!=0">
                            <blockquote><p class="text-danger text-leyenda">Aún no sube la imagen, presione guardar para subirla</p></blockquote>
                        </div>
                    </div>

                    <div class="col-lg-3 p-0 m-0">
                        <span class="titulosmonitoreo">Cliente: </span> <span class="text-info titulosmonitoreo">{{ Contacto.Nombre }}</span>
							<br>
							<span class="titulosmonitoreo">N° Propiedades: </span> <span class="text-info titulosmonitoreo">{{ Contacto.Sucursales }}</span>  
                            <!-- <span class="titulosmonitoreo ml-3">Propiedades Restantes: </span>  -->
                            <!-- <span class="text-info titulosmonitoreo">{{ ContarPropiedades() }}</span> -->
							<br>
							<span class="titulosmonitoreo">N° Contratos: </span> <span class="text-info titulosmonitoreo">{{ Contacto.Contratos }}</span>
							<br>
                            <div class="d-flex mt-1">
                                <span class="titulosmonitoreo mr-2 mt-1">Propiedad:</span> 
                                <treeselect
                                    :disabled="DesactivarInputPropiedad"
                                    :maxHeight="180"
                                    :options="PropiedadesCombo"
                                    placeholder="Busque una Propiedad..."
                                    v-model="IdClienteS"
                                    @input="IdClienteS!=null ? SeleccionarPropiedad() : ''"
                                />
                            </div>
                            <!-- <span class="text-info titulosmonitoreo">{{ Equipo.Unidad }}</span> -->
							<!-- <br> -->
							<span class="titulosmonitoreo">Dirección: </span> <span class="text-info titulosmonitoreo">{{ Contacto.Direccion }}</span>
							<br>
                            <span class="titulosmonitoreo">Ciudad:</span> <span class="text-info titulosmonitoreo">{{ Contacto.Ciudad }}</span> <span class="titulosmonitoreo ml-4">Monitoreo:</span> <span class="text-info titulosmonitoreo">{{ Contacto.Monitoreo > 0 ? 'Si' : 'No' }}</span>
                            <br>
                            <span class="titulosmonitoreo">País:</span> <span class="text-info titulosmonitoreo">{{ Contacto.Pais=="null" ? '' : Contacto.Pais }}</span>  
                            <!-- <span class=" ml-4 titulosmonitoreo">Usuarios Restantes:</span>  -->
                            <!-- <span class="text-info titulosmonitoreo">{{ ContarUsuarios() }}</span> -->
                            <!-- <div class="row">
                                <div class="col-lg-6">
                                    <span class="titulosmonitoreo">Ciudad:</span> <span class="text-info titulosmonitoreo">{{ Contacto.Ciudad }}</span>
                                </div>
                                <div class="col-lg-6">
                                    <span class="titulosmonitoreo">Monitoreo:</span> <span class="text-info titulosmonitoreo">{{ Contacto.Monitoreo > 0 ? 'Si' : 'No' }}</span>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-6">
                                    <span class="titulosmonitoreo">País:</span> <span class="text-info titulosmonitoreo">{{ Contacto.Pais }}</span>
                                </div>
                                <div class="col-lg-6">
                                    <span class="titulosmonitoreo">Usuarios Restantes:</span> <span class="text-info titulosmonitoreo">{{ Contacto.Monitoreo > 0 ? 'Si' : 'No' }}</span>
                                </div>
                            </div> -->
							
                    </div>
                    <div class="col-lg-2">
                        <button v-show="!EsCliente" :disabled="BloquearAgregarPropiedad()" @click="NuevaPropiedad()"  data-toggle="modal" data-target="#ContactoSucursal"  data-backdrop="static" data-keyboard="false"  type="button" class="btn btn-pink mb-2 mr-1">
                            <i class="far fa-plus-circle"></i>
                            Nueva Propiedad
                        </button>
                    </div>
                </div>

                <div class="row mb-0">
                    <div class="col-lg-12">
						<div class="form-inline">
							<button @click="CambiarPestania('Propiedades')" type="button" :class="Botones.Propiedades ? 'btn btn-06 text-info bold m-0 pb-0 pr-2 pt-2' : 'btn btn-06 text-dark bold m-0 pb-0 pr-2 pt-2'">Propiedades</button>
                            <button v-show="IdClienteS!=0" @click="CambiarPestania('ServiciosFuturos')" type="button" :class="Botones.ServiciosFuturos ? 'btn btn-06 text-info bold m-0 pb-0 pr-2 pt-2' : 'btn btn-06 text-dark bold m-0 pb-0 pr-2 pt-2'">Servicios Futuros</button>
                            <button v-show="IdClienteS==0" class="btn btn-06 text-gray-300 bold m-0 pb-0 pr-2 pt-2" disabled>Servicios Futuros</button>
                            <button v-show="IdClienteS!=0" @click="CambiarPestania('HistorialServicios')" type="button" :class="Botones.HistorialServicios ? 'btn btn-06 text-info bold m-0 pb-0 pr-2 pt-2' : 'btn btn-06 text-dark bold m-0 pb-0 pr-2 pt-2'">Historial Servicios</button>
                            <button v-show="IdClienteS==0" class="btn btn-06 text-gray-300 bold m-0 pb-0 pr-2 pt-2" disabled>Historial Servicios</button>
							<button v-show="IdClienteS!=0" @click="CambiarPestania('Activos')" type="button" :class="Botones.Activos ? 'btn btn-06 text-info bold m-0 pb-0 pr-2 pt-2' : 'btn btn-06 text-dark bold m-0 pb-0 pr-2 pt-2'">Activos</button>
                            <button v-show="IdClienteS==0" class="btn btn-06 text-gray-300 bold m-0 pb-0 pr-2 pt-2" disabled>Activos</button>
                            <button v-show="IdClienteS!=0" @click="CambiarPestania('Contratos')" type="button" :class="Botones.Contratos ? 'btn btn-06 text-info bold m-0 pb-0 pr-2 pt-2' : 'btn btn-06 text-dark bold m-0 pb-0 pr-2 pt-2'">Contratos</button>
                            <button v-show="IdClienteS==0" class="btn btn-06 text-gray-300 bold m-0 pb-0 pr-2 pt-2" disabled>Contratos</button>
                            <button v-show="IdClienteS!=0" @click="CambiarPestania('Facturacion')" type="button" :class="Botones.Facturacion ? 'btn btn-06 text-info bold m-0 pb-0 pr-2 pt-2' : 'btn btn-06 text-dark bold m-0 pb-0 pr-2 pt-2'">Facturación</button>
                            <button v-show="IdClienteS==0" class="btn btn-06 text-gray-300 bold m-0 pb-0 pr-2 pt-2" disabled>Facturación</button>
                            <button v-show="IdClienteS!=0" @click="CambiarPestania('Propuestas')" type="button" :class="Botones.Propuestas ? 'btn btn-06 text-info bold m-0 pb-0 pr-2 pt-2' : 'btn btn-06 text-dark bold m-0 pb-0 pr-2 pt-2'">Propuestas</button>
                            <button v-show="IdClienteS==0" class="btn btn-06 text-gray-300 bold m-0 pb-0 pr-2 pt-2" disabled>Propuestas</button>
                            <button v-show="IdClienteS!=0" @click="CambiarPestania('Documentos')" type="button" :class="Botones.Documentos ? 'btn btn-06 text-info bold m-0 pb-0 pr-2 pt-2' : 'btn btn-06 text-dark bold m-0 pb-0 pr-2 pt-2'">Documentos</button>
                            <button v-show="IdClienteS==0" class="btn btn-06 text-gray-300 bold m-0 pb-0 pr-2 pt-2" disabled>Documentos</button>
                            <button class="btn btn-06 text-gray-300 bold m-0 pb-0 pr-2 pt-2" disabled>Ticket Servicio</button>
                            <!-- <button @click="CambiarPestania('TicketServicio')" type="button" :class="Botones.TicketServicio ? 'btn btn-06 text-info bold m-0 pb-0 pr-2 pt-2' : 'btn btn-06 text-dark bold m-0 pb-0 pr-2 pt-2'">Ticket Servicio</button> -->
                            <button v-show="!EsCliente" @click="CambiarPestania('Contactos')" type="button" :class="Botones.Contactos ? 'btn btn-06 text-info bold m-0 pb-0 pr-2 pt-2' : 'btn btn-06 text-dark bold m-0 pb-0 pr-2 pt-2'">Contactos</button>
                            <!-- <button v-show="IdClienteS!=0" @click="CambiarPestania('Cotizaciones')" type="button" :class="Botones.Cotizaciones ? 'btn btn-06 text-info bold m-0 pb-0 pr-2 pt-2' : 'btn btn-06 text-dark bold m-0 pb-0 pr-2 pt-2'">Cotizaciones</button>
                            <button v-show="IdClienteS==0" class="btn btn-06 text-gray-300 bold m-0 pb-0 pr-2 pt-2" disabled>Cotizaciones</button> -->
                            <button v-show="!EsCliente && IdClienteS!=0" @click="CambiarPestania('Ubicacion')" type="button" :class="Botones.Ubicacion ? 'btn btn-06 text-info bold m-0 pb-0 pr-2 pt-2' : 'btn btn-06 text-dark bold m-0 pb-0 pr-2 pt-2'">Ubicación</button>
                            <button v-show="!EsCliente && IdClienteS==0" type="button" class="btn btn-06 text-gray-300 bold m-0 pb-0 pr-2 pt-2" disabled>Ubicación</button>
                            <!-- <button v-if="RolUsuario!='Monitoreo' && IdPerfil!=0" @click="CambiarPestania('Refacciones')" type="button" :class="Botones.Refacciones ? 'btn btn-06 text-info bold' : 'btn btn-06 text-dark bold'">Refacciones</button>
							<button v-else class="btn btn-06 text-gray-300 bold" disabled>Refacciones</button>
							<button v-if="RolUsuario!='Monitoreo' && IdPerfil!=0" @click="CambiarPestania('Programa')" type="button" :class="Botones.Programa ? 'btn btn-06 text-info bold' : 'btn btn-06 text-dark bold'">Programa Mantenimiento</button>
							<button v-else class="btn btn-06 text-gray-300 bold" disabled>Programa  Mantenimiento</button> -->
						</div>
					</div>
                </div>
            </div>
        </template>
        <template v-if="Botones.Propiedades" slot="header">
            <tr>
                <th>Nombre</th>
                <th>Tipo Propiedad</th>
                <th>Dirección</th>
                <th>Estado</th>
                <th>Ciudad</th>
                <th>Teléfono</th>
                <th class="text-center">Total Activos</th>
                <th class="text-center">Operando</th>
                <th class="text-center">Observación</th>
                <th class="text-center">Detenidos</th>
                <th class="text-center">Servicios</th>
                <th class="text-center">Tickets</th>
                <th>Estatus</th>
                <th v-show="!EsCliente">Acciones</th>
            </tr>
        </template>
        <template v-if="Botones.Propiedades" slot="body">
            <tr v-show="!EsCliente || (EsCliente && EsClienteMatriz)">
                <td>{{ ContactoMatriz.Nombre }}</td>
                <td>Matriz</td>
                <td>{{ $limitCharacters(ContactoMatriz.Direccion,40) }}</td>
                <td>{{ ContactoMatriz.Estado == 'null' ? '' : ContactoMatriz.Estado }}</td>
                <td>{{ ContactoMatriz.Ciudad }}</td>
                <td>{{ ContactoMatriz.Telefono }}</td>
                <td class="text-center">{{ ContadorMatriz("totalactivos") }}</td>
                <td class="text-center">{{ ContadorMatriz("operando") }}</td>
                <td class="text-center">{{ ContadorMatriz("observacion") }}</td>
                <td class="text-center">{{ ContadorMatriz("detenidos") }}</td>
                <td class="text-center">{{ ContadorMatriz("servicios") }}</td>
                <td class="text-center">0</td>
                <td>Matriz</td>
                <td v-show="!EsCliente">
                    <Cbtnaccion 
                    :isModal="true" 
                    :pEmitSeccion="ConfigModalMatriz.EmitSeccion" 
                    :Id="Contacto.IdCliente"
                    :pShowButtonDelete="false"
                    :IrA="ConfigModalMatriz.Nombre"></Cbtnaccion> 
                </td>
            </tr>
            <tr v-for="(item,index) in Propiedades" :key="index">
                <td>{{ item.Nombre }}</td>
                <td>{{ ExtraerTipoPropiedad(item.IdIconoEmp) }}</td>
                <td>{{ $limitCharacters(item.Direccion,40) }}</td>
                <td>{{ item.Estado == 'null' ? '' : item.Estado }}</td>
                <td>{{ item.Ciudad }}</td>
                <td>{{ item.Telefono }}</td>
                <td class="text-center"> {{ isNaN(item.TotalActivos) ? 0 : item.TotalActivos }} </td>
                <td class="text-center"> {{ isNaN(item.Operando) ? 0 : item.Operando }} </td>
                <td class="text-center"> {{ isNaN(item.Observacion) ? 0 : item.Observacion }} </td>
                <td class="text-center"> {{ isNaN(item.Detenidos) ? 0 : item.Detenidos }} </td>
                <td class="text-center"> {{ isNaN(item.Servicios) ? 0 : item.Servicios }} </td>
                <td class="text-center"> 0 </td>
                <td>{{ item.Estatus }}</td>
                <td v-show="!EsCliente"> 
                    <Cbtnaccion 
                    :isModal="true" 
                    :pEmitSeccion="ConfigModalPropiedad.EmitSeccion" 
                    :Id="item.IdClienteS"
                    :IrA="ConfigModalPropiedad.Nombre" ></Cbtnaccion> 
                </td>
            </tr>
        </template>

        <template v-if="Botones.HistorialServicios || Botones.ServiciosFuturos" slot="header">
            <tr>
                <th>Servicio</th>
                <th>Fecha</th>
                <th>Tipo de Servicio</th>
                <th>Propiedad</th>
                <th>Líder Servicio</th>
                <th>Tareas</th>
                <th v-show="!EsCliente || (EsCliente && Botones.HistorialServicios)">Acciones</th>
            </tr>
        </template>
        <template v-if="Botones.HistorialServicios || Botones.ServiciosFuturos" slot="body">
            <tr v-for="(item,index) in HistorialServicios" :key="index">
                <td>{{ item.Folio }}</td>
                <td><i class="fa fa-calendar"></i> {{ $getCleanDate(item.Fecha_I, false) }}</td>
                <td>{{ item.Servicio }}</td>
                <td>{{ item.Cliente }}</td>
                <td>{{ item.Nombre == null ? 'No Asignado' : item.Nombre }}</td>
                <td>{{ $limitCharacters(item.Observaciones,30) }}</td>
                <td>
                    <button class="btn-icon"
                        v-show="Botones.ServiciosFuturos"
                        v-b-tooltip.hover.left
                        type="button"
                        data-backdrop="static"
						data-keyboard="false"
						title="Ver Servicio"
                        @click="AbrirServicio(item.IdServicio)"
                        data-toggle="modal"
                        data-target="#Servicio"
                    ><i class="fa fa-eye"></i></button>
                    <button v-if="Botones.HistorialServicios"
						v-b-tooltip.hover.left
						@click="DescargarReporte(item.IdServicio)"
						type="button"
						data-backdrop="static"
						data-keyboard="false"
						class="btn-icon mr-2"
						title="Ver Reporte de Servicio"
					>
						<i 
						:id="'pdfOrden_'+item.IdServicio"
						class="fas fa-file-pdf fa-fw-m"></i>
					</button>

                    <button v-if="Botones.HistorialServicios"
						v-b-tooltip.hover.left
						@click="DescargarEvidencia(item.IdServicio)"
						type="button"
						data-backdrop="static"
						data-keyboard="false"
						class="btn-icon mr-2"
						title="Ver Reporte Fotográfico"
					>
						<i 
						:id="'pdfOrden_'+item.IdServicio"
						class="fas fa-file-pdf fa-fw-m"></i>
					</button>
                </td>
            </tr>
            <CSinRegistros :pContIF="HistorialServicios.length" :pColspan="7" ></CSinRegistros>
        </template>

        <template v-if="Botones.Activos" slot="Filtros">
            <label for="">Estatus</label>
            <select v-model="FiltroEquipo" @change="Lista" class="form-control ml-2">
                <option value="">Seleccione un Estatus</option>
                <option value="Operando">Operando</option>
                <option value="En Observacion">En Observacion</option>
                <option value="Fuera de Servicio">Fuera de Servicio</option>
            </select>
        </template>
        <template v-if="Botones.Activos" slot="header">
            <tr>
                <th>Id Activo</th>
                <th>Tipo de Equipo</th>
                <th>Marca</th>
                <th>Modelo</th>
                <th>No° Serie</th>
                <th>Ubicación Física</th>
                <th>Año Fabricación</th>
                <th>Año Reemplazo</th>
                <th>Valor Aproximado</th>
                <th>Código Cliente</th>
                <th>Id Equipo</th>
                <th>Estatus</th>
                <th>Acciones</th>
            </tr>
        </template>
        <template v-if="Botones.Activos" slot="body">
            <tr v-for="(item,index) in Activos" :key="index">
                <td>{{ item.Nequipo }}</td>
                <td>{{ item.Unidad }}</td>
                <td>{{ item.Marca }}</td>
                <td>{{ item.Modelo }}</td>
                <td>{{ item.Serie }}</td>
                <td>{{ item.Ubicacion }}</td>
                <td>{{ item.Ano }}</td>
                <td>{{ item.AnoReemplazo==0 ? 'No Asignado' : item.AnoReemplazo}}</td>
                <td>{{ item.Valor==null ? 'No Asignado' : item.Valor }}</td>
                <td>{{ item.ActivoCliente }}</td>
                <td>{{ item.CodigoActivo }}</td>
                <td class="text-center">
                    <div v-b-tooltip.hover :title="item.Status" :class="DefinirClaseActivo(item.Status)"></div>
                </td>
                <td>
                    <button @click="IrAEquipo(item)" v-b-tooltip.hover title="Ver Más" class="btn-icon" type="button"><i class="fa fa-eye"></i></button>
                </td>
            </tr>
            <CSinRegistros :pContIF="Activos.length" :pColspan="13"></CSinRegistros>
        </template>

        <template v-if="Botones.Facturacion" slot="header">
            <tr>
                <th>Contacto</th>
                <th>Propiedad</th>
                <th>Servicio</th>
                <th>Prefactura</th>
                <th>Facturado</th>
                <th>No° Prefactura</th>
                <th>Folio</th>
                <th>Folio Fact. Real</th>
                <th>Monto</th>
                <th>Acciones</th>
            </tr>
        </template>
        <template v-if="Botones.Facturacion" slot="body">
            <tr v-for="(item,index) in Facturas" :key="index">
                <td>{{ item.NombreCliente }}</td>
                <td>{{ item.Sucursal }}</td>
                <td><i class="fa fa-calendar"></i> {{ item.FechaRegFormat }}</td>
                <td><i class="fa fa-calendar"></i> {{ $getCleanDate(item.FechaReg,false) }}</td>
                <td><i class="fa fa-calendar"></i> {{ item.FechaFacRealF }}</td>
                <td>{{ item.FolioFactura }}</td>
                <td>{{ item.FolioServ }}</td>
                <td>{{ item.FolioFactReal }}</td>
                <td>$ {{ Number(item.Monto).toLocaleString('es-MX') }}</td>
                <td>
                    <button v-show="!EsCliente" v-b-tooltip.hover title="Editar" class="btn btn-table pl-01 mr-1" @click="AbrirFactura(index,item.IdFactura,item.DiasCredito,item.Observacion,item.FechaFacReal,item.IdServicio)" type="button" data-toggle="modal" data-target="#Factura"  data-backdrop="static"  >
                        <i class="fas fa-pencil-alt fa-fw-m"></i>
                    </button>
                    <button @click="DescargarFactura(item.IdFactura)" class="btn-icon pl-01 mr-1" type="button" v-b-tooltip.hover title="Prefactura"><i class="fas fa-file-pdf fa-fw-m"></i></button>
                </td>
            </tr>
            <CSinRegistros :pContIF="Facturas.length" :pColspan="10"></CSinRegistros>
        </template>

        <template v-if="Botones.Propuestas" slot="header">
            <tr>
                <th>Propiedad</th>
                <th>Ciudad</th>
                <th>Número</th>
                <th>Nombre Propuesta</th>
                <th>Nombre Equipo</th>
                <th>Valor</th>
                <th>Fecha</th>
                <th>Vigencia</th>
                <th>Urgencia</th>
                <th class="text-center">Documentación</th>
                <th>Estatus</th>
                <th v-show=!EsCliente>Acciones</th>
                <th v-show="!EsCliente">
                    <button @click="NuevaPropuesta()" type="button" data-toggle="modal" data-target="#Propuesta" class="btn-obs"><i class="fa fa-plus" title="Agregar Propuesta"></i></button>
                </th>
            </tr>
        </template>
        <template v-if="Botones.Propuestas" slot="body">
            <tr v-for="(item,index) in Propuestas" :key="index">
                <td>{{ item.Propiedad }}</td>
                <td>{{ item.Ciudad }}</td>
                <td>{{ item.Numero }}</td>
                <td>{{ item.NombrePropuesta }}</td>
                <td>{{ item.NombreEquipo }}</td>
                <td>$ {{ item.Valor }}</td>
                <td><i class="fa fa-calendar"></i> {{ $getCleanDate(item.Fecha,false) }}</td>
                <td>{{ item.Vigencia }}</td>
                <td>{{ item.Urgencia }}</td>
                <td class="text-center"><button @click="AbrirDocumento(item.Archivo)" class="btn-icon" title="Ver Documentación"><i class="fa fa-file-pdf"></i></button></td>
                <td>{{ item.Estatus }}</td>
                <td v-show=!EsCliente>
                    <Cbtnaccion 
                    :isModal="true" 
                    :pEmitSeccion="ConfigModalPropuesta.EmitSeccion" 
                    :Id="item.IdPropuesta"
                    :IrA="ConfigModalPropuesta.Nombre"
                    :pShowBtnEdit="EsCliente ? false : true"
                    :pShowBtnDelete="EsCliente ? false : true"></Cbtnaccion>
                </td>
                <td v-show="!EsCliente"></td>
            </tr>
            <CSinRegistros :pContIF="Propuestas.length" :pColspan="13"></CSinRegistros>
        </template>

        <template v-if="Botones.Contratos" slot="header">
            <tr>
                <th>Nombre Contrato</th>
                <th>Tipo Contrato</th>
                <th>Trabajo</th>
                <th>Valor</th>
                <th>Fecha Inicio</th>
                <th>Fecha Término</th>
                <th>Facturación</th>
                <th>Comentarios</th>
                <th>Documento</th>
                <th>Estatus</th>
                <th v-show="!EsCliente">Acciones</th>
                <th v-show="!EsCliente"><button @click="NuevoContrato()" type="button" data-toggle="modal" data-target="#Contratos" class="btn-obs"><i class="fa fa-plus" title="Agregar Contrato"></i></button></th>
            </tr>
        </template>
        <template v-if="Botones.Contratos" slot="body">
            <tr v-for="(item,index) in Contratos" :key="index">
                <td>{{ item.NumeroC }}</td>
                <td>{{ item.TipoContrato }}</td>
                <td>{{ item.Tipo }}</td>
                <td>$ {{ $numberTo(parseFloat(item.Valor)) }}</td>
                <td>{{ !isNaN(item.FechaInicio) ? 'S/F' : $getCleanDate(item.FechaInicio,false) }}</td>
                <td>{{ !isNaN(item.FechaInicio) ? 'S/F' : $getCleanDate(item.FechaTermino,false) }}</td>
                <td>{{ item.Facturacion }}</td>
                <td>{{ $limitCharacters(item.Comentario,30) }}</td>
                <td>
                    <button v-if="item.Archivo!='' && item.Archivo !=null" @click="AbrirDocumento(RutaPdfContrato + item.Archivo)" title="Ver Documento" class="btn-icon mr-2"><i class="fa fa-file-pdf"></i></button>
                    <span v-else>No Contiene Archivo</span>
                </td>
                <td>{{ item.Estatus }}</td>
                <td v-show="!EsCliente">
                    <Cbtnaccion 
                    :isModal="true" 
                    :pEmitSeccion="ConfigModalContratos.EmitSeccion" 
                    :Id="item.IdContrato"
                    :IrA="ConfigModalContratos.Nombre" ></Cbtnaccion>
                </td>
                <td v-show="!EsCliente"></td>
            </tr>
            <CSinRegistros :pContIF="Contratos.length" :pColspan="12" ></CSinRegistros>
        </template>

        <template v-if="Botones.Contactos" slot="header">
            <tr>
                <th>Nombre</th>
                <th v-show="IdClienteS==0">Propiedad</th>
                <th v-show="IdClienteS==0">Tipo</th>
                <th>Posición</th>
                <th>Teléfono</th>
                <th>Celular</th>
                <th>Email</th>
                <!-- <th>Password</th> -->
                <th>Usuario Monitoreo</th>
                <th>Acciones</th>
                <th><button @click="NuevoContacto()" type="button" data-toggle="modal" data-target="#Contactos" class="btn-obs"><i class="fa fa-plus" title="Agregar Contacto"></i></button></th>
            </tr>
        </template>
        <template v-if="Botones.Contactos" slot="body">
            <tr v-for="(item,index) in Contactos" :key="index">
                <td>{{ item.Nombre }}</td>
                <td v-show="IdClienteS==0">{{ item.Propiedad==null ? ContactoMatriz.Nombre : item.Propiedad }}</td>
                <td v-show="IdClienteS==0">{{ item.Propiedad==null ? 'Matriz' : 'Propiedad'}}</td>
                <td>{{ item.Posicion }}</td>
                <td>{{ item.Telefono }}</td>
                <td>{{ item.Celular }}</td>
                <td>{{ item.Email }}</td>
                <td>{{ $capitalize(item.UsuarioMonitoreo) }}</td>
                <td>
                    <Cbtnaccion 
                    :isModal="true" 
                    :pEmitSeccion="ConfigModalContactos.EmitSeccion" 
                    :Id="item.IdContacto"
                    :pObj="item"
                    :IrA="ConfigModalContactos.Nombre"></Cbtnaccion>
                </td>
                <td></td>
            </tr>
            <CSinRegistros :pContIF="Contactos.length" :pColspan="9" ></CSinRegistros>
        </template>
        <template v-if="Botones.Cotizaciones" slot="header">
            <tr>
                <th>Folio</th>
                <th>Contacto</th>
                <th>Propiedad</th>
                <th class="text-center">Gross Profit</th>
                <th class="text-center">Utilidad Aproximada</th>
                <th class="text-center">Costo Total</th>
                <th class="text-center">Valor de Venta</th>
                <th class="text-center">Fecha</th>
                <th>Acciones</th>
            </tr>
        </template>
        <template v-if="Botones.Cotizaciones" slot="body">
            <tr v-for="(item,index) in Cotizaciones" :key="index">
                <td>{{ item.Folio }}</td>
                <td>{{ item.Cliente }}</td>
                <td>{{ item.Sucursal }}</td>
                <td class="text-center">{{ item.GrossProfit }}%</td>
                <td class="text-center">{{ item.utilidad }}%</td>
                <td class="text-center">${{ Number(item.totalGlobal).toLocaleString() }}</td>
                <td class="text-center">${{ Number(item.ValorVenta).toLocaleString() }}</td>
                <td class="text-center"><i class="fa fa-calendar"></i> {{ item.fechaCotiServicio }}</td>
                <td>
                    <Cbtnaccion :pEmitSeccion="'Cotizacion'" :isModal="false" :Id="item.IdCotizacionServicio" :IrA="'cotizacion_principal'">

                    </Cbtnaccion>
                </td>
            </tr>
            <CSinRegistros :pContIF="Cotizaciones.length" :pColspan="9" ></CSinRegistros>
        </template>

        <template v-if="Botones.Documentos" slot="header">
            <tr>
                <th>Nombre</th>
                <th>Descripcion</th>
                <th>Fecha</th>
                <th>Entregado A</th>
                <th>Posición</th>
                <th>Acciones</th>
                <th v-show="!EsCliente">
                    <button @click="NuevoDocumento()" type="button" data-toggle="modal" data-target="#DocumentoCm" class="btn-obs"><i class="fa fa-plus" title="Agregar Documento"></i></button>
                </th>
            </tr>
        </template>
        <template v-if="Botones.Documentos" slot="body">
            <tr v-for="(item,index) in Documentos" :key="index">
                <td>{{ item.Nombre }}</td>
                <td>{{ $limitCharacters(item.Descripcion,40) }}</td>
                <td><i class="fa fa-calendar"></i> {{ $getCleanDate(item.Fecha,false) }}</td>
                <td>{{ item.EntregadoA }}</td>
                <td>{{ item.Posicion }}</td>
                <td>
                    <Cbtnaccion 
                    :isModal="true" 
                    :pEmitSeccion="ConfigModalDocumentosCm.EmitSeccion" 
                    :Id="item.IdDocumentoCm"
                    :pShowButtonEdit="EsCliente ? false : true"
                    :pShowButtonDelete="EsCliente ? false : true"
                    :IrA="ConfigModalDocumentosCm.Nombre">
                        <template slot="btnaccion">
                            <button v-if="item.Archivo!='' && item.Archivo!='Elegir Archivo (5MB)'" @click="AbrirDocumento(item.Ruta + item.Archivo)" class="btn-icon mr-2" title="Ver Documentación"><i class="fa fa-file-pdf"></i></button>
                        </template>
                    </Cbtnaccion>
                </td>
                <td v-show="!EsCliente"></td>
            </tr>
            <CSinRegistros :pContIF="Documentos.length" :pColspan="7" ></CSinRegistros>
        </template>

        <template v-if="Botones.Ubicacion" slot="mapa">
            <div v-if="BndMostrarMapa" class="position-relative" style="bottom:30px;z-index:1000">
                <Search :oLatLng="oLatLng" :Input="false"></Search>
            </div>
        </template>
        </Clist>

        <Modal :Showbutton="false" :NameModal="'imagen'" :size="'modal-lg'" :Nombre="'Imagen'">
			<template slot="Form">
				<div class="form-row justify-content-center align-items-center">
					<img :src="ImagenAMostrar" alt="Aún no hay una imagen para mostrar" class="img-thumbnail">
				</div>
			</template>
		</Modal>

        <Modal :pEmitSeccion="ConfigModalPropiedad.EmitSeccion" :NameModal="ConfigModalPropiedad.NombreSeccion"  :size="ConfigModalPropiedad.Size" :Nombre="'Propiedad'" :poBtnSave="oBtnSavePropiedad">
            <template slot="Form">
                <FormPropiedad :pRutaCliente="pRuta" :pContadorPropiedades="ContarPropiedades()" :pContadorMonCliente="ContarPropiedadesMonCliente()" :NameList="ConfigModalPropiedad.NameForm" :ocliente="pContacto" @titulomodal="Change" :poBtnSave="oBtnSavePropiedad" 
                :pRutaIconoEmp="ConfigModalPropiedad.RutaIcoEmp">
                </FormPropiedad>
            </template>
        </Modal>

        <Modal :pEmitSeccion="ConfigModalServicio.Emitseccion" :NameModal="ConfigModalServicio.NombreSeccion" :size="ConfigModalServicio.Size" :Nombre="'Servicio'" :Showbutton="false">
            <template slot="Form">
                <FormServicios :pEmitSeccion="ConfigModalServicio.EmitSeccion"></FormServicios>
            </template>
        </Modal>

        <Modal :pEmitSeccion="ConfigModalActivos.EmitSeccion" :NameModal="ConfigModalActivos.NombreSeccion"  :size="ConfigModalActivos.Size" :Nombre="'Activos'" :poBtnSave="oBtnSaveActivo">
            <template slot="Form">
                <FormActivos :NameList="ConfigModalActivos.NameForm" :oClienteSucursalP="Contacto" :poBtnSave="oBtnSaveActivo">
                </FormActivos>
            </template>
        </Modal>

        <Modal :pEmitSeccion="ConfigModalContratos.EmitSeccion" :NameModal="ConfigModalContratos.NombreSeccion"  :size="ConfigModalContratos.Size" :Nombre="'Contratos'" :poBtnSave="oBtnSaveContratos">
            <template slot="Form">
                <FormContratos :NameList="ConfigModalContratos.NameForm" :pIdClienteS="IdClienteS" :poBtnSave="oBtnSaveContratos">
                </FormContratos>
            </template>
        </Modal>

        <Modal :pEmitSeccion="ConfigModalFactura.EmitSeccion" :NameModal="ConfigModalFactura.NombreSeccion"  :size="ConfigModalFactura.Size" :Nombre="'Factura'" :poBtnSave="oBtnSaveFactura">
            <template slot="Form">
                <FormFactura :pOrigen="'cm'" :factura="factura" @Listar="Lista()" :NameList="ConfigModalFactura.NameForm" :poBtnSave="oBtnSaveFactura">
                </FormFactura>
            </template>
        </Modal>
        
        <Modal :pEmitSeccion="ConfigModalPropuesta.EmitSeccion" :NameModal="ConfigModalPropuesta.NombreSeccion"  :size="ConfigModalPropuesta.Size" :Nombre="'Propuesta'" :poBtnSave="oBtnSavePropuesta">
            <template slot="Form">
                <FormPropuesta :pIdClienteS="IdClienteS" :pContacto="Contacto" :NameList="ConfigModalPropuesta.NameForm" :poBtnSave="oBtnSavePropuesta">
                </FormPropuesta>
            </template>
        </Modal>

        <Modal :pEmitSeccion="ConfigModalContactos.EmitSeccion" :NameModal="ConfigModalContactos.NombreSeccion"  :size="ConfigModalContactos.Size" :Nombre="IdClienteS==0 ? 'Contactos de la Matriz' : 'Contactos de la Propiedad '+ContactoPropiedad" :poBtnSave="oBtnSaveContactos">
            <template slot="Form">
                <FormContactos :NameList="ConfigModalContactos.NameForm" :pIdClienteS="IdClienteS" :pIdCliente="ContactoMatriz.IdCliente" :poBtnSave="oBtnSaveContactos" :pContadorUsuariosMonitoreo="ContadorUsuariosMonitoreo" :pContadorPaqueteMonitoreo="ContadorPaqueteMonitoreo" :pContadorMatriz="ContadorUsuariosMatriz" :pCheckMonCli="Contacto.CheckMonCli">
                </FormContactos>
            </template>
        </Modal>

        <Modal :pEmitSeccion="ConfigModalDocumentosCm.EmitSeccion" :NameModal="ConfigModalDocumentosCm.NombreSeccion"  :size="ConfigModalDocumentosCm.Size" :Nombre="'Documentos'" :poBtnSave="oBtnSaveDocumentosCm">
            <template slot="Form">
                <FormDocumentosCm :NameList="ConfigModalDocumentosCm.NameForm" :poBtnSave="oBtnSaveDocumentosCm">
                </FormDocumentosCm>
            </template>
        </Modal>

        <Modal :pEmitSeccion="ConfigModalMatriz.EmitSeccion" :NameModal="ConfigModalMatriz.NombreSeccion"  :size="ConfigModalMatriz.Size" :Nombre="'Contacto'" :poBtnSave="oBtnSaveMatriz">
            <template slot="Form">
                <FormMatriz :NameList="ConfigModalMatriz.NameForm" :pIdClienteS="IdClienteS" :IdCliente="Contacto.IdCliente" :poBtnSave="oBtnSaveMatriz" :pContadorUsuariosMonitoreo="ContadorUsuariosMonitoreo">
                </FormMatriz>
            </template>
        </Modal>
    </div>
</template>

<script>
import FormMatriz from '../contactos/form.vue';
import FormPropiedad from '../contactosucursal/Form.vue';
import FormServicios from './VisorServicio.vue';
import FormActivos from '@/views/catalogos/equipos/Form.vue';
import FormContratos from '@/views/catalogos/contratos/Form.vue';
import FormFactura from '@/views/modulos/factura/FormChange.vue';
import FormPropuesta from '@/views/catalogos/propuestas/Form.vue';
import FormContactos from '@/views/catalogos/contactos/Form.vue';
import FormDocumentosCm from '@/views/catalogos/documentocm/Form.vue'
import Modal from '@/components/Cmodal.vue';
import Clist from "@/components/Clist.vue";
import Cbtnaccion from "@/components/Cbtnaccion.vue";
import CSinRegistros from "@/components/CSinRegistros";
import Config from "@/helpers/VarConfig.js";
export default {
    name:"listPropiedades",
    props:['pContacto',
    'pRuta',
    'pRutaExterior',
    'pContadorUsuarios',
    'pContadorPaqueteMonitoreo',
    'pContadorPaquetePropiedad',
    'pContadorPaquetePropiedadBase',
    'pContadorPaquetePropiedadMonCliente',
    'pContadorPropiedades',
    'pContadorPropiedadesBase',
    'pContadorPropiedadesMonCliente',
    'pRutaLogoSucursal',
    'pRutaExteriorSucursal',
    'pOrigen'],
    components:{
        Clist,
        FormMatriz,
        CSinRegistros,
        FormPropiedad,
        FormServicios,
        FormActivos,
        FormContratos,
        FormContactos,
        FormFactura,
        FormPropuesta,
        FormDocumentosCm,
        Modal,
        Cbtnaccion
    },
    data() {
        return {
            Titulo:"Contacto",
            Regresar:true,
            EsModal:true,
            Filtro:{
                Nombre: "",
				Placeholder: "Nombre..",
				TotalItem: 0,
				Pagina: 1,
				Entrada: 8,
				Show:true
            },
            ShowPager:true,
            ConfigModalMatriz:{
                NombreSeccion:"Contacto",
                EmitSeccion:"Contacto",
                Size:"modal-lg",
                Nombre:"Contacto",
                NameForm:"Contacto"
            },
            ConfigModalPropiedad:{
                NombreSeccion:"ContactoSucursal",
                EmitSeccion:"ContactoSucursal",
                Size:"modal-lg",
                Nombre:"Sucursal",
                NameForm:"Sucursal del contacto : ",
                RutaIcoEmp:""
            },
            ConfigModalServicio:{
                NombreSeccion:"Servicio",
                EmitSeccion:"Servicio",
                Size:"modal-lg",
                Nombre:"Servicio",
                NameForm:"Servicio",
            },
            ConfigModalActivos:{
                NombreSeccion:"Activo",
                EmitSeccion:"Activo",
                Size:"modal-lg",
                Nombre:"Activo",
                NameForm:"Nuevo Activo",
            },
            ConfigModalContratos:{
                NombreSeccion:"Contratos",
                EmitSeccion:"Contratos",
                Size:"modal-xl",
                Nombre:"Contratos",
                NameForm:"Contratos"
            },
            ConfigModalFactura:{
                NombreSeccion:"Factura",
                EmitSeccion:"Factura",
                Size:"modal-lg",
                Nombre:"Factura",
                NameForm:"Factura"
            },
            ConfigModalPropuesta:{
                NombreSeccion:"Propuesta",
                EmitSeccion:"Propuesta",
                Size:"modal-lg",
                Nombre:"Propuesta",
                NameForm:"Propuesta"
            },
            ConfigModalContactos:{
                NombreSeccion:"Contactos",
                EmitSeccion:"Contactos",
                Size:"modal-lg",
                Nombre:"Contactos",
                NameForm:"Contactos"
            },
            ConfigModalDocumentosCm:{
                NombreSeccion:"DocumentoCm",
                EmitSeccion:"DocumentoCm",
                Size:"modal-lg",
                Nombre:"DocumentoCm",
                NameForm:"DocumentoCm"
            },
            ConfigLoad:{
                ShowLoader:true,
                ClassLoad:true,
            },
            oBtnSavePropiedad:{
                isModal:true,
                disableBtn:false,
                toast:0,
                nombreModal:"ContactoSucursal",
                EmitSeccion:"ContactoSucursal"
            },
            oBtnSaveActivo:{
                isModal:true,
                disableBtn:false,
                toast:0,
                nombreModal:"Activo",
                EmitSeccion:"Activo"
            },
            oBtnSaveContratos:{
                isModal:true,
                disableBtn:false,
                toast:0,
                nombreModal:"Contratos",
                EmitSeccion:"Contratos"
            },
            oBtnSaveFactura:{
                isModal:true,
                disableBtn:false,
                toast:0,
                nombreModal:"Factura",
                EmitSeccion:"Factura"
            },
            oBtnSavePropuesta:{
                isModal:true,
                disableBtn:false,
                toast:0,
                nombreModal:"Propuesta",
                EmitSeccion:"Propuesta"
            },
            oBtnSaveContactos:{
                isModal:true,
                disableBtn:false,
                toast:0,
                nombreModal:"Contactos",
                EmitSeccion:"Contactos"
            },
            oBtnSaveDocumentosCm:{
                isModal:true,
                disableBtn:false,
                toast:0,
                nombreModal:"DocumentoCm",
                EmitSeccion:"DocumentoCm"
            },
            oBtnSaveMatriz:{
                isModal:true,
                disableBtn:false,
                toast:0,
                nombreModal:"Contacto",
                EmitSeccion:"Contacto"
            },
            DesactivarInputPropiedad:false,
            RutaImagen:"",
            RutaImagenExterior:"",
            RutaImagenLogoContacto:"",
            RutaImagenExteriorContacto:"",
            ImagenExteriorSeleccionada:"",
            // RutaImagenLogoSucursal:"",
            // RutaImagenExteriorSucursal:"",
            RutaPdfContrato:"",
            BndMostrarMapa:false,
            ValidElement:Config.validImage2m,
            EsCliente:false,
            EsClienteMatriz:false,
            ContadorImagenLogo:0,
            ContadorImagenExterior:0,
            ContadorUsuarios:0,
            ContadorPaqueteMonitoreo:0,
            ContadorPropiedades:0,
            ContadorPaquetePropiedades:0,
            ContadorPropiedadesBase:0,
            ContadorPaquetePropiedadBase:0,
            ContadorPropiedadesMonCliente:0,
            ContadorPaquetePropiedadMonCliente:0,
            BndImagenLogo:0,
            BndImagenExterior:0,
            ObjImagenExterior:{},
            ObjImagenLogo:{},
            ImagenAMostrar:"",
            PermisoAdmin:{},
            factura:{
                IdFactura:0,
                IdServicio:0,
                Motivo:'',
                Folio:'',
                Monto:'',
                RegEstatus:'A',
                FolioFactReal:'',
                FechaFacReal:'',
                File:'',
                FilePrevious:'',
                ComentarioAnulada:'',
                DiasCredito:'',
                FolioFactura:'',
                Observacion:'',
                FechaCobro:'',
                FechaAnulado:'',
                Facturado:'',
                ComentarioCancel:''
            },
            Contacto:{
                // Nombre:"",
                // NPropiedades:0,
                // NContratos:0,
                // Propiedad:0,
                // Direccion:"",
                // Ciudad:"",
                // Pais:"",
                // Monitoreo:"",
                // LocoCliente:"",
                // ImagenExterior:""
            },
            ContactoMatriz:{},
            IdClienteS:null,
            IdClienteMatriz:null,
            // ClienteSucursal:{

            // },
            ContadorLista:0,
            Propiedades:[],
            PropiedadesCompletas:[],
            PropiedadesCombo:[],
            HistorialServicios:[],
            ServiciosFuturos:[],
            Activos:[],
            Contratos:[],
            Facturas:[],
            Propuestas:[],
            Contactos:[],
            Cotizaciones:[],
            Documentos:[],
            FiltroEquipo:"",
            ContactoPropiedad:"",
            Botones:{
                Propiedades:true,
                ServiciosFuturos:false,
                HistorialServicios:false,
                Activos:false,
                Contratos:false,
                Facturacion:false,
                Propuestas:false,
                TicketServicio:false,
                Contactos:false,
                Cotizaciones:false,
                Documentos:false,
                Ubicacion:false,
            },
            PropiedadesTodas:[],
            ContadorUsuariosMonitoreo:0,
            ContadorUsuariosMatriz:0,
            oLatLng:{
				// Lat:parseFloat(this.ClienteSucursal.Latitud),
				// Lng:parseFloat(this.ClienteSucursal.Longitud)
			},
            OperacionPropiedad:"Inicio",
            ConsultaGuardada:{}
        }
    },
    methods: {
        async Lista(obj=null,Camino=null){
            this.Filtro.Show=true;
            this.ShowPager=true;
            this.ConfigLoad.ShowLoader=true;
            this.Filtro.Placeholder='Nombre...';
            if (this.Botones.Propiedades) {
                let params={
                    IdCliente:this.Contacto.IdCliente,
					Nombre: this.Filtro.Nombre,
					Entrada: this.Filtro.Entrada,
					pag: this.Filtro.Pagina,
					RegEstatus: "A",
                    isPropiedades:true,
                    IdClienteS:''
                }
                if (this.EsCliente && !this.EsClienteMatriz) {
                    params.IdClienteS=JSON.parse(sessionStorage.getItem("propiedad")).IdClienteS;
                }
                await this.$http.get('clientesucursal/get',{
                    params:params
                }).then((res)=>{
                    this.Propiedades = res.data.data.clientesucursal;
                    this.PropiedadesTodas=res.data.data.all;
					this.Filtro.Entrada = res.data.data.pagination.PageSize;
					this.Filtro.TotalItem = res.data.data.pagination.TotalItems;
                    this.ConfigModalPropiedad.RutaIcoEmp=res.data.RutaIcoEmp;
                }).finally(()=>{
                    if (this.ContadorLista==0) {
                        this.OperacionPropiedad='Inicio';
                    }
                    else{
                        if (Camino!=null) {
                            this.OperacionPropiedad=Camino;
                        }
                    }
                    if (this.IdClienteS==0) {
                        this.OperacionPropiedad='Matriz';
                    }
                    // else{
                    //     this.ConfigLoad.ShowLoader=false;
                    // }
                    this.ListaTodasPropiedades(this.OperacionPropiedad,obj);
                    // this.ConfigLoad.ShowLoader=false;
                    this.ContadorLista++;
                })
            }
            if (this.Botones.HistorialServicios || this.Botones.ServiciosFuturos) {
                let EstadoS=this.Botones.HistorialServicios ? "CERRADA" : "ABIERTA";
                let Fecha_I;
                if (this.Botones.ServiciosFuturos){
                    Fecha_I=new Date()
                    Fecha_I.setDate(Fecha_I.getDate()+1);
                    let y=Fecha_I.getFullYear();
                    let m=Fecha_I.getMonth()+1;
                    let d=Fecha_I.getDate();
                    Fecha_I=y+'-'+m+'-'+d;
                    // Fecha_I=new Date(y,m,1);
                }
                else{
                    Fecha_I='';
                }
                // let Fecha_I=this.Botones.HistorialServicios ? null : new Date().getDate()+1;
                await this.$http.get('servicio/get',{
                    params:{
                        IdClienteS:this.IdClienteS,
                        Nombre:this.Filtro.Nombre,
                        EstatusS:EstadoS,
                        FechaI:Fecha_I,
                        pag:this.Filtro.Pagina,
                        Entrada:this.Filtro.Entrada
                    }
                }).then((res)=>{
                    this.HistorialServicios=res.data.data.servicio;
                    this.Filtro.Entrada = res.data.data.pagination.PageSize;
					this.Filtro.TotalItem = res.data.data.pagination.TotalItems;
					this.ConfigLoad.ShowLoader=false;
                }).finally(()=>{
                    if (this.ContadorLista==0) {
                        this.ContadorLista++;
                    }
                });
            }
            if (this.Botones.Activos) {
                await this.$http.get('equipos/get',{
                    params:{
                        IdClienteS:this.IdClienteS,
                        Nombre:this.Filtro.Nombre,
                        RegEstatus:'A',
                        pag:this.Filtro.Pagina,
                        Entrada:this.Filtro.Entrada,
                        Status:this.FiltroEquipo
                    }
                }).then((res)=>{
                    this.Activos=res.data.data.equipos;
                    this.Filtro.Entrada = res.data.data.pagination.PageSize;
					this.Filtro.TotalItem = res.data.data.pagination.TotalItems;
					this.ConfigLoad.ShowLoader=false;
                })
            }
            if (this.Botones.Contratos) {
                this.Filtro.Placeholder='Nombre/Tipo/Trabajo/Valor/Facturacion';
                await this.$http
				.get('numcontrato/get', {
					params: {
						pag: this.Filtro.Pagina,
						Nombre: this.Filtro.Nombre,
						Entrada: this.Filtro.Entrada,
						IdClienteS: this.IdClienteS,
						// IdCliente: this.oClienteP.IdCliente,
						// IdSucursa: this.oClienteP.IdSucursal,
						RegEstatus: "A"
					}
				})
				.then(res => {
					this.Contratos = res.data.data.contractlist;
                    this.RutaPdfContrato=res.data.ruta;
					// this.ListaHeader = res.data.headers;
					this.Filtro.Entrada = res.data.data.pagination.PageSize;
					this.Filtro.TotalItem = res.data.data.pagination.TotalItems;
					this.ConfigLoad.ShowLoader=false;
				});
            }
            if (this.Botones.Facturacion) {
                await this.$http.get('facturaautorizada/v2/get', {
				params:{
					AFacturar:'SI',
					Facturado:'Facturada',
					// FechaFacReal:this.FechaFacReal,
					// TipoFiltro:this.TipoFiltro,
					Nombre:this.Filtro.Nombre,
					Entrada:this.Filtro.Entrada,
					pag:this.Filtro.Pagina,
					RegEstatus:'A',
                    IdClienteS:this.IdClienteS,
                    TipoListado:"4"
					// Anulado:this.Anulado,
					// Fecha_I: FechaInicial,
					// Fecha_F: FechaFinal
				}
			    }).then((res) => {
                    this.Facturas		= res.data.data.prefacturas;
                    this.Filtro.Entrada		= res.data.data.pagination.PageSize;
                    this.Filtro.TotalItem	= res.data.data.pagination.TotalItems;
			    	this.Filtro.Pagina 		= res.data.data.pagination.CurrentPage;
                    this.RutaFileOrg 		= res.data.RutaFileOrg;
                    this.ConfigLoad.ShowLoader=false;

                })
            }
            if (this.Botones.Propuestas) {
                await this.$http.get('propuestas/get',{
                    params:{
                        pag: this.Filtro.Pagina,
						Nombre: this.Filtro.Nombre,
                        Entrada: this.Filtro.Entrada,
						IdClienteS: this.IdClienteS,
                    }
                }).then((res)=>{
                    this.Propuestas=res.data.data.propuestas;
                    this.Filtro.Entrada = res.data.data.pagination.PageSize;
					this.Filtro.TotalItem = res.data.data.pagination.TotalItems;
                }).finally(()=>{
                    this.ConfigLoad.ShowLoader=false;
                })
            }
            if (this.Botones.Contactos) {
                this.ContadorUsuariosMatriz=0;
                let params={
                    pag: this.Filtro.Pagina,
					Nombre: this.Filtro.Nombre,
					Entrada: this.Filtro.Entrada,
                    IdCliente:this.ContactoMatriz.IdCliente,
                    IdClienteS:null
                };
                if (this.IdClienteS>0) {
                    params.IdClienteS=this.IdClienteS
                }
                await this.$http.get('contactos/get',{
                    params:params
                }).then((res)=>{
                    if (res.data.data.ContadorUsuariosMatriz!==undefined) {
                        this.ContadorUsuariosMatriz=res.data.data.ContadorUsuariosMatriz;
                    }
                    this.ContadorUsuariosMonitoreo=res.data.data.ContadorUsuariosMonitoreo;
                    this.Contactos=res.data.data.Contactos;
                    this.Filtro.Entrada = res.data.data.pagination.PageSize;
					this.Filtro.TotalItem = res.data.data.pagination.TotalItems;
					this.ConfigLoad.ShowLoader=false;
                })
            }
            if(this.Botones.Cotizaciones){
                await this.$http.get('cotizacion_servicio/get',{
                    params:{
                        Nombre:this.Filtro.Nombre,
                        Entrada:this.Filtro.Entrada,
                        pag:this.Filtro.Pagina, 
                        RegEstatus:'A',
                        IdCliente:this.IdClienteS
                    }
                }).then((res)=>{
                    this.Cotizaciones =res.data.data.row;
				    this.Filtro.Entrada=res.data.data.pagination.PageSize;
				    this.Filtro.TotalItem=res.data.data.pagination.TotalItems;
                }).finally(()=>{
                    this.ConfigLoad.ShowLoader=false;
                })
            }
            if (this.Botones.Documentos){
                await this.$http.get('documentoscm/get',{
                    params:{
                        Nombre:this.Filtro.Nombre,
                        IdClienteS:this.IdClienteS
                    }
                }).then((res)=>{
                    this.Documentos=res.data.data.documentoscm;
                    this.Filtro.Entrada=res.data.data.pagination.PageSize;
				    this.Filtro.TotalItem=res.data.data.pagination.TotalItems;
                }).finally(()=>{
                    this.ConfigLoad.ShowLoader=false;
                })
            }
            if (this.Botones.Ubicacion) {
                this.BndMostrarMapa=false;
                this.Filtro.Show=false;
                this.ShowPager=false;
                await this.$http.get('clientesucursal/recovery',{
                    params:{
                        IdClienteS:this.IdClienteS
                    }
                }).then((res)=>{
                    this.oLatLng.Lat=parseFloat(res.data.data.Clientes.Latitud);
                    this.oLatLng.Lng=parseFloat(res.data.data.Clientes.Longitud);
                    this.BndMostrarMapa=true;
                });
            }
        },
        EliminarPropiedad(Id){
            this.$swal({
                title: 'Esta seguro que desea eliminar este dato?',
                text: 'No se podra revertir esta acción',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si',
                cancelButtonText: 'No, mantener',
                showCloseButton: true,
                showLoaderOnConfirm: true
            }).then((result)=>{
                if(result.value){
                    this.$http.delete(
                        'clientesucursal/'+Id
                    ).then((res)=>{
                        this.$toast.success('Información eliminada');
                        this.bus.$emit('List',"Eliminar","Propiedad",res.data.data);
                        // this.ListaTodasPropiedades();
                    })
                }
            })
        },
        EliminarContrato(Id){
            this.$swal({
                title: 'Esta seguro que desea eliminar este dato?',
                text: 'No se podra revertir esta acción',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si',
                cancelButtonText: 'No, mantener',
                showCloseButton: true,
                showLoaderOnConfirm: true
            }).then((result)=>{
                if(result.value){
                    this.$http.delete(
                        'numcontrato/'+Id
                    ).then((res)=>{
                        this.$toast.success('Información eliminada');
                        this.bus.$emit('List',"Eliminar","Contrato");
                    })
                }
            })
        },
        EliminarPropuesta(Id){
            this.$swal({
                title: 'Esta seguro que desea eliminar este dato?',
                text: 'No se podra revertir esta acción',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si',
                cancelButtonText: 'No, mantener',
                showCloseButton: true,
                showLoaderOnConfirm: true
            }).then((result)=>{
                if(result.value){
                    this.$http.delete(
                        'propuestas/'+Id
                    ).then((res)=>{
                        this.$toast.success('Información eliminada');
                        this.bus.$emit('List',"Eliminar","Propuesta");
                    })
                }
            })
        },
        EliminarContacto(Id){
            this.$swal({
                title: 'Esta seguro que desea eliminar este dato?',
                text: 'No se podra revertir esta acción',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si',
                cancelButtonText: 'No, mantener',
                showCloseButton: true,
                showLoaderOnConfirm: true
            }).then((result)=>{
                if(result.value){
                    this.$http.delete(
                        'contactos/'+Id
                    ).then((res)=>{
                        this.$toast.success('Información eliminada');
                        this.bus.$emit('List',"Eliminar","Contactos",res.data.data.Contacto);
                    })
                }
            })
        },
        EliminarCotizacion(Id){
            this.$swal({
                title: 'Esta seguro que desea eliminar este dato?',
                text: 'No se podra revertir esta acción',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si',
                cancelButtonText: 'No, mantener',
                showCloseButton: true,
                showLoaderOnConfirm: true
            }).then((result)=>{
                if(result.value){
                    this.$http.delete(
                        'cotizacion_servicio/'+Id
                    ).then((res)=>{
                        this.$toast.success('Información eliminada');
                        this.bus.$emit('List',"Eliminar","Cotizacion");
                    })
                }
            })
        },
        EliminarDocumento(Id){
            this.$swal({
                title: 'Esta seguro que desea eliminar este dato?',
                text: 'No se podra revertir esta acción',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si',
                cancelButtonText: 'No, mantener',
                showCloseButton: true,
                showLoaderOnConfirm: true
            }).then((result)=>{
                if(result.value){
                    this.$http.delete(
                        'documentoscm/'+Id
                    ).then((res)=>{
                        this.$toast.success('Información eliminada');
                        this.bus.$emit('List',"Eliminar","DocumentoCm");
                    })
                }
            })
        },
        async ListaTodasPropiedades(camino,obj={}){
            // await this.$http.get('clientesucursal/get',{
            //         params:{
            //             IdCliente:this.Contacto.IdCliente,
			// 			RegEstatus: "A",
            //             Todos:true,
            //             isPropiedades:true
            //         }
            // }).then((res)=>{
            //     let MatrizElemento={};
            //     this.PropiedadesTodas=res.data.data.clientesucursal;
            //     this.PropiedadesCombo= res.data.data.clientesucursal.map((obj)=> {
			// 	    return {
			// 	        id: obj.IdClienteS,
			// 	        label: obj.Nombre
			// 	    };
			//     });
            //     MatrizElemento={id:0,label:this.ContactoMatriz.Nombre +'        (Matriz)'};
            //     this.PropiedadesCombo.unshift(MatrizElemento);
            // })
            // .finally(()=>{
            //     this.SeleccionAutomaticaPropiedad(camino,obj);
            //     this.ConfigLoad.ShowLoader=false;
            // });
            let MatrizElemento={};
            this.PropiedadesCombo= this.PropiedadesTodas.map((obj)=> {
				return {
				    id: obj.IdClienteS,
				    label: obj.Nombre
				};
			});
            MatrizElemento={id:0,label:this.ContactoMatriz.Nombre +'        (Matriz)'};
            this.PropiedadesCombo.unshift(MatrizElemento);
            this.SeleccionAutomaticaPropiedad(camino,obj);
            this.ConfigLoad.ShowLoader=false;
        },
        SeleccionAutomaticaPropiedad(camino,obj={}){
            if (camino=="Inicio") {
                if (this.EsCliente) {
                    if (this.EsClienteMatriz) {
                        if (this.PropiedadesCombo.length>1) {
                            this.IdClienteS=this.PropiedadesCombo[1].id;
                        }
                        else{
                            this.IdClienteS=this.PropiedadesCombo[0].id;
                        }
                    }
                    else{
                        let index=this.PropiedadesCombo.findIndex((element)=>element.id==JSON.parse(sessionStorage.getItem("propiedad")).IdClienteS);
                        this.IdClienteS=this.PropiedadesCombo[index].id;
                        this.DesactivarInputPropiedad=true;
                    }
                }
                else{
                    if (this.PropiedadesCombo.length>1) {
                        this.IdClienteS=this.PropiedadesCombo[1].id;
                    }
                    else{
                        this.IdClienteS=this.PropiedadesCombo[0].id;
                    }
                }
                    // this.SeleccionarPropiedad();
            }
            else if (camino=="Crear"){
                if (this.PropiedadesCombo.length==1) {
                    this.IdClienteS=this.PropiedadesCombo[1].id;
                }
            }
            else if (camino=="Editar"){
                this.SeleccionarPropiedad();
            }
            else if (camino=="Eliminar") {
                // console.log(obj.IdClienteS);
                if (obj.IdClienteS==this.IdClienteS && this.PropiedadesCombo.length>0) {
                    this.IdClienteS=this.PropiedadesCombo[1].id;
                }
                else{
                    this.IdClienteS=this.PropiedadesCombo[0].id;;
                }
            }
        },
        ContadorMatriz(camino){
            let Contador=0;
            switch (camino) {
                case "totalactivos":
                    this.PropiedadesTodas.forEach((element)=>{
                        Contador+=Number(element.TotalActivos);
                    });
                    break;
                case "operando":
                    this.PropiedadesTodas.forEach((element)=>{
                        Contador+=Number(element.Operando);
                    });
                    break;
                case "observacion":
                    this.PropiedadesTodas.forEach((element)=>{
                        Contador+=Number(element.Observacion);
                    });
                    break;
                case "detenidos":
                    this.PropiedadesTodas.forEach((element)=>{
                        Contador+=Number(element.Detenidos);
                    });
                    break;
                case "servicios":
                    this.PropiedadesTodas.forEach((element)=>{
                        Contador+=Number(element.Servicios);
                    });
                    break;
                default:
                    break;
            }
            if (isNaN(Contador)) {
                Contador=0;
            }
            return Contador;
        },
        CambiarImagenLogo(target){
            if (this.ContadorImagenLogo==0) {
				this.$uploadImagePreview(target,this.ValidElement, Array('Img','imagePreview'));
				if(this.$refs.file.files[0]!=undefined){
					this.BndImagenLogo=1;
					this.ContadorImagenLogo++;
					this.ObjImagenLogo=this.$refs.file.files;
				}
				else{
					this.BndImagenLogo=0;
				}
			}
			else{
				this.$uploadImagePreview(target,this.ValidElement, Array('Img','imagePreview'));
				if(this.$refs.file.files[0]==undefined){
					this.$refs.file.files=this.ObjImagenLogo;
				}
				else{
					this.ObjImagenLogo=this.$refs.file.files;
				}
			}
        },
        ContarUsuarios(){
            return this.ContadorPaqueteMonitoreo-this.ContadorUsuarios;
        },
        ContarPropiedades(){
            if (this.ContadorPaquetePropiedades-this.ContadorPropiedades<=0) {
                return 0
            }
            else{
                return this.ContadorPaquetePropiedades-this.ContadorPropiedades;
            }
        },
        ContarPropiedadesMonCliente(){
            if (this.ContadorPaquetePropiedadMonCliente-this.ContadorPropiedadesMonCliente<=0) {
                return 0
            }
            else{
                return this.ContadorPaquetePropiedadMonCliente-this.ContadorPropiedadesMonCliente;
            }
        },
        CambiarImagenExterior(target){
            if (this.ContadorImagenExterior==0) {
				this.$uploadImagePreview(target,this.ValidElement, Array('Img','ImagePreviewExterior'));
				if(this.$refs.file2.files[0]!=undefined){
					this.BndImagenExterior=1;
					this.ContadorImagenExterior++;
					this.ObjImagenExterior=this.$refs.file2.files;
				}
				else{
					this.BndImagenExterior=0;
				}
			}
			else{
				this.$uploadImagePreview(target,this.ValidElement, Array('Img','ImagePreviewExterior'));
				if(this.$refs.file2.files[0]==undefined){
					this.$refs.file2.files=this.ObjImagenExterior;
				}
				else{
					this.ObjImagenExterior=this.$refs.file2.files;
				}
			}
        },
        ZoomImagen(Imagen,Camino){
            switch (Camino) {
				case 1:
					if (this.$refs.file.files[0]!=undefined) {
						let ImagenPreview=document.getElementById("imagePreview");
						const Estilos=window.getComputedStyle(ImagenPreview);
						let CadenaBase=Estilos.backgroundImage;
						let Url=CadenaBase.slice(5,-2);
						this.ImagenAMostrar=Url;
					}
					else{
						this.ImagenAMostrar=Imagen;
					}
					break;
				case 2:
					if (this.$refs.file2.files[0]!=undefined){
						let ImagenPreview=document.getElementById("ImagePreviewExterior");
						const Estilos=window.getComputedStyle(ImagenPreview);
						let CadenaBase=Estilos.backgroundImage;
						let Url=CadenaBase.slice(5,-2);
						this.ImagenAMostrar=Url;
					}
					else{
						this.ImagenAMostrar=Imagen;
					}
					break;
			}
        },
        GuardarImagenLogo(){
            let RutaImagenAnterior=this.RutaImagen;
            let formData=new FormData();
			let image=this.$refs.file.files[0];
			if(image!=undefined){
				if (this.Contacto.LogoSucursal!=null) {
					let index=this.Contacto.LogoSucursal.lastIndexOf("/")+1;
					let cadena=this.Contacto.LogoSucursal.substr(index);	
					formData.set('LogoSucursalPrevio','/'+cadena);
				}
                formData.set('IdClienteS',this.IdClienteS);
				formData.set('IdCliente',this.Contacto.IdCliente);
				formData.append('LogoSucursal',image);
				this.RutaImagen=image.name;
				// this.$http.post('crmcontactos/imagenexterior/post',formData,{
				// 	'Content-Type':'multipart/form-data'
				// })
                this.$http.post('crmsucursal/updatelogo/post',formData,{
					'Content-Type':'multipart/form-data'
				})
                .then((res)=>{
                    this.RutaImagen=RutaImagenAnterior;
					this.$toast.success("Imagen guardada");
					// this.bus.$emit('ActualizarImagenExterior',res.data.data.equipos.ImagenExterior);
					this.BndImagenLogo=0;
                    this.ContadorImagenLogo=0;
                    
				}).finally(() => {
                    this.Lista("Editar");
                });
			}
			else{
				this.$toast.info("Tienes que subir una imagen");
			}
        },
        GuardarImagenExterior(){
            let formData=new FormData();
			let image=this.$refs.file2.files[0];
			if(image!=undefined){
				if (this.Contacto.ImagenExterior!=null) {
					let index=this.Contacto.ImagenExterior.lastIndexOf("/")+1;
					let cadena=this.Contacto.ImagenExterior.substr(index);	
					formData.set('ImagenExteriorPrevio','/'+cadena);
				}
				formData.set('IdCliente',this.Contacto.IdCliente);
                formData.set('IdClienteS',this.IdClienteS);
				formData.append('ImagenExterior',image);
				this.RutaImagenExteriorContacto=image.name;
				// this.$http.post('crmcontactos/imagenexterior/post',formData,{
				// 	'Content-Type':'multipart/form-data'
				// })
                this.$http.post('crmsucursal/updateexterior/post',formData,{
					'Content-Type':'multipart/form-data'
				})
                .then((res)=>{
					this.$toast.success("Imagen guardada");
					// this.bus.$emit('ActualizarImagenExterior',res.data.data.equipos.ImagenExterior);
					this.BndImagenExterior=0;
                    this.ContadorImagenExterior=0;
                    
				}).finally(() => {
                    this.Lista(null,"Editar");
                    // this.ListaTodasPropiedades();
                });
			}
			else{
				this.$toast.info("Tienes que subir una imagen");
			}
        },
        CambiarPestania(index){
            let Botones=this.Botones;
			Object.keys(Botones).forEach(function(key,ind){
				if (key==index) {
					Botones[key]=true;
				}
				else{
					Botones[key]=false;
				}
				// console.log(index)
			});
            this.Filtro.Nombre="";
            this.Filtro.Pagina=1;
            this.Lista();
        },
        ExtraerTipoPropiedad(Cadena){
            if (Cadena!="") {
                return Cadena.substr(0,Cadena.lastIndexOf('.'));
            }
        },
        SeleccionarPropiedad(){
            // console.log(this.IdClienteS);
            this.Filtro.Nombre="";
            this.Filtro.Pagina=1;
            if (this.PropiedadesCombo.length>0) {
                if (this.IdClienteS>0) {
                    let index=this.PropiedadesTodas.findIndex((element)=>element.IdClienteS==this.IdClienteS);
                    this.Contacto.Direccion=this.PropiedadesTodas[index].Direccion;
                    this.Contacto.Ciudad=this.PropiedadesTodas[index].Ciudad;
                    this.Contacto.LogoSucursal=this.PropiedadesTodas[index].LogoSucursal;
                    this.Contacto.ExisteImagen=this.PropiedadesTodas[index].ExisteImagen;
                    this.Contacto.ImagenExterior=this.PropiedadesTodas[index].ImagenExterior;
                    this.Contacto.Monitoreo=this.PropiedadesTodas[index].CheckCli==1 ? 1 : 0;
                    this.Contacto.CheckMonCli=this.PropiedadesTodas[index].CheckMonCli;
                    this.ContactoPropiedad=this.PropiedadesTodas[index].Nombre;
                }
                else{
                    this.CambiarPestania('Propiedades');
                }
                this.BndImagenExterior=0;
                this.BndImagenLogo=0;
                this.ContadorImagenExterior=0;
                this.ContadorImagenLogo=0;
            }
            if (!this.Botones.Propiedades) {
                this.Lista();
            }
            if (this.pOrigen=='Evidencia' && this.ContadorLista==0) {
                let Filtros=JSON.parse(sessionStorage.getItem("filtros"));
                this.Filtro.Pagina=Filtros.Pagina;
                this.Filtro.Nombre=Filtros.Nombre;
                this.bus.$emit('CurrentPage');
            }
        },
        NuevaPropiedad(){
            this.bus.$emit('NewModal_'+this.ConfigModalPropiedad.EmitSeccion,true); 
        },
        NuevoContrato(){
            this.bus.$emit('NewModal_'+this.ConfigModalContratos.EmitSeccion,true); 
        },
        NuevaPropuesta(){
            this.bus.$emit('NewModal_'+this.ConfigModalPropuesta.EmitSeccion,true,0,this.Contacto,this.IdClienteS); 
        },
        NuevoDocumento(){
            this.bus.$emit('NewModal_'+this.ConfigModalDocumentosCm.EmitSeccion,true,0,this.Contacto,this.IdClienteS); 
        },
        NuevoContacto(){
            this.bus.$emit('NewModal_'+this.ConfigModalContactos.EmitSeccion,true);
        },
        AbrirServicio(Id){
            this.bus.$emit('NewModal_'+this.ConfigModalServicio.EmitSeccion,true,Id);
        },
        RecuperarMatriz(){
            this.$http.get("crmcontactos/recovery",{
                params:{
                    IdCliente:this.Contacto.IdCliente
                }
            }).then((res)=>{
                let obj=res.data.data.Clientes;
                this.Contacto.IdCliente=obj.IdCliente;
                this.Contacto.Nombre=obj.Nombre;
                this.Contacto.Telefono=obj.Telefono;
                this.Contacto.Direccion=obj.Direccion;
                this.Contacto.Correo=obj.Correo;
                this.Contacto.Ciudad=obj.Ciudad;
                this.Contacto.Pais=obj.Pais;
                this.Contacto.Estado=obj.Estado;
                this.Contacto.CP=obj.CP;
                this.Contacto.IdSucursal=obj.IdSucursal;
                this.Contacto.Contacto=obj.Contacto;
                this.Contacto.Dfac=obj.Dfac;
                this.Contacto.LogoCliente=obj.LogoCliente;
            })
        },
        DefinirClaseActivo(Status){
            let Clase="";
            switch (Status) {
                case "Operando":
                    Clase="circuloStatusOperando";
                    break;
                case "En Observacion":
                    Clase="circuloStatusObservacion";
                    break;
                case "Fuera de Servicio":
                    Clase="circuloStatusFueraServicio";
                    break;
                case "En Servicio":
                    Clase="circuloStatusServicio";
                    break;
                default:
                    Clase="circuloStatusDefault";
                    break;
            }
            return Clase;
        },
        AbrirFactura(index, IdFactura,DiasCredito,Observacion,FechaFacReal,IdServicio){
            this.factura.IdFactura=this.Facturas[index].IdFactura;
            this.factura.IdServicio=this.Facturas[index].IdServicio;
            this.factura.RegEstatus=this.Facturas[index].RegEstatus;
            this.factura.Monto=this.Facturas[index].Monto;
            this.factura.FolioFactReal=this.Facturas[index].FolioFactReal;
            let formatedDate = this.Facturas[index].FechaFacReal.replace(/-/g,'\/')
            this.factura.FechaFacReal = new Date(formatedDate);
            this.factura.FilePrevious=this.Facturas[index].ArchivoFactura;
            this.factura.FolioFactura=this.Facturas[index].FolioFactura;
            this.factura.DiasCredito=this.Facturas[index].DiasCredito;
            if (Observacion=="null") {
                this.factura.Observacion="";
            }
            else{
                this.factura.Observacion=this.Facturas[index].Observacion;
            }
            this.factura.FechaCobro=this.Facturas[index].FechaCobro;
            this.factura.FechaAnulado=this.Facturas[index].FechaAnulado;
            if (this.Facturas[index].Facturado=="SI") {
                this.factura.isFacturado="NO"
                this.factura.Facturado="Facturada";
            }
            else{
                this.factura.isFacturado="SI"
                this.factura.Facturado=this.Facturas[index].Facturado;
            }
            this.bus.$emit('EditarCance',this.oBtnSave,IdFactura,DiasCredito,Observacion,FechaFacReal,IdServicio);
            this.bus.$emit('Limpiar');
        },
        Change(titulo)
        {
            var bdn=true;
            if(titulo=='Selecciona la imagen'){
                bdn=false;
            }else{
                titulo=titulo+' : '+this.Contacto.Nombre;
            }
            this.ConfigModalPropiedad.NameForm=titulo;
           
            this.bus.$emit('cambiar_CloseModal',bdn);
        },
        AbrirDocumento(Nombre){
            let archivo = Nombre;
			window.open(
				archivo,
				"_blank",
				"toolbar=1, scrollbars=1, resizable=1, width=" +
					1015 +
					", height=" +
					800
			);
        },
        DescargarReporte(IdServicio){
            let spinner=document.getElementById(`pdfOrden_${IdServicio}`);
			spinner.setAttribute('class','fa fa-spinner fa-pulse fa-1x fa-fw');
			this.$http.get('reporte/servicio',
            {
                responseType: 'blob',
                params :
                    {
                        IdServicio:IdServicio,
                    }
            }).then( (response) => {

                let pdfContent = response.data;
                let file = new Blob([pdfContent], { type: 'application/pdf' });
                let fileUrl = URL.createObjectURL(file);
                window.open(fileUrl);
                spinner.setAttribute('class','fas fa-file-pdf fa-fw-m');

            });
        },
        DescargarEvidencia(IdServicio){
            this.$router.push({name:'mon_evidencia',
                params:{
                    IdServicio:IdServicio,
                    // Equipo:this.Equipo,
                    // Cliente:this.Cliente,
                    // ClienteS:this.ClienteSucursal,
                    isMonitoreo:false,
                    isContacto:true,
                    pObjPropiedades:{
                        IndexPropiedad:this.IdClienteS,
                        Propiedades:this.PropiedadesCombo,
                        PropiedadesTodas:this.PropiedadesTodas,
                        Pagina:this.Filtro.Pagina,
                        Busqueda:this.Filtro.Nombre
                    }
                }
            });
        },
        DescargarFactura(IdFactura){
            if (IdFactura !='' || IdFactura !=null)
            {
                this.$http.get(
                    'reporte/factura',
                    {
                    responseType: 'blob',
                    params :{
                            IdFactura:IdFactura,
                        }
                    }
                ).then( (response) => {

                    let pdfContent = response.data;
                    let file = new Blob([pdfContent], { type: 'application/pdf' });
                    let fileUrl = URL.createObjectURL(file);
                    window.open(fileUrl);
                });
            }
        },
        BloquearAgregarPropiedad(){
            // return false;
            return (this.ContadorPaquetePropiedadBase<=this.ContadorPropiedadesBase) ? true : false;
        },
        IrAEquipo(Item){
            let Index=this.PropiedadesTodas.findIndex((element)=>element.IdClienteS==this.IdClienteS);
            let Propiedad=this.PropiedadesTodas[Index];
            this.$router.push({
                name:'mon_histequipo',
                params:{
                    ocliente:this.ContactoMatriz,
                    oClienteSucursal:Propiedad,
                    oEquipo:Item,
                    rutaIcoEmp:this.ConfigModalPropiedad.RutaIcoEmp,
                    pOrigen:'CM'
                }
            });
        }
        // Regresar(){

        // }
    },
    created() {
        let index=0;
        this.EsCliente=sessionStorage.getItem("clientelog")!="null" ? true : false;
        this.EsClienteMatriz=sessionStorage.getItem("propiedad")!=null ? false : true;
        // console.log(sessionStorage.getItem("propiedad"));
        // if (sessionStorage.getItem("clientelog")!=="null") {
        //     this.EsCliente=true;
        // }
        if (!this.EsCliente) {
            if (this.pContacto!==undefined) {
                sessionStorage.setItem("ocontacto",JSON.stringify(this.pContacto));
                sessionStorage.setItem("rutaimagen",this.pRuta);
                sessionStorage.setItem("rutaexterior",this.pRutaExterior);
                sessionStorage.setItem("rutaimagensucursal",this.pRutaLogoSucursal);
                sessionStorage.setItem("rutaexteriorsucursal",this.pRutaExteriorSucursal);
                sessionStorage.setItem("contadorusuarios",this.pContadorUsuarios);
                sessionStorage.setItem("contadorpaquetemonitoreo",this.pContadorPaqueteMonitoreo);
                sessionStorage.setItem("contadorpaquetepropiedades",this.pContadorPaquetePropiedad);
                sessionStorage.setItem("contadorpropiedades",this.pContadorPropiedades);
                sessionStorage.setItem("contadorpropiedadesbase",this.pContadorPropiedadesBase);
                sessionStorage.setItem("contadorpaquetepropiedadbase",this.pContadorPaquetePropiedadBase);
                sessionStorage.setItem("contadorpropiedadesmoncliente",this.pContadorPropiedadesMonCliente);
                sessionStorage.setItem("contadorpaquetepropiedadmoncliente",this.pContadorPaquetePropiedadMonCliente);
            }
            this.Contacto=JSON.parse(sessionStorage.getItem("ocontacto"));
            this.ContactoMatriz=JSON.parse(sessionStorage.getItem("ocontacto"));
            // this.RutaImagen=sessionStorage.getItem("rutaimagen");
            // this.RutaImagenExterior=sessionStorage.getItem("rutaexterior");
            this.RutaImagen=sessionStorage.getItem("rutaimagensucursal");
            this.RutaImagenLogoContacto=sessionStorage.getItem("rutaimagen");
            this.RutaImagenExterior=sessionStorage.getItem("rutaexteriorsucursal");
            this.ContadorUsuarios=Number(sessionStorage.getItem("contadorusuarios"));
            this.ContadorPaqueteMonitoreo=Number(sessionStorage.getItem("contadorpaquetemonitoreo"));
            this.ContadorPropiedades=Number(sessionStorage.getItem("contadorpropiedades"));
            this.ContadorPaquetePropiedades=Number(sessionStorage.getItem("contadorpaquetepropiedades"));
            this.ContadorPropiedadesBase=Number(sessionStorage.getItem("contadorpropiedadesbase"));
            this.ContadorPaquetePropiedadBase=Number(sessionStorage.getItem("contadorpaquetepropiedadbase"));
            this.ContadorPropiedadesMonCliente=Number(sessionStorage.getItem("contadorpropiedadesmoncliente"));
            this.ContadorPaquetePropiedadMonCliente=Number(sessionStorage.getItem("contadorpaquetepropiedadmoncliente"));
        }
        else{
            this.Regresar=false;
            this.Titulo="Administración de Contrato";
            this.Contacto=JSON.parse(sessionStorage.getItem("clientelog"));
            this.ContactoMatriz=JSON.parse(sessionStorage.getItem("clientelog"));
            this.ContadorPropiedades=this.Contacto.ContadorPropiedades;
            this.ContadorPropiedadesBase=this.Contacto.ContadorPropiedadesBase;
            this.ContadorPropiedadesMonCliente=this.Contacto.ContadorPropiedadesMonCliente;
            this.RutaImagen=this.Contacto.RutaLogo;
            this.RutaImagenExterior=this.Contacto.RutaExterior;
            this.RutaImagenLogoContacto=this.Contacto.RutaLogoMatriz;
        }
        
        this.bus.$off('List');
        this.bus.$on('List',(Operacion,Seccion,obj=null)=> 
        {
            if (Operacion=="Crear") {
                switch (Seccion) {
                    case "Matriz":
                        this.RecuperarMatriz();
                        break;
                    case "Propiedad":
                        this.Contacto.Sucursales++;
                        if (obj.CheckCli==1) {
                            this.ContadorPropiedades++;
                        }
                        if (obj.CheckMonCli==1) {
                            this.ContadorPropiedadesMonCliente++;
                        }
                        // this.ContadorPropiedades++;
                        sessionStorage.setItem("contadorpropiedades",this.ContadorPropiedades);
                        sessionStorage.setItem("contadorpropiedadesmoncliente",this.ContadorPropiedadesMonCliente);
                        this.OperacionPropiedad="Crear";
                        // this.ListaTodasPropiedades("Crear");
                        // this.SeleccionAutomaticaPropiedad("Crear");
                        break;
                    case "Contrato":
                        this.Contacto.Contratos++;
                        break;
                    case "Contactos":
                        if (obj.UsuarioMonitoreo=='si') {
                            this.ContadorUsuarios++;
                            sessionStorage.setItem("contadorusuarios",this.ContadorUsuarios);
                            this.ContarUsuarios();
                        }
                        // this.ContadorUsuarios=sessionStorage.getItem("contadorusuarios");
                        break;
                }
                sessionStorage.setItem("ocontacto",JSON.stringify(this.Contacto));
                this.Contacto=JSON.parse(sessionStorage.getItem("ocontacto"));
            }
            if (Operacion=="Editar") {
                switch (Seccion) {
                    case 'Propiedad':
                        index=this.Propiedades.findIndex((element)=>element.IdClienteS==obj.IdClienteS);
                        if (this.Propiedades[index].CheckCli!=obj.CheckCli) {
                            if (obj.CheckCli==1) {
                                this.ContadorPropiedades++;
                            }
                            else{
                                this.ContadorPropiedades--;
                            }
                        }
                        if (this.Propiedades[index].CheckMonCli!=obj.CheckMonCli){
                            if (obj.CheckMonCli==1) {
                                this.ContadorPropiedadesMonCliente++;
                            }
                            else{
                                this.ContadorPropiedadesMonCliente--;
                            }
                        }
                        sessionStorage.setItem("contadorpropiedades",this.ContadorPropiedades);
                        sessionStorage.setItem("contadorpropiedadesmoncliente",this.ContadorPropiedadesMonCliente);
                        // this.ListaTodasPropiedades("Editar");
                        this.OperacionPropiedad="Editar";
                        break;
                    case 'Contactos':
                        index=this.Contactos.findIndex((element)=>element.IdContacto==obj.IdContacto);
                        if (this.Contactos[index].UsuarioMonitoreo!=obj.UsuarioMonitoreo) {
                            if (obj.UsuarioMonitoreo=='si') {
                                this.ContadorUsuarios++;
                            }
                            else{
                                this.ContadorUsuarios--;
                            }
                            sessionStorage.setItem("contadorusuarios",this.ContadorUsuarios);
                            this.ContarUsuarios();
                        }
                        break;
                
                    default:
                        break;
                }
            }
            if (Operacion=="Eliminar") {
                switch (Seccion) {
                    case "Propiedad":
                        this.Contacto.Sucursales--;
                        if (obj.CheckCli==1) {
                            this.ContadorPropiedades--;
                        }
                        sessionStorage.setItem("contadorpropiedades",this.ContadorPropiedades);
                        // this.ListaTodasPropiedades("Eliminar",obj);
                        this.OperacionPropiedad="Eliminar";
                        // this.SeleccionAutomaticaPropiedad("Eliminar",obj.IdClienteS);
                        break;
                    case "Contrato":
                        this.Contacto.Contratos--;
                        break;
                    case "Contactos":
                        if (obj.UsuarioMonitoreo=='si') {
                            this.ContadorUsuarios--;
                            sessionStorage.setItem("contadorusuarios",this.ContadorUsuarios);
                            this.ContarUsuarios();
                        }
                        // this.ContadorUsuarios=sessionStorage.getItem("contadorusuarios");
                        break;
                }
                sessionStorage.setItem("ocontacto",JSON.stringify(this.Contacto));
                this.Contacto=JSON.parse(sessionStorage.getItem("ocontacto"));
            }
            this.Lista(obj);
        });
        this.bus.$off('Regresar');
        this.bus.$on('Regresar',()=> 
        {
            this.$router.push({name:'crmcontactos'});
        });

        this.bus.$off('Delete_'+this.ConfigModalPropiedad.EmitSeccion);
        this.bus.$on('Delete_'+this.ConfigModalPropiedad.EmitSeccion,(Id)=>{
            this.EliminarPropiedad(Id);
        });

        this.bus.$off('Delete_'+this.ConfigModalContratos.EmitSeccion);
        this.bus.$on('Delete_'+this.ConfigModalContratos.EmitSeccion,(Id)=>{
            this.EliminarContrato(Id);
        });

        this.bus.$off('Delete_'+this.ConfigModalPropuesta.EmitSeccion);
        this.bus.$on('Delete_'+this.ConfigModalPropuesta.EmitSeccion,(Id)=>{
            this.EliminarPropuesta(Id);
        });

        this.bus.$off('Delete_'+this.ConfigModalContactos.EmitSeccion);
        this.bus.$on('Delete_'+this.ConfigModalContactos.EmitSeccion,(Id)=>{
            this.EliminarContacto(Id);
        });
        this.bus.$off('Delete_'+this.ConfigModalDocumentosCm.EmitSeccion);
        this.bus.$on('Delete_'+this.ConfigModalDocumentosCm.EmitSeccion,(Id)=>{
            this.EliminarDocumento(Id);
        });
        this.bus.$off('Delete_Cotizacion');
        this.bus.$on('Delete_Cotizacion',(Id)=>{
            this.EliminarCotizacion(Id);
        });

        //VERIFICAR ORIGENES
        if (this.pOrigen=='Evidencia') {
            if (this.EsCliente) {
                this.DesactivarInputPropiedad=true;
            }
            this.Botones.HistorialServicios=true;
            this.Botones.Propiedades=false;
            this.PropiedadesCombo=JSON.parse(sessionStorage.getItem("propiedades"));
            this.PropiedadesTodas=JSON.parse(sessionStorage.getItem("propiedadestodas"));
            this.IdClienteS=sessionStorage.getItem("indexpropiedad");
            this.SeleccionarPropiedad();
        }
        this.Lista();
        
        // this.SeleccionAutomaticaPropiedad("Inicio");
        
    },
    mounted(){
        // this.ListaTodasPropiedades("Inicio");
    },
    destroyed() {
        // sessionStorage.removeItem("ocontacto");
        // sessionStorage.removeItem("rutaimagen");
        // sessionStorage.removeItem("rutaexterior");
        sessionStorage.removeItem("propiedades");
        sessionStorage.removeItem("propiedadestodas");
        sessionStorage.removeItem("indexpropiedad");
    },
}
</script>