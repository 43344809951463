<template>
    <CHeader :pHeaderProps="HeaderProps">
        <template slot="Contenido">
            <CLoader :pConfigLoad="ConfigLoad">
                <template slot="BodyFormLoad">
                    <div class="row">
                        <div class="col-6 col-sm-6 mt-3 card-group" v-for="(item,index) in Imagenes" :key="index">
                            <div class="card card-01" style="min-height:25vh">
                                <div class="card-body d-flex flex-column">
                                    <div class="cropp">
                                        <img :src=RutaImagen+item.Imagen alt="">
                                    </div>
                                    <div style="overflow-y:scroll;height:5rem;" class="mt-2 mb-2">
                                        <p>{{ item.Descripcion }}</p>
                                    </div>
                                    <button @click="EliminarFoto(item.IdImagenEquipo2)" class="mt-auto btn btn-01"><i class="fa fa-trash"></i> Eliminar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </CLoader>
        </template>
    </CHeader>
</template>

<script>
import CHeader from "@/views/webapp/components/HeaderWebApp.vue";
import CLoaderVue from '../../../components/CLoader.vue';
export default {
    name:'ListImagenes',
    props:['pServicio','pEquipo','pTipoEvidencia'],
    components:{CHeader,CLoaderVue},
    data() {
        return {
            HeaderProps:{
                ShowSubHeader:false,
                ShowReturn:true,
                TextoHeader:'Galería de Fotos',
                ShowLogo:false,
            },
            ConfigLoad:{
				ShowLoader:true,
				ClassLoad:true,
			},
            Imagenes:[],
            Servicio:{},
            Equipo:{},
            RutaImagen:'',
            TipoEvidencia:''
        }
    },
    methods: {
        async Lista(){
            this.ConfigLoad.ShowLoader=true;
            this.ConfigLoad.ClassLoad=false;
            this.$http.get('webapp/imagenes',{
                params:{
                    IdServicio:this.Servicio.IdServicio,
                    IdEquipo:this.Equipo.IdEquipo
                }
            }).then((res)=>{
                this.Imagenes=res.data.Lista;
                this.RutaImagen=res.data.ruta;
            }).finally(()=>{
                this.ConfigLoad.ShowLoader=false;
                this.ConfigLoad.ClassLoad=true;
            })
        },
        EliminarFoto(Index){
            this.$swal({
                title: 'Eliminar Imagen',
                text: '¿Desea eliminar la imagen?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si',
                cancelButtonText: 'No',
                showCloseButton: true,
                showLoaderOnConfirm: true
            }).then((result)=>{
                if(result.value){
                    this.$http.delete('webapp/removeimagen/'+Index).then((res)=>{
                        this.$toast.success('Imagen Eliminada');
                        this.Lista();
                    })
                }
            });
        },
        Regresar(){
            this.$router.push({name:'FotoGeneralWebApp',params:{pEquipo:this.Equipo,pServicio:this.Servicio,pTipoEvidencia:this.TipoEvidencia}})
        }
    },
    created() {
        if (this.pServicio==undefined) {
            this.Servicio=JSON.parse(sessionStorage.getItem('servicio'));
        }
        else{
            this.Servicio=this.pServicio;
        }
        if (this.pTipoEvidencia!=undefined) {
            sessionStorage.setItem('tipoevidencia',this.pTipoEvidencia);
        }
        
        this.TipoEvidencia=sessionStorage.getItem('tipoevidencia');
        

        if (this.TipoEvidencia!='EQUIPO') {
            this.Equipo.IdEquipo=0;
        }
        else{
            if (this.pEquipo!=undefined) {
                sessionStorage.setItem('equipo',JSON.stringify(this.pEquipo));
            }
            this.Equipo=JSON.parse(sessionStorage.getItem('equipo'));
            // this.HeaderProps.TextoHeader='Fotos del Equipo';
            this.HeaderProps.ShowSubHeader=true;
            this.HeaderProps.ShowTecnico=false;
            this.HeaderProps.ShowFechaHoy=false;
            this.HeaderProps.ShowFotoPerfil=false;
            this.HeaderProps.ShowMenuDropdown=false;
            this.HeaderProps.TextoCentroSubHeader=this.Equipo.Nequipo;
        }
    },
    mounted() {
        this.bus.$off('EmitReturn');
        this.bus.$on('EmitReturn',()=>{
            this.Regresar();
        });
        this.Lista();
    },
}
</script>

<style>
.cropp{
    position: relative;
    width:100%;
    margin: auto;
    overflow: hidden;
}
.cropp > img{ 
    width: 100%;
    height: 20vh;
    object-fit: cover;
}
</style>