<template>
    <div>
        <CLoader :pConfigLoad="ConfigLoad">
            <template slot="BodyFormLoad">
                <div class="row justify-content-center">
                    <div class="col-lg-3">
                        <label >Código </label>
                        <input type="text" v-model="Equipo.Codigo" class="form-control" placeholder="Código" />
                        <label id="lblmsuser" style="color:red"><Cvalidation v-if="this.errorvalidacion.Codigo" :Mensaje="'Campo obligatorio'"></Cvalidation></label>
                    </div>
                    <div class="col-lg-7">
                        <label >Equipo </label>
                        <input type="text" v-model="Equipo.Nombre" class="form-control" placeholder="Nombre del Equipo" />
                        <label id="lblmsuser" style="color:red"><Cvalidation v-if="this.errorvalidacion.Nombre" :Mensaje="'Campo obligatorio'"></Cvalidation></label>
                    </div>
                    <div class="col-lg-2 ">
                        <label >Costo Unit. </label>
                        <vue-numeric placeholder="$ 0.00"  :minus="false"  class="form-control "  currency="$" separator="," :precision="2" v-model="Equipo.Precio"></vue-numeric>
                        <label id="lblmsuser" style="color:red"><Cvalidation v-if="this.errorvalidacion.Precio" :Mensaje="this.errorvalidacion.Precio[1]"></Cvalidation></label>
                    </div>
                    <!--fin col-6-->
                </div>
                <!--Fin body del panel-->
            </template>
        </CLoader>
    </div>

</template>

<script>
import Cbtnsave from '@/components/Cbtnsave.vue'
import Clist from '@/components/Clist.vue';
import Cvalidation from '@/components/Cvalidation.vue'
export default {
    name:'Form',
    props:['NameList','poBtnSave'],
    data() {
        return {
            Modal:true,//Sirve pra los botones de guardado
            FormName:'Equipo',//Sirve para donde va regresar
            ConfigLoad:{
                ShowLoader:true,
                ClassLoad:true
            },
            Equipo:{            
                IdEquipoCot:0,
                Codigo:"",
                Nombre:"",
                Precio:"",
            },
            urlApi:"cotizacion_equipo/recovery",
            errorvalidacion:[]
        }
    },
    components:{
        Cbtnsave,
        Clist,Cvalidation,
    },
    methods :
    {
    
       async Guardar()
        {
             //deshabilita botones
            this.poBtnSave.toast=0; 
            this.poBtnSave.disableBtn=true;
            await this.$http.post(
                'cotizacion_equipo/post',
                this.Equipo
                ,
            ).then( (res) => {
                this.poBtnSave.disableBtn=false;  
                this.poBtnSave.toast=1;
                $('#ModalForm').modal('hide');
                this.bus.$emit('List'); 

            }).catch( err => {
                this.errorvalidacion=err.response.data.message.errores;
                this.poBtnSave.disableBtn=false;
                this.poBtnSave.toast=2;  

            });
       
        },
         Limpiar()
        {
            this.Equipo.IdEquipoCot=0;
            this.Equipo.Nombre="";
            this.Equipo.Codigo="";
            this.Equipo.Precio="";
            this.errorvalidacion=[];
            
        },
        get_one()
        {
            this.$http.get(
                this.urlApi,
                {
                    params:{IdEquipoCot: this.Equipo.IdEquipoCot}
                }
            ).then( (res) => {
                this.Equipo=res.data.data.equipocot;
            }).finally(()=>{
                this.ConfigLoad.ShowLoader=false;
            });
        }

    },
    created() {
        
        this.bus.$off('Save');
        this.bus.$off('Nuevo');

        this.bus.$on('Save',()=>
        {
           this.Guardar();
        });
        
        //Este es para modal
        this.bus.$on('Nuevo',(data,Id)=> 
        {   
            this.ConfigLoad.ShowLoader=true;
            this.poBtnSave.disableBtn=false; 
             this.Limpiar();
            if (Id>0)
            {
                this.Equipo.IdEquipoCot=Id;
                this.get_one();
            }
            else{
                this.ConfigLoad.ShowLoader=false;
            }
            this.bus.$emit('Desbloqueo',false);
            
        });
       
    }
}
</script>

<style>

</style>