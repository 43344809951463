<template>
    <div>
    <CHead :oHead="oHead"></CHead>
        <div class="row justify-content-start mt-3">
            <div class="col-12 col-sm-12 col-md-2 col-lg-2"></div>

            <div class="col-12 col-sm-12 col-md-8 col-lg-8">
                <div class="card card-01">
                    <div class="row justify-content-end mt-2">

                        <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <div class="form-group form-row">
                                        <div class="col-12 col-sm-12 col-md-4 col-lg-4">
                                            <label>HOST</label>
                                            <input v-model="proveedor.Host" placeholder="Ingrese el host, ejemplo (smtp.gmail.com)" type="text" class="form-control">
                                            <label>
                                                <CValidation v-if="this.errorvalidacion.Host" :Mensaje="'*'+errorvalidacion.Host[0]"></CValidation>
                                            </label>
                                        </div>
                                        <div class="col-12 col-sm-12 col-md-4 col-lg-4">
                                            <label>PUERTO</label>
                                                <input v-model="proveedor.Port" placeholder="Ingrese el puerto, ejemplo (678)" type="text" class="form-control">
                                                <label>
                                                    <CValidation v-if="this.errorvalidacion.Port" :Mensaje="'*'+errorvalidacion.Port[0]"></CValidation>
                                                </label>
                                        </div>
                                        <div class="col-12 col-sm-12 col-md-4 col-lg-4">
                                            <label>ENCRIPTACIÓN</label>
                                                <select v-model="proveedor.Encryption" class="form-control form-select">
                                                    <option value="">--Seleccionar--</option>
                                                    <option value="ssl">SSL</option>
                                                    <option value="tls">TLS</option>
                                                </select>
                                                <label>
                                                    <CValidation v-if="this.errorvalidacion.Encryption" :Mensaje="'*'+errorvalidacion.Encryption[0]"></CValidation>
                                                </label>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <div class="form-group form-row">
                                        <div class="col-12 col-sm-12 col-md-4 col-lg-4">
                                            <label>NOMBRE DEL SISTEMA <small>(Nombre que aparecerá en el correo)</small></label>
                                                <input v-model="proveedor.NameApp" placeholder="Ingrese el nombre del sistema, ejemplo (Desprosoft)" type="text" class="form-control">
                                                <label>
                                                    <CValidation v-if="this.errorvalidacion.NameApp" :Mensaje="'*'+errorvalidacion.NameApp[0]"></CValidation>
                                                </label>
                                        </div>
                                        <div class="col-12 col-sm-12 col-md-4 col-lg-4">
                                            <label>USUARIO</label>
                                                <input v-model="proveedor.Username" placeholder="Ingrese el usuario, ejemplo (miempresa@gmail.com)" type="text" class="form-control">
                                                <label>
                                                    <CValidation v-if="this.errorvalidacion.Username" :Mensaje="'*'+errorvalidacion.Username[0]"></CValidation>
                                                </label>
                                        </div>
                                        <div class="col-12 col-sm-12 col-md-4 col-lg-4">
                                            <label>CONTRASEÑA</label>
                                                <input v-model="proveedor.Password" :type="type" placeholder="Ingrese la contraseña, ejemplo (contraseña)" class="form-control">
                                                <button v-if="proveedor.Password !== ''" @click="ToggleShow" class="button btn-password-formulario-recovery" type="button" id="button-addon2">
                                                    <i class="far icono-password" :class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"></i>
                                                </button>
                                                <label>
                                                    <CValidation v-if="this.errorvalidacion.Password" :Mensaje="'*'+errorvalidacion.Password[0]"></CValidation>
                                                </label>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <div class="form-group form-row">
                                        <div class="col-12 col-sm-12 col-md-4 col-lg-4">
                                            <label>REMITENTE</label>
                                                <input v-model="proveedor.Remitente" placeholder="Ingrese el usuario, ejemplo (miempresa@gmail.com)" type="text" class="form-control">
                                                <label>
                                                    <CValidation v-if="this.errorvalidacion.Remitente" :Mensaje="'*'+errorvalidacion.Remitente[0]"></CValidation>
                                                </label>
                                        </div>
                                        <div class="col-12 col-sm-12 col-md-4 col-lg-4">
                                            
                                        </div>
                                        <div class="col-12 col-sm-12 col-md-4 col-lg-4">
                                            
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                         <div class="col-12 col-sm-12 col-md-12 col-lg-12 text-right">
                            <button @click="Regresar" type="button" class="btn btn-04 ban">Cancelar</button>
                             <button @click="Guardar" type="button" class="ml-1 btn btn-01">Guardar</button>&nbsp;
                        </div>

                    </div>
                </div>
            </div>

            
        </div>

    </div>
</template>
<script>
//El props Id es cuando no es modal y se mando con props
//El export de btnsave es por si no se usa el modal
import Cbtnsave from '@/components/Cbtnsave.vue'
export default {
    name:'FromConfigMailServices',
    props:['IdHorasL'],
    data() {
        return {
            Modal:true,//Sirve pra los botones de guardado
            FormName:'horaslaborales',//Sirve para donde va regresar
           horaslaborales:{
                IdHorasL:0,
                Hora_I:"",
                Hora_F:"",
                IdSucursal:"",
                Intervalo:"",
            },
            urlApi:"horaslaborales/recovery",
            ListaHoras:[],
            oHead:{//Encabezado
                Title:'Configuración de Servicio de Correo',
                BtnNewShow:false,
                BtnNewName:'Nuevo',
                isreturn:true,
                isModal:false,
                isEmit:false,
                Url:'submenuadmon',
                ObjReturn:'',
            },

            proveedor:{
                Host:'',
                Port:'',
                Encryption:'',
                NameApp:'',
                Username: '',
                Password:'',
                Remitente:''
            },
            type:            'password',
            showPassword:    false,
            errorvalidacion:[],
        }
    },
    components:{
        Cbtnsave
    },
    methods :
    {   
        ToggleShow() {

            if (this.showPassword = !this.showPassword) {                    
                this.type = 'text'
            } else {
                this.type = 'password'
            }

        },

        async Guardar()
        {
            let formData = new FormData();

            formData.set('IdHorasL',this.horaslaborales.IdHorasL);
            formData.set('Hora_I',this.horaslaborales.Hora_I);
            formData.set('Hora_F', this.horaslaborales.Hora_F);
            await this.$http.post(
                'horaslaborales/post',
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                },
            ).then( (res) => {
                this.$toast.success('Información Guardada');
                this.get_one();
            }).catch( err => {
                this.$toast.error('Agregue todos los campos');
            });
        },
        Limpiar()
        {
            this.horaslaborales.IdHorasL=0,
            this.horaslaborales.Hora_I="",
            this.horaslaborales.Hora_F="",
            this.horaslaborales.IdSucursal="",
            this.horaslaborales.Intervalo=""
        },
        get_one()
        {
            this.$http.get(
                this.urlApi,
                {
                    params:{}
                }
            ).then( (res) => {
                this.horaslaborales.IdHorasL=res.data.data.horaslaborales.IdHorasL;
                this.horaslaborales.Hora_I=res.data.data.horaslaborales.Hora_I;
                this.horaslaborales.Hora_F=res.data.data.horaslaborales.Hora_F;
             });
        },
        async get_horas()
        {
            await this.$http.get(
                'horaslaborales/horaslaborales',
                {
                    params:{}
                }
            ).then( (res) => {
                this.ListaHoras=res.data.data.horaslaborales;
                //this.TotalPagina=res.data.data.pagination.TotalItems;
                //this.Pag=res.data.data.pagination.CurrentPage;
            });
        },
        Regresar()
        {
            this.$router.push({name:'submenuadmon'});
        }
    },
    created() {
        this.bus.$off('Nuevo');
        this.get_horas();
        this.Limpiar();
        this.get_one();
    }
}
</script>
<style>
.btn-password-formulario-recovery {
    font-size: 18px;
    border: none !important;
    background-color: transparent;
    z-index: 100;
    position: absolute;
    right: 20px;
    top: 27px;
}
</style>
