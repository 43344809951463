<template>
	<div class="row">
		<input type="hidden" :disabled="validate">
		<div class="col-12 col-sm-12 col-md-12 col-lg-12">
			<nav class="navbar navbar-breadcrumb navbar-expand-md bg-breadcrumb breadcrumb-borde">
				<div class="mr-auto">
					<nav aria-label="breadcrumb">
						<ol class="breadcrumb clearfix pt-3">
							<!-- <li class="breadcrumb-item">
								<a v-if="Retornar" v-show="ShowReturn" @click="iracalendario">{{TxtDefault}}</a>
								<a v-else @click="Atras" v-show="ShowReturn">{{MenuAtras}}</a>
							</li> -->
							<li v-if="ShowNext" class="breadcrumb-item active">{{Seccion}}</li>
						</ol>
					</nav>
				</div>

				<form class="form-inline justify-content-end">
					<div class="form-group mt-n1">
						<slot name="BtnInicio"></slot>
						<div class="mr-3 mt-2 mb-2">
							<button v-if="!Menus.isInContactos" @click="Contactos" class="btn btn-pink dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
								Contactos
							</button>
						</div>
						<div class="mr-3 mt-2 mb-2">
							<button v-if="!Menus.isInOportunidad" @click="Oportunidad" class="btn btn-pink dropdown-toggle" type="button" id="dropdownConfigButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
								Oportunidades
							</button>
						</div>
						
						<slot name="Localizac"></slot>
					</div>
				</form>
			</nav>
		</div>
	</div>
</template>

<script>


export default {
	name: "Menu",
	props:['pSitio','pSitioAtras','pRegresar','pTxtDefault','pShowNext','isInContactos','isInOportunidad','pShowReturn'],
	components: {
	},
	data: function() {
		return {
			ShowReturn:true,
			TxtDefault: 'Calendario',
			Seccion: 'Menu',
			MenuAtras: 'Menu',
			Retornar: true,
			ShowNext: true,
			Menus:{
				isInContactos:false,
				isInOportunidad:false
			}
		};
	},
	methods: {
		iracalendario() {
			this.$router.push({ name: "submenucrm" });
		},
		Contactos() {
			this.$router.push({ name: "crmcontactos", params: {} });
		},
		Oportunidad() {
			this.$router.push({ name: "crmoportunidad", params: {} });
		},
		pipedrive() {
			this.$router.push({ name: "crmpipedrive", params: {} });
		},
		forecast() {
			this.$router.push({ name: "crmforecast", params: {} });
		},
		vendedores() {
			this.$router.push({ name: "crmvendedores", params: {} });
		},
		Procesos() {
			this.$router.push({ name: "crmtiposprocesos", params: {} });
		},
		Atras()
		{
			this.bus.$emit('RegresarCalendario');
		},
	},
	created() {
		if (this.isInContactos!=undefined) {
			this.Menus.isInContactos=true;
		}
		if (this.isInOportunidad!=undefined) {
			this.Menus.isInOportunidad=true;
		}
	},
	computed: {
        validate(){
            if(this.pSitio!=undefined){
                this.Seccion = this.pSitio;
            }
			if(this.pSitioAtras!=undefined){
                this.MenuAtras = this.pSitioAtras;
            }
			if(this.pRegresar!=undefined){
                this.Retornar = this.pRegresar;
            }
			if(this.pTxtDefault!=undefined){
                this.TxtDefault = this.pTxtDefault;
            }
			if(this.pShowNext!=undefined){
                this.ShowNext = this.pShowNext;
            }
			if(this.pShowReturn!=undefined){
                this.ShowReturn = this.pShowReturn;
            }

			return this.Seccion;
		}
	},
};
</script>
