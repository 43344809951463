<template>
	<div>
		<CLoader :pConfigLoad="ConfigLoad" v-bind:class="ConfigLoad.ShowLoader ? 'plusAlto' : '' ">
			<template slot="BodyFormLoad">
				<div class="row">

					<div v-if="imagenes.length === 0 && this.ConfigLoad.ShowLoader === false" class="col-md-12 col-lg-12">
						<h5 class="text-center col-form-h1">No contiene evidencia.</h5>
					</div>

					<ImgRoate v-for="(item, index) in imagenes" :key="index" :pItem="item"/>

					<div class="col-md-12 col-lg-12" v-if="imagenes.length > 0">
						<div class="f1-buttons mt-4">
							<button type="button" :disabled="Disablebtn" class="btn btn-01" @click="Guardar">
								<i class="fa fa-spinner fa-pulse fa-1x fa-fw" v-show="Disablebtn"></i>
								<i v-if="!Disablebtn" class="fa fa-plus-circle"></i> {{ txtSave }}
							</button>
						</div>
					</div>
				</div>

			</template>
		</CLoader>
	</div>
</template>
<script>
import ImgRoate from "@/views/modulos/servicios/componentes/imgrotate.vue";
import CLoader from "../../../components/CLoader";

export default {
	components: {
		ImgRoate,
		CLoader
	},
	data() {
		return {
			croppa: {},
			position: "right bottom",
			valor: 1,

			imagenes: [],
			Ruta: "",
			Disablebtn: false,
			IdServicio: 0,
			txtSave: "Guardar",
			ConfigLoad:{
				ShowLoader:false,
				ClassLoad:true,
			},
		};
	},
	methods: {

		getImagenes(IdServicio) {
			this.imagenes = [];
			this.ConfigLoad.ShowLoader = true;

			this.IdServicio = IdServicio;
			this.$http.get("imageneservicio/get", {
				params: {
					IdServicio: IdServicio
				}
			}).then(res => {
				//this.imagenes =res.data.data.imagenes;

				res.data.data.imagenes.forEach(element => {
					this.imagenes.push({
						IdImgEquipo: element.IdImgEquipo,
						Contador: element.Contador,
						Descripcion: element.Descripcion,
						Descripcion2: element.Descripcion2,
						Fecha: element.Fecha,
						FileEquipo: element.FileEquipo,
						IdEquipo: element.IdEquipo,
						IdServicio: element.IdServicio,
						Imagen: element.Imagen,
						Mostrar: element.Mostrar,
						Posicion: 0,
						Tipo: element.Tipo,
						isRotated: 0,
						OrigenImg: element.OrigenImg,
						isLoaded:false,
						error:false
					});
				});
				this.Ruta = res.data.data.ruta;

			}).finally(() => {
				this.ConfigLoad.ShowLoader = false;
			});
		},

		Guardar() {
			if (this.imagenes.length > 0) {
				this.Disablebtn = true;
				this.txtSave = " Espere...";
				this.$http.post("imageneservicio/postImages", {
					imagenes: this.imagenes,
					IdServicio: this.IdServicio

				}).then(res => {
					this.Disablebtn = false;
					this.txtSave = " Guardar";

					$("#ModalReporte1").modal("hide");
					document.body.classList.add("modal-open");
					this.$toast.success("Información guardada",'','');

				}).catch(err => {
					this.Disablebtn = false;
					this.txtSave = " Guardar";
					this.$toast.warning("Complete la Información",'','');
				});

			} else {
				this.$toast.info("No hay Información por Guardar",'','');
			}
		}
	},
	created() {
		this.bus.$off("OpenRepor1");
		this.bus.$on("OpenRepor1", IdServicio => {
			this.getImagenes(IdServicio);
		});
	}
};
</script>
<style>
.plusAlto{
	margin-top: 25px;
	height: 90px;
}
</style>
