<template>
    <div>

		<input type="hidden" :value="blockValidate">

		<div class="row mt-2">
			<div :class="servicios.IdServicio==0 ? 'col-12 col-sm-12 col-md-12 col-lg-12' : 'col-12 col-sm-12 col-md-6 col-lg-6'">
				<h4 class="titulo-04">FECHAS PROGRAMADAS</h4>
				<hr>
			</div>
			<div v-if="servicios.IdServicio!=0" class="col-12 col-sm-12 col-md-6 col-lg-6">
				<h4 class="titulo-04">HORAS REALES DE ATENCION</h4>
				<hr>
			</div>
		</div>
        <div class="row mt-2">
            <div class="col-12 col-sm-12 col-md-3 col-lg-3">
                <label>Fecha de Inicio *</label>

                <v-date-picker

                    v-model="servicios.Fecha_I"
                    @input="ListarFechas"
                    :min-date="fechaMinima()"
                    :popover="{
                        placement: 'top',
                        visibility: 'click',
                    }"
                    :input-props='{
                        class:"form-control form-control-sm calendar",
                        style:"cursor:pointer;background-color:#F9F9F9",
                        readonly: true,
                        disabled: localBlocker.BlockFecha
                    }'
                />
				<Cvalidation v-if="errorvalidacion.Fecha_Inicio" :Mensaje="errorvalidacion.Fecha_Inicio[0]"/>
            </div>

            <div class="col-12 col-sm-12 col-md-3 col-lg-3">
                <label>Fecha para Finalizar *</label>

                <v-date-picker

                    @input="ListarFechas"
                    v-model="servicios.Fecha_F"
                    :min-date="servicios.Fecha_I"
                    :popover="{
                        placement: 'top',
                        visibility: 'click',
                    }"
                    :input-props='{
                        class:" form-control form-control-sm calendar",
                        style:"cursor:pointer;background-color:#F9F9F9",
                        readonly: true,
                        disabled: localBlocker.BlockFecha
                    }'
                    :timezone="timezone"
                />

				<Cvalidation v-if="errorvalidacion.Fecha_Fin" :Mensaje="errorvalidacion.Fecha_Fin[0]"/>
            </div>
			<div v-if="servicios.IdServicio!=0" class="col-12 col-sm-12 col-md-6 col-lg-6">
				<label for="">Inició:</label>

				<div class="row">
				
					<div class="col-6">
						<input type="text" class="form-control" readonly v-model="servicios.HoraInicioReal">
					</div>
					<div class="col-6">
						<i v-b-tooltip.hover.rightbottom class="fas fa-info-circle fa-lg text-info" title="Las horas reales del servicio no son visibles en fechas anteriores al 30/Nov/2023 "></i>
					</div>
						
				</div>
				
				
				<!-- <label for="">Finalizó:</label>
				<input type="text" class="form-control" readonly> -->
			</div>
        </div>

        <div class="row mt-2">
			<div :class="servicios.IdServicio==0 ? 'col-12 col-sm-12 col-md-12 col-lg-12' : 'col-12 col-sm-12 col-md-6 col-lg-6'">
			<!-- <div class="col-12 col-sm-12 col-md-12 col-lg-12"> -->
				<div class="row">
					<div v-for="(item, index) in LitaFechas" :key="index" :class="servicios.IdServicio==0 ? 'col-12 col-sm-12 col-md-4 col-lg-3' : 'col-12 col-sm-12 col-md-6 col-lg-6'">
						<div class="box-01">
							<div class="form-row">
								<div class="col-md-4 col-lg-4">
									<div class="row">
										<div class="col-12 text-center">
											<span class="dia">{{item.Dia}} </span><br>
											<span class="mes">{{item.Mes}}</span>
										</div>
		
										<div class="col-12"></div>
									</div>
								</div>
		
								<div class="col-md-8 col-lg-8">
									<div class="row">
		
										<div class="col-12" >
											<select :disabled="localBlocker.BlockHoraI" @change="ValidarFechas(servicios.FechasHoras[index],index);HabilitarHoraFin(index)" v-model="servicios.FechasHoras[index].HoraI"  class="form-control border-verde">
												<option value="">--Hora Inicio--</option>
												<option v-for="(item2 ,index2 ) in servicios.FechasHoras[index].horaslaborales" :key="index2" :value="item2">{{item2}}</option>
											</select>
										</div>
		
										<div class="col-12 mt-2">
											<select :disabled="localBlocker.BlockHoraF" @change="ValidarFechas(servicios.FechasHoras[index],index)" v-model="servicios.FechasHoras[index].HoraF" class="form-control border-rojo">
												<option value="">--Hora Termino--</option>
												<option v-for="(item2 ,index2 ) in HorasF[index].horaslaborales " :key="index2" :value="item2">{{item2}}</option>
											</select>
										</div>
		
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div v-if="servicios.IdServicio!=0" class="col-12 col-sm-12 col-md-3 col-lg-3">
				<label for="">Finalizó:</label>
				<input type="text" class="form-control" readonly v-model="servicios.HoraFinReal">
			</div>
        </div>

		<!-- ---------------------------------- PERSONAL ------------------------------------------------------------>
		<div class="row mt-2">
			<div class="col-md-12 col-lg-6">
				<div class="row">
					<div class="col-md-12 col-lg-12">
						<h4>PERSONAL</h4>
						<hr class="mb-2">
					</div>

					<div class="col-md-6 col-lg-6">
						<CLoader :pConfigLoad="ConfigLoad">
							<template slot="BodyFormLoad">
								<draggable @change="Cambiar" id="div1" class="droppable bg-feed" group="people"  :list="Consultado.ListaTrabajadores"  :disabled="localBlocker.BlockHoraF">
									<div v-for="(element, index) in Consultado.ListaTrabajadores" :key="index">
										{{ element.Nombre }}
									</div>
								</draggable>
							</template>
						</CLoader>
					</div>

					<div class="col-md-6 col-lg-6" >
						<CLoader :pConfigLoad="ConfigLoad">
							<template slot="BodyFormLoad">
								<draggable  @change="Cambiar"  id="div2" class="droppable" :list="this.servicios.Trabajadores" group="people" :disabled="localBlocker.BlockHoraF">
									<div v-for="(element, index) in this.servicios.Trabajadores" :key="index">
										{{ element.Nombre }}<span v-if="element.RegEstatus === 'B'"> (Usuario Eliminado)</span>
										<input class="icon-eye" v-model="servicios.Personal" :value="element.IdTrabajador" :disabled="localBlocker.BlockHoraF" type="radio" name="radio1" id="radio1">
									</div>
								</draggable>
							</template>
						</CLoader>
					</div>
				</div>
			</div>

			<div class="col-md-12 col-lg-6">
				<div class="row">
					<div class="col-md-12 col-lg-12">
						<h4>VEHÍCULOS</h4>
						<hr class="mb-2">
					</div>

					<div class="col-md-6 col-lg-6">
						<CLoader :pConfigLoad="ConfigLoad">
							<template slot="BodyFormLoad">
								<draggable  :move="checkMove"  id="div1" class="droppable bg-feed" group="vehiculo"  :list="Consultado.ListaVehiculos" :disabled="localBlocker.BlockHoraF">
									<div v-for="(element, index) in Consultado.ListaVehiculos" :key="index">
										{{ element.Vehiculo+' '+element.Numero}}
									</div>
								</draggable>
							</template>
						</CLoader>
					</div>

					<div class="col-md-6 col-lg-6">
						<CLoader :pConfigLoad="ConfigLoad">
							<template slot="BodyFormLoad">
								<draggable id="div2" class="droppable" :list="this.servicios.Vehiculos" group="vehiculo" :disabled="localBlocker.BlockHoraF">
									<div v-for="(element, index) in this.servicios.Vehiculos" :key="index">
										{{ element.Vehiculo+' '+element.Numero}} <span v-if="element.RegVehiculo === 'B'"> (Vehiculo Eliminado)</span>
									</div>
								</draggable>
							</template>
						</CLoader>
					</div>

				</div>
			</div>
		</div>
		<!-- --------------------------------TERMINA PERSONAL ------------------------------------------------------------>




    </div>
</template>

<script>
import Cvalidation from '@/components/Cvalidation.vue'
import moment from 'moment';
import CLoader from "../../../../components/CLoader";

const zonaH = sessionStorage.getItem('ZonaHoraria');

export default {
    props:['Consultado','errorvalidacion','servicios','pBloker'],
    name:'',
    components:{
		CLoader,
        Cvalidation
    },
    data() {
        return {
            Fecha:'',
            Fecha2:'',
            LitaFechas:[],
            ListaHoras:[],
			HorasF:[],
            FechaMin: '',
            timezoneIndex: 0,
            timezones: [
                zonaH
            ],
			localBlocker: {
				BlockFecha:true,
				BlockHoraI:false,
				BlockHoraF:false,
                FechaAyer:''
			},

			ConfigLoad:{
				ShowLoader:false,
				ClassLoad:true,
			},



		}
    },
    methods: {
		HabilitarHoraFin(index){
			let indexHoraFin=this.servicios.FechasHoras[index].horaslaborales.findIndex((element)=>this.servicios.FechasHoras[index].HoraI==element);
			const ArrayCopia=[...this.servicios.FechasHoras[index].horaslaborales];
			let HorasF=ArrayCopia.slice(indexHoraFin+1);
			this.HorasF[index].horaslaborales=HorasF;
		},
		ValidarFechas(item,index) {
			// console.log(item);

			if (item.HoraI !='' && item.HoraF !='')
			{
				var HoraI =item.HoraI.split(':');
				var HoraF =item.HoraF.split(':');

				if( parseInt(HoraI[0]) == parseInt(HoraF[0]) )
				{	
					//console.log('Pasa 1');

					if(parseInt(HoraI[1]) == parseInt(HoraF[1]) ) {
						//console.log('Se encuntra Igualdad '+parseInt(HoraI[1]) +'/'+ parseInt(HoraF[1]))
						//console.log('Pasa 1 - 2');

						this.$toast.warning('La hora inicial y final no pueden ser la misma, el lapso minimo de un servicio es de 30min.');
						this.servicios.FechasHoras[index].HoraF="";
						return false;


					}else if(parseInt(HoraI[1]) >= parseInt(HoraF[1])) {

						//console.log(parseInt(HoraI[1]) +'/'+ parseInt(HoraF[1]));
						this.$toast.warning('La hora final debe ser mayor que la inicial (!)');
							this.servicios.FechasHoras[index].HoraF="";
							return false;

						// if(parseInt(HoraI[0]) == 0 && parseInt(HoraF[0]) == 0){
						// 	this.$toast.warning('La hora final debe ser mayor que la inicial (!)');
						// 	this.servicios.FechasHoras[index].HoraF="";
						// 	return false;
						// }

						if(parseInt(HoraF[1]) == 0){
							//return true;
						}else{
							this.$toast.warning('La hora final debe ser mayor que la inicial (!!)');
							this.servicios.FechasHoras[index].HoraF="";
							return false;
						}

					}
				}
				else
				{
					//console.log('Pasa2');
					if (parseInt(HoraI[0]) > parseInt(HoraF[0]))
					{
						
						if(parseInt(HoraI[0]) > parseInt(HoraF[0])){
							this.servicios.FechasHoras[index].HoraF="";
							this.$toast.warning('La hora final debe ser mayor que la inicial !');
							return false;
						}

						if(parseInt(HoraF[1]) == 0){
							//return true;
						}else{

							this.servicios.FechasHoras[index].HoraF="";
							this.$toast.warning('La hora final debe ser mayor que la inicial !!');
							return false;
						}
					}
				}

				this.ObtenerTrabajadores();

			}else{
				//console.log('Pasa aqui');
			}
		},

        async ListarFechas() {
            //falta validar que las fechas no sean mayores que otras
            if (this.servicios.Fecha_I !=='' && this.servicios.Fecha_F !==''  && this.servicios.Fecha_I !=null && this.servicios.Fecha_F !=null  ) {

                await this.$http.get('servicio/fehcasdinamicas', {
					params:{
						FechaI:this.servicios.Fecha_I,
						FechaF:this.servicios.Fecha_F,
						IdServicio:this.servicios.IdServicio
					}
				}).then((res) => {

                    // if ( res.data.data.Fechas.length>1 && this.servicios.IdServicio>0)
                    // {
                    //     this.$toast.warning('No se puede seleccionar un rango mayor a 2 días');
                    //     this.servicios.Fecha_F = this.servicios.Fecha_I;
                    // }
                    // else
                    // {
                        this.servicios.FechasHoras =[];
                        this.LitaFechas = res.data.data.Fechas;
                        //Este atributo Fechas horas solo funciona para un dia de fecha si se quiere mas de una fecha eliminarlo y atras vez de fechas.Horas laborales ya regresa los valores que tiene
                        this.servicios.FechasHoras = res.data.data.Fechas;
						this.HorasF=res.data.data.FechasFin;

                        if(parseInt(this.servicios.IdServicio) > 0) {
                            this.ObtenerTrabajadores();
                        }
                    // }
                }).finally(()=>{
					for (let index = 0; index < this.servicios.FechasHoras.length; index++) {
						this.HabilitarHoraFin(index);
					}
				});
            }
        },



        async ObtenerTrabajadores() {
			this.ConfigLoad.ShowLoader = true;
            await this.$http.post('servicio/disponibles', {
				Fechas:this.servicios.FechasHoras,
				IdServicio:this.servicios.IdServicio

			}).then( (res) => {

				//console.log(res.data);

				this.Consultado.ListaTrabajadores	= res.data.data.Trabajadores;
				this.Consultado.ListaVehiculos		= res.data.data.Vehiculos;
				this.servicios.Trabajadores			= res.data.data.TrabajadorOcupado;
				this.servicios.Vehiculos			= res.data.data.VehiculosOCupado;
				this.servicios.IdVehiculo			= res.data.data.IdVehiculo;

				if (this.servicios.Vehiculos.length > 0 && this.servicios.Vehiculos[0].Vehiculo === '') {
					this.Consultado.ListaVehiculos.splice(0,1);

				}else if(this.servicios.Vehiculos.length > 0 && this.servicios.Vehiculos[0].Vehiculo === 'VIRTUAL'){
					this.Consultado.ListaVehiculos = res.data.data.Vehiculos.filter(function(el){
						return el.Vehiculo != "VIRTUAL"
					});
				}

					// if (this.servicios.Vehiculos[0].Vehiculo == 'VIRTUAL') {
					//     this.Consultado.ListaVehiculos=res.data.data.Vehiculos.filter(function(el){
					//         return el.Vehiculo !="VIRTUAL"
					//     });
					// }else{
					//    this.Consultado.ListaVehiculos=res.data.data.Vehiculos;
					// }

            }).catch( err => {
                console.log(err);

            }).finally(()=>{
				this.ConfigLoad.ShowLoader = false;
			});
        },

        Limpiar() {
            this.Fecha='';
            this.Fecha2='';
            this.LitaFechas=[];
            this.ListaHoras=[];
			this.HorasF=[];
            this.servicios.FechasHoras=[];
            this.servicios.Vehiculos=[];
			this.localBlocker = {
				BlockFecha: true,
				BlockHoraI: false,
				BlockHoraF: false,
				FechaActual:'',
				FechaAyer:'',
				isPast: false
			}
        },

		fechaMinima() {
			if(this.localBlocker.BlockFecha) {
				//console.log('return null');
				return null

			} else{
				let fecha = '';
				if(this.localBlocker.isPast) {
					fecha = moment(this.localBlocker.FechaAyer).add(1,'day').format('YYYY-MM-DD');
					fecha = new Date(fecha);
					//console.log('FechaMinimaPast: '+fecha);

				}else {
					fecha = moment(new Date()).add(1,'day').format('YYYY-MM-DD');
					fecha = new Date(fecha);
					//console.log('FechaMinima: '+fecha);
				}

				return fecha;
			}
		},

		Cambiar() {
			for (let i=0; i<this.Consultado.ListaTrabajadores.length; i++) {

				if (this.Consultado.ListaTrabajadores[i].IdTrabajador === this.servicios.Responsable) {
					this.servicios.Responsable=0;
				}
			}
		},

		checkMove: function(e) {
			if (this.servicios.Vehiculos.length>0) {
				return false;
			}
		}


    },
    created() {

        this.bus.$off('LimpiarCompoenets');
        this.bus.$on('LimpiarCompoenets',()=> {
            this.Limpiar();
        });



    },
	mounted() {
		this.ConfigLoad.ShowLoader = false;
		if(this.servicios.IdServicio>0) {
			this.ListarFechas();
		}

	},
	computed: {
        timezone() {
            return this.timezones[this.timezoneIndex];
        },

		blockValidate() {
			if(this.pBloker !== undefined){
				this.localBlocker = this.pBloker;
			}
			return this.localBlocker;
		}
    },
}
</script>
