<template>
    <div>
        <input type="hidden" :disabled="Validate">
        <div>
            <div class="modal" :id="NombreModal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div :class="'modal-dialog modal-dialog-centered modal-dialog '+Tamanio" role="document">
                    <div class="modal-content">
                        <div class="modal-header bg-modal">
                            <h5 class="modal-title" id="exampleModalLabel">{{ Titulo }}</h5>
                            <button @click="CerrarModal" type="button" class="close close-2" aria-label="Close">
							    <i class="fad fa-times-circle"></i>
						    </button>
                        </div>
                        <div class="modal-body">
                            <slot name="Form"></slot>
                        </div>
                        <div v-if="ShowF" class="modal-footer justify-content-center">
                            <slot name="BtnAdicional"></slot>
                            <button :disabled="DisableBtn" v-if="ShowBtnCancel" @click="Limpiar" type="button" class="btn btn-04 ban"></button>
                            <button v-if="ShowBtnSave" :disabled="DisableBtn" type="button" class="btn btn-01 btn-block btn-grande mt-1" style="width:40%;min-height:6vh" @click="Guardar">
                                <i v-show="DisableBtn" class="fa fa-spinner fa-pulse fa-fw"></i> <span class="h2">{{ TextBtnSave }}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:["pModalProps"],
    data() {
        return {
            Modal: true,
			Tamanio: "",
			Titulo: "",
			CloseM: true,
			ShowF: true,
			NombreModal: "ModalForm",
			TipoModal: 1,
			showComponentFoot: false,
			Rol:"Admin",
			EmitSeccion:"",
            DisableBtn:false,
            ShowBtnCancel:true,
            ShowBtnSave:true,
            DisableBtn:false,
            TextBtnSave:"Guardar"
        }
    },
    methods: {
        CerrarModal(){
            $("#" + this.NombreModal).modal("hide");
        },
        Limpiar(){
            $("#" + this.NombreModal).modal("hide");
        },
        Guardar(){
            this.bus.$emit('Save_'+this.EmitSeccion);
        }
    },
    computed:{
        Validate(){
            if (this.pModalProps.Tamanio!=undefined) {
                this.Tamanio=this.pModalProps.Tamanio;
            }
            if (this.pModalProps.Titulo!=undefined) {
                this.Titulo=this.pModalProps.Titulo;
            }
            if (this.pModalProps.ShowF!=undefined) {
                this.ShowF=this.pModalProps.ShowF;
            }
            if (this.pModalProps.NombreModal!=undefined) {
                this.NombreModal=this.pModalProps.NombreModal;
            }
            if (this.pModalProps.EmitSeccion!=undefined) {
                this.EmitSeccion=this.pModalProps.EmitSeccion;
            }
            if (this.pModalProps.DisableBtn!=undefined) {
                this.DisableBtn=this.pModalProps.DisableBtn;
            }
            if (this.pModalProps.ShowBtnCancel!=undefined) {
                this.ShowBtnCancel=this.pModalProps.ShowBtnCancel;
            }
            if (this.pModalProps.ShowBtnSave!=undefined) {
                this.ShowBtnSave=this.pModalProps.ShowBtnSave;
            }
            if (this.pModalProps.DisableBtn!=undefined) {
                this.DisableBtn=this.pModalProps.DisableBtn;
            }
            if (this.pModalProps.TextBtnSave!=undefined) {
                this.TextBtnSave=this.pModalProps.TextBtnSave;
            }
            return true;
        }
    }
}
</script>

<style>

</style>