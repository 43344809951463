<template>
	<div>
		<input type="hidden" :disabled="validate" />
		<input type="hidden" :disabled="validateFiltros" />

		<CHead :oHead="Head">
			<template slot="component">
				<slot name="botonextra">

				</slot>
				<button
					v-if="btnCli2"
					data-toggle="modal"
					data-target="#ModalNewUser"
					data-backdrop="static"
					type="button"
					class="btn btn-pink mb-2 mr-1"
				>
					Añadir
				</button>
			</template>
		</CHead>
		<!-- SOLO APARECE SI ESTA EN EL LISTADO GENERAL DE FACTURAS -->
		<slot v-if="Head.isFactura" name="FacturaDashboard">
		</slot>

		<div :class="Head.isContactos ? 'row justify-content-center mt-0 p-0' : 'row justify-content-center mt-2'">
			<div class="col-12 col-sm-12 col-md-12 col-lg-12">
				<!-- Cambiar clase únicamente si estamos en el historial -->
				<div>
				<slot name="contoleradicional"></slot>

				<div :class="'card card-01'">
					<div class="row" v-if="isHistorialEquipo">
						<slot name="DatosEquipo"></slot>
					</div>
					<div class="row" v-if="this.FiltroC.Show">

						<!-- <div :class="Head.isCuentas || Head.isCrm ? 'col-md-6 col-lg-6': 'col-md-12 col-lg-12' "> -->
							<div :class="Head.isCuentas ? 'col-md-6 col-lg-6' : Head.isCrm ? 'col-md-10' : 'col-md-12 col-lg-12' ">
							<form class="form-inline">

								<div class="form-group mr-2">
									<input @input="Filtrar"
									v-if="Head.ShowBusqueda"
										autocomplete="off"
										v-model="FiltroC.Nombre"
										type="text"
										class="form-control lup"
										id="textBusqueda"
										:placeholder="validateFiltros"/>
								</div>

								<div v-if="Head.ShowFilas" class="form-group  mr-2">
									<label class="mr-1">Filas &nbsp;</label>
									<select v-if="!Head.isContactos" @change="Filtrar" v-model="FiltroC.Entrada" class="form-control">
										<option v-if="Head.isHistorialEquipo" :value="16">16</option>
										<option v-else :value="20">20</option>
										<option :value="50">50</option>
										<option :value="100">100</option>
									</select>
									<select v-else @change="Filtrar" v-model="FiltroC.Entrada" class="form-control">
										<!-- BLOQUE DE OPCIONES DE CONTACTOS -->
										<option :value="30">30</option>
										<option :value="60">60</option>
										<option :value="90">90</option>
										<option :value="120">120</option>
									</select>
								</div>

								<slot name="Filtros"> </slot>
								<div v-if="Head.isHistorialEquipo" class="form-group ml-lg-5 mr-0 justify-content-end align-items-center w-50">
									<button @click="Nuevo(0)" v-if="RolUsuario!='Monitoreo'" data-toggle="modal" data-target="#Servicios"  data-backdrop="static" data-keyboard="false"  type="button" hidden class="btn btn-pink mt-1 mr-1">
										<i class="far fa-plus-circle"></i>
										{{Head.BtnNewName}}
									</button>
								</div>
							</form>
						</div>
						<slot name="Botonnuevo"></slot>
						<div class="col-md-6 col-lg-6 text-right" v-if="Head.isCuentas">
							<form>
								<slot name="botonCuentas"></slot>
							</form>
						</div>
					</div>
					<!-- Solo cuando es modulo de cuentas -->
					<div v-if="Head.isCuentas" class="row">
						<div :class="Head.verFiltroCuentas ? 'col-12 col-sm-12 col-md-9 multi-1 col-lg-10 col-xl-10': 'col-12 col-sm-12 col-md-9 multi-1 col-lg-12 col-xl-12' ">
							<div :class="'table-responsive'">
								<CLoader :pConfigLoad="ConfigLoad">
									<template slot="BodyFormLoad">
										<table class="table-01 text-nowrap mt-2">
											<thead>
												<slot name="header"></slot>
											</thead>
											<tbody>
												<slot name="body"></slot>
												<slot name="botones"> </slot>
											</tbody>
										</table>
									</template>
								</CLoader>
							</div>
						</div>
						<div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-2" v-if="Head.verFiltroCuentas">
							<slot name="FiltroCuentas"></slot>
						</div>
					</div>

					<!-- Cuando es cualquier catalogo -->


					<div v-else :class="!Head.isContactos ? 'table-responsive': 'mb-2'" :id="Head.isHistorialEquipo ? 'scrollvertical':''">
						<CLoader v-if="!Head.isContactos" :pConfigLoad="ConfigLoad">
							<template slot="BodyFormLoad">
								<table class="table-01 text-nowrap mt-2">
									<thead>
										<slot name="header"></slot>
									</thead>
									<tbody>
										<slot name="body"></slot>
										<slot name="botones"> </slot>
									</tbody>
								</table>
							</template>
						</CLoader>
					</div>

					<Pagina
						v-if="!Head.isContactos && Head.ShowPager"
						:Filtro="Filtro"
						:Entrada="FiltroC.Entrada"
						@Pagina="Filtrar"
					></Pagina>
					<slot name="mapa"></slot>
				</div>
				<!-- Que exista únicamente si estamos en el historial del equipo -->
				<!-- <div v-if="Head.isHistorialEquipo" class="flex card card-01 col-xs-12 col-sm-12 col-md-6 col-lg-6">
					<slot name="detallehistorialequipo"></slot>
				</div> -->
				<!-- UNICAMENTE SI ESTÁ EN SUS CONTACTOS -->
				<div v-if="Head.isContactos">
					<CLoader :pConfigLoad="ConfigLoad">
						<template slot="BodyFormLoad">
							<slot name="cards">

							</slot>
						</template>
					</CLoader>
					<Pagina
						:Filtro="Filtro"
						:Entrada="FiltroC.Entrada"
						@Pagina="Filtrar"
					></Pagina>
				</div>
			</div>
			</div>
		</div>

		<!--Fin del conenido-->
	</div>
</template>
<script>
import Pagina from "@/components/Cpagina.vue";
import CLoader from "./CLoader";
export default {
	name: "Clist",
	props: [
		"regresar",
		"Nombre",
		"Pag",
		"Total",
		"isModal",
		"pShowBtnAdd",
		"PNameButonNuevo",
		"ShowHead",
		"Filtro",
		"NameReturn",
		"btnCli",
		"Cuentas",
		"pConfigLoad",
		"isHistorialEquipo",
		"isCrm",
		"isContactos",
		"isFactura",
		"ShowPager",
		"ShowFilas",
		"ShowBusqueda",
		"EmitSeccion"
	],
	components: {
		Pagina,
		CLoader
	},

	data() {
		return {
			Entrada: 50,
			ShowBtnAdd: true,
			NameButonNuevo: "Nuevo",
			Head: {
				ShowHead: true,
				Title: "Datos",
				BtnNewShow: true,
				BtnNewName: "Nuevo",
				isreturn: true,
				isModal: true,
				isEmit: true,
				Url: "",
				ObjReturn: "",
				NameReturn: "Regresar",
				isCuentas:false,
				isFactura:false,
				verFiltroCuentas:false,
				isHistorialEquipo:false,
				isCrm:false,
				isContactos:false,
				ShowPager:true,
				EmitSeccion:'',
				ShowFilas:true,
				ShowBusqueda:true
			},
			FiltroC: {
				Nombre: "",
				Entrada: 20,
				Placeholder: "Buscar..",
				Show: true
			},
			btnCli2: false,
			ConfigLoad:{
				ShowLoader: true,
				ClassLoad:  true,
			},
			TimeOut: null,
			RolUsuario:''


		};
	},
	methods: {
		Nuevo() {
			if (this.isModal == true) {
				if (this.Head.EmitSeccion!='' && this.Head.EmitSeccion!=undefined) {
					this.bus.$emit("NewModal_"+this.Head.EmitSeccion, true);
				}
				else{
					this.bus.$emit("Nuevo", true);
				}

			} else {
				this.bus.$emit("Nuevo");
			}
			if (this.isHistorialEquipo==true) {
				this.bus.$emit("NuevoServicioMonitoreo");
			}
		},
		Regresar() {
			this.bus.$emit("Regresar");
		},
		Filtrar() {
			if(this.FiltroC != undefined) {

				if (this.FiltroC.Entrada != this.Filtro.Entrada) {
					this.Filtro.Pagina = 1;
				}

				this.Filtro.Nombre = this.FiltroC.Nombre;
				this.Filtro.Entrada = this.FiltroC.Entrada;

				if(this.FiltroC.Nombre != '') {
					clearTimeout(this.TimeOut);

					this.TimeOut = setTimeout(() => {
						this.$emit("FiltrarC");
					}, 1000);

				} else {
					this.$emit("FiltrarC");
				}
			}

		}
	},
	created() {
		/*
        if(this.PNameButonNuevo!=undefined){
            this.NameButonNuevo=this.PNameButonNuevo;
        }*/

	},
	computed: {
		validate() {
			if (this.pShowBtnAdd != undefined) {
				this.ShowBtnAdd = this.pShowBtnAdd;
				this.Head.BtnNewShow = this.pShowBtnAdd;
			}
			if (this.Nombre != undefined) {
				this.Head.Title = this.Nombre;
			}
			if (this.isModal != undefined) {
				this.Head.isModal = this.isModal;
			}
			if(this.isHistorialEquipo!=undefined) {
				this.Head.isHistorialEquipo = this.isHistorialEquipo;
			}
			if(this.isContactos!=undefined) {
				this.Head.isContactos = this.isContactos;
			}

			if (this.PNameButonNuevo != undefined) {
				this.Head.BtnNewName = this.PNameButonNuevo;
			}
			if (this.regresar != undefined) {
				this.Head.isreturn = this.regresar;
			}
			if (this.ShowHead != undefined) {
				this.Head.ShowHead = this.ShowHead;
			}

			if (this.NameReturn != undefined) {
				this.Head.NameReturn = this.NameReturn;
			}
			if (this.Cuentas !== undefined) {
				this.Head.isCuentas = this.Cuentas.isCuentas;
				this.Head.verFiltroCuentas = this.Cuentas.verFiltros;
			}
			if (this.isCrm!=undefined) {
				this.Head.isCrm=this.isCrm;
			}
			if (this.isFactura!=undefined) {
				this.Head.isFactura=this.isFactura;
			}

			if (this.ShowPager != undefined) {
				this.Head.ShowPager = this.ShowPager;
			}
			if (this.ShowFilas != undefined) {
				this.Head.ShowFilas = this.ShowFilas;
			}
			if (this.ShowBusqueda != undefined) {
				this.Head.ShowBusqueda = this.ShowBusqueda;
			}

			if (this.btnCli != undefined) {
				this.btnCli2 = this.btnCli;
			}
			if (this.EmitSeccion!=undefined) {
				this.Head.EmitSeccion=this.EmitSeccion;
			}

			if(this.pConfigLoad != undefined && this.pConfigLoad.ShowLoader != undefined){
				this.ConfigLoad.ShowLoader = this.pConfigLoad.ShowLoader;
			}else {
				this.ConfigLoad.ShowLoader = false;
			}

			if(this.pConfigLoad != undefined && this.pConfigLoad.ClassLoad != undefined){
				this.ConfigLoad.ClassLoad = this.pConfigLoad.ClassLoad;
			}else{
				this.ConfigLoad.ClassLoad = false;
			}
			this.RolUsuario=JSON.parse(sessionStorage.getItem("user")).Perfil;

			return this.isModal;
		},

		validateFiltros() {
			if (this.Filtro !== undefined) {
				if (this.Filtro.Nombre !== undefined) {
					this.FiltroC.Nombre = this.Filtro.Nombre;
				}
				if (this.Filtro.Placeholder !== undefined) {
					this.FiltroC.Placeholder = this.Filtro.Placeholder;
				}
				if (this.Filtro.Show !== undefined) {
					this.FiltroC.Show = this.Filtro.Show;
				}
				if (this.Filtro.Entrada !== undefined) {
					this.FiltroC.Entrada = this.Filtro.Entrada;
				}
			}

			return this.FiltroC.Placeholder;
		}
	}
};
</script>
