<template>
    <small class="text-danger">{{Mensaje}}</small>
</template>
<script>
export default {
    props:['Mensaje'],
    data() {
        return {
            
        }
    }
}
</script>