<template>
    <div class="col-md-6 col-lg-6" :style="pShowPuntuacion ? '' : 'height:400px;'">
        <b-overlay :show="isOverlay" rounded="sm" spinner-variant="primary" :style="pShowPuntuacion ? '' : 'height:250%;'">
            <div class="row">
                <div class="col-12">
                    <div :class="pShowPuntuacion ? 'card card-grafica h-100 mb-3' : 'card card-grafica mb-3'" :style="pShowPuntuacion ? '' : 'height:188%;'">
                        <div class="card-body">
                            <div class="filtro-grafic" id="grafica_004" v-if="isVisible">
                                <div class="row justify-content-start">
                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                        <div class="form-inline mb-2">
											<label class="title-grafic side-l mr-3">Hrs. por Tipo Servicios</label>
                                            <label for="">Cliente</label>
											<treeselect
                                        		class="d-inline-block w-25 mt-2 pt-0 ml-2"
                                        		style="top: -4px;"
                                        		:maxHeight="180"
                                        		:options="ListaCliente"
                                        		placeholder="Cliente..."
                                        		v-model="cliente.IdCliente"
                                        		@input="get_dataSource();GetListaClienteS()"
                                    		/>

											<label for="" class="ml-2">Propiedad</label>
											<treeselect
                                        		class="d-inline-block w-25 mt-2 pt-0 ml-2"
                                        		style="top: -4px;"
                                        		:maxHeight="180"
                                        		:options="ListaClienteS"
                                        		placeholder="Propiedad..."
                                        		v-model="cliente.IdClienteS"
                                        		@input="get_dataSource();ListaNumContrato()"
                                    		/>
											<!-- <div v-if="cliente.Nombre != ''">
												<span class="text-center mr-5 bold text-body" style="font-size:16px">Cliente: {{$limitCharacters(cliente.Empresa,40)}}</span>
												<span class="bold text-body" style="font-size:16px"> Propiedad: {{$limitCharacters(cliente.Nombre,40)}}</span>
											</div>
                                            <h6 v-else class="text-center mr-2">Cliente Sin Selecionar</h6> -->
                                        </div>
                                        <form class="form-inline">
                                            <!-- <button @click="ListaCliente"  data-toggle="modal" data-target="#ModalForm4" data-backdrop="static" type="button" class="btn btn-01 search mr-2">Cliente</button> -->

											<label class="mr-1">No. De Contrato</label>
                                    		<select @change="get_dataSource" v-model="IdContrato" name="" id=""  class="form-control form-control-sm mr-2">
                                    		    <option :value="''">Seleccione Un Numero de Contrato</option>
                                    		    <option v-for="(item,index) in ListaNumc" :key="index" :value="item.IdContrato">
                                    		    	{{item.NumeroC}}
                                    		    </option>
                                    		</select>

											<v-date-picker
												mode="range"
												v-model="rangeDate"
												@input="get_dataSource"
												:input-props="{
													class: 'form-control form-control-sm mr-2 calendar',
													placeholder:
														'Selecciona un rango de fecha para buscar',
													readonly: true
												}"
											/>
                                        </form>
                                        <button
											type="button"
											class="btn close abs_01"
											@click="Ocultar()"
										>
											<i class="fal fa-times"></i>
										</button>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-10 col-sm-10 col-md-8 col-lg-8">
									<h6 class="title-grafic side-l">
										Horas Por Tipo de Servicio
									</h6>
								</div>
                                <div class="col-2 col-sm-2 col-md-4 col-lg-4 text-right">
									<button
										type="button"
										class="btn-fil-002"
										title="Filtros"
										@click="mostrarfiltro()"
									>
										<i class="fas fa-filter"></i>
									</button>
								</div>
								<div class="col-12 col-sm-12 col-md-12 col-lg-12">
									<hr />
								</div>
                            </div>
                            <div :style="pShowPuntuacion ? 'height:481px' : 'height:430px'">
                                <jscharting :mutable="false" ref="chart2" :options="chartOptions" :class="pShowPuntuacion ? 'ml-0 pl-0 columnChart2Puntuacion' : 'ml-0 pl-0 columnChart2'"></jscharting>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </b-overlay>
		<Ccliente :TipoModal='1' :Origen="'Tiposervicio'" :IdModal="'ModalForm4'"/>
    </div>
</template>

<script>
export default {
    name: "HorasTipoServicio",
	props:["pShowPuntuacion"],
    data() {
        return {
            cliente:{
				Nombre:'',
				IdCliente: null,
				IdClienteS: null,
			},
			ListaCliente:[],
			ListaClienteS:[],
            ListaNumc:[],
			IdContrato:'',
            rangeDate: {
                start:null,
                end:null
            },
            isVisible: false,
            isOverlay: false,
            chartOptions:{
				debug:true,
                type:'horizontal column solid',
				legend_visible:false,
            	yAxis:{
            	    scale_range:{min:0, padding:0.1},
            	    orientation:'opposite',
            	},
            	xAxis:{
					overflow:'scroll',
            	    defaultTick_label_width: 150,
					caOffset:5, 
            	    crosshair: { 
            	        enabled: true, 
            	        gridLine_visible: false, 
            	        outline_width: 0, 
            	        label_style_fontWeight: 'bold'
            	    } 
            	},
            	defaultSeries_palette: ['#CFD8DC'],
            	defaultPoint: { 
            	    outline_width: 0, 
            	    tooltip: '<b>%yValue hr</b>',
            	},
            	defaultTooltip_label_text: '%points',
            	series:[]
            },
            
        }
    },
    methods: {
        mostrarfiltro() {
			this.isVisible = true;
		},

		Ocultar() {
			this.isVisible = false;
		},
        verificarnumero(value) {
			let val = value;
			if (value < 10) {
				val = "0" + value;
			}
			return val;
		},
        async get_dataSource(){
            this.isOverlay = true;
            await this.$http.get("despachooneequipo/get",{
                params:{
                    Fecha_I: this.rangeDate.start,
					Fecha_F: this.rangeDate.end,
					IdCliente:this.cliente.IdCliente,
					IdClienteS:this.cliente.IdClienteS,
					IdContrato:this.IdContrato
                }
            }).then((res)=>{
                this.isOverlay = false;
                var date = new Date(this.rangeDate.start);
				var year = date.getFullYear();
				var month = date.getMonth() + 1; //getMonth is zero based;
				var day = date.getDate();
				var formatted = this.verificarnumero(day) + "-" + this.verificarnumero(month) + "-" + year;

				var datef = new Date(this.rangeDate.end);
				var yearf = datef.getFullYear();
				var monthf = datef.getMonth() + 1; //getMonth is zero based;
				var dayf = datef.getDate();
				var formattedf = this.verificarnumero(dayf) + "-" + this.verificarnumero(monthf) + "-" + yearf;
				
				res.data.data.sort((a,b)=>b.value - a.value)

                let points=res.data.data.map((element)=>{
					return {
                            x:element.label,
							opacity:1,
                            y:Number(element.value),
							color:element.color,
							xAxisTick_label_text: this.$limitCharacters(element.label,18),
							xAxisTick_label_color: '#373D3F'
                        }
				});
				// points.sort((a, b) => b.y - a.y);
				this.chartOptions.series=
					[
						{
							points:points
						}
					]
				
            })
        },
		async GetListaCliente(){
			await this.$http.get("clientes/get"
			).then((res)=>{
				this.ListaCliente=res.data.data.clientes.map(function(obj){
					return {id:obj.IdCliente,label:obj.Nombre}
				})
			});
		},
		GetListaClienteS(){
			this.cliente.IdClienteS=null;
			this.IdContrato='';
			this.ListaNumc=[];
			this.$http.get("clientesucursal/get",{
				params:{
					Todos:true,
					RegEstatus:'A',
					IdCliente:this.cliente.IdCliente
				}
			}
			).then((res)=>{
				this.ListaClienteS=res.data.data.sucursal.map(function(obj){
					return {id:obj.IdClienteS,label:obj.Nombre}
				})
			});
		},
        // async ListaCliente() {
		// 	this.bus.$emit('ListCcliente');
		// },
		async ListaNumContrato() {
			this.IdContrato='';
			await this.$http.get(
				'numcontrato/get',
				{
					params:{IdClienteS:this.cliente.IdClienteS}
				}
			).then( (res) => {
				this.ListaNumc =res.data.data.row;
			});
		},
		SeleccionarCliente(objeto) {
			this.cliente=objeto;
			this.get_dataSource();
			this.ListaNumContrato();
		},
    },
    created() {
		this.bus.$off('SeleccionarClienteOportunidad');
		var date = new Date(),
		y = date.getFullYear(),
		m = date.getMonth();
		var firstDay = new Date(y, m, 1);
		var lastDay = new Date(y, m + 1, 0);
		this.rangeDate = {
			start: firstDay,
			end: lastDay
		};
	},
	mounted() {
		this.bus.$on('SeleccionarClienteOportunidad',(oSucursal)=> {
			this.SeleccionarCliente(oSucursal);
		});
		this.GetListaCliente();
		this.get_dataSource();
	},
}
</script>

<style>
	.columnChart2{
		height: 938px;
	}
	.columnChart2Puntuacion{
		height: 510px;
	}
</style>