<template>
    <div>
        <CHeader :pHeaderProps="HeaderProps">
            <template slot="Contenido">
                <div class="row mt-3">
                    <div class="col-12 col-sm-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-6 col-sm-6">
                                        <p class="text-center"><b>Monto Asignado</b></p>
                                        <p class="text-center">${{ Gasto.Monto }}</p>
                                    </div>
                                    <div class="col-6 col-sm-6">
                                        <p class="text-center"><b>Saldo Actual</b></p>
                                        <p class="text-center">${{ Gasto.Actual }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-2" style="min-height:65vh;">
                    <div class="col-12 col-sm-12">
                        <!-- <div class="card mt-2" @click="Nuevo()" v-for="(item,index) in ListaGastos" :key="index">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-4 col-sm-4 justify-content-center d-flex">
                                        <span class="h2">Concepto</span>
                                    </div>
                                    <div class="col-4 col-sm-4 justify-content-center d-flex">
                                        <span class="h2">Monto</span>
                                    </div>
                                    <div class="col-4 col-sm-4 justify-content-center d-flex">
                                        <span class="h2">Fecha</span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-4 col-sm-4 justify-content-center d-flex">
                                        <span>{{ item.Concepto }}</span>
                                    </div>
                                    <div class="col-4 col-sm-4 justify-content-center d-flex">
                                        <span>{{ item.Total }}</span>
                                    </div>
                                    <div class="col-4 col-sm-4 justify-content-center d-flex">
                                        <span>{{ item.Fecha }}</span>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <div class="table-responsive">
                            <table class="table-01 text-nowrap">
                                <thead class="text-center">
                                    <th>Concepto</th>
                                    <th>Monto</th>
                                    <th>Fecha</th>
                                </thead>
                                <tbody>
                                    <tr class="text-center" style="height:10vh;" role="button" data-toggle="modal" data-target="#ModalForm"  data-backdrop="static" data-keyboard="false" @click="Nuevo(item.IdGastoxTrabajador)" v-for="(item,index) in ListaGastos" :key="index">
                                        <td>{{ item.Concepto }}</td>
                                        <td>{{ item.Total }}</td>
                                        <td>{{ item.Fecha }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center mt-2">
                    <div class="col-4 col-sm-4">
                        <button @click="Nuevo(0)" class="btn btn-01 btn-block" data-toggle="modal" data-target="#ModalForm"  data-backdrop="static" data-keyboard="false"  type="button">
                            <i class="fa fa-3x fa-plus"></i>
                        </button>
                    </div>
                </div>
            </template>
        </CHeader>
        <Modal :pModalProps="ModalProps">
            <template slot="Form">
                <Form :poBtnSave="ModalProps" :IdCajaC="IdCajaC" :IdTrabajador="IdTrabajador"></Form>
            </template>
            <template slot="BtnAdicional">
                <button v-if="ShowCamara" type="button" class="btn btn-01 btn-block btn-grande" style="width:40%;height:6vh"><i class="far fa-2x fa-camera"></i></button>
            </template>
        </Modal>
        <!-- <Modal :size="'modal-md'" :Nombre="'Registro de Gasto'" :NameModal="'ModalForm'">
            <template slot="Form">
                <Form></Form>
            </template>
        </Modal> -->

    </div>
</template>

<script>
import CHeader from "@/views/webapp/components/HeaderWebApp.vue";
// import Modal from '@/components/Cmodal.vue';
import Modal from '@/views/webapp/components/ModalWebApp.vue';
import Form from "@/views/webapp/gastos/FormDetalleWebApp.vue";
export default {
    components:{Modal,CHeader,Form},
    props:['pIdCajaC'],
    data() {
        return {
            HeaderProps:{
                ShowFechaHoy:false,
                ShowTecnico:false,
                ShowMenuDropdown:false,
                ShowFotoPerfil:false,
                ShowReturn:true,
                TextoHeader:'Gastos',
                ShowLogo:false,
                IndicadorAccion:false,
                IndicadorAccionTexto:'',
                TextoCentroSubHeader:''
            },
            ModalProps:{
                Tamanio:'modal-md',
                Titulo: "Registro de Gasto",
                NombreModal: "ModalForm",
                EmitSeccion:"Gasto",
                ShowBtnCancel:false,
                DisableBtn:false,
            },
            Gasto:{},
            IdTrabajador:'',
            IdCajaC:'',
            ListaGastos:[],
            IdDetalle:0,
            ShowCamara:false
        }
    },
    methods: {
        async Lista(){
            await this.$http.get('webapp/gastodetalle',{
                params:{
                    IdTrabajador:this.IdTrabajador,
                    IdCajaC:this.IdCajaC
                }
            }).then((res)=>{
                this.Gasto=res.data.data.Detalle;
                this.ListaGastos=res.data.data.Lista;
                this.HeaderProps.TextoCentroSubHeader=this.Gasto.Caja;
            });
        },
        Nuevo(Id){
            this.ShowCamara=Id>0 ? true : false;
            this.bus.$emit('Nuevo_'+this.ModalProps.EmitSeccion);
        }
    },
    created() {
        this.bus.$off('Lista_'+this.ModalProps.EmitSeccion);
        this.bus.$on('Lista_'+this.ModalProps.EmitSeccion,()=>{
            this.Lista();
        });
        if (this.pIdCajaC!=undefined) {
            sessionStorage.setItem('idcajac',this.pIdCajaC);
        }
        this.IdCajaC=sessionStorage.getItem('idcajac');
        this.IdTrabajador=JSON.parse(sessionStorage.getItem('user')).IdTrabajador;
    },
    mounted() {
        this.Lista();
    },
}
</script>

<style>

</style>