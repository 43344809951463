<template>
    <div>
        <CHeader :pHeaderProps="HeaderProps">
            <template slot="Contenido">
                <div class="row mt-3">
                    <div class="col-12 col-sm-12">
                        <input :readonly="SinFirma" v-model="Nombre" type="text" class="form-control input-firma" placeholder="Escriba aquí su nombre completo"/>
                        <Cvalidation v-if="this.errorvalidacion.Nombre" :Mensaje="this.errorvalidacion.Nombre[0]"></Cvalidation>
                    </div>
                </div>
                <div class="row">
                    <div v-show="!SinFirma" class="col-12 col-sm-12">
                        <!-- <textarea name="" id="" cols="30" rows="20" class="form-control"></textarea> -->
                        <VueSignaturePad height="400px" ref="signaturePad" />
                    </div>
                    <div v-show="SinFirma" class="col-12 col-sm-12">
                        <div style="height:400px;color:gray;width:100%"></div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 mb-0 mt-0">
                        <p class="text-center">Firme en el espacio superior</p>
                    </div>
                </div>
                <hr>
                <div v-show="!SinFirma" class="row mb-3 mt-2">
                    <div class="col-12 col-sm-12">
                        <h3 class="text-center">Encuesta de Calidad</h3>
                        <div class="d-flex justify-content-center">
                            <star-rating v-model="Rating" :star-size="55" active-color="#ebf20a" :border-width="1" :border-color="'#000000'" :max-rating="5"/>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-12">
                        <div class="form-group">
                            <div class="form-check form-check-inline">
                                <div class="checkbox">
                                    <label>
                                        <input @change="DesactivarFirma" type="checkbox" v-model="SinFirma">
                                        <span class="checkbox-material-green">
                                            <span class="check"></span>
                                        </span> Sin Firma de Cliente
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-12 col-sm-12">
                        <div class="row justify-content-center mb-2">
                            <div class="col-4 col-sm-4">
                                <button @click="LimpiarFirma" type="button" class="btn btn-01 btn-block">
                                    <i class="far fa-redo fa-3x"></i>
                                </button>
                            </div>
                            <div class="col-4 col-sm-4">
                                <button :disabled="DisabledBtn" @click="FirmarOrden" type="button" class="btn btn-01 btn-block">
                                    <i v-show="DisabledBtn" class="fa fa-spinner fa-3x fa-pulse fa-fw"></i>
                                    <i v-show="!DisabledBtn" class="far fa-check fa-3x"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </CHeader>
    </div>
</template>

<script>
import CHeader from "@/views/webapp/components/HeaderWebApp.vue";
import StarRating from 'vue-star-rating'
export default {
    props:[],
    components:{
        CHeader,
        StarRating
    },
    data() {
        return {
            HeaderProps:{
                ShowFechaHoy:true,
                ShowTecnico:false,
                ShowMenuDropdown:false,
                ShowIconSubAccion:false,
                ShowFotoPerfil:false,
                ShowReturn:true,
                TextoHeader:'Cierre de Orden',
                ShowLogo:false
            },
            Nombre:'',
            SinFirma:false,
            Servicio:{},
            DisabledBtn:false,
            errorvalidacion:[],
            Rating:null,
            ShowPuntuacion:false
        }
    },
    methods: {
        FirmarOrden(){
            this.DisabledBtn=true;
            const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
            // console.log(isEmpty);
            // console.log(data);
            let formData=new FormData();
            formData.set('IdServicio',this.Servicio.IdServicio);
            formData.set('IdCliente',this.Servicio.IdCliente);
            formData.set('IdClienteS',this.Servicio.IdCliente);
            if (this.SinFirma) {
                formData.set('Firma',"");
            }
            else{
                if (isEmpty) {
                    this.$toast.info('Por Favor Firme en el Recuadro');
                    this.DisabledBtn=false;
                    return false;
                }
                else{
                    formData.set('Firma',data.split("base64,")[1]);
                }
            }
            formData.set('Nombre',this.Nombre);
            formData.set('SinFirma',this.SinFirma);
            formData.set('Rating',this.Rating)

            this.$http.post('webapp/firmaservicio',formData
            ).then((res)=>{
                this.DisabledBtn=false;
                this.$toast.success('El servicio ha concluído correctamente');
                this.$router.push({name:'ServiciosWebApp'});
            }).catch((err)=> {
                this.errorvalidacion=err.response.data.message.errores;
                this.DisabledBtn=false;
                this.$toast.info('Verifique los datos');
            })
        },
        LimpiarFirma(){
            this.$refs.signaturePad.clearSignature();
            this.Nombre='';
        },
        DesactivarFirma(){
            this.LimpiarFirma();
            this.Rating=null;
        },
    },
    mounted(){
        this.Servicio=JSON.parse(sessionStorage.getItem('servicio'));
    }
}
</script>

<style>

</style>