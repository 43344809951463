<template>
    <CHeader :pHeaderProps="HeaderProps">
        <template slot="Contenido">
            <CLoader :pConfigLoad="ConfigLoad">
                <template slot="BodyFormLoad">
                    <div v-show="ListHistorialServicio.length > 0" class="row mt-3" v-for="(item,index) in ListHistorialServicio" :key="index" @click="IrAReportes()">
                        <div class="col-12 col-sm-12">
                            <div class="card card-01 shadow" @click="IrAReportes(item)">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-3 col-sm-3 position-relative">
                                            <div class="icon-circul d-flex justify-content-center align-content-center" :style="'background-color:'+item.FondoColor+';'">
                                                <img class="icon-servicio" :src=RutaIconos+item.Imagen2 alt="nada">
                                            </div>
                                        </div>
                                        <div class="col-9 col-sm-9">
                                            <p><b>Tipo de servicio:</b> {{ item.Tipo }}</p>
                                            <p><b>Fecha de servicio:</b> {{ $getCleanDate(item.Fecha_I,false) }}</p>
                                            <p><b>Hora:</b> {{ getHourClean(item.HoraInicio) }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-show="ListHistorialServicio.length == 0"  class="row mt-3">
                        <div class="col-12 col-sm-12 text-center mt-3">
                            <h2>Sin Servicios.</h2>
                        </div>
                    </div>
                </template>
            </CLoader>
        </template>
    </CHeader>
</template>

<script>
import CHeader from "@/views/webapp/components/HeaderWebApp.vue";
export default {
    props:['pEquipo'],
    components:{CHeader},
    data() {
        return {
            HeaderProps:{
                ShowSubHeader:true,
                ShowTecnico:false,
                ShowFechaHoy:false,
                ShowMenuDropdown:false,
                TextoCentroSubHeader:'',
                ShowFotoPerfil:false,
                ShowReturn:true,
                TextoHeader:'Historial de Servicio',
                ShowLogo:false,
                TextoCentroSubHeader:''
            },
            Filtro:{
                TotalItem:0,
                Pagina:1,
                Entrada:20
            },
            ConfigLoad:{
				ShowLoader:true,
				ClassLoad:false,
			},
            ListHistorialServicio:[],
            Equipo:{},
            RutaIconos:''
        }
    },
    methods: {
        async Lista(){
            this.ConfigLoad.ShowLoader=true;
            this.ConfigLoad.ClassLoad=false;
            await this.$http.get('webapp/historialEquipo',{
                params:{
                    IdEquipo:this.Equipo.IdEquipo,
                    pag:this.Filtro.Pagina,
                    Entrada:this.Filtro.Entrada
                }
            }).then((res)=>{
                this.ListHistorialServicio=res.data.Lista;
                this.RutaIconos=res.data.ruta;
            }).finally(()=>{
                this.ConfigLoad.ShowLoader=false;
                this.ConfigLoad.ClassLoad=true;
            });
        },
        IrAReportes(item){
            this.$router.push({name:'HistorialReporteWebApp',params:{pServicio:item}});
        },
        getHourClean(cadena){
            let cadenasplit=cadena.split(':');
            return cadenasplit[0]+':'+cadenasplit[1];
        },
        Regresar(){
            this.$router.push({name:'EquiposServicioWebApp'});
        }
    },
    created() {
        this.bus.$off('EmitReturn');
        this.bus.$on('EmitReturn',()=>{
            this.Regresar();
        });
    },
    mounted() {
        if (this.pEquipo!=undefined) {
            sessionStorage.setItem('equipo',JSON.stringify(this.pEquipo));
        }
        this.Equipo=JSON.parse(sessionStorage.getItem('equipo'));
        this.HeaderProps.TextoCentroSubHeader=this.Equipo.Nequipo;
        this.Lista();
    }
}
</script>

<style>
.icon-servicio {
    width: 58px;
    height: 58px;
    padding: 0px;
    margin: auto;
 
}
</style>