<template>
  <div class="row justify-content-center">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>