<template>
    <div>
        <div class="form-group row">
            <div class="col-lg-12">
                <label for="">Nombre</label>
                <input v-model="Nombre" type="text" class="form-control">
                <label id="lblmsuser" style="color:red"><Cvalidation v-if="this.errorvalidacion.Nombre" :Mensaje="errorvalidacion.Nombre[0]"></Cvalidation></label>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-lg-4">
                <label for="">Sueldo</label>
                <vue-numeric placeholder="$ 0" class="form-control" currency="$" separator="," :precision="0" v-model="Sueldo" ></vue-numeric>
                <label id="lblmsuser" style="color:red"><Cvalidation v-if="this.errorvalidacion.Sueldo" :Mensaje="errorvalidacion.Sueldo[0]"></Cvalidation></label>
            </div>

            <div class="col-lg-4">
                <label for="">Obligaciones (Ley)</label>
                <vue-numeric placeholder="$ 0" class="form-control" currency="$" separator="," :precision="0" v-model="Obligaciones" ></vue-numeric>
            </div>

            <div class="col-lg-4">
                <label for="">Prestaciones</label>
                <vue-numeric placeholder="$ 0" class="form-control" currency="$" separator="," :precision="0" v-model="Prestaciones" ></vue-numeric>
            </div>
        </div>
    </div>
</template>

<script>
import Cvalidation from "@/components/Cvalidation.vue";
export default {
    name:'FormAgregarEmpleado',
    components:{Cvalidation},
    props:['pEmitSeccion'],
    data(){
        return{
            Nombre:'',
            Sueldo:0,
            Obligaciones:0,
            Prestaciones:0,
            errorvalidacion:{}
        }
    },
    methods: {
        Guardar(){
            this.errorvalidacion={};
            if (this.Nombre=='') {
                this.errorvalidacion.Nombre=["El Nombre del Empleado no Puede Estar Vacío"];
            }
            if (this.Sueldo==0 || this.Sueldo=='') {
                this.errorvalidacion.Sueldo=["El Sueldo del Empleado no Puede Ser Igual a 0"];
            }
            if (Object.keys(this.errorvalidacion).length>0) {
                return false;
            }
            this.bus.$emit('AniadirEmpleado',this.Nombre,this.Sueldo,this.Obligaciones,this.Prestaciones);
        },
        Limpiar(){
            this.Nombre='';
            this.Sueldo=0;
            this.Obligaciones=0;
            this.Prestaciones=0;
            this.errorvalidacion={};
        }
    },
    created() {
        this.bus.$off('Save_'+this.pEmitSeccion);
        this.bus.$on('Save_'+this.pEmitSeccion,()=>{
            this.Guardar();
        });
        this.bus.$off('Limpieza');
        this.bus.$on('Limpieza',()=>{
            this.Limpiar();
        })
    },
}
</script>

<style>

</style>