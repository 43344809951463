<template>
	<div>

		<Modal :Showbutton="false" :NameModal="NameModal"  :TipoM="GetTipoModal"  :size="'modal-xl'" :Nombre="''" >
			<template slot="Form">
				<Clist  v-if="Mostrar" :btnCli="true"  :NameReturn="NameReturn" :pShowBtnAdd="false" :regresar="Regresar" :Nombre="TituloLista" @FiltrarC="ListaCliente" :Filtro="Filtro" :isModal="true">
					<template slot="header">
						<tr>
							<th>Cliente</th>
							<th>Teléfono</th>
							<!--<th>Dirección</th>-->
							<th>Correo</th>
							<th>Ciudad</th>
							<th>Acciones</th>
						</tr>
                	</template>
					<template slot="body">
						<tr  v-for="(item , index ) in ListaClientes" :key="index" >
							<td>{{item.Nombre.substr(0, 20)}}</td>
							<td>{{item.Telefono}}</td>
							<!--<td>{{item.Direccion}}</td>-->
							<td>{{item.Correo}}</td>
							<td>{{$limitCharacters(item.Ciudad,35)}}</td>
							<td>
							<button @click="go_to_sucursal(item,1)" type="button" class="btn btn-table" title="Propiedades"> <i class="fa fa-building" aria-hidden="true"></i></button>
							</td>
						</tr>
					</template>
            	</Clist>


				<Clist v-else :btnCli="true"  :NameReturn="NameReturn" :pShowBtnAdd="false" :regresar="Regresar" :Nombre="TituloLista" @FiltrarC="go_to_sucursal(OClienteSelect,0)" :Filtro="Filtro" :isModal="true">
					<template slot="header">
						<tr>
							<th>Cliente</th>
							<th>Teléfono</th>
							<th>Dirección</th>
							<th>Correo</th>
							<th>Ciudad</th>
							<th>Acciones</th>
						</tr>
					</template>

					<template slot="body">
						<tr v-for="(item , index2 ) in ListaSucursal" :key="index2" >
							<td>{{item.Nombre}}</td>
							<td>{{item.Telefono}}</td>
							<td>{{$limitCharacters(item.Direccion,40)}}</td>
							<td>{{item.Correo}}</td>
							<td>{{$limitCharacters(item.Ciudad,35)}}</td>
							<td>
								<button @click="SeleccionarCliente(item)" type="button" class="btn btn-table" title="Propiedades"><i class="fa fa-check" aria-hidden="true"></i></button>
							</td>
						</tr>
					</template>
				</Clist>
        </template>
    </Modal>

     <Modal :Showbutton="false" :NameModal="'ModalNewUser'" :size="'modal-lg'"   :Nombre="'Nuevo Cliente'" :poBtnSave="oBtnSave">
		 <template slot="Form">
			 <NewCliente v-if="this.IdCliente === 0" :poBtnSave="oBtnSave" />
			 <NewClienteSuc :ocliente="OClienteSelect" v-if="this.IdCliente>0" :poBtnSave="oBtnSave" @titulomodal="Change"/>
        </template>
	 </Modal>
  </div>
</template>

<script>
import Modal from '@/components/Cmodal.vue';
import Clist from '@/components/Clist.vue';
import NewCliente from '@/views/modulos/servicios/NewCliente.vue'
import NewClienteSuc from '@/views/modulos/servicios/NewCliSuc.vue'

export default {
    name:'Form',
    props:['NameList','TipoModal','Origen','IdModal'],
	components:{
		Clist,
		Modal,
		NewCliente,
		NewClienteSuc
	},
    data() {
        return {
            size :"modal-xl",
            ListaClientes:[],
            ListaSucursal:[],
            Mostrar:true,
            Regresar:false,
            TituloLista:'Lista de Clientes',
            OClienteSelect:{},
            NameReturn:'RegresarCliente',
            isEmit:true,
            tipoModal:2,
			NameModal:this.IdModal==undefined ? 'ModalForm3' : this.IdModal,
			Filtro:{
				Nombre:'',
                Placeholder:'Nombre..',
				TotalItem:0,
				Pagina:1,
				Entrada:20
            },
            Nuevo:false,
            IdCliente:0,
			oBtnSave:{
                isModal:true,
                disableBtn:false,
				toast:0,
            },
            NameForm:"Sucursal del Cliente : ",
            oClienteP:{},
			ContactoMarcado:[]
        }
    },

    methods : {
        async ListaCliente() {
			this.IdCliente = 0;
            await this.$http.get('clientes/get', {
                    params:{
						Nombre:this.Filtro.Nombre,
						Entrada:this.Filtro.Entrada,
						pag:this.Filtro.Pagina,
						RegEstatus:'A'
					}
			}).then( (res) => {
                this.TituloLista		= 'Lista de Clientes';
                this.Mostrar			= true;
               	this.Regresar			= false;
                this.ListaClientes 		= res.data.data.clientes;
				this.Filtro.Entrada		= res.data.data.pagination.PageSize;
                this.Filtro.TotalItem	= res.data.data.pagination.TotalItems;

            });
        },

		async go_to_sucursal(ocliente,op) {
            if (parseInt(op) > 0) {
				this.limpiaFiltros();
            }
            this.OClienteSelect=ocliente;
            await this.$http.get('clientesucursal/get', {
                    params:{
						IdCliente:ocliente.IdCliente,
						Nombre:this.Filtro.Nombre,
						Entrada:this.Filtro.Entrada,
						pag:this.Filtro.Pagina,
						RegEstatus:'A'}
                }
            ).then( (res) => {

                this.IdCliente=ocliente.IdCliente;
                this.Nuevo=true;
                this.TituloLista		= 'Lista de Propiedades';
                this.ListaSucursal 		= res.data.data.clientesucursal;
				this.Mostrar			= false;
				this.Regresar			= true;
				this.Filtro.Entrada		= res.data.data.pagination.PageSize;
                this.Filtro.TotalItem	= res.data.data.pagination.TotalItems;
				if (res.data.data.ContactoMarcado.length>0) {
					this.ContactoMarcado=res.data.data.ContactoMarcado;
				}
				// if (res.data.data.ContactoMarcado!=null) {
				// 	this.OClienteSelect.Contacto=res.data.data.ContactoMarcado.Nombre;
				// 	this.OClienteSelect.Telefono=res.data.data.ContactoMarcado.Telefono;
				// }
            });
        },

		SeleccionarCliente(osucursal){
			if (this.Origen!=undefined && this.Origen=='Oportunidad') {
				this.bus.$emit('SeleccionarClienteOportunidad',osucursal,this.OClienteSelect);
			}
			else if(this.Origen!=undefined && this.Origen=='Servicio'){
				if (this.ContactoMarcado.length>0) {
					let Index=this.ContactoMarcado.findIndex((element)=>osucursal.IdClienteS==element.IdClienteS);
					if (Index!=-1) {
						this.OClienteSelect.Contacto=this.ContactoMarcado[Index].Nombre;
						this.OClienteSelect.Telefono=this.ContactoMarcado[Index].Telefono;
					}
				}
				this.bus.$emit('SeleccionarCliente',osucursal,this.OClienteSelect);
			}
			else if(this.Origen!=undefined && this.Origen=='Factura'){
				if (this.ContactoMarcado.length>0) {
					let Index=this.ContactoMarcado.findIndex((element)=>osucursal.IdClienteS==element.IdClienteS);
					if (Index!=-1) {
						this.OClienteSelect.Contacto=this.ContactoMarcado[Index].Nombre;
						this.OClienteSelect.Telefono=this.ContactoMarcado[Index].Telefono;
						this.OClienteSelect.Correo=this.ContactoMarcado[Index].Email;
					}
				}
				this.bus.$emit('SeleccionarCliente',osucursal,this.OClienteSelect);
			}
			else{
				this.bus.$emit('SeleccionarCliente',osucursal,this.OClienteSelect);
			}
            
            $('#'+this.NameModal).modal('hide');
            if (this.TipoModal === 1) {
                $('#'+this.NombreModal).modal('hide');

            }else{
				document.body.classList.add("modal-open");
            }
        },

		Change(titulo) {
			let bdn = true;
			if(titulo === 'Selecciona la imagen'){
                bdn=false;

            }else{
				titulo = titulo+' : '+this.oClienteP.Nombre;
            }

            this.NameForm = titulo;
            this.bus.$emit('cambiar_CloseModal',bdn);
        },

		limpiaFiltros(){
			this.Filtro.Nombre = '';
		}
    },

    created() {
		if (this.Origen!=undefined) {
			switch (this.Origen) {
				case "Oportunidad":
					this.NameModal="OporModalCliente";
					break;
				case "Seguimiento":
					this.NameModal="SegModalCliente";
			}
		}
        this.IdCliente=0;
        this.bus.$off('ListCcliente');
        this.bus.$off('RegresarCliente');
        this.bus.$off('ListCliSuc');
    },

	mounted() {
		this.bus.$on('RegresarCliente',()=> {
			this.limpiaFiltros();
			this.ListaCliente();
		});

		this.bus.$on('ListCcliente',()=> {
			this.limpiaFiltros();
			this.ListaCliente();
		});

		this.bus.$on('ListCliSuc',(objeto)=> {
			this.limpiaFiltros();
			this.go_to_sucursal(objeto,1);
		});
	},
	computed: {
        GetTipoModal(){

            if(this.TipoModal !== undefined){
				this.tipoModal= this.TipoModal;
            }

            return this.tipoModal;
        }
    }
}

</script>

<style>

</style>
