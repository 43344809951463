<template>
    <div>
        <CLoader :pConfigLoad="ConfigLoad">
            <template slot="BodyFormLoad">
                <div class="row">
                    <div class="col-lg-4">
                        <div class="avatar-upload">
                            <div class="avatar-edit">
                                <input
									id="imagen"
									ref="imagen"
									type="file"
									name="imagen"
									@change="CambiarImagen($event)"
									accept=".png, .jpg, .jpeg"
								/>
								<label for="imagen"></label>
                            </div>
                            <div class="avatar-preview">
                                <div
                                    id="imagePreview"
                                    style="background-image: url(Img);"
                                ></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-8">
                        <label >Nombre del Proyecto *</label>
                        <input type="text" v-model="Proyecto.Nombre" class="form-control" placeholder="Nombre" id="Nombre" name="Nombre" />
                        <Cvalidation v-if="this.errorvalidacion.Nombre" :Mensaje="errorvalidacion.Nombre[0]"></Cvalidation>
                        
                        <label>Cliente</label>
                        <treeselect
                            :options="ListaClientes"
                            placeholder="Cliente..."
                            v-model="Proyecto.IdCliente"
                            @input="ImagenPredeterminada();GetListaPropiedades()"
                        />

                        <label>Propiedad</label>
                        <treeselect
                            :options="ListaPropiedades"
                            placeholder="Propiedad..."
                            v-model="Proyecto.IdClienteS"
                            @input="ImagenPredeterminada()"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-3">
                        <label for="">Fecha Inicio *</label>
                        <v-date-picker v-model="Proyecto.FechaInicio" :mode="typeCalendar"
				        	:popover="{
				        		placement: 'bottom',
				        		visibility: 'click'
				        	}"
				        	:input-props="{
				        		class: 'form-control cal-black',
				        		style: 'cursor:pointer;'
				        	}"
				        />
                        <label id="lblmsuser" style="color:red"><Cvalidation v-if="this.errorvalidacion.FechaInicio" :Mensaje="this.errorvalidacion.FechaInicio[0]"></Cvalidation></label>
                    </div>
                    <div class="col-lg-3">
                        <label for="">Fecha Fin *</label>
                        <v-date-picker v-model="Proyecto.FechaFin" :mode="typeCalendar"
				        	:popover="{
				        		placement: 'bottom',
				        		visibility: 'click'
				        	}"
				        	:input-props="{
				        		class: 'form-control cal-black',
				        		style: 'cursor:pointer;'
				        	}"
				        />
                        <label id="lblmsuser" style="color:red"><Cvalidation v-if="this.errorvalidacion.FechaFin" :Mensaje="this.errorvalidacion.FechaFin[0]"></Cvalidation></label>
                    </div>
                    <div class="col-lg-6">
                        <label>Dirección</label>
                        <input type="text" v-model="Proyecto.Direccion" class="form-control" placeholder="Direccion" id="Direccion" name="Direccion" />
                        <Cvalidation v-if="this.errorvalidacion.Direccion" :Mensaje="errorvalidacion.Direccion[0]"></Cvalidation>
                    </div>
                </div>
                <!-- <div class="row mt-3">
                    <div class="col-lg-4 d-flex">
                        <div class="avatar-upload-logocliente">
                            <div class="avatar-preview">
                                <div id="ImagePreviewLogo" :style="'background-image:url('+Proyecto.Imagen+');background-size:contain'" :src="Proyecto.Imagen">
                                    <div class="d-flex h-100 align-items-center justify-content-center" v-if="AltLogoContacto">
                                        <h4>Logo</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-1 pl-0 ml-0">
                        <label title="Seleccionar Imagen" for="imagen" style="cursor:pointer" class="btn-icon mb-2"><i class=" mt-2 fa fa-pencil"></i></label>
                        <input @change="CambiarImagen($event)" class="d-none" type="file" id="imagen" ref="imagen" name="imagen" accept=".png, .jpg, .jpeg">
                        <br>
                        <button @click="EliminarImagen" class="btn-icon-02"><i class="fa fa-trash"></i></button>
                    </div>
                </div> -->
                <div class="row mt-3">
                    <div class="col-lg-12">
                        <label for="">Seleccione la Ubicación del Proyecto</label>
                        <Search v-if="ShowMap" :oLatLng="oLatLng"></Search>
                    </div>
                </div>
            </template>
        </CLoader>
    </div>
</template>

<script>
import Cbtnsave from '@/components/Cbtnsave.vue'
import Cvalidation from '@/components/Cvalidation.vue'
import Config from "@/helpers/VarConfig.js";
import CLoader from "@/components/CLoader.vue";
export default {
    name:'Form',
    props:['Id','poBtnSave','pRutaImagenCliente','pRutaImagenPropiedad'],
    components:{
        Cbtnsave,
        Cvalidation,
        CLoader
    },
    data() {
        return {
            Modal:true,
            FormName:'proyecto',
            EmitSeccion:this.poBtnSave.EmitSeccion,
            ValidElement:Config.validImage2m,
            Proyecto:{
                IdProyecto:0,
                IdEmpresa:"",
                IdSucursal:"",
                IdCliente:null,
                IdClienteS:null,
                Nombre:"",
                Valor:"",
                Direccion:"",
                Latitud:"", 
                Longitud:"",
                FechaInicio:"",
                FechaFin:"",
                Imagen:""
            },
            urlApi:"proyectos/recovery",
            errorvalidacion:[],
            Ruta:"",
            // RutaImagenCliente:"",
            // RutaImagenPropiedad:"",
            oBtnSave:{
                isModal:true,
                disableBtn:false,
                toast:0,
            },
            ConfigLoad:{
                ShowLoader: false,
				ClassLoad:  true,
            },
            ListaClientesNormal:[],
            ListaClientes:[],
            ListaPropiedadesNormal:[],
            ListaPropiedades:[],
            AltLogoContacto:true,
            ShowMap:false,
            IdClienteS:null,
            oLatLng:{
                Lat:null,
                Lng:null
            },
            ImagenRecuperada:false
        }
    },
    methods: {
        async Guardar()
        {
            //deshabilita botones
            this.poBtnSave.toast=0; 
            this.poBtnSave.disableBtn=true;
            // this.Proyecto.Imagen = this.$refs.imagen.files[0];
            let formData = new FormData();
            let image=this.$refs.imagen.files[0];

            let FechaInicio='';
            if (this.Proyecto.FechaInicio!='' && this.Proyecto.FechaInicio!=undefined) {
                let day=this.Proyecto.FechaInicio.getDate();
                let month=this.Proyecto.FechaInicio.getMonth()+1;
                let year=this.Proyecto.FechaInicio.getFullYear();
                FechaInicio=year+'-'+month+'-'+day;   
            }
            let FechaFin='';
            if (this.Proyecto.FechaFin!='' && this.Proyecto.FechaFin!=undefined) {
                let day=this.Proyecto.FechaFin.getDate();
                let month=this.Proyecto.FechaFin.getMonth()+1;
                let year=this.Proyecto.FechaFin.getFullYear();
                FechaFin=year+'-'+month+'-'+day;   
            }

            formData.set('IdProyecto',this.Proyecto.IdProyecto);
            formData.set('Nombre',this.Proyecto.Nombre);
            formData.set('IdCliente', this.Proyecto.IdCliente);
            formData.set('IdClienteS',this.Proyecto.IdClienteS);
            formData.set('Direccion',this.Proyecto.Direccion);
            formData.set('Valor', this.Proyecto.Valor);
            formData.set('Latitud', this.oLatLng.Lat);
            formData.set('Longitud', this.oLatLng.Lng);
            formData.set('FechaInicio', FechaInicio);
            formData.set('FechaFin', FechaFin);
            formData.set('Imagen', this.Proyecto.Imagen);
            formData.append('ImagenPrevio', image);
            
            await this.$http.post('proyectos/post',
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                },
            ).then( (res) => {
                // sessionStorage.setItem("ocontacto",res.data.data.clientes);
                this.poBtnSave.disableBtn=false;  
                this.poBtnSave.toast=1;
                $('#'+this.poBtnSave.nombreModal).modal('hide');
                this.bus.$emit('List'); 
            }).catch( err => {
                this.errorvalidacion=err.response.data.message.errores;
                this.poBtnSave.disableBtn=false;
                this.poBtnSave.toast=2;  
            });
        },
        Limpiar()
        {
            const  input  = this.$refs.imagen;
            input .type = 'text'
            input .type = 'file';
            this.AltLogoContacto=true;
            this.Proyecto={
                IdProyecto:0,
                IdEmpresa:"",
                IdSucursal:"",
                IdCliente:null,
                IdClienteS:null,
                Nombre:"",
                Valor:"",
                Direccion:"",
                Latitud:"", 
                Longitud:"",
                FechaInicio:"",
                FechaFin:"",
                Imagen:""
            },
            this.oLatLng = {
				Lat: 19.43120339928868,
				Lng: -99.13450664719238
			};
            this.IdClienteS=null,
            this.errorvalidacion=[""],
            this.Ruta="",
            // LIMPIAR LA IMAGEN
            $("#imagePreview").css('background-image','url()');
            $("#imagePreview").css('background-size','contain');
            // this.CorreoAntiguo='';
        },
        get_one()
        {
            this.ConfigLoad.ShowLoader=true;
            this.$http.get(
                this.urlApi,
                {
                    params:{IdProyecto: this.Proyecto.IdProyecto}
                }
            ).then( (res) => {
                const objres=res.data.data.proyecto
                this.Proyecto.IdProyecto =objres.IdProyecto;
                this.Proyecto.Nombre =objres.Nombre;
                this.Proyecto.Valor =objres.Valor;
                this.Proyecto.IdCliente =objres.IdCliente==0 ? null : objres.IdCliente;
                this.IdClienteS =objres.IdClienteS==0 ? null : objres.IdClienteS;
                this.Proyecto.IdEmpresa =objres.IdEmpresa;
                this.Proyecto.IdSucursal =objres.IdSucursal;
                this.Proyecto.Direccion =objres.Direccion;
                this.oLatLng.Lat =parseFloat(objres.Latitud);
                this.oLatLng.Lng =parseFloat(objres.Longitud);
                let formatedDateInicio = objres.FechaInicio.replace(/-/g, "\/");
                this.Proyecto.FechaInicio=new Date(formatedDateInicio);
                let formatedDateFin = objres.FechaFin.replace(/-/g, "\/");
                this.Proyecto.FechaFin=new Date(formatedDateFin);
                // PARA RECUPERAR LA IMAGEN
                this.Ruta=res.data.data.ruta;
                this.Proyecto.Imagen=objres.Imagen;
                if (this.Proyecto.Imagen!='') {
                    $("#imagePreview").css('background-image','url('+this.Ruta+this.Proyecto.Imagen+')');
                    $("#imagePreview").css('background-size','contain');
                }
                if (this.Proyecto.Imagen=='' && this.Proyecto.IdCliente!=0) {
                    let IndexCliente=this.ListaClientesNormal.findIndex((element)=>element.IdCliente==this.Proyecto.IdCliente);
                    $("#imagePreview").css('background-image','url('+this.pRutaImagenCliente+this.ListaClientesNormal[IndexCliente].LogoCliente+')');
                    $("#imagePreview").css('background-size','contain');
                }
                this.ShowMap=true;
                this.ConfigLoad.ShowLoader=false;
                this.GetListaPropiedades();
            });
        },
        async GetListaClientes(){
            await this.$http.get("clientes/get"
			).then((res)=>{
                this.ListaClientesNormal=res.data.data.clientes;
				this.ListaClientes=res.data.data.clientes.map(function(obj){
					return {id:obj.IdCliente,label:obj.Nombre}
				});
                // if (this.Proyecto.IdProyecto>0) {
                //     this.GetListaPropiedades()
                // }
			});
        },
        async GetListaPropiedades(){
            this.Proyecto.IdClienteS=null;
            this.ListaPropiedadesNormal=[];
            this.ListaPropiedades=[];
            this.$http.get("clientesucursal/get",{
				params:{
					Todos:true,
					RegEstatus:'A',
					IdCliente:this.Proyecto.IdCliente
				}
			}
			).then((res)=>{
                this.ListaPropiedadesNormal=res.data.data.sucursal;
				this.ListaPropiedades=res.data.data.sucursal.map(function(obj){
					return {id:obj.IdClienteS,label:obj.Nombre}
				});
                if (this.Proyecto.IdProyecto>0) {
                    this.Proyecto.IdClienteS=this.IdClienteS;
                }
                if (this.Proyecto.IdProyecto>0 && this.Proyecto.Imagen=='') {
                    this.ImagenPredeterminada();
                }
			});
        },
        EliminarImagen(){
            if (this.$refs.imagen.files[0]!=undefined || this.Proyecto.Imagen!="") {
                this.$swal({
                title: 'Esta seguro que desea eliminar esta imagen?',
                text: 'No se podra revertir esta acción',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si',
                cancelButtonText: 'No, mantener',
                showCloseButton: true,
                showLoaderOnConfirm: true
                }).then((result) => {
                    if(result.value) {
                        this.$toast.success('Información eliminada');
                        $("#imagePreview").css('background-image','url()');
                        $("#imagePreview").css('background-size','contain');
                        // console.log(this.$refs.logocliente.files);
                        this.$refs.imagen.value=null;
                        this.Proyecto.Imagen="";
                    } 
                });   
            }
            else{
                this.$toast.info('No hay una imagen para eliminar');
            }
        },
        CambiarImagen(event){
            this.$uploadImagePreview(event,this.ValidElement,Array('Img','imagePreview'));
        },
        ImagenPredeterminada(){
            $("#imagePreview").css('background-image','url()');
            $("#imagePreview").css('background-size','contain');
            if (this.Proyecto.Imagen!='') {
                $("#imagePreview").css('background-image','url('+this.Ruta+this.Proyecto.Imagen+')');
                $("#imagePreview").css('background-size','contain');
            }
            if (this.Proyecto.Imagen=='' && this.Proyecto.IdCliente!=null) {
                let IndexCliente=this.ListaClientesNormal.findIndex((element)=>element.IdCliente==this.Proyecto.IdCliente);
                $("#imagePreview").css('background-image','url('+this.pRutaImagenCliente+this.ListaClientesNormal[IndexCliente].LogoCliente+')');
                $("#imagePreview").css('background-size','contain');
            }
            if (this.Proyecto.Imagen=='' && this.Proyecto.IdClienteS!=null) {
                let IndexCliente=this.ListaPropiedadesNormal.findIndex((element)=>element.IdClienteS==this.Proyecto.IdClienteS);
                if (this.ListaPropiedadesNormal[IndexCliente].LogoSucursal!='' && this.ListaPropiedadesNormal[IndexCliente].LogoSucursal!=null) {
                    let Imagen=this.ListaPropiedadesNormal[IndexCliente].LogoSucursal;
                    $("#imagePreview").css('background-image','url('+this.pRutaImagenPropiedad+this.Proyecto.IdClienteS+'/'+Imagen+')');
                    $("#imagePreview").css('background-size','contain');
                }
            }
        }
    },
    created() {
        this.GetListaClientes();
        this.bus.$off('NewModal_'+this.EmitSeccion);

        //Este es para modal
        this.bus.$on('NewModal_'+this.EmitSeccion,(data,Id)=> 
        {
            this.poBtnSave.disableBtn=false; 
            this.bus.$off('Save_'+this.EmitSeccion);
            this.bus.$on('Save_'+this.EmitSeccion,()=>
            {
                this.Guardar();
            });

            this.Limpiar();
            if (Id>0)
            {
                this.Proyecto.IdProyecto=Id;
                this.get_one();
            }
            else{
                this.ShowMap=true;
            }
            this.bus.$emit('Desbloqueo',false);
        });
        if (this.Id!=undefined)
        {
            this.Proyecto.IdProyecto=this.Id;
            this.get_one();
        }
    },
    computed:{
        typeCalendar:function(){
            return 'single';
        }
    }
}
</script>

<style>

</style>