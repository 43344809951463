<template>
	<div class="col-md-4 col-lg-3 mb-2" :id="timeCurrent+'_'+localItem.IdImgEquipo">
		{{functionstyle}}

		<div class="ajuste-Img">
			<div class="float_button">
				<button type="button" @click="GirarImg()" class="btn btn-01" > <i class="fas fa-undo fa-1x"></i> </button>
			</div>

			<div class="float_checkbox">
				<label class="check_box">
					<input type="checkbox" v-model="localItem.Mostrar" >
					<span class="checkmark"></span>
				</label>
			</div>
            <CLoader v-show="!localItem.isLoaded" :pConfigLoad="ConfigLoad">
                <template slot="BodyFormLoad">
                    <div class="h-100"></div>
                    <!-- <img src="" alt="" class="img-thumbnail img-fluid"/> -->
                </template>
            </CLoader>
			<img v-show="localItem.isLoaded" @error="ErrorImg" :src="localItem.FileEquipo" @load="Cargado"  class="img-thumbnail img-fluid" :style="stylerotate" alt="">
		</div>

		<div class="row">
			<div class="col-12">
				<label class="mt-1">Observación original</label>
				<textarea v-model="localItem.Descripcion" disabled class="form-control" rows="2"></textarea>

				<label class="mt-2">Editar observación <small> (max 100 caracteres)</small></label>
				<textarea v-model="localItem.Descripcion2" maxlength="100" placeholder="Observación" class="form-control" rows="3"></textarea>
			</div>
		</div>

	</div>

   <!-- <div>

            <button type="button" @click="GirarImg()" class="btn btn-01" >Girar <span class="badge badge-light"></span></button>
            <br>

            <div class="float_checkbox">
            <label class="check_box">
            <input type="checkbox" v-model="item.Mostrar">
            <span class="checkmark"></span>
            </label>
            </div>

            <div class="ajustar">

            <img :style="stylerotate" :src="item.FileEquipo" alt="..." class="img-thumbnail img-fluid">

            </div>

    </div>-->
</template>



<script>
import CLoaderVue from '@/components/CLoader.vue';
import ImagenDefault from "@/images/sinfoto.jpg";
import moment from "moment";
export default {
    props:['pItem'],
    data() {
        return {
            stylerotate:'transform: rotate(0deg)',
            coordinates: {
				width: 300,
				height: 200,
				left: 0,
                top: 0,
                style:'border-style: dotted; height: 208px; width: 308px;',
                placeholder:'Subir imagen'
			},
			localItem: {
				IdImgEquipo:0,
				Contador: 0,
				Descripcion: '',
				Descripcion2: '',
				Fecha: '',
				FileEquipo: '',
				IdEquipo: 0,
				IdServicio: 0,
				Imagen: '',
				Mostrar: 0,
				Posicion: 0,
				Tipo: '',
				isRotated: 0,
                isLoaded:false,
                error:false
			},
            ConfigLoad:{
				ShowLoader:true,
				ClassLoad:false,
			},
			timeCurrent:  moment(new Date()).format('HH:mm:ss')
        }
    },
	created() {
        if(this.pItem !== undefined) {
			//console.log('chage_info');
			this.localItem = this.pItem;
		}
    },
	mounted() {

    },
    methods: {

        GirarImg(){
            //console.log('execute_rotate');
            this.localItem.Posicion = this.localItem.Posicion + 1;
            this.localItem.isRotated = 1;

            if(this.localItem.Posicion >= 5){
                this.localItem.Posicion = 1;
            }

        },
        Cargado(){
            return this.localItem.isLoaded=true;
        },
        ErrorImg(e){
            e.target.src=ImagenDefault;
            this.localItem.isLoaded=true;
        }
    },
	computed:{

        functionstyle: function () {

            if(this.localItem.Posicion === 1){
                this.stylerotate='transform: rotate(-90deg);';
            }else if(this.localItem.Posicion === 2){
                this.stylerotate='transform: rotate(-180deg);';
            }else if(this.localItem.Posicion === 3){
                this.stylerotate='transform: rotate(-270deg);';
            }else if(this.localItem.Posicion === 4){
                this.stylerotate='transform: rotate(-360deg);';
            }else{
                this.stylerotate='transform: rotate(0deg);';
            }

            return '';

        }

    }


}
</script>
