<template>
	<div class="card mb-3">
		<CLoader :pConfigLoad="ConfigLoad" v-bind:class="ConfigLoad.ShowLoader ? 'plusAlto' : '' ">
			<template slot="BodyFormLoad">
				<div v-if="equipos.length === 0 && ConfigLoad.ShowLoader === false" class="col-md-12 col-lg-12">
					<h5 class="text-center col-form-h1">No hay equipo agregados.</h5>
				</div>

				<div v-for="(item, index) in equipos" :key="index" class="card-body">
					<div class="row">
						<div class="col-md-12 col-lg-12 justify-content-start">
							<div class="checkbox">
								<label>
									<input v-model="item.Permitir" type="checkbox" name="optionsCheckboxes"/>
									<span class="checkbox-material-green">
										<span class="check"></span>
									</span>
									{{ item.Nequipo }}
								</label>
								
								<span v-if="item.Status == 'Operando'" class="ml-3 badge badge-success">Operando</span>
								<span v-if="item.Status == 'En Observacion'" class="ml-3 badge badge-warning">En Observación</span>
								<span v-if="item.Status == 'Fuera de Servicio'" class="ml-3 badge badge-danger">Fuera de Servicio</span>
								<span v-if="item.Status == 'En Servicio'" class="ml-3 badge badge-purple">En Servicio</span> 
							</div>
						
							<hr/>
						</div>
					</div>

					<div class="row mt-1">
						<div class="col-md-6 col-lg-6">
							<label>Observación original</label>
							<textarea class="form-control" v-model="item.Comentario" rows="2" readonly></textarea>
						</div>
						<div class="col-md-6 col-lg-6">
							<label>Editar observación <small> (max 1500 caracteres)</small></label>
							<textarea class="form-control" v-model="item.Comentario2" rows="2" maxlength="1500"></textarea>
						</div>
					</div>

					<div class="row mt-3">
						<div class="col-md-12 col-lg-12">
							<h4>FOTOS DEL EQUIPO {{ item.Equipo }}</h4>
							<hr />
						</div>
					</div>
					<div class="row mt-1">
						<div v-if="item.Imagenes.length === 0" class="col-md-12 col-lg-12">
							<h6 class="text-center col-form-h1">No contiene evidencias.</h6>
						</div>
						<ImgRoate v-for="(item2, index2) in item.Imagenes" :key="index2" :pItem="item2"/>
						<!--<div v-for="(item2, index) in item.Imagenes" :key="index" class="col-md-4 col-lg-4 mb-4">
							<ImgRoate class="ajustar" :item="item" :ruta="Ruta" :img="item.FileEquipo"></ImgRoate>

							<label>Observación original</label>
							<textarea
								v-model="item.Descripcion"
								disabled
								class="form-control"
								rows="2"
							></textarea>

							<label>Editar observación</label>
							<textarea
								v-model="item.Descripcion2"
								maxlength="289"
								placeholder="Observación"
								class="form-control"
								rows="2"
							></textarea>
						</div>-->
					</div>
				</div>
				<div class="col-md-12 col-lg-12 mb-2" v-if="equipos.length > 0">
					<div class="f1-buttons mt-4">
						<button type="button" :disabled="Disablebtn" class="btn btn-01" @click="Guardar">
							<i class="fa fa-spinner fa-pulse fa-1x fa-fw" v-show="Disablebtn"></i>
							<i v-if="!Disablebtn" class="fa fa-plus-circle"></i> {{ txtSave }}
						</button>
					</div>
				</div>
			</template>
		</CLoader>

	</div>
</template>
<script>
import ImgRoate from "@/views/modulos/servicios/componentes/imgrotate.vue";
import CLoader from "../../../components/CLoader";

export default {
	components: {
		ImgRoate,
		CLoader
	},
	data() {
		return {
			position: "right bottom",
			valor: 1,

			equipos: [],
			Ruta: "",
			Disablebtn: false,
			IdServicio: 0,
			txtSave: "Guardar",
			ConfigLoad:{
				ShowLoader:false,
				ClassLoad:true,
			},
		};
	},
	filters: {
		truncate(text, stop, clamp) {
			return text.slice(0, stop) + (stop < text.length ? clamp || "..." : "");
		}
	},
	methods: {


		getImagenes(IdServicio) {
			this.equipos = [];
			this.ConfigLoad.ShowLoader = true;

			this.IdServicio = IdServicio;
			this.$http
				.get("imageneservicio/getequipos", {
					params: { IdServicio: IdServicio }
				})
				.then(res => {


					res.data.data.equipos.forEach(element => {

						let Imgs = [];
						element.Imagenes.forEach(item => {

							Imgs.push({
								IdImgEquipo: item.IdImgEquipo,
								Contador: item.Contador,
								Descripcion: item.Descripcion,
								Descripcion2: item.Descripcion2,
								Equipo: item.Equipo,
								Fecha: item.Fecha,
								FileEquipo: item.FileEquipo,
								IdEquipo: item.IdEquipo,
								IdServicio: item.IdServicio,
								Imagen: item.Imagen,
								Mostrar: item.Mostrar,
								Posicion: 0,
								Status: item.Status,
								Tipo: item.Tipo,
								isRotated: 0,
								isLoaded:false,
								error:false
							});
						});


						this.equipos.push({
							Comentario: element.Comentario,
							Comentario2: element.Comentario2,
							IdEquipo: element.IdEquipo,
							Nequipo: element.Nequipo,
							Permitir: element.Permitir,
							Status: element.Status,
							Imagenes:Imgs,
						})


					});


					//console.log(this.equipos);

					this.Ruta = res.data.data.ruta;
					this.ruta1 = res.data.data.ruta1;
					this.ruta2 = res.data.data.ruta2;
				}).finally(() => {
				this.ConfigLoad.ShowLoader = false;
			});
		},

		Guardar() {
			if (this.equipos.length > 0) {
				this.Disablebtn = true;
				this.txtSave = " Espere...";
				this.$http
					.post("imageneservicio/post", {
						equipos: this.equipos,
						IdServicio: this.IdServicio
					})
					.then(res => {
						this.Disablebtn = false;
						this.txtSave = " Guardar";

						$("#ModalReporte2").modal("hide");
						document.body.classList.add("modal-open");
						this.$toast.info("Información guardada");
					})
					.catch(err => {
						this.Disablebtn = false;
						this.txtSave = " Guardar";
						this.$toast.warning("Complete la Información");
					});
			} else {
				this.$toast.info("No hay Información por Guardar");
			}
		}
	},
	created() {
		this.bus.$off("OpenRepor2");
		this.bus.$on("OpenRepor2", IdServicio => {
			this.getImagenes(IdServicio);
		});
	}
};
</script>
<style>
.plusAlto {
	margin-top: 25px;
	height: 90px;
}

#circle {
    background: lightblue;
    border-radius: 50%;
    width: 100px;
    height: 100px;
}
</style>
