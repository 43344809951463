<template>
	<div class="mt-2">
		<div class="row">
			<div class="col-12 col-sm-12 col-md-12 col-lg-12">
				<nav class="navbar navbar-breadcrumb navbar-expand-md bg-breadcrumb breadcrumb-borde">
					<div class="mr-auto">
						<nav aria-label="breadcrumb">
							<ol class="breadcrumb clearfix pt-3">
								<li class="breadcrumb-item">
									<a href="">Despacho / {{ FechaSelected }}</a>
								</li>
							</ol>
						</nav>
					</div>

					<form class="form-inline d-none d-md-none d-lg-block">
						<div class="form-group mt-n1">
							<router-link class="btn btn-01 mr-1" to="/listservicio">Consultas</router-link>

							<button @click="Open_Modal(2)" type="button" data-toggle="modal" data-target="#ModalForm" data-backdrop="static" class="btn btn-pink mr-3">
								Nuevo
							</button>
							<label class="mr-1">Técnico</label>
							<select @change="Buscar" v-model="Busqueda" class="form-control form-control-sm mr-2">
								<option :value="''">seleccione un Trabajador</option>
								<option v-for="(item, index) in ListaTrabajadores" :key="index" :value="item.IdTrabajador">{{ item.Nombre }}</option>
							</select>
							&ensp;<!--doble-->
							&emsp;<!--Cuadruple-->

							<button type="button" data-toggle="modal" data-target="#ModalForm4" :disabled="this.Finalizados.length == 0" data-backdrop="static" class="btn btn-02 color-01 mr-2">
								Terminados
								<span class="color-02">{{ this.Finalizados.length }}</span>
							</button>
							&emsp;<!--Cuadruple-->

							<button @click="irHoy" type="button" class="btn btn-02 color-01 mr-2">
								<i class="fas fa-calendar-day"></i> Hoy
							</button>

							<button @click="IrCalendario()" type="button" class="btn btn-02 color-01 mr-2" id="button">
								<i class="fas fa-calendar"></i> Calendario
							</button>
							<!-- <button @click="Open_Modal(1)" data-toggle="modal" data-target="#ModalForm2"  data-backdrop="static" data-keyboard="false" type="button" class="btn btn-02 color-01 mr-2"><i class="fas fa-map-marker-alt"></i>
                        Localización
                        </button> -->

							<!-- <button @click="OpenOterView()" type="button" class="btn btn-02 color-01 mr-2" id="button"><i class="fas fa-calendar"></i> Otra Vista</button> -->

							<!-- <div class="dropdown mr-2">
                            <button class="dropdown-toggle btn btn-02 color-01 mr-2 " type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Localización <i class="fas fa-map-marker-alt"></i>
                            </button>
                            <div class="dropdown-menu dropdown-menu-button dropdown-menu-right" aria-labelledby="dropdownMenuButton">

                                <a class="dropdown-item" @click="Open_Modal(1)" data-toggle="modal" data-target="#ModalForm2"  data-backdrop="static" data-keyboard="false" type="button">Vista Vista</a>
                                <a class="dropdown-item" @click="OpenOterView()">Nueva Actual</a>

                            </div>
                        </div> -->

							<div class="dropdown mr-2">
								<button class="btn btn-02 color-01 btn-07 dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									Localización <span class="ml-1"></span>
								</button>
								<div class="dropdown-menu dropdown-menu-button dropdown-menu-right" aria-labelledby="dropdownMenuButton">
									<a class="dropdown-item" @click="Open_Modal(1)" data-toggle="modal" data-target="#ModalForm2" data-backdrop="static" data-keyboard="false" type="button">
										Localización &nbsp;<i class="fas fa-map-marker-alt"></i>
									</a>
									<a class="dropdown-item" @click="OpenOterView()">
										Localización Amplia <i class="fas fa-map-marker-alt"></i>
									</a>
								</div>
							</div>
						</div>
					</form>

					<!-- <form class="form-inline d-none d-md-block d-lg-none">
                    <label>Técnico 2</label>
                    <select @change="Buscar" v-model="Busqueda" class="form-control form-control-sm">
                        <option :value="''">seleccione un Trabajador</option>
                        <option v-for="(item, index) in ListaTrabajadores" :key="index" :value="item.IdTrabajador">{{item.Nombre}}</option>
                    </select>
                    <div class="dropdown">
                        <button class="btn-06 dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="fas fa-ellipsis-v"></i>
                        </button>
                        <div class="dropdown-menu dropdown-menu-button dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                            <a class="dropdown-item" href="#">Terminados <span class="color-02">15</span></a>
                            <a @click="irHoy" class="dropdown-item" ><i class="fas fa-calendar-day"></i> Hoy</a>
                            <a @click="IrCalendario()" class="dropdown-item" href="javascript:open_modal();"><i class="fas fa-calendar"></i> Calendario</a>
                            <a @click="Open_Modal(1)" data-toggle="modal" data-target="#ModalForm2"  data-backdrop="static" data-keyboard="false" class="dropdown-item" href="#"><i class="fas fa-map-marker-alt"></i> Localización</a>
                            <a  @click="Open_Modal(2)" type="button"  data-toggle="modal" data-target="#ModalForm"  data-backdrop="static" class="dropdown-item" href="#Form_02" >Nuevo</a>
                        </div>
                    </div>
                </form> -->
				</nav>
			</div>
		</div>

		<!--INICIO CUERPO TABLA--->
		<div class="row mt-2">
			<div class="col-12 col-sm-12 col-md-12 col-lg-12">
				<CLoader :pConfigLoad="ConfigLoad">
					<template slot="BodyFormLoad">
						<div class="card">
							<div class="card-body">
								<div class="tab-gantt">
									<table cellpadding="0" cellspacing="0">
										<thead>
											<tr>
												<th>Personal</th>
												<th v-for="(item, index) in ListaHoras" :key="index" :class="item.class">
													{{ item.hora }}
													<section v-if="item.class !== ''">
														<span style="display:none">{{ (Focus = true) }}</span>
														<i class="fas fa-arrow-alt-down icon-arrow"></i>
														<input style="max-width:0;max-height:0;line-height:0;border:0;position:absolute;" id="timefocus" type="text" ref="timefocus"/>
													</section>
												</th>
											</tr>
										</thead>
										<tbody> 
											<tr v-for="(item2, index2) in ListaServicios" :key="index2">
												<td :class="Busqueda === item2.IdTra ? 'bg-info' : ''">
													<b-avatar
														class="mr-1"
														size="1.5rem"
														:variant="AvatarData(item2.Trabajador).Color"
														:text="AvatarData(item2.Trabajador).Nombre"
													/>
													<input style="width:0;height:0;border:0;position:absolute;background-color:transparent" :id="'txt' + item2.IdTra" :ref="'txt' + item2.IdTra" :value="item2.IdTra"/>
													{{ $limitCharacters(item2.Trabajador,22) }}
													<i :style="item2.message > 0 ? 'color:#f8831b; cursor:pointer;' : 'color:#0f3f87; cursor:pointer;'" v-bind:class="item2.message > 0 ? 'tada' : ''" class="far fa-comment-dots icon-gant"
													   @click="GetChat(item2.IdUser, item2.Trabajador, item2.Foto)" v-b-toggle.sidebar-right>
													</i>
												</td>

												<td v-for="(item3, index) in item2.Servicios" :key="index">
													<span v-for="(serv, index3) in item3.items" :key="index3">
														<div
															v-b-tooltip.hover.rightbottom.html="getTooTip(serv)"
															ref=""
															@click="Open_Modal(2, serv.IdServicio)"
															data-toggle="modal"
															data-target="#ModalForm"
															data-backdrop="static"
															v-if="serv.pintar"
															:class="serv.class"
															:style="serv.color"
														></div>
													</span>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</template>
				</CLoader>
			</div>
		</div>
		<!--Fin Cuerpo de la tabla--->
		<!-- <pre>
        {{ListaServicios}}
    </pre> -->

		<b-sidebar 
			id="sidebar-right" 
			:backdrop-variant="'dark'"
			body-class="modal-dialog-scrollable"  
			width="40em" 
			backdrop 
			right 
			no-header 
			shadow
			:visible="showChatSideBar">
			<template v-slot:="{ hide }">
				
				<div class="modal-content">
					<div class="modal-header bg-modal">
						<h5 class="modal-title">
							<b-avatar :src="rutatrab + ImagenTab" size="3rem"></b-avatar>
							{{ NombreTraba }}
						</h5>
						<button id="BtcCerrarsidebar" @click="hide" type="button" class="close close-2">
							<i class="fad fa-times-circle"></i>
						</button>
					</div>
					
					<div id="cuerpo" class="modal-body">
						<CLoader :pConfigLoad="ConfigLoadChat">
							<template slot="BodyFormLoad">

								<p v-for="(item, index) in ListaChat" :key="index" :class="Chat.IdContacto != item.IdUsuario ? 'globo-res' : 'globo'">
									{{ item.Mensaje }}<br />
									<b class="color-03">{{ item.Fecha }}</b>
								</p>

							</template>
						</CLoader>
					</div>
					

					<div class="card-footer">
						<div class="row">
							<div class="col-12">
								<div class="form-inline">
									<input v-model="Chat.Mensaje" ref="msj" v-on:keyup.enter="SaveChat" type="text" class="form-control mr-2 col-9"/>
									<button :disabled="LoadingChat" @click="SaveChat" type="button" class="btn btn-01 send">
										{{ txtSave }}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				
			</template>

		</b-sidebar>

		<Modal :NameModal="'ModalForm4'" :Showbutton="false" :size="'modal-bg'" :Nombre="'Servicios Finalizados'">
			<template slot="Form">
				<finalizados :Finalizados="Finalizados"></finalizados>
			</template>
		</Modal>

		<Modal :NameModal="'ModalForm2'" :Showbutton="false" :size="'modal-lg'" :Nombre="'Ubicación'">
			<template slot="Form">
				<Cmapa :Arreglo="markers" :rutatrab="rutatrab" :rutaclientes="rutaclientes" :rutaempresa="rutaempresa"></Cmapa>
			</template>
		</Modal>

		<Modal :NameModal="'ModalForm'" :Showbutton="false" :size="size" :Nombre="NameList">
			<template slot="Form">
				<ServicioForm :pOrigen="'DESPACHO'"></ServicioForm>
			</template>
		</Modal>
	</div>
</template>
<script>
import $ from "jquery";
import Modal from "@/components/Cmodal.vue";
import Cbtnaccion from "@/components/Cbtnaccion.vue";
import Cmapa from "@/components/Cmapa.vue";
import ServicioForm from "../servicios/Form";
import finalizados from "@/views/modulos/despacho/finalizados.vue";
import moment from "moment";
import CLoader from "../../../components/CLoader";

export default {
	name: "list",
	props: ["ocliente", "objeto"],
	components: {
		Modal,
		Cbtnaccion,
		Cmapa,
		ServicioForm,
		finalizados,
		CLoader
	},
	data() {
		return {
			FormName: "despachoform", //Por si no es modal y queremos ir a una vista declarada en el router
			EsModal: false, //indica si es modal o no
			size: "modal-xl",
			NameList: "Despacho",
			NameForm: "Servicios",
			urlApi: "servicio/despacho",
			ListaHoras: [],
			ListaServicios: [],

			HoraActual: "",
			Focus: false,
			Tipo: 1,
			markers: [],
			FechaBusqueda: "",
			Finalizados: [],
			Chat: {
				IdContacto: 0,
				Mensaje: "",
				Tipo: 1
			},
			showChatSideBar: false,
			ListaChat: [],
			LoadingChat: false,
			txtSave: "Enviar",
			errorvalidacion: [],
			Busqueda: "",
			ListaTrabajadores: [],
			rutatrab: "",
			rutaclientes:"",
			rutaempresa:"",
			NombreTraba: "",
			ImagenTab: "",
			FechaSelected: "Hoy",

			//IntervalTime: [],
			timer: '',
			ConfigLoad:{
				ShowLoader:false,
				ClassLoad:true,
			},

			ConfigLoadChat:{
				ShowLoader:false,
				ClassLoad:false,
				addStyle: 'margin-top: 50px;'
			},
		};
	},
	methods: {
		irHoy() {
			if (this.FechaBusqueda != "") {
				this.FechaBusqueda = "";
				this.FechaSelected = "Hoy";
				this.get_horaslaborales();
			}
		},

		async get_horaslaborales() {
			console.log(moment(new Date()).format('MMMM Do YYYY, h:mm:ss a'));
			if (this.FechaBusqueda === "") {
				/* var dateObj = new Date();
                var month = dateObj.getUTCMonth() + 1; //months from 1-12
                var day = dateObj.getUTCDate();
                var year = dateObj.getUTCFullYear();
                console.log(day)
                if(month < 10){
                    month = '0'+month;
                }

                if(day < 10){
                    day = '0'+day;
                } */
				var zonaH = sessionStorage.getItem("ZonaHoraria");
				moment.tz.setDefault(zonaH);
				var fechaa = moment(new Date()).format("YYYY-MM-DD");
				this.FechaBusqueda = fechaa; //year+"-"+month+"-"+day;

			} else {
				var desglose = this.FechaBusqueda.split("-");
				this.FechaSelected =
					desglose[2] + "-" + desglose[1] + "-" + desglose[0];
			}

			this.ConfigLoad.ShowLoader = true;
			this.rutatrab 		= '';
			this.Focus 			= false;
			this.ListaHoras 	= [];
			this.ListaServicios = [];
			this.HoraActual 	= '';

			await this.$http.get(this.urlApi, {
					params: {
						RegEstatus: "A",
						FechaBusqueda: this.FechaBusqueda
					}
			}).then(res => {
					

					this.rutatrab 		= res.data.data.ruta;
					this.Focus 			= false;
					this.ListaHoras 	= res.data.data.horaslaborales;
					this.ListaServicios = res.data.data.servicios;
					this.HoraActual 	= res.data.data.horaactual;
					this.ServiciosFinalizados();

			}).finally(()=>{
				let ref=document.getElementById("timefocus");
				this.ConfigLoad.ShowLoader = false;
				ref.scrollIntoView({behavior:"smooth",block:"nearest",inline:"center"});
			});
		},


		Open_Modal(tipo, Id = 0) {
			this.Tipo = tipo;

			if (this.Tipo == 1) {
				this.bus.$emit("OpenModal");
			} else {
				this.bus.$emit("Nuevo", null, Id);
			}
		},

		OpenOterView() {
			//window.open("http://localhost:8080/Geolocalizacion");
			//window.open("http://localhost:8080/Geolocalizacion" , '_blank');

			//window.open("https://desprosoft.lugarcreativo.com.mx/Geolocalizacion");
			window.open("https://desprosoft.online/Geolocalizacion", "_blank");
		},

		async getUbicaciones() {
			await this.$http
				.get(
					// 'ubicacionmapa/get',
					"ubicacionmapa2/get",
					{
						params: {}
					}
				)
				.then(res => {
					this.rutatrab = res.data.data.ruta;
					this.rutaclientes=res.data.data.rutaclientes;
					this.rutaempresa=res.data.data.rutaempresa;
					this.markers = [];

					res.data.data.ubicaciones.forEach(element => {
						this.markers.push({
							position: {
								lat: parseFloat(element.lat),
								lng: parseFloat(element.lng)
							},

							//Nuevo
							datos: {
								Tipo: element.Tipo,
								Tecnico: element.Nombre,
								Cliente: element.Cliente,
								Servicio: element.Folio,
								TipoServicio: element.Concepto,
								FechaI: element.Fecha_I,
								FechaF: element.Fecha_F,
								HoraI: element.HoraInicio,
								HoraF: element.HoraFin,
								Estatus: element.Estatus,
								Foto2: element.Foto2,
								IsAdmin:element.IsAdmin
							}
						});
					});
					sessionStorage.setItem("rutaMapa", this.rutatrab);
				});
		},

		IrCalendario() {
			this.$router.push({ name: "calendar", params: {} });
		},

		ServiciosFinalizados() {
			this.$http
				.get("servicio/finalizados", {
					params: { FechaBusqueda: this.FechaBusqueda }
				})
				.then(res => {
					this.Finalizados = res.data.data.finalizados;
				});
		},

		GetChat(IdContacto, Nombre, Foto) {
			this.Chat.IdContacto 	= 0;
			this.Chat.Mensaje 		= "";
			this.Chat.IdContacto 	= IdContacto;
			this.NombreTraba 		= Nombre;
			this.ImagenTab 			= Foto;
			this.ListaChat 			= [];
			this.ConfigLoadChat.ShowLoader = true;

			this.$http.get("despacho/getchat", {
					params: { 
						IdContacto: IdContacto, 
						Tipo: 1 
					}

			}).then(res => {
				this.ListaChat = res.data.Lista;
				this.$refs.msj.focus();
				setTimeout(this.scrollToEnd, 100);
				this.bus.$emit("countNotify");

			}).finally(() => {
				this.ConfigLoadChat.ShowLoader = false;
			})
		},

		SaveChat() {
			if (this.Chat.Mensaje.trim() == "") {
				this.$refs.msj.focus();
				return false;
			}

			this.errorvalidacion = [];
			this.LoadingChat = true;
			this.txtSave = " Esperar";

			this.$http
				.post("despacho/postchat", this.Chat)
				.then(res => {
					this.GetChat(this.Chat.IdContacto, this.NombreTraba, this.ImagenTab);
					this.LoadingChat = false;
					this.$refs.msj.focus();
					this.txtSave = " Enviar";
				})
				.catch(err => {
					this.$refs.msj.focus();
					this.LoadingChat = false;
					this.txtSave = " Enviar";
					this.errorvalidacion = err.response.data.message.errores;
				});
		},

		scrollToEnd: function() {
			let container = this.$el.querySelector("#sidebar-right");
			container.scrollTop = container.scrollHeight;
		},

		AvatarData(name) {
			var name = name;
			var nameSplit = name.split(" ");
			var initials;

			if (nameSplit.length > 1) {
				initials =
					nameSplit[0].charAt(0).toUpperCase() +
					nameSplit[1].charAt(0).toUpperCase();
			} else {
				initials = nameSplit[0].charAt(0).toUpperCase();
			}

			var colours = [
				"secondary",
				"secondary",
				"dark",
				"success",
				"danger",
				"warning",
				"info"
			];
			const randomMonth = colours[Math.floor(Math.random() * colours.length)];
			var Arreglo = { Nombre: initials, Color: randomMonth };

			return Arreglo;
		},

		Buscar() {
			if(this.Busqueda !== '' && this.Busqueda !== undefined){
				let id = "txt" + this.Busqueda;
				document.getElementById(id).focus();
			}


		},

		get_listtrabajador() {
			this.$http
				.get("trabajador/get", {
					params: { Rol: "USUARIO APP", IdPerfil: 4 }
				})
				.then(res => {
					this.ListaTrabajadores = res.data.data.trabajador;

					let cantidadUsuarios = res.data.data.usuarios.Quantity;
					let cantidadPaquete = res.data.data.sucursal.PaqueteU;

					//VALIDACIÓN LÍMITE DE USUARIOS
					if (
						//Si la cantidad de usuarios es mayor al paquete
						Number(cantidadUsuarios) > Number(cantidadPaquete)
					) {
						this.$swal({
							title: "Excedió su paquete de usuarios",
							text:
								"Su paquete incluye hasta " +
								cantidadPaquete +
								" usuarios",
							type: "warning",
							confirmButtonText: "Ajustar",
							showCloseButton: false,
							showLoaderOnConfirm: true,
							allowOutsideClick: false
						}).then(result => {
							this.$router.push("/personal");
						});
					}
				});
		},

		getTooTip(item) {
			var html = "";

			if (item.Folio != "0") {
				html =
					"Folio : " +
					item.Folio +
					"<br>" +
					" Cliente : " +
					item.Cliente +
					"<br>" +
					" Sucursal : " +
					item.Sucursal;
			}
			return html;
		},

		openSideChat(tp){
			if(tp > 0){
				this.showChatSideBar = true;
			}else {
				this.showChatSideBar = false;
			}
		}
	},
	created() {
		this.bus.$off("ListDespacho");
		this.bus.$off("OpenModal");
		this.bus.$off("ListarUbicacion");
		this.bus.$off("OpenChatD");


		if (this.objeto != undefined) {
			this.FechaBusqueda = this.objeto.Fecha;
		}

		this.get_horaslaborales();
		//console.log(this.$store.state.timeDespacho);
		clearInterval(this.$store.state.timeDespacho);
		this.$store.state.timeDespacho = setInterval(() => {this.get_horaslaborales();}, 180000);
		//console.log(this.$store.state.timeDespacho);


		this.bus.$on("ListarUbicacion", () => {
			this.getUbicaciones();
		});

		this.get_listtrabajador();

		this.bus.$on("OpenChatD", ocliente => {
			this.GetChat(ocliente.IdContacto, ocliente.Contacto, ocliente.Foto2);
			this.openSideChat(1);
		});
	},
	computed: {

	},

	mounted() {
		this.bus.$on("ListDespacho", FechaBusqueda => {
			this.get_horaslaborales();
		});

		if (this.ocliente != undefined) {
			this.GetChat(
				this.ocliente.IdContacto,
				this.ocliente.Contacto,
				this.ocliente.Foto2
			);
			this.openSideChat(1);
		}
	},
	beforeDestroy() {
		//console.log(this.$store.state.timeDespacho);
		clearInterval(this.$store.state.timeDespacho);
		
		//console.log(this.$store.state.timeDespacho);
	},
};
</script>
<style>
     .tada {
		
		-moz-animation: tada 1s infinite;
		-webkit-animation: tada 1s infinite;
     	-o-animation: tada 1s infinite;
        animation: tada 1s infinite;
		-webkit-animation-fill-mode: both;
		animation-fill-mode: both;
	
	}
	@-webkit-keyframes tada {
	0% {
	-webkit-transform: scale3d(1, 1, 1);
	transform: scale3d(1, 1, 1);
	}
	10%, 20% {
	-webkit-transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
	transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
	}
	30%, 50%, 70%, 90% {
	-webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
	transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
	}
	40%, 60%, 80% {
	-webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
	transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
	}
	100% {
	-webkit-transform: scale3d(1, 1, 1);
	transform: scale3d(1, 1, 1);
	}
	}
	@keyframes tada {
	0% {
	-webkit-transform: scale3d(1, 1, 1);
	transform: scale3d(1, 1, 1);
	}
	10%, 20% {
	-webkit-transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
	transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
	}
	30%, 50%, 70%, 90% {
	-webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
	transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
	}
	40%, 60%, 80% {
	-webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
	transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
	}
	100% {
	-webkit-transform: scale3d(1, 1, 1);
	transform: scale3d(1, 1, 1);
	}
  } 

</style>
