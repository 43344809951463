<template>
    <CHeader :pHeaderProps="HeaderProps">
        <template slot="Contenido">
            <div v-show="ShowLista" class="row">
                <div class="col-6 col-sm-6 mt-3 card-group" v-for="(item,index) in Imagenes" :key="index">
                    <div class="card card-01" :style="Servicio.Offline==0 ? 'min-height:25vh' : ''">
                        <div class="card-body d-flex flex-column">
                            <!-- <div class="float_checkbox_send_image">
                                <label class="check_box">
                                    <input type="checkbox" v-model="item.Check">
                                    <span class="checkmark checkmark-app"></span>
                                </label>
                            </div> -->
                            <div class="cropp">
                                <img class="img img-fluid" style="height:12vh;" :src=item.Imagen alt="">
                            </div>
                            <!-- <div style="overflow-y:scroll;height:5rem;" class="mt-2 mb-2">
                                <p>{{ item.Descripcion }}</p>
                            </div> -->
                            <i @click="IrAComentarioFoto(item.Imagen,item.Descripcion,index)" class="mt-3 text-center far fa-comment-alt-dots fa-3x icon-color-app"></i>
                            <!-- <button @click="EliminarFoto(item.IdImagen)" class="mt-auto btn btn-01"><i class="fa fa-trash"></i> Eliminar</button> -->
                        </div>
                    </div>
                </div>
            </div>
            <div v-show="!ShowLista && ShowCamara">
                <div class="photo-servicio-container">
                    <div style="width:100%;position:fixed;height:100vh">
                        <div class="pb-0 mb-0 camera-container" style="height:80vh;">
                            <video class="video-camera" id="videoCam" ref="videoCam"></video>
                        </div>
                        <div class="row mt-0 pt-0">
                            <div style="height:20vh;background:#000" class="col-12 d-flex justify-content-center">
                                <button v-if="ShowTomarFoto" @click="TomarFoto" id="tomar-foto" ref="tomar-foto" class="mt-3"></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-show="ShowConfirmacionFoto" class="position-absolute confirmacion-photo">
                    <canvas @canplay="AjustarCanvas" id="canvas" ref="canvas" style="display:none;"></canvas>
                    <div class="btn-confirmar">
                        <button class="btn btn-01" @click="ConfirmarFoto"><i class="fa fa-check fa-3x"></i></button>
                    </div>
                    <div class="btn-rechazar">
                        <button class="btn btn-01" @click="VolverATomarFoto"><i class="fa fa-times-circle fa-3x"></i></button>
                    </div>
                </div>
                <!-- <img src="" alt="" id="photo"> -->
            </div>
            <div v-show="!ShowCamara" class="row mt-3">
                <div class="col-12 col-sm-12">
                    <div class="d-flex flex-column">
                        <div class="cropp">
                            <img src="" alt="" id="photo" ref="photo">
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-12 mt-4">
                    <label for="">Observaciones</label><small> (max 100 caracteres)</small>
                    <textarea class="form-control" v-model="Observaciones" id="" cols="30" rows="10" placeholder="Escriba aquí sus observaciones..." maxlength="100"></textarea>
                </div>
            </div>
            <div v-show="!ShowCamara" class="row justify-content-center">
                <div class="col-12 col-sm-12 fixed-bottom mb-3">
                    <div class="row">
                        <div class="col-6 col-sm-6">
                            <button @click="CancelarFoto" class="btn btn-01 btn-block btn-grande" type="button">Eliminar</button>
                        </div>
                        <div class="col-6 col-sm-6">
                            <button @click="EnviarFoto" class="btn btn-01 btn-block btn-grande" type="button">Guardar</button>
                        </div>
                    </div>
                </div>
            </div>
            <div v-show="ShowCamara" class="row fixed-bottom justify-content-center mt-5">
                <div v-show="ShowBotonesServidor" class="col-12 col-sm-12 mb-3">
                    <div class="row justify-content-center mb-2">
                        <div class="col-4 col-sm-4">
                            <button id="BotonCaptura" v-if="Servicio.Offline=='0'" :disabled="DisabledBtn" type="button" class="btn btn-01 btn-block" @click="AbrirCamara">
                                <i class="far fa-camera fa-3x"></i>
                            </button>
                            <input id="InputCamara" style="display:none;" type='file' accept='image/*' ref="foto" capture='camera' @change="TomarFoto"/>
                            <!-- <input id="InputCamara" style="display:none;" type='file' accept='image/*' ref="foto" capture='camera'/> -->
                            <input ref="archivos" v-if="Servicio.Offline=='1'" accept='image/jpg,image/png,image/jpeg,image/heic' multiple type="file" id="serviciogaleria" class="d-none" @change="ImagenesGaleria"/>
                            <label for="serviciogaleria" v-if="Servicio.Offline=='1'" :disabled="DisabledBtn" type="button" class="btn btn-01 btn-block">
                                <i class="far fa-camera fa-3x"></i>
                            </label>
                        </div>
                        <div class="col-4 col-sm-4">
                            <button type="button" class="btn btn-01 btn-block" @click="IrAListaImagenes">
                                <i class="far fa-image-polaroid fa-3x"></i>
                            </button>
                            <!-- <input type="file" accept="image/*, video/*" capture="environment"> -->

                        </div>
                    </div>
                    <div class="row justify-content-center mt-4">
                        <div class="col-10 col-sm-10">
                            <button :disabled="DisabledBtn || this.Imagenes.length==0" @click="GuardarFotos" type="button" class="btn btn-01 btn-block">
                                <i v-show="DisabledBtn" class="fa fa-spinner fa-pulse fa-2x fa-fw"></i>
                                <i v-show="!DisabledBtn" class="far fa-upload fa-3x"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </CHeader>
</template>

<script>
import CHeader from "@/views/webapp/components/HeaderWebApp.vue";
import { Cropper,StencilPreview,BoundingBox,DraggableArea } from "vue-advanced-cropper/dist/index.cjs";
// import 'vue-advanced-cropper/dist/style.css';
export default {
    props:['pServicio','pEquipo','pTipoEvidencia'],
    components:{CHeader,Cropper,StencilPreview,BoundingBox,DraggableArea},
    data() {
        return {
            HeaderProps:{
                ShowSubHeader:false,
                ShowReturn:true,
                TextoHeader:'Fotos Generales',
                ShowLogo:false,
            },
            Servicio:{},
            Equipo:{},
            Imagenes:[],
            ShowLista:true,
            ShowCamara:true,
            ShowTomarFoto:false,
            ShowConfirmacionFoto:false,
            ShowBotonesServidor:true,
            Observaciones:'',
            IdImagen:0,
            localstream:null,
            DisabledBtn:false,
            coordinates: {
				width: 0,
				height: 0,
				left: 0,
				top: 0,
			},
			Image: null,
            TipoEvidencia:'',
            IndexImagen:0,
            Edicion:false,
            BtnGuardado:true,
            OrigenImg:''
        }
    },
    methods: {
        ImagenesGaleria(){
            // const images=this.$refs.archivos.files;
            // var allowedExtensions = /(\.jpg|\.JPG|\.png|\.PNG)$/i;
            // for (let index = 0; index < images.length; index++) {
            //     if (this.Imagenes.length==4) {
            //         this.$toast.info('Sólo se Pueden Subir 4 Imágenes a la Vez');
            //         break;
            //     }
            //     const element = images[index];
            //     var FileSize = element.size / 1024 / 1024;
            //     if (FileSize>5) {
            //         this.$toast.info('Solo se puede subir archivos menores a 5 MB');
            //         const  input  = this.$refs.archivos;
            //         input .type = 'text'
            //         input .type = 'file';
            //         return false;
            //     }
            //     else if(!allowedExtensions.exec(element.name)){
            //         this.$toast.info('Extenciones permitidas .jpg,.png');
            //         const  input  = this.$refs.archivos;
            //         input.type = 'text'
            //         input.type = 'file';
            //         return false;
            //     }
            //     const readerizado = new FileReader();

            //     readerizado.readAsDataURL(element);
            //     readerizado.onload = r => {
            //         if (this.Imagenes.length==4) {
            //             this.$toast.info('Sólo se Pueden Subir 4 Imágenes a la Vez');
            //             return false;
            //         }
            //         this.Imagenes.push({IdImagen:this.IdImagen,Imagen:r.target.result,Descripcion:''});
            //         this.IdImagen++;
            //     }
            // }
            let MaxWidth=1920;
            let MaxHeight=1080;
            const images=this.$refs.archivos.files;
            for (let index = 0; index < images.length; index++){
                let Origen='';
                let Extension='';
                if(this.Imagenes.length==4){
                    this.$toast.info('Sólo se Pueden Subir 4 Imágenes a la Vez');
                    break;
                }
                const element=images[index];
                var FileSize = element.size / 1024 / 1024;
                if (FileSize>5){
                    this.$toast.info('Solo se puede subir archivos menores a 5 MB');
                    const  input  = this.$refs.archivos;
                    input .type = 'text'
                    input .type = 'file';
                    return false;
                }
                //AQUI VAN LAS EXTENSIONES VALIDAS
                //FIN EXTENSIONES
                Extension=element.name.split('.').pop();
                switch (Extension) {
                    case 'jpg':
                        Origen='1';
                        break;
                    case 'jpeg':
                        Origen='1';
                        break;
                    case 'png':
                        Origen='2';
                    default:
                        break;
                }
                const blobURL = URL.createObjectURL(element);
                const img = new Image();
                img.src = blobURL;
                img.onerror = function () {
                    URL.revokeObjectURL(this.src);
                    this.$toast.warning("No se pudo cargar la imagen");
                };
                img.onload=()=>{
                    if(this.Imagenes.length==4){
                        this.$toast.info('Sólo se Pueden Subir 4 Imágenes a la Vez');
                        return false;
                    }
                    URL.revokeObjectURL(this.src);
                    let WidthImg = img.width;
                    let HeightImg = img.height;
                    if (WidthImg > HeightImg) {
                        if (WidthImg > MaxWidth) {
                            HeightImg = Math.round((HeightImg * MaxWidth) / WidthImg);
                            WidthImg = MaxWidth;
                        }
                    } else {
                        if (HeightImg > MaxHeight) {
                            WidthImg = Math.round((WidthImg * MaxHeight) / HeightImg);
                            HeightImg = MaxHeight;
                        }
                    }
                    const canvas = document.createElement("canvas");
                    canvas.width = WidthImg;
                    canvas.height = HeightImg;
                    const ctx = canvas.getContext("2d");
                    ctx.drawImage(img, 0, 0, WidthImg, HeightImg);
                    this.Imagenes.push({IdImagen:this.IdImagen,Origen:Origen,Imagen:canvas.toDataURL("image/jpeg",0.7),Descripcion:''});
                    this.IdImagen++;
                    // this.$refs.photo.setAttribute("src",canvas.toDataURL("image/jpeg",0.7));
                }
            }

            // let MaxWidth=1920;
            // let MaxHeight=1080;
            // const foto=this.$refs.foto.files[0];
            // const blobURL = URL.createObjectURL(foto);
            // const img = new Image();
            // img.src = blobURL;
            // img.onerror = function () {
            //     URL.revokeObjectURL(this.src);
            //     this.$toast.warning("No se pudo cargar la imagen");
            // };
            // img.onload=()=>{
            //     URL.revokeObjectURL(this.src);
            //     let WidthImg = img.width;
            //     let HeightImg = img.height;
            //     if (WidthImg > HeightImg) {
            //         if (WidthImg > MaxWidth) {
            //             HeightImg = Math.round((HeightImg * MaxWidth) / WidthImg);
            //             WidthImg = MaxWidth;
            //         }
            //     } else {
            //         if (HeightImg > MaxHeight) {
            //             WidthImg = Math.round((WidthImg * MaxHeight) / HeightImg);
            //             HeightImg = MaxHeight;
            //         }
            //     }
            //     const canvas = document.createElement("canvas");
            //     canvas.width = WidthImg;
            //     canvas.height = HeightImg;
            //     const ctx = canvas.getContext("2d");
            //     ctx.drawImage(img, 0, 0, WidthImg, HeightImg);
            //     this.$refs.photo.setAttribute("src",canvas.toDataURL("image/jpeg",0.7));
            //     this.ShowLista=false;
            //     this.ShowBotonesServidor=false;
            //     this.ShowCamara=false;
            //     this.Observaciones='';
            //     this.Edicion=false;
            // }
        },
        IrAComentarioFoto(Foto,Descripcion,Index){
            this.ShowLista=false;
            let photo=document.getElementById("photo");
            this.ShowCamara=false;
            photo.setAttribute("src",Foto);
            this.Observaciones=Descripcion;
            this.IndexImagen=Index;
            this.Edicion=true;
        },
        AbrirCamara(){
            if (this.Imagenes.length==4) {
                this.$toast.warning('Sólo se pueden cargar 4 fotos a la vez');
            }
            else{
                let InputCamara=document.getElementById("InputCamara");
                InputCamara.click();
            }
            // else{
            //     let DispMedia=navigator.mediaDevices;
            //     if (!DispMedia || !DispMedia.getUserMedia) {
            //         console.log("getUserMedia() not supported.");
            //         return;
            //     }
            //     DispMedia.getUserMedia({
            //         audio:false,
            //         video:{
            //             width: { min:340, ideal: 620, max:1920 },
            //             height: { min:750,ideal: 460, max:1080 },
            //             aspectRatio:{ideal:1.7777777778,},
            //             facingMode:{exact:"environment"},
            //         }
            //     }).then((VidStream)=>{
            //         this.localstream=VidStream;
            //         this.ShowLista=false;
            //         this.ShowBotonesServidor=false;
            //         var video = document.getElementById('videoCam');
            //         this.ShowTomarFoto=true;
            //         if ("srcObject" in video) {
            //             video.srcObject = VidStream;
            //         } else {
            //             video.src = window.URL.createObjectURL(VidStream);
            //         }
            //         video.onloadedmetadata = function(e) {
            //             video.play();
            //         };
            //     }).catch((e)=>{
            //         console.log(e.name + ": " + e.message);
            //     });
            // }
        },
        AjustarCanvas(){
            let {width, height} = this.localstream.getVideoTracks()[0].getSettings();
            let canvas=document.getElementById("canvas");
            let video=document.getElementById("videoCam");
            video.setAttribute("width",width);
            video.setAttribute("height",height);
            const containerWidth = document.querySelector('.camera-container').offsetWidth;
            const containerHeight = document.querySelector('.camera-container').offsetHeight;
            canvas.setAttribute("width",containerWidth);
            canvas.setAttribute("height",containerHeight);
        },
        TomarFoto(){
            // const foto=this.$refs.foto.files[0];
            // const FR=new FileReader();
            // this.ShowLista=false;
            // this.ShowBotonesServidor=false;
            // this.ShowCamara=false;
            // this.Observaciones='';
            // this.Edicion=false;
            // FR.readAsDataURL(foto);
            // FR.onloadend = ()=> {
            //     this.$refs.photo.src = FR.result;                
            // }
            this.OrigenImg='';
            let Extension='';
            let MaxWidth=1920;
            let MaxHeight=1080;
            const foto=this.$refs.foto.files[0];
            Extension=foto.name.split('.').pop();
            switch (Extension) {
                    case 'jpg':
                        this.OrigenImg='1';
                        break;
                    case 'jpeg':
                        this.OrigenImg='1';
                        break;
                    case 'png':
                        this.OrigenImg='2';
                    default:
                        break;
                }
            const blobURL = URL.createObjectURL(foto);
            const img = new Image();
            img.src = blobURL;
            img.onerror = function () {
                URL.revokeObjectURL(this.src);
                this.$toast.warning("No se pudo cargar la imagen");
            };
            img.onload=()=>{
                URL.revokeObjectURL(this.src);
                let WidthImg = img.width;
                let HeightImg = img.height;
                if (WidthImg > HeightImg) {
                    if (WidthImg > MaxWidth) {
                        HeightImg = Math.round((HeightImg * MaxWidth) / WidthImg);
                        WidthImg = MaxWidth;
                    }
                } else {
                    if (HeightImg > MaxHeight) {
                        WidthImg = Math.round((WidthImg * MaxHeight) / HeightImg);
                        HeightImg = MaxHeight;
                    }
                }
                const canvas = document.createElement("canvas");
                canvas.width = WidthImg;
                canvas.height = HeightImg;
                const ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0, WidthImg, HeightImg);
                this.$refs.photo.setAttribute("src",canvas.toDataURL("image/jpeg",0.7));
                this.ShowLista=false;
                this.ShowBotonesServidor=false;
                this.ShowCamara=false;
                this.Observaciones='';
                this.Edicion=false;
            }
        },
        ConfirmarFoto(){
            this.ShowCamara=false;
            this.$refs.videoCam.src="";
            this.Observaciones='';
            this.localstream.getTracks()[0].stop();
            this.Edicion=false;
        },
        VolverATomarFoto(){
            this.ShowCamara=true;
            this.ShowTomarFoto=true;
            this.ShowConfirmacionFoto=false;
            this.$refs.videoCam.play();
            this.$refs.photo.setAttribute("src","");
        },
        EnviarFoto(){
            if (this.Servicio.Offline==0) {
                if (this.Edicion) {
                    this.Imagenes[this.IndexImagen].Descripcion=this.Observaciones;
                    this.Observaciones='';
                }
                else{
                    this.Imagenes.push({IdImagen:this.IdImagen,Origen:this.OrigenImg,Imagen:this.$refs.photo.getAttribute("src"),Descripcion:this.Observaciones});
                    this.IdImagen++;
                    this.OrigenImg='';
                }
                this.ShowLista=true;
                this.ShowCamara=true;
                this.ShowConfirmacionFoto=false;
                this.ShowBotonesServidor=true;
            }
            else{
                this.Imagenes[this.IndexImagen].Descripcion=this.Observaciones;
                this.Observaciones='';
                this.ShowLista=true;
                this.ShowCamara=true;
                this.ShowConfirmacionFoto=false;
                this.ShowBotonesServidor=true;
            }
        },
        CancelarFoto(){
            this.$swal({
                title: 'Eliminar Foto',
                text: '¿Está seguro que quiere eliminar la foto?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si',
                cancelButtonText: 'No',
                showCloseButton: true,
                showLoaderOnConfirm: true
            }).then((result)=>{
                if(result.value){
                    if (this.Edicion) {
                        this.Imagenes.splice(this.IndexImagen,1);
                    }
                    this.$refs.photo.setAttribute("src","");
                    this.ShowLista=true;
                    this.ShowCamara=true;
                    this.ShowConfirmacionFoto=false;
                    this.ShowBotonesServidor=true;
                    this.OrigenImg='';
                }
            });
        },
        EliminarFoto(index){
            this.$swal({
                title: 'Eliminar Foto',
                text: '¿Está seguro que quiere eliminar la foto?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si',
                cancelButtonText: 'No',
                showCloseButton: true,
                showLoaderOnConfirm: true
            }).then((result)=>{
                if(result.value){
                    let indexarray=this.Imagenes.findIndex((Element)=>index==Element.IdImagen);
                    this.Imagenes.splice(indexarray,1);
                }
            });
        },
        GuardarFotos(){
            this.$swal({
                title: 'Enviar Fotos',
                text: '¿Desea enviar las fotos?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si',
                cancelButtonText: 'No',
                showCloseButton: true,
                showLoaderOnConfirm: true
            }).then((result)=>{
                if(result.value){
                    this.BtnGuardado=true;
                    this.DisabledBtn=true;
                    let Imagenes=[];
                    this.Imagenes.forEach((Element)=>{
                        let Imagen={
                            Descripcion:Element.Descripcion,
                            Imagen:Element.Imagen.split("base64,")[1],
                            Origen:Element.Origen
                        };
                        Imagenes.push(Imagen);
                    });
                    let formData=new FormData();
                    formData.set('IdServicio',this.Servicio.IdServicio);
                    formData.set('IdEquipo',this.Equipo.IdEquipo);
                    formData.set('TipoEvidencia',this.TipoEvidencia);
                    formData.set('Imagenes',JSON.stringify(Imagenes));
                    this.$http.post(
                        'webapp/addserviciofiles',formData
                    ).then((res)=>{
                        this.BtnGuardado=true;
                        this.$toast.success(res.data.message);
                        this.Imagenes=[];
                        this.DisabledBtn=false;
                        this.Edicion=false;
                        // this.$router.push({name:'EquiposServicioWebApp',params:{pServicio:this.Servicio}});
                    }).catch((err)=>{
                        this.DisabledBtn=false;
                        this.$toast.warning(err.response.data.message);
                        for (let index = 0; index < this.Imagenes.length; index++) {
                            if (this.Imagenes[index].Check) {
                                this.BtnGuardado=false;
                                break;
                            }
                        }
                    });
                }
            });
        },
        Regresar(){
            if (this.ShowLista) {
                this.$router.push({name:'EquiposServicioWebApp'});
            }
            else{
                let photo=document.getElementById("photo");
                this.ShowLista=true;
                this.ShowCamara=true;
                photo.setAttribute("src","");
                this.Edicion=false;
                this.Observaciones='';
                this.IndexImagen=0;
            }
        },
        IrAListaImagenes(){
            this.$router.push({name:'ListImagenesWebApp',params:{pTipoEvidencia:this.TipoEvidencia,pServicio:this.Servicio,pEquipo:this.Equipo}});
        },
        // DesactivarBoton(){
        //     let Bnd=false;
        //     for (let index = 0; index < this.Imagenes.length; index++) {
        //         if (this.Imagenes[index].Check) {
        //             Bnd=true;
        //             break;
        //         }
        //     }
        //     this.BtnGuardado=Bnd ? false : true;
        // },
    },
    mounted() {
        this.bus.$off('EmitReturn');
        this.bus.$on('EmitReturn',()=>{
            this.Regresar();
        });
        if (this.pTipoEvidencia!=undefined) {
            sessionStorage.setItem('tipoevidencia',this.pTipoEvidencia);
        }
        
        this.TipoEvidencia=sessionStorage.getItem('tipoevidencia');
        
        if (this.pServicio==undefined) {
            this.Servicio=JSON.parse(sessionStorage.getItem('servicio'));
        }
        else{
            this.Servicio=this.pServicio;
        }
        if (this.TipoEvidencia!='EQUIPO') {
            this.Equipo.IdEquipo=0;
        }
        else{
            if (this.pEquipo!=undefined) {
                sessionStorage.setItem('equipo',JSON.stringify(this.pEquipo));
            }
            this.Equipo=JSON.parse(sessionStorage.getItem('equipo'));
            this.HeaderProps.TextoHeader='Fotos del Equipo';
            this.HeaderProps.ShowSubHeader=true;
            this.HeaderProps.ShowTecnico=false;
            this.HeaderProps.ShowFechaHoy=false;
            this.HeaderProps.ShowFotoPerfil=false;
            this.HeaderProps.ShowMenuDropdown=false;
            this.HeaderProps.TextoCentroSubHeader=this.Equipo.Nequipo;
        }
    },
    beforeDestroy() {
        if (this.localstream!=null) {
            this.$refs.videoCam.src="";
            this.localstream.getTracks()[0].stop();
        }
    },
}
</script>

<style scoped>
.photo-servicio-container{
    position: absolute;
    overflow: hidden;
    z-index:90;
    top:0;
    left:0;
    min-height: 100%;
    min-width: 100%;
}
.cropper {
	height: 300px;
	width: 600px;
    background: #DDD;
}
.cropp{
    position: relative;
    width:100%;
    height: 100%;
}
#cropp-alt{
    width:100%;
    height: 40vh;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
}
.cropp > img{ 
    max-width: 100vw;
    height: 40vh;
    object-fit: fill;
}
.video-camera{
    position: relative;
    width: 100%;
    min-height:100% !important;
    object-fit:cover;
    margin-bottom: 0;
    padding-bottom: 0;
}
#tomar-foto{
    margin-left: auto;
    margin-right: auto;
    border-radius: 50%;
    width: 80px;
    height: 80px;
}
.confirmacion-photo{
    z-index:96
}
.btn-confirmar{
    position: absolute;
    width: 100%;
    left: 10vw;
    top: 75vh;
    right: 4vw;
}
.btn-rechazar{
    position: absolute;
    left: 60vw;
    right: 1vw;
    top: 75vh;
}
</style>