<template>
    <div>
        <CHeader :pHeaderProps="HeaderProps">
            <template slot="Contenido">
                <FullCalendar
                    id="Agenda"
					:options="calendarOptions" 
				/>
            </template>
        </CHeader>
    </div>
</template>

<script>
import CHeader from "@/views/webapp/components/HeaderWebApp.vue";
import FullCalendar from "@fullcalendar/vue";
import esLocale from "@fullcalendar/core/locales/es";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
export default {
    components:{FullCalendar,CHeader},
    data() {
        return {
            HeaderProps:{
                ShowReturn:true,
                TextoHeader:'Agenda',
                ShowLogo:false,
                IndicadorAccion:false,
                IndicadorAccionTexto:''
            },
            locale:esLocale,
            calendarPlugins: [
				dayGridPlugin,
				timeGridPlugin,
				interactionPlugin
			],
            calendarWeekends: true,
            calendarEvents:[],
            ListServicios:[],
            IdTrabajador:'',
            calendarOptions:{
				locale:esLocale,
				contentHeight:500,
				// ref:"fullCalendar",
				initialView:'dayGridMonth',
				headerToolbar:{
					start: 'prev,next,today',
					center: 'title',
                    end:false
				},
				plugins:[dayGridPlugin,timeGridPlugin,interactionPlugin],
				weekends:true,
				events:[],
				dayMaxEvents:4,
				dateClick:this.handleDateClick,
				eventClick:this.eventClick,
                eventDisplay:'list-item',
                aspectRatio:0,
				// class:'full-calendar'
			}
        }
    },
    methods: {
        Lista(){
            this.$http.get('webapp/servicios',{
                params:{
                    IdTrabajador:this.IdTrabajador,
                }
            }).then((res)=>{
                this.calendarOptions.events = [];
                const actividades = res.data.Lista;
                actividades.forEach(Item => {
                    this.calendarOptions.events.push({
                        start: `${Item.Fecha_I+' '+Item.HoraInicio}`,
                        title: "",
                        allDay: false,
                        id: Item.IdServicio,
                        backgroundColor: Item.FondoColor,
                        color: Item.FondoColor,
                        textColor: Item.ColorLetra,
                        Servicio: Item.Folio + "\n" + " Cliente: " + Item.Client,
                        Hora: Item.HoraInicio.substring(0, 5),
                        Fecha: this.formatoFecha(Item.Fecha_I),
                        classNames:['EventosInd']
                    });
                });
                this.ListServicios=res.data.Lista;
            }).finally(()=>{
			});
        },
        Regresar(){
            this.$router.push({ name: "HomeWebApp", params: {} });
        },
        handleDateClick(date){
            this.$router.push({name:'ServiciosWebApp',params:{pFecha:date.dateStr,pOrigen:'Agenda'}});
        },
        eventClick(event){

        },
        formatoFecha(texto) {

			let data = texto;

			let dias = [
				"Lunes",
				"Martes",
				"Miercoles",
				"Jueves",
				"Viernes",
				"Sabado",
				"Domingo"
			];
			let meses = [
				"Enero",
				"Febrero",
				"Marzo",
				"Abril",
				"Mayo",
				"Junio",
				"Julio",
				"Agosto",
				"Septiembre",
				"Octubre",
				"Noviembre",
				"Diciembre"
			];

			let date = new Date(data);
			//let fechaNum = date.getDate() + 1;
			//let fechaNum = date.getDate();
			let mes_name = date.getMonth();
			let fechaDivided = data.split('-')

			return dias[date.getDay()] + " " + fechaDivided[2] + " de " + meses[mes_name] + " de " + date.getFullYear();
        },
    },
    created() {
        this.bus.$off('EmitReturn');
        this.bus.$on('EmitReturn',()=>{
            this.Regresar();
        });
        this.IdTrabajador=JSON.parse(sessionStorage.getItem('user')).IdTrabajador;
    },
    mounted() {
        this.Lista();
    },
}
</script>
<style>
    #Agenda .fc-center{
        width: 100%;
        display: flex;
        justify-content: center;
        font-size: 18px;
    }
    #Agenda .fc-header-toolbar .fc-toolbar-chunk .fc-toolbar-title{
        font-size: 18px;
    }
    #Agenda .fc-right{
        display: none;
    }
    .EventosInd .fc-event-title{
        display: none;
    }
    .EventosInd .fc-event-time{
        display: none;
    }
    #Agenda .fc-daygrid-body-unbalanced .fc-daygrid-day-events{
        display:inline-flex;
    }
    #Agenda .fc-daygrid-body-unbalanced .fc-daygrid-day-events .fc-daygrid-event-harness{
        width:10%;
    }
    #Agenda .fc-daygrid-body-unbalanced .fc-daygrid-day-events .fc-daygrid-day-bottom{
        position: relative;
        top: 13px;
        right: 43px;
    }
    #Agenda .fc-daygrid-body-unbalanced .fc-daygrid-day-events .fc-daygrid-day-bottom .fc-daygrid-more-link{
        border: 1px solid #FF640A;
        border-radius: 0.25rem;
        -webkit-box-shadow: 0px 3px 6px #00000066 !important;
                box-shadow: 0px 3px 6px #00000066 !important;
        color: #FF640A !important;
        padding: 0 !important;
        font-weight: 600;
        font-size:12px;
        pointer-events: none;
    }
</style>