<template>
    <div>
        <div class="form-group row">
            <div class="col-12 col-sm-12">
                <label for="">Concepto</label>
                <input v-model="ObjGastoTrabajador.Concepto" type="text" class="form-control">
            </div>
        </div>
        <div class="form-group row mt-3">
            <div class="col-12 col-sm-12">
                <label for="">Monto</label>
                <input v-model="ObjGastoTrabajador.Total" type="text" class="form-control">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:['poBtnSave','IdCajaC','IdTrabajador'],
    data() {
        return {
            ObjGastoTrabajador:{
                IdGastoxTrabajador:0,
                IdGasto:'',
                Fecha:'',
                Concepto:'',
                Total:0,
                IdTrabajador:'',
                IdCajaC:''
            }
        }
    },
    methods: {
        Guardar(){
            this.poBtnSave.DisableBtn=true;
            let formData=new FormData();
            formData.set('IdGastoxTrabajador',this.ObjGastoTrabajador.IdGastoxTrabajador);
            formData.set('IdGasto',this.ObjGastoTrabajador.IdGasto);
            formData.set('Concepto',this.ObjGastoTrabajador.Concepto);
            formData.set('Total',this.ObjGastoTrabajador.Total);
            formData.set('IdTrabajador',this.ObjGastoTrabajador.IdTrabajador);
            formData.set('IdCajaC',this.ObjGastoTrabajador.IdCajaC);
            this.$http.post('webapp/gastos',formData).then((res)=>{
                $('#ModalForm').modal('hide');
                this.poBtnSave.DisableBtn=false;
                this.bus.$emit('Lista_Gasto');
                this.$toast.success('Guardado Correctamente');
            }).catch((err)=>{
                this.poBtnSave.DisableBtn=false;
            })
        },
        Recuperar(){

        },
        Limpiar(){
            this.ObjGastoTrabajador={
                IdGasto:'',
                Fecha:'',
                Concepto:'',
                Total:0,
                IdTrabajador:'',
                IdCajaC:''
            }
        }
    },
    created() {
        this.bus.$off('Nuevo_Gasto');
        this.bus.$on('Nuevo_Gasto',(Id)=>{
            this.bus.$off('Save_Gasto');
            this.bus.$on('Save_Gasto',()=>{
                this.Guardar();
            });
            this.Limpiar();
            this.ObjGastoTrabajador.IdCajaC=this.IdCajaC;
            this.ObjGastoTrabajador.IdTrabajador=this.IdTrabajador;
            if (Id>0) {
                this.Recuperar();
            }
        });
    },
}
</script>

<style>

</style>