<template>
    <CHeader :pHeaderProps="HeaderProps">
        <template slot="Contenido">
            <div class="row mt-3">
                <div class="col-12 col-sm-12">
                    <div class="card card-01 shadow">
                        <div class="card-body">
                            <span style="white-space:break-spaces;">{{ Servicio.Observaciones }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </CHeader>
</template>

<script>
import CHeader from "@/views/webapp/components/HeaderWebApp.vue";
export default {
    props:['pServicio'],
    components:{CHeader},
    data() {
        return {
            HeaderProps:{
                ShowSubHeader:false,
                ShowReturn:true,
                TextoHeader:'Tareas',
                ShowLogo:false,
            },
            Servicio:{}
        }
    },
    methods: {
        Regresar(){
            this.$router.push({name:'EquiposServicioWebApp'});
        }
    },
    created() {
        this.bus.$off('EmitReturn');
        this.bus.$on('EmitReturn',()=>{
            this.Regresar();
        });
    },
    mounted() {
        this.Servicio=this.pServicio;
    },
}
</script>

<style>

</style>