<template>
    <CHeader :pHeaderProps="HeaderProps">
        <template slot="Contenido">
            <div class="row mt-3">
                <div class="col-12 col-sm-12">
                    <CLoader :pConfigLoad="ConfigLoadServicio">
                        <template slot="BodyFormLoad">
                            <div class="card card-01 shadow" @click="VerReporteServicio()">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-2 col-sm-2 position-relative">
                                            <i class="fa fa-file-pdf fa-3x icon-color-app"></i>
                                        </div>
                                        <div class="col-10 col-sm-10 align-items-center d-flex">
                                            <h6>Reporte de servicio</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </CLoader>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-12 col-sm-12">
                    <CLoader :pConfigLoad="ConfigLoadEvidencia">
                        <template slot="BodyFormLoad">
                            <div class="card card-01 shadow" @click="VerReporteEvidencia()">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-2 col-sm-2 position-relative">
                                            <i class="fa fa-file-pdf fa-3x icon-color-app"></i>
                                        </div>
                                        <div class="col-10 col-sm-10 align-items-center d-flex">
                                            <h6>Reporte de evidencia fotográfica</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </CLoader>
                </div>
            </div>
        </template>
    </CHeader>
</template>

<script>
import CHeader from "@/views/webapp/components/HeaderWebApp.vue";
export default {
    props:['pServicio'],
    components:{CHeader},
    data() {
        return {
            HeaderProps:{
                ShowSubHeader:true,
                ShowTecnico:false,
                ShowFechaHoy:false,
                ShowMenuDropdown:false,
                TextoCentroSubHeader:'',
                ShowFotoPerfil:false,
                ShowReturn:true,
                TextoHeader:'Historial de Servicio',
                ShowLogo:false,
            },
            ConfigLoadServicio:{
				ShowLoader:false,
				ClassLoad:true,
			},
            ConfigLoadEvidencia:{
				ShowLoader:false,
				ClassLoad:true,
			},
            Servicio:{}
        }
    },
    methods: {
        Regresar(){
            this.$router.push({name:'HistorialEquipoWebApp'});
        },
        VerReporteServicio(){
            this.ConfigLoadServicio.ShowLoader=true;
            this.$http.get(
                'reporte/servicio',
                {
                responseType: 'blob',
                params :
                    {
                        IdServicio:this.Servicio.IdServicio,
                    }
                }
            ).then( (response) => {
                let pdfContent = response.data;
                let file = new Blob([pdfContent], { type: 'application/pdf' });
                let fileUrl = URL.createObjectURL(file);
                window.open(fileUrl);
            }).finally(()=>{
                this.ConfigLoadServicio.ShowLoader=false;
            });
        },
        VerReporteEvidencia(){
            this.ConfigLoadEvidencia.ShowLoader=true;
            this.$http.get('reporte/servicioevidencia',
            {
                responseType: 'blob',
                params :
                    {
                        IdServicio:this.Servicio.IdServicio,
                    }
            }).then( (response) => {

                let pdfContent = response.data;
                let file = new Blob([pdfContent], { type: 'application/pdf' });
                let fileUrl = URL.createObjectURL(file);
                window.open(fileUrl);
            }).finally(()=>{
                this.ConfigLoadEvidencia.ShowLoader=false;
            });
        }
    },
    created() {
        if (this.pServicio!=undefined) {
            sessionStorage.setItem('serviciohist',JSON.stringify(this.pServicio));
        }
        this.Servicio=JSON.parse(sessionStorage.getItem('serviciohist'));
        this.bus.$off('EmitReturn');
    },
    mounted() {
        this.bus.$on('EmitReturn',()=>{
            this.Regresar();
        });
    },
}
</script>

<style>

</style>