<template>

</template>
<!--<template>
    <div>

<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <a class="navbar-brand" href="#">Navbar</a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item active">
        <a class="nav-link" href="#">Home <span class="sr-only">(current)</span></a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#">Link</a>
      </li>
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Administración
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <router-link class="dropdown-item" to="/sucursal">Sucursales</router-link>
            <router-link class="dropdown-item" to="/clientes">Clientes</router-link>
          <router-link class="dropdown-item" to="/usuarios">Usuarios</router-link>
               <router-link class="dropdown-item" to="/personal">Personal</router-link>
                   <router-link class="dropdown-item" to="/tipounidad">Tipo Unidad</router-link>
                                     <router-link class="dropdown-item" to="/tiposervicio">Tipo Servicio</router-link>
                               <router-link class="dropdown-item" to="/folio">Folio</router-link>
                                   <router-link class="dropdown-item" to="/servicio">Servicio</router-link>
  <router-link class="dropdown-item" to="/vehiculo">Vehiculo</router-link>        
                       <router-link class="dropdown-item" to="/categoriapersonal">Categoria Personal</router-link>        
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="#">Something else here</a>
        </div>
      </li>
      <li class="nav-item">
        <a class="nav-link disabled" href="#">Disabled</a>
      </li>
    </ul>
    <form class="form-inline my-2 my-lg-0">
      <input class="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search">
      <button type="button" @click="CerrarSession" class="btn btn-outline-success my-2 my-sm-0" >Cerrar sesion</button>
    </form>
  </div>
</nav>

    
</div>
</template>-->
<script>
export default {
  data() {
    return {
      
    }
  },
  methods: {
    CerrarSession()
    {
      this.$store.dispatch('logout');
      this.$router.push({name: "Login"});
    }
    
  },
}
</script>
 
   