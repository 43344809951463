<template>
    <div>
        <section class="container-fluid mt-2">
            <Menu :isInOportunidad="true" :pShowReturn="false" :isInContactos="true" :pSitio="NombreSeccion">
            </Menu>

            <div class="row"> 
                <div class="col-md-12 col-lg-12 col-xl-12 mt-2">
                   <Clist :isCrm="true" :pShowBtnAdd="ShowBtns" :ShowHead="false" @FiltrarC="ListaCards" :Filtro="Filtro" :regresar="false"  :Nombre="NameList"  :isModal="EsModal" :pConfigLoad="ConfigLoad" :isContactos="true">
                    <template slot="Botonnuevo">
                        <div class="col-md-12 col-lg-2 mt-2 text-right p-0">
                            <!-- <button v-if="disableHouseAccount" type="button" data-toggle="modal" data-target="#ModalNewUser"  data-backdrop="static" data-keyboard="false" class="btn btn-01 mr-1" @click="houseAccount()">House Account</button> -->
                            <button type="button" data-toggle="modal" :data-target="'#'+NombreSeccion"  data-backdrop="static" data-keyboard="false" class="btn btn-pink mr-3" @click="Nuevo()"><i class="far fa-plus-circle"></i> Nuevo</button>   
                        </div>
                    </template>
                    <template slot="Filtros"> 
                        <label class="mr-2">Categoría</label>
                        <select @change="ListaCards" v-model="Tipo" class="form-control form-control">
                            <option value="">Seleccione una opción</option>
                            <option :value="'Cliente'">Cliente</option>
                            <option :value="'Prospecto'">Prospecto</option>
                            <option :value="'Suspecto'">Suspecto</option>
                            <option :value="'Inactivo'">Inactivo</option>
                        </select>
                        <h4 class="Propiedades Restantes"></h4>
                        <!-- <span class="ml-3 titulosmonitoreo">Monitoreo de Propiedades:</span> <span class="titulosmonitoreo text-primary"> {{ (Number(ContadorPaquetePropiedad)-Number(ContadorPropiedades) ) < 0 ? 0 : (Number(ContadorPaquetePropiedad)-Number(ContadorPropiedades) )}}</span> -->
                        <span class="ml-3 titulosmonitoreo">Propiedades:</span> <span class="titulosmonitoreo text-primary"> {{ Number(ContadorPropiedadesBase) }}</span>
                        <span class="ml-3 titulosmonitoreo">Propiedades Monitoreo Interno:</span> <span class="titulosmonitoreo text-primary"> {{ Number(ContadorPropiedades) }}</span>
                        <span class="ml-3 titulosmonitoreo">Client View Propiedades:</span> <span class="titulosmonitoreo text-primary"> {{ Number(ContadorPropiedadesMonCliente) }}</span>
                        <!-- <span class="ml-3 titulosmonitoreo">Usuarios Monitoreo Restantes:</span> <span class="titulosmonitoreo text-primary"> {{ (Number(ContadorPaqueteMonitoreo)-Number(ContadorUsuarios) ) < 0 ? 0 : (Number(ContadorPaqueteMonitoreo)-Number(ContadorUsuarios)) }}</span> -->
                        <span class="ml-3 titulosmonitoreo">Usuarios Client View:</span> <span class="titulosmonitoreo text-primary"> {{ Number(ContadorUsuarios) }}</span>
                    </template>


                    <template slot="cards">
                        <div class="row row-cols-md-4 row-cols-lg-4 row-cols-xl-2 mt-3">                        
                            <div v-for="(lista,key,index) in ListaContactosCards" :key="index" class="col-md-4 col-lg-4 col-xl-2 card-group">
                                <div class="card mb-3 tarjetacliente" style="border:2px solid #0F3F87;cursor:pointer;"  @click="go_to_sucursal(lista)">
                                    <!-- <div class="card-body pt-2" style="min-height:265px"> -->
                                        <!-- div v-if="lista.LogoCliente!=null && lista.LogoCliente!=''" :style="'background-image:url('+(Ruta+lista.LogoCliente)+');background-size:contain;width:100%;height:100px;background-repeat:no-repeat;background-position:center'"></div> -->
                                        <!-- <div v-else style="background:#b4b4b4;width:100%;height:100px"></div> -->
                                    <img height="160px" v-if="lista.LogoCliente!=null && lista.LogoCliente!=''" class="card-img-top" :src="Ruta+lista.LogoCliente" alt=""/>
                                    <img height="160px" v-else class="card-img-top" src="" alt="" style="background-color:#b4b4b4;padding:75px"/>
                                    <div class="card-body pt-2">
                                        <!-- <div class="row">
                                            <div class="col-sm-12 col-md-12 col-lg-5 col-xl-5 p-0 mt-3" >
                                                <div v-if="lista.LogoCliente!=null && lista.LogoCliente!=''" :style="'background-image:url('+(Ruta+lista.LogoCliente)+');background-size:contain;width:90%;height:100px;background-repeat:no-repeat;background-position:center'"></div>
                                                <div v-else style="background:#b4b4b4;width:90%;height:100px"></div>
                                            </div>
                                            <div class="col-sm-12 col-md-12 col-lg-7 col-xl-7 p-0">
                                                    <h6 class="m-0">{{ $limitCharacters(lista.Nombre,22) }}</h6>
                                                    {{ $limitCharacters(lista.Ciudad,22) }}
                                                <div class="mt-1 mb-0 mr-0 ml-0">
                                                    <b>Sucursales: </b> {{ lista.Sucursales }}
                                                    <br>
                                                    <b>Oportunidades: </b> {{ lista.Oportunidades }}
                                                    <br>
                                                    <b>Contratos: </b> {{ lista.Contratos }}
                                                </div>
                                            </div>
                                        </div> -->
                                        <!-- <div class="row">
                                            <div class="col-lg-12">
                                                <div v-if="lista.LogoCliente!=null && lista.LogoCliente!=''" :style="'background-image:url('+(Ruta+lista.LogoCliente)+');background-size:contain;width:100%;height:100px;background-repeat:no-repeat;background-position:center'"></div>
                                                <div v-else style="background:#b4b4b4;width:100%;height:100px"></div>
                                            </div>
                                        </div> -->
                                        <div class="row mt-1">
                                            <div class="col-lg-12">
                                                <h6 class="m-0">{{lista.Nombre.toUpperCase() }}
                                                    <!-- <button v-b-tooltip.hover.rightbotton :title="lista.Nombre" class="btn-circle boton-info"><i class="fa fa-info"></i></button> -->
                                                </h6>
                                                <!-- <h6 style="line-height:1" class="m-0">{{ lista.Nombre.toUpperCase()}}
                                                    <button v-b-tooltip.hover.rightbotton :title="lista.Nombre" class="btn-circle boton-info"><i class="fa fa-info"></i></button>
                                                </h6> -->
                                                {{ lista.Ciudad }}
                                                <div class="mb-0 mr-0 ml-0">
                                                    <b>Propiedades: </b> {{ lista.Sucursales }}
                                                    <br>
                                                    <b>Oportunidades: </b> {{ lista.Oportunidades }}
                                                    <br>
                                                    <b>Contratos: </b> {{ lista.Contratos }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="editar_contactos dropright">
                                    <button v-if="RolUsuario=='admin'" type="button" style="background:rgba(0,0,0,0); border:none;"
                                    title="Menu"
                                    id="dropdownMenuButton"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                    >
                                        <i class="fa fa-ellipsis-v"></i>
                                    </button>
                                    <button v-else type="button" style="background:rgba(0,0,0,0); border:none;"
                                    title="Editar"
                                    data-toggle="modal"
                                    :data-target="'#'+NombreSeccion"
                                    data-backdrop="static"
                                    @click="Editar(lista.IdCliente)"
                                    >
                                        <i class="fa fa-ellipsis-v"></i>
                                    </button>
                                    <div v-if="RolUsuario=='admin'" class="dropdown-menu dropdown-menu-user" aria-labelledby="dropdownMenuButton">
                                        <div class="dropdown-menu-header">
                                            <h4 style="opacity:1" class="widget-nombre mt-1 text-center">Seleccione una opción</h4>
                                        </div>
                                        <a
                                            @click="Editar(lista.IdCliente)"
                                            data-toggle="modal"
                                            :data-target="'#'+NombreSeccion"
                                            data-backdrop="static"
                                            class="dropdown-item"
                                            >
                                            <i class="fa fa-pencil fa-fw-m"></i> Editar
                                        </a>
        
                                        <a
                                            @click="Eliminar(lista.IdCliente)"
                                            data-backdrop="static"
                                            class="dropdown-item"
                                            >
                                            <i class="fa fa-trash fa-fw-m"></i> Eliminar
                                        </a>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </template>
                </Clist>
                <Modal :NameModal="NombreSeccion" :size="size" :Nombre="NameList" :poBtnSave="oBtnSave" :pEmitSeccion="EmitSeccion">
                    <template slot="Form">
                        <Form :poBtnSave="oBtnSave" >
                        </Form>
                    </template>
                </Modal> 


                </div>
            </div>
        </section>
        <!-- LISTADO EN FORMA DE CARTAS -->
    </div>
</template>
<script>
import Modal from '@/components/Cmodal.vue';
import Clist from '@/components/Clist.vue';
import Cbtnaccion from '@/components/Cbtnaccion.vue';
import Form from '@/views/modulos/crm/contactos/form.vue'
import Menu from "../indexMenu.vue";

export default {
    name :'list',
    props:['tipolistp'],
    components :{
        Modal,
        Clist,
        Cbtnaccion,
        Form,
        Menu,
    },
    data() {
        return {
            NombreSeccion: 'Contactos',
            Tipo:'',
            FormName:'clientesForm',//Por si no es modal y queremos ir a una vista declarada en el router
            EsModal:true,//indica si es modal o no
            size :"modal-lg",
            NameList:"Contacto Principal",
            EmitSeccion:"Contacto",
            urlApi:"crmcontactos/list",
            ListaContactos:[],
            ListaContactosCards:[],
            ListaHeader:[],
            TotalPagina:2,
            Pag:0,
            ShowBtns:true,
            TipoList:'',
            Filtro:{
                Nombre:'',
                Placeholder:'Nombre..',
                TotalItem:0,
                Pagina:1,
                Entrada:30
            },
             oBtnSave:{//valores  isModal(true),nombreModal('ModalForm'),tipoModal=1,regresarA(''),disableBtn(false),txtSave('Guardar'),txtCancel('Cerrar');
                isModal:true,
                disableBtn:false,
                toast:0,
                nombreModal:"Contactos",
                EmitSeccion:'Contacto'
            },
			ConfigLoad:{
				ShowLoader:true,
				ClassLoad:true,
			},
            Ruta:"",
            RutaExterior:"",
            RutaLogoSucursal:"",
            RutaExteriorSucursal:"",
            disableHouseAccount:true,
            RolUsuario:"",
            ContadorUsuarios:0,
            ContadorPaqueteMonitoreo:0,
            ContadorPropiedades:0,
            ContadorPaquetePropiedad:0,
            ContadorPropiedadesBase:0,
            ContadorPaquetePropiedadesBase:0,
            ContadorPropiedadesMonCliente:0,
            ContadorPaquetePropiedadesMonCliente:0
        }
    },
    methods: {
        Nuevo()
        {
            if (this.oBtnSave.isModal==true)
            { 
                this.bus.$emit('NewModal_'+this.EmitSeccion,true);
            }
            else{
                this.bus.$emit('NewModal_'+this.EmitSeccion);
            }
        },
        Eliminar(Id)
        {
            this.$swal({
                title: 'Esta seguro que desea eliminar este dato?',
                text: 'No se podra revertir esta acción',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si',
                cancelButtonText: 'No, mantener',
                showCloseButton: true,
                showLoaderOnConfirm: true
            }).then((result) => {
                if(result.value) {
                    this.$http.delete(
                        'crmcontactos/' + Id
                    ).then( (res) => {
                        this.$toast.success('Información eliminada');
                        this.ListaCards();
                    });
                } 
            });
        },
        async Lista()
        {
            this.ConfigLoad.ShowLoader = true;
            await this.$http.get(
                this.urlApi,
                {
                    params:{Nombre:this.Filtro.Nombre,Entrada:this.Filtro.Entrada,pag:this.Filtro.Pagina, RegEstatus:'A',Tipo:this.Tipo}
                }
            ).then( (res) => {
     
                this.ListaContactos =res.data.data.clientes;
                this.ListaHeader=res.data.headers;
                this.Filtro.Entrada=res.data.data.pagination.PageSize;
                this.Filtro.TotalItem=res.data.data.pagination.TotalItems; 
            }).finally(()=>{
				this.ConfigLoad.ShowLoader = false;
			});
        },
        Editar(Id){
            this.bus.$emit('NewModal_'+this.EmitSeccion,false,Id);
        },
        // REEMPLAZAR LO DE ARRIBA CUANDO LA FUNCIONALIDAD ESTÉ COMPLETA
        async ListaCards(){
            this.ConfigLoad.ShowLoader = true;
            await this.$http.get(
                'crm/contactoscard/get',
                {
                    params:{
                        Nombre:this.Filtro.Nombre,
                        Entrada:this.Filtro.Entrada,
                        pag:this.Filtro.Pagina, 
                        RegEstatus:'A',
                        Tipo:this.Tipo}
                }
            ).then((res)=>{
                this.ListaContactosCards=res.data.data.clientes;
                this.ContadorUsuarios=res.data.data.ContadorUsuariosMonitoreo;
                this.ContadorPaqueteMonitoreo=res.data.data.ContadorPaqueteMonitoreo;
                this.ContadorPaquetePropiedad=res.data.data.ContadorPaquetePropiedad;
                this.ContadorPaquetePropiedadBase=res.data.data.ContadorPaquetePropiedadBase;
                this.ContadorPaquetePropiedadesMonCliente=res.data.data.ContadorPaquetePropiedadMonCliente;
                this.ContadorPropiedades=res.data.data.ContadorPropiedades;
                this.ContadorPropiedadesBase=res.data.data.ContadorPropiedadesBase;
                this.ContadorPropiedadesMonCliente=res.data.data.ContadorPropiedadesMonCliente;
                this.ListaHeader=res.data.headers;
                this.Filtro.Entrada=res.data.data.pagination.PageSize;
                this.Filtro.TotalItem=res.data.data.pagination.TotalItems; 
                this.Ruta=res.data.ruta;
                this.RutaExterior=res.data.rutaExterior;
                this.RutaLogoSucursal=res.data.rutaSucursalLogo;
                this.RutaExteriorSucursal=res.data.rutaSucursalExterior;
                this.ListaContactosCards.forEach(item => {
						if (item.Nombre == "House Account") {
							this.disableHouseAccount = false;
						}
					});
            }).finally(()=>{
				this.ConfigLoad.ShowLoader = false;
			});
        },
        MostrarAlgo(){
            console.log("a");
        },
        go_to_sucursal(objcliente){
            // objcliente.Ruta=this.Ruta;
            // this.$router.push({name:'crmcontactosucursal', params:{ocliente:objcliente,tipolistp:this.TipoList}})
            this.$router.push({name:'propiedades',
            params:{
                pContacto:objcliente,
                pRuta:this.Ruta,
                pRutaExterior:this.RutaExterior,
                pContadorUsuarios:this.ContadorUsuarios,
                pContadorPaqueteMonitoreo:this.ContadorPaqueteMonitoreo,
                pContadorPaquetePropiedad:this.ContadorPaquetePropiedad,
                pContadorPaquetePropiedadBase:this.ContadorPaquetePropiedadBase,
                pContadorPaquetePropiedadMonCliente:this.ContadorPaquetePropiedadesMonCliente,
                pContadorPropiedades:this.ContadorPropiedades,
                pContadorPropiedadesBase:this.ContadorPropiedadesBase,
                pContadorPropiedadesMonCliente:this.ContadorPropiedadesMonCliente,
                pRutaLogoSucursal:this.RutaLogoSucursal,
                pRutaExteriorSucursal:this.RutaExteriorSucursal
            }});
        },
        go_to_usuarios(objcliente){
            this.$router.push({name:'listusu', params:{obj:objcliente}})
        },
        houseAccount() {
			this.$swal({
				title: "Se registrará una cuenta House Account",
				text: "No se podra revertir esta acción",
				type: "warning",
				showCancelButton: true,
				confirmButtonText: "Si",
				cancelButtonText: "No, cancelar",
				showCloseButton: true,
				showLoaderOnConfirm: true
			}).then(result => {
				if (result.value) {
					this.$toast.success("Información eliminada");
					this.$http
						.post("clientes/houseaccount", {
							params: {}
						})
						.then(res => {
							this.ListaCards();
						});
				}
			});
		}
    },
    created()
    {   
        // EVALUAR EL ROL DEL USUARIO
        this.RolUsuario=JSON.parse(sessionStorage.getItem("user")).Perfil.toLowerCase();

        //Obligatorio pasar el tipolist
        if(this.tipolistp!=undefined)
        {
            sessionStorage.setItem('IdSaved',JSON.stringify(this.tipolistp));       
        }

        this.TipoList=JSON.parse( sessionStorage.getItem('IdSaved'));
        
        if(this.TipoList=='Scanning'){
            this.ShowBtns=false;
        }
        
        this.bus.$off('Delete');
        this.bus.$off('List');
        this.bus.$off('Regresar');
        // this.Lista();
        this.ListaCards();
        
        this.bus.$on('Delete',(Id)=> 
        {
            this.Eliminar(Id);
        });
        this.bus.$on('List',(proceso,seccion)=> 
        {
            // this.Lista();
            this.ListaCards();
        });
        this.bus.$on('Regresar',()=> 
        {
            this.$router.push({name:'submenucrm'});
        });
        // PASARLO COMO EL LLAMADO DEL METODO LIST
        this.ListaCards();
    }
}
</script>