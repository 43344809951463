<template>
    <div>
        <div class="row">
            <div class="col-lg-12 d-flex justify-content-center">
                <img v-if="CheckTrabajador.TipoCheck=='checkin'" class="img img-thumbnail w-50" :src="Check=='In' ? CheckTrabajador.Imagen : CheckTrabajador.ImagenCheckOut"/>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <label for="">Comentario</label>
                <textarea class="form-control" v-if="Check=='In'" disabled v-model="CheckTrabajador.Comentario" cols="10" rows="10"></textarea>
                <textarea class="form-control" v-if="Check=='Out'" disabled v-model="CheckTrabajador.ComentarioCheckOut" cols="10" rows="10"></textarea>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:"Comentario",
    props:["poBtnSave"],
    data() {
        return {
            Modal:true,
            FormName:'Comentario',
            EmitSeccion:this.poBtnSave.EmitSeccion,
            CheckTrabajador:{
                IdCheckTrabajador:0,
                IdTrabajador:0,
                Latitud:0,
                Longitud:0,
                TipoCheck:'',
                FechaReg:'',
                FechaCheckOut:'',
                Imagen:'',
                Comentario:'',
                ComentarioCheckOut:'',
                LatitudOut:'',
                LongitudOut:'',
                Estatus:''
            },
            Tipo:'',
            Check:''
        }
    },
    methods: {
        Limpiar(){
            this.CheckTrabajador={
                IdCheckTrabajador:0,
                IdTrabajador:0,
                Latitud:0,
                Longitud:0,
                TipoCheck:'',
                FechaReg:'',
                FechaCheckOut:'',
                Imagen:'',
                Comentario:'',
                ComentarioCheckOut:'',
                LatitudOut:'',
                LongitudOut:'',
                Estatus:''
            }
            this.Check='';
        },
    },
    created() {
        this.bus.$off("NewModal_"+this.EmitSeccion);
        this.bus.$on("NewModal_"+this.EmitSeccion,(CheckTrabajador,Check)=>{
            this.Limpiar();
            this.CheckTrabajador=CheckTrabajador;
            this.Check=Check;
        });
    },
}
</script>

<style>

</style>