<template>
    <div>
        <div class="row">
            <div class="col-lg-12">
                <label for="">Nombre del Trabajador: </label> <span>{{ Trabajador.Nombre }}</span>
                <!-- <input type="text" v-model="MaterialTrabajo.Nombre" placeholder="Nombre" class="form-control">
                <label id="lblmsuser" style="color:red"><Cvalidation v-if="this.errorvalidacion.Nombre" :Mensaje="'Campo obligatorio'"></Cvalidation></label> -->
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-lg-12">
                <label for="">Fecha *</label>
                <v-date-picker v-model="Trabajador.Fecha" :mode="'typeCalendar'"
					:popover="{
						placement: 'bottom',
						visibility: 'click'
					}"
					:input-props="{
						class: 'form-control cal-black',
						style: 'cursor:pointer;'
					}"
				/>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <label for="">Comentario</label>
                <textarea class="form-control" v-model="Trabajador.Comentario" cols="10" rows="6"></textarea>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:"CambiarEstatus",
    props:["poBtnSave"],
    data() {
        return {
            Modal:true,
            FormName:'CambiarEstatus',
            EmitSeccion:this.poBtnSave.EmitSeccion,
            Trabajador:{
                Nombre:'',
                IdTrabajador:0,
                Tipo:0,
                Fecha:'',
                Comentario:'',
                RegEstatus:'',
                IdUsuario:0
            },
            errorvalidacion:[]
        }
    },
    methods: {
        Guardar(){
            let formData=new FormData();
            let Fecha='';
            if (this.Trabajador.Fecha!='' && this.Trabajador.Fecha!=undefined) {
                let day=this.Trabajador.Fecha.getDate();
                let month=this.Trabajador.Fecha.getMonth()+1;
                let year=this.Trabajador.Fecha.getFullYear();
                Fecha=year+'-'+month+'-'+day;   
            }
            formData.set('IdTrabajador',this.Trabajador.IdTrabajador);
            formData.set('FechaIngreso',Fecha);
            formData.set('FechaEgreso',Fecha);
            formData.set('Comentario',this.Trabajador.Comentario);
            formData.set('Tipo',this.Trabajador.Tipo);
            formData.set('IdUsuario',this.Trabajador.IdUsuario);
            formData.set('RegEstatus',this.Trabajador.Tipo==1 ? 'A' : 'B');
            this.poBtnSave.toast=0;
            this.poBtnSave.disableBtn=true;
            this.$http.post('trabajador/CambiarEstatus',formData,{
                headers:{
                    'Content-Type':'multipart/form-data'
                }
            }).then((res)=>{
                this.poBtnSave.disableBtn=false; 
                this.poBtnSave.toast=1;
                $("#CambiarEstatus").modal("hide");
                this.bus.$emit('List');
            }).catch((err)=>{
                this.poBtnSave.disableBtn=false;
                if (err.response.data.message.errores==undefined) {
                    this.$toast.warning(err.response.data.message);
                }
                else{
                    this.errorvalidacion=err.response.data.message.errores;
                    this.poBtnSave.toast=2;
                }
                // this.poBtnSave.toast=2;
            });
        },
        Limpiar(){
            this.Trabajador={
                Nombre:'',
                IdTrabajador:0,
                Tipo:0,
                Fecha:'',
                Comentario:'',
                RegEstatus:'',
                IdUsuario:0
            }
        },
        // Recuperar(){
        //     this.$http.get('materialtrabajo/recovery',{
        //         params:{
        //             IdMaterialTrabajo:this.MaterialTrabajo.IdMaterialTrabajo
        //         }
        //     }).then((res)=>{
        //         const objres=res.data.data.materialtrabajo;
        //         this.MaterialTrabajo.IdMaterialTrabajo=objres.IdMaterialTrabajo;
        //         this.MaterialTrabajo.IdTrabajador=objres.IdTrabajador;
        //         this.MaterialTrabajo.Nombre=objres.Nombre;
        //         this.MaterialTrabajo.Archivo=objres.Archivo;
        //         this.MaterialTrabajo.Comentario=objres.Comentario;
        //         this.Archivo=objres.Archivo;
        //     })
        // },
        // SubirArchivo(){
        //     const image = this.$refs.file.files[0];

        //     var FileSize = image.size / 1024 / 1024; // in MB
        //     if (FileSize > 5) {
        //         this.$toast.info('Solo se puede subir archivos menores a 5 MB');
        //         const  input  = this.$refs.file;
        //         input .type = 'text'
        //         input .type = 'file';
        //         return false;
        //     }
                        
        //     var allowedExtensions = /(\.pdf|\.PDF)$/i;
        //     if(!allowedExtensions.exec(image.name)){
        //         this.$toast.info('Extensiones permitidas .pdf');
        //         const  input  = this.$refs.file;
        //         input.type = 'text'
        //         input.type = 'file';
        //         this.Archivo='Elegir archivo (5MB)';
        //         return false;
        //     }

        //     this.Archivo=image.name;
        // }
    },
    created() {
        this.bus.$off("NewModal_"+this.EmitSeccion);
        this.bus.$on("NewModal_"+this.EmitSeccion,(data,Id,IdTrabajador,Nombre,Tipo,IdUsuario)=>{
            this.poBtnSave.disableBtn=false;
            this.bus.$off("Save_"+this.EmitSeccion);
            this.bus.$on("Save_"+this.EmitSeccion, () => {
				this.Guardar();
			});
            this.Limpiar();
            if (IdTrabajador!==undefined) {
                this.Trabajador.IdTrabajador=IdTrabajador;
            }
            this.Trabajador.Nombre=Nombre;
            this.Trabajador.Tipo=Tipo;
            this.Trabajador.IdUsuario=IdUsuario;
            // if (Id > 0) {
			// 	this.MaterialTrabajo.IdMaterialTrabajo = Id;
			// 	this.Recuperar();
			// }
        });
    },
}
</script>

<style>

</style>