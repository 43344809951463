<template>
    <div>
        <CHeader :pHeaderProps={}>
            <template slot="Contenido">

                <div class="row justify-content-center mt-4 mb-2">
                    <div class="col-6 col-sm-6">
                        <template v-if="useCheckIn">
                            <button :disabled="Perfil!='usuario app' || (IndexPaqueteCheckIn!=-1 && !doCheckIn)" @click="irServicios" class="btn btn-01 btn-block" style="height: 200px; font-size:1.40rem">
                                <i class="fas fa-tools fa-5x"></i><br>
                                Servicios
                            </button>
                        </template>
                        <template v-else>
                            <button :disabled="Perfil!='usuario app'" @click="irServicios" class="btn btn-01 btn-block" style="height: 200px; font-size:1.40rem">
                                <i class="fas fa-tools fa-5x"></i><br>
                                Servicios
                            </button>
                        </template>
                        
                    </div>
                    <div class="col-6 col-sm-6">
                        <button :disabled="!Online || Perfil!='usuario app'" @click="irAgenda" class="btn btn-01 btn-block" style="height: 200px; font-size:1.40rem">
                            <i class="fas fa-calendar-alt fa-5x"></i><br>
                            Agenda
                        </button>
                    </div>
                </div>

                <div class="row justify-content-center mt-4 mb-2">
                    <div class="col-6 col-sm-6">
                        <!-- <button :disabled="!useCheckIn && !FinDia" @click="IrACheckIn" class="btn btn-01 btn-block" style="height: 200px; font-size:1.40rem"> -->
                        <button :disabled="!useCheckIn || FinDia || Vacacion==1 || IndexPaqueteCheckIn==-1" @click="IrACheckIn" class="btn btn-01 btn-block" style="height: 200px; font-size:1.40rem">
                            <i class="fas fa-clock fa-5x"></i><br>
                            Check-In
                        </button>
                    </div>

                    <div class="col-6 col-sm-6">
                        <!-- <a target="_blank" href="https://universidadhvac.classonlive.com/acceso" class="btn btn-01 btn-block" style="height: 200px; font-size:1.40rem">
                            <img class="mt-2" width="135" src="../../images/Logo-Universidad-hvac.png" alt="">
                            <p class="position-relative mb-0 mt-0">OnLive</p>
                        </a> -->
                        <button @click="IrAUni" class="btn btn-01 btn-block" style="height: 200px; font-size:1.40rem" :disabled="!Online">
                            <img class="mt-2" width="135" src="../../images/Logo-Universidad-hvac.png" alt="">
                        </button>
                        <!-- <button class="btn btn-01 btn-block" style="height: 200px; font-size:1.40rem" disabled>
                            <i class="fad fa-circle fa-5x"></i><br>
                            OnLive
                        </button> -->
                    </div>
                </div>

                <div class="row justify-content-center mt-4 mb-2">
                    <div class="col-6 col-sm-6">
                        <!-- <button @click="irGastos" class="btn btn-01 btn-block" style="height: 200px; font-size:1.40rem">
                            <i class="fas fa-dollar-sign fa-5x"></i><br>
                            Gastos
                        </button> -->
                        <button disabled @click="irGastos" class="btn btn-01 btn-block" style="height: 200px; font-size:1.40rem">
                            <i class="fas fa-dollar-sign fa-5x"></i><br>
                            Gastos
                        </button>
                    </div>
                    <div class="col-6 col-sm-6">
                        <button class="btn btn-01 btn-block" style="height: 200px; font-size:1.40rem" disabled>
                        </button>
                    </div>
                </div>

                
            </template>
        </CHeader>
    </div>
</template>
<script>
import CHeader from "@/views/webapp/components/HeaderWebApp.vue";
export default {
        components:{
            CHeader
        },
        // created(){
        //     const path = window.location.pathname;
        //     if (path.includes('WebApp')) {
        //         loadCSSFile('../../style/css/mainwebapp.css');
        //     }
        // }
        data() {
            return {
                Online:false,
                doCheckIn:false,
                FinDia:false,
                IdTrabajador:0,
                Perfil:'',
                IndexPaqueteCheckIn:0,
                useCheckIn: false,
                Vacacion:0
            }
        },
        methods:{
            async Buscar() {
                // Si el usuario, tiene activo el uso de checkIn Utilizamos la api
                if(this.useCheckIn) { 
                    await this.$http.get('webapp/consultacheckin', {
                        params:{
                            IdTrabajador:this.IdTrabajador,
                        }
                    }).then((res)=>{
                        if (res.data.data.length>0) {
                            let Index=res.data.data.findIndex((element)=>element.TipoCheck=="checkin");
                            if (Index==-1) {
                                this.doCheckIn=false;
                            }
                            else{
                                this.doCheckIn=true;
                                if (res.data.data[Index].FechaCheckOut!==null) {
                                    // this.FinDia=true;
                                    let FiltroCheckIn=res.data.data.filter((element)=>element.TipoCheck=='checkin');
                                    if (FiltroCheckIn.length>1) {
                                        if (FiltroCheckIn[FiltroCheckIn.length-1].FechaCheckOut!==null) {
                                            this.FinDia=true;
                                        }
                                    }
                                    else{
                                        this.doCheckIn=false;
                                    }
                                }
                            }
                        }
                    });
                }else {
                    this.doCheckIn    = false;
                    this.FinDia     = false;
                }
                
            },
            irServicios(){
                this.$router.push({name:'ServiciosWebApp', params: {pOrigen:'Home'}})
            },
            irAgenda(){
                this.$router.push({name:'AgendaWebApp', params: {}})
            },
            irGastos(){
                this.$router.push({name:'ListGastosWebApp', params: {}})
            },
            IrAUni(){
                window.open('https://universidadhvac.classonlive.com/acceso','_blank');
            },
            IrACheckIn(){
                this.$router.push({name:'CheckInWebApp'});
            },
            PingServer(){
                this.$http.get('webapp/ping',{
                    timeout:2000
                }
                ).then(()=>{
                    this.Online=true;
                    this.Buscar();
                }).catch((err)=>{
                    // console.log(err);
                    this.Online=false;
                }).finally(()=>{
                })
            },
        },
        created(){
            let User=JSON.parse(sessionStorage.getItem("user"));
            this.useCheckIn             = parseInt(User.AccesoApp) > 0 ? true : false;
            this.IndexPaqueteCheckIn=User.listaPaquetesPrincipal.findIndex((element)=>element.Paquete=='Check-In');
            this.IdTrabajador=User.IdTrabajador;
            this.Vacacion=User.Vacacion;
            this.Perfil=User.Perfil.toLowerCase();
            this.PingServer();
        },
        mounted(){
           
        }
    }
</script>