var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-row"},[_c('div',{staticClass:"col-12 col-ms-12 col-md-6  col-lg-6 form-group"},[_c('label',[_vm._v("Folio Factura")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.Folio),expression:"Folio"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Factura","readonly":""},domProps:{"value":(_vm.Folio)},on:{"input":function($event){if($event.target.composing){ return; }_vm.Folio=$event.target.value}}})]),_c('div',{staticClass:"col-12 col-ms-12 col-md-6  col-lg-6 form-group"},[_c('label',[_vm._v(" Fecha Cobro ")]),_c('v-date-picker',{attrs:{"popover":{ 
                      placement: 'bottom',
                      visibility: 'click',
                  },"input-props":{
                      class:"form-control  calendar",
                      style:"cursor:pointer;background-color:#F9F9F9",
                      readonly: true,
                    
                  }},model:{value:(_vm.ctaporcobrar.FechaCobro),callback:function ($$v) {_vm.$set(_vm.ctaporcobrar, "FechaCobro", $$v)},expression:"ctaporcobrar.FechaCobro"}}),(this.errorvalidacion.FechaCobro)?_c('Cvalidation',{attrs:{"Mensaje":'Campo obligatorio'}}):_vm._e()],1),_c('div',{staticClass:"col-12 col-ms-12 col-md-12 col-lg-12 form-group"},[_c('label',[_vm._v("Comentario")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.ctaporcobrar.Comentario),expression:"ctaporcobrar.Comentario"}],staticClass:"form-control",attrs:{"cols":"2","rows":"3"},domProps:{"value":(_vm.ctaporcobrar.Comentario)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.ctaporcobrar, "Comentario", $event.target.value)}}}),(this.errorvalidacion.Comentario)?_c('Cvalidation',{attrs:{"Mensaje":'Campo obligatorio'}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }