<template>
    <div>
        <div class="form-group row">
            <div class="col-lg-2" v-show="oDetalle.IdServicio==0 || oDetalle.Indicador!=null">
                <label for="">{{ oDetalle.IdServicio==0 || oDetalle.Indicador!=null ? 'Servicio' : 'Folio'}}</label>
                <select :disabled="oDetalle.Origen=='despacho' || RolUsuario=='Monitoreo' || (RolUsuario.toLowerCase()!='admin' && oDetalle.IdServicio!=0) || (oDetalle.Indicador==null && oDetalle.IdServicio!=0)" class="form-control" v-model="oDetalle.TipoInsercion">
                    <option value="">Selecciona una opción *</option>
                    <option value="1">Offline</option>
                    <option value="2">Antiguo</option>
                </select>
                <label id="lblmsuser" style="color:red"><Cvalidation v-if="this.errorvalidacion.Folio || this.errorvalidacion.TipoInsercion" :Mensaje="'Campo obligatorio'"></Cvalidation></label>
            </div>
            <div :class="oDetalle.IdServicio==0 || oDetalle.Indicador!=null ? 'col-lg-3' : 'col-lg-4'">
                <label for="">{{ oDetalle.IdServicio==0 || oDetalle.Indicador!=null ? '' : 'Folio'}}</label>
                <input type="text" class="form-control mt-1" :readonly="oDetalle.Origen=='despacho' || RolUsuario=='Monitoreo' || (RolUsuario.toLowerCase()!='admin' && oDetalle.IdServicio!=0) || (oDetalle.Indicador==null && oDetalle.IdServicio!=0)" v-model="oDetalle.Folio">
            </div>
            <div class="col-lg-4">
                <label for="">Tipo de servicio *</label>
                <select :disabled="oDetalle.Origen=='despacho' || RolUsuario=='Monitoreo' || (RolUsuario.toLowerCase()!='admin' && oDetalle.IdServicio!=0) || (oDetalle.Indicador==null && oDetalle.IdServicio!=0)" name="" id="" class="form-control" v-model="oDetalle.IdTipoSer">
                    <option value="">Seleccione una opción</option>
                    <option v-for="(item, index) in ListaTipoServicios" :key="index" :value="item.IdTipoSer">{{item.Concepto}}</option>
                </select>
                <label id="lblmsuser" style="color:red"><Cvalidation v-if="this.errorvalidacion.Tipo_Serv" :Mensaje="'Campo obligatorio'"></Cvalidation></label>
            </div>
            <div :class="oDetalle.IdServicio==0 || oDetalle.Indicador!=null ? 'col-lg-3' : 'col-lg-4'">
                <label for="">Fecha *</label>
                <!-- <input type="text" class="form-control" :readonly="oDetalle.Origen=='despacho' || RolUsuario=='Monitoreo'" v-model="oDetalle.Fecha_I"> -->
                <v-date-picker
                v-model="oDetalle.Fecha_I"
					:popover="{
						placement: 'bottom',
						visibility: 'click'
					}"
					:input-props="{
						class: 'form-control  calendar',
						style: 'cursor:pointer;background-color:#F9F9F9',
						readonly: true
					}"
                >

                </v-date-picker>
                <label id="lblmsuser" style="color:red"><Cvalidation v-if="this.errorvalidacion.Fecha_I" :Mensaje="'Campo obligatorio'"></Cvalidation></label>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-lg-12">
                <label for="">Cliente *</label>
                <input type="text" readonly class="form-control" v-model="oDetalle.Cliente">
            </div>
        </div>
        
        <div class="form-group row mt-3">
            <div class="col-lg-12">
                <label for="">Direccion *</label>
                <input type="text" readonly class="form-control" v-model="oDetalle.Direccion">
            </div>
        </div>
        <!-- <div class="col-lg-6">
            <button class="btn btn-01 search mr-2 mt-4" data-toggle="modal" data-target="#ModalForm3" data-backdrop="static" type="button" @click="ListaClientes">Buscar</button>
        </div> -->

        <div class="form-group row mt-3">
            <div class="col-md-12 col-lg-4">
                <label for="">Nombre del Contacto *</label>
                <input type="text" class="form-control" readonly v-model="oDetalle.Contacto">
            </div>
            <div class="col-md-12 col-lg-4">
                <label for="">Email *</label>
                <input type="text" class="form-control" readonly v-model="oDetalle.Econtacto">
            </div>
            <div class="col-md-12 col-lg-4">
                <label for="">Número de Teléfono *</label>
                <input type="text" class="form-control" readonly v-model="oDetalle.Telefono">
            </div>
        </div>

        <div v-if="oDetalle.IdServicio==0 || oDetalle.Indicador!=null" class="form-group row mt-3">
            <div class="col-lg-12">
                <label for="">Estatus del Equipo *</label>
                <div class="row mt-2">
                    <div class="col-lg-2">
                        <div class="form-check form-check-inline RadioOperando">
                            <input :disabled="oDetalle.Origen=='despacho' || RolUsuario=='Monitoreo' || (RolUsuario.toLowerCase()!='admin' && oDetalle.IdServicio!=0)" class="form-check-input" type="radio" id="Operando" v-model="oDetalle.EstatusEquipo" value="Operando">
                            <!-- <span class="RadioOperandoSpan"></span> -->
                            <label class="form-check-label" for="Operando">Operando</label>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="form-check form-check-inline RadioObservacion">
                            <input :disabled="oDetalle.Origen=='despacho' || RolUsuario=='Monitoreo' || (RolUsuario.toLowerCase()!='admin' && oDetalle.IdServicio!=0)" class="form-check-input" type="radio" id="Observacion" v-model="oDetalle.EstatusEquipo" value="En Observacion">
                            <label class="form-check-label" for="Observacion">Operando Con Observacion</label>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="form-check form-check-inline RadioFueraServicio">
                            <input :disabled="oDetalle.Origen=='despacho' || RolUsuario=='Monitoreo' || (RolUsuario.toLowerCase()!='admin' && oDetalle.IdServicio!=0)" class="form-check-input" type="radio" id="FueraServicio" v-model="oDetalle.EstatusEquipo" value="Fuera de Servicio">
                            <label class="form-check-label" for="FueraServicio">Fuera de Servicio</label>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="form-check form-check-inline RadioDesconocido">
                            <input :disabled="oDetalle.Origen=='despacho' || RolUsuario=='Monitoreo' || (RolUsuario.toLowerCase()!='admin' && oDetalle.IdServicio!=0)" class="form-check-input" type="radio" id="Desconocido" v-model="oDetalle.EstatusEquipo" value="No Revisado">
                            <label class="form-check-label" for="Desconocido">Desconocido</label>
                        </div>
                    </div>
                </div>
                <label id="lblmsuser" style="color:red"><Cvalidation v-if="this.errorvalidacion.EstatusEquipo" :Mensaje="'Campo obligatorio'"></Cvalidation></label>
            </div>
        </div>

        <div class="form-group row">
            <div class="col-lg-12">
                <label for="">Observaciones del Equipo</label>
                <textarea :disabled="oDetalle.Origen=='despacho' || RolUsuario=='Monitoreo' || (RolUsuario.toLowerCase()!='admin' && oDetalle.IdServicio!=0) || (oDetalle.Indicador==null && oDetalle.IdServicio!=0)" name="" id="" cols="30" rows="10" class="form-control" v-model="oDetalle.Comentario2"></textarea>
            </div>
        </div>

        <div v-show="oDetalle.IdServicio==0 || oDetalle.Indicador!=null" class="form-group row mt-3">
            <div class="col-lg-6">
                <label for="">Reporte de Servicio</label>
                <div class="custom-file-input-image">
                    <input :disabled="oDetalle.Origen=='despacho' || RolUsuario=='Monitoreo' || (RolUsuario.toLowerCase()!='admin' && oDetalle.IdServicio!=0)" @change="uploadImage('Reporte')" type="file" accept="application/pdf" ref="file" class="custom-file-input" id="validatedCustomFile" required>
                    <input :disabled="oDetalle.Origen=='despacho' || RolUsuario=='Monitoreo' || (RolUsuario.toLowerCase()!='admin' && oDetalle.IdServicio!=0)" type="text" v-model="ReporteServicio" class="form-control">
                    <button type="button" class=""><i class="fas fa-paperclip"></i></button>
                </div>
            </div>

            <div class="col-lg-6">
                <label for="">Evidencia de Servicio</label>
                <div class="custom-file-input-image">
                    <input :disabled="oDetalle.Origen=='despacho' || RolUsuario=='Monitoreo' || (RolUsuario.toLowerCase()!='admin' && oDetalle.IdServicio!=0)" @change="uploadImage('Evidencia')" type="file" accept="application/pdf" ref="file2" class="custom-file-input" id="validatedCustomFile2" required>
                    <input :disabled="oDetalle.Origen=='despacho' || RolUsuario=='Monitoreo' || (RolUsuario.toLowerCase()!='admin' && oDetalle.IdServicio!=0)" type="text" v-model="ReporteEvidencia" class="form-control">
                    <button type="button" class=""><i class="fas fa-paperclip"></i></button>
                </div>
            </div>
        </div>
        <!-- <Cliente :TipoModal='1'></Cliente> -->
    </div>
    
    
</template>

<script>
import Cbtnsave from '@/components/Cbtnsave.vue';
import Cvalidation from '@/components/Cvalidation.vue';
import Cmodal from '@/components/Cmodal.vue';
// import Cliente from '@/components/Ccliente.vue';

export default {
    components:{
        Cbtnsave,
        Cvalidation,
        Cmodal
        // Cliente
    },
    props:[
        "IdEquipo",
        "Edicion",
        "poBtnSave",
        "RolUsuario",
        "IdCliente",
        "IdClienteS",
        "IdSucursal",
        "Cliente",
        "Direccion",
        "Contacto",
        "Econtacto",
        "Telefono"],
    data() {
        return {
            oDetalle:{
                Folio:"",
                Cliente:"",
                IdServicio:0,
                Fecha_I:"",
                Fecha_F:"",
                Observaciones:"",
                ComentarioFin:"",
                Materiales:"",
                Direccion:"",
                Contacto:"",
                Econtacto:"",
                Origen:"",
                Servicio:"",
                NomCli:"",
                Telefono:"",
                Comentario2:"",
                Comentario:"",
                IdEquipo:0,
                IdTipoSer:"",
                Indicador:null,
                ReporteServicio:"",
                ReporteEvidencia:"",
                TipoInsercion:"",
                EstatusEquipo:""
            },
            oClientesSuc:{},
            ListaTipoServicios:[],
            errorvalidacion:[],
            ReporteServicio:'Elegir Archivo (5MB)',
            ReporteEvidencia:'Elegir Archivo (5MB)',
            FechaServicioReciente:'' 
        }
    },
    methods: {
        // ListaClientes(){
        //     this.bus.$emit('ListCcliente');
        // },
        async ListaTipoServicio(){
            await this.$http.get(
                'tiposervicio/get',
                {
                    params:{Nombre:'',Entrada:50,pag:0, RegEstatus:'A'}
                }
            ).then( (res) => {
              this.ListaTipoServicios =res.data.data.tiposervicio;
            });
        },
        GuardarServicio(){
            this.poBtnSave.toast=0; 
            this.poBtnSave.disableBtn=true;
            let ArchivoReporte=this.$refs.file.files[0];
            let ArchivoEvidencia=this.$refs.file2.files[0];
            // PARA CONVERTIR LA FECHA DE INICIO
            let FechaI = "";
			if (this.oDetalle.Fecha_I != "") {
				let day = this.oDetalle.Fecha_I.getDate();
				let month = this.oDetalle.Fecha_I.getMonth()+1;
				let year = this.oDetalle.Fecha_I.getFullYear();
				FechaI = year + "-" + month + "-" + day;
			}
            // EL RESTO DEL FORMULARIO
            let formData=new FormData();
            formData.set('IdServicio',this.oDetalle.IdServicio);
            formData.set('Folio',this.oDetalle.Folio);
            formData.set('Tipo_Serv',this.oDetalle.IdTipoSer);
            formData.set('Fecha_I',FechaI);
            formData.set('Cliente',this.oDetalle.Cliente);
            formData.set('IdCliente',this.IdCliente);
            formData.set('IdClienteS',this.IdClienteS);
            formData.set('Econtacto',this.oDetalle.Econtacto);
            formData.set('Contacto',this.oDetalle.Contacto);
            formData.set('ComentarioFin',this.oDetalle.ComentarioFin);
            formData.set('Direccion',this.oDetalle.Direccion);
            formData.set('IdEquipo',this.IdEquipo);
            formData.set('IdSucursal',this.IdSucursal);
            formData.set('ReporteServicio',this.oDetalle.ReporteServicio);
            formData.set('ReporteEvidencia',this.oDetalle.ReporteEvidencia);
            formData.set('EstatusEquipo',this.oDetalle.EstatusEquipo);
            formData.set('TipoInsercion',this.oDetalle.TipoInsercion);
            formData.set('FechaServicioReciente',this.FechaServicioReciente);
            formData.append('ArchivoReporte',ArchivoReporte);
            formData.append('ArchivoEvidencia',ArchivoEvidencia);
            this.$http.post('monitoreo/servicio/post',formData,{
                headers:{
                    'Content-Type':'multipart/form-data'
                }
            })
            .then((res)=>{
                this.$toast.success("Información guardada");
                this.poBtnSave.toast=0; 
                this.poBtnSave.disableBtn=false;
                $('#Servicios').modal('hide');
                // this.bus.$emit('Listar');
                this.bus.$emit('ActualizarEquipo',res.data.data.equipo);
            }).
            catch((err)=>{
                this.errorvalidacion=err.response.data.message.errores;
                this.poBtnSave.disableBtn=false;
                this.poBtnSave.toast=2; 
            });
        },
        Limpiar(){
            this.poBtnSave.toast=0; 
            const  input  = this.$refs.file;
            input .type = 'text'
            input .type = 'file';

            const  input2  = this.$refs.file2;
            input2 .type = 'text'
            input2 .type = 'file';

            this.oDetalle.Folio="";
            // this.oDetalle.Cliente="";
            this.oDetalle.IdServicio=0;
            this.oDetalle.Fecha_I="";
            this.oDetalle.Fecha_F="";
            this.oDetalle.Observaciones="";
            this.oDetalle.ComentarioFin="";
            this.oDetalle.Materiales="";
            // this.oDetalle.Direccion="";
            // this.oDetalle.Contacto="";
            // this.oDetalle.Econtacto="";
            this.oDetalle.Origen="";
            this.oDetalle.Servicio="";
            this.oDetalle.NomCli="";
            // this.oDetalle.Telefono="";
            this.oDetalle.Comentario2="";
            this.oDetalle.Comentario="";
            this.oDetalle.IdEquipo=0;
            this.oDetalle.IdTipoSer="";
            this.oDetalle.TipoInsercion="";
            this.oDetalle.ReporteServicio="";
            this.oDetalle.ReporteEvidencia="";
            this.oDetalle.EstatusEquipo="";
            this.oDetalle.Indicador=null;
            this.FechaServicioReciente='';

            this.ReporteServicio="Elegir Archivo (5MB)";
            this.ReporteEvidencia="Elegir Archivo (5MB)";
            this.errorvalidacion=[];
        },
        uploadImage(TipoArchivo){
            let referencia=null;
            switch (TipoArchivo) {
                case 'Reporte':
                    referencia = this.$refs.file;
                    break;
            
                case 'Evidencia':
                    referencia = this.$refs.file2;
                    break;
            }
            let image = referencia.files[0];

            var FileSize = image.size / 1024 / 1024; // in MB
            if (FileSize > 5) {
                this.$toast.info('Solo se puede subir archivos menores a 5 MB');
                const  input  = referencia;
                input .type = 'text'
                input .type = 'file';
                return false;
            }
                        
            var allowedExtensions = /(\.pdf|\.PDF)$/i;
            if(!allowedExtensions.exec(image.name)){
                this.$toast.info('Extenciones permitidas .pdf');
                const  input  = referencia;
                input.type = 'text'
                input.type = 'file';
                switch (TipoArchivo) {
                case 'Reporte':
                    this.ReporteServicio='Elegir archivo (5MB)';
                    break;
            
                case 'Evidencia':
                    this.ReporteEvidencia='Elegir archivo (5MB)';
                    break;
                }
                return false;
            }

            switch (TipoArchivo) {
                case 'Reporte':
                    this.ReporteServicio=image.name;
                    break;
            
                case 'Evidencia':
                    this.ReporteEvidencia=image.name;
                    break;
            }
        }
    },
    created(){
        this.bus.$off('NuevoServicioMonitoreo');
        this.bus.$on('NuevoServicioMonitoreo',(lista)=>{
            // this.poBtnSave.ShowSave=true;
            // this.poBtnSave.ShowCancel=true;
            this.Limpiar();
            if (lista.length>0) {
                this.FechaServicioReciente=lista[0].Fecha_I.replace(/-/g, "\/");
                this.oDetalle.Fecha_I=new Date(this.FechaServicioReciente);
            }
            else{
                // let NuevaFecha = localStorage.getItem("fechacalendario");
				// let formatedDate = NuevaFecha.replace(/-/g, "\/");
				this.oDetalle.Fecha_I = new Date();
            }
            this.bus.$emit("MostrarBotonF",true);
            this.oDetalle.IdEquipo=this.IdEquipo;
            this.oDetalle.IdCliente=this.IdCliente;
            this.oDetalle.IdClienteS=this.IdClienteS;
            this.oDetalle.Cliente=this.Cliente;
            this.oDetalle.Direccion=this.Direccion;
            this.oDetalle.Contacto=this.Contacto;
            this.oDetalle.Econtacto=this.Econtacto;
            this.oDetalle.Telefono=this.Telefono;
            this.poBtnSave.disableBtn=false;
            this.bus.$off('Save');
            this.bus.$on('Save',()=>{
                this.GuardarServicio();
            });
        });
        this.bus.$off('Recuperar');
        this.bus.$on('Recuperar',(obj,lista)=>{
            this.Limpiar();
            // this.oDetalle=obj;
            this.oDetalle.Cliente=obj.Cliente;
            this.oDetalle.Comentario=obj.Comentario;
            this.oDetalle.Comentario2=obj.Comentario2;
            this.oDetalle.ComentarioFin=obj.ComentarioFin;
            this.oDetalle.Direccion=obj.Direccion;
            this.oDetalle.EstatusEquipo=obj.EstatusEquipo;
            this.oDetalle.Fecha_F=obj.Fecha_F;
            this.oDetalle.Folio=obj.Folio;
            this.oDetalle.IdEquipo=obj.IdEquipo;
            this.oDetalle.IdServicio=obj.IdServicio;
            this.oDetalle.IdTipoSer=obj.IdTipoSer;
            this.oDetalle.Indicador=obj.Indicador;
            this.oDetalle.Materiales=obj.Materiales;
            this.oDetalle.NomCli=obj.NomCli;
            this.oDetalle.Observaciones=obj.Observaciones;
            this.oDetalle.Origen=obj.Origen;
            this.oDetalle.ReporteEvidencia=obj.ReporteEvidencia;
            this.oDetalle.ReporteServicio=obj.ReporteServicio;
            this.oDetalle.Servicio=obj.Servicio;
            this.oDetalle.Sucursal=obj.Sucursal;
            this.oDetalle.TipoInsercion=obj.TipoInsercion;
            this.oDetalle.Trabajador=obj.Trabajador;
            this.oDetalle.Contacto=this.Contacto;
            this.oDetalle.Econtacto=this.Econtacto;
            this.oDetalle.Telefono=this.Telefono;
            if (lista.length>0) {
                this.FechaServicioReciente=lista[0].Fecha_I
            }
            if (obj.Indicador==null || this.RolUsuario=='Monitoreo' || this.RolUsuario.toLowerCase()!='admin') {
                this.bus.$emit("MostrarBotonF",false);
                // this.poBtnSave.ShowSave=false;
                // this.poBtnSave.ShowCancel=false;
            }
            else{
                this.bus.$emit("MostrarBotonF",true);
            }
            // REFORMATEAR LA FECHA DE INICIO
            let formatedDate = obj.Fecha_I.replace(/-/g, "\/");
            // console.log(formatedDate);
		    this.oDetalle.Fecha_I = new Date(formatedDate);
            this.ReporteServicio=obj.ReporteServicio;
            this.ReporteEvidencia=obj.ReporteEvidencia;
            // console.log(this.oDetalle.Fecha_I);
            this.bus.$off('Save');
            this.bus.$on('Save',()=>{
                this.GuardarServicio();
            });
        });
        this.bus.$off('RecuperarCliente');
        this.bus.$on('RecuperarCliente',(cliente)=>{
            this.oClientesSuc=cliente;
        });
        this.bus.$off("MostrarBoton");
        this.bus.$on("MostrarBoton",(valor)=>{
            this.bus.$emit("MostrarBotonF",valor);
        });
        // this.bus.$off('Save');
        // this.bus.$on('Save',()=>{
        //     this.Guardar();
        // });
        // this.poBtnSave.disableBtn=false;
        this.ListaTipoServicio();
        
    }
}
</script>