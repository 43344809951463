<template>
    <div>
        <CHeader :pHeaderProps="HeaderProps">
            <template slot="Contenido">
                <div class="row">
                    
                    <div class="col-12 col-sm-12 mt-3" @click="CambiarEstatus('Operando')">
                        <div class="card card-01 shadow" style="height: 100px;">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-8 col-sm-8">
                                        <h2>Operando sin Problemas</h2>
                                    </div>
                                    <div class="col-4 col-sm-4 m-0 p-0">
                                        <div class="EquipoIndicadorOperando"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 mt-5 pt-4" @click="CambiarEstatus('En Observacion')">
                        <div class="card card-01 shadow" style="height: 100px;">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-8 col-sm-8">
                                        <h2>Operando con Observación</h2>
                                    </div>
                                    <div class="col-4 col-sm-4 m-0 p-0">
                                        <div class="EquipoIndicadorObservacion"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 mt-5 pt-4" @click="CambiarEstatus('Fuera de Servicio')">
                        <div class="card card-01 shadow" style="height: 100px;">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-8 col-sm-8">
                                        <h2>Fuera de <br> Operación</h2>
                                    </div>
                                    <div class="col-4 col-sm-4 m-0 p-0">
                                        <div class="EquipoIndicadorFueraServicio"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </CHeader>
    </div>
</template>

<script>
import CHeader from "@/views/webapp/components/HeaderWebApp.vue";
export default {
    props:['pEquipo'],
    components:{CHeader},
    data() {
        return {
            HeaderProps:{
                ShowSubHeader:true,
                ShowTecnico:false,
                ShowFechaHoy:false,
                ShowMenuDropdown:false,
                ShowFotoPerfil:false,
                ShowReturn:true,
                TextoHeader:'Estatus del equipo',
                ShowLogo:false,
                TextoCentroSubHeader:''
            },
            Equipo:{},
        }
    },
    methods: {
        CambiarEstatus(Estatus){
            this.$swal({
                title: 'Cambiar Estatus',
                text: '¿Desea cambiar el estatus del equipo?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si',
                cancelButtonText: 'No',
                showCloseButton: true,
                showLoaderOnConfirm: true
            }).then((result)=>{
                if(result.value){
                    let formData=new FormData();
                    formData.set('IdEquipo',this.Equipo.IdEquipo);
                    formData.set('Status',Estatus);
                    this.$http.post('webapp/setequipoestatus',formData
                    ).then((res)=>{
                        this.$router.push({name:'EquiposServicioWebApp'});
                        this.$toast.success("Estatus del Equipo Cambiado Correctamente");
                    }).catch((err)=>{
                        this.$toast.warning(err.response.data.message);
                    });
                }
            });
        },
        Regresar(){
            this.$router.push({name:'EquiposServicioWebApp'});
        }
    },
    created() {
        this.bus.$off('EmitReturn');
        this.bus.$on('EmitReturn',()=>{
            this.Regresar();
        });
    },
    mounted() {
        this.Equipo=this.pEquipo;
        this.HeaderProps.TextoCentroSubHeader=this.Equipo.Nequipo;
    },
}
</script>

<style>

</style>