<!-- REORGANIZAR TODAS LAS FUNCIONES DE LA LISTA DE LAS ACTIVIDADES -->
<!-- AGREGAR METODOS A LOS BOTONES -->
<template>
    <div>
        <div class="row  justify-content-center">
            <div :class="RolUsuario=='Admin' ? 'col-lg-6 mb-3' : 'col-lg-12'">
                <label>Nombre de la Oportunidad</label>
                <input  class="form-control" v-model=" objoportunidad.Nombre" placeholder="Nombre">
                <Cvalidation v-if="this.errorvalidacion.Nombre" :Mensaje="errorvalidacion.Nombre[0]"></Cvalidation>
            </div>

            <div v-if="RolUsuario=='Admin'" class="col-lg-6 mb-3">
                <label>Vendedor o Responsable</label>
                <select @change="listartipo(objoportunidad.IdVendedor)"  v-model="objoportunidad.IdVendedor" class="form-control">
                    <option value="">Seleccione una opción</option>
                    <option v-for="(item, index) in Listatipoproceso" :key="index" :value="item.IdUsuario">{{item.Nombre}}</option>
                </select>
                <Cvalidation v-if="this.errorvalidacion.Vendedor" :Mensaje="errorvalidacion.Vendedor[0]"></Cvalidation>
            </div>
    
            <div :class="BuscarCliente ? 'col-lg-10 mb-3':'col-lg-12 mb-3'">
                <label >Contacto (Empresa)</label>
                <input  class="form-control" readonly="readonly" v-model=" objoportunidad.Cliente" placeholder="Cliente">
                <Cvalidation v-if="this.errorvalidacion.Cliente" :Mensaje="errorvalidacion.Cliente[0]"></Cvalidation>
            </div>
    
            <div v-if="BuscarCliente" class="col-lg-2 mb-3">
                <div class="form-inline">
                    <button @click="ListarCli"  data-toggle="modal" data-target="#OporModalCliente"  data-backdrop="static" type="button" class="btn btn-01 search mt-3b">Buscar</button>
                </div>
            </div>
            
            <div class="col-lg-12 mb-3">
                <label>Sucursal</label>
                <input  class="form-control" readonly="readonly" v-model=" objoportunidad.Sucursal" placeholder="Sucursal">
                <Cvalidation v-if="this.errorvalidacion.Sucursal" :Mensaje="errorvalidacion.Sucursal[0]"></Cvalidation>
            </div>

            <div class="col-lg-4 mb-3">
                <label for="">Folio de Servicio (Opcional)</label>
                <input class="form-control" placeholder="Folio" type="text" v-model="objoportunidad.Folio">
            </div>
            <div class="col-lg-4 mb-3">
                <label for="">Monto Oportunidad</label>
                <vue-numeric
					class="form-control "
					currency="$"
					separator=","
                    placeholder="$0.00"
                    :precision="2"
                    v-model="objoportunidad.MontoPropuesta"
				></vue-numeric>
            </div>
            <div class="col-lg-4">
                <label>Estatus</label>
                <select id="" class="form-control" v-model="objoportunidad.Estatus">
                    <option :value="''">Seleccione un estado</option>
                    <option :value="'1'">Activa</option>
                    <option :value="'2'">Ganada</option>
                    <option :value="'3'">Perdida</option>
                </select>
                <Cvalidation v-if="this.errorvalidacion.Estado" :Mensaje="errorvalidacion.Estado[0]"></Cvalidation>
            </div>

        </div>
        <div class="form-row" v-if="objoportunidad.IdOportunidad!=0">
            <table class="table-01 text-nowrap mt-2">
                <thead>
                    <tr>
                        <th>Actividad</th>
                        <th>Fecha</th>
                        <th>Tipo</th>
                        <th class="text-right">
                            <button
								v-b-tooltip.hover.left
								type="button"
								data-backdrop="static"
								data-toggle="modal"
								data-target="#Seguimiento"
								data-keyboard="false"
								class="mr-2 btn-obs"
								title="Agregar actividad"
                                @click="NuevaActividad(0)"
								>
								<i
								class="fas fa-plus"></i>
								</button>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item,index) in ListaActividades" :key="index">
                        <td>{{ $limitCharacters(item.Actividad,40) }}</td>
                        <td> <i class="fa fa-calendar"></i> {{ $getCleanDate(item.Fecha,false) }}</td>
                        <td>{{ $capitalize(item.NombreTipo) }}</td>
                        <td class="text-right">
                            <button
								v-b-tooltip.hover.left
								type="button"
								data-backdrop="static"
								data-toggle="modal"
								data-target="#Seguimiento"
								data-keyboard="false"
								class="mr-1 btn btn-table"
								title="Cambiar actividad"
                                @click="NuevaActividad(item.IdSeguimientoCliente)"
								>
								<i
								class="fa fa-pencil"></i>
							</button>
								
                            <button v-if="item.Archivo!=''"
								v-b-tooltip.hover.left
								type="button"
								data-backdrop="static"
								data-keyboard="false"
								class="btn btn-table mr-1"
								title="consultar evidencia 1"
                                @click="open_file(item.Archivo)"
								>
								<i 
								class="fas fa-file-pdf"></i>
							</button>

                            <button v-if="item.Archivo2!=''"
								v-b-tooltip.hover.left
								type="button"
								data-backdrop="static"
								data-keyboard="false"
								class="btn btn-table mr-1"
								title="consultar evidencia 2"
                                @click="open_file(item.Archivo2)"
								>
								<i 
								class="fas fa-file-pdf"></i>
							</button>

                            <button
								v-b-tooltip.hover.left
								type="button"
								data-backdrop="static"
								data-keyboard="false"
								class="btn btn-table-da mr-1"
								title="Borrar"
                                @click="EliminarActividad(item.IdSeguimientoCliente)"
								>
								<i 
								class="fas fa-trash"></i>
							</button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <Cpagina :Filtro="Filtro" :Entrada="Filtro.Entrada" @Pagina="Filtrar"></Cpagina>
        </div>
        <Modal :NameModal="'Seguimiento'" :pEmitSeccion="'Seguimiento'" :Showbutton="false" :size="'modal-md'" :Nombre="'Programar Actividad'" :poBtnSave="poBtnSave2">
            <template slot="Form">
                <FormActividades :oOportunidad="objoportunidad" :poBtnSave="poBtnSave2"></FormActividades>
            </template>
        </Modal>
    </div>
    
</template>
<script>
//El props Id es cuando no es modal y se mando con props
//El export de btnsave es por si no se usa el modal
import Cbtnsave from '@/components/Cbtnsave.vue'
import Cvalidation from '@/components/Cvalidation.vue'
import Modal from '@/components/Cmodal.vue'
import FormActividades from '../calendario/form.vue'
import Cpagina from '@/components/Cpagina.vue';
// import Clist from '@/components/Clist.vue'

export default {
    name:'Form',
        props:['IdOportunidad','poBtnSave','OClienteS'],
    data() {
        return {
            Modal:true,//Sirve pra los botones de guardado
            FormName:'objoportunidad',//Sirve para donde va regresar
            poBtnSave2:{
                isModal:true,
                toast:0,
                disableBtn:false,
                nombreModal:"Seguimiento",
                EmitSeccion:"Seguimiento"
            },
            Pag:0,
            Filtro:{
                Nombre:'',
                TotalItem:0,
                Pagina:1,
                Entrada:6
            },
            EmitSeccion:this.poBtnSave.EmitSeccion,
            RolUsuario:'',
            objoportunidad:{
                Nombre:"",
                IdVendedor:"",
                IdClienteS:"",
                IdTipoP:"",
                IdOportunidad:'',
                Sucursal:'',
                Cliente:'',
                Estatus:"",
                Folio:'',
                IdCliente:"",
                MontoPropuesta:""
            },
            Ruta:'',
            urlApi:"crmoportunidad/recovery",
            urlApiVendedor:"trabajador/ListTrabRolQuery",
            urlApiVendedorNuevo:"vendedores/get",
            errorvalidacion:[],
            Listatipoproceso:[],
            Listatipos:[],
            ListaActividades:[],
            perfil :['Vendedor','Gerente de ventas'],
            BuscarCliente:true,
            oClienteS:{}
        }
    },
    components:{
        Cbtnsave,
        Cvalidation,
        FormActividades,
        Modal,
        Cpagina
    },
    methods :
    {
        NuevaActividad(Id){
            if (Id!=0) {
                this.bus.$emit('NewModal_Seguimiento',true,Id);
            }
            else{
                this.bus.$emit('NewModal_Seguimiento',true);
            }
            
        },
       async Guardar()
        {
            if (Object.keys(this.OClienteS)!=0) {
                if (this.objoportunidad.IdOportunidad==0) {
                    let Obj=JSON.parse(sessionStorage.getItem("IdSaved"));
			        Obj.Oportunidades++;
			        sessionStorage.setItem("IdSaved",JSON.stringify(Obj));   
                }
        }
            //deshabilita botones
            this.poBtnSave.toast=0;
            this.poBtnSave.disableBtn=true;
            let formData = new FormData();
            formData.set('IdOportunidad',this.objoportunidad.IdOportunidad);
            formData.set('Nombre',this.objoportunidad.Nombre);
            if (this.RolUsuario!="Admin") {
                formData.set('IdVendedor',JSON.parse(sessionStorage.getItem("user")).IdUsuario);
            }
            else{
                formData.set('IdVendedor',this.objoportunidad.IdVendedor);
            }
            formData.set('IdClienteS',this.objoportunidad.IdClienteS);
            formData.set('IdTipoP',this.objoportunidad.IdTipoP);
            formData.set('IdCliente',this.objoportunidad.IdCliente);
            formData.set('Folio',this.objoportunidad.Folio);
            formData.set('Estatus',this.objoportunidad.Estatus);
            formData.set('MontoPropuesta',this.objoportunidad.MontoPropuesta);

            await this.$http.post(
                'crmoportunidad/post',
                formData,
                {
                headers: {
                    'Content-Type': 'multipart/form-data'

                }
                },
            ).then( (res) => {
                // MODIFICAR LA VARIABLE DE SESION EN CASO DE ESTAR DESDE CONTACTO

                this.poBtnSave.disableBtn=false;
                this.poBtnSave.toast=1;
                $('#ModalForm').modal('hide');
                this.bus.$emit('List');
            }).catch( err => {
                this.errorvalidacion=err.response.data.message.errores;
                this.poBtnSave.disableBtn=false;
                this.poBtnSave.toast=2;
            });
        },
        Limpiar()
        {
            this.objoportunidad={
                Nombre:"",
                IdVendedor:"",
                IdClienteS:"",
                IdTipoP:"",
                IdOportunidad:'',
                Sucursal:'',
                Cliente:'',
                Estatus:"",
                Folio:'',
                IdCliente:"",
                MontoPropuesta:""
            };
            this.errorvalidacion=[""],
            this.ListaActividades=[],
            this.Ruta=''
        },
        get_one()
        {
            this.$http.get(
                this.urlApi,
                {
                    params:{IdOportunidad: this.objoportunidad.IdOportunidad}
                }
            ).then( (res) => {
                this.objoportunidad.IdOportunidad= res.data.data.oportunidad.IdOportunidad;
                this.objoportunidad.Nombre=res.data.data.oportunidad.Nombre;
                this.objoportunidad.IdTipoP=res.data.data.oportunidad.IdTipoP;
                this.objoportunidad.IdVendedor=res.data.data.oportunidad.IdVendedor;
                this.listartipo(this.objoportunidad.IdVendedor);
                this.objoportunidad.IdClienteS=res.data.data.oportunidad.IdClienteS;
                this.objoportunidad.Sucursal=res.data.data.oportunidad.Sucursal;
                this.objoportunidad.Cliente=res.data.data.oportunidad.Cliente;
                this.objoportunidad.IdCliente=res.data.data.oportunidad.IdCliente;
                this.objoportunidad.Folio=res.data.data.oportunidad.Folio;
                this.objoportunidad.MontoPropuesta=res.data.data.oportunidad.MontoPropuesta;
                // MARCAR EL ESTATUS DE LA OPORTUNIDAD
                switch (res.data.data.oportunidad.Estado) {
                    case "Activa":
                        this.objoportunidad.Estatus=1;
                        break;
                    case "Ganada":
                        this.objoportunidad.Estatus=2;
                        break;
                    case "Perdida":
                        this.objoportunidad.Estatus=3;
                        break;
                }
            });
            this.ObtenerListaActividades();
        },
        ListarCli()
        {
            this.$emit('Listar');
        },
        SeleccionarCliente(objeto,obj)
        {
            this.objoportunidad.IdClienteS=objeto.IdClienteS;
            this.objoportunidad.Sucursal=objeto.Nombre;
            this.objoportunidad.Cliente=obj.Nombre;
            this.objoportunidad.IdCliente=obj.IdCliente;
        },
        ListaVendedor()
        {
            this.$http.get(
                this.urlApiVendedorNuevo,
                {
                    params:{
						RegEstatus: 'A'
					}
                }
            ).then( (res) => {
              this. Listatipoproceso = res.data.data.Vendedores;
            });
        },
        listartipo(Id)
        {   if (Id>0)
            {
                this.Listatipos = [];
                this.$http.get(
                    'crmprocesovendedor/listasig',
                    {
                        params:{IdTrabajador: Id}
                    }
                ).then( (res) => {
                    let Listatipos2= res.data.data.asignados;

                    Listatipos2.forEach(element => {
                        if(element.Estatus === 'true'){
                        this.Listatipos.push(element);
                        }
                    });
                });
            }
        },
        ObtenerListaActividades(){
            this.$http.get('crm/actividadoportunidad/get',{
                params:{
                    IdOportunidad:this.objoportunidad.IdOportunidad,
                    Entrada:this.Filtro.Entrada,
                    pag:this.Filtro.Pagina
                }
            }
            ).then((res)=>{
                this.ListaActividades=res.data.data.actividades;
                this.Filtro.Entrada=res.data.data.pagination.PageSize;
                this.Filtro.TotalItem=res.data.data.pagination.TotalItems;
                this.Ruta=res.data.ruta;
            });
        },
        open_file(Nombre) {
			let pdfWindow = window.open(this.Ruta + Nombre);
			pdfWindow.document.write(
				"<iframe width='100%' height='100%' src='" +
					this.Ruta +
					Nombre +
					"'></iframe>"
			);
		},
        Filtrar(){
            this.ObtenerListaActividades();
        },
        EliminarActividad(Id){
            this.$swal({
				title: "Esta seguro que desea eliminar este dato?",
				text: "No se podra revertir esta acción",
				type: "warning",
				showCancelButton: true,
				confirmButtonText: "Si",
				cancelButtonText: "No, mantener",
				showCloseButton: true,
				showLoaderOnConfirm: true
			}).then(result => {
				if (result.value) {
					this.$toast.success("Información eliminada");

					this.$http.delete("crmseguimiento/" + Id).then(res => {
						this.bus.$emit("ListaOportunidadxActividad");
					});
				}
			});
        }
    },
    created() {
        this.ListaVendedor();
        this.RolUsuario=JSON.parse(sessionStorage.getItem("user")).Perfil;
        if (Object.keys(this.OClienteS)!=0) {
            this.oClienteS=this.OClienteS;
            this.BuscarCliente=false;
        }
        this.bus.$off('SeleccionarClienteOportunidad');
        this.bus.$on('SeleccionarClienteOportunidad',(oSucursal,ocliente)=>
        {
            this.SeleccionarCliente(oSucursal,ocliente);
        });
        this.bus.$off('ListaOportunidadxActividad');
        this.bus.$on('ListaOportunidadxActividad',()=>{
            this.ObtenerListaActividades();
        })
        this.bus.$off('NewModal_'+this.EmitSeccion);

        //Este es para modal
        this.bus.$on('NewModal_'+this.EmitSeccion,(data,Id)=>
        {
            this.poBtnSave.disableBtn=false;
            this.bus.$off('Save_'+this.EmitSeccion);
            this.bus.$on('Save_'+this.EmitSeccion,()=>
            {
                this.Guardar();
            });

            this.Limpiar();
            if (!this.BuscarCliente) {
                this.objoportunidad.IdClienteS=this.oClienteS.IdClienteS;
                this.objoportunidad.Sucursal=this.oClienteS.Nombre;
                this.objoportunidad.Cliente=this.oClienteS.Empresa;
                this.objoportunidad.IdCliente=this.oClienteS.IdCliente;
            }
            if (Id>0)
            {
                this.objoportunidad.IdOportunidad=Id;
                this.get_one();
            }
            this.bus.$emit('Desbloqueo',false);
        });

        if (this.Id!=undefined)
        {
            this.objoportunidad.IdOportunidad=this.Id;
            this.get_one();
        }
    }
}
</script>
