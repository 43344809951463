<template>
    <div>
        <CHeader :pHeaderProps="HeaderProps">
            <template slot="Contenido" v-if="ServicioWQr">
                <div v-if="VerQRScan" class="qr-camera p-0 m-0">
                    <StreamBarcodeReader @decode="LeerQREquipo"></StreamBarcodeReader>
                </div>
                <!-- ANOTE: AQUI VA LISTA DE EQUIPOS -->
                <CLoader :pConfigLoad="ConfigLoad">
                    <template slot="BodyFormLoad">
                        <div class="row" style="min-height:64vh;">
                            <div class="col-lg-12">
                                <div class="row p-2" v-for="(item,index) in ListaEquipos" :key="index" @click="PonerDropdown(index)">
                                    <div class="col-lg-12">
                                        <div class="dropdown show">
                                            <div :id="'equipo'+index" role="button" :class="SetClaseBorde(item.Status)+' dropdown-toggle toggle-menu'" data-toggle="dropdown"> 
                                                <!-- <div role="button" :class="'card card-01 shadow dropdown-toggle toggle-menu'" data-toggle="dropdown"> -->
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col-6 col-sm-6">
                                                            <h6>{{ item.Nequipo }}</h6>
                                                            <p>{{ DevolverStatus(item.Status) }}</p>
                                                        </div>
                                                        <div class="col-6 col-sm-6 d-flex justify-content-end flex-column align-items-end">
                                                            <div class="row">
                                                                <div class="col-sm-12">
                                                                    <i class="fa fa-dot-circle fa-2x" aria-hidden="true" :style="item.Status=='Operando' ? 'color: #22B573;' : item.Status=='En Observacion' ? 'color: #FFA426;' : item.Status=='Fuera de Servicio' ? 'color: #FF0000;' : 'color: #652D90;'"></i>
                                                                </div>
                                                            </div>
                                                            <div class="row mt-2">
                                                                <div class="col-sm-12">
                                                                    <i @click="BorrarEquipo(item)" class="fal fa-trash-alt fa-2x" :style="Servicio.Personal==IdTrabajador || item.Trabajador==IdTrabajador ? 'color: #1b17bb;cursor:pointer' : 'color: gray'"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-show="(item.Status=='En Servicio' && item.Trabajador==IdTrabajador) || (item.Status=='En Servicio' && IdTrabajador==Servicio.Personal)" :id="'dropdown'+index" class="dropdown-menu dropdown-menu-bottom w-100 shadow mt-3 menu-dropdown-equipo">
                                                    <i class="fa fa-triangle opciones-equipo"></i>
                                                    <div class="row text-center">
                                                        <div class="col-4 col-sm-4">
                                                            <button class="btn btn-02" @click="IrADetalleEquipo(item)">
                                                                <i class="fa fa-clipboard-list icons-equipo"></i>
                                                            </button>
                                                        </div>
                                                        <div class="col-4 col-sm-4">
                                                            <button class="btn btn-02" @click="IrAManualesEquipo(item)">
                                                                <i class="fa fa-book icons-equipo"></i>
                                                            </button>
                                                        </div>
                                                        <div class="col-4 col-sm-4" @click="IrAHistorialEquipo(item)">
                                                            <button class="btn btn-02">
                                                                <i class="fa fa-file-check icons-equipo"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div class="row text-center mt-3">
                                                        <div class="col-4 col-sm-4">
                                                            <button class="btn btn-02" @click="IrAObservacionesCriticas(item)">
                                                                <i class="fa fa-comment icons-equipo"></i>
                                                            </button>
                                                        </div>
                                                        <div class="col-4 col-sm-4">
                                                            <button class="btn btn-02" @click="IrARefacciones(item)">
                                                                <i class="fa fa-box-full icons-equipo"></i>
                                                            </button>
                                                        </div>
                                                        <div class="col-4 col-sm-4">
                                                            <button class="btn btn-02" @click="IrATareas">
                                                                <i class="fa fa-clipboard-list-check icons-equipo"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <hr>
                                                    <div class="row text-center mt-3">
                                                        <div class="col-4 col-sm-4">
                                                            <button class="btn btn-01 btn-menus-equipo" @click="IrAFotoGeneral(item,'EQUIPO')">
                                                                <i class="fa fa-camera icons-equipo"></i>
                                                            </button>
                                                        </div>
                                                        <div class="col-4 col-sm-4">
                                                            <button class="btn btn-01 btn-menus-equipo"  @click="IrAEquipoComentario(item,'EQUIPO')">
                                                                <i class="fa fa-comment icons-equipo"></i>
                                                            </button>
                                                        </div>
                                                        <div class="col-4 col-sm-4">
                                                            <button disabled class="btn btn-01 btn-menus-equipo" @click="IrAEstatusEquipo(item)">
                                                                <i class="fa fa-list icons-equipo"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div class="row justify-content-center mt-3 mb-3">
                                                        <div class="col-10 col-sm-10">
                                                            <button type="button" class="btn btn-01 btn-block" style="font-size:20px;font-weight:bold" @click="IrAEstatusEquipo(item)">Terminar</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </CLoader>
                <div v-show="!VerQRScan" class="row justify-content-center mt-5 mb-5">
                    <div class="col-12 col-sm-12">
                        <div class="row justify-content-center">
                            <div class="col-10 col-sm-10">
                                <button @click="TerminarServicio" type="button" class="btn btn-01 btn-block btn-grande">
                                    FINALIZAR
                                </button>
                            </div>
                        </div>

                        <div class="row justify-content-center mt-3 mb-2">
                            <div class="col-4 col-sm-4">
                                <button @click="IrAFotoGeneral({IdEquipo:0},'SERVICIO')" type="button" class="btn btn-01 btn-block">
                                    <i class="far fa-camera fa-3x"></i>
                                </button>
                            </div>
                            <div class="col-4 col-sm-4">
                                <button @click="IrAEquipoComentario({},'SERVICIO')" type="button" class="btn btn-01 btn-block">
                                    <i class="far fa-comment-lines fa-3x"></i>
                                </button>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </template>
            <template slot="Contenido" v-else>
                <div class="row mt-3 align-content-center" style="height:75vh">
                    <div class="col-12 col-sm-12">
                        <div class="row mb-4 justify-content-center">
                            <div class="col-8 col-sm-8">
                                <button @click="IrAFotoGeneral({IdEquipo:0},'SERVICIO')" type="button" class="btn btn-01 btn-block" style="font-size:50px">
                                    <i class="far fa-camera fa-3x"></i>
                                </button>
                            </div>
                        </div>
                        <div class="row justify-content-center" style="margin-top: 8rem;">
                            <div class="col-8 col-sm-8">
                                <button @click="IrAEquipoComentario({},'SERVICIO')" type="button" class="btn btn-01 btn-block" style="font-size:50px">
                                    <i class="far fa-comment-lines fa-3x"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center fixed-bottom mb-4">
                        <div class="col-10 col-sm-10">
                            <button @click="TerminarServicio" type="button" class="btn btn-01 btn-block btn-grande">
                                FINALIZAR
                            </button>
                        </div>
                    </div>
                </div>
                <!-- <div class="row justify-content-center mt-5 mb-5">
                    <div class="col-12 col-sm-12 fixed-bottom mb-3">
                        <div class="row justify-content-center mb-2">
                            <div class="col-3 col-sm-3">
                                <button @click="IrAFotoGeneral({IdEquipo:0},'SERVICIO')" type="button" class="btn btn-01 btn-block">
                                    <i class="far fa-camera fa-2x"></i>
                                </button>
                            </div>
                            <div class="col-3 col-sm-3">
                                <button @click="IrAEquipoComentario({},'SERVICIO')" type="button" class="btn btn-01 btn-block">
                                    <i class="far fa-comment-lines fa-2x"></i>
                                </button>
                            </div>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-10 col-sm-10">
                                <button @click="TerminarServicio" type="button" class="btn btn-01 btn-block">
                                    Cerrar Servicio
                                </button>
                            </div>
                        </div>
                    </div>
                </div> -->
            </template>
        </CHeader>
    </div>
</template>

<script>
import { StreamBarcodeReader } from "vue-barcode-reader";
import CHeader from "@/views/webapp/components/HeaderWebApp.vue";
export default {
    props:['pServicio'],
    components:{
        CHeader,
        StreamBarcodeReader,
    },
    data() {
        return {
            HeaderProps:{
                ShowFechaHoy:false,
                ShowTecnico:false,
                ShowMenuDropdown:false,
                ShowIconSubAccion:true,
                IconClassSubAccion:'fas fa-barcode-read fa-2x fa-fwn mr-1 mt-2',
                ShowFotoPerfil:false,
                ShowReturn:true,
                TextoHeader:'Equipos Asignados',
                ShowLogo:false,
                IndicadorAccion:false,
                IndicadorAccionTexto:''
            },
            ConfigLoad:{
				ShowLoader:true,
				ClassLoad:false,
			},
            VerQRScan:false,
            ListaEquipos:[],
            Servicio:{},
            Indice:null,
            ServicioWQr:true,
            IdTrabajador:0
        }
    },
    methods: {
        async GetEquipos(){
            this.ConfigLoad.ShowLoader=true;
            this.ConfigLoad.ClassLoad=false;
            await this.$http.get('webapp/servequipos',{
                params:{
                    IdServicio:this.Servicio.IdServicio
                }
            }).then((res)=>{
                this.ListaEquipos=res.data.data;
            }).finally(()=>{
                this.ConfigLoad.ShowLoader=false;
                this.ConfigLoad.ClassLoad=true;
            });
        },
        IrADetalleEquipo(item){
            this.$router.push({name:'DatosEquipoWebApp',params:{pEquipo:item}});
            sessionStorage.setItem('dropdown',this.Indice);
        },
        IrAManualesEquipo(item){
            this.$router.push({name:'ManualesEquipoWebApp',params:{pEquipo:item}});
            sessionStorage.setItem('dropdown',this.Indice);
        },
        IrAHistorialEquipo(item){
            this.$router.push({name:'HistorialEquipoWebApp',params:{pEquipo:item}});
            sessionStorage.setItem('dropdown',this.Indice);
        },
        IrAObservacionesCriticas(item){
            this.$router.push({name:'ListObservacionesWebApp',params:{pEquipo:item}});
            sessionStorage.setItem('dropdown',this.Indice);
        },
        IrARefacciones(item){
            this.$router.push({name:'ListRefaccionesWebApp',params:{pEquipo:item}});
            sessionStorage.setItem('dropdown',this.Indice);
        },
        IrATareas(){
            this.$router.push({name:'TareasWebApp',params:{pServicio:this.Servicio}});
            sessionStorage.setItem('dropdown',this.Indice);
        },
        IrAFotoGeneral(item,TipoEvidencia){
            this.$router.push({name:'FotoGeneralWebApp',params:{pServicio:this.Servicio,pEquipo:item,pTipoEvidencia:TipoEvidencia}});
            sessionStorage.setItem('dropdown',this.Indice);
        },
        IrAEquipoComentario(item,TipoComentario){
            this.$router.push({name:'EquipoComentarioWebApp',params:{pEquipo:item,pServicio:this.Servicio,pTipoComentario:TipoComentario}});
            sessionStorage.setItem('dropdown',this.Indice);
        },
        IrAEstatusEquipo(item){
            this.$router.push({name:'EstatusEquipoWebApp',params:{pEquipo:item}});
            sessionStorage.setItem('dropdown',this.Indice);
        },
        ActivarQR(){
            this.VerQRScan=true;
            // let Bnd=true;
            // this.ListaEquipos.forEach((Element)=>{
            //     if (Element.Status=='En Servicio') {
            //         Bnd=false;
            //     }
            // });
            // if (Bnd) {
            //     this.VerQRScan=true;
            // }
            // else{
            //     this.$toast.warning("Debe terminar el servicio de un equipo para agregar otro");
            // }
        },
        LeerQREquipo(Result){
            let formData=new FormData();
            formData.set('IdServicio',this.Servicio.IdServicio);
            formData.set('IdEquipo',Result);
            formData.set('IdTrabajador',this.IdTrabajador);
            formData.set('Comentario','');

            this.$http.post('webapp/eqcomentarios',formData
            ).then((res)=>{
                this.VerQRScan=false;
                this.GetEquipos();
            }).catch((err)=>{
                this.VerQRScan=false;
                this.$toast.warning(err.response.data.message.errores);
            })
            // console.log(Result);
        },
        PonerDropdown(index){
            if (this.Servicio.Personal!=this.IdTrabajador) {
                if (this.ListaEquipos[index].Trabajador!=this.IdTrabajador){
                    this.$toast.warning('Las Opciones Sólo las Puede Ver el que Escaneó el Equipo');
                }
            }
            // $('.dropdown').on('show.bs.dropdown',() => {
            //     console.log(index);
            // });
            // if (this.ListaEquipos[index].Trabajador!=this.IdTrabajador) {
            //     $('#equipo'+index+'.dropdown-toggle').dropdown('toggle');
            // }
            // $('.dropdown-toggle').dropdown('toggle');
        },
        SetClaseBorde(Estatus){
            let CadenaClase='card card-01 shadow ';
            switch (Estatus) {
                case 'Operando':
                    CadenaClase=CadenaClase+'BordeEquipoIndicadorOperando';
                    break;
                case 'En Observacion':
                CadenaClase=CadenaClase+'BordeEquipoIndicadorObservacion';
                    break;
                case 'Fuera de Servicio':
                CadenaClase=CadenaClase+'BordeEquipoIndicadorFueraServicio';
                    break;
                case 'En Servicio':
                CadenaClase=CadenaClase+'BordeEquipoIndicadorServicio';
                    break;
            }
            return CadenaClase;
        },
        TerminarServicio(){
            if (this.Servicio.Personal!=this.IdTrabajador) {
                this.$toast.warning('Sólo el Responsable Puede Finalizar el Servicio');
            }
            else{
                this.$swal({
                    title: 'Terminar Servicio',
                    text: '¿Desea terminar el servicio?',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Si',
                    cancelButtonText: 'No',
                    showCloseButton: true,
                    showLoaderOnConfirm: true
                }).then((result)=>{
                    if(result.value){
                        let formData=new FormData();
                        formData.set('IdServicio',this.Servicio.IdServicio);
                        formData.set('IdTrabajador',JSON.parse(sessionStorage.getItem('user')).IdTrabajador);
                        formData.set('Estatus','Disponible');
                        formData.set('EstadoS','REALIZADA')

                        this.$http.post('webapp/setservicioestatus',formData
                        ).then((res)=>{
                            this.$router.push({name:'FirmaWebApp',params:{}});
                        }).catch((err)=>{
                            this.$toast.warning(err.response.data.message);
                        });
                    }
                });
            }
            // this.$router.push({name:'FirmaWebApp',params:{}});
        },
        BorrarEquipo(item){
            if (this.Servicio.Personal==this.IdTrabajador || item.Trabajador==this.IdTrabajador) {
                this.$swal({
                    title: 'Eliminar el equipo',
                    text: '¿Desea eliminar este equipo del servicio?',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Si',
                    cancelButtonText: 'No',
                    showCloseButton: true,
                    showLoaderOnConfirm: true
                }).then((result)=>{
                    if(result.value){
                        let formData=new FormData();
                        formData.set('IdEquipo',item.IdEquipo);
                        formData.set('IdServicio',this.Servicio.IdServicio);

                        this.$http.post('webapp/removeequipo',formData).then((res)=>{
                            this.$toast.success('Equipo Eliminado');
                            this.GetEquipos();
                        }).catch((err)=>{
                            this.$toast.warning(err.response.data.message);
                        });
                    }
                });
            }
        },
        Regresar(){
            this.$router.push({name:'ServicioDetalleWebApp'});
        },
        DevolverStatus(cadena){
            let devolver='';
            switch (cadena) {
                case 'Operando':
                    devolver = 'Operando sin Problemas';
                    break;
                case 'En Observacion':
                    devolver = 'En Servicio con Observación';
                    break;
                case 'Fuera de Servicio':
                    devolver = 'Fuera de Operación';
                    break;
                case 'En Servicio':
                    devolver = 'En Servicio';
                    break;
                default:
                    devolver = 'Sin Estatus';
                    break;
            }
            return devolver;
        }
    },
    created() {
        this.bus.$off('EjecutarSubAccion');
        this.bus.$off('EmitReturn');
    },
    mounted() {
        this.bus.$on('EjecutarSubAccion',()=>{
            this.ActivarQR();
        });
        this.bus.$on('EmitReturn',()=>{
            this.Regresar();
        });
        if (this.pServicio!=undefined) {
            sessionStorage.setItem('servicio',JSON.stringify(this.pServicio));
        }
        // if (sessionStorage.getItem('dropdown')!=undefined) {
        //     $('#dropdown'+sessionStorage.getItem('dropdown')).dropdown('toggle');
        // }
        this.Servicio=JSON.parse(sessionStorage.getItem('servicio'));
        if (this.Servicio.TipoQr==1) {
            this.ServicioWQr=true;
            this.HeaderProps.IndicadorAccionTexto='Escanear Equipo';
            this.HeaderProps.IndicadorAccion=true;
        }
        else{
            this.ServicioWQr=false;
            this.HeaderProps.TextoHeader="Servicio: "+this.Servicio.Folio;
            this.HeaderProps.ShowSubHeader=false;
        }
        this.IdTrabajador=JSON.parse(sessionStorage.getItem('user')).IdTrabajador;
        this.GetEquipos();
    },
}
</script>

<style>
.qr-camera{
    z-index:1000;
    position:absolute !important;
    top:0;
    right:0;
    min-width: 100%;
    min-height: 100%;
}
.scanner-container{
    position:fixed !important;
    margin:0;
    padding:0;
    min-width:100%;
    min-height:100%;
}
.scanner-container > div{
    min-width:100%;
    min-height:70em;
}
.qr-camera > .scanner-container > div > video{
    min-width:100% !important;
    min-height:70em !important;
    max-height:120em !important;
    object-fit:cover !important;
}
.overlay-element{
    height:50vh !important;
    top:23vh !important;
}
.laser {
    width: 60%;
    margin-left: 20%;
    background-color: tomato;
    height: 1px;
    position: absolute;
    top: 39vh !important;
    z-index: 2;
    box-shadow: 0 0 4px red;
    -webkit-animation: scanningnvo 2s infinite !important;
    animation: scanningnvo 2s infinite !important;
}
@-webkit-keyframes scanningnvo {
    50% {
        -webkit-transform: translateY(15vh);
        transform: translateY(15vh);
    }
}
@keyframes scanningnvo {
    50% {
        -webkit-transform: translateY(15vh);
        transform: translateY(15vh);
    }
}
</style>