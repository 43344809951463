<template>
    <div>
        <CLoader :pConfigLoad="ConfigLoad">
            <template slot="BodyFormLoad">
                <div class="form-group row">
                    <div class="col-lg-12">
                        <div class="form-check form-check-inline">
                            <div class="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        v-model="Contacto.CheckReporte"
                                        name="optionsCheckboxes"
                                    /><span class="checkbox-material-green"
                                        ><span class="check"></span
                                    ></span>
                                    Utilizar Contacto Para Servicio
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-lg-4">
                        <div class="form-group row">
                            <div class="col-lg-12">
                                <div class="avatar-upload-histequipo">
                                    <div class="avatar-edit">
                                        <input id="FileContacto" @change="CambiarImagen($event)" ref="FileContacto" type="file" name="file" accept=".png, .jpg, .jpeg">
                                        <label for="FileContacto"></label>
                                    </div>
                                    <div class="avatar-preview">
                                        <div id="ImagePreviewContacto" :style="'background-image: url('+ImgContacto+');'" :src="ImgContacto">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-lg-12">
                                <label for="">Usuario Client View</label>
                                <select :disabled="BndDesactivarMonitoreo" @change="SeleccionUsuarioMonitoreo" v-model="Contacto.UsuarioMonitoreo" class="form-control">
                                    <option value="si">SI</option>
                                    <option value="no">NO</option>
                                </select>
                                <label id="lblmsuser" style="color:red"
                                    ><Cvalidation v-if="this.errorvalidacion.UsuarioMonitoreo" :Mensaje="errorvalidacion.UsuarioMonitoreo[0]"></Cvalidation
                                ></label>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div v-show="BndUsuarioMonitoreo" class="col-lg-12">
                                <label>Contraseña *</label>
                                <input type="text" v-model="Contacto.Password" class="form-control" placeholder="Password"/>
                                <label id="lblmsuser" style="color:red"
                                    ><Cvalidation v-if="this.errorvalidacion.Password" :Mensaje="errorvalidacion.Password[0]"></Cvalidation
                                ></label>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-lg-8">
                        <div class="form-group row">
                            <div class="col-lg-6">
                                <label>Nombre del Contacto *</label>
                                <input v-model="Contacto.Nombre" class="form-control" placeholder="Nombre"/>
                                <label id="lblmsuser" style="color:red"
                                    ><Cvalidation v-if="this.errorvalidacion.Nombre" :Mensaje="errorvalidacion.Nombre[0]"></Cvalidation
                                ></label>
                            </div>
                            <div class="col-lg-6">
                                <label>Posición *</label>
                                <input v-model="Contacto.Posicion" class="form-control" placeholder="Posición"/>
                                <label id="lblmsuser" style="color:red"
                                    ><Cvalidation v-if="this.errorvalidacion.Posicion" :Mensaje="errorvalidacion.Posicion[0]"></Cvalidation
                                ></label>
                            </div>
                        </div>
        
                        <div class="form-group row">
                            <div class="col-lg-4">
                                <label>Teléfono *</label>
                                <input v-model="Contacto.Telefono" class="form-control" placeholder="Teléfono"/>
                                <label id="lblmsuser" style="color:red"
                                    ><Cvalidation v-if="this.errorvalidacion.Telefono" :Mensaje="errorvalidacion.Telefono[0]"></Cvalidation
                                ></label>
                            </div>
                
                            <div class="col-lg-4">
                                <label>Celular</label>
                                <input v-model="Contacto.Celular" class="form-control" placeholder="Celular"/>
                                <label id="lblmsuser" style="color:red"
                                    ><Cvalidation v-if="this.errorvalidacion.Celular" :Mensaje="errorvalidacion.Celular[0]"></Cvalidation
                                ></label>
                            </div>
                
                            <div class="col-lg-4">
                                <label>Email *</label>
                                <input v-model="Contacto.Email" class="form-control" placeholder="Email"/>
                                <label id="lblmsuser" style="color:red"
                                    ><Cvalidation v-if="this.errorvalidacion.Email" :Mensaje="errorvalidacion.Email[0]"></Cvalidation
                                ></label>
                            </div>
                        </div>
        
                        <div class="form-group row">
                            <div class="col-lg-12">
                                <label>Comentarios</label>
                                <textarea placeholder="Comentarios" class="form-control" cols="30" rows="10" v-model="Contacto.Comentarios"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </CLoader>
    </div>
</template>

<script>
import $$ from "jquery";
import Cbtnsave from "@/components/Cbtnsave.vue";
import Cvalidation from "@/components/Cvalidation.vue";
import Config from "@/helpers/VarConfig.js";
import ImagenDefault from "@/images/foto.png";
export default {
    name: "Form",
	props: ["IdContacto", "NameList", "poBtnSave","pIdClienteS","pIdCliente","pContadorUsuariosMonitoreo","pContadorPaqueteMonitoreo","pContadorMatriz","pCheckMonCli"],
    data() {
        return {
            Modal:true,
            FormName:"contacto",
            Contacto:{
                IdContacto:0,
                IdClienteS:'',
                IdCliente:'',
                IdUsuario:'',
                Nombre:'',
                Posicion:'',
                Telefono:'',
                Celular:'',
                Email:'',
                UsuarioMonitoreo:'no',
                Password:'',
                CheckReporte:false,
                Comentarios:'',
                ImgContacto:''
            },
            ValidElement:Config.validImage2m,
            EmitSeccion:this.poBtnSave.EmitSeccion,
            ConfigLoad:{
                ShowLoader:true,
                ClassLoad:true
            },
            errorvalidacion: [],
            BndUsuarioMonitoreo:false,
            BndDesactivarMonitoreo:false,
            ContadorUsuarioMonitoreo:0,
            ContadorPaqueteMonitoreo:0,
            ContadorUsuariosMatriz:0,
            ImgContacto:''
        }
    },
    components: {
		Cbtnsave,
		Cvalidation
	},
    methods: {
        async Guardar(){
            let Operacion="";
			if (this.Contacto.IdContacto=="" ? Operacion="Crear" : Operacion="Editar");
            this.poBtnSave.disableBtn=true;
            let formData=new FormData();
            formData.set('IdContacto',this.Contacto.IdContacto);
            formData.set('IdClienteS',this.Contacto.IdClienteS);
            formData.set('IdCliente',this.Contacto.IdCliente);
            formData.set('IdUsuario',this.Contacto.IdUsuario);
            formData.set('Nombre',this.Contacto.Nombre);
            formData.set('Posicion',this.Contacto.Posicion);
            formData.set('Telefono',this.Contacto.Telefono);
            formData.set('Celular',this.Contacto.Celular);
            formData.set('Email',this.Contacto.Email);
            formData.set('UsuarioMonitoreo',this.Contacto.UsuarioMonitoreo);
            formData.set('Password',this.Contacto.Password);
            formData.set('Comentarios',this.Contacto.Comentarios);
            formData.set('CheckReporte',this.Contacto.CheckReporte ? 1 : 0);
            formData.set("ImgContacto", this.Contacto.ImgContacto);
            let file = this.$refs.FileContacto.files[0];
			formData.append("File", file);
            await this.$http.post("contactos/post", formData,{
				headers: {
					"Content-Type": "multipart/form-data"
				}
			})
            .then((res) => {
                this.poBtnSave.disableBtn = false;
				this.poBtnSave.toast = 1;
				$("#Contactos").modal("hide");
				this.bus.$emit("List",Operacion,"Contactos",res.data.data.Contacto);
            }).catch((err) => {
                this.errorvalidacion = err.response.data.message.errores;
				this.poBtnSave.disableBtn = false;
                this.$toast.warning(err.response.data.message);
				// this.poBtnSave.toast = 2;
            });
        },
        Limpiar(){
            this.ImgContacto='';
            this.Contacto={
                IdContacto:0,
                IdClienteS:'',
                IdCliente:'',
                IdUsuario:'',
                Nombre:'',
                Posicion:'',
                Telefono:'',
                Celular:'',
                Email:'',
                UsuarioMonitoreo:'no',
                Password:'',
                CheckReporte:false,
                Comentarios:'',
                ImgContacto:''
            }
            this.errorvalidacion = [];
            this.BndUsuarioMonitoreo=false;
            this.BndDesactivarMonitoreo=false;
            this.ContadorUsuarioMonitoreo=0;
            this.ContadorPaqueteMonitoreo=0;
            const input = this.$refs.FileContacto;
			(input.type = "text"), (input.type = "file");
            $$("#ImagePreviewContacto").css("background-image", "url(" + this.ImgContacto + ")");
			$$("#ImagePreviewContacto").hide();
			$$("#ImagePreviewContacto").fadeIn(650);
        },
        get_one(){
            this.$http.get("contactos/recovery",{
                params:{
                    IdContacto:this.Contacto.IdContacto
                }
            }).then((res)=>{
                this.Contacto=res.data.data.Contacto;
                if (this.Contacto.ImgContacto != "" && this.Contacto.ImgContacto != null) {
                    this.ImgContacto = res.data.data.UrlFoto + this.Contacto.ImgContacto;
				} else {
					this.ImgContacto = ImagenDefault;
				}
                $$("#ImagePreviewContacto").css("background-image", "url(" + this.ImgContacto + ")");
			    $$("#ImagePreviewContacto").hide();
			    $$("#ImagePreviewContacto").fadeIn(650);

                if (this.Contacto.UsuarioMonitoreo=="si") {
                    this.BndUsuarioMonitoreo=true;
                }
                if (this.pCheckMonCli==0){
                    this.Contacto.UsuarioMonitoreo="no";
                    this.BndDesactivarMonitoreo=true;
                }
                this.Contacto.CheckReporte = this.Contacto.CheckReporte==1 ? true : false;
            }).finally(()=>{
                this.ConfigLoad.ShowLoader=false;
            });
        },
        SeleccionUsuarioMonitoreo(){
            if (this.Contacto.UsuarioMonitoreo=="si") {
                this.BndUsuarioMonitoreo=true;
            }
            else{
                this.BndUsuarioMonitoreo=false;
            }
        },
        CambiarImagen(Target){
            this.$uploadImagePreview(Target,this.ValidElement, Array('Img','ImagePreviewContacto'));
        }
    },
    created() {
        this.bus.$off("NewModal_"+this.EmitSeccion);
        this.bus.$on("NewModal_"+this.EmitSeccion, (data, Id, Obj) => {
            this.ConfigLoad.ShowLoader=true;
			this.Limpiar();
            // if (Obj!==undefined) {
            //     this.Contacto=Obj;
            //     if (this.Contacto.UsuarioMonitoreo=='si') {
            //         this.BndUsuarioMonitoreo=true;
            //     }
            // }
			this.Contacto.IdClienteS = this.pIdClienteS;
            this.Contacto.IdCliente=this.pIdCliente;
			this.poBtnSave.disableBtn = false;
            // this.pContadorUsuarioMonitoreo=
            this.ContadorUsuarioMonitoreo=Number(this.pContadorUsuariosMonitoreo);
            this.ContadorPaqueteMonitoreo=Number(this.pContadorPaqueteMonitoreo);
            this.ContadorUsuariosMatriz=Number(this.pContadorMatriz);
            if (this.ContadorUsuarioMonitoreo>=this.ContadorPaqueteMonitoreo) {
                this.Contacto.UsuarioMonitoreo="no";
                this.BndDesactivarMonitoreo=true;
            }
            else if (this.pCheckMonCli==0){
                this.Contacto.UsuarioMonitoreo="no";
                this.BndDesactivarMonitoreo=true;
            }
            console.log(this.pCheckMonCli);
			this.bus.$off("Save_"+this.EmitSeccion);
			this.bus.$on("Save_"+this.EmitSeccion, () => {
                this.poBtnSave.toast = 0;
				this.Guardar();
			});

			if (Id > 0) {
                this.Limpiar();
				this.Contacto.IdContacto = Id;
				this.get_one();
			}
            else{
                this.ConfigLoad.ShowLoader=false;
            }

			this.bus.$emit("Desbloqueo", false);
		});
    },
}
</script>

<style>

</style>