<template>
	<div>
		<div class="row mt-2">
			<div class="col-12 col-sm-12 col-md-12 col-lg-12">
				<nav class="navbar navbar-breadcrumb navbar-expand-md bg-breadcrumb breadcrumb-borde">
					<div class="mr-auto">
						<nav aria-label="breadcrumb">
							<ol class="breadcrumb clearfix pt-3">
								<li class="breadcrumb-item active">Dashboard Finanzas</li>
							</ol>
						</nav>
					</div>
				</nav>
			</div>
		</div>

		<div class="form-row justify-content-center mt-2">
			<PlanFacturacion :pListaAnios="listaAnios" :pAnioActual="anioActual" ref="Facturacion"/>
			<FacturaTipos :pListaAnios="listaAnios" :pAnioActual="anioActual" ref="FacturaTipos" />

			<CCostos :pListaAnios="listaAnios" :pAnioActual="anioActual" ref="EstadoResultados" />

			<CGrossProfit :pListaAnios="listaAnios" :pAnioActual="anioActual" ref="GrossProfit"/>

			<CTipoServ :pListaAnios="listaAnios" :pAnioActual="anioActual" ref="NetProfit"/>

			<CuentasFlujoCaja ref="FlujoCaja"/>
			<CRealCuentas :pListaAnios="listaAnios" :pAnioActual="anioActual" ref="EstadoCuentas"/>


		</div>
	</div>
</template>

<script>
import Vue from "vue";
import VueFusionCharts from "vue-fusioncharts";
import FusionCharts from "fusioncharts";
import Column2D from "fusioncharts/fusioncharts.charts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import Widgets from "fusioncharts/fusioncharts.widgets";

import GammelTheme from "fusioncharts/themes/fusioncharts.theme.gammel";
import CandyTheme from "fusioncharts/themes/fusioncharts.theme.candy";
import ZuneTheme from "fusioncharts/themes/fusioncharts.theme.zune";
import OceanTheme from "fusioncharts/themes/fusioncharts.theme.ocean";
import CarbonTheme from "fusioncharts/themes/fusioncharts.theme.carbon";

Vue.use(
	VueFusionCharts,
	FusionCharts,
	Column2D,
	Widgets,
	FusionTheme,
	GammelTheme,
	CandyTheme,
	ZuneTheme,
	OceanTheme,
	CarbonTheme
);

import PlanFacturacion from "./PlanFacturacion.vue";
import CGrossProfit from "./GrossProfit.vue";
import FacturaTipos from "./FacturaTipos.vue";
import CTipoServ from "./TipoServ.vue";
import CHoras from "./Horas.vue";
import CCostos from "./Costos.vue";
import CuentasFlujoCaja from "./CuentasFlujoCaja.vue";
import CRealCuentas from "./RealCuentas.vue";

export default {
	name: "DashboardFinanzas",
	props: [""],
	components: {
		PlanFacturacion,
		CGrossProfit,
		FacturaTipos,
		CTipoServ,
		CHoras,
		CCostos,
		CuentasFlujoCaja,
		CRealCuentas
	},
	data() {
		return {
			Head: {
				Title: "Dashboard Finanzas",
				BtnNewShow: false,
				BtnNewName: "Nuevo",
				isreturn: false,
				isModal: false,
				isEmit: false,
				Url: "",
				ObjReturn: ""
			},
			isVisible: true,
			listaAnios:[],
			anioActual:''
		};
	},
	provide: {},
	methods: {
		/*Filtros() {
			//this.bus.$emit('Ocultar',true);
			this.bus.$emit("Ocultar", this.isVisible);
			this.bus.$emit("Ocultar2", this.isVisible);
			this.bus.$emit("Ocultar3", this.isVisible);
			this.bus.$emit("Ocultar4", this.isVisible);
			this.bus.$emit("Ocultar5", this.isVisible);
			this.bus.$emit("Ocultar6", this.isVisible);
			this.bus.$emit("Ocultar7", this.isVisible);
			this.bus.$emit("Ocultar8", this.isVisible);

			if (this.isVisible) {
				this.isVisible = false;
			} else {
				this.isVisible = true;
			}
		},*/

		async anios() {
			await this.$http.get("funciones/getanios").then(res => {
				//console.log('Calling Years');
				this.listaAnios = res.data.ListaAnios;
				this.anioActual = res.data.AnioActual;
				this.listFacturacion();
			});
		},

		listFacturacion() {
			this.$refs.Facturacion.ConfigServicio().then(res => {
				if (res.status) {
					//console.log('Graph Facturacion Cargada...');
					this.graphFacturaTipos();
				}
			}).catch(err => {
				console.log('Error en carga de Facturacion')
			});
		},

		graphFacturaTipos() {
			this.$refs.FacturaTipos.getDataFacturacionTipos().then(res => {
				if (res.status) {
					//console.log('Graph FacturacionTipos Cargada...')
					this.graphFlujoCaja();
				}
			}).catch(err => {
				console.log('Error en carga de FacturacionTipos')
			});
		},

		EstadoResultados() {
			this.$refs.EstadoResultados.getYTD().then(res => {
					if(res.status) {
						//console.log('EstadoResultados Cargada...');
						this.graphNetProfit();
					}
				}).catch(err => {
				console.log('Error en carga de EstadoResultados ')
			});
		},

		GrossProfit() {
			this.$refs.GrossProfit.getGrossProfit().then(res => {
				if (res.status) {
					//console.log('Graph GrossProfit Cargada...')
					this.EstadoResultados();
				}
			}).catch(err =>{
				console.log('Error en carga de GrossProfit')
			});
		},

		graphNetProfit() {
			this.$refs.NetProfit.getDataSource().then(res => {
				if (res.status) {
					//console.log('Graph NetProfit Cargada...')
					//this.GrossProfit();
				}
			}).catch(err => {
				console.log('Error en carga de NetProfit');
			});
		},

		graphFlujoCaja() {
			this.$refs.FlujoCaja.getFlujoCaja().then(res => {
				if (res.status) {
					//console.log('Graph FlujoCaja Cargada...')
					this.graphEstadoCuentas();
				}
			}).catch(err => {
				console.log('Error en carga de FlujoCaja')
			});
		},

		graphEstadoCuentas() {
			this.$refs.EstadoCuentas.getEstadoCuentas().then(res => {
				if (res.status) {
					//console.log('Graph EstadoCuentas Cargada...')
					//this.EstadoResultados();
					this.GrossProfit();
				}
			}).catch(err => {
				console.log('Error en carga de EstadoCuentas')
			});
		},


	},
	created() {
		this.anios()
	},
	mounted() {},
	destroyed() {}
};
</script>

<style>
svg > g[class^="raphael-group-"] > text {
	display: none;
}
</style>
