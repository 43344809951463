<template>
    <div class="col-md-12 col-lg-6 col-xl-6 mb-2" style="height:410px">
        <b-overlay
			:show="isOverlay"
			rounded="sm"
			spinner-variant="primary"
			style="height: 100%;"
		>
            <div class="card card-grafica" style="height:100%">
                <div class="card-body">
                    <div class="filtro-grafic" id="grafica_001" v-if="isVisible">
                        <div class="row justify-content-start">
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <form class="form-inline">
                                    <label class="title-grafic side-l mr-3">Hrs. Prod. Semanales</label>
                                    <select style="width: 140px" id="Trabajador" @change="get_dataSource()" class="form-control form-control-sm mb-2 mr-1" v-model="IdTrabajador1">
                                        <option
											v-for="(item, index) in ListaTrabajadores" :key="index" :value="item.IdTrabajador">
											{{ item.Nombre }}
										</option>
                                    </select>
                                    &nbsp;
                                    <select style="width: 140px" id="Trabajador2" @change="get_dataSource()" class="form-control form-control-sm mb-2 mr-2" v-model="IdTrabajador2">
										<!-- <option value="0">Todos</option> -->
										<option v-for="(item, index) in ListaTrabajadores" :key="index" :value="item.IdTrabajador">
											{{ item.Nombre }}
										</option>
									</select>
                                    &nbsp;
                                    <v-date-picker v-model="startDate" :masks="masks" :popover="{ visibility: 'focus' }" locale="es" @input="calculateRange()">
										<template v-slot="{ inputValue, inputEvents }">
											<input
												class="form-control form-control-sm mb-2 mr-1 calendar"
												:value="inputValue"
												v-on="inputEvents"
												readonly
											/>
										</template>
									</v-date-picker>
                                    <input v-model="finishDate" class="form-control form-control-sm mb-2 calendar" type="text" disabled="disabled"/>

                                </form>
                                <button	type="button" class="btn close abs_01" @click="Ocultar()">
									<i class="fal fa-times"></i>
								</button>
                            </div>
                        </div>
                    </div>

                    <div class="row">
						<div class="col-10 col-sm-10 col-md-8 col-lg-8">
							<h6 class="title-grafic side-l">Horas Productivas Semanales</h6>
						</div>
						<div class="col-2 col-sm-2 col-md-4 col-lg-4 text-right">
							<button type="button" class="btn-fil-002" title="Filtros" @click="mostrarfiltro()">
								<i class="fas fa-filter"></i>
							</button>
						</div>

						<div class="col-12 col-sm-12 col-md-12 col-lg-12">
							<hr />
						</div>
					</div>

                    <div class="row">
                        <div class="col-md-6 col-lg-6">
                            <div id="grafica01">
                                <jscharting ref="chart1" :mutable="true" :options="chartOptions1" class="columnChart"></jscharting>
                            </div>
                            <h5 class="text-center tile-grafic-02">{{ NombreTrabajador1 }}</h5>
                        </div>
						<div class="col-md-6 col-lg-6">
                            <div id="grafica02">
                                <jscharting ref="chart2" :mutable="true" :options="chartOptions2" class="columnChart"></jscharting>
                            </div>
                            <h5 class="text-center tile-grafic-02">{{ NombreTrabajador2 }}</h5>
                        </div>
                    </div>
                </div>
            </div>
        </b-overlay>
    </div>
</template>

<script>
import moment from "moment";
export default {
    name: "HorasProductivas",
    data() {
        return {
            masks: {
				input: "DD/MM/YYYY"
			},
            finishDate: "",
			realFinishDate: "",
			startDate: "",
            isVisible: false,
            ListaTrabajadores: [],
			rangeDate: {},
			IdTrabajador1: 0,
			IdTrabajador2: 0,

			isOverlay: false,

            NombreTrabajador1:'',
            NombreTrabajador2:'',
            
            chartOptions1: {
                debug:false,
                type: 'gauge',
                xAxis: { spacingPercentage: 0.25 },
                yAxis: { 
                    defaultTick: { 
                      padding: 6, 
                      label_style_fontSize: '14px'
                    }, 
                    line: { 
                      width: 10, 
                      color: 'smartPalette:pal1', 
                      breaks_gap: 0 
                    }, 
                    scale_range: [0, 100] 
                }, 
                // palette: {
				// 	pointValue: '{%value/100}',
				// 	colors:['red', 'orange', 'green'],
				// 	ranges:[{value:[0,50]},{value:[50,75]},{value:[75,100]}]
                // },
                defaultSeries: { 
                    type: 'gauge column roundcaps',
                    angle: { sweep: 180 }, 
                    shape: { 
                        innerSize: '70%', 
                        label: { 
                            text: 
                                '<span color="%color">{%sum:n1}</span><br/><span color="#696969" fontSize="20px">Hrs</span>', 
                            style_fontSize: '46px', 
                            verticalAlign: 'middle'
                        } 
                    } 
                }, 
                series: [ 
                    { 
                        type: 'column roundcaps',
                        palette: {
                            id:'pal1',
					        pointValue: '%yValue',
					        // colors:['red', 'orange', 'green'],
					        ranges:[{value:50,color:'red'},{value:75,color:'orange'},{value:[75,100],color:'green'}]
                        }, 
                        points: [
                            { 
                                id: '1', 
                                x: 'Horas Trabajadas', 
                                y: 0 
                            }
                        ] 
                    } 
                ],
				legend:{
					visible:false
				}
            },
			chartOptions2: {
                debug:false,
                type: 'gauge',
                xAxis: { spacingPercentage: 0.25 },
                yAxis: {
                    id:'ax1',
                    // color: 'smartPalette:pal1', 
                    defaultTick: { 
                      padding: 6, 
                      label_style_fontSize: '14px'
                    }, 
                    line: { 
                      width: 10, 
                      color: 'smartPalette:pal1', 
                      breaks_gap: 0
                    }, 
                    scale_range: [0, 100] 
                }, 
                //palette: {
				//	pointValue: '{%value/100}',
				//	colors:['red', 'orange', 'green'],
				//	ranges:[{value:[0,50]},{value:[50,75]},{value:[75,100]}]
                //},
                defaultSeries: { 
                    type: 'gauge column roundcaps', 
                    angle: { sweep: 180 }, 
                    shape: { 
                        innerSize: '70%', 
                        label: { 
                            text: 
                                '<span color="%color">{%sum:n1}</span><br/><span color="#696969" fontSize="20px">Hrs</span>', 
                            style_fontSize: '46px', 
                            verticalAlign: 'middle'
                        } 
                    } 
                }, 
                series: [ 
                    { 
                        yAxis:'ax1',
                        type: 'column roundcaps',
                        palette: {
                            id: 'pal1',
					        pointValue: '{%yValue/100}',
					        // colors:['red', 'orange', 'green'],
					        // ranges:[{value:[0,50]},{value:[50,75]},{value:[75,100]}],
                            // ranges:[{value:50,color:"#FFFFFF"},{value:75,color:"#FF4433"},{value:100,color:"#000000"}]
                            ranges:[{value:50,color:'red'},{value:75,color:'orange'},{value:[75,100],color:'green'}]
                        }, 
                        points: [
                            { 
                                id: '1', 
                                x: 'Horas Trabajadas', 
                                y: 0 
                            }
                        ] 
                    } 
                ],
				legend:{
					visible:false
				}
            }
        }
    },
    methods: {
        setWeekValue() {
			//this.startDate = new Date(),
			//this.finishDate	=  moment(this.startDate).add(6, 'days').format('YYYY-MM-DD');

			this.startDate = moment()
				.startOf("week")
				.add(1, "days")
				.toDate();

			let endOfWeek = moment()
				.endOf("week")
				.toDate();

			this.finishDate = moment(endOfWeek)
				.add(1, "days")
				.format("DD/MM/YYYY");


			this.realFinishDate = moment(endOfWeek)
				.add(1, "days")
				.format("YYYY-MM-DD");
		},
        async get_dataSource(){
            this.isOverlay = true;
			if (this.ListaTrabajadores.length > 0) {
				/**
				 * Cargar inicialmente los datos
				 */

				//console.log(this.startDate)
				//console.log(this.realFinishDate);


				let newRequest = {};

				if (parseInt(this.IdTrabajador1) > 0 && parseInt(this.IdTrabajador2) == 0) {
					newRequest = {
						IdTrabajador: this.IdTrabajador1,
						IdTrabajador2: 0,

						Fecha_I: moment(this.startDate).format('YYYY-MM-DD'),
						Fecha_F: this.realFinishDate
					};
				} else {
					newRequest = {
						IdTrabajador: this.IdTrabajador1,
						IdTrabajador2: this.IdTrabajador2,

						Fecha_I: moment(this.startDate).format('YYYY-MM-DD'),
						Fecha_F: this.realFinishDate
					};
				}


				await this.$http
					.post("despachoone/post", newRequest)
					.then((res) => {
						this.isOverlay = false;
						//console.log(res.data.data);
						this.create_FirstGraph(res.data.data.Trabajador1);
						this.create_SecondGraph(res.data.data.Trabajador2);
						// this.create_SecondGraph(res.data.data.Trabajador2);
					})
					.catch(() => {

					}).finally(() => {
						this.isOverlay = false;
					});
			}
        },
        create_FirstGraph(Trabajador1){
            let HorasT=parseFloat(Trabajador1.horasT);
            this.chartOptions1.series[0].palette.pointValue='%yValue';
            // this.chartOptions1.yAxis.scale_range[0]=parseInt(Trabajador1.HoraPS)-5;
			this.chartOptions1.yAxis.scale_range[1]=Trabajador1.horasT>Trabajador1.HoraPS ? Trabajador1.horasT : Trabajador1.HoraPS+10;
			// this.chartOptions1.palette.pointValue= Trabajador1.horasT>Trabajador1.HoraPS ? '{%yValue/'+Trabajador1.horasT+'}' : '{%yValue/'+(Trabajador1.HoraPS+10)+'}';

            // this.chartOptions1.series[0].palette.ranges[0].value=parseInt(Trabajador1.HoraPS)-5;
            this.chartOptions1.series[0].palette.ranges[0].value=0;
            this.chartOptions1.series[0].palette.ranges[1].value=parseInt(Trabajador1.HoraPS)-5;
            this.chartOptions1.series[0].palette.ranges[2].value[0]=parseInt(Trabajador1.HoraPS);
            this.chartOptions1.series[0].palette.ranges[2].value[1]=Trabajador1.horasT>Trabajador1.HoraPS ? Trabajador1.horasT : parseInt(Trabajador1.HoraPS)+10;
			// this.chartOptions1.palette.ranges[0].value[0]=0;
			// this.chartOptions1.palette.ranges[0].value[1]=parseInt(Trabajador1.HoraPS)-5;

			// this.chartOptions1.palette.ranges[1].value[0]=parseInt(Trabajador1.HoraPS)-5;
			// this.chartOptions1.palette.ranges[1].value[1]=parseInt(Trabajador1.HoraPS);

			// this.chartOptions1.palette.ranges[2].value[0]=parseInt(Trabajador1.HoraPS);
			// this.chartOptions1.palette.ranges[2].value[1]=parseInt(Trabajador1.HoraPS)+10;
			
			this.chartOptions1.series[0].points[0].y=HorasT;

            let index=this.ListaTrabajadores.findIndex((Trabajador)=>Trabajador.IdTrabajador==this.IdTrabajador1);
            this.NombreTrabajador1=this.ListaTrabajadores[index].Nombre;
            // this.chartOptions1.palette.pointValue='{%value/'+this.chartOptions1.yAxis.scale_range[1]+'}';
        },
		create_SecondGraph(Trabajador2){
			// this.chartOptions2.yAxis.scale_range[1]=Trabajador2.horasT>Trabajador2.HoraTrabajoSemanal ? Trabajador2.horasT : Trabajador2.HoraTrabajoSemanal;
            // this.chartOptions2.defaultSeries.shape.label.text='<span color="%color">{%sum:n1}</span><br/><span color="#696969" fontSize="20px">Hrs</span>'

			// this.chartOptions2.series[0].palette.pointValue= Trabajador2.horasT>Trabajador2.HoraPS ? '{%yValue/'+Trabajador2.horasT+'}' : '{%yValue/'+(parseInt(Trabajador2.HoraPS)+15)+'}';
            this.chartOptions2.series[0].palette.pointValue='%yValue';
            this.chartOptions2.yAxis.scale_range[1]=Trabajador2.horasT>Trabajador2.HoraPS ? Trabajador2.horasT : parseInt(Trabajador2.HoraPS)+10;

            // this.chartOptions2.series[0].palette.ranges[0].value[0]=0;
			// this.chartOptions2.series[0].palette.ranges[0].value[1]=parseInt(Trabajador2.HoraPS)-5;
			// this.chartOptions2.series[0].palette.ranges[1].value[0]=parseInt(Trabajador2.HoraPS)-5;
			// this.chartOptions2.series[0].palette.ranges[1].value[1]=parseInt(Trabajador2.HoraPS);
			// this.chartOptions2.series[0].palette.ranges[2].value[0]=parseInt(Trabajador2.HoraPS);
			// this.chartOptions2.series[0].palette.ranges[2].value[1]=parseInt(Trabajador2.HoraPS)+10;

            // this.chartOptions2.series[0].palette.ranges[0].value=parseInt(Trabajador2.HoraPS)-5;
            this.chartOptions2.series[0].palette.ranges[0].value=0;
            this.chartOptions2.series[0].palette.ranges[1].value=parseInt(Trabajador2.HoraPS)-5;
            this.chartOptions2.series[0].palette.ranges[2].value[0]=parseInt(Trabajador2.HoraPS);
            this.chartOptions2.series[0].palette.ranges[2].value[1]=Trabajador2.horasT>Trabajador2.HoraPS ? Trabajador2.horasT : parseInt(Trabajador2.HoraPS)+10;
            // this.chartOptions2.series[0].palette.ranges[3].value=parseInt(Trabajador2.HoraPS)+11;
            // this.chartOptions2.series[0].palette.ranges[0].value=0;
            // this.chartOptions2.series[0].palette.ranges[1].value=parseInt(Trabajador2.HoraPS)-5;
            // this.chartOptions2.series[0].palette.ranges[2].value=parseInt(Trabajador2.HoraPS);
            // this.chartOptions2.series[0].palette.ranges[3].value=parseInt(Trabajador2.HoraPS)+10;

			
			this.chartOptions2.series[0].points[0].y=parseFloat(Trabajador2.horasT);
            let index=this.ListaTrabajadores.findIndex((Trabajador)=>Trabajador.IdTrabajador==this.IdTrabajador2);
            this.NombreTrabajador2=this.ListaTrabajadores[index].Nombre;
		},
        calculateRange(){
            this.finishDate = "";
			this.finishDate = moment(this.startDate)
				.add(6, "days")
				.format("DD/MM/YYYY");

			this.realFinishDate = "";
			this.realFinishDate = moment(this.startDate)
				.add(6, "days")
				.format("YYYY-MM-DD");

			this.get_dataSource();
        },
        Ocultar(){
            this.isVisible = false;
        },
        mostrarfiltro(){
            this.isVisible = true;
        },
        get_listtrabajador() {
			this.$http
				.get("trabajador/get", {
					params: {
						Rol: "USUARIO APP",
						IdPerfil: 4
					}
				})
				.then(res => {
					this.ListaTrabajadores = res.data.data.trabajador;
					this.IdTrabajador1 = this.ListaTrabajadores[0].IdTrabajador;
                    this.IdTrabajador2 = this.ListaTrabajadores[1].IdTrabajador;
					this.get_dataSource();
				});
		}
    },
    created() {
        this.setWeekValue();
    },
    mounted() {
        this.get_listtrabajador();
    },
}
</script>

<style>
.columnChart {
    height: 300px;
}
</style>