<template>
    <div class="form-row">
        <div class="col-lg-12">
            <label for="">Notas</label>
            <textarea v-model="equipo.Observaciones" placeholder="Notas acerca del equipo" name="" id="" cols="30" rows="10" class="form-control"></textarea>
        </div>
    </div>
</template>

<script>

import Cbtnsave from '@/components/Cbtnsave.vue';

export default {
    props:["poBtnSave"],
    components:{Cbtnsave},
    data(){
        return{
            Modal:true,
            FormName:'notas',
            equipo:{
                Observaciones:"",
                IdEquipo:0
            },
        }
    },
    methods:{
        async guardarNota(){
            // this.poBtnSave.toast=0;
            this.poBtnSave.disableBtn=false;
            let formData=new FormData();
            formData.set('IdEquipo',this.equipo.IdEquipo);
            formData.set('Observaciones',this.equipo.Observaciones);
            await this.$http.post(
                'equipo/aniadirnota/post',
                formData).then((res)=>{
                    this.poBtnSave.disableBtn=false;
                    // this.poBtnSave.toast=1;
                    this.$toast.success("Información guardada");
                    $('#notas').modal('hide');
                    this.bus.$emit('ActualizarNota',this.equipo.Observaciones);
                }).catch((err)=>{
                    this.poBtnSave.disableBtn=false;
                    this.poBtnSave.toast=2;
                });
        },
        listauno(){
            this.$http.get('equipos/recovery',{
                params:{IdEquipo:this.equipo.IdEquipo}
            }).then((res)=>{
                this.equipo.IdEquipo=res.data.data.Equipo.IdEquipo;
                this.equipo.Observaciones=res.data.data.Equipo.Observaciones;
            });
        },
        limpiar(){
            this.equipo.IdEquipo=0;
            this.equipo.Observaciones="";
        }
    },
    created(){
        this.bus.$off('NuevaNota');
        this.bus.$on('NuevaNota',(data,Id,Observaciones)=>{
            this.poBtnSave.disableBtn=false;
            this.bus.$off('Save');
            this.bus.$on('Save',()=>{
                this.guardarNota();
            });
            this.limpiar();
            if(Id>0){
                this.equipo.IdEquipo=Id;
                this.listauno();
                this.bus.$emit('Desbloqueo',false);
            }
            if(Observaciones!=null){
                this.equipo.Observaciones=Observaciones;
            }
            else{
                this.equipo.Observaciones="Escriba sus observaciones";
            }
        });
        if (this.equipo.IdEquipo!=0) {
            this.listauno();
        }
    }
}
</script>