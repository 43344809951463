<template>
    <div>
        <CHead :oHead="Head">
        </CHead>
        <div class="row mt-2">
            <div class="col-lg-12">
                <div class="card w-50 m-auto">
                    <div class="card-body">
                        <div class="form-row form-group">
                            <div class="col-lg-6">
                                <label for="">Propiedad de Origen</label>
                                <select v-model="IdSucursal" @change="ListaSucursalesDestino()" name="" id="" class="form-control">
                                    <option value="">Selecciona una Propiedad</option>
                                    <option v-for="(item,index) in Sucursales" :key="index" :value="item.IdClienteS">
                                        {{ item.Nombre }}
                                    </option>
                                </select>
                            </div>

                            <div class="col-lg-6">
                                <label for="">Propiedad Destino</label>
                                <select v-model="IdSucursalDestino" name="" id="" class="form-control">
                                    <option value="">Selecciona una Propiedad</option>
                                    <option v-for="(item,index) in SucursalesDestino" :key="index" :value="item.IdClienteS">
                                        {{ item.Nombre }}
                                    </option>
                                </select>
                                <Cvalidation v-if="this.errorvalidacion.IdSucursalDestino" :Mensaje="errorvalidacion.IdSucursalDestino[0]"/>
                            </div>

                        </div>
                        <div class="form-group form-row mt-3">
                            <div class="col-lg-6">
                                <Cloader :pConfigLoad="ConfigLoad">
                                    <template slot="BodyFormLoad">
                                        <draggable id="div1" :list=Equipos class="droppable bg-feed" group="people">
                                            <div v-for="(element, index) in Equipos" :key="index">
                                                {{ element.Nequipo }}
                                            </div>
                                        </draggable> 
                                    </template>
                                </Cloader>
                                
                            </div>

                            <div class="col-lg-6">
                                <draggable id="div2" :list=EquiposReasignados class="droppable" group="people">
                                    <div v-for="(element, index) in EquiposReasignados" :key="index">
                                        {{ element.Nequipo }}
                                    </div>
                                </draggable>
                                <Cvalidation v-if="this.errorvalidacion.EquiposReasignados" :Mensaje="errorvalidacion.EquiposReasignados[0]"/>
                            </div>
                        </div>
                        <div class="form-row form-group mt-3">
                            <div class="col-lg-12 text-right">
                                <button @click="Reasignar" type="button" class="btn btn-01 save">Reasignar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Cloader from '@/components/CLoader.vue';
import Cvalidation from '@/components/Cvalidation.vue';
export default {
    components:{Cloader},
    data() {
        return {
            Head: {
                Title: 'Reasignar Equipos',
                BtnNewShow: false,
                // BtnNewName:'Nuevo',
                isreturn: true,
                isModal: false,
                isEmit: false,
                Url: 'mon_sucursal',
                // POR SI SE QUEDA EN EL MENU PRINCIPAL DE MONITOREO
                // Url: 'monitoreo_cli',
                ObjReturn: '',
            },
            ConfigLoad:{
                ShowLoader:false,
                ClassLoad:true
            },
            errorvalidacion:[],
            IdSucursal:"",
            IdSucursalDestino:"",
            oClienteP:{},
            Sucursales:[],
            SucursalesDestino:[],
            Equipos:[],
            EquiposReasignados:[]
        };
    },
    methods: {
        async ListaSucursales(){
            await this.$http.get(
                "clientesucursal/get",
                {
                    params:{
                        // Nombre:this.Filtro.Nombre,
                        IdSucursa:this.oClienteP.IdSucursal,
                        IdCliente:this.oClienteP.IdCliente,
                        // Entrada:this.Filtro.Entrada,
                        // pag:this.Filtro.Pagina, 
                        RegEstatus:'A',
                        Todos:true
                    }
                }
            ).then( (res) => {
                this.Sucursales =res.data.data.clientesucursal;
                this.EquiposReasignados=[];
                // this.RutaIcoEmp=res.data.RutaIcoEmp;
            });
        },
        Reasignar(){
            let formData=new FormData();
            formData.set('IdClienteSAntiguo',this.IdSucursal);
            formData.set('IdSucursalDestino',this.IdSucursalDestino);
            formData.append('EquiposReasignados',JSON.stringify(this.EquiposReasignados));
            this.$http.post('monitoreo/reasignar',formData)
            .then(()=>{
                this.$toast.success('Equipos Reasignados');
                this.errorvalidacion=[];
            }).catch((err)=>{
                this.errorvalidacion=err.response.data.message.errores;
            })
        },
        ListaSucursalesDestino(){
            // console.log(this.IdSucursal);
            this.SucursalesDestino=this.Sucursales.filter((element)=>element.IdClienteS!=this.IdSucursal);
            this.EquiposReasignados=[];
            this.ConfigLoad.ShowLoader=true;
            this.$http.get(
                "equipos/get",
                {
                    params:{
                        IdClienteS:this.IdSucursal, 
                        RegEstatus:'A',
                        Todos:true
                    }
                }
            ).then( (res) => {
                this.Equipos =res.data.data.equipos;
                // this.RutaIcoEmp=res.data.RutaIcoEmp;
            }).finally(()=>{
                this.ConfigLoad.ShowLoader=false;
            });
        }
    },
    created() {
        this.oClienteP=JSON.parse(sessionStorage.getItem('IdSaved'));
        this.ListaSucursales();
    },
}
</script>