var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"col-lg-12 row"},[_c('div',{staticClass:"col-lg-4"},[_c('label',[_vm._v("Fecha")]),_c('v-date-picker',{attrs:{"mode":_vm.typeCalendar,"min-date":new Date(),"popover":{ 
                        placement: 'bottom',
                        visibility: 'click',                     
                    },"input-props":{
                        class:"form-control form-control-sm cal-black",
                        style:"cursor:pointer;background-color:#F9F9F9",
                        readonly: true,
                    }},on:{"input":function($event){return _vm.$emit('input', _vm.form)}},model:{value:(_vm.form.Fecha),callback:function ($$v) {_vm.$set(_vm.form, "Fecha", $$v)},expression:"form.Fecha"}}),(_vm.error.Fecha)?_c('m2-validator',{attrs:{"mensaje":_vm.error.Fecha[0]}}):_vm._e()],1),_c('div',{staticClass:"col-lg-4"},[_c('label',[_vm._v("Hora inicio")]),_c('the-mask',{staticClass:"form-control form-control-sm",attrs:{"mask":['##:##']},on:{"input":function($event){return _vm.$emit('input', _vm.form)}},model:{value:(_vm.form.HoraInicio),callback:function ($$v) {_vm.$set(_vm.form, "HoraInicio", $$v)},expression:"form.HoraInicio"}}),(_vm.error.HoraInicio)?_c('m2-validator',{attrs:{"mensaje":_vm.error.HoraInicio[0]}}):_vm._e()],1),_c('div',{staticClass:"col-lg-4"},[_c('label',[_vm._v("Hora fin")]),_c('the-mask',{staticClass:"form-control form-control-sm",attrs:{"mask":['##:##']},on:{"input":function($event){return _vm.$emit('input', _vm.form)}},model:{value:(_vm.form.HoraFin),callback:function ($$v) {_vm.$set(_vm.form, "HoraFin", $$v)},expression:"form.HoraFin"}}),(_vm.error.HoraFin)?_c('m2-validator',{attrs:{"mensaje":_vm.error.HoraFin[0]}}):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }