<template>
    <div class="col-md-12 col-lg-12 col-xl-12 mb-2">
        <b-overlay :show="isOverlay" rounded="sm" spinner-variant="primary" class="h-100">
            <div class="card card-grafica" style="max-height:510px;overflow-y:auto">
                <div class="card-body">
                    <div class="filtro-grafic" id="grafica_002" v-if="isVisible">
                        <div class="row justify-content-start">
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <form class="form-inline">
                                    <label class="title-grafic side-l mr-3">Indicadores - Personal</label>
                                    <v-date-picker
										mode='range'
										v-model='rangeDate'
										@input="GetListaTrabajadores"
										:input-props='{
											class: "form-control form-control mb-2 mr-1 calendar",
											placeholder: "Selecciona un rango de fecha para buscar",
											readonly: true
										}'
										/>
                                    <treeselect
                                        class="d-inline-block w-25 mt-0 pt-0 ml-2"
                                        style="top: -4px;"
                                        :maxHeight="180"
                                        :options="ListaComboTrabajadores"
                                        placeholder="Trabajador..."
                                        v-model="IdTrabajador"
                                        @input="GetListaTrabajadores()"
                                    />
                                </form>
                                <button type="button" class="btn close abs_01" @click="ocultarfiltro()"><i class="fal fa-times"></i></button>
                            </div>
                        </div>
                    </div>
                    <div class="row">
						<div class="col-10 col-sm-10 col-md-8 col-lg-8">
							<h6 class="title-grafic side-l">Indicadores - Personal</h6>
						</div>
						<div class="col-2 col-sm-2 col-md-4 col-lg-4 text-right">
							<button	type="button" class="btn-fil-002" title="Filtros" @click="mostrarfiltro()">
								<i class="fas fa-filter"></i>
							</button>
						</div>

						<div class="col-12 col-sm-12 col-md-12 col-lg-12">
							<hr />
						</div>
					</div>
                    <div class="row">
                        <div class="table-responsive">
                            <table class="table-dashboard-despacho">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th style="font-size:19px;">Posición</th>
                                        <th class="text-center" style="font-size:19px;">Trabajados</th>
                                        <th class="text-center" style="font-size:19px;">Ausencias</th>
                                        <th class="text-center" style="font-size:19px;">Vacaciones</th>
                                        <th class="text-center" style="font-size:19px;">Permisos</th>
                                        <th class="text-center" style="font-size:19px;">Retrasos</th>
                                        <th class="text-center" style="font-size:19px;">Sal. Anticip.</th>
                                        <th class="text-center" style="font-size:19px;">Sal. Tardía</th>
                                        <th class="text-center" style="font-size:19px;">Horas Extras</th>
                                        <th class="text-center" style="font-size:19px;">Eventos</th>
                                        <th class="text-center" style="font-size:19px;">Licencias</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="pt-0 mt-0" v-for="(item,index) in ListaTrabajadores" :key="index">
                                        <td style="width:24%;" class="pl-2 pt-1">
                                            <div class="d-flex align-items-center">
                                                <div :style="'width:50px;height:50px;border-radius:50%;background-image:url('+item.Foto2+');background-size:cover;background-repeat:no-repeat;background-position:center'">
                                                </div>
                                                <h4 class="text-info ml-4" style="font-size:19px;line-height:0">{{ item.Nombre }}</h4>
                                            </div>
                                        </td>
                                        <td><h4 class="text-info" style="font-size:19px;line-height:0">{{ item.Categoria }}</h4></td>
                                        <td class="text-center"><h2 class="text-info" style="font-size:39px;line-height:0">{{ item.Trabajados }}</h2></td>
                                        <td class="text-center"><h2 class="text-info" style="font-size:39px;line-height:0">{{ item.Ausencias }}</h2></td>
                                        <td class="text-center"><h2 class="text-success" style="font-size:39px;line-height:0">{{ item.Vacaciones }}</h2></td>
                                        <td class="text-center"><h2 class="text-info" style="font-size:39px;line-height:0">{{ item.Permisos }}</h2></td>
                                        <td class="text-center"><h2 class="text-danger" style="font-size:39px;line-height:0">{{ item.Retrasos }}</h2></td>
                                        <td class="text-center"><h2 class="text-info" style="font-size:39px;line-height:0">{{ item.SalidaAnticipada }}</h2></td>
                                        <td class="text-center"><h2 class="text-info" style="font-size:39px;line-height:0">{{ item.SalidaTardia }}</h2></td>
                                        <td class="text-center"><h2 style="font-size:39px;color:#8A5488;line-height:0">{{ item.HorasExtra }}</h2></td>
                                        <td class="text-center"><h2 class="text-info" style="font-size:39px;line-height:0">{{ item.Eventos }}</h2></td>
                                        <td class="text-center"><h2 class="text-info" style="font-size:39px;line-height:0">{{ item.Licencias }}</h2></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </b-overlay>
    </div>
</template>

<script>
export default {
    name:"TablaGeneral",
    data(){
        return{
            isOverlay:false,
            isVisible:false,
            masks: {
				input: "DD/MM/YYYY"
			},
            rangeDate:{
                start:null,
                end:null
            },
            ListaTrabajadores:[],
            ListaComboTrabajadores:[],
            IdTrabajador:null
        }
    },
    methods: {
        async GetListaComboTrabajadores(){
            await this.$http.get('trabajador/checkin',{
            }).then((res)=>{
                this.ListaComboTrabajadores=res.data.data.trabajadores.map(function(obj){
                    return {id:obj.IdTrabajador,label:obj.Nombre}
                });
            })
        },
        async GetListaTrabajadores(){
            this.isOverlay=true;
            await this.$http.get('checkincontadorgrafica',{
                params:{
                    Fecha_I:this.rangeDate.start,
                    Fecha_F:this.rangeDate.end,
                    IdTrabajador:this.IdTrabajador
                }
            }).then((res)=>{
                // res.data.data.forEach((Trabajador)=>{
                //     let Horas=0;
                //     Trabajador.HorasTiposServicio.forEach((Hora)=>{
                //         Horas+=parseFloat(Hora.Horas);
                //     })
                //     Trabajador.HoraTotal=parseInt(Horas);
                // })
                // res.data.data.sort((a,b)=>b.HoraTotal - a.HoraTotal);
                this.ListaTrabajadores=res.data.data;
            }).finally(()=>{
                this.isOverlay=false;
            })
        },
        mostrarfiltro(){
            this.isVisible=true;
        },
        ocultarfiltro(){
            this.isVisible=false;
        }
    },
    created() {
        var date = new Date(), y = date.getFullYear(), m = date.getMonth();
        var firstDay = new Date(y, m, 1);
        var lastDay = new Date(y, m + 1, 0);
        this.rangeDate={
            start:firstDay,
            end:lastDay
        }
        this.GetListaComboTrabajadores();
    },
    mounted() {
        this.GetListaTrabajadores();
    },
}
</script>

<style>

</style>