<template>
    <CHeader :pHeaderProps="HeaderProps">
        <template slot="Contenido" v-if="!VerDetalle">
            <CLoader :pConfigLoad="ConfigLoad">
                <template slot="BodyFormLoad">
                    <div v-show="ListRefacciones.length > 0" class="row mt-3" v-for="(item,index) in ListRefacciones" :key="index" @click="IrADetalle(item)">
                        <div class="col-12 col-sm-12">
                            <div class="card card-01 shadow">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-2 col-sm-2 position-relative d-flex align-items-center">
                                            <i class="fa fa-cog fa-3x icon-color-app"></i>
                                        </div>
                                        <div class="col-10 col-sm-10">
                                            <p><b>Nombre:</b> {{ item.Descripcion }}</p>
                                            <p><b>Marca:</b> {{ item.Marca }}</p>
                                            <p><b>Modelo:</b> {{ item.Modelo }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-show="ListRefacciones.length == 0"  class="row mt-3">
                        <div class="col-12 col-sm-12 text-center mt-3">
                            <h2>Sin Refacciones.</h2>
                        </div>
                    </div>
                </template>
            </CLoader>
        </template>
        <template v-else slot="Contenido">
            <div class="row p-2">
                <div class="col-12 col-sm-12 mb-2">
                    <p><b>Cantidad:</b></p>
                    <p>{{ RefaccionDetalle.Cantidad }}</p>
                    <div class="border-bottom"></div>
                </div>
                <div class="col-12 col-sm-12 mb-2">
                    <p><b>No. De Parte:</b></p>
                    <p>{{ RefaccionDetalle.NumParte }}</p>
                    <div class="border-bottom"></div>
                </div>
                <!-- <div class="col-12 col-sm-12 mb-2">
                    <p><b>O/R:</b></p>
                    <p>{{ RefaccionDetalle.Cantidad }}</p>
                    <div class="border-bottom"></div>
                </div> -->
                <div class="col-12 col-sm-12 mb-2">
                    <p><b>Reemplazo:</b></p>
                    <p>{{ RefaccionDetalle.Periodo }} {{TiempoReemplazoStr(RefaccionDetalle.Periodo,RefaccionDetalle.TipoPeriodo)}}</p>
                    <div class="border-bottom"></div>
                </div>
                <div class="col-12 col-sm-12 mb-2">
                    <p><b>Tipo:</b></p>
                    <p>{{ RefaccionDetalle.Tipo == 1 ? 'Original' : 'Genérico' }}</p>
                    <div class="border-bottom"></div>
                </div>
                <div class="col-12 col-sm-12 mb-2">
                    <p><b>Valor Aproximado:</b></p>
                    <p>${{ RefaccionDetalle.Valor }}</p>
                    <div class="border-bottom"></div>
                </div>
                <div class="col-12 col-sm-12 mb-2">
                    <p><b>Fecha de Actualización de Valor:</b></p>
                    <p>{{ $getCleanDate(RefaccionDetalle.FechaActValor,false) }}</p>
                    <div class="border-bottom"></div>
                </div>
                <div v-show="RefaccionDetalle.Descarga!=''" class="col-12 col-sm-12 mb-2">
                    <a target="_blank" :href="RefaccionDetalle.Archivo" :download="RefaccionDetalle.Descarga" role="button" class="btn btn-01 btn-grande">
                        Descargar Archivos
                    </a>
                </div>
            </div>
        </template>
    </CHeader>
</template>

<script>
import CHeader from "@/views/webapp/components/HeaderWebApp.vue";
export default {
    props:['pEquipo'],
    components:{CHeader},
    data() {
        return {
            HeaderProps:{
                ShowSubHeader:true,
                ShowTecnico:false,
                ShowFechaHoy:false,
                ShowMenuDropdown:false,
                ShowFotoPerfil:false,
                ShowReturn:true,
                TextoHeader:'Refacciones',
                ShowLogo:false,
                TextoCentroSubHeader:''
            },
            Filtro:{
                TotalItem:0,
                Pagina:1,
                Entrada:20
            },
            ConfigLoad:{
				ShowLoader:true,
				ClassLoad:false,
			},
            ListRefacciones:[],
            Equipo:{},
            VerDetalle:false,
            RefaccionDetalle:{
                IdRefaccion:0,
                IdActivo:0,
                Descripcion:'',
                Marca:'',
                Modelo:'',
                Cantidad:'',
                NumParte:'',
                Periodo:'',
                Tipo:'',
                Valor:0,
                FechaActValor:'',
                Archivo:''
            }
        }
    },
    methods: {
        async Lista(){
            this.ConfigLoad.ShowLoader=true;
            this.ConfigLoad.ClassLoad=false;
            await this.$http.get('refacciones/get',{
                params:{
                    IdActivo:this.Equipo.ActivoId
                }
            }).then((res)=>{
                this.ListRefacciones=res.data.data.refacciones;
            }).finally(()=>{
                this.ConfigLoad.ShowLoader=false;
                this.ConfigLoad.ClassLoad=true;
            });
        },
        IrADetalle(item){
            this.RefaccionDetalle=item;
            this.VerDetalle=true;
        },
        Regresar(){
            if (this.VerDetalle) {
                this.VerDetalle=false;
            }
            else{
                this.$router.push({name:'EquiposServicioWebApp'});
            }
        },
        TiempoReemplazoStr(Periodo,TipoPeriodo){
            let Str='';
            switch (TipoPeriodo) {
                case null:
                    Str='';
                    break;
                case '1':
                    Str=parseInt(Periodo) > 1 ? 'Meses' : 'Mes'
                    break;
                case '2':
                    Str=parseInt(Periodo) > 1 ? 'Años' : 'Año'
                    break;
            }
            return Str;
        }
    },
    created() {
        this.bus.$off('EmitReturn');
        this.bus.$on('EmitReturn',()=>{
            this.Regresar();
        });
    },
    mounted() {
        this.Equipo=this.pEquipo;
        this.HeaderProps.TextoCentroSubHeader=this.Equipo.Nequipo;
        this.Lista();
    },
}
</script>

<style>

</style>