<template>
	<div>
		<span v-if="ShowButtonGeneral">
			<span v-if="ShowButtonEdit">
				<button
					v-b-tooltip.hover.right
					v-if="Esmodal"
					:title="ButtonEditTitle"
					@click="Editar(Id, Obj, Esmodal)"
					type="button"
					class="btn-icon mr-2"
					data-toggle="modal"
					:data-target="pEmitSeccion===undefined ? '#ModalForm' : '#'+pEmitSeccion"
					data-backdrop="static"
					data-keyboard="false"
				>
					<i class="fas fa-pencil-alt"></i>
				</button>
				<button
					v-b-tooltip.hover.right
					v-else
					:title="ButtonEditTitle"
					@click="Editar(Id, Obj, Esmodal)"
					type="button"
					class="btn-icon mr-2"
					data-backdrop="static"
					data-keyboard="false"
				>
					<i class="fas fa-pencil-alt"></i>
				</button>
			</span>
		</span>
		<slot name="btnaccion"> </slot>
		<!-- Perfil "Admin" corresponde a IdPerfil 2 (después de normalizar), candado "admin" corresponde a Sofanor -->
		<span v-if="PermisoAdmin.Perfil == 'Admin' || PermisoAdmin.Candado == 'admin' || DeleteShowAll">
			<span v-if="ShowButtonGeneral">
				<span v-if="ShowButtonDelete">
					<button
						v-b-tooltip.hover.right
						title="Eliminar"
						v-if="this.Btndelete"
						@click="Delete(Id)"
						type="button"
						class="btn-icon-02 mr-2"
					>
						<i class="fas fa-trash"></i>
					</button>
				</span>
			</span>
		</span>
		

		<!--
    <a-dropdown :trigger="['click']">
        <a class=" far fa-ellipsis-v fa-2x color-pantone-01" @click="e => e.preventDefault()">
      
    </a>
    <a-menu slot="overlay">
       <a-menu-item   key="0">
         
                                                        
      </a-menu-item>
      <a-menu-item v-if="ShowButtonGeneral" key="1">
           
      </a-menu-item>
      <a-menu-divider />
      
    </a-menu>
  </a-dropdown>-->
	</div>
</template>

<script>
export default {
	name: "Cbtnaccion",
	props: [
		"isModal",
		"Id",
		"IrA",
		"ShowButtonG",
		"PBtndelete",
		"pShowButtonEdit",
		"pShowButtonDelete",
		"pEmitSeccion",
		"pButtonEditTitle",
		"pObj",
		"pDeleteShowAll"
	],
	data() {
		return {
			Esmodal: this.isModal,
			ShowButtonGeneral: true,
			Btndelete: true,
			ShowButtonEdit: true,
			ShowButtonDelete: true,
			ButtonEditTitle:'Editar',
			PermisoAdmin: [],
			Obj:{},
			EmitSeccion:'',
			DeleteShowAll:false
		};
	},
	methods: {
		Editar(Id,Obj) {
			if (this.Esmodal == true) {
				if (this.pEmitSeccion!=undefined) {
					if (this.pObj!==undefined) {
						this.bus.$emit("NewModal_"+this.EmitSeccion,false,Id,Obj);
					}
					else{
						this.bus.$emit("NewModal_"+this.EmitSeccion,false,Id);
					}
				}
				else{
					this.bus.$emit("Nuevo", false, Id);
				}
			} else {
				//this.$root.$emit('Nuevo',false,Id);
				this.$router.push({ name: this.IrA, params: { Id: Id } });
			}
		},
		Delete(Id) {
			if (this.pEmitSeccion!==undefined) {
				this.bus.$emit("Delete_"+this.pEmitSeccion, Id);
			}
			else{
				this.bus.$emit("Delete", Id);
			}
		}
	},
	created() {
		//sesión donde se obtiene permisos de administrador eliminar
		this.PermisoAdmin = JSON.parse(sessionStorage.getItem("user"));

		if (this.ShowButtonG != undefined) {
			this.ShowButtonGeneral = this.ShowButtonG;
		}

		if (this.PBtndelete != undefined) {
			this.Btndelete = this.PBtndelete;
		}
		if (this.pShowButtonEdit != undefined) {
			this.ShowButtonEdit = this.pShowButtonEdit;
		}
		if (this.pShowButtonDelete != undefined) {
			this.ShowButtonDelete = this.pShowButtonDelete;
		}
		if (this.pEmitSeccion != undefined) {
			this.EmitSeccion=this.pEmitSeccion;
		}
		if (this.pButtonEditTitle != undefined) {
			this.ButtonEditTitle=this.pButtonEditTitle;
		}
		if (this.pDeleteShowAll != undefined) {
			this.DeleteShowAll=this.pDeleteShowAll;
		}
		if (this.pObj != undefined) {
			this.Obj=this.pObj;
		}
	}
};
</script>
