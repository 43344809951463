<template>
    <div>
        <div class="row mt-2">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                <nav class="navbar navbar-breadcrumb navbar-expand-md bg-breadcrumb breadcrumb-borde">
                    <div class="mr-auto">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb clearfix pt-3">
                                <li class="breadcrumb-item active">Dashboard de Administración de Personal</li>
                            </ol>
                        </nav>
                    </div>
                </nav>
            </div>
        </div>

        <div class="form-row mt-2">
            <CTablaGeneral></CTablaGeneral>
            <CContadorBarras></CContadorBarras>
        </div>
    </div>
</template>

<script>
import CTablaGeneral from '@/views/modulos/dashboard/checkin/TablaGeneral.vue';
import CContadorBarras from '@/views/modulos/dashboard/checkin/ContadorBarra.vue';
export default {
    components:{CTablaGeneral,CContadorBarras},
    data() {
        return {
            Head:{
                Title:'Dashboard Administración de Personal',
                BtnNewShow:false,
                BtnNewName:'Nuevo',
                isreturn:false,
                isModal:false,                 
                isEmit:false,
                Url:'',
                ObjReturn:'',
            },
            // ShowPuntuacion:false,
            // ListaPaquetes:[]
        }
    },
}
</script>

<style>

</style>