<template>
    <div>
        <CHead :oHead="Head">
            <template slot="component">
                <button @click="DescargarPDF()" :disabled="disabledBtn" type="button" class="btn btn-pink mr-2">
                    <i v-show="disabledBtn" class="fa fa-spinner fa-pulse fa-1x fa-fw"></i>
                    <i v-show="!disabledBtn" class="mr-2 fa fa-file-pdf"></i>{{ txtBoton }}</button>
            </template>
        </CHead>

        <div class="row mt-2">
            <div class="col-lg-12">
                <div class="card">
                    <CLoader :pConfigLoad="ConfigLoad">
                        <template slot="EvidenciaLoad">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-xs-12 col-lg-2">
                                        <img class="img img-thumbnail" :src="Evidencia.Empresa.Logo">
                                        <div class="mt-2">
                                            <h3><b>Teléfono: </b>{{ Evidencia.Empresa.TelefonoPublico }}</h3>
                                            <h3><b>Correo: </b>{{ Evidencia.Empresa.CorreoPublico }}</h3>
                                        </div>
                                    </div>
                                    <div class="col-xs-12 col-lg-5">
                                        <h2><b>Datos del Cliente</b></h2>
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <h3><b>Cliente:</b></h3>
                                                <h3>{{ Evidencia.Cliente.Cliente }}</h3>
                                                <h3><b>Propiedad:</b></h3>
                                                <h3>{{ Evidencia.Cliente.Sucursal }}</h3>
                                                <h3><b>Dirección:</b></h3>
                                                <h3>{{ Evidencia.Cliente.Direccion }}</h3>
                                            </div>
                                            <div class="col-lg-6">
                                                <h3><b>Contacto:</b></h3>
                                                <h3>{{ Evidencia.Cliente.ContactoS }}</h3>
                                                <h3><b>Teléfono:</b></h3>
                                                <h3>{{ Evidencia.Cliente.Telefono }}</h3>
                                            </div>
                                        </div>
    
                                    </div>
    
                                    <div class="col-xs-12 col-lg-5">
                                        <h2><b>Datos del Servicio</b></h2>
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <h3><b>Folio:</b></h3>
                                                <h3>{{ Evidencia.Servicio.Folio }}</h3>
                                                <h3><b>Servicio:</b></h3>
                                                <h3>{{ Evidencia.Servicio.Concepto }}</h3>
                                                <h3><b>Contrato:</b></h3>
                                                <h3>{{ Evidencia.Servicio.NumContrato }}</h3>
                                                <h3><b>Fecha:</b></h3>
                                                <h3>{{ $getCleanDate(Evidencia.Servicio.Fecha_I,false) }}</h3>
                                            </div>
                                            <div class="col-lg-6">
                                                <h3><b>Hora Inicio:</b></h3>
                                                <h3>{{ Evidencia.Servicio.HoraInicio }}</h3>
                                                <h3><b>Hora Fin:</b></h3>
                                                <h3>{{ Evidencia.Servicio.HoraFin }}</h3>
                                                <h3><b>Responsable:</b></h3>
                                                <h3>{{ Evidencia.Servicio.Nombre }}</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="Evidencia.ListaGeneral.length>0">
                                    <div class="row">
                                        <div class="col-lg-12 mb-3">
                                            <div class="d-flex w-25 justify-content-center" style="background-color:green" >
                                                <h1 class="text-white">Imagenes del servicio</h1>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row row-cols-md-10 row-cols-lg-10 row-cols-xl-2">
                                        <div v-for="(lista,key,index) in Evidencia.ListaGeneral" :key="index" class="col-md-2 col-lg-2 col-xl-2 card-group">
                                            <div class="card mb-3" style="border:2px solid #0F3F87;">
                                                <div class="card-body m-0 p-1">
                                                    <div class="row">
                                                        <div class="col-lg-12">
                                                            <!-- <div style="background-image:url(https://www.elsudcaliforniano.com.mx/circulos/fgcz20-tips-para-comprar-un-aire-acondicionado/ALTERNATES/LANDSCAPE_768/Tips%20para%20comprar%20un%20aire%20acondicionado);background-size:contain;width:100%;height:150px;background-repeat:no-repeat;background-position:center"></div> -->
                                                            <!-- <div :style="'background-image:url('+listaevidencia.Imagen+');background-size:contain;width:100%;height:150px;background-repeat:no-repeat;background-position:center'"></div> -->
                                                            <img class="card-img" :src="lista.Imagen" alt="">
                                                            <!-- <div style="background:#b9b9b9;background-size:contain;width:100%;height:150px;background-repeat:no-repeat;background-position:center"></div> -->
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="mt-2 mb-2 ml-2 col-lg-12">
                                                            <h3>Descripción:</h3>
                                                            <p v-if="lista.Descripcion2==''">{{ lista.Descripcion }}</p>
                                                            <p v-else>{{ lista.Descripcion2 }}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="Evidencia.ListaEquipos.length>0">
                                    <div v-for="(lista,key,index) in Evidencia.ListaEquipos" :key="index">
                                        <div class="row">
                                            <div class="col-lg-12 mb-3">
                                                <div class="d-flex w-25 justify-content-center" :style="lista.EstatusEquipo=='Operando' ? 'background-color:green' : lista.EstatusEquipo=='En Observacion' ? 'background-color:yellow' : lista.EstatusEquipo=='Fuera de Servicio' ? 'background-color:red' : 'background-color:gray'" >
                                                    <h1 :class="lista.EstatusEquipo=='En Observacion' ? '': 'text-white'">{{ lista.Nequipo }}</h1>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row row-cols-md-10 row-cols-lg-10 row-cols-xl-2">
                                            <div v-for="(listaevidencia,key,index) in lista.Imagenes" :key="index" class="col-md-2 col-lg-2 col-xl-2 card-group">
                                                <div class="card mb-3" style="border:2px solid #0F3F87;cursor:pointer;">
                                                    <div class="card-body m-0 p-1">
                                                        <div class="row">
                                                            <div class="col-lg-12">
                                                                <!-- <div style="background-image:url(https://www.elsudcaliforniano.com.mx/circulos/fgcz20-tips-para-comprar-un-aire-acondicionado/ALTERNATES/LANDSCAPE_768/Tips%20para%20comprar%20un%20aire%20acondicionado);background-size:contain;width:100%;height:150px;background-repeat:no-repeat;background-position:center"></div> -->
                                                                <!-- <div :style="'background-image:url('+listaevidencia.Imagen+');background-size:contain;width:100%;height:150px;background-repeat:no-repeat;background-position:center'"></div> -->
                                                                <img class="card-img" :src="listaevidencia.Imagen" alt="">
                                                                <!-- <div style="background:#b9b9b9;background-size:contain;width:100%;height:150px;background-repeat:no-repeat;background-position:center"></div> -->
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="mt-2 mb-2 ml-2 col-lg-12">
                                                                <h3>Descripción:</h3>
                                                                <p v-if="listaevidencia.Descripcion2==''">{{ listaevidencia.Descripcion }}</p>
                                                                <p v-else>{{ listaevidencia.Descripcion2 }}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
    
                            </div>
                        </template>
                        
                    </CLoader>  
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Clist from '@/components/Clist.vue';
import CLoader from "@/components/CLoader.vue"
export default{
    props:[
        "IdServicio",
        "Equipo",
        "Cliente",
        "ClienteS",
        "isMonitoreo",
        "RutaIcoEmp",
        "isOportunidad",
        "isContacto",
        "pFiltro",
        "pRangeDate",
        "pObjPropiedades",
    ],
    components:{
        CLoader
    },
    data(){
        return{
            Head:{
                Title:'Visualizar Evidencia',
                BtnNewShow:false,
                BtnNewName:'Nuevo',
                isreturn:true,
                isModal:false,    

                isEmit:true,
                NameReturn:'Regresar',
                // Url:this.isMonitoreo ? 'mon_histequipo':'listservicio',
                // ObjReturn:this.isMonitoreo ? {oEquipo: this.Equipo, oCliente:this.Cliente,oClienteSucursal:this.ClienteS} : {},

            },
            ConfigLoad:{
                ShowLoader:false,
                ClassLoad:true
            },
            Evidencia:{
                Empresa:{
                    Foto:'',
                    Telefono:'',
                    Correo:''
                },
                Servicio:{
                    
                },
                Cliente:{
                    IdCliente:'',
                    IdClienteS:'',
                    Cliente:'',
                    Sucursal:'',
                    Direccion:'',
                    Telefono:'',
                    Contacto:''
                },
                ListaEquipos:[],
                ListaGeneral:[]
            },
            txtBoton:"Descargar PDF",
            disabledBtn:false,
            ListaPropiedades:[],
            IndexPropiedad:0
        }
    },
    methods:{
        ObtenerServicio(){
            this.ConfigLoad.ShowLoader=true;
            this.$http.get("monitoreo/evidenciaservicio",
            {
                params:{
                    IdServicio:this.IdServicio
                }
            }).then((res)=>{
                this.Evidencia.Servicio=res.data.data.servicio[0];
                this.Evidencia.Empresa=res.data.data.DatosEmpresa.data;
                this.Evidencia.Cliente=res.data.data.cliente[0];
                this.Evidencia.ListaEquipos=res.data.data.equipos;
                this.Evidencia.ListaGeneral=Object.values(res.data.data.ImagenesGeneral);
                this.ConfigLoad.ShowLoader=false;
            })
        },
        DescargarPDF(){
            this.disabledBtn=true;
            this.txtBoton="Espere...";
            this.$http.get('reporte/servicioevidencia',
            {
                responseType: 'blob',
                params :
                    {
                        IdServicio:this.IdServicio,
                    }
            }).then( (response) => {

                let pdfContent = response.data;
                let file = new Blob([pdfContent], { type: 'application/pdf' });
                let fileUrl = URL.createObjectURL(file);
                window.open(fileUrl);
				// pdfWindow.document.write(
				// "<iframe width='100%' height='100%' src='" +
				// 	fileUrl +
				// 	"'></iframe>"
			    // );
                this.disabledBtn=false;
                this.txtBoton="Descargar PDF";
            });
        }
    },
    created(){
        if (this.isOportunidad==undefined) {
            this.isOportunidad=false;
        }
        if (this.isContacto==undefined) {
            this.isContacto=false;
        }
        this.ObtenerServicio();
        this.bus.$off("Regresar");
        this.bus.$on("Regresar", () => {
            if (this.isMonitoreo) {
                this.$router.push({
				    name: "mon_histequipo",
				    params: { oEquipo: this.Equipo, oCliente:this.Cliente,oClienteSucursal:this.ClienteS,rutaIcoEmp:this.RutaIcoEmp }
			    });   
            }else if(this.isOportunidad){
                this.$router.push({
				    name: "oportunidadesserviciolista",
				    params: {  }
			    }); 
            } else if(this.isContacto){
                this.$router.push({
                    name:'propiedades',
                    params:{
                        pOrigen:'Evidencia'
                    }
                });
            }
            else{
                this.$router.push({
				    name: "listservicio",
                    params:{pFiltro:this.pFiltro,pRangeDate:this.pRangeDate}
                });

            }
		});
        if (this.isMonitoreo) {
            sessionStorage.setItem("ocliente", JSON.stringify(this.Cliente));
            sessionStorage.setItem("oClienteSucursal",JSON.stringify(this.ClienteS));
            sessionStorage.setItem("oEquipo",JSON.stringify(this.Equipo));   
        }
        if (this.isContacto) {
            if (this.pObjPropiedades!=undefined) {
                sessionStorage.setItem("indexpropiedad",this.pObjPropiedades.IndexPropiedad);
                sessionStorage.setItem("propiedades",JSON.stringify(this.pObjPropiedades.Propiedades));
                sessionStorage.setItem("propiedadestodas",JSON.stringify(this.pObjPropiedades.PropiedadesTodas));
                sessionStorage.setItem("filtros",JSON.stringify({Pagina:this.pObjPropiedades.Pagina,Nombre:this.pObjPropiedades.Nombre}));
            }
        }
    }
}
</script>