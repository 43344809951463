<template>
    <div>
        <!-- <header class="mov_box_01">
            <img src="../../images/logo_84x30.png" alt="" class="img-fluid">
        </header>
        <main class="mov_box_02">
            <div class="container-fluid">
             <div class="row">
                <div class="col-9 col-sm-9">
                    <img :src="Foto" alt="Diego" class="rounded-circle img-fluid float-left mr-2" style="width: 50px;">
                    <div class="mt-2" style="line-height: 1rem;"><span>{{ Usuario.Nombre + ' ' + Usuario.Apellido }}</span><br><span>Viernes 2 de Febrero</span></div>
                </div>
                <div class="col-3 col-sm-3 text-right">
                    <div class="dropdown mt-2">
                        <button class="btn btn-01 dropdown-toggle toggle-menu" type="button" data-toggle="dropdown" aria-expanded="false">
                            <i class="fas fa-ellipsis-v fa-fw"></i>
                        </button>
                        <div class="dropdown-menu dropdown-menu-right">
                          <a class="dropdown-item" href="#"><img src="images/team-2.jpg" alt="" class="avatar"> Mi Perfil</a>
                          <a class="dropdown-item" href="#">
                            <div class="avatar bg-gradient-secondary  me-3  my-auto">
                                <i class="fas fa-sign-out-alt"></i>
                            </div>
                            Salir</a>
                        </div>
                    </div>
                </div>
             </div>
            </div>
        </main> -->
        <transition name="fade" mode="out-in">
            <router-view :key="$route.fullPath" />
        </transition>
        <!-- <section class="container-fluid">
        </section> -->
    </div>
    
</template>

<script>
export default {
    data() {
        return {
            Usuario:{},
            Foto:''
        }
    },
    methods: {
        GetDatos(){
            const path = window.location.pathname;
            if (path.includes('WebApp')) {
                import('@/style/css/mainwebapp.css');
            }
            // let Datos=JSON.parse(sessionStorage.getItem("user"));
            // let ruta = sessionStorage.getItem("ruta");
            // this.Foto = ruta + Datos.Foto2;
            // this.Usuario=Datos;
            // this.$router.push({ name: "HomeWebApp", params: {} });
        },
        // loadCSSFile(href){
        //     const linkElement = document.createElement('link');
        //     linkElement.rel = 'stylesheet';
        //     linkElement.href = href;
        //     document.head.appendChild(linkElement);
        // }
    },
    mounted() {
        this.GetDatos();
        // let file = document.createElement('link');
        // file.rel = 'stylesheet';
        // file.href = '/style/css/mainwebapp.css'
        // document.head.appendChild(file)
    },
}
</script>

<!-- <style scoped src="@/style/css/mainwebapp.css"></style> -->
<!-- <style lang="css" scoped>
@import "../../style/css/mainwebapp.css"
</style> -->