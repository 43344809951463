<template>
    <div class="col-md-12 col-lg-12 col-xl-12 mb-2">
        <b-overlay :show="isOverlay" rounded="sm" spinner-variant="primary" class="h-100">
            <div class="card card-grafica" style="max-height:510px;overflow-y:auto">
                <div class="card-body">
                    <div class="filtro-grafic" id="grafica_002" v-if="isVisible">
                        <div class="row justify-content-start">
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <form class="form-inline">
                                    <label class="title-grafic side-l mr-3">Horas por Tipo Servicios - Técnico</label>
                                    <v-date-picker
										mode='range'
										v-model='rangeDate'
										@input="GetListaTrabajadores"
										:input-props='{
											class: "form-control form-control mb-2 mr-1 calendar",
											placeholder: "Selecciona un rango de fecha para buscar",
											readonly: true
										}'
										/>
                                    <treeselect
                                        class="d-inline-block w-25 mt-0 pt-0 ml-2"
                                        style="top: -4px;"
                                        :maxHeight="180"
                                        :options="ListaComboTrabajadores"
                                        placeholder="Trabajador..."
                                        v-model="IdTrabajador"
                                        @input="GetListaTrabajadores()"
                                    />
                                </form>
                                <button type="button" class="btn close abs_01" @click="ocultarfiltro()"><i class="fal fa-times"></i></button>
                            </div>
                        </div>
                    </div>
                    <div class="row">
						<div class="col-10 col-sm-10 col-md-8 col-lg-8">
							<h6 class="title-grafic side-l">Horas por Tipo Servicios - Técnico</h6>
						</div>
						<div class="col-2 col-sm-2 col-md-4 col-lg-4 text-right">
							<button	type="button" class="btn-fil-002" title="Filtros" @click="mostrarfiltro()">
								<i class="fas fa-filter"></i>
							</button>
						</div>

						<div class="col-12 col-sm-12 col-md-12 col-lg-12">
							<hr />
						</div>
					</div>
                    <div class="row">
                        <div class="table-responsive">
                            <table class="table-dashboard-despacho">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th class="text-center" style="font-size:19px;">TOTAL</th>
                                        <th class="text-center" style="font-size:19px;">Mantenimiento</th>
                                        <th class="text-center" style="font-size:19px;">Servicio</th>
                                        <th class="text-center" style="font-size:19px;">Proyecto</th>
                                        <th class="text-center" style="font-size:19px;">Garantía</th>
                                        <th class="text-center" style="font-size:19px;">No Productivo</th>
                                        <th class="text-center" style="font-size:19px;">Oportunidades</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item,index) in ListaTrabajadores" :key="index">
                                        <td style="width:26%" class="p-2">
                                            <div class="d-flex align-items-center">
                                                <div :style="'width:50px;height:50px;border-radius:50%;background-image:url('+item.Foto2+');background-size:cover;background-repeat:no-repeat;background-position:center'">
                                                </div>
                                                <h4 class="text-info ml-4" style="font-size:19px;">{{ item.Nombre }}</h4>
                                            </div>
                                        </td>
                                        <td class="tw-1"><h2 class="text-center textonaranja" style="font-size:39px;">{{ item.HoraTotal }}</h2></td>
                                        <td class="tw-1"><h2 :class="parseInt(item.HorasTiposServicio[0].Horas)==0 ? 'text-center texto-claro' : 'text-center text-info'" style="font-size:39px;">{{ parseInt(item.HorasTiposServicio[0].Horas) }}</h2></td>
                                        <td class="tw-1"><h2 :class="parseInt(item.HorasTiposServicio[1].Horas)==0 ? 'text-center texto-claro' : 'text-center text-info'" style="font-size:39px;">{{ parseInt(item.HorasTiposServicio[1].Horas) }}</h2></td>
                                        <td class="tw-1"><h2 :class="parseInt(item.HorasTiposServicio[2].Horas)==0 ? 'text-center texto-claro' : 'text-center text-info'" style="font-size:39px;">{{ parseInt(item.HorasTiposServicio[2].Horas) }}</h2></td>
                                        <td class="tw-1"><h2 :class="parseInt(item.HorasTiposServicio[4].Horas)==0 ? 'text-center texto-claro' : 'text-center text-info'" style="font-size:39px;">{{ parseInt(item.HorasTiposServicio[4].Horas) }}</h2></td>
                                        <td class="tw-1"><h2 :class="parseInt(item.HorasTiposServicio[3].Horas)==0 ? 'text-center texto-claro' : 'text-center text-info'" style="font-size:39px;">{{ parseInt(item.HorasTiposServicio[3].Horas) }}</h2></td>
                                        <td class="tw-1"><h2 :class="parseInt(item.HorasTiposServicio[5].Horas)==0 ? 'text-center texto-claro' : 'text-center text-info'" style="font-size:39px;">{{ parseInt(item.HorasTiposServicio[5].Horas) }}</h2></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </b-overlay>
    </div>
</template>
<script>
export default {
    name:"HorasTipoServicioTecnico",
    data() {
        return {
            isOverlay:false,
            isVisible:false,
            masks: {
				input: "DD/MM/YYYY"
			},
            rangeDate:{
                start:null,
                end:null
            },
            ListaClientes:[],
            ListaSucursales:[],
            ListaTrabajadores:[],
            ListaComboTrabajadores:[],
            IdCliente:null,
            IdClienteS:null,
            IdTrabajador:null
        }
    },
    methods: {
        // async GetListaClientes(){
		// 	await this.$http.get("clientes/get"
		// 	).then((res)=>{
		// 		this.ListaClientes=res.data.data.clientes.map(function(obj){
		// 			return {id:obj.IdCliente,label:obj.Nombre}
		// 		})
		// 	});
		// },
        // GetListaSucursales(){
		// 	this.sucursalId=null;
		// 	this.$http.get("clientesucursal/get",{
		// 		params:{
		// 			Todos:true,
		// 			RegEstatus:'A',
		// 			IdCliente:this.IdCliente
		// 		}
		// 	}
		// 	).then((res)=>{
		// 		this.ListaSucursales=res.data.data.sucursal.map(function(obj){
		// 			return {id:obj.IdClienteS,label:obj.Nombre}
		// 		})
		// 	});
		// },
        async GetListaComboTrabajadores(){
            await this.$http.get('despachoonehorasxtipoxtecnico/get',{
                params:{
                    Fecha_I:this.rangeDate.start,
                    Fecha_F:this.rangeDate.end,
                }
            }).then((res)=>{
                this.ListaComboTrabajadores=res.data.data.map(function(obj){
                    return {id:obj.IdTrabajador,label:obj.Nombre}
                });
            })
        },
        async GetListaTrabajadores(){
            this.isOverlay=true;
            await this.$http.get('despachoonehorasxtipoxtecnico/get',{
                params:{
                    Fecha_I:this.rangeDate.start,
                    Fecha_F:this.rangeDate.end,
                    IdCliente:this.IdCliente,
                    IdClienteS:this.IdClienteS,
                    IdTrabajador:this.IdTrabajador
                }
            }).then((res)=>{
                res.data.data.forEach((Trabajador)=>{
                    let Horas=0;
                    Trabajador.HorasTiposServicio.forEach((Hora)=>{
                        Horas+=parseFloat(Hora.Horas);
                    })
                    Trabajador.HoraTotal=parseInt(Horas);
                    // Trabajador;
                })
                res.data.data.sort((a,b)=>b.HoraTotal - a.HoraTotal);
                this.ListaTrabajadores=res.data.data;
                // this.ListaTrabajadores.forEach((Trabajador)=>{
                //     let Horas=0;
                //     Trabajador.HorasTipoServicio.forEach(element => {
                //         Horas+=parseFloat(element.Horas);
                //     });
                //     Trabajador.TotalHoras=Horas;
                // });
            }).finally(()=>{
                this.isOverlay=false;
            })
        },
        GetTotalHoras(item){
            let Horas=0;
            item.forEach(element => {
                Horas+=parseFloat(element.Horas);
            });
            return parseInt(Horas);
        },
        mostrarfiltro(){
            this.isVisible=true;
        },
        ocultarfiltro(){
            this.isVisible=false;
        }
    },
    created() {
        var date = new Date(), y = date.getFullYear(), m = date.getMonth();
        var firstDay = new Date(y, m, 1);
        var lastDay = new Date(y, m + 1, 0);
        this.rangeDate={
            start:firstDay,
            end:lastDay
        }
        this.GetListaComboTrabajadores();
    },
    mounted() {
        this.GetListaTrabajadores();
    },
}
</script>
<style>
    .textonaranja{
        color: #F8831B;
    }

    .texto-claro {
        color: #e7e9eb !important;
    }
</style>