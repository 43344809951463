<template>
    <div>
        <CHeader :pHeaderProps=HeaderProps>
            <template slot="Contenido">
                <CLoader :pConfigLoad="ConfigLoad">
                    <template slot="BodyFormLoad">
                        <div v-show="ListGastos.length > 0"  class="row mt-3" v-for="(item,index) in ListGastos" :key="index" @click="IrADetalleGasto(item.IdCajaC)">
                            <div class="col-12 col-sm-12">
                                <div class="card card-01 shadow">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-12 col-sm-12">
                                                <h2>{{ item.Caja }}</h2>
                                            </div>
                                        </div>
                                        <div class="row mt-2">
                                            <div class="col-6 col-sm-6">
                                                <p class="text-center"><b>Monto Asignado</b></p>
                                                <p class="text-center">${{ item.Monto }}</p>
                                            </div>
                                            <div class="col-6 col-sm-6">
                                                <p class="text-center"><b>Saldo Actual</b></p>
                                                <p class="text-center">${{ item.Actual }}</p>
                                            </div>
                                            <!-- <div class="col-3 col-sm-3">
                                                <span><b>Monto Asignado:</b></span>
                                            </div>
                                            <div class="col-3 col-sm-3">
                                                <span>${{ item.Monto }}</span>
                                            </div>
                                            <div class="col-3 col-sm-3">
                                                <span><b>Saldo Actual:</b></span>
                                            </div>
                                            <div class="col-3 col-sm-3">
                                                <span> ${{item.Actual }}</span>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </CLoader>
            </template>
        </CHeader>
    </div>
</template>

<script>
import CHeader from "@/views/webapp/components/HeaderWebApp.vue";
import moment from 'moment';
import CLoaderVue from '../../../components/CLoader.vue';
export default {
    components:{
        CHeader,
        CLoaderVue
    },
    data() {
        return {
            HeaderProps:{
                GoToHome:true
            },
            ConfigLoad:{
				ShowLoader:true,
				ClassLoad:true,
			},
            IdTrabajador:'',
            ListGastos:[]
        }
    },
    methods: {
        Lista(){
            this.ConfigLoad.ShowLoader=true;
            this.ConfigLoad.ClassLoad=false;
            this.$http.get('webapp/listgasto',{
                params:{
                    IdTrabajador:this.IdTrabajador
                }
            }).then((res)=>{
                this.ListGastos=res.data.Lista;
            }).finally(()=>{
                this.ConfigLoad.ShowLoader=false;
                this.ConfigLoad.ClassLoad=true;
            })
        },
        IrADetalleGasto(Index){
            this.$router.push({name:"GastoDetalleWebApp",params:{pIdCajaC:Index}})
        }
    },
    created() {
        this.IdTrabajador=JSON.parse(sessionStorage.getItem('user')).IdTrabajador;
    },
    mounted() {
        this.Lista();
    },
}
</script>

<style>

</style>