<template>
    <div>

        <input type="hidden" :value="calcularValores">
        <input type="hidden" :value="tipofiltrado">

        <section class="container-fluid">
            <div class="row mt-3">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                    <nav class="navbar navbar-breadcrumb navbar-expand-md bg-breadcrumb breadcrumb-borde">
                        <div class="mr-auto">
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb clearfix pt-3">
                                    <li class="breadcrumb-item"><a href="#" @click="Menu">Menú</a></li>
                                    <li class="breadcrumb-item active">Actualizar Facturación de Servicios</li>
                                </ol>
                            </nav>
                        </div>
                        <div class="form-inline">
                            <div class="form-group mt-n1">
                                <button @click="Descargar" type="button" class="btn btn-01 print mr-2">Imprimir</button>
                                <button :disabled="Disabled" @click="Guardar" type="button" class="btn btn-01 save mr-2"><i v-show="Disabled" class="fa fa-spinner fa-pulse fa-1x fa-fw"></i> {{txtSave}}</button>
                            </div>
                        </div>
                    </nav>

                </div>
            </div>
            <input type="hidden" :value="IdConfigS">
            <div class="row justify-content-start mt-3">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                    <div class="card card-01">
                        <div class="row">
                            <div class="col-md-12 col-lg-12">

                                <div class="form-inline justify-content-start mt-2">

                                    <label class="mr-1">Servicio</label>
                                    <select @change="ListaSubtipo();" v-model="IdConfigS"   class="form-control form-control-sm mr-2">
                                        <option v-for="(item, index) in ListaServicios" :key="index" :value="item.IdConfigS">{{item.Nombre}}</option>
                                        <option :value="6">Todos</option>
                                    </select>

                                    <label class="mr-1">Tipo Servicio</label>
                                    <select  @change="get_listdata();" v-model="IdTipoSubservicio"    class="form-control form-control-sm mr-2">
                                        <option :value="''">Todos</option>
                                        <option v-for="(item, index) in ListaTipoServicio" :key="index" :value="item.IdTipoSer">{{item.Concepto}}</option>
                                    </select>
                                    <Cvalidation v-if="this.errorvalidacion.IdTipoServ" :Mensaje="'Seleccione el Servicio'"></Cvalidation>

                                    <label class="mr-1">Año</label>
                                    <select :disabled="Disabled" @change="get_listdata"  v-model="Anio" class="form-control mr-2">
                                        <option v-for="(item,index) in ListaAnios" :key="index" :value="item">{{item}}</option>
                                    </select>

                                    <label class="mr-1">Mes</label>
                                    <select :disabled="Disabled" @change="get_listdata()"  v-model="Mes"  class="form-control form-control-sm mr-2">
                                        <option  value="">Todos</option>
										<option  :value="1">Enero</option>
                                        <option  :value="2">Febrero</option>
                                        <option  :value="3">Marzo</option>
                                        <option  :value="4">Abril</option>
                                        <option  :value="5">Mayo</option>
                                        <option  :value="6">Junio</option>
                                        <option  :value="7">Julio</option>
                                        <option  :value="8">Agosto</option>
                                        <option  :value="9">Septiembre</option>
                                        <option  :value="10">Octubre</option>
                                        <option  :value="11">Noviembre</option>
                                        <option  :value="12">Diciembre</option>
                                    </select>
                                </div>

                                <hr>
                                <div class="form-inline justify-content-start mt-3">
                                    <h6 class="text-center mr-2" v-if="cliente.Nombre != ''">{{$limitCharacters(cliente.Nombre,30)}}</h6>
                                    <h6 v-else class="text-center mr-2">Cliente Sin Selecionar</h6>
                                    <button @click="ListaCliente"  data-toggle="modal" data-target="#ModalForm3" data-backdrop="static" type="button" class="btn btn-01 search mr-2">Cliente</button>

									<label class="mr-1">No. De Contrato</label>
                                    <select @change="get_listdata" v-model="IdContrato" name="" id=""  class="form-control form-control-sm mr-2">
                                        <option :value="''">Seleccione Un Numero de Contrato</option>
                                        <option v-for="(item,index) in ListaNumc" :key="index" :value="item.IdContrato">
                                        	{{item.NumeroC}}
                                        </option>
                                    </select>
                                    <button @click="Limpiar"  type="button" class="btn btn-04"><i class="fas fa-times"></i></button>
                                </div>
                                <hr>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                <h5 v-if="cliente.Nombre != ''" class="mb-3 text-center">{{cliente.Empresa}} - ({{cliente.Nombre}})</h5>
                                <h5 v-else class="mb-3 text-center">Cliente Sin Selecionar</h5>
                            </div>
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                <div class="table-finanzas">
									<CLoader :pConfigLoad="ConfigLoad">
										<template slot="BodyFormLoad">
											<!-- <table class="table-fin-05" v-if="!loading"> -->
											<table class="table-fin-05" >
												<thead>
												<tr>
													<th></th>
													<th colspan="2" class="mediana text-center">Mes Actual</th>
													<th colspan="2" class="mediana text-center" style="background: #3399cc;">Mes Actual</th>
												</tr>
												<tr>
													<th class="sticky mediana marca"><b>Descripción</b></th>
													<th class="mediana text-center">Plan</th>
													<th class="mediana text-center">%</th>
													<th class="blue-02 mediana text-center">Actual</th>
													<th class="blue-02 mediana text-center">%</th>
												</tr>
												</thead>
												<tbody>

												<tr v-for="(item,index) in ListaDetalle" :key="index">
													<!-- TITULOS -->
													<td :class="item.Descripcion === 'Facturacion' ? 'sticky borde_bajo' : 'sticky' ">
														<b>{{item.Descripcion}}</b>
													</td>


													<!-- COLUMNA MONTO PLAN -->
													<template>
														<td v-if="item.Descripcion === 'Facturacion'" :class="item.Descripcion === 'Facturacion' ? 'sticky borde_bajo' : 'sticky' "><Cmoneda :activo="blockCampos" :minus="true" :clase="'form-control form-finanza form-control-sm text-center bold'"  currency="$" separator="," :precision="2" v-model="item.MontoPlan" />  </td>
														<td v-if="item.Descripcion === 'Materiales'"><Cmoneda :activo="blockCampos" :minus="true" :clase="'form-control form-finanza form-control-sm text-center bold'"  currency="$" separator="," :precision="2" v-model="item.MontoPlan"/>  </td>
														<td v-if="item.Descripcion === 'Equipos'"><Cmoneda :activo="blockCampos" :minus="true" :clase="'form-control form-finanza form-control-sm text-center bold'"  currency="$" separator="," :precision="2" v-model="item.MontoPlan"/>  </td>
														<td v-if="item.Descripcion === 'Mano de Obra'"><Cmoneda :activo="blockCampos" :minus="true" :clase="'form-control form-finanza form-control-sm text-center bold'"  currency="$" separator="," :precision="2" v-model="item.MontoPlan"/>  </td>
														<td v-if="item.Descripcion === 'Vehiculos'"><Cmoneda :activo="blockCampos" :minus="true" :clase="'form-control form-finanza form-control-sm text-center bold'"  currency="$" separator="," :precision="2" v-model="item.MontoPlan"/>  </td>
														<td v-if="item.Descripcion === 'Contratistas'"><Cmoneda :activo="blockCampos" :minus="true" :clase="'form-control form-finanza form-control-sm text-center bold'"  currency="$" separator="," :precision="2" v-model="item.MontoPlan"/>  </td>
														<td v-if="item.Descripcion === 'Viaticos'"><Cmoneda :activo="blockCampos" :minus="true" :clase="'form-control form-finanza form-control-sm text-center bold'"  currency="$" separator="," :precision="2" v-model="item.MontoPlan"/>  </td>
														<td v-if="item.Descripcion === 'Burden'"><Cmoneda :activo="blockCampos" :minus="true" :clase="'form-control form-finanza form-control-sm text-center bold'"  currency="$" separator="," :precision="2" v-model="item.MontoPlan"/>  </td>
													</template>
													<!-- <template>
														<td v-if="item.Descripcion === 'Facturacion'" :class="item.Descripcion === 'Facturacion' ? 'sticky borde_bajo' : 'sticky' "><Cmoneda :activo="true" :minus="true" :clase="'form-control form-finanza form-control-sm text-center bold'"  currency="$" separator="," :precision="2" v-model="item.MontoPlan" />  </td>
														<td v-if="item.Descripcion === 'Materiales'"><Cmoneda :activo="true" :minus="true" :clase="'form-control form-finanza form-control-sm text-center bold'"  currency="$" separator="," :precision="2" v-model="item.MontoPlan"/>  </td>
														<td v-if="item.Descripcion === 'Equipos'"><Cmoneda :activo="true" :minus="true" :clase="'form-control form-finanza form-control-sm text-center bold'"  currency="$" separator="," :precision="2" v-model="item.MontoPlan"/>  </td>
														<td v-if="item.Descripcion === 'Mano de Obra'"><Cmoneda :activo="true" :minus="true" :clase="'form-control form-finanza form-control-sm text-center bold'"  currency="$" separator="," :precision="2" v-model="item.MontoPlan"/>  </td>
														<td v-if="item.Descripcion === 'Vehiculos'"><Cmoneda :activo="true" :minus="true" :clase="'form-control form-finanza form-control-sm text-center bold'"  currency="$" separator="," :precision="2" v-model="item.MontoPlan"/>  </td>
														<td v-if="item.Descripcion === 'Contratistas'"><Cmoneda :activo="true" :minus="true" :clase="'form-control form-finanza form-control-sm text-center bold'"  currency="$" separator="," :precision="2" v-model="item.MontoPlan"/>  </td>
														<td v-if="item.Descripcion === 'Viaticos'"><Cmoneda :activo="true" :minus="true" :clase="'form-control form-finanza form-control-sm text-center bold'"  currency="$" separator="," :precision="2" v-model="item.MontoPlan"/>  </td>
														<td v-if="item.Descripcion === 'Burden'"><Cmoneda :activo="true" :minus="true" :clase="'form-control form-finanza form-control-sm text-center bold'"  currency="$" separator="," :precision="2" v-model="item.MontoPlan"/>  </td>
													</template> -->


													<!-- COLUMNA PORCENTAJE PLAN -->
													<td v-if="item.Descripcion === 'Facturacion' " :class="item.Descripcion === 'Facturacion' ? 'sticky borde_bajo' : 'sticky' "><Cporcentaje :activo="true" :minus="true" :clase="'bold form-control form-finanza form-control-sm text-center'"  currency="%" separator="," :precision="2" v-model="item.PorcentPlan"/> </td>
													<td v-if="item.Descripcion === 'Materiales'  "><Cporcentaje :activo="true" :minus="true" :clase="'bold form-control form-finanza form-control-sm text-center'"  currency="%" separator="," :precision="2" v-model="item.PorcentPlan"/> </td>
													<td v-if="item.Descripcion === 'Equipos'  "><Cporcentaje :activo="true" :minus="true" :clase="'bold form-control form-finanza form-control-sm text-center'"  currency="%" separator="," :precision="2" v-model="item.PorcentPlan"/> </td>
													<td v-if="item.Descripcion === 'Mano de Obra'  "><Cporcentaje :activo="true" :minus="true" :clase="'bold form-control form-finanza form-control-sm text-center'"  currency="%" separator="," :precision="2" v-model="item.PorcentPlan"/> </td>
													<td v-if="item.Descripcion === 'Vehiculos'  "><Cporcentaje :activo="true" :minus="true" :clase="'bold form-control form-finanza form-control-sm text-center'"  currency="%" separator="," :precision="2" v-model="item.PorcentPlan"/> </td>
													<td v-if="item.Descripcion === 'Contratistas'  "><Cporcentaje :activo="true" :minus="true" :clase="'bold form-control form-finanza form-control-sm text-center'"  currency="%" separator="," :precision="2" v-model="item.PorcentPlan"/> </td>
													<td v-if="item.Descripcion === 'Viaticos'  "><Cporcentaje :activo="true" :minus="true" :clase="'bold form-control form-finanza form-control-sm text-center'"  currency="%" separator="," :precision="2" v-model="item.PorcentPlan"/> </td>
													<td v-if="item.Descripcion === 'Burden'  "><Cporcentaje :activo="true" :minus="true" :clase="'bold form-control form-finanza form-control-sm text-center'"  currency="%" separator="," :precision="2" v-model="item.PorcentPlan"/> </td>


													<!-- COLUMNA MONTO ACTUAL -->
													<!-- <td v-if=" cliente.Nombre !== '' && IdTipoSubservicio !=='' && IdContrato !== '' && item.Descripcion=='Facturacion' " :class="item.Descripcion === 'Facturacion' ? 'sticky borde_bajo' : 'sticky' ">
														<Cmoneda  :minus="true" :clase="'bold form-control form-finanza form-control-sm text-center'"  currency="$" separator="," :precision="2" v-model="item.MontoActual"/>
													</td>

													<td v-else :class="item.Descripcion === 'Facturacion' ? 'sticky borde_bajo' : '' ">
														<Cmoneda  :activo="true" :minus="true" :clase="'bold form-control form-finanza form-control-sm text-center'"  currency="$" separator="," :precision="2" v-model="item.MontoActual"/>
													</td> -->
													<td :class="item.Descripcion === 'Facturacion' ? 'sticky borde_bajo' : ''">
														<Cmoneda  :activo="true" :minus="true" :clase="'bold form-control form-finanza form-control-sm text-center'"  currency="$" separator="," :precision="2" v-model="item.MontoActual"/>
													</td>

													<!-- COLUMNA PORCENTAJE ACTUAL -->
													<td :class="item.Descripcion === 'Facturacion' ? 'sticky borde_bajo' : '' "><Cporcentaje :activo="true"  :minus="true" :clase="'bold form-control form-finanza form-control-sm text-center'"  currency="%" separator="," :precision="2" v-model="item.PorcentActual"/> </td>

												</tr>

												</tbody>

												<tfoot>

												<tr>
													<td class="color-01 bold sticky marca">Costo Operacional</td>
													<td><Cmoneda 	 :activo="true" :minus="true" :clase="'form-control form-finanza form-control-sm color-01 bold text-center'"  currency="$" separator="," :precision="2" v-model="CostoOpPlan"/></td>
													<td><Cporcentaje :activo="true" :minus="true" :clase="'form-control form-finanza form-control-sm color-01 bold text-center'"  currency="%" separator="," :precision="2" v-model="CostoOpPlanPorcent"/></td>
													<td><Cmoneda 	 :activo="true" :minus="true" :clase="'form-control form-finanza form-control-sm color-01 bold text-center'"  currency="$" separator="," :precision="2" v-model="CostoOpActual"/></td>
													<td><Cporcentaje :activo="true" :minus="true" :clase="'form-control form-finanza form-control-sm color-01 bold text-center'"  currency="%" separator="," :precision="2" v-model="CostoOpActualPorcent"/></td>
												</tr>

												<tr >
													<td class="color-01 bold sticky marca">Gross Profit</td>
													<td><Cmoneda 	 :activo="true" :minus="true" :clase="'form-control form-finanza form-control-sm color-01 bold text-center'"  currency="$" separator="," :precision="2" v-model="GpPlan"/></td>
													<td><Cporcentaje :activo="true" :minus="true" :clase="'form-control form-finanza form-control-sm color-01 bold text-center'"  currency="%" separator="," :precision="2" v-model="GpPlanPorcent"/></td>
													<td><Cmoneda 	 :activo="true" :minus="true" :clase="'form-control form-finanza form-control-sm color-01 bold text-center'"  currency="$" separator="," :precision="2" v-model="GpActual"/></td>
													<td><Cporcentaje :activo="true" :minus="true" :clase="'form-control form-finanza form-control-sm color-01 bold text-center'"  currency="%" separator="," :precision="2" v-model="GpActualPorcent"/></td>
												</tr>

												</tfoot>
											</table>
										</template>
									</CLoader>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

         <Ccliente :TipoModal='1'/>
    </div>
</template>
<script>
import Cvalidation from '@/components/Cvalidation.vue'
import Ccliente from '../../../../components/Ccliente.vue'
import Plan from '@/views/modulos/finanzas/planventas/Plan.vue'
import CLoader from "../../../../components/CLoader";

export default {
    props:['Id'],
    components:{
        Plan,
        Cvalidation,
		Ccliente,
		CLoader
    },
    data() {
        return {
			Head:{
				Title:'Actualización de facturación',
				BtnNewShow:false,
				BtnNewName:'Nuevo',
				isreturn:true,
				isModal:false,
				isEmit:false,
				Url:'MenusFinanzas',
				ObjReturn:'',
			},

			errorvalidacion :[],
			ConfigLoad:{
				ShowLoader:true,
				ClassLoad:true,
			},

			blockCampos:false,
            ListaAnios:[],
            ListaServicios:[],
			ListaTipoServicio:[],
			ListaNumc:[],
            ListaDetalle:[],

			cliente:{
				Nombre:'',
				IdCliente: '',
				IdClienteS: '',
			},

            IdConfigS:0,
            IdTipoSubservicio:'',
            Anio:2020,
            Mes:1,


            Factura:0,
            Material:0,
            Equipo:0,
            Mano:0,
            Vehiculo:0,
            Contratista:0,
            Burden:0,
            Viatico:0,



            Disabled:false,
            loading:true,
            txtSave:'Guardar',
            Decimal:1,

            IdContrato:'',


			CostoOpPlan: 0,
			CostoOpPlanPorcent: 0,
			CostoOpActual: 0,
			CostoOpActualPorcent: 0,

			GpPlan: 0,
			GpPlanPorcent: 0,
			GpActual:0,
			GpActualPorcent:0
        }
    },
    methods: {
		/* REGRESAR */
		Menu() {
			this.$router.push({name:'MenusFinanzas', params: {}});
		},

		/* LLAMAR A LA MODAL DE CLIENTES Y SUCURSALES */
		async ListaCliente() {
			this.bus.$emit('ListCcliente');
		},

		/* CARGARMOS LOS AÑOS VIGENTES ACTUALES ASI COMO LOS MESES */
		get_anios() {
			this.Disabled=true;
			this.$http.get(
				'funciones/getanios',
				{
					params:{}
				}
			).then( (res) => {
				this.ListaAnios=res.data.ListaAnios;
				this.Anio=res.data.AnioActual;
				this.Mes= parseInt( res.data.MesActual);
				this.get_lisServicios();
			});
		},

		/* CARGAR EL TIPOS DE SERVICIO */
		get_lisServicios() {
			this.$http.get('baseactual/get',{
				params:{
					RegEstatus:'A',
					Entrada:''
				}
			}).then( (res) => {
				this.ListaServicios	= res.data.data.lista;
				this.IdConfigS		= this.ListaServicios[0].IdConfigS;
				this.ListaSubtipo();

			});
		},

		/* CARGAR LOS SUBTIPOS DE SERVICIOS EXISTENTES BASADOS EN LA CLASIFICAION DE SERVICIOS PRINCIPALES */
		async ListaSubtipo() {
			if (this.IdConfigS>0)
			{
				await this.$http.get('tiposervicio/get',{
					params:{
						RegEstatus:'A',
						IdConfigS:this.IdConfigS,
						IdTipoServ:this.IdTipoServ,
						Entrada:''
					}
				}).then( (res) => {
					this.ListaTipoServicio =res.data.data.tiposervicio;
					this.IdTipoSubservicio="";
					this.get_listdata();
				});
			}
		},

		/* ACCIÓN DE SELECCIONAR UN CLIENTE DEL LISTADO DE CLIENTES/SUCURSALES */
		SeleccionarCliente(objeto) {
			this.cliente=objeto;
			this.IdContrato="";
			this.get_listdata();
			this.ListaNumContrato();
		},

		/* CARGAR LA LISTA DE CONTRATOS QUE TENGA LA SUCURSAL SELECCIONADA */
		async ListaNumContrato() {
			await this.$http.get(
				'numcontrato/get',
				{
					params:{IdClienteS:this.cliente.IdClienteS}
				}
			).then( (res) => {
				this.ListaNumc =res.data.data.row;

			});
		},


        get_listdata(){
			
			if (this.IdConfigS >0 )
			{
				this.ConfigLoad.ShowLoader = true;

				this.$http.get('actualizarfacturaservicios/get',
					{
						params:{
							IdConfigS:this.IdConfigS,
							IdTipoServ:this.IdTipoSubservicio,
							Anio:this.Anio,
							Mes:this.Mes,
							IdCliente:this.cliente.IdCliente,
							IdClienteS:this.cliente.IdClienteS,
							IdContrato:this.IdContrato
						}
					})
					.then( (res) => {
						//console.log(res.data)

						this.ListaDetalle 			= res.data.data.row;
						let valores 	  			= res.data.data.rowconfig;

						this.CostoOpPlan 			= valores.CostoOperacion.MontoPlan;
						this.CostoOpPlanPorcent 	= valores.CostoOperacion.PorcentPlan;
						this.CostoOpActual 			= valores.CostoOperacion.MontoActual;
						this.CostoOpActualPorcent 	= valores.CostoOperacion.PorcentActual;
						this.GpPlan 				= valores.GrossProfit.MontoPlan;
						this.GpPlanPorcent			= valores.GrossProfit.PorcentPlan;
						this.GpActual 				= valores.GrossProfit.MontoActual;
						this.GpActualPorcent 		= valores.GrossProfit.PorcentActual;

						this.Disabled=false;
						this.loading=false;

					}).finally(() => {
						this.ConfigLoad.ShowLoader = false;
				});
			}
        },


        async Guardar() {
			let Facturacion = this.ListaDetalle[0].MesActualMonto;
            if(Facturacion === "") {
                Facturacion = 0;
            }

			if(this.Mes == ''){
                this.$toast.warning('Debe seleccionar un mes');
                return false;

            }
            else if(this.IdConfigS == 0 || this.IdConfigS == ''){
                this.$toast.warning('Debe seleccionar un servicio');
                return false;

            } else if(this.IdTipoSubservicio == 0 || this.IdTipoSubservicio == '') {

                this.$toast.warning('Debe seleccionar un tipo de servicio');
                return false;

            } else if(this.cliente.IdClienteS == 0 || this.cliente.IdClienteS == '') {
                this.$toast.warning('Debe seleccionar un cliente y su sucursal');
                return false;

            } else if(this.IdContrato == 0 || this.IdContrato == '') {
				this.$toast.warning('Debe seleccionar un número de contrato');
				return false;

			} else {
                this.Disabled = true;

				let newRequest = {

					IdConfigS:this.IdConfigS,
					IdTipoServ:this.IdTipoSubservicio,
					Anio:this.Anio,
					Mes:this.Mes,
					IdCliente:this.cliente.IdCliente,
					IdClienteS:this.cliente.IdClienteS,
					IdContrato:this.IdContrato,

					Detalle: this.ListaDetalle,
				};

                this.$http.post('estadosfinancierosUpt/post', newRequest)
					.then((res) => {
						this.Disabled = false;
						this.$toast.success('Información Guardada');
						this.get_listdata();
						this.errorvalidacion = [];

                }).catch( err => {
                    this.Disabled = false;
                    this.$toast.error('Seleccione los Campos Solicitados');
                    this.errorvalidacion = err.response.data.message.errores;
                });

            }
        },



        Limpiar() {
            this.IdConfigS=1;
            this.IdTipoSubservicio="";
            this.cliente={Nombre:''};
            this.IdContrato="";
            this.get_listdata();
        },

        Descargar() {
			const url = "estadofinanciero";
			let Tipo = 1;

			if (this.IdConfigS > 0 ) {
                if (this.IdConfigS == 6) {
                    Tipo = 2;
                }

                this.$http.get('reporte/'+url, {
					responseType: 'arraybuffer',
					params :{
                        IdConfigS:this.IdConfigS,
						IdTipoServ:this.IdTipoSubservicio,
						Anio:this.Anio,Mes:this.Mes,
						IdCliente:this.cliente.IdCliente,
						IdClienteS:this.cliente.IdClienteS,
						IdContrato:this.IdContrato,
						Tipo:Tipo
                    }
                }).then( (response) => {
                    let pdfContent 	= response.data;
                    let file 		= new Blob([pdfContent], { type: 'application/pdf' });
					const fileUrl 	= URL.createObjectURL(file);
					window.open(fileUrl);
                });
            }
        },

		getPorcent($monto,$montoDivisor){
			let PORCENTAJE_FINA = 0;

			if (parseFloat($monto)  > 0) {
				if (parseFloat($montoDivisor) > 0){
					let decimal = ( parseFloat($monto) / parseFloat($montoDivisor) );
					let porcenTemp= (decimal * 100);
					porcenTemp = porcenTemp.toFixed(2);
					PORCENTAJE_FINA = (porcenTemp > 100) ? 100 : porcenTemp;
				}
			}

			return PORCENTAJE_FINA;
		}


    },

    created() {
        this.bus.$off('Regresar');
        this.bus.$off('SeleccionarCliente');
		this.get_anios();
	},

    mounted() {
		this.bus.$on('Regresar',()=> {
			this.$router.push({name:'SubMenusFinanzas'});
		});

		this.bus.$on('SeleccionarCliente',(oSucursal)=> {
			this.SeleccionarCliente(oSucursal);
		});
    },

    computed: {
		tipofiltrado : function (){
			if (this.cliente.Nombre === '' && this.IdConfigS !== 6) {
				this.blockCampos = true;

			}else if(this.IdContrato == '' && this.IdConfigS !== 6){
				this.blockCampos = true;

			}else if(this.IdTipoSubservicio == '' && this.IdConfigS !== 6){
				this.blockCampos = true;

			}else if(this.cliente.Nombre !== '' && this.IdContrato !== '' && this.IdConfigS !== 6 && this.IdTipoSubservicio!==''){
				this.blockCampos = false;
			}

			return this.blockCampos;
		},

        calcularValores() {

			let PlanFacturacion = 0;
			let ActualFacturacion = 0;
			let CostoOpActual = 0;
			let CostoOpPlan = 0;

            //! FOR NUEVO ANDREA
            for(let i =0;i<this.ListaDetalle.length;i++ )
            {

                if(i>0) {

                    if(this.ListaDetalle[i].Descripcion != '') {

						if( isNaN(this.ListaDetalle[i].MontoPlan) ) {
							CostoOpPlan += 0;
						}else {
							CostoOpPlan += parseFloat(this.ListaDetalle[i].MontoPlan);
						}

						// PORCENTAJE DE COLUMNA DE PLAN DE FACTURACIÓN
						this.ListaDetalle[i].PorcentPlan = this.getPorcent(this.ListaDetalle[i].MontoPlan, PlanFacturacion);


						if( isNaN(this.ListaDetalle[i].MontoActual) ) {
							CostoOpActual += 0;
						}else {
							CostoOpActual += parseFloat(this.ListaDetalle[i].MontoActual);
						}

						// PORCENTAJE DE COLUMNA DE ACTUAL DE FACTURACIÓN
						this.ListaDetalle[i].PorcentActual = this.getPorcent(this.ListaDetalle[i].MontoActual, ActualFacturacion);
                    }
                }
				else {

                    if( isNaN(this.ListaDetalle[i].MontoPlan) ) {
						PlanFacturacion += 0;
                    }else {
						PlanFacturacion += parseFloat(this.ListaDetalle[i].MontoPlan);
					}

					if( isNaN(this.ListaDetalle[i].MontoActual) ) {
						ActualFacturacion += 0;
					}else {
						ActualFacturacion += parseFloat(this.ListaDetalle[i].MontoActual);
					}
                }


            }
            //! FIN FOR NUEVO ANDREA
            //Costos Operacionales
			this.CostoOpPlan 				= CostoOpPlan.toFixed(2);
			this.CostoOpPlanPorcent 		= this.getPorcent(CostoOpPlan, PlanFacturacion);
			this.CostoOpActual 				= CostoOpActual;
			this.CostoOpActualPorcent 		= this.getPorcent(CostoOpActual, ActualFacturacion);



			let montoGP						= (PlanFacturacion - CostoOpPlan);
			this.GpPlan 					= montoGP;
			this.GpPlanPorcent				= this.getPorcent (montoGP, PlanFacturacion);

			let montoGPActual				= ( ActualFacturacion - CostoOpActual )
			this.GpActual 					= montoGPActual;
			this.GpActualPorcent 			= this.getPorcent (montoGPActual, ActualFacturacion);
        }
    },
}
</script>
