<template>
    <div>
        <Clist :regresar="true" :Nombre="NameList" @FiltrarC="Lista" :Filtro="Filtro" :isModal="EsModal" :pConfigLoad="ConfigLoad">
            <template slot="header">
                <th>Cantidad</th>
                <th>Acciones</th>
            </template>
            <template slot="body">
                <tr v-for="(item,index) in ListaCantidades" :key="index">
                    <td>{{ item.Cantidad }}</td>
                    <td>
                        <Cbtnaccion 
                        :isModal="EsModal" :Id="item.IdPaquetePropiedadMonCliente" :IrA="FormName"></Cbtnaccion>
                    </td>
                </tr>
                <CSinRegistros :pContIF="ListaCantidades.length" :pColspan="2" ></CSinRegistros>
            </template>
        </Clist>
        <Modal :size="size" :Nombre="NameList" :poBtnSave="oBtnSave" >
            <template slot="Form">
                <Form :poBtnSave="oBtnSave" ></Form>
            </template>
        </Modal>
    </div>
</template>

<script>
import Modal from '@/components/Cmodal.vue';
import Clist from '@/components/Clist.vue';
import CSinRegistros from '@/components/CSinRegistros.vue'
import Cbtnaccion from '@/components/Cbtnaccion.vue';
import Form from '@/views/catalogos/Root/Paquetes/PropiedadesMonCliente/Form.vue'
export default {
    name :'list',
    components :{
        Modal,
        Clist,
        Cbtnaccion,
        Form,
        CSinRegistros
    },
    data() {
        return {
            FormName:'PropiedadesMonitoreoClienteForm',
            EsModal:true,
            size :"None",
            NameList:"Propiedades Client View",
            ListaCantidades:[],
            TotalPagina:2,
            Pag:0,
             Filtro:{
                Nombre:'',
                Placeholder:'Nombre..',
                 TotalItem:0,
                Pagina:1
            },
            oBtnSave:{
                isModal:true,
                disableBtn:false,
                toast:0,
            },
            ConfigLoad:{
				ShowLoader:true,
				ClassLoad:true,
			}
        }
    },
    methods: {
        async Lista(){
            this.ConfigLoad.ShowLoader=true;
            this.$http.get('paquetepropiedadmoncliente/get',{
                params:{
                    Nombre:this.Filtro.Nombre,
                    Entrada:this.Filtro.Entrada,
                    pag:this.Filtro.Pagina
                }
            }).then((res)=>{
                this.ListaCantidades=res.data.data.PaquetesPropiedadMonCliente;
                this.Filtro.Entrada	= res.data.data.pagination.PageSize;
				this.Filtro.TotalItem = res.data.data.pagination.TotalItems;
				this.Filtro.Pagina = res.data.data.pagination.CurrentPage;
            }).finally(()=>{
                this.ConfigLoad.ShowLoader=false;
            })
        },
        Eliminar(Id){
            this.$swal({
                title: 'Esta seguro que desea eliminar este dato?',
                text: 'No se podra revertir esta acción',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si',
                cancelButtonText: 'No, mantener',
                showCloseButton: true,
                showLoaderOnConfirm: true
            }).then((result) => {
                if(result.value) {
                    this.$http.delete(
                        'paquetepropiedadmoncliente/' + Id
                    ).then( (res) => {
                        this.$toast.success('Información eliminada');
                            this.Lista();

                    });
                }
            });
        }
    },
    created() {
        this.bus.$off('Delete');
        this.bus.$off('List');
        this.bus.$off('Regresar');
        this.Lista();
        
        this.bus.$on('Delete',(Id)=> 
        {
            this.Eliminar(Id);
            
        });
        
        this.bus.$on('List',()=> 
        {
            this.Lista();
            
        });
        
        this.bus.$on('Regresar',()=> 
        {
            this.$router.push({name:'ConfigPaquetes'});    
        });
    },
    mounted() {
        $(".card-01").css('width','40%');
        $(".card-01").css('margin-left','auto');
        $(".card-01").css('margin-right','auto');
    },
}
</script>

<style>

</style>