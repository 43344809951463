<template>
    <div class="form-row">
        <div v-if="ShowButtonTop" class="col-lg-6 d-flex align-items-center">
            <h3>{{ NombreTabla }}</h3>
        </div>
        <div v-if="ShowButtonTop" class="col-lg-6 d-flex justify-content-end mb-2">
            <button
                @click="Nuevo"
                class="btn btn-pink"
                type="button"
                data-toggle="modal"
                :data-target="'#'+TituloBoton"
                data-backdrop="static"
                data-keyboard="false"
                ><i class="fa fa-plus-circle mr-1"></i>Nuevo
            </button>
        </div>
        <div class="col-lg-12">
            <CLoader :pConfigLoad="ConfigLoad">
                <template slot="BodyFormLoad">
                    <div class="table-responsive scrollY-tableform">
                        <table class="table-01 text-nowrap-mt-2">
                            <thead>
                                <tr>
                                    <slot name="header_table_form">
                                    </slot>
                                    <th v-if="ShowButton" class="tw-1 text-right">
                                        <button
                                            class="mr-2 btn-obs"
                                            type="button"
                                            data-backdrop="static"
                                            data-toggle="modal"
                                            :data-target="'#'+TituloBoton"
                                            data-keyboard="false"
                                            title="Agregar"
                                            @click="Nuevo"
                                        ><i class="fa fa-plus"></i></button>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <slot name="body_table_form"></slot>
                                <!-- <slot name="buttons_action_form"></slot> -->
                            </tbody>
                        </table>
                    </div>
                    
                </template>
            </CLoader>
        </div>
        
        
    </div>
</template>

<script>
    import CLoader from "./CLoader";
    export default{
        name:"Clistform",
        props:[
            "TituloBoton",
            "NombreTabla",
            "EmitSeccionTabla",
            "ShowButton",
            "ShowButtonTop",
            "pConfigLoad"
        ],
        components:{
            CLoader
        },
        data(){
            return{
                ConfigListForm:{
                    TituloBoton:"",
                    NombreTabla:"",
                    EmitSeccionTabla:"",
                    ShowButton:false,
                    ShowButtonTop:false
                },
                ConfigLoad:{
				    ShowLoader: true,
				    ClassLoad:  true,
			    },
            }
        },
        methods:{
            Nuevo(){
                this.bus.$emit("NewModal_"+this.EmitSeccionTabla,true);
            }
        },
        created(){
            if (this.TituloBoton!=undefined){
                this.ConfigListForm.TituloBoton=this.TituloBoton;
            }
            if (this.NombreTabla!=undefined){
                this.ConfigListForm.NombreTabla=this.NombreTabla;
            }
            if (this.EmitSeccionTabla!=undefined){
                this.ConfigListForm.EmitSeccionTabla=this.EmitSeccionTabla;
            }
            if (this.ShowButton!=undefined){
                this.ConfigListForm.ShowButton=this.ShowButton;
            }
            if (this.ShowButtonTop!=undefined){
                this.ConfigListForm.ShowButtonTop=this.ShowButtonTop;
            }
            if (this.pConfigLoad!=undefined) {
                this.ConfigLoad=this.pConfigLoad;
                
            }
        }
    }
</script>