<template>
    <div>
        <CLoader :pConfigLoad="ConfigLoadForm">
            <template slot="BodyFormLoad">
                <div class="row">
                    <div class="col-lg-6 ">
                        <label >Nombre de la Empresa *</label>
                        <input type="text" v-model="Clientes.Nombre" class="form-control form-control-sm" placeholder="Nombre" id="Nombre" name="Nombre" />
                        <Cvalidation v-if="this.errorvalidacion.Nombre" :Mensaje="errorvalidacion.Nombre[0]"></Cvalidation>
                    </div>     
        
                    <div class="col-lg-6">
                        <label >Nombre del Contacto (Persona) *</label>
                        <input type="text" v-model="Clientes.Contacto" class="form-control form-control-sm" placeholder="Contacto" />
                        <label id="lblmsuser" style="color:red"><Cvalidation v-if="this.errorvalidacion.Contacto" :Mensaje="errorvalidacion.Contacto[0]"></Cvalidation></label>
                    </div>  
        
                    <div class="col-lg-6">
                        <label >Dirección *</label>
                        <input type="text" v-model="Clientes.Direccion"  class="form-control form-control-sm" placeholder="Dirección" />
                        <label id="lblmsuser" style="color:red"><Cvalidation v-if="this.errorvalidacion.Direccion" :Mensaje="errorvalidacion.Direccion[0]"></Cvalidation></label>
                    </div>
        
                    <div class="col-lg-3 ">
                        <label >Ciudad *</label>
                        <input type="text" v-model="Clientes.Ciudad" class="form-control form-control-sm"
                                placeholder="Ciudad" />
                        <label id="lblmsuser" style="color:red"><Cvalidation v-if="this.errorvalidacion.Ciudad" :Mensaje="errorvalidacion.Ciudad[0]"></Cvalidation></label>
                    </div>

                    <div class="col-lg-3 ">
                        <label >Estado </label>
                        <input type="text" v-model="Clientes.Estado" class="form-control form-control-sm"
                                placeholder="Estado" />
                        <label id="lblmsuser" style="color:red"><Cvalidation v-if="this.errorvalidacion.Estado" :Mensaje="errorvalidacion.Estado[0]"></Cvalidation></label>
                    </div>
                               
                    <div class="col-lg-4">
                        <label >Teléfono *</label>
                            <input type="text" v-model="Clientes.Telefono" class="form-control form-control-sm"  placeholder="Teléfono" />
                            <label id="lblmsuser" style="color:red"><Cvalidation v-if="this.errorvalidacion.Telefono" :Mensaje="errorvalidacion.Telefono[0]"></Cvalidation></label>
                    </div>
        
                    <div class="col-lg-4 ">
                        <label >Correo *</label>
                        <input  v-model="Clientes.Correo" class="form-control form-control-sm"  placeholder="Correo" />        
                        <label id="lblmsuser" style="color:red"><Cvalidation v-if="this.errorvalidacion.Correo" :Mensaje="errorvalidacion.Correo[0]"></Cvalidation></label>
                    </div>
        
                    <div class="col-lg-4 ">
                        <label >Pais </label>
                        <input  v-model="Clientes.Pais" class="form-control form-control-sm"  placeholder="Pais" />        
                        <!-- <label id="lblmsuser" style="color:red"><Cvalidation v-if="this.errorvalidacion.Correo" :Mensaje="errorvalidacion.Correo[0]"></Cvalidation></label> -->
                    </div>
                                               
                    <div class=" col-lg-6">
                        <label >Datos de Facturacion</label>
                        <textarea rows="5" class="form-control form-control-sm" v-model="Clientes.Dfac"></textarea>
                        <label id="lblmsuser" style="color:red"><Cvalidation v-if="this.errorvalidacion.Dfac" :Mensaje="'Campo obligatorio'"></Cvalidation></label>
                    </div>
        
                    <div class="col-lg-4 d-flex justify-content-end p-0">
                        <div class="avatar-upload-logocliente">
                            <div class="avatar-preview">
                                <div id="ImagePreviewLogo" :style="'background-image:url('+Clientes.LogoCliente+');background-size:contain'" :src="Clientes.LogoCliente">

                                    <div class="d-flex h-100 align-items-center justify-content-center" v-if="AltLogoContacto">
                                        <h4>Logo</h4>
                                    </div>
                                    <!-- PONER ESTO DENTRO DEL DIV -->
                                    <!-- :style="'background-image: url('+Equipo.ImagenEspecificaciones+');'" :src="Equipo.ImagenEspecificaciones" -->
                                </div>
                            </div>
                        </div>
                        <label id="lblmsuser" style="color:red"><Cvalidation v-if="this.errorvalidacion.LogoClientes" :Mensaje="errorvalidacion.LogoClientes[0]"></Cvalidation></label>
                    </div>
                    <div class="col-lg-2">
                        <label title="Seleccionar Imagen" for="logocliente" style="cursor:pointer" class="btn-icon mb-2"><i class=" mt-2 fa fa-pencil"></i></label>
                        <input @change="CambiarLogo($event)" class="d-none" type="file" id="logocliente" ref="logocliente" name="logocliente" accept=".png, .jpg, .jpeg">
                        <br>
                        <button @click="EliminarImagen" class="btn-icon-02"><i class="fa fa-trash"></i></button>
                    </div>
                    <!--fin col-6-->
                </div>
        
                <!-- El componente para la tabla del formulario-->
                <!-- <Clistform :pConfigLoad="ConfigLoad" v-if="Clientes.IdCliente>0" :ShowButtonTop="ShowButtonTop" :NombreTabla="NombreTablaForm" :EmitSeccionTabla="EmitSeccionTabla" :TituloBoton="TituloBoton">
                    <template slot="header_table_form">
                        <th>Nombre</th>
                        <th>Correo</th>
                        <th>Acciones</th>
                    </template>
                    <template slot="body_table_form">
                        <tr v-for="(item,key,index) in ListaUsuarios" :key="index">
                            <td>{{ item.Nombre + " " + item.Apellido }}</td>
                            <td>{{ item.Candado }}</td>
                            <td>
                                <button
                                    @click="Editar(item.IdUsuario)"
                                    title="Editar"
                                    data-backdrop="static"
                                    data-keyboard="false"
                                    data-toggle="modal"
                                    :data-target="'#'+EmitSeccionTabla"
                                    class="btn-icon mr-2"
                                ><i class="fa fa-pencil"></i></button>
                                <button
                                    @click="Eliminar(item.IdUsuario)"
                                    class="btn-icon-02"
                                ><i class="fa fa-trash"></i>
                                </button>
                            </td>
                        </tr>
                    </template>
                </Clistform> -->
        
                <!-- El componente modal para el usuario monitoreo -->
                <Cmodal :Nombre="NameList" :pEmitSeccion="EmitSeccionTabla" :NameModal="TituloBoton" :poBtnSave="oBtnSave">
                    <template slot="Form">
                        <FormUsu :objcliente="Clientes" :poBtnSave="oBtnSave"></FormUsu>
                    </template>
                </Cmodal>
            </template>
        </CLoader>
        
    </div>
</template>
<script>
//El props Id es cuando no es modal y se mando con props
//El export de btnsave es por si no se usa el modal
import Cbtnsave from '@/components/Cbtnsave.vue'
import Cvalidation from '@/components/Cvalidation.vue'
import Config from "@/helpers/VarConfig.js";
import Cmodal from "@/components/Cmodal.vue";
import Clistform from '../../../../components/Clistform.vue';
import FormUsu from '../contactos/FormUsu.vue'
import CLoader from "@/components/CLoader.vue";

export default {
    name:'Form',
    props:['IdCliente','poBtnSave'],
    data() {
        return {
            Modal:true,//Sirve pra los botones de guardado
            FormName:'cliente',//Sirve para donde va regresar
            EmitSeccion:this.poBtnSave.EmitSeccion,
            ValidElement:Config.validImage2m,
            NombreTablaForm:"Usuario Monitoreo",
            TituloBoton:"UsuarioMonitoreo",
            EmitSeccionTabla:"UsuarioMonitoreo",
            NameList:"Usuarios del Cliente: ",
            ShowButtonTop:true,
            Clientes:{
                IdCliente:0,
                Nombre:"",
                Telefono:"",
                Direccion:"",
                Correo:"",
                Ciudad:"",
                Pais:"",
                Estado:"",
                CP:"", 
                IdSucursal:"",
                Contacto:"",
                Dfac:"",
                LogoCliente:""
            },
            // CorreoAntiguo:'',
            ListaUsuarios:[],
            urlApi:"crmcontactos/recovery",
            errorvalidacion:[],
            Ruta:"",
            oBtnSave:{
                isModal:true,
                disableBtn:false,
                toast:0,
                nombreModal:"UsuarioMonitoreo",
                EmitSeccion:"UsuarioMonitoreo"
            },
            ConfigLoad:{
                ShowLoader: false,
				ClassLoad:  true,
            },
            ConfigLoadForm:{
                ShowLoader: false,
				ClassLoad:  true,
            },
            AltLogoContacto:true
        }
    },
    components:{
        Cbtnsave,
        Cvalidation,
        Clistform,
        Cmodal,
        FormUsu,
        CLoader
    },
    methods :
    {
       async Guardar()
        {
            //deshabilita botones
            this.poBtnSave.toast=0; 
            this.poBtnSave.disableBtn=true;
            let formData = new FormData();

            formData.set('IdCliente',this.Clientes.IdCliente);
            formData.set('Nombre',this.Clientes.Nombre);
            formData.set('Telefono', this.Clientes.Telefono);
            formData.set('Direccion',this.Clientes.Direccion);
            formData.set('Correo',this.Clientes.Correo);
            formData.set('Ciudad', this.Clientes.Ciudad);
            formData.set('Pais', this.Clientes.Pais);
            formData.set('Estado', this.Clientes.Estado);
            formData.set('CP', this.Clientes.CP);
            formData.set('Contacto', this.Clientes.Contacto);
            formData.set('Dfac', this.Clientes.Dfac);
            formData.set('CorreoAntiguo', this.CorreoAntiguo);

            // RECUPERAR LOGO DEL CLIENTE
            let image=this.$refs.logocliente.files[0];
            formData.append('LogoClientes',image);
            if (this.Clientes.LogoCliente!="") {
                let index=this.Clientes.LogoCliente.lastIndexOf("/")+1;
			    let cadena=this.Clientes.LogoCliente.substr(index);
                formData.set('LogoClientesPrevio',"/"+cadena);   
            }
            
            await this.$http.post('crmcontactos/post',
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                },
            ).then( (res) => {
                // sessionStorage.setItem("ocontacto",res.data.data.clientes);
                this.poBtnSave.disableBtn=false;  
                this.poBtnSave.toast=1;
                $('#'+this.poBtnSave.nombreModal).modal('hide');
                this.bus.$emit('List',"Crear","Matriz"); 
            }).catch( err => {
                this.errorvalidacion=err.response.data.message.errores;
                this.poBtnSave.disableBtn=false;
                this.poBtnSave.toast=2;  
            });
        },
        Limpiar()
        {
            this.AltLogoContacto=true;
            this.Clientes.Nombre="",
            this.Clientes.Telefono="",
            this.Clientes.Direccion="",
            this.Clientes.Correo="",
            this.Clientes.Ciudad=""
            this.Clientes.Pais="",
            this.Clientes.Estado="",
            this.Clientes.CP="",
            this.Clientes.Contacto="",
            this.Clientes.Dfac="",
            this.Clientes.IdCliente=0,
            this.Clientes. IdSucursal="",
            this.Clientes.LogoCliente="",
            this.errorvalidacion=[""],
            this.Ruta="",
            this.ListaUsuarios=[],
            this.NameList="Usuarios del Cliente: "
            // LIMPIAR LA IMAGEN
            $("#ImagePreviewLogo").css('background-image','url()');
            $("#ImagePreviewLogo").css('background-size','contain');
            this.$refs.logocliente.value="";
            this.ShowButtonTop=true;
            // this.CorreoAntiguo='';
        },
        get_one()
        {
            this.ConfigLoadForm.ShowLoader=true;
            this.$http.get(
                this.urlApi,
                {
                    params:{IdCliente: this.Clientes.IdCliente}
                }
            ).then( (res) => {
                this.Clientes.IdCliente =res.data.data.Clientes.IdCliente;
                this.Clientes.Nombre =res.data.data.Clientes.Nombre;
                this.Clientes.Telefono =res.data.data.Clientes.Telefono;
                this.Clientes.Direccion =res.data.data.Clientes.Direccion;
                this.Clientes.Correo =res.data.data.Clientes.Correo;
                this.Clientes.Ciudad =res.data.data.Clientes.Ciudad;
                this.Clientes.Pais =res.data.data.Clientes.Pais == "null" ? '' : res.data.data.Clientes.Pais;
                this.Clientes.Estado =res.data.data.Clientes.Estado;
                this.Clientes.CP =res.data.data.Clientes.CP;
                this.Clientes.IdSucursal =res.data.data.Clientes.IdSucursal;
                this.Clientes.Contacto =res.data.data.Clientes.Contacto;
                this.Clientes.Dfac =res.data.data.Clientes.Dfac;
                // PARA RECUPERAR LA IMAGEN
                this.Ruta=res.data.ruta;
                this.Clientes.LogoCliente=this.Ruta+res.data.data.Clientes.LogoCliente;
                this.NameList=this.NameList+this.Clientes.Nombre;
                
                // this.CorreoAntiguo=res.data.data.Clientes.Correo;

                if (res.data.data.Clientes.LogoCliente != null && res.data.data.Clientes.LogoCliente!="") {
                    this.AltLogoContacto=false;
                }
                // this.ConfigLoadForm.ShowLoader=false;
                this.Lista();
            });
        },
        async Lista(){
            // this.ConfigLoad.ShowLoader=true;
            this.$http.get('usuario/get',{
                params:{IdCliente:this.Clientes.IdCliente}
            }).then((res)=>{
                this.ListaUsuarios=res.data.data.usuarios
                if (this.ListaUsuarios.length>=3) {
                    this.ShowButtonTop=false;
                }
                this.ConfigLoadForm.ShowLoader=false;
            })
        },
        Editar(Id){
            this.bus.$emit('NewModal_'+this.EmitSeccionTabla,false,Id);
        },
        Eliminar(Id)
        {
            this.$swal({
            title: 'Esta seguro que desea eliminar este dato?',
            text: 'No se podra revertir esta acción',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si',
            cancelButtonText: 'No, mantener',
            showCloseButton: true,
            showLoaderOnConfirm: true
            }).then((result) => {
                if(result.value) {
                    this.$toast.success('Información eliminada');
                    this.$http.delete(
                       'usuario/' + Id
                    ).then( (res) => {
                        this.Lista();
                    });

                } 
            });
        },
        EliminarImagen(){
            if (this.$refs.logocliente.files[0]!=undefined || this.Clientes.LogoCliente!="") {
                this.$swal({
                title: 'Esta seguro que desea eliminar esta imagen?',
                text: 'No se podra revertir esta acción',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si',
                cancelButtonText: 'No, mantener',
                showCloseButton: true,
                showLoaderOnConfirm: true
                }).then((result) => {
                    if(result.value) {
                        this.$toast.success('Información eliminada');
                        $("#ImagePreviewLogo").css('background-image','url()');
                        $("#ImagePreviewLogo").css('background-size','contain');
                        // console.log(this.$refs.logocliente.files);
                        this.$refs.logocliente.value=null;
                        this.Clientes.LogoCliente="";
                        this.AltLogoContacto=true;
                    } 
                });   
            }
            else{
                this.$toast.info('No hay una imagen para eliminar');
            }
        },
        CambiarLogo(event){
            this.$uploadImagePreview(event,this.ValidElement,Array('Img','ImagePreviewLogo'));
            this.AltLogoContacto=false;
        }
    },
    created() {
        this.bus.$off('List_'+this.EmitSeccionTabla);
        this.bus.$on('List_'+this.EmitSeccionTabla,()=>{
            this.Lista();
        })
        this.bus.$off('NewModal_'+this.EmitSeccion);

        //Este es para modal
        this.bus.$on('NewModal_'+this.EmitSeccion,(data,Id)=> 
        {
            this.poBtnSave.disableBtn=false; 
            this.bus.$off('Save_'+this.EmitSeccion);
            this.bus.$on('Save_'+this.EmitSeccion,()=>
            {
                this.Guardar();
            });

            this.Limpiar();
            if (Id>0)
            {
                this.Clientes.IdCliente=Id;
                this.get_one();
            }
            this.bus.$emit('Desbloqueo',false);
        });
        if (this.Id!=undefined)
        {
            this.Clientes.IdCliente=this.Id;
            this.get_one();
        }
    }
}
</script>