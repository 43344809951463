<template>
    <div>
        <CLoader :pConfigLoad="ConfigLoad">
            <template slot="BodyFormLoad">
                <div class="row">
                    <div class="col-lg-4">
                        <label for="">Folio</label>
                        <input readonly type="text" class="form-control" v-model="objServicio.Folio">
                    </div>
                    <div class="col-lg-4">
                        <label for="">Servicio</label>
                        <input readonly type="text" class="form-control" v-model="objServicio.TipoServicio">
                    </div>
                    <div class="col-lg-4">
                        <label for="">Fecha de Servicio</label>
                        <input readonly type="text" class="form-control" v-model="objServicio.Fecha_I">
                    </div>
                </div>
        
                <div class="row">
                    <div class="col-lg-12">
                        <label for="">Cliente</label>
                        <input readonly type="text" class="form-control" v-model="objServicio.Cliente">
                    </div>
                </div>
        
                <div class="row">
                    <div class="col-lg-12">
                        <label for="">Propiedad</label>
                        <input readonly type="text" class="form-control" v-model="objServicio.Propiedad">
                    </div>
                </div>
        
                <div class="row">
                    <div class="col-lg-12">
                        <label for="">Dirección</label>
                        <input readonly type="text" class="form-control" v-model="objServicio.Direccion">
                    </div>
                </div>
        
                <div class="row">
                    <div class="col-lg-4">
                        <label for="">Contacto</label>
                        <input readonly type="text" class="form-control" v-model="objServicio.Contacto">
                    </div>
                    <div class="col-lg-4">
                        <label for="">Email</label>
                        <input readonly type="text" class="form-control" v-model="objServicio.Email">
                    </div>
                    <div class="col-lg-4">
                        <label for="">Teléfono</label>
                        <input readonly type="text" class="form-control" v-model="objServicio.Teléfono">
                    </div>
                </div>
        
                <div class="row">
                    <div class="col-lg-12">
                        <label for="">Tareas</label>
                        <textarea readonly class="form-control" rows="5" cols="10" v-model="objServicio.Tareas"></textarea>
                    </div>
                </div>
            </template>
        </CLoader>
    </div>
</template>

<script>
import CLoader from "@/components/CLoader.vue";
export default {
    name:"Form",
    props:["pEmitSeccion"],
    components:{CLoader},
    data() {
        return {
            Modal:true,
            FormName:"Servicio",
            ConfigLoad:{
                ShowLoader: false,
				ClassLoad:  true,
            },
            objServicio:{
                IdServicio:0,
                Folio:'',
                Fecha_I:'',
                TipoServicio:'',
                Cliente:'',
                Propiedad:'',
                Direccion:'',
                Contacto:'',
                Email:'',
                Telefono:'',
                Tareas:''
            },
            EmitSeccion:this.pEmitSeccion
        }
    },
    methods: {
        Recuperar(){
            this.ConfigLoad.ShowLoader=true;
            this.$http.get('servicio/recovery',{
                params:{
                    IdServicio:this.objServicio.IdServicio
                }
            }).then((res)=>{
                let objres=res.data.data.servicio;
                this.objServicio.Folio=objres.Folio;
                this.objServicio.Fecha_I=objres.Fecha_I;
                this.objServicio.TipoServicio=objres.Servicio;
                this.objServicio.Cliente=objres.Client;
                this.objServicio.Propiedad=objres.Sucursal;
                this.objServicio.Direccion=objres.DireccionCS;
                this.objServicio.Contacto=objres.Contacto;
                this.objServicio.Email=objres.CorreoCS;
                this.objServicio.Telefono=objres.TelCS;
                this.objServicio.Tareas=objres.Observaciones;
            }).finally(()=>{
                this.ConfigLoad.ShowLoader=false;
            })
        },
        Limpiar(){
            this.objServicio={
                IdServicio:0,
                Folio:'',
                Fecha_I:'',
                TipoServicio:'',
                Cliente:'',
                Propiedad:'',
                Direccion:'',
                Contacto:'',
                Email:'',
                Telefono:'',
                Tareas:''
            }
        }
    },
    created() {
        this.bus.$off('NewModal_'+this.EmitSeccion);
        this.bus.$on('NewModal_'+this.EmitSeccion,(data,Id)=>{
            this.Limpiar();
            this.objServicio.IdServicio=Id;
            this.Recuperar();
        })
    },
}
</script>

<style>

</style>