<template>
    <div class="col-md-6 col-lg-6">
        <b-overlay
			:show="isOverlay"
			rounded="sm"
			spinner-variant="primary"
			class="h-100"
		>
            <div class="row">
                <div class="col-12">
                    <div class="card card-grafica h-100 mb-3">
                        <div class="card-body">
                            <div class="filtro-grafic" id="grafica_003" v-if="isVisible">
                                <div class="row justify-content-start">
                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                        <div class="form-inline mb-2">
											<div v-if="cliente.Nombre != ''">
												<span class="text-center mr-5 bold text-body" style="font-size:16px">Cliente: {{$limitCharacters(cliente.Empresa,40)}}</span>
												<span class="bold text-body" style="font-size:16px">Propiedad: {{$limitCharacters(cliente.Nombre,40)}}</span>
											</div>
											
                                    		<h6 v-else class="text-center mr-2">Cliente Sin Selecionar</h6>
										</div>
                                        <form class="form-inline">
											<!-- <h6 class="title-grafic side-l mr-3">
												Km Vendidos Por Vehículo
											</h6> -->
                                    		<button @click="ListaCliente"  data-toggle="modal" data-target="#ModalForm3" data-backdrop="static" type="button" class="btn btn-01 search mr-2">Cliente</button>

											<label class="mr-1">No. De Contrato</label>
                                    		<select @change="get_dataSource" v-model="IdContrato" name="" id=""  class="form-control form-control-sm mr-2">
                                    		    <option :value="''">Seleccione Un Numero de Contrato</option>
                                    		    <option v-for="(item,index) in ListaNumc" :key="index" :value="item.IdContrato">
                                    		    	{{item.NumeroC}}
                                    		    </option>
                                    		</select>

											<v-date-picker
												mode="range"
												v-model="rangeDate"
												@input="get_dataSource"
												:input-props="{
													class: 'form-control form-control-sm mr-2 calendar',
													placeholder:
														'Selecciona un rango de fecha para buscar',
													readonly: true
												}"
											/>
											<!--<select
											selected
											@change="get_dataSource"
											v-model="TipoVehiculo"
											class="form-control form-control-sm mr-2"
											>
											<option value="Vendidos">Kilometros Vendidos</option>

											</select>-->
										</form>
                                        <button
											type="button"
											class="btn close abs_01"
											@click="Ocultar()"
										>
											<i class="fal fa-times"></i>
										</button>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
								<div class="col-10 col-sm-10 col-md-8 col-lg-8">
									<h6 class="title-grafic side-l">Km Vendidos Por Vehículo</h6>
								</div>
								<div class="col-2 col-sm-2 col-md-4 col-lg-4 text-right">
									<button
										type="button"
										class="btn-fil-002"
										title="Filtros"
										@click="mostrarfiltro()"
									>
										<i class="fas fa-filter"></i>
									</button>
								</div>
								<div class="col-12 col-sm-12 col-md-12 col-lg-12">
									<hr />
								</div>
							</div>
                            <div style="height:481px">
                                <jscharting :mutable="false" ref="chart3" :options="chartOptions" class="ml-0 pl-0 columnChart3"></jscharting>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </b-overlay>
        <Ccliente :TipoModal='1'/>
    </div>
</template>

<script>
export default {
    name: "KilometrosVendidos",
    data() {
        return {
            cliente:{
				Nombre:'',
				IdCliente: '',
				IdClienteS: '',
			},
			ListaNumc:[],
			IdContrato:'',
            TipoVehiculo: "Vendidos",
			isVisible: false,
			isOverlay: false,
            chartOptions:{
                debug:false,
                type:'horizontal column solid',
				legend_visible:false,
            	yAxis:{
            	    scale_range:{min:0, padding:0.1},
            	    orientation:'opposite',
            	},
            	xAxis:{
					overflow:'scroll',
            	    defaultTick_label_width: 150,
					caOffset:5, 
            	    crosshair: { 
            	        enabled: true, 
            	        gridLine_visible: false, 
            	        outline_width: 0, 
            	        label_style_fontWeight: 'bold'
            	    } 
            	},
            	defaultSeries_palette: ['#CFD8DC'],
            	defaultPoint: { 
            	    outline_width: 0, 
            	    tooltip: '<b>%yValue km</b>',
            	},
            	defaultTooltip_label_text: '%points',
            	series:[]
            }
        }
    },
    methods: {
        mostrarfiltro() {
			this.isVisible = true;
		},

		Ocultar() {
			this.isVisible = false;
		},
		verificarnumero(value) {
			let val = value;
			if (value < 10) {
				val = "0" + value;
			}
			return val;
		},
        async get_dataSource() {
            this.isOverlay = true;
            await this.$http
				.get(
					//'dashboard/ventaxvehiculo',
					"despachooneproductividad/get",
					{
						params: {
							Fecha_I: this.rangeDate.start,
							Fecha_F: this.rangeDate.end,
							TipoVehiculo: this.TipoVehiculo,
							IdClienteS:this.cliente.IdClienteS,
							IdContrato:this.IdContrato
						}
					}
				).then((res)=>{
                    let DatosOrdenados=[];
					for (let index = 0; index < res.data.datacategory.length; index++) {
						const label = res.data.datacategory[index];
						const value = res.data.dataseries[index];
						DatosOrdenados.push({label:label,value:value});
					}
					this.isOverlay = false;
					DatosOrdenados.sort((a,b)=>b.value-a.value);


					var date = new Date(this.rangeDate.start);
					var year = date.getFullYear();
					var month = date.getMonth() + 1; //getMonth is zero based;
					var day = date.getDate();
					var formatted =
						this.verificarnumero(day) +
						"-" +
						this.verificarnumero(month) +
						"-" +
						year;

					var datef = new Date(this.rangeDate.end);
					var yearf = datef.getFullYear();
					var monthf = datef.getMonth() + 1; //getMonth is zero based;
					var dayf = datef.getDate();
					var formattedf =
						this.verificarnumero(dayf) +
						"-" +
						this.verificarnumero(monthf) +
						"-" +
						yearf;
                    let points=DatosOrdenados.map((datos)=>{
                        return {
                            x:datos.label,
                            y:datos.value,
                            color:"#0F3F87",
                            xAxisTick_label_text: this.$limitCharacters(datos.label,18),
							xAxisTick_label_color: '#373D3F'
                        }
                    });
                    this.chartOptions.series=
					[
						{
							points:points
						}
					];
                })
        },
        async ListaCliente() {
			this.bus.$emit('ListCcliente');
		},
        async ListaNumContrato() {
			await this.$http.get(
				'numcontrato/get',
				{
					params:{IdClienteS:this.cliente.IdClienteS}
				}
			).then( (res) => {
				this.ListaNumc =res.data.data.row;
			});
		},
		SeleccionarCliente(objeto) {
			this.cliente=objeto;
			this.get_dataSource();
			this.ListaNumContrato();
		},
    },
    created() {
		this.bus.$off('SeleccionarCliente');
		var date = new Date(),
			y = date.getFullYear(),
			m = date.getMonth();
		var firstDay = new Date(y, m, 1);
		var lastDay = new Date(y, m + 1, 0);
		this.rangeDate = {
			start: firstDay,
			end: lastDay
		};
	},
	mounted() {
		this.get_dataSource();
		this.bus.$on('SeleccionarCliente',(oSucursal)=> {
			this.SeleccionarCliente(oSucursal);
		});
	}
}
</script>

<style>
.columnChart3{
    height: 510px;
}
</style>