<template>
  <div>
    <div class="row">
        <div class="col-lg-12">
            <label for="">Oportunidad</label>
            <textarea class="form-control" cols="30" rows="5" v-model="DescripOportunidad"></textarea>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    name:'Oportunidad',
    props:['pServicio','poBtnSave'],
    data() {
        return {
            DescripOportunidad:''
        }
    },
    methods: {
        Guardar(){
            this.bus.$emit('GuardarConOportunidad',this.DescripOportunidad);
        },
    },
    created() {
        this.bus.$off('NewModal_'+this.poBtnSave.EmitSeccion);
        this.bus.$on('NewModal_'+this.poBtnSave.EmitSeccion,()=>{
            // this.Servicio=this.pServicio;
            this.bus.$off('Save_'+this.poBtnSave.EmitSeccion);
            this.bus.$on('Save_'+this.poBtnSave.EmitSeccion,()=>{
                this.Guardar();
            })
        });
    },
    mounted() {
        // this.Servicio=this.pServicio;
    },
}
</script>

<style>

</style>