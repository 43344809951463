<template>
    <div>
        <CHeader :pHeaderProps="HeaderProps">
            <template slot="Contenido">
                <div class="row mt-3">
                    <div class="col-12 col-sm-12">
                        <label for="">Observaciones</label><small> (max 1500 caracteres)</small>
                        <textarea class="form-control shadow" cols="30" rows="10" maxlength="1500" v-model="Comentario" placeholder="Escriba aquí sus observaciones"></textarea>
                    </div>
                </div>
                <div class="row mt-3 justify-content-center">
                    <!-- <div class="col-6 col-sm-6 text-center">
                        <button @click="Regresar" type="button" class="btn btn-01"><i class="fa fa-times-circle"></i></button>
                    </div> -->
                    <div class="col-10 col-sm-10 text-center">
                        <button :disabled="DisabledBtn" @click="Guardar" type="button" class="btn btn-01 btn-block btn-grande">
                            <i v-show="DisabledBtn" class="fa fa-spinner fa-pulse fa-fw"></i> Guardar
                            <!-- <i v-show="!DisabledBtn" class="fa fa-check"></i> -->
                        </button>
                    </div>
                </div>
            </template>
        </CHeader>
    </div>
</template>

<script>
import CHeader from "@/views/webapp/components/HeaderWebApp.vue";
export default {
    props:['pEquipo','pServicio','pTipoComentario'],
    components:{CHeader},
    data() {
        return {
            DisabledBtn:false,
            HeaderProps:{
                ShowSubHeader:true,
                ShowTecnico:false,
                ShowFechaHoy:false,
                ShowMenuDropdown:false,
                ShowFotoPerfil:false,
                ShowReturn:true,
                TextoHeader:'Observaciones de equipo',
                ShowLogo:false,
                TextoCentroSubHeader:''
            },
            Equipo:{},
            Servicio:{},
            Comentario:''
        }
    },
    methods: {
        Guardar(){
            this.DisabledBtn=true;
            let formData=new FormData();
            formData.set('IdServicio',this.Servicio.IdServicio);
            formData.set('Comentario',this.Comentario);
            if (this.pTipoComentario=='EQUIPO') {
                formData.set('IdEquipo',this.Equipo.IdEquipo);
                this.$http.post('webapp/eqcomentarios',formData
                ).then((res)=>{
                    this.DisabledBtn=false;
                    this.$toast.success('Comentario Guardado');
                    // this.$router.push({name:'EquiposServicioWebApp'});
                }).catch((err)=>{
                    this.DisabledBtn=false;
                    this.$toast.warning(err.response.data.message);
                });
            }
            else{
                this.$http.post('webapp/setserviciocomentario',formData
                ).then((res)=>{
                    this.DisabledBtn=false;
                    this.RecuperarServicio();
                }).catch((err)=>{
                    this.DisabledBtn=false;
                    this.$toast.warning(err.response.data.message);
                });
            }
        },
        RecuperarServicio(){
            this.$http.get('webapp/getservicio',{
                params:{
                    IdTrabajador:JSON.parse(sessionStorage.getItem('user')).IdTrabajador,
                    IdServicio:this.Servicio.IdServicio
                }
            }).then((res)=>{
                sessionStorage.setItem('servicio',JSON.stringify(res.data.Datos));
                this.$toast.success('Comentario Guardado');
                // this.$router.push({name:'EquiposServicioWebApp'});
            })
        },
        Regresar(){
            this.$router.push({name:'EquiposServicioWebApp'});
        }
    },
    mounted() {
        this.bus.$off('EmitReturn');
        this.bus.$on('EmitReturn',()=>{
            this.Regresar();
        });
        this.Servicio=this.pServicio;

        if (this.pTipoComentario=='EQUIPO') {
            this.Equipo=this.pEquipo;
            this.HeaderProps.TextoHeader='Observaciones de equipo';
            this.HeaderProps.TextoCentroSubHeader=this.Equipo.Nequipo;
            this.Comentario=this.Equipo.Comentario;
        }
        else{
            this.Comentario=this.Servicio.Comentario;
            this.HeaderProps.ShowSubHeader=false;
            this.HeaderProps.TextoHeader='Comentario de servicio';
        }
    },
}
</script>

<style>

</style>